import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

export const MonriContext = createContext();

const monriOptions = {
    fonts: [
        {
            family: 'Lato',
            src: 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh7USSwiPGQ.woff2',
            weight: '300'
        },
        {
            family: 'Lato',
            src: 'https://fonts.gstatic.com/s/lato/v23/S6u9w4BMUTPHh6UVSwiPGQ.woff2',
            weight: '700'
        }
    ]
};

export function useMonriScript() {
    const [monri, setMonri] = useState();

    useEffect(() => {
        if (monri) {
            return;
        }
        let monriScript = document.getElementById('monri-script');
        const loadListener = () => {
            setMonri(new window.Monri(process.env.REACT_APP_MONRI_KEY, monriOptions));
        };
        if (monriScript) {
            if (window.Monri) {
                setMonri(new window.Monri(process.env.REACT_APP_MONRI_KEY, monriOptions));
                return;
            } else {
                monriScript.addEventListener('load', loadListener);
                return () => {
                    monriScript.removeEventListener('load', loadListener);
                };
            }
        }
        monriScript = document.createElement('script');
        monriScript.setAttribute('src', process.env.REACT_APP_MONRI_SRC);
        monriScript.id = 'monri-script';
        monriScript.addEventListener('load', loadListener);
        document.body.append(monriScript);
        return () => {
            monriScript.removeEventListener('load', loadListener);
        };
    }, [setMonri, monri]);
    console.log('Monri', monri);
    return monri;
}

export function useMonriComponents() {
    const monri = useContext(MonriContext);
    const componentList = useRef([]);
    const changeListeners = useRef([]);
    return useMemo(() => {
        return {
            instance: monri?.components(),
            componentList: componentList.current,
            getCardComponent: () => componentList.current[0],
            changeListeners: changeListeners.current,
            addListener: (listener) => {
                changeListeners.current.push(listener);
            },
            removeListener: (listener) => {
                changeListeners.current.splice(changeListeners.current.indexOf(listener));
            }
        };
    }, [monri]);
}
