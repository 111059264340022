// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Message, Grid, Image } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { isIOS, isAndroid } from 'react-device-detect';

type Props = {
    onDismissPromo: () => void
};

function PromoBar({ onDismissPromo }: Props) {
    const storeLink = isIOS ? 'https://itunes.apple.com/my/app/mybazar/id1065804162?mt=8' : isAndroid ? 'https://play.google.com/store/apps/details?id=com.mybazar&hl=en' : null;
    return (
        <Container fluid className='mb-mobile only promo-bar'>
            <Message onDismiss={onDismissPromo}>
                <Message.Content>
                    <Grid verticalAlign='middle' style={{ marginLeft: '0px', marginRight: '0px' }}>
                        <Grid.Row>
                            <Grid.Column width={2} style={{ padding: '0px' }}>
                                <Image src='/MyBazarIcon.png' size='mini' />
                            </Grid.Column>
                            <Grid.Column width={9} style={{ padding: '0px' }}>
                                <h4 style={{ margin: '0px' }}>{`MyBazar ${isIOS ? 'iOS' : isAndroid ? 'Android' : 'Other'}`}</h4>
                                <span>
                                    <Trans i18nKey='promoBar.openStoreDesc'>Open in the MyBazar app</Trans>
                                </span>
                            </Grid.Column>
                            <Grid.Column width={3} style={{ padding: '0px' }}>
                                <a className='ui blue basic compact button promo-bar-link' href={storeLink}>
                                    <Trans i18nKey='promoBar.openStoreLink'>OPEN</Trans>
                                </a>
                            </Grid.Column>
                            <Grid.Column width={1} />
                        </Grid.Row>
                    </Grid>
                </Message.Content>
            </Message>
        </Container>
    );
}

export default PromoBar;
