// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_STORE_CONVERSATIONS ------------------
export type LoadMyStoreConversationsParams = {
    limit: number,
    offset: number
};

export const LOAD_MY_STORE_CONVERSATIONS = 'LOAD_MY_STORE_CONVERSATIONS';
export const loadMyStoreConversations = wrapAPIActionCreator(({ limit = 10, offset = 0 }: LoadMyStoreConversationsParams) => ({
    [API_DATA]: {
        type: LOAD_MY_STORE_CONVERSATIONS,
        endpoint: '/v3/conversations',
        endpointParams: { limit, offset },
        reducerParams: { limit, offset },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            conversations: [Schemas.conversation]
        }
    }
}));
