// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Divider, Grid, Menu } from 'semantic-ui-react';
import { sidebarLinkList } from 'utilities/links';
import { getIsNonTempUser } from 'store/utilities';

import SidebarMenuMainItem from './components/SidebarMenuMainItem';

type Props = {
    isLoggedIn: boolean,
    user: EntityData<User>,
    merchantUser: EntityData<User>
    // bazarPages: EntityData<LandingPage>[],
};

function SidebarMenuMain({ isLoggedIn, user, merchantUser, isNonTempUser, shopperUserUnread, shopperSystemUnread, merchantUserUnread, merchantSystemUnread }: Props) {
    function mapMenuListItem(item) {
        return [item];
    }

    function flatMap(array, fn) {
        return [].concat(...array.map(fn));
    }

    function getMessageCount(listItemKey) {
        let newMessageCount = 0;

        switch (listItemKey) {
            case 'shopperInbox':
                newMessageCount = shopperUserUnread;
                break;
            case 'promotions':
                newMessageCount = shopperSystemUnread;
                break;
            case 'merchantInbox':
                newMessageCount = merchantUserUnread;
                break;
            case 'campaigns':
                newMessageCount = merchantSystemUnread;
                break;
            default:
                break;
        }

        return newMessageCount;
    }

    return sidebarLinkList.map(
        (category) =>
            !((category.key === 'user' && (!user || !isNonTempUser)) || (category.key === 'merchant' && !merchantUser) || (category.key === 'becomeAMerchant' && isLoggedIn)) && (
                <Grid.Row key={category.key}>
                    <Grid.Column>
                        <Grid className='tensed'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Menu className='simple icons-left' vertical borderless fluid>
                                        {flatMap(category.list, mapMenuListItem).map((listItem) => (
                                            <SidebarMenuMainItem key={listItem.key} item={listItem} category={category} newMessageCount={getMessageCount(listItem.key)} />
                                        ))}
                                    </Menu>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column>
                                    <Divider fitted />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            )
    );
}

const mapStateToProps = (state) => {
    const isNonTempUser = getIsNonTempUser(state);
    // const shopperHasNewMessage = state.userData.conversations.hasNewMessage;
    // const shopperTotalUnread = state.userData.conversations.totalUnread;
    const shopperUserUnread = state.userData.conversations.totalUserUnread;
    const shopperSystemUnread = state.userData.conversations.totalSystemUnread;
    // const merchantHasNewMessage = state.merchantData.conversations.hasNewMessage;
    // const merchantTotalUnread = state.merchantData.conversations.totalUnread;
    const merchantUserUnread = state.merchantData.conversations.totalUserUnread;
    const merchantSystemUnread = state.merchantData.conversations.totalSystemUnread;

    return {
        isNonTempUser,
        shopperUserUnread,
        shopperSystemUnread,
        merchantUserUnread,
        merchantSystemUnread
    };
};

export default compose(connect(mapStateToProps, null))(SidebarMenuMain);
