// @flow
import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import store, { history, persistor } from './store';
import onLoad from './common/onLoad';
import LogInScene from './scenes/LogIn';
import SelectProfile from './scenes/SelectProfile';
import ForgotPassword from './scenes/ForgotPassword';
import ResetEmailSent from './scenes/ResetEmailSent';
import RecoverScene from './scenes/Recover';
import SignUpScene from './scenes/SignUp';
import CreateStoreScene from './scenes/CreateStore';
import SellItemScene from './scenes/SellItem';
import PreviewItemScene from './scenes/PreviewItem';
import EditItemScene from './scenes/EditItem';
import CartScene from './scenes/Cart';
import CheckoutScene from './scenes/Checkout';
import CheckoutReturnScene from './scenes/CheckoutReturn';
import CheckoutCompleteScene from './scenes/CheckoutComplete';
import LandingScene from './scenes/Landing';
import LandingViewAllScene from './scenes/LandingViewAll';
import EmbeddedPage from './scenes/EmbeddedPage';
import TopCategoryScene from './scenes/TopCategory';
import SearchScene from './scenes/Search';
import SubCategoryScene from './scenes/SubCategory';
import PageNotFound from './scenes/PageNotFound';
import SlugResolver from './scenes/SlugResolver';
import ProductScene from './scenes/Product';
import MyStoreScene from './scenes/MyStore';
import MyProfileScene from './scenes/MyProfile';
import MyAgentScene from './scenes/MyAgent';
import DevTestPanelScene from './scenes/DevTestPanelScene';
import BulkImportScene from './scenes/BulkImport';
import BulkEditScene from './scenes/BulkEdit';
import MerchantWelcomeScene from './scenes/MerchantWelcomeScene';
import InformationScene from 'scenes/InformationScene';

import ScrollToTop from './components/ScrollToTop';
import Page from './components/Page';
import MyAgentOfficeContainer from 'scenes/MyAgent/components/MyAgentOffice';
import MyAgentSettingsContainer from 'scenes/MyAgent/components/MyAgentSettings/MyAgentSettingsContainer';
import StoreSettings from 'scenes/MyStore/StoreSettingsContainer';

import { getLanguages } from './utilities/languages';
import { MonriContext, useMonriScript } from './utilities/monri/useMonri';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { GoogleOAuthProvider } from '@react-oauth/google';

const locizeLanguages = getLanguages();
const clientId = process.env.REACT_APP_GOOGLE_APP_ID;
function App() {
    const languages = locizeLanguages.read();
    const monri = useMonriScript();
    return (
        <GoogleOAuthProvider clientId={clientId}>
            <MonriContext.Provider value={monri}>
                <Provider store={store}>
                    <PersistGate loading={null} onBeforeLift={onLoad} persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <ScrollToTop>
                                <Page languages={languages}>
                                    <MessengerCustomerChat
                                        style={{ zIndex: '2000' }}
                                        pageId={process.env.REACT_APP_MESSENGER_PAGE_ID}
                                        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                                    />
                                    <Switch>
                                        <Route exact path='/' render={() => <LandingScene tab='discover' />} />
                                        <Route exact path='/password' component={MerchantWelcomeScene} />
                                        <Route exact path='/information/:slug' component={InformationScene} />
                                        {process.env.NODE_ENV === 'development' && <Route exact path='/testpanel' component={DevTestPanelScene} />}
                                        {/* Onboarding & personal */}
                                        <Route exact path='/select-profile' component={SelectProfile} />
                                        <Route exact path='/login' component={LogInScene} />
                                        <Route exact path='/login/recover/:token' component={RecoverScene} />
                                        <Route exact path='/forgot-password' component={ForgotPassword} />
                                        <Route exact path='/reset-email-sent' component={ResetEmailSent} />
                                        <Route exact path='/signup' component={SignUpScene} />
                                        <Route path='/my-profile' component={MyProfileScene} />
                                        <Route path='/my-store' component={MyStoreScene} />
                                        <Route path='/agent-office' component={MyAgentOfficeContainer} />
                                        <Route path='/agent-settings' component={MyAgentSettingsContainer} />
                                        <Route path='/store-settings' component={StoreSettings} />
                                        <Route path='/my-agent' component={MyAgentScene} />
                                        {/* Buyers: cart & checkout */}
                                        <Route exact path='/cart' component={CartScene} />
                                        <Route exact path='/checkout' component={CheckoutScene} />
                                        <Route exact path='/checkout/return' component={CheckoutReturnScene} />
                                        <Route exact path='/checkout-complete' component={CheckoutCompleteScene} />
                                        {/* Sellers: my store etc */}
                                        <Route exact path='/create-store' component={CreateStoreScene} />
                                        <Route exact path='/sell-item' component={SellItemScene} />
                                        <Route exact path='/bulk-import' component={BulkImportScene} />
                                        <Route exact path='/bulk-edit' component={BulkEditScene} />
                                        <Route exact path='/item/:itemId' component={PreviewItemScene} />
                                        <Route exact path='/edit-item/:itemId' component={EditItemScene} />
                                        {/* Categories */}
                                        <Route exact path='/categories/:slug' component={TopCategoryScene} />
                                        <Route exact path='/categories/:top/:slug' component={SubCategoryScene} />
                                        <Route exact path='/categories/:top/:slug/:child/:page(\d+)' component={SubCategoryScene} />
                                        <Route exact path='/categories/:top/:slug/:child' component={SubCategoryScene} />
                                        {/* Search pages */}
                                        <Route exact path='/search/' component={SearchScene} />
                                        <Route exact path='/search/:tab?/:value' component={SearchScene} />
                                        {/* Landing pages -- TODO: deprecate these (not /view-all/) */}
                                        <Route exact path='/discover' render={() => <LandingScene tab='discover' />} />
                                        <Route exact path='/latest' render={() => <LandingScene tab='latest' />} />
                                        <Route exact path='/classifieds' render={() => <LandingScene tab='mypasar' />} />
                                        <Route exact path='/view-all/:landingComponentId/:page?' component={LandingViewAllScene} />
                                        {/* Blee -- TODO: depreacte these/make generic */}
                                        <Route exact path='/blee' render={() => <LandingScene tab='blee' />} />
                                        <Route
                                            exact
                                            path='/blee/about-blee'
                                            render={() => <EmbeddedPage src='https://www.mybazarglobal.com/blee/?app=blank' contentHeight='2880px' />}
                                        />
                                        <Route exact path='/blee/categories/:slug' component={TopCategoryScene} />
                                        <Route exact path='/blee/categories/:top/:slug' component={SubCategoryScene} />
                                        <Route exact path='/blee/categories/:top/:slug/:child/:page(\d+)' component={SubCategoryScene} />
                                        <Route exact path='/blee/categories/:top/:slug/:child' component={SubCategoryScene} />
                                        <Route exact path='/blee/view-all/:landingComponentId/:page?' component={LandingViewAllScene} />
                                        {/* "Bazar" routes, subpages of landing page */}
                                        <Route
                                            exact
                                            path='/:landingSlug/about'
                                            render={({ match }) => (
                                                <EmbeddedPage src={`https://www.mybazarglobal.com/${match.params.landingSlug}/?app=blank`} contentHeight='2880px' />
                                            )}
                                        />
                                        <Route exact path='/:landingSlug/categories/:slug' component={TopCategoryScene} />
                                        <Route exact path='/:landingSlug/categories/:top/:slug' component={SubCategoryScene} />
                                        <Route exact path='/:landingSlug/categories/:top/:slug/:child/:page(\d+)' component={SubCategoryScene} />
                                        <Route exact path='/:landingSlug/categories/:top/:slug/:child' component={SubCategoryScene} />
                                        <Route exact path='/:landingSlug/view-all/:landingComponentId/:page?' component={LandingViewAllScene} />
                                        {/* TODO: /:landingSlug/items */}
                                        {/* Legacy redirects */}
                                        <Route exact path='/mypasar' render={() => <Redirect to='/classifieds' />} />
                                        {/* Catch-all slug routes */}
                                        <Route exact path='/:slug' component={SlugResolver} />
                                        <Route exact path='/:storeSlug/:itemSlug' component={ProductScene} />
                                        <Route component={PageNotFound} />
                                    </Switch>
                                    {/*<Analytics />*/}
                                </Page>
                            </ScrollToTop>
                        </ConnectedRouter>
                    </PersistGate>
                </Provider>
            </MonriContext.Provider>
        </GoogleOAuthProvider>
    );
}

const SuspenseWrapper = () => (
    <Suspense fallback={<div></div>} maxDuration={5000}>
        <App />
    </Suspense>
);

export default SuspenseWrapper;
