// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import SignUpForm from './components/SignUpForm';

function SignUp({ onSuccess }) {
    return (
        <div className='sign-up'>
            <SignUpForm onSuccess={onSuccess} />
            <Grid columns='2' style={{ marginTop: 0 }}>
                <Grid.Row>
                    <Grid.Column />
                    <Grid.Column textAlign='right'>
                        <p>
                            <Trans i18nKey='signUp.alreadySignedUp'>Already signed up?</Trans>{' '}
                            <Link to='/login' className='standard-color-link'>
                                <Trans i18nKey='signUp.logIn'>Log in!</Trans>
                            </Link>
                        </p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default withTranslation('translations')(SignUp);
