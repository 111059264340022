// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { wrapAPIDispatch } from 'services/api';
import { ModalNames } from 'store/actions/ui';
import * as actions from 'store/actions';

import onLogin from '../../common/onLogin';
import RecoverView from './RecoverView';

type Props = {
    match: {
        params: { [string]: string }
    },
    // from store
    openModal: typeof actions.openModal,
    recoverResetPassword: typeof actions.recoverResetPassword,
    validateForgotPasswordToken: typeof actions.validateForgotPasswordToken,
    navigateTo: typeof push
};

type State = {
    isLoading: boolean,
    isSubmitting: boolean,
    isBadToken: boolean,
    errorMessage: string
};

class RecoverContainer extends Component<Props, State> {
    state = {
        isBadToken: false,
        isLoading: false,
        isSubmitting: false,
        errorMessage: null
    };

    componentDidMount() {
        this.loadData();
    }

    // UNSAFE_componentWillMount() {
    //   this.loadData();
    // }

    loadData = () => {
        const { match, validateForgotPasswordToken } = this.props;
        const { token } = match.params;

        this.setState({ isLoading: true });
        validateForgotPasswordToken({ resetToken: token })
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch(() => {
                this.setState({ isLoading: false, isBadToken: true });
            });
    };

    handleSubmit = ({ password }) => {
        const { match, recoverResetPassword, navigateTo } = this.props;
        const { token } = match.params;
        this.setState({ isSubmitting: true });
        recoverResetPassword({ resetToken: token, password })
            .then(() => {
                onLogin();
                navigateTo('/');
            })
            .catch(({ message }) => {
                this.setState({ isSubmitting: false, errorMessage: message });
            });
    };

    handleClickForgotPassword = () => {
        const { openModal } = this.props;
        openModal(ModalNames.FORGOT_PASSWORD);
    };

    render() {
        const { isBadToken, isLoading, isSubmitting, errorMessage } = this.state;
        return (
            <RecoverView
                onSubmit={this.handleSubmit}
                onClickForgotPassword={this.handleClickForgotPassword}
                {...{
                    isBadToken,
                    isLoading,
                    isSubmitting,
                    errorMessage
                }}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    validateForgotPasswordToken: wrapAPIDispatch(dispatch, actions.validateForgotPasswordToken),
    recoverResetPassword: wrapAPIDispatch(dispatch, actions.recoverResetPassword),
    openModal: (...args) => dispatch(actions.openModal(...args)),
    navigateTo: (...args) => dispatch(push(...args))
});

export default compose(connect(null, mapDispatchToProps))(RecoverContainer);
