// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import MyStoreDashboardView from './MyStoreDashboardView';

type Props = {
    // from store
};

class MyStoreDashboardContainer extends Component<Props> {
    componentDidMount() {
        const { activity, loadActivity } = this.props;

        if (!activity.isLoadingActivity) {
            loadActivity().catch(console.log);
        }
    }

    render() {
        const { orders, items, activity, sales } = this.props;

        return <MyStoreDashboardView orders={orders} items={items} activity={activity} sales={sales} />;
    }
}

const mapStateToProps = (state) => ({
    orders: state.merchantData.orders,
    items: state.merchantData.items,
    activity: state.merchantData.activity,
    sales: state.merchantData.sales
});

const mapDispatchToProps = (dispatch) => ({
    loadActivity: wrapAPIDispatch(dispatch, actions.loadMyStoreActivity)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreDashboardContainer);
