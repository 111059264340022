// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

export type LoadAllLandingPagesParams = {
    channel?: string
};

export const LOAD_ALL_LANDING_PAGES = 'LOAD_ALL_LANDING_PAGES';
export const loadAllLandingPages = wrapAPIActionCreator(({ channel = 'web' }: LoadAllLandingPagesParams = {}) => ({
    [API_DATA]: {
        type: LOAD_ALL_LANDING_PAGES,
        endpoint: '/v3/landing_page/all',
        endpointParams: { displayChannel: channel },
        schema: {
            landingPages: [Schemas.landingPage]
        },
        postprocess: (res) => ({ ...res, channel })
    }
}));
