// @flow
import React, { useEffect, useState } from 'react';
import { Container, Dropdown, Grid, Header, Message, Visibility, Loader, Menu, Label, Button } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import ProductList from 'components/ProductList';
import StoreList from 'components/StoreList';
import UserList from 'components/UserList';
import { useHistory } from 'react-router';

const itemsOptions = [
    { key: 0, text: <Trans i18nKey='sortDropdown.options.latestAdded.label'>Latest</Trans>, value: 'latest' },
    { key: 1, text: <Trans i18nKey='sortDropdown.options.bestDeals.label'>Deals</Trans>, value: 'deal' },
    { key: 2, text: <Trans i18nKey='sortDropdown.options.priceMinToMax.label'>Price: Lowest</Trans>, value: 'price_min' },
    { key: 3, text: <Trans i18nKey='sortDropdown.options.priceMaxToMin.label'>Price: Highest</Trans>, value: 'price_max' }
];

type Calculations = {
    bottomPassed: boolean,
    bottomVisible: boolean,
    direction: string,
    fits: boolean,
    height: number,
    offScreen: boolean,
    onScreen: boolean,
    passing: boolean,
    pixelsPassed: number,
    topPassed: boolean,
    topVisible: boolean,
    width: number
};

type Props = {
    itemsByKeyword: array,
    tagsByKeyword: array,
    storesByKeyword: array,
    usersByKeyword: array,
    searchByKeywordResults: {
        itemCount: number,
        items: [object],
        searchByKeywordIsLoading: boolean,
        tagItemCount: number,
        userCount: number
    },
    match: {
        isExact: boolean,
        params: {
            value: string
        },
        path: string,
        url: string
    },
    isRequestError?: boolean,
    loadMoreItems: (string, Calculations, isManualLoad: boolean) => void,
    loadMoreItemsByTags: (string, Calculations, isManualLoad: boolean) => void,
    loadMoreStores: (string, Calculations, isManualLoad: boolean) => void,
    loadMoreUsers: (string, Calculations, isManualLoad: boolean) => void,
    handleTabChange: () => void,
    sortItems: (string) => void,
    currentTab: number,
    currentSortWord: string,
    itemsByKeywordIsLoading: boolean,
    storesByKeywordIsLoading: boolean,
    usersByKeywordIsLoading: boolean,
    tagsByKeywordIsLoading: boolean
};

const SearchView = (props: Props) => {
    const [state, setState] = useState('latest');
    const history = useHistory();
    useEffect(() => {
        setState(props.currentSortWord ? props.currentSortWord : 'latest');
    }, []);

    const handleChange = (e, { value }) => {
        setState(value);
        props.sortItems(value);
    };

    const contentIsLoading = () => {
        const { itemsByKeywordIsLoading, storesByKeywordIsLoading, usersByKeywordIsLoading, tagsByKeywordIsLoading } = props;

        if (itemsByKeywordIsLoading || storesByKeywordIsLoading || usersByKeywordIsLoading || tagsByKeywordIsLoading) {
            return (
                <div className='infinite-scrolling-throbber-container'>
                    <Loader active inline='centered' className='infinite-scrolling-throbber' />
                </div>
            );
        }
        return null;
    };

    function renderCount(text) {
        const { searchByKeywordIsLoading } = props.searchByKeywordResults;
        if (searchByKeywordIsLoading) {
            return <Loader active />;
        }
        return text;
    }

    const value = state;
    const {
        isRequestError,
        itemsByKeyword,
        tagsByKeyword,
        storesByKeyword,
        searchByKeywordResults,
        loadMoreItems,
        loadMoreItemsByTags,
        loadMoreStores,
        loadMoreUsers,
        usersByKeyword,
        match
    } = props;
    const { searchByKeywordIsLoading } = searchByKeywordResults;

    const { value: searchValue, tab: searchTab = 'items' } = match.params;

    const tabs = [
        {
            index: 0,
            name: 'items',
            label: <Trans i18nKey='search.tab.items'>Items</Trans>,
            count: searchByKeywordResults.itemCount,
            items: itemsByKeyword,
            render: 'product',
            loadMore: loadMoreItems
        },
        {
            index: 1,
            name: 'stores',
            label: <Trans i18nKey='search.tab.stores'>Stores</Trans>,
            count: searchByKeywordResults.storeCount,
            items: storesByKeyword,
            render: 'store',
            loadMore: loadMoreStores
        },
        {
            index: 2,
            name: 'users',
            label: <Trans i18nKey='search.tab.users'>Users</Trans>,
            count: searchByKeywordResults.classifiedUserCount,
            items: usersByKeyword,
            render: 'user',
            loadMore: loadMoreUsers
        },
        {
            index: 3,
            name: 'tags',
            label: <Trans i18nKey='search.tab.tags'>Tags</Trans>,
            count: searchByKeywordResults.tagItemCount,
            items: tagsByKeyword,
            render: 'product',
            loadMore: loadMoreItemsByTags
        }
    ];

    const results = searchByKeywordResults.itemCount + searchByKeywordResults.storeCount + searchByKeywordResults.classifiedUserCount + searchByKeywordResults.tagItemCount;
    const tab = tabs.filter(({ name }) => name === searchTab)[0] || tabs[0];

    function handleHasMore(tab: string) {
        switch (tab) {
            case 'product':
                return !!props.hasMoreItemsByKeyword;
            case 'user':
                return !!props.hasMoreUsersByKeyword;
            case 'tag':
                return !!props.hasMoreTagsByKeyword;
            case 'store':
                return !!props.hasMoreStoresByKeyword;
            default:
                return false;
        }
    }

    return (
        <Container style={{ width: '95%' }}>
            <Grid className='auto'>
                <Grid.Column style={{ marginTop: -44, marginBottom: 15 }} className='col-row'>
                    <Grid className='auto vertically-tensed grid-align-items-center'>
                        <Grid.Column className='col-grow'>
                            <Header style={{ fontWeight: 'normal', fontStyle: 'Lato', fontSize: 18 }} as='h2'>
                                <Trans i18nKey='search.header'></Trans> {" "}
                                <span style={{ fontWeight: 'bold', fontSize: 18, fontStyle: 'Lato' }}>{searchValue || ''}</span>
                            </Header>
                            {/* <Trans i18nKey="search.header" ></Trans> */}
                        </Grid.Column>
                        <Grid.Column className='col-shrink'>
                            {history.location.pathname.includes('item') && (
                                <Dropdown onChange={handleChange} options={itemsOptions} placeholder='Choose an option' selection value={value} />
                            )}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                {isRequestError && (
                    <Grid.Column className='col-row'>
                        <Message error>
                            Oops! An error occurred when performing your search. You can still browse the search results, but it is possible that some results are missing.
                        </Message>
                    </Grid.Column>
                )}
                <Grid.Column className='col-row'>
                    <Menu secondary>
                        {tabs.map(({ index, name, label, count }) => (
                            <Menu.Item as={Link} key={index} to={`/search/${name}/${encodeURIComponent(searchValue)}`} active={tab.index === index}>
                                {label}
                                <Label content={searchByKeywordIsLoading ? '...' : count} />
                            </Menu.Item>
                        ))}
                    </Menu>
                    <Visibility
                        continuous
                        fireOnMount
                        onUpdate={(e, { calculations }) => {
                            if (tab.items.length < 105) {
                                tab.loadMore(value, calculations,);
                            }
                        }}
                    >
                        {tab.render === 'product' && <ProductList items={tab.items.map(({ entity }) => entity.id)} />}
                        {tab.render === 'store' && <StoreList items={tab.items.map((merchantId) => ({ merchantId, itemIds: [] }))} />}
                        {tab.render === 'user' && <UserList items={tab.items.map((merchantId) => ({ merchantId, itemIds: [] }))} />}
                        <div style={{ width: '100%', textAlign: 'center', marginTop: '48px' }}>
                            {handleHasMore(tab.render) && tab.items.length > 104 ? <Button onClick={() => tab.loadMore(value, undefined, true)}>Učitaj još</Button> : null}
                        </div>
                    </Visibility>
                    {contentIsLoading()}
                </Grid.Column>
            </Grid>
        </Container>
    );
};

SearchView.defaultProps = {
    isRequestError: false
};

export default withRouter(withTranslation('translations')(SearchView));
