// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Segment, Image, Grid, Label } from 'semantic-ui-react';

import { formatStoreName } from 'utilities/format';

import type { EntityData, Item, User } from 'store/types';
import missingImage from 'styles/assets/images/missing-image.svg';
import missingStoreImage from 'styles/assets/images/missing-store-image.svg';

function renderItem({ isFetching, entity, preventSlickClickDuringChange }: EntityData<Item>) {
    // TODO: handle loading better
    if (isFetching || entity == null) {
        return null;
    }
    const { id, name, pictures, slug } = entity;

    // TODO: show placeholder if no picture
    const pictureURL = pictures[0] && pictures[0].imageThumb;
    return (
        <Grid.Column key={id} className='col-5-and-one-third' style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}>
            <Link to={`/${slug}`} onClick={preventSlickClickDuringChange || null}>
                <Image
                    className='square-image'
                    wrapped
                    title={name}
                    alt={name}
                    src={pictureURL || missingImage}
                    onError={(event) => {
                        event.target.src = missingImage;
                    }}
                />
            </Link>
        </Grid.Column>
    );
}

function ShoppingMallCarouselItem(props) {
    const { entity, component, preventSlickClickDuringChange } = props;
    // TODO: handle loading better
    if (component?.isFetching || entity == null) {
        return null;
    }
    const { title, imageFull, slug, id } = entity;
    const storeURL = `/${slug}?bazar=${slug}`;

    return (
        <Segment className='store-teaser simple'>
            <Link to={storeURL} onClick={preventSlickClickDuringChange || null}>
                <Image
                    className='square-image'
                    wrapped
                    alt={title}
                    src={imageFull || missingStoreImage}
                    onError={(event) => {
                        event.target.src = missingStoreImage;
                    }}
                />
            </Link>
        </Segment>
    );
}

export default withTranslation('translations')(ShoppingMallCarouselItem);
