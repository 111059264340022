import React, { Component } from 'react';
import { compose } from 'redux';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import DeliveryAddress from 'components/DeliveryAddress';
import MyProfileSettingsFormProfile from './components/MyProfileSettingsFormProfile';
// import ConnectedAccounts from 'components/ConnectedAccounts/ConnectedAccountsView';
import ProfileImages from 'components/ProfileImages';

class MyProfileSettingsFormView extends Component {
    handleMessages(message: string) {
        switch (message) {
            case 'Changes saved':
                return 'Promjene su uspješno spremljene.';
            case 'password value is out of range, must be between [6, 50]':
                return 'Šifra mora sadržavati između 6 i 50 karaktera.';
            default:
                return message;
        }
    }
    render() {
        const { isPosting, errorMessage, successMessage, fieldData, onChange, onSubmit } = this.props;
        const { name, username, email, phone, newPassword, currentPassword } = fieldData;
        return (
            <Form onSubmit={onSubmit} error={!!errorMessage} success={!!successMessage}>
                <Grid stackable>
                    {errorMessage && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message error>{this.handleMessages(errorMessage)}</Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    {successMessage && (
                        <Grid.Row>
                            <Grid.Column>
                                <Message success>{this.handleMessages(successMessage)}</Message>
                            </Grid.Column>
                        </Grid.Row>
                    )}
                    <Grid.Row>
                        <Grid.Column computer={4}>
                            <ProfileImages />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <MyProfileSettingsFormProfile
                                fieldData={{
                                    name,
                                    username,
                                    email,
                                    phone,
                                    newPassword,
                                    currentPassword
                                }}
                                onChange={onChange}
                            />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <DeliveryAddress />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={16}>
                        <Grid.Column computer={16}>
                            <Button className='save-changes-btn' loading={isPosting} type='submit' floated='left' color='black'>
                                <Trans i18nKey='myProfile.settings.form.submit'>Save Settings</Trans>
                            </Button>
                        </Grid.Column>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default compose(withTranslation('translations'))(MyProfileSettingsFormView);
