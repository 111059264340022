// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// UPDATE_MERCHANT_STORE_LOCATION --------------------------------
type MailingAddressParams = {
    city: string,
    contactNo?: string, // Phone number
    country: string,
    houseNo: string,
    state: string,
    street: string,
    zip: string
};

export type UpdateMerchantStoreLocationParams = {
    name: string,
    storeId: number,
    mailingAddress: MailingAddressParams
};

export const UPDATE_MERCHANT_STORE_LOCATION = 'UPDATE_MERCHANT_STORE_LOCATION';
export const updateMerchantStoreLocation = wrapAPIActionCreator(({ name, storeId, mailingAddress }: UpdateMerchantStoreLocationParams) => ({
    [API_DATA]: {
        type: UPDATE_MERCHANT_STORE_LOCATION,
        endpoint: `/v3/me/stores/${storeId}`,
        authentication: Authentication.Merchant,
        method: 'PUT',
        body: {
            name,
            mailingAddress
        },
        schema: {
            store: Schemas.store
        }
    }
}));
