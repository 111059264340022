import React from 'react';
import { withTranslation } from 'react-i18next';

import CreateStoreForm from './components/CreateStoreForm';

function CreateStore() {
    return (
        <div className='create-store'>
            <CreateStoreForm />
        </div>
    );
}

export default withTranslation('translations')(CreateStore);
