// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getCartItem, getUserDeliveryAddress, getIsNonTempUser } from 'store/utilities';

import CheckoutDetailsView from './CheckoutDetailsView';

type Props = {
    deleteCartPromoCode: typeof actions.deleteCartPromoCode,
    openModal: typeof actions.openModal
};

class CheckoutDetailsContainer extends Component<Props> {
    render() {
        const { cart } = this.props;
        if (cart.errorMessage) {
            return null;
        }
        return <CheckoutDetailsView {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    const { cart } = state.userData;
    const { addresses } = cart;
    const cartItems = cart.cartItems.map((id) => getCartItem(state, id).entity);

    // Order amount breakdown
    const shippingFee = cart.totalShipping;
    const vat = cart.totalVat;
    const subtotal = cart.subtotal;

    const deliveryAddress = getUserDeliveryAddress(state, cart.addressId);
    const isNonTempUser = getIsNonTempUser(state);

    return {
        addresses,
        cart,
        shippingFee,
        subtotal,
        vat,
        cartItems,
        deliveryAddress,
        isNonTempUser
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteCartPromoCode: wrapAPIDispatch(dispatch, actions.deleteCartPromoCode),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckoutDetailsContainer);
