// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import defaultCover from 'styles/assets/images/defaults/coverPhotoDefaultLow.jpg';

function SidebarProfileBackground({ user }) {
    let coverPhotoUrl = defaultCover;
    if (user) {
        const { coverPhotos } = user;
        if (coverPhotos && coverPhotos.length > 0) {
            coverPhotoUrl = coverPhotos[0].imageFull;
        }
    }

    return <div className='sidebar-profile-background' style={{ backgroundImage: `url(${coverPhotoUrl})` }} />;
}

export default SidebarProfileBackground;
