// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { Form, Icon } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

type FormFieldData = {
    value: string,
    error: boolean
};

type Props = {
    t: Function,
    isLoading?: boolean,
    onChange: (SyntheticEvent<>, Object) => void,
    fieldData: {
        [string]: FormFieldData
    }
};

type RenderInputProps = {
    name: string,
    type?: string
};

class MyProfileSettingsFormProfile extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            currentPassword: false,
            newPassword: false
        };
    }
    static defaultProps: Object;

    togglePassword = (name: string) => {
        if (name === 'currentPassword') {
            this.setState({ currentPassword: !this.state.currentPassword });
        } else {
            this.setState({ newPassword: !this.state.newPassword });
        }
    };

    renderInput = (props: RenderInputProps) => {
        const { name, type = 'text' } = props;
        const { fieldData, onChange, isLoading, t } = this.props;
        const { error, value } = fieldData[name];
        return (
            <Form.Input
                required
                disabled={isLoading}
                onChange={onChange}
                label={t(`myProfile.settings.form.${name}.label`)}
                placeholder={t(`myProfile.settings.form.${name}.placeholder`)}
                {...{
                    name,
                    type,
                    value,
                    error
                }}
                {...props}
                icon={() =>
                    (name === 'newPassword' || name === 'currentPassword') && (
                        <Icon onClick={() => this.togglePassword(name)} style={{ cursor: 'pointer', pointerEvents: 'unset' }} name='eye' />
                    )
                }
            />
        );
    };

    render() {
        const { fieldData, t } = this.props;
        const { value: username } = fieldData.username;
        return (
            <div>
                {this.renderInput({ name: 'name' })}
                {this.renderInput({ name: 'phone' })}
                {this.renderInput({ name: 'email', type: 'email' })}
                {/*
                <Form.Dropdown
                    selection
                    name='language'
                    id='-language'
                    onChange={() => {}}
                    label={t('myProfile.settings.form.language.label')}
                    placeholder={t('myProfile.settings.form.language.placeholder', {
                      defaultValue: '',
                    })}
                    value={''}
                    options={[
                      { text: 'English', value: 'en' },
                      { text: 'B/H/S', value: 'ba'}
                    ]}
                    required
                />
                <Form.Dropdown
                    selection
                    name='currency'
                    id='-currency'
                    onChange={() => {}}
                    label={t('myProfile.settings.form.currency.label')}
                    placeholder={t('myProfile.settings.form.currency.placeholder', {
                      defaultValue: '',
                    })}
                    value={''}
                    options={[
                      { text: 'KM', value: 'BAM'},
                      { text: 'US Dollar', value: 'USD' },
                      { text: 'Euro', value: 'EUR' }
                    ]}
                    required
                />
                <Form.Dropdown
                    selection
                    name='landing-page'
                    id='-landing-page'
                    onChange={() => {}}
                    label={t('myProfile.settings.form.landingPage.label')}
                    placeholder={t('myProfile.settings.form.landingPage.placeholder', {
                      defaultValue: '',
                    })}
                    value={''}
                    options={[
                       { text: 'Default', value: 'default' },
                       { text: 'Custom', value: 'custom'}
                    ]}
                    required
                />
                */}
                <input type='hidden' name='username' value={username} />
                {this.renderInput({ name: 'currentPassword', type: this.state.currentPassword ? 'text' : 'password', required: false })}
                {this.renderInput({ name: 'newPassword', type: this.state.newPassword ? 'text' : 'password', required: false })}
            </div>
        );
    }
}

MyProfileSettingsFormProfile.defaultProps = {
    isLoading: false
};

export default compose(withTranslation('translations'))(MyProfileSettingsFormProfile);
