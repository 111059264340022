// @flow
import React, { Component } from 'react';
import { Modal, Form, Button, Icon, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { FormValues } from './index';

type FormFieldData<T = string> = {
    value: T,
    error: boolean
};

type Props = {
    isOpen: boolean,
    onClose: () => void,
    onSubmit: (FormValues) => void,
    t: (string) => string
};

type State = {
    promoCode: FormFieldData<>,
    errorMessage: ?string
};

function createEmptyFormFieldData(value = '') {
    return {
        value,
        error: false
    };
}

class AddCartPromoCodeModalView extends Component<Props, State> {
    state = {
        promoCode: createEmptyFormFieldData(),
        errorMessage: null
    };

    handleChange = (event, { name, value }) => {
        const target = this.state[name];
        target.value = value;
        this.setState({ [name]: target });
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const {
            promoCode: { value: promoCode }
        } = this.state;
        onSubmit({
            promoCode
        })
            .then(() => {
                this.setState({ loading: false });
                this.handleClose();
            })
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };


    handleMessages = (message: string) => {
        switch (message) {
            case 'Promo code is invalid':
                return 'Kod nije validan.';
            case 'Promo code has been expired':
                return 'Kod je istekao.';
            default:
                return message;
        }
    }

    render() {
        const { t, isOpen } = this.props;
        const {
            promoCode: { value: promoCode },
            errorMessage
        } = this.state;
        return (
            <Modal size='tiny' open={isOpen} onClose={this.handleClose}>
                <Modal.Header>
                    <Trans i18nKey='checkout.details.payment.promoCode.title'>Promo Code</Trans>
                </Modal.Header>
                <Modal.Content>
                    <Form error={errorMessage != null}>
                        <Message error>{this.handleMessages(errorMessage)}</Message>
                        <Form.Input
                            error={this.state.promoCode.error}
                            type='text'
                            name='promoCode'
                            id='checkout-promoCode-form-promoCode'
                            onChange={this.handleChange}
                            label={t('checkout.promoCode.form.promoCode.label')}
                            placeholder={t('checkout.promoCode.form.promoCode.placeholder')}
                            value={promoCode}
                            required
                        />
                    </Form>
                </Modal.Content>
                {/* <Modal.Content>
          <Input fluid />
        </Modal.Content> */}
                <Modal.Actions>
                    <Button basic onClick={this.handleClose}>
                        <Trans i18nKey='checkout.promoCode.form.button.cancel'>Cancel</Trans>
                    </Button>
                    <Button positive icon labelPosition='right' onClick={this.handleSubmit}>
                        <Trans i18nKey='checkout.promoCode.form.button.add'>Add Promo Code</Trans>
                        <Icon name='check' />
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withTranslation('translations')(AddCartPromoCodeModalView);
