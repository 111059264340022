// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { EntityData, Item } from 'store/types';
import { getItem } from 'store/utilities';

import PreviewItemView from './PreviewItemView';

type Props = {
    item: EntityData<Item>,
    itemId: number,
    merchantId: ?number,
    loadItemByItemId: typeof actions.loadItemByItemId
};

class PreviewItemContainer extends Component<Props> {
    componentDidMount() {
        const { item, itemId, loadItemByItemId } = this.props;

        if (!item.entity || !item.entity.merchant) {
            loadItemByItemId({ itemId, fields: ['pictures', 'merchant', 'store', 'is_liked', 'comments'] }).catch((error) => console.error(error));
        }
    }

    render() {
        const { item, merchantId } = this.props;
        const product = item.entity;

        if (product) {
            if (product.merchant === merchantId) {
                return <PreviewItemView product={product} />;
            }
            return <Redirect to={`/${product.slug}`} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state, { match }) => {
    const { itemId } = match.params;
    return {
        itemId,
        item: getItem(state, itemId),
        merchantId: state.merchantData.user
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadItemByItemId: wrapAPIDispatch(dispatch, actions.loadItemByItemId)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PreviewItemContainer);
