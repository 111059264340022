// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { EntityData, User } from 'store/types';

import MyStoreItemsView from './MyStoreItemsView';

type Props = {
    merchant: EntityData<User>,
    loadItems: typeof actions.loadMyStoreItems
};

class MyStoreItemsContainer extends Component<Props> {
    componentDidMount() {
        this.props.loadItems({ limit: 10, offset: 0 }).catch((err) => console.error(err));
    }

    loadMoreItems = () => {
        const { items, loadItems } = this.props;
        const { isLoading, active, activeCount, banned, bannedCount, inactive, inactiveCount, soldout, soldoutCount } = items;
        const mostLoaded = Math.max(active.length, banned.length, inactive.length, soldout.length);
        const mostTotal = Math.max(activeCount, bannedCount, inactiveCount, soldoutCount);
        if (!isLoading && mostTotal > mostLoaded) {
            loadItems({ limit: 10, offset: mostLoaded });
        }
    };

    render() {
        return <MyStoreItemsView loadMoreItems={this.loadMoreItems} {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    items: state.merchantData.items
});

const mapDispatchToProps = (dispatch) => ({
    loadItems: wrapAPIDispatch(dispatch, actions.loadMyStoreItems)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreItemsContainer);
