// @flow
import React from 'react';
import { connect } from 'react-redux';
import { getIsLoggedIn, getUser } from 'store/utilities';
import { openModal } from 'store/actions';
import { ModalNames } from 'store/actions/ui';
import * as actions from 'store/actions';

import TopBarView from './TopBarView';

function TopBarContainer(props) {
    return <TopBarView {...props} />;
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        user: getUser(state, state.userData.user),
        merchant: getUser(state, state.merchantData.user),
        quantity: state.userData.cart.cartItems.length,
        totalUnreadMessageUser: state.userData.conversations.totalUserUnread,
        totalUnreadMessageMerchant: state.merchantData.conversations.totalUserUnread
    };
};
const mapDispatchToProps = (dispatch) => ({
    onClickLogInButton: () => dispatch(openModal(ModalNames.LOGIN)),
    onLogout: () => dispatch(actions.logoutUser()),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBarContainer);
