// @flow
import { wrapAPIActionCreator, Schemas, API_DATA, Authentication } from 'services/api';

export type LoadLandingComponentEntitiesParams = {
    componentId: number,
    offset?: number,
    limit?: number,
    sort?: string,
    categoryId?: number,
    onlyBlee?: boolean,
    collection?: ?number
};

export const LOAD_LANDING_COMPONENT_ENTITIES = 'LOAD_LANDING_COMPONENT_ENTITIES';
export const loadLandingComponentEntities = wrapAPIActionCreator(
    ({ componentId, offset, limit, sort, categoryId, onlyBlee = false, collection = undefined }: LoadLandingComponentEntitiesParams) => ({
        [API_DATA]: {
            type: LOAD_LANDING_COMPONENT_ENTITIES,
            endpoint: `/v3/landing_components/${componentId}/entities`,
            optionalAuthentication: Authentication.User,
            endpointParams: {
                limit,
                offset,
                sort,
                categoryId,
                onlyBlee,
                collection,
                wantTotalCount: true
                // wantTotalCount: undocumented parameter which makes the API call
                // return the whole component rather than just array of entities
                // (still allows for pagination)
            },
            preprocess: ({ entities, ...rest }) => ({ component: { ...rest }, entities }),
            schema: {
                component: Schemas.landingComponent,
                entities: Schemas.landingComponentEntitiesSibling
            }
        }
    })
);
