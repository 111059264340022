import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button, Form, Message } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const isOpen = useSelector((state) => (state.ui.modals[actions.ModalNames.FORGOT_PASSWORD] || {}).isOpen);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleBlur = () => null;
    const handleClose = () => dispatch(actions.closeModal(actions.ModalNames.FORGOT_PASSWORD));
    const handleSubmit = () => {
        setLoading(true);
        wrapAPIDispatch(
            dispatch,
            actions.forgotPassword
        )({ email })
            .then(() => {
                setLoading(false);
                dispatch(actions.closeModal(actions.ModalNames.FORGOT_PASSWORD));
                dispatch(actions.openModal(actions.ModalNames.RESET_EMAIL_SENT));
            })
            .catch((err) => {
                setLoading(false);
                setErrorMessage(err.message);
            });
    };
    const handleMount = () => {
        setErrorMessage(null);
        setEmail('');
    };

    const handleErrorMessage = (message: string) => {
        switch (message) {
            case 'InvalidEmail':
                return 'Neispravna email adresa.';
            case 'UserNotFound':
                return 'Korisnik nije pronađen.';
            default:
                return message;
        }
    };


    return (
        <Modal size='mini' dimmer='inverted' centered={true} closeOnDimmerClick={true} open={isOpen} onClose={handleClose} onMount={handleMount}>
            <Form style={{ padding: 20 }} error={errorMessage !== null}>
                <p style={{ fontSize: 18, fontWeight: 'bold', marginTop: 0 }}>{t('resetPasswordForm.title')}</p>
                <Message error>{handleErrorMessage(errorMessage)}</Message>
                <Form.Field>
                    <label>{t('resetPasswordForm.email.label')}</label>
                    <input
                        type='text'
                        id='forgot-password'
                        placeholder={t('resetPasswordForm.email.placeholder')}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onBlur={handleBlur}
                        disabled={loading}
                    />
                </Form.Field>
                <Form.Field>
                    <Button secondary loading={loading} disabled={!email.length || loading} onClick={handleSubmit}>
                        {t('resetPasswordForm.submit')}
                    </Button>
                    <Button style={{ marginLeft: 20 }} onClick={handleClose}>
                        {t('resetPasswordForm.cancel')}
                    </Button>
                </Form.Field>
            </Form>
        </Modal>
    );
};

export default ForgotPassword;
