// @flow
import React from 'react';
import { Grid, List, Header, Loader } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utilities/format';

function MyStoreDashboard({ orders, items, activity, sales }) {
    const activityLoaded = activity.newFollowersCount !== undefined;

    return (
        <Grid>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2' size='medium'>
                    <Trans i18nKey='myStore.dashboard.storeActivity.header'>Store Activity</Trans>
                </Header>
                {activityLoaded ? (
                    <List>
                        <List.Item as={Link} to='/my-store/inbox'>
                            {activity.newMessagesCount} <Trans i18nKey='myStore.dashboard.storeActivity.newMessages'>New Messages</Trans>
                        </List.Item>
                        {/* <List.Item as="a">
                {activity.newCommentsCount}
                {' '}
                <Trans i18nKey="myStore.dashboard.storeActivity.newComments">New Comments</Trans>
              </List.Item>
              <List.Item as="a">
                {activity.newFollowersCount}
                {' '}
                <Trans i18nKey="myStore.dashboard.storeActivity.newFollowers">New Followers</Trans>
              </List.Item> */}
                    </List>
                ) : (
                    <Loader active />
                )}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2' size='medium'>
                    <Trans i18nKey='myStore.dashboard.sales.header'>Sales</Trans>
                </Header>
                {activityLoaded ? (
                    <List>
                        <List.Item>
                            <Trans i18nKey='myStore.dashboard.sales.total'>Total</Trans> {formatPrice(sales.totalSalesCount)}
                        </List.Item>
                        <List.Item>
                            <Trans i18nKey='myStore.dashboard.sales.released'>Released</Trans> {formatPrice(sales.releasedSalesCount)}
                        </List.Item>
                        <List.Item>
                            <Trans i18nKey='myStore.dashboard.sales.toRelease'>To Release</Trans> {formatPrice(sales.toReleaseSalesCount)}
                        </List.Item>
                    </List>
                ) : (
                    <Loader active />
                )}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2' size='medium'>
                    <Trans i18nKey='myStore.dashboard.items.header'>Items</Trans>
                </Header>
                {activityLoaded ? (
                    <List>
                        <List.Item as={Link} to='/my-store/items'>
                            {items.activeCount} <Trans i18nKey='myStore.dashboard.items.active'>Active</Trans>
                        </List.Item>
                        <List.Item as={Link} to='/my-store/items/banned'>
                            {items.bannedCount} <Trans i18nKey='myStore.dashboard.items.banned'>Banned</Trans>
                        </List.Item>
                        <List.Item as={Link} to='/my-store/items/sold-out'>
                            {items.soldoutCount} <Trans i18nKey='myStore.dashboard.items.soldOut'>Sold Out</Trans>
                        </List.Item>
                        <List.Item as={Link} to='/my-store/items/inactive'>
                            {items.inactiveCount} <Trans i18nKey='myStore.dashboard.items.inactive'>Inactive</Trans>
                        </List.Item>
                    </List>
                ) : (
                    <Loader active />
                )}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2' size='medium'>
                    <Trans i18nKey='myStore.dashboard.orders.header'>Orders</Trans>
                </Header>
                {activityLoaded ? (
                    <List>
                        <List.Item as={Link} to='/my-store/orders/open'>
                            {orders.openCount} <Trans i18nKey='myStore.dashboard.orders.active'>Active</Trans>
                        </List.Item>
                        <List.Item as={Link} to='/my-store/orders/delivered'>
                            {orders.deliveredCount} <Trans i18nKey='myStore.dashboard.orders.delivered'>Delivered</Trans>
                        </List.Item>
                        <List.Item as={Link} to='/my-store/orders/cancelled'>
                            {orders.cancelledCount} <Trans i18nKey='myStore.dashboard.orders.cancelled'>Cancelled</Trans>
                        </List.Item>
                    </List>
                ) : (
                    <Loader active />
                )}
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(MyStoreDashboard);
