// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getEntityDataBySlug } from 'store/utilities';
import withNavigationAid from 'utilities/withNavigationAid';
import type { EntityData, Category, LandingPage } from 'store/types';
import type { NavigationAid } from 'utilities/withNavigationAid';

import TopCategoryView from './TopCategoryView';

type Props = {
    // from route params
    slug: string,
    parentSlug: string,
    page: number,
    navigationAid: NavigationAid,
    // from store
    sort: string,
    category: EntityData<Category>,
    landingPage: EntityData<LandingPage>,
    loadCategoryItemsBySlug: typeof actions.loadCategoryItemsBySlug,
    loadLandingPage: typeof actions.loadLandingPage,
    bazar: string
};

type State = {
    isLoadingLandingPage: boolean
};

class TopCategoryContainer extends Component<Props, State> {
    state = {
        isLoadingLandingPage: false
    };

    componentDidMount() {
        this.loadData();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { landingPage } = nextProps;
        const needLandingPage = !landingPage.isFetching && (landingPage.entity == null || landingPage.entity.components == null);
        if (nextProps.slug !== this.props.slug || needLandingPage) {
            this.loadData(nextProps);
        }
    }

    loadData = ({ category, loadLandingPage, bazar } = this.props) => {
        const { isLoadingLandingPage } = this.state;
        if (category.isFetching || category.entity == null) {
            return;
        }
        if (isLoadingLandingPage) {
            return;
        }
        this.setState({ isLoadingLandingPage: true });
        loadLandingPage({ slug: 'categories', categoryId: category.entity.id , bazar })
            .then(() => this.setState({ isLoadingLandingPage: false }))
            .catch(() => this.setState({ isLoadingLandingPage: false }));
    };

    loadMoreItems = ({ offset, limit }) => {
        const { loadCategoryItemsBySlug, slug, navigationAid, sort , bazar } = this.props;
        return loadCategoryItemsBySlug({
            slug,
            offset,
            limit,
            sort,
            onlyBlee: navigationAid.isBlee,
            bazar
            // collection: navigationAid.collection
        }).then(({ result }) => result.items);
    };

    render() {
        const { ...rest } = this.props;
        return <TopCategoryView loadMoreItems={this.loadMoreItems} {...rest} />;
    }
}

const mapStateToProps = (state, { match }) => {
    let bazar = '';
    if (state.router.location.query.bazar) {
        bazar = state.router.location.query.bazar;
    }
    const { slug } = match.params;

    const category = getEntityDataBySlug(state, slug);
    const { sort } = state.filters;
    const landingPage = getEntityDataBySlug(state, 'categories');

    return {
        slug,
        category,
        landingPage,
        sort,
        bazar: bazar
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadCategoryItemsBySlug: wrapAPIDispatch(dispatch, actions.loadCategoryItemsBySlug),
    loadLandingPage: wrapAPIDispatch(dispatch, actions.loadLandingPage)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withNavigationAid)(TopCategoryContainer);
