// @flow
import * as ActionTypes from '../../../actions';

const initialState = {};

function conversations(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_PRODUCT_CONVERSATIONS: {
            if (action.status === 'request') {
                const { itemId } = action.reducerParams;
                return {
                    ...state,
                    [itemId]: {
                        isLoading: true
                    }
                };
            }
            if (action.status === 'success') {
                const { conversations: conversationId } = action.payload.result;
                const { itemId } = action.reducerParams;
                return {
                    ...state,
                    [itemId]: {
                        isLoading: false,
                        conversationId
                    }
                };
            }
            if (action.status === 'error') {
                const { itemId } = action.reducerParams;
                return {
                    ...state,
                    [itemId]: {
                        isLoading: false
                    }
                };
            }
            break;
        }
        // case ActionTypes.POST_MESSAGE: {
        //   if (action.status === 'success') {
        //     const { itemId } = action.reducerParams;
        //     const messageId = action.payload.result;
        //     const message = action.payload.entities.messages[messageId];
        //     const { conversationKey } = message;
        //     return {
        //       ...state,
        //       [itemId]: {
        //         isLoading: false,
        //         conversationId: conversationKey,
        //       },
        //     };
        //   }
        // }
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default conversations;
