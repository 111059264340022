// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Image, Header } from 'semantic-ui-react';

import customerIcon from 'styles/assets/icons/customer-icon.svg';
import storeIcon from 'styles/assets/icons/store-icon.svg';
import agentIcon from 'styles/assets/icons/agent-icon.svg';
import { UserTypes } from 'store/enums';

type Props = {
    conversation: {}
};

function MessagesConversationMenuItemView({ conversation, lastMessage, user }: Props) {
    const missingUserImage = user.userType === UserTypes.SHOPPER ? customerIcon : user.userType === UserTypes.MERCHANT ? storeIcon : agentIcon;
    return (
        <Grid className='auto tensed grid-align-items-center'>
            <Grid.Column className='col-sm-3 col-4'>
                <Image
                    className='circular-image'
                    wrapped
                    src={user.pictureThumb || missingUserImage}
                    onError={(event) => {
                        event.target.src = missingUserImage;
                    }}
                />
            </Grid.Column>
            <Grid.Column className='col-sm-13 col-12'>
                <Grid className='auto very-tensed'>
                    <Grid.Column className='col-row'>
                        <Header size='tiny' className='margin-0 line-height-1 text-ellipsis'>
                            {user.alias}
                        </Header>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <div className='color-de-emphasized font-size-small line-height-1 text-ellipsis'>{conversation.time}</div>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default MessagesConversationMenuItemView;
