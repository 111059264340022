// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Comment, Loader, Grid, Button } from 'semantic-ui-react';

import type { EntityData, Comment as CommentType } from 'store/types';

import CommentItem from './components/Comment';
import SendMessageForm from './components/SendCommentForm';

type Props = {
    onMoreComments: () => void,
    isLoadingMoreComments: boolean,
    comments: EntityData<CommentType>[],
    hasMoreComments: boolean
};

function CommentsView(props: Props) {
    const { comments, onMoreComments = () => {}, isLoadingMoreComments, hasMoreComments } = props;
    const sortedComments = comments.sort((a, b) => new Date(b.entity.createdAt) - new Date(a.entity.createdAt));
    const allEntititiesHaveIds = sortedComments.every(({entity}) => entity?.id);

    return (
        <Grid className='auto tensed'>
            <Grid.Column className='col-sm-16 col-9'>
                {hasMoreComments && (
                    <Button fluid onClick={onMoreComments}>
                        <Trans i18nKey='comments.loadMore'>Load more comments</Trans>
                    </Button>
                )}
                {isLoadingMoreComments && (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column />
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <Loader active />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                )}
                <Comment.Group size='large'>
                    {sortedComments.map(({ entity }, index) => (
                        <CommentItem key={allEntititiesHaveIds ? entity.id : index} comment={entity} />
                    ))}
                </Comment.Group>
            </Grid.Column>
            <Grid.Column className='col-sm-16 col-7'>
                <SendMessageForm {...props} />
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(CommentsView);
