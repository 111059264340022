// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Segment, Image, Header } from 'semantic-ui-react';

import missingImage from 'styles/assets/images/missing-image.svg';
import missingStoreImage from 'styles/assets/images/missing-store-image.svg';

type Props = {
    item: EntityData<Item>,
    inverted: boolean,
    preventSlickClickDuringChange: () => void
};

function BazarTeaserView({ landingPage, inverted, preventSlickClickDuringChange }: Props) {
    if (landingPage.isFetching || landingPage.entity == null) {
        return null;
    }

    const { title, imageFull, slug, id } = landingPage.entity;
    const bazarURL = `/${slug}?bazar=${slug}`;

    return (
        <Segment className='store-teaser simple'>
            <Link to={bazarURL} onClick={preventSlickClickDuringChange || null}>
                <Image
                    className='square-image'
                    wrapped
                    alt={title}
                    src={imageFull || missingStoreImage}
                    onError={(event) => {
                        event.target.src = missingStoreImage;
                    }}
                />
            </Link>
            <Header as='h3' className='store-teaser-header'>
                <Link to={bazarURL} onClick={preventSlickClickDuringChange || null}>
                    {title}
                </Link>
            </Header>
        </Segment>
    );
}

export default withTranslation('translations')(BazarTeaserView);
