import React from 'react';

import ProfilePicture from 'scenes/MyStore/components/MyStoreProfilePicture';
import MyStoreCover from 'scenes/MyStore/components/MyStoreCover';

const StoreProfileImages = () => {
    return (
        <div className='store-profile-images-container'>
            <ProfilePicture />
            <MyStoreCover btnFit />
        </div>
    );
};

export default StoreProfileImages;
