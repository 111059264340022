// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getStore, getCategoryAttribute } from 'store/utilities';

import EditItemFormView from './EditItemFormView';

type Props = {
    settings: {
        updatedAt: string
    },
    isLoadingSettings: boolean,
    updateItem: typeof actions.updateItem,
    loadMyStoreSettings: typeof actions.loadMyStoreSettings
};

class ItemFormContainer extends Component<Props> {
    componentDidMount() {
        const { settings, isLoadingSettings, loadMyStoreSettings } = this.props;
        if (!settings.updatedAt && !isLoadingSettings) {
            loadMyStoreSettings().catch((error) => console.error(error));
        }
    }

    handleSubmit = (data) => {
        const { updateItem } = this.props;
        return updateItem(data);
    };

    render() {
        return <EditItemFormView onSubmit={this.handleSubmit} {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    const { settings } = state.merchantData;
    const stores = settings.stores ? settings.stores.map((id) => getStore(state, id).entity) : [];
    return {
        isLoadingSettings: settings.isLoading,
        stores,
        settings,
        getFormValues: (formId) => state.ui.forms[formId],
        getCategoryAttribute: (id) => getCategoryAttribute(state, id).entity
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    updateItem: wrapAPIDispatch(dispatch, actions.updateItem),
    loadMyStoreSettings: wrapAPIDispatch(dispatch, actions.loadMyStoreSettings),
    resetEntity: ({ entityType, entityId }) => dispatch(actions.resetEntity({ entityType, entityId }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemFormContainer);
