// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Container, Loader } from 'semantic-ui-react';

import LandingComponent from 'components/LandingComponent';

type Props = {
    isLoading?: boolean,
    components: number[],
    tab: string
};

const backgroundImages = {
    blee: 'https://d1a071ac7yeh2t.cloudfront.net/static/blee_bg.jpg'
};

function LandingView({ isLoading, tab, components }: Props) {
    const backgroundImageUrl = backgroundImages[tab];
    if (isLoading) {
        return (
            <Container>
                <Loader active inline='centered' />
            </Container>
        );
    }
    console.log({components})
    return (
        <div className='landing-background' style={{ backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : undefined }}>
            <Container fluid className='landing-container-fluid'>
                {components.map((id) => (
                    <LandingComponent key={id} landingComponentId={id} />
                ))}
            </Container>
        </div>
    );
}

LandingView.defaultProps = {
    isLoading: false
};

export default compose(withTranslation('translations'))(LandingView);
