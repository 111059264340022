// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';

import * as actions from 'store/actions';
import { getItem } from 'store/utilities';
import type { EntityData, Conversation } from 'store/types';

import { MessageTypes } from 'store/enums';
import MakeOfferFormView from './MakeOfferFormView';

type Props = {
    conversation: EntityData<Conversation>,
    myId: number,
    productId: number
};

class MakeOfferFormContainer extends Component<Props> {
    sendMessage = ({ message, offer }) => {
        const { postMessage, conversation, myId, authentication = Authentication.User } = this.props;

        const messageParams = {
            message,
            myId,
            authentication,
            offer,
            typeId: MessageTypes.OFFER
        };

        if (conversation && conversation.entity) {
            messageParams.itemId = conversation.entity.item;
            messageParams.recipientId = conversation.entity.user;
        }

        return postMessage(messageParams);
    };

    render() {
        const { onPostMessageSuccess, conversation } = this.props;
        return (
            <MakeOfferFormView
                {...this.props}
                formId={`makeOffer.form.conversation.${conversation.entity.conversationKey}`}
                translationId='makeOffer.form'
                sendMessage={this.sendMessage}
                onPostMessageSuccess={onPostMessageSuccess}
            />
        );
    }
}

const mapStateToProps = (state, { productId }) => ({
    getFormValues: (id) => state.ui.formValues[id],
    product: getItem(state, productId)
});

const mapDispatchToProps = (dispatch) => ({
    postMessage: wrapAPIDispatch(dispatch, actions.postMessage),
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MakeOfferFormContainer);
