// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Dropdown, Icon, Input, Table, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import CommonForm from 'components/CommonForm';

type Props = {
    t: (string) => string
};

type State = {
    template: string,
    excelFile: {
        value: string,
        error: boolean
    }
};

class BulkImportFormView extends CommonForm<Props, State> {
    state = {
        template: '',
        excelFile: {
            value: '',
            error: false
        }
    };

    handleChangeFile = (event, data) => {
        const file = event.target.files[0];
        this.setState({ excelFile: file }, () => this.props.handleUploadExcelDocument(this.state.excelFile));
        event.target.value = null;
    };

    handlePublishItems = () => {
        this.props.handlePublishItems(this.state.excelFile);
    };

    render() {
        const { isUploadingExcel, isPublishingItems, isPublished, isPublishedSuccessfully, API_SERVER, uploadResults } = this.props;
        const templateUrl = `${API_SERVER}/v3/bulk-import/download/`;
        const presentationUrl = `${API_SERVER}/static/bulkoperations/MB_BulkImport_UsersGuide.pdf`;
        const categoriesUrl = `${API_SERVER}/static/bulkoperations/MyBazar-Categories.xlsx`;
        const publishItems = uploadResults.length > 0 && uploadResults.filter((row) => row.errors.length !== 0).length === 0;

        return (
            <div className='sell-item'>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Grid className='auto tensed'>
                                    <Grid.Column>
                                        <Form.Field>
                                            <a className={`ui button fluid primary`} style={{ color: 'white' }} href={templateUrl}>
                                                <Trans i18nKey='downloadTemplate.button'>Download Template</Trans>
                                            </a>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Button
                                                as='label'
                                                icon
                                                labelPosition='left'
                                                htmlFor='my-profile-cover-photo-file'
                                                disabled={isUploadingExcel}
                                                loading={isUploadingExcel}
                                            >
                                                <Icon name='upload' />
                                                <Trans i18nKey='uploadDocument.button'>Upload Document</Trans>
                                            </Button>
                                            <Input
                                                type='file'
                                                name='file'
                                                id='my-profile-cover-photo-file'
                                                className='store-logo-input'
                                                disabled={isUploadingExcel}
                                                onChange={this.handleChangeFile}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                <Grid className='auto tensed'>
                                    <Grid.Column width={8}>
                                        <Form.Field>
                                            <Button
                                                type='submit'
                                                fluid
                                                primary
                                                disabled={!publishItems || isPublishedSuccessfully}
                                                loading={isPublishingItems}
                                                onClick={this.handlePublishItems}
                                            >
                                                <Trans i18nKey='publishItems.button'>Publish Items</Trans>
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                            </Grid.Column>
                            <Grid.Column width={8} verticalAlign='middle'>
                                <Grid centered columns={3}>
                                    <Grid.Row centered columns={3}>
                                        <Grid.Column>
                                            <a
                                                className='ui button fluid'
                                                style={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}
                                                href={presentationUrl}
                                                target='_blank'
                                            >
                                                <Trans i18nKey='bulkImport.instructions'>How to use Bulk Import</Trans>
                                            </a>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <a className='ui button fluid' style={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }} href={categoriesUrl}>
                                                <Trans i18nKey='bulkImport.categories'>List of categories on MyBazar</Trans>
                                            </a>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                {isPublished && isPublishedSuccessfully && (
                    <Message positive>
                        <Message.Header>
                            <Trans i18nKey='bulkImport.published.success.header'>Successful Import</Trans>
                        </Message.Header>
                        <p>
                            <Trans i18nKey='bulkImport.published.success.msg'>
                                Your products have been successfully imported. You can see your new products<Link to='/my-store/items'>here</Link>.
                            </Trans>
                        </p>
                    </Message>
                )}

                {isPublished && !isPublishedSuccessfully && (
                    <Message error>
                        <Message.Header>
                            <Trans i18nKey='bulkImport.published.failed.header'>Failed Import</Trans>
                        </Message.Header>
                        <p>
                            <Trans i18nKey='bulkImport.published.failed.msg'>
                                Your data was not successfully imported. Please try again later or contact the site administrator.
                            </Trans>
                        </p>
                    </Message>
                )}

                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Trans i18nKey='bulkImport.table.category'>Category</Trans>
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Trans i18nKey='bulkImport.table.row'>Row</Trans> #
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                <Trans i18nKey='bulkImport.table.status'>Status</Trans>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {uploadResults.map((row) => (
                            <Table.Row key={`${row.category}-${row.idx}`}>
                                <Table.Cell>{row.category}.</Table.Cell>
                                <Table.Cell>{row.idx}</Table.Cell>
                                <Table.Cell>{row.errors.length > 0 ? row.errors.map((m, i) => <li style={{color:"#db2828"}} key={i + 1}> {m.toLowerCase()} </li>) : 'Validno'}</Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            </div>
        );
    }
}

export default withTranslation('translations')(BulkImportFormView);
