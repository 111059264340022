// @flow
import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { getLanguageOptions } from 'utilities/languages';

const LanguageSelectModalView = ({ onClick }) => (
    <Form>
        {getLanguageOptions().map((option) => (
            <Form.Field key={option.key}>
                <Button type='submit' basic fluid onClick={(event) => onClick(event, option.key)}>
                    <Trans i18nKey={`languageSelectForm.${option.text.toLowerCase()}`}>{option.text}</Trans>
                </Button>
            </Form.Field>
        ))}
    </Form>
);

export default withTranslation('translations')(LanguageSelectModalView);
