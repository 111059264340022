// @flow
import i18n from 'i18n';
import store from 'store';
import * as actions from 'store/actions';
import { ModalNames } from 'store/actions/ui';
import { COOKIE_LANGUAGE, setCookie, getCookie } from './cookies';

export const languageOptions = [
    {
        key: 'en',
        value: 'en',
        text: 'English'
    },
    {
        key: 'ms-MY',
        value: 'ms-MY',
        text: 'Malay'
    },
    {
        key: 'bs-BA',
        value: 'bs-BA',
        text: 'Bosnian'
    },
    {
        key: 'ar-SA',
        value: 'ar-SA',
        text: 'Arabic'
    },
    {
        key: 'tr-TR',
        value: 'tr-TR',
        text: 'Turkish'
    }
];

export const shortBy = [
    {
        key: 'en',
        text: 'Latest Added'
    },
    {
        key: 'ms-MY',
        text: 'Best Deals'
    },
    {
        key: 'bs-BA',
        text: 'Price : Low To High'
    },
    {
        key: 'ar-SA',
        text: 'Price: High To Low'
    }
];

const getEnabledLanguages = () => {
    const enabledLanguages = process.env.REACT_APP_ENABLED_LANGUAGES;
    return enabledLanguages ? enabledLanguages.split(' ') : [];
};

export const DEFAULT_LANGUAGE = 'en';

export const currencyOptions = [
    {
        key: 'KM',
        value: 'KM',
        text: 'Konvertibilna marka'
    }
];

export const isLanguageEnabled = (key) => getEnabledLanguages().indexOf(key) >= 0;

export const getLanguageOptions = () => {
    const enabledLanguages = getEnabledLanguages();
    return languageOptions.filter((option) => enabledLanguages.indexOf(option.key) >= 0);
};

export const syncLanguage = (accounts) => {
    const userId = accounts.result.accounts[0].user;
    const user = accounts.entities.users[userId];
    if (user) {
        if (user.locale) {
            if (user.locale !== i18n.language) {
                // user locale setting and i18n language out of sync, change if enabled
                if (!isLanguageEnabled(user.locale)) {
                    // reset user to locale to the current locale
                    store.dispatch(actions.setLocale(getCookie(COOKIE_LANGUAGE)));
                } else {
                    i18n.changeLanguage(user.locale);
                    setCookie(COOKIE_LANGUAGE, user.locale);
                    store.dispatch(actions.setLocale(user.locale));
                }
            }
        } else {
            const cookie = getCookie(COOKIE_LANGUAGE);
            if (!cookie) {
                // user has not yet selected language & cookie is not set, show popup on next frame
                showLanguageSelectionPopup();
            }
        }
    }
};

export const onPageRefresh = (isLoggedIn) => {
    if (!isLoggedIn) {
        const cookie = getCookie(COOKIE_LANGUAGE);
        if (!cookie) {
            // user is not logged in & cookie is not set, show language selection popup
            showLanguageSelectionPopup();
        }
    }
};

export const showLanguageSelectionPopup = () => {
    if (process.env.REACT_APP_FEATURE_LANGUAGE_SELECT_POPUP === 'true') {
        setTimeout(() => store.dispatch(actions.openModal(ModalNames.LANGUAGE_SELECT)), 0);
    }
};

export const changeLanguage = (language) => {
    store.dispatch(actions.setLocale(language));
    setCookie(COOKIE_LANGUAGE, language);

    setTimeout(() => {
        i18n.changeLanguage(language || 'en');
    }, 0);
};

const wrapPromise = (promise) => {
    let status = 'pending';
    let response;

    const suspender = promise.then(
        (res) => {
            status = 'success';
            response = res;
        },
        (err) => {
            status = 'error';
            response = err;
        }
    );
    const read = () => {
        switch (status) {
            case 'pending':
                throw suspender;
            case 'error':
                throw response;
            default:
                return response;
        }
    };

    return { read };
};

export const getLanguages = () => {
    const request = fetch(`https://api.locize.app/languages/${process.env.REACT_APP_LOCIZE_PROJECT_ID}`)
        .then((response) => response.json())
        .then((response) =>
            Object.keys(response).map((key) => ({
                key,
                value: key,
                text: response[key].name
            }))
        );
    return wrapPromise(request);
};
