// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_ITEM_BY_ITEM_ID ------------------
export type LoadItemByItemIdParams = {
    itemId: number,
    fields?: ('pictures' | 'merchant' | 'store' | 'is_liked' | 'comments')[]
};

export const LOAD_ITEM_BY_ITEM_ID = 'LOAD_ITEM_BY_ITEM_ID';
export const loadItemByItemId = wrapAPIActionCreator(({ itemId, fields = [] }: LoadItemByItemIdParams) => ({
    [API_DATA]: {
        type: LOAD_ITEM_BY_ITEM_ID,
        endpoint: `/v3/items/${itemId}`,
        optionalAuthentication: Authentication.Merchant,
        endpointParams: { fields: fields.join(',') },
        schema: {
            item: Schemas.item
        }
    }
}));

export default loadItemByItemId;
