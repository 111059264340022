// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

type Props = {
    src: string,
    contentHeight?: string
};

function EmbeddedPageView({ src, contentHeight }: Props) {
    return (
        <Container fluid className='container-flush'>
            <iframe
                style={{
                    overflow: 'hidden',
                    height: contentHeight,
                    width: '100%'
                }}
                title='embedded page'
                src={src}
                frameBorder='0'
            />
        </Container>
    );
}

EmbeddedPageView.defaultProps = {
    contentHeight: '100vw'
};

export default EmbeddedPageView;
