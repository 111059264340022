// @flow
import React from 'react';

import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header, Button, Icon } from 'semantic-ui-react';
import Product from 'components/Product';

function PreviewItemView({ product }) {
    return (
        <Container>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    <Grid className='auto grid-align-item-center'>
                        <Grid.Column>
                            <Header as='h1'>
                                <Trans i18nKey='previewItem.header'>Preview Item</Trans>
                            </Header>
                        </Grid.Column>
                        <Grid.Column className='col-shrink'>
                            <Button as={Link} to={`/edit-item/${product.id}`} basic icon labelPosition='left'>
                                <Icon name='edit' />
                                <Trans i18nKey='previewItem.edit'>Edit Item</Trans>
                            </Button>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
                <Grid.Column className='col-row'>
                    <Product product={product} preview />
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default withTranslation('translations')(PreviewItemView);
