// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Sidebar as SidebarComponent } from 'semantic-ui-react';

import SidebarClose from './components/SidebarClose';
import SidebarProfile from './components/SidebarProfile';
import SidebarMenu from './components/SidebarMenu';

type Props = {
    active: boolean,
    toggle: () => void,
    isLoggedIn: boolean,
    onLogout: () => void,
    user: EntityData<User>,
    merchantUser: EntityData<User>
};

function Sidebar({ active, toggle, isLoggedIn, onLogout, user, merchantUser }: Props) {
    return (
        <SidebarComponent className='main-sidebar' animation='overlay' width='wide' visible={active}>
            <SidebarClose close={toggle} />
            <Grid className='tensed'>
                <Grid.Row>
                    <Grid.Column>
                        <SidebarProfile isLoggedIn={isLoggedIn} user={user} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <SidebarMenu isLoggedIn={isLoggedIn} onLogout={onLogout} user={user} merchantUser={merchantUser} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </SidebarComponent>
    );
}

export default Sidebar;
