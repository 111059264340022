// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import type { EntityData, LandingComponent } from 'store/types';

import { wrapAPIDispatch } from 'services/api';

import LandingItemListView from './LandingItemListView';

type Props = {
    component: EntityData<LandingComponent>,
    // from store
    categoryId: ?number,
    sort: string,
    loadLandingComponentEntities: typeof actions.loadLandingComponentEntities
};

class LandingItemListContainer extends Component<Props> {
    loadMoreItems = ({ offset, limit }) => {
        const { component, categoryId, sort, loadLandingComponentEntities } = this.props;

        if (component.isFetching || component.entity == null) {
            return Promise.reject(new Error('Loading...'));
        }

        const { id: componentId } = component.entity;

        return loadLandingComponentEntities({
            componentId,
            offset,
            limit,
            sort,
            categoryId
        }).then(({ result }) => result.entities.map(({ id }) => id));
    };

    render() {
        const { component, ...rest } = this.props;
        return <LandingItemListView loadMoreItems={this.loadMoreItems} {...{ component, ...rest }} />;
    }
}

const mapStateToProps = (state) => {
    const { categoryId } = state.filters;
    const { sort } = state.filters;
    return { categoryId, sort };
};

const mapDispatchToProps = (dispatch) => ({
    loadLandingComponentEntities: wrapAPIDispatch(dispatch, actions.loadLandingComponentEntities)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LandingItemListContainer);
