// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'store/actions';

import itemsByKeyword from './itemsByKeyword';
import tagsByKeyword from './tagsByKeyword';
import storesByKeyword from './storesByKeyword';
import usersByKeyword from './usersByKeyword';
import currentTabState from './currentTabState';

function searchByKeyword(state = { searchByKeywordIsLoading: false }, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state,
                    searchByKeywordIsLoading: true
                };
            }
            if (action.status === 'success') {
                return {
                    ...state,
                    ...action.payload.result,
                    searchByKeywordIsLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    searchByKeywordIsLoading: false
                };
            }
            break;
        default:
            break;
    }
    return state;
}

export default combineReducers({
    itemsByKeyword,
    searchByKeyword,
    tagsByKeyword,
    storesByKeyword,
    usersByKeyword,
    currentTabState
});
