// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import type { EntityData, Item } from 'store/types';

import missingImage from 'styles/assets/images/missing-image.svg';

type Props = {
    item: EntityData<Item>
};

function ProductDetailListItemImage({ item }: Props) {
    const { pictures } = item.entity || {};
    const imageSrc = pictures && pictures[0] && pictures[0].imageThumb;
    return (
        <Image
            className='square-image'
            wrapped
            src={imageSrc || missingImage}
            onError={(event) => {
                event.target.src = missingImage;
            }}
        />
    );
}

export default ProductDetailListItemImage;
