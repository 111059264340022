// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { EntityData, User } from 'store/types';

import MyStoreSettingsView from './MyStoreSettingsView';

type Props = {
    merchant: EntityData<User>,
    settings: Object,
    loadMyStoreSettings: typeof actions.loadMyStoreSettings
};

class MyStoreSettingsContainer extends Component<Props> {
    UNSAFE_componentWillReceiveProps(props) {
        const { settings, loadMyStoreSettings } = props;
        // if (!settings.createdAt && !settings.isLoading) {
        //   loadMyStoreSettings({ fields: ['cover_photos', 'pictures', 'comments', 'merchant', 'store', 'is_liked'] })
        //     .catch(error => console.error(error));
        // }
    }

    render() {
        const { settings } = this.props;

        if (settings.isLoading) {
            return <Loader active />;
        }
        if (settings.createdAt) {
            return <MyStoreSettingsView {...this.props} />;
        }
        return null;
    }
}

const mapStateToProps = (state) => {
    const { settings } = state.merchantData;
    return {
        settings
    };
};

const mapDispatchToProps = (dispatch) => ({
    // loadMyStoreSettings: wrapAPIDispatch(dispatch, actions.loadMyStoreSettings),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreSettingsContainer);
