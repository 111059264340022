// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import { wrapAPIDispatch } from 'services/api';
// import * as actions from 'store/actions';
import { getItem } from 'store/utilities';

import MessagesView from './MessagesView';

type Props = {};

class MessagesContainer extends Component<Props> {
    render() {
        return <MessagesView {...this.props} />;
    }
}

const mapStateToProps = (state, { productId }) => ({
    product: getItem(state, productId)
});

export default compose(connect(mapStateToProps))(MessagesContainer);
