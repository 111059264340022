// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';

import MyProfilePictureView from './MyProfilePictureView';

type Props = {
    updateProfilePicture: typeof actions.updateProfilePicture,
    loadMyAccounts: typeof actions.loadMyAccounts
};

class MyProfilePictureContainer extends Component<Props> {
    state = {
        isUploadingPicture: false
    };

    onSubmitProfilePicture = (profilePictureUrl) => {
        this.setState({ isUploadingPicture: true });

        const { updateProfilePicture, loadMyAccounts } = this.props;
        updateProfilePicture({ profilePictureUrl })
            .then(loadMyAccounts)
            .then(() => this.setState({ isUploadingPicture: false }))
            .catch(() => this.setState({ isUploadingPicture: false }));
    };

    render() {
        const { user } = this.props;
        const { picture } = user;
        return <MyProfilePictureView onSubmitProfilePicture={this.onSubmitProfilePicture} profilePictureUrl={picture} isUploadingPicture={this.state.isUploadingPicture} />;
    }
}

const mapStateToProps = (state) => ({
    user: getUser(state, state.userData.user).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateProfilePicture: wrapAPIDispatch(dispatch, actions.updateProfilePicture),
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyProfilePictureContainer);
