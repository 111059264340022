// @flow
import Container from './BankAccountModalContainer';

export type FormValues = {
    accountHolder: string,
    accountNo: string,
    bankAddress: string,
    bankName: string,
    postCode: string,
    country: number,
    state: number,
    city: number
};

export { default as Container } from './BankAccountModalContainer';
export { default as View } from './BankAccountModalView';

export default Container;
