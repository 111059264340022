// @flow
import React from 'react';
import { Form, Header, Dropdown, Button } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import BankAccountList from './components/BankAccountList';

function MyStoreBankAccountView(props) {
    const { bankAccounts, onOpenAddBankAccount, onOpenEditBankAccount, primaryBankAccount, onSelectBankAccount } = props;

    const list = [
        { id: '1', bankName: 'Raiffaisen', address: 'Jalan Bandar Melawati 355', postCode: '53100 Kuala Lumpur', state: 'Malasyia', city: {}, country: {} },
        { id: '2', bankName: 'Raiffaisen', address: 'Jalan Bandar Melawati 355', postCode: '53100 Kuala Lumpur', state: 'Malasyia', city: {}, country: {} }
    ];

    return (
        <Form.Field>
            <Form.Field>
                <Header as='h2'>
                    <Trans i18nKey='myStore.settings.bankAccount.header'>Bank Accounts</Trans>
                </Header>
            </Form.Field>
            {bankAccounts && (
                <Form.Field style={{ display: 'none' }}>
                    <label htmlFor='my-store-settings-bank-accounts'>
                        <Trans i18nKey='myStore.settings.bankAccount.label'>Bank Accounts</Trans>
                    </label>
                    <Dropdown
                        fluid
                        multiple
                        selection
                        disabled
                        name='bankAccounts'
                        id='my-store-settings-bank-accounts'
                        options={bankAccounts}
                        defaultValue={bankAccounts.map((bankAccount) => bankAccount.value)}
                    />
                </Form.Field>
            )}
            <BankAccountList list={list} onOpenEditBankAccount={onOpenEditBankAccount} primaryBankAccount={primaryBankAccount} onSelectBankAccount={onSelectBankAccount} />
            <Form.Field>
                <Button type='button' fluid onClick={onOpenAddBankAccount}>
                    <Trans i18nKey='myStore.settings.bankAccount.add.trigger'>Add Bank Account</Trans>
                </Button>
            </Form.Field>
        </Form.Field>
    );
}

export default withTranslation('translations')(MyStoreBankAccountView);
