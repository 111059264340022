// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/actions';

import { getStore } from 'store/utilities';

import ItemFormDeliveryView from './ItemFormDeliveryView';

type Props = {};

class ItemFormDeliveryContainer extends Component<Props> {
    render() {
        return <ItemFormDeliveryView {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    //console.log('ItemFormDeliveryContainer stores', state.merchantData.settings.stores);
    return {
        stores: (state.merchantData.settings.stores || []).map((id) => getStore(state, id))
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemFormDeliveryContainer);
