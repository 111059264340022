// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header, Label } from 'semantic-ui-react';

import ProductTeaser from 'components/ProductTeaser';
import StoreTeaser from 'components/TeaserCarousel/components/StoreTeaser';
import BazarTeaser from 'components/BazarTeaser';
import SortablePaginatedGrid from 'components/SortablePaginatedGrid';

import type { EntityData, LandingComponent } from 'store/types';

type Props = {
    component: EntityData<LandingComponent>,
    sort: string,
    loadMoreItems: ({ offset: number, limit: number }) => Promise<number[]>
};

const renderItem = (props) => {
    const { id, schema } = props;
    switch (schema) {
        case 'items':
            return <ProductTeaser itemId={id} />;
        case 'stores':
            return <StoreTeaser merchantId={id.merchant} itemIds={id.items.slice(0, 3)} />;
        case 'landingPages':
            return <BazarTeaser landingPageId={id} />;
        default:
            console.error(`LandingViewAll renderItem: unimplemented schema ${schema}`);
            return null;
    }
};

const keyExtractor = ({ id, schema }) => `${schema}-${id.merchant || id}`;

function LandingViewAllView({ component, componentId, sort, loadMoreItems, t }: Props) {
    const { entity } = component;
    const title = entity ? t(`landingComponent.viewAll.${entity.id}.label`, { defaultValue: entity.title }) : '';
    return (
        <Container style={{ width: '95%' }}>
            <SortablePaginatedGrid
                title={
                    <Header style={{ fontWeight: 'normal', fontStyle: 'Lato', fontSize: 18 }} as='h2'>
                        <Trans i18nKey='search.header'></Trans> "<span style={{ fontWeight: 'bold', fontSize: 18, fontStyle: 'Lato' }}>{title}</span>"
                    </Header>
                }
                loadMoreItems={loadMoreItems}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                componentId={componentId}
                id={sort}
                componentType={entity ? entity.componentType : 'default'}
            />
        </Container>
    );
}

export default withTranslation('translations')(LandingViewAllView);
