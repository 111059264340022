// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getBankAccount } from 'store/utilities';

import MyStoreBankAccountsView from './MyStoreBankAccountsView';

type Props = {
    loadBankAccounts: typeof actions.loadBankAccounts,
    createBankAccount: typeof actions.createBankAccount,
    updateBankAccount: typeof actions.updateBankAccount,
    deleteBankAccount: typeof actions.deleteBankAccount
};

class MyStoreBankAccountsContainer extends Component<Props> {
    state = {
        primaryBankAccount: null
    };

    componentDidMount() {
        this.props.loadBankAccounts();
    }

    handleSubmitNewBankAccount = (params) => {
        const { createBankAccount } = this.props;
        return createBankAccount({ isPrimary: 1, ...params });
    };

    handleSubmitEditBankAccount = (params) => {
        const { updateBankAccount } = this.props;
        const { bankAccount, ...rest } = params;
        return updateBankAccount({ isPrimary: 1, id: bankAccount.id, ...rest });
    };

    handleDeleteBankAccount = (params) => {
        const { deleteBankAccount } = this.props;
        const { bankAccount } = params;
        return deleteBankAccount({ id: bankAccount.id });
    };

    handleOpenAddBankAccount = () => {
        const { openModal } = this.props;
        console.warn('MyStoreBankAccounts handleOpenAddBankAccount');
        openModal(actions.ModalNames.BANK_ACCOUNT_ADD, {
            onSubmit: this.handleSubmitNewBankAccount
        });
    };

    handleOpenEditBankAccount = (bankAccount) => {
        const { openModal } = this.props;
        console.warn('MyStoreBankAccounts handleOpenAddBankAccount');
        openModal(actions.ModalNames.BANK_ACCOUNT_EDIT, {
            bankAccount,
            onSubmit: this.handleSubmitEditBankAccount,
            onDelete: this.handleDeleteBankAccount
        });
    };

    handleSelectBankAccount = (bankAccount) => {
        this.setState({ primaryBankAccount: bankAccount });
        this.props.updateBankAccount({ id: bankAccount.id, isPrimary: 1 }).then(this.props.loadBankAccounts);
    };

    render() {
        return (
            <MyStoreBankAccountsView
                onOpenAddBankAccount={this.handleOpenAddBankAccount}
                onOpenEditBankAccount={this.handleOpenEditBankAccount}
                onSelectBankAccount={this.handleSelectBankAccount}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { bankAccounts } = state.merchantData;

    return {
        bankAccounts: bankAccounts.list && bankAccounts.list.map((id) => getBankAccount(state, id)),
        primaryBankAccount: bankAccounts.primaryBankAccount,
        isLoading: bankAccounts.isLoading
    };
};

const mapDispatchToProps = (dispatch) => ({
    openModal: (...args) => dispatch(actions.openModal(...args)),
    loadBankAccounts: wrapAPIDispatch(dispatch, actions.loadBankAccounts),
    createBankAccount: wrapAPIDispatch(dispatch, actions.createBankAccount),
    updateBankAccount: wrapAPIDispatch(dispatch, actions.updateBankAccount),
    deleteBankAccount: wrapAPIDispatch(dispatch, actions.deleteBankAccount)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreBankAccountsContainer);
