// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_SHIPPING_LOCATIONS ----------------------
export const LOAD_SHIPPING_LOCATIONS = 'LOAD_SHIPPING_LOCATIONS';
export const loadShippingLocations = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_SHIPPING_LOCATIONS,
        endpoint: '/v3/shipping/locations',
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            shippingLocations: [Schemas.shippingLocation]
        }
    }
}));
