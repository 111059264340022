// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import type { ComponentType } from 'react';
import { getEntityDataBySlug } from '../store/utilities';

type Props = {
    match: {
        path: string
    }
};

export type NavigationAid = {
    /** Whether we are on the Blee sub-site */
    isBlee: boolean,
    /** URL prefix for the current sub-site (e.g. '' or '/blee') */
    urlPrefix: string,
    /** Entity object for landingPage of current sub-site, or null */
    landingPage: ?Object
};

/**
 * HOC that injects a `navigationAid` prop with utilities for managing
 * navigation around the site.
 */
function withNavigationAid(Comp: ComponentType<Props>) {
    const WrappedComp = (props: Props) => <Comp {...props} />;
    WrappedComp.displayName = `withNavigationAid(${Comp.displayName || Comp.name})`;

    const mapStateToProps = (state, { location }) => {
        const landingSlug = (location.pathname.match(/^\/[^/]*/) || [''])[0].replace(/\//g, '');
        const landingPage = getEntityDataBySlug(state, landingSlug);
        const urlPrefix = landingPage.entity != null && landingPage.entity.collectionId != null ? `/${landingSlug}` : '';

        const navigationAid: NavigationAid = {
            isBlee: landingSlug === 'blee',
            urlPrefix,
            landingPage: landingPage.entity,
            collection: landingPage.entity ? landingPage.entity.collectionId : undefined
        };

        return { navigationAid };
    };

    return compose(withRouter, connect(mapStateToProps))(WrappedComp);
}

export default withNavigationAid;
