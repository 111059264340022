// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import * as actions from 'store/actions';
import { getCategory } from 'store/utilities';

import ItemFormItemView from './ItemFormItemView';

type Props = {};

class ItemFormItemContainer extends Component<Props> {
    render() {
        const { rootCategories } = this.props;

        if (rootCategories.length > 0) {
            return <ItemFormItemView {...this.props} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state) => ({
    rootCategories: state.rootCategories.map((id) => getCategory(state, id).entity),
    getCategory: (id) => getCategory(state, id).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemFormItemContainer);
