// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getIsLoggedIn, getUser } from 'store/utilities';
import * as actions from 'store/actions';

import SidebarProfileUserImageView from './SidebarProfileUserImageView';

type Props = {
    logoutUser: typeof actions.logoutUser
};

function SidebarProfileUserImageContainer(props: Props) {
    return <SidebarProfileUserImageView {...props} />;
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    user: getUser(state, state.userData.user).entity
});

export default connect(mapStateToProps, null)(SidebarProfileUserImageContainer);
