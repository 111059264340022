// @flow
import * as ActionTypes from 'store/actions';

const initialState = {};

export default function forms(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_FORM_VALUES: {
            const { formId, ...rest } = action.payload;
            if (formId) {
                return {
                    ...state,
                    [formId]: {
                        ...state[formId],
                        ...rest
                    }
                };
            }
            break;
        }
        case ActionTypes.CLEAR_FORM_VALUES:
            return initialState;
        default:
            return state;
    }
    return state;
}
