// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';
import * as actions from 'store/actions';
import { getUser, getConversation } from 'store/utilities';

import MyAgentInboxView from './MyAgentInboxView';

type Props = {
    loadMyAgentConversations: typeof actions.loadMyAgentConversations
};

type State = {
    isLoadingMoreMessages: boolean,
    hasLoadedConversations: boolean
};

class MyAgentMessagesContainer extends Component<Props, State> {
    state = {
        isLoadingMoreMessages: false,
        hasLoadedConversations: false
    };

    componentDidMount() {
        this.loadConversations().then(() => {
            this.props.markAllAgentMessagesAsRead();
        });
    }

    loadConversations = () => {
        const { loadMyAgentConversations, isLoading } = this.props;
        if (!isLoading) {
            loadMyAgentConversations({ offset: 0 }).catch((error) => console.error(error));
        }
        return Promise.resolve(true);
    };

    loadMoreConversations = () => {
        const { loadMyAgentConversations, conversations, hasMore, isLoading } = this.props;
        if (!isLoading && hasMore) {
            loadMyAgentConversations({ offset: conversations.length }).catch((error) => console.error(error));
        }
    };

    loadMoreMessages = ({ conversation, offset }) => {
        this.setState({ isLoadingMoreMessages: true });
        const { loadMyAgentMessages } = this.props;
        if (conversation.entity) {
            const { conversationKey, typeId, item: itemId } = conversation.entity;
            loadMyAgentMessages({ conversationKey, typeId, itemId, limit: 20, offset })
                .then(() => this.setState({ isLoadingMoreMessages: false }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingMoreMessages: false });
                });
        }
    };

    render() {
        const { conversations, me } = this.props;
        return (
            <MyAgentInboxView
                authentication={Authentication.Agent}
                conversations={conversations}
                me={me}
                // onPostMessageSuccess={this.loadConversations}
                onLoadMoreMessages={this.loadMoreMessages}
                type='myAgentInbox'
            />
        );
    }
}

const mapStateToProps = (state) => ({
    conversations: state.userData.conversations.list.map((conversationKey) => getConversation(state, conversationKey)),
    hasMore: state.userData.conversations.hasMore,
    isLoading: state.userData.conversations.isLoading,
    me: getUser(state, state.userData.user)
});

const mapDispatchToProps = (dispatch) => ({
    loadMyAgentConversations: wrapAPIDispatch(dispatch, actions.loadMyAgentConversations),
    loadMyAgentMessages: wrapAPIDispatch(dispatch, actions.loadMyAgentMessages),
    markAllAgentMessagesAsRead: wrapAPIDispatch(dispatch, actions.markAllAgentMessagesAsRead)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAgentMessagesContainer);
