// @flow
import React, { Component } from 'react';
import { Button, Form, Modal, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { EntityData, UserAddress } from 'store/types';
import type { FormValues } from './index';

type FormFieldData<T = string> = {
    value: T,
    error: boolean
};

type Props = {
    isOpen: boolean,
    address: EntityData<UserAddress>,
    onClose: () => void,
    onDelete: (SyntheticEvent<>, { addressId: number }) => void,
    onSubmit: (SyntheticEvent<>, FormValues) => void,
    title: React.Node,
    showCancelButton?: boolean,
    showDeleteButton?: boolean,
    acceptButtonLabel?: React.Node,
    deleteButtonLabel?: React.Node,
    cancelButtonLabel?: React.Node,
    // from translation
    t: (string, { defaultValue: string }) => string,
    // from store
    countries: array,
    addressStates: array,
    cities: array,
    onCountryChange: ({ value: number }) => void,
    getCountry: (number) => void,
    getState: (number) => void,
    getCity: (number) => void
};

type State = {
    loadedId: ?number,
    name: FormFieldData<>,
    email: FormFieldData<>,
    phone: FormFieldData<>,
    address: FormFieldData<>,
    addressSecondary: FormFieldData<>,
    postal: FormFieldData<>,
    country: FormFieldData<number>,
    state: FormFieldData<number>,
    city: FormFieldData<number>,
    errorMessage: ?string
};

function createEmptyFormFieldData(value = '') {
    return {
        value,
        error: false
    };
}

class AddressModalView extends Component<Props, State> {
    static defaultProps;

    state = {
        loadedId: null,
        name: createEmptyFormFieldData(),
        email: createEmptyFormFieldData(),
        phone: createEmptyFormFieldData(),
        address: createEmptyFormFieldData(),
        addressSecondary: createEmptyFormFieldData(),
        postal: createEmptyFormFieldData(),
        country: createEmptyFormFieldData(),
        state: createEmptyFormFieldData(),
        city: createEmptyFormFieldData(),
        errorMessage: null
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { address } = nextProps;
        if (address == null) {
            this.setState({ loadedId: null });
        } else if (address && address.id !== this.state.loadedId) {
            this.setState({ loadedId: address.id }, () => {
                this.setDataFromAddressFields(address, nextProps, true);
            });
        } else if (this.state.loadedId != null) {
            this.setDataFromAddressFields(address, nextProps, false);
        }

        //TODO: Preselect Bosnia if country list is empty, and make sure to trigger onchange event
        // if(this.state.country == null) {
        //     this.setState({country: 27}); //preselect Bosnia
        // }
    }


    setDataFromAddressFields = (address, props = this.props, forceUpdate = false) => {
        const { countries, addressStates, getCountry, getState, getCity } = props;
        if (address == null) {
            return;
        }

        // Update unedited fields with values from received address
        const mapping = {
            name: address.contactPerson ? 'contactPerson' : 'fullName',
            email: address.contactEmail ? 'contactEmail' : 'emailAddress',
            phone: 'contactNo',
            address: 'houseNo',
            addressSecondary: 'street', // TODO DG Address this?
            postal: 'zip'
        };
        Object.keys(mapping).forEach((targetKey) => {
            // Copy values from source (existing address entity) to target (our
            // local state in the form).
            const sourceKey = mapping[targetKey];
            const sourceValue = address[sourceKey];
            const targetValue = this.state[targetKey].value;
            if (targetValue === '' || forceUpdate) {
                this.setField(targetKey, sourceValue || '');
            }
        });

        if (forceUpdate) {
            // Clear these fields so that they'll be loaded once we have all the
            // data necessary to load them (i.e. have fetched state & city data)
            this.setField('country', '');
            this.setField('state', '');
            this.setField('city', '');
        }

        // Country
        const country = (countries || []).map(getCountry).find(({ entity }) => entity.name === address.country);
        const countryId = country && country.entity && country.entity.id;
        if (countryId == null) {
            return;
        }

        if (this.state.country.value === '' || forceUpdate) {
            this.setField('country', countryId);
        }

        if (addressStates[countryId] == null) {
            // fetch states & cities
            this.props.onCountryChange({ value: countryId });
            return;
        }

        // State
        const states = (addressStates[countryId] && addressStates[countryId].list) || [];
        const state = states.map(getState).find(({ entity }) => entity.district === address.state);
        const stateId = state && state.entity && state.entity.id;
        if (stateId == null) {
            return;
        }

        if (this.state.state.value === '' || forceUpdate) {
            this.setField('state', stateId);
        }
        // City
        const city = state.entity.cities.map(getCity).find(({ entity }) => entity.name === address.city);
        const cityId = city && city.entity && city.entity.id;
        if (cityId == null) {
            return;
        }

        if (this.state.city.value === '' || forceUpdate) {
            this.setField('city', cityId);
        }
    };


    setField = (key, value) => {
        // FIXME
        const target = this.state[key];
        target.value = value;
        this.setState({ [key]: target });
    };

    handleChange = (event, { name, value }) => {
        this.setField(name, value);
    };

    handleCountryChange = (event, data) => {
        const { onCountryChange } = this.props;
        this.handleChange(event, data);
        onCountryChange(data);
    };

    handleStateChange = (event, data) => {
        const target = this.state.city;
        target.value = null;
        this.setState({ city: target });

        this.handleChange(event, data);
    };

    handleBlur = (event) => {
        this.validate(event.target.name).catch(() => {});
    };

    handleSubmit = (event) => {
        const { onSubmit, getCountry, getState, getCity, fields = ['name', 'email', 'phone', 'address', 'postal', 'country', 'state', 'city'] } = this.props;
        const {
            loadedId,
            name: { value: name },
            email: { value: email },
            phone: { value: phone },
            address: { value: address },
            addressSecondary: { value: addressSecondary },
            postal: { value: postal },
            country: { value: country },
            state: { value: state },
            city: { value: city }
        } = this.state;

        this.validate(...fields)
            .then(() => {
                return onSubmit(event, {
                    addressId: loadedId,
                    name,
                    email,
                    contactPerson: name,
                    contactEmail: email,
                    contactNo: phone,
                    phone,
                    address,
                    addressSecondary,
                    postal,
                    country: getCountry(country).entity.name,
                    state: getState(state).entity.district,
                    city: getCity(city).entity.name,
                    countryId: country,
                    stateId: state,
                    cityId: city
                });
            })
            .then(() => {
                this.setState({
                    loading: false,
                    name: createEmptyFormFieldData(),
                    email: createEmptyFormFieldData(),
                    phone: createEmptyFormFieldData(),
                    address: createEmptyFormFieldData(),
                    addressSecondary: createEmptyFormFieldData(),
                    postal: createEmptyFormFieldData(),
                    country: createEmptyFormFieldData(),
                    state: createEmptyFormFieldData(),
                    city: createEmptyFormFieldData()
                });
            })
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    validate = (...inputs) => {
        const { t } = this.props;

        let valid = true;
        inputs
            .filter(input => input !=='addressSecondary') //skip address secondary validation
            .forEach((input) => {
            const target = this.state[input];
            target.error = target.value === null || target.value.length === 0;
            if (target.error) {
                valid = false;
            }
            this.setState({ [input]: target });
        });

        const errorMessage = t('address.validationErrors.invalidFields', { defaultValue: 'Some fields were invalid' });
        return valid ? Promise.resolve() : Promise.reject(new Error(errorMessage));
    };

    handleDelete = (event) => {
        const { onDelete } = this.props;
        const { loadedId } = this.state;
        onDelete(event, { addressId: loadedId }).catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    render() {
        const {
            isOpen,
            title,
            showCancelButton,
            showDeleteButton,
            deleteButtonLabel,
            cancelButtonLabel,
            acceptButtonLabel,
            t,
            // from store
            countries,
            addressStates,
            getCountry,
            getState,
            getCity,
            fields = ['name', 'email', 'phone', 'address', 'addressSecondary', 'postal', 'country', 'state', 'city']
        } = this.props;

        const {
            country: { value: countryId }
        } = this.state;

        const countryStates = addressStates[countryId] || {};
        const statesOptions = countryId && countryStates && countryStates.list ? countryStates.list : [];
        const selectedState = this.state.state.value && getState(this.state.state.value);

        const citiesOptions = selectedState ? selectedState.entity.cities : [];

        const {
            name: { value: name },
            email: { value: email },
            phone: { value: phone },
            address: { value: address },
            addressSecondary: { value: addressSecondary },
            postal: { value: postal },
            country: { value: country },
            state: { value: state },
            city: { value: city },
            errorMessage
        } = this.state;

        return (
            <Modal size='tiny' open={isOpen} onClose={this.handleClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Form error={errorMessage != null}>
                        <Message error>{errorMessage}</Message>
                        {fields.includes('name') && (
                            <Form.Input
                                error={this.state.name.error}
                                type='text'
                                name='name'
                                id='-address-name'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                label={t('address.form.name.label')}
                                placeholder={t('address.form.name.placeholder', { defaultValue: '' })}
                                value={name}
                                required
                            />
                        )}
                        {fields.includes('email') && (
                            <Form.Input
                                error={this.state.email.error}
                                type='email'
                                name='email'
                                id='-address-email'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                label={t('address.form.email.label')}
                                placeholder={t('address.form.email.placeholder', { defaultValue: '' })}
                                value={email}
                                required
                            />
                        )}
                        {fields.includes('phone') && (
                            <Form.Input
                                error={this.state.phone.error}
                                type='text'
                                name='phone'
                                id='-address-phone'
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                label={t('address.form.phone.label')}
                                placeholder={t('address.form.phone.placeholder', { defaultValue: '' })}
                                value={phone}
                                required
                            />
                        )}
                        <Form.Input
                            error={this.state.address.error}
                            type='text'
                            name='address'
                            id='-address-address'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label='Adresa'
                            placeholder={t('address.form.address.placeholder', { defaultValue: '' })}
                            value={address}
                            required
                        />
                        {/*<Form.Input*/}
                        {/*    error={this.state.addressSecondary.error}*/}
                        {/*    type='text'*/}
                        {/*    name='addressSecondary'*/}
                        {/*    id='-address-address-secondary'*/}
                        {/*    onChange={this.handleChange}*/}
                        {/*    onBlur={this.handleBlur}*/}
                        {/*    label={t('address.form.addressSecondary.label')}*/}
                        {/*    placeholder={t('address.form.addressSecondary.placeholder', { defaultValue: '' })}*/}
                        {/*    value={addressSecondary}*/}
                        {/*/>*/}
                        <Form.Input
                            error={this.state.postal.error}
                            type='text'
                            name='postal'
                            id='-address-postal'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label={t('address.form.postal.label')}
                            placeholder={t('address.form.postal.placeholder', { defaultValue: '' })}
                            value={postal}
                            required
                        />
                        <Form.Dropdown
                            error={this.state.country.error}
                            selection
                            name='country'
                            id='-address-country'
                            onChange={this.handleCountryChange}
                            onBlur={() => this.validate('country').catch(() => {})}
                            label={t('address.form.country.label')}
                            placeholder={t('address.form.country.placeholder', { defaultValue: '' })}
                            value={country}
                            options={countries.map(getCountry).map(({ entity }) => ({ text: entity.name, value: entity.id }))}
                            required
                        />
                        {fields.includes('state') && (
                            <Form.Dropdown
                                error={this.state.state.error}
                                selection
                                name='state'
                                id='-address-state'
                                onChange={this.handleStateChange}
                                onBlur={() => this.validate('state').catch(() => {})}
                                label={t('address.form.state.label')}
                                placeholder={t('address.form.state.placeholder', { defaultValue: '' })}
                                value={state}
                                options={statesOptions.map(getState).map(({ entity }) => ({ text: entity.district, value: entity.id }))}
                                required
                            />
                        )}
                        <Form.Dropdown
                            error={this.state.city.error}
                            selection
                            name='city'
                            id='-address-city'
                            onChange={this.handleChange}
                            onBlur={() => this.validate('city').catch(() => {})}
                            label={t('address.form.city.label')}
                            placeholder={t('address.form.city.placeholder', { defaultValue: '' })}
                            value={city}
                            options={citiesOptions.map(getCity).map(({ entity }) => ({ text: entity.name, value: entity.id }))}
                            required
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    {showDeleteButton && (
                        <Button negative icon labelPosition='left' onClick={this.handleDelete}>
                            {deleteButtonLabel}
                        </Button>
                    )}
                    {showCancelButton && (
                        <Button basic onClick={this.handleClose}>
                            {cancelButtonLabel}
                        </Button>
                    )}
                    <Button positive icon labelPosition='right' onClick={this.handleSubmit}>
                        {acceptButtonLabel}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

AddressModalView.defaultProps = {
    onDelete: null,
    showCancelButton: true,
    showDeleteButton: false,
    acceptButtonLabel: <Trans i18nKey='address.acceptButton'>Accept</Trans>,
    cancelButtonLabel: <Trans i18nKey='address.cancelButton'>Cancel</Trans>,
    deleteButtonLabel: <Trans i18nKey='address.deleteButton'>Delete</Trans>
};

export default withTranslation('translations')(AddressModalView);
