import { BuilderComponent, builder } from '@builder.io/react';
import { useEffect, useState } from 'react';
import { Segment } from 'semantic-ui-react';
import React from 'react';

builder.init(process.env.REACT_APP_BUILDER);

const FooterView = () => {
    const [builderContentJson, setBuilderContentJson] = useState(null);

    useEffect(() => {
        builder.get('footer', { url: location.pathname }).promise().then(setBuilderContentJson);
        console.log('tu kaj');
        console.log(setBuilderContentJson);
    }, []);

    return (
        <Segment className='page-footer' vertical>
            <div style={{ maxWidth: '1180px', margin: 'auto' }}>
                <BuilderComponent model='footer' content={builderContentJson} />
            </div>
        </Segment>
    );
};

export default FooterView;
