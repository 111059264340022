// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import withNavigationAid from 'utilities/withNavigationAid';
import type NavigationAid from 'utilities/withNavigationAid';

import type { EntityData, Category } from 'store/types';

type Props = {
    categories: EntityData<Category>[],
    // from middleware
    navigationAid: NavigationAid
};

const homeSectionOf = ({ title, slug }) => ({
    key: slug,
    content: title,
    as: Link,
    to: `/${slug}`
});
const homeSectionMain = homeSectionOf({ title: 'Početna', slug: '' });
const homeSectionBlee = homeSectionOf({ title: 'Blee', slug: 'blee' });

function categoryToSection(t, { entity }, active, link, name) {
    if (entity == null) {
        return null;
    }
    const { id } = entity;

    return {
        key: `category-${id}`,
        as: active ? 'span' : Link,
        to: active ? null : link,
        content: name,
        active
    };
}

function CategoryBreadcrumbView({ categories, navigationAid, t }: Props) {
    const parentCategories = categories.slice(0, -1);
    const activeCategory = categories.slice(-1)[0];

    let links = categories
        .reduce(
            (arr, { entity }) => {
                const prev = arr.slice(-1)[0];
                // return arr.concat([[...prev, entity.slug]]);
                return arr.concat([[...prev, { slug: entity.slug, name: entity.name }]]);
                // }, [[navigationAid.urlPrefix, 'categories']]).map(arr => arr.join('/'));
            },
            [[{ slug: navigationAid.urlPrefix }]]
        )
        .map((arr) => ({ value: arr.map((obj) => obj.slug).join('/'), name: arr.slice(-1)[0].name }));

    const names = links.slice(1);
    names.forEach((link, index, array) => {
        array[index] = t(
            `subBar.categories.dropdown.${link.value.substr(link.value.indexOf('/', link.value.indexOf('categories')) + 1)}`,
            { defaultValue: link.name }
            // `subBar.categories.dropdown.${link.substr(link.indexOf('/', link.indexOf('categories')) + 1)}`,
            // { defaultValue: link.name },
        );
    });
    links = links.slice(0, -1);

    homeSectionMain.content = t(`categories.home`, { defaultValue: homeSectionMain.content });

    let homeSection = homeSectionMain;
    if (navigationAid.isBlee) {
        homeSection = homeSectionBlee;
    } else if (navigationAid.landingPage != null && navigationAid.collection != null) {
        homeSection = homeSectionOf(navigationAid.landingPage);
    }

    return (
        <Breadcrumb
            sections={[
                homeSection,
                ...parentCategories.map((category, i) => categoryToSection(t, category, false, links[i + 1].value, names[i])),
                activeCategory && categoryToSection(t, activeCategory, true, undefined, names[names.length - 1])
            ].filter(Boolean)}
        />
    );
}

export default compose(withNavigationAid, withTranslation('translations'))(CategoryBreadcrumbView);
