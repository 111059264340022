// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Grid, Header, Image, Loader } from 'semantic-ui-react';

import type { EntityData, Category, LandingComponent } from 'store/types';

type Props = {
    component: EntityData<LandingComponent>,
    categories: EntityData<Category>[],
    title: string
};

function LandingCategoryListView({ component, categories, title, t }: Props) {
    if (component.isFetching || component.entity == null) {
        return <Loader active inline='centered' />;
    }

    return (
        <Grid className='auto tensed'>
            <Grid.Column className='col-row'>
                <Header as='h3'>{title}</Header>
            </Grid.Column>
            <Grid.Column className='col-row'>
                <Grid className='auto tensed'>
                    {categories.map(({ entity }) => (
                        <Grid.Column key={entity.id} className='col-4 col-md-5-and-one-third'>
                            <Link to={`/categories/${entity.slug}`} title={entity.name}>
                                <Image fluid src={entity.imageFull} />
                                <Header as='h4' className='landing-category-list-item-title'>
                                    {t(`landingComponent.categoryList.${entity.id}.label`, { defaultValue: entity.name })}
                                </Header>
                            </Link>
                        </Grid.Column>
                    ))}
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(LandingCategoryListView);
