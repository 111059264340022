// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Header, Segment, Image, Rating, Item, Icon, Label } from 'semantic-ui-react';

type Props = {
    merchant: {
        pictureThumb: string,
        alias: string,
        slug: string,
        isVerified: boolean,
        description: string,
        totalFollowers: number,
        totalFollowing: number,
        items: number
    }
};

function ProductStore({ merchant }: Props) {
    const { alias, description, slug, pictureThumb, totalFollowers, totalFollowing, items } = merchant;

    return (
        <Grid className='auto tensed grid-align-items-center'>
            <Grid.Column>
                <Segment size='small' padded style={{ width: '100%' }}>
                    <Item className='storeoverview-content'>
                        <Link to={`/${slug}`}>
                            <Image src={pictureThumb} size='small' circular className='storeoverview-image' />
                        </Link>
                        <Item.Content className='storeoverview-info'>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '15px',
                                    marginBottom: '15px'
                                }}
                            >
                                <Link to={`/${slug}`}>
                                    <Header as='h4' size='medium' style={{ margin: 0, padding: 0 }}>
                                        {alias}
                                    </Header>
                                </Link>
                                <Rating icon='star' defaultRating={4} maxRating={5} disabled size='mini' style={{ marginRight: '10px', display: 'none' }} />
                            </div>
                            <Item.Description className='storeoverview-description'>{description}</Item.Description>
                            <Item.Extra style={{ marginTop: '40px' }}>
                                <Label size='medium'>
                                    <Icon color='violet' name='tag' /> {items} <Trans i18nKey='product.storeOverview.products'>Products</Trans>
                                </Label>
                                <Label size='medium'>
                                    <Icon color='violet' name='user' /> {totalFollowers} <Trans i18nKey='product.storeOverview.followers'>Followers</Trans>
                                </Label>
                                <Label size='medium' color='violet'>
                                    <Icon name='user' /> {totalFollowing} <Trans i18nKey='product.storeOverview.following'>Following</Trans>
                                </Label>
                            </Item.Extra>
                        </Item.Content>
                    </Item>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(ProductStore);
