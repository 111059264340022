// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';

import * as actions from 'store/actions';
import { getItem } from 'store/utilities';

import SendCommentView from './SendCommentView';

type Props = {
    productId: number
};

class SendCommentContainer extends Component<Props> {
    sendComment = ({ comment }) => {
        const {
            postComment,
            product: { entity },
            authentication = Authentication.User
        } = this.props;

        const commentParams = {
            itemId: entity.id,
            text: comment,
            authentication
        };
        return postComment(commentParams);
    };

    render() {
        const { onPostCommentSuccess } = this.props;
        return <SendCommentView sendComment={this.sendComment} onPostCommentSuccess={onPostCommentSuccess} />;
    }
}

const mapStateToProps = (state, { productId }) => ({
    product: getItem(state, productId)
});

const mapDispatchToProps = (dispatch) => ({
    postComment: wrapAPIDispatch(dispatch, actions.postComment)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SendCommentContainer);
