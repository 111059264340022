// @flow
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

const RegisterScene = ({ Register, ...props }) => {
    const click = (event) => {
        event.preventDefault();
        Register();
    };

    const { t } = props;

    return (
        <div>
            <p>{t('registerAlert.body2', { defaultValue: 'To fully benefit from MyBazar services you need to be registered user. Registration is FREE.' })}</p>
            <Link to='/login' onClick={click}>
                <Button fluid primary>
                    Registracija
                </Button>
            </Link>
        </div>
    );
};

export default withTranslation('translations')(RegisterScene);
