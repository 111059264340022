// @flow
import React from 'react';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import DeliveryAddressModal from '../DeliveryAddressModal';

type Props = {
    onSubmit: () => void,
    onDelete: () => void
};

function DeliveryAddressEditModalView({ onSubmit, onDelete, ...rest }: Props) {
    return (
        <DeliveryAddressModal
            title={<Trans i18nKey='deliveryAddress.edit.title'>Edit Delivery Address</Trans>}
            acceptButtonLabel={[
                <Trans key='trans' i18nKey='deliveryAddress.edit.acceptButton'>
                    Edit Delivery Address
                </Trans>,
                <Icon key='icon' name='check' />
            ]}
            deleteButtonLabel={[
                <Trans key='trans' i18nKey='deliveryAddress.delete.acceptButton'>
                    Delete
                </Trans>,
                <Icon key='icon' name='delete' />
            ]}
            confirmDeleteLabel={[
                <Trans key='trans' i18nKey='deliveryAddress.delete.title'>
                    Are you sure you want to delete this delivery address?
                </Trans>
            ]}
            onSubmit={onSubmit}
            onDelete={onDelete}
            {...rest}
        />
    );
}

export default compose(withTranslation('translations'))(DeliveryAddressEditModalView);
