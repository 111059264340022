// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import Slick from 'react-slick';

import ShoppingMallCarouselItem from './components/ShoppingMallItem';
import ShoppingMallArrow from './components/ShoppingMallArrow';

type Props = {
    header: string,
    link: string,
    type: 'shopping-mall',
    content: array,
    inverted: boolean,
    invertedType: string,
    showViewAll?: boolean
};

function ShoppingMallCarousel({ header, link, type, content, inverted, invertedType, showViewAll }: Props) {
    let slickChanging = false;
    const preventSlickClickDuringChange = (event) => {
        if (slickChanging) {
            event.preventDefault();
        }
    };
    const slickClass = 'teaser-carousel-slick-slider';

    const getSlickClass = (length) => `${slickClass}${content.length < length ? ` ${slickClass}-items-${content.length}` : ''}`;

    // Adjust number of items shown depending on carousel width
    const responsive = [
        [1320, 2],
        [950, 1]
    ].map(([breakpoint, n]) => ({
        breakpoint,
        touchThreshold: n * 4,
        settings: {
            className: getSlickClass(n),
            slidesToShow: Math.min(n, content.length),
            slidesToScroll: Math.min(n, content.length)
        }
    }));

    const slickSettings = {
        className: getSlickClass(3),
        slidesToShow: Math.min(3, content.length),
        slidesToScroll: Math.min(3, content.length),
        speed: 500,
        lazyLoad: true,
        touchThreshold: 3 * 4,
        nextArrow: <ShoppingMallArrow direction='next' inverted={inverted} />,
        prevArrow: <ShoppingMallArrow direction='prev' inverted={inverted} />,
        beforeChange: () => {
            slickChanging = true;
        },
        afterChange: () => {
            slickChanging = false;
        },
        responsive
    };

    const seen = new Set();
    const hasDuplicates = content.some((currentObject) => {
        if (seen.size === seen.add(currentObject.key).size) {
            return true;
        }
        return false;
    });

    return (
        <Segment className={`simple teaser-carousel${inverted ? ` teaser-carousel-inverted teaser-carousel-inverted-${invertedType}` : ''}`}>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    {(header !== '' || showViewAll) && (
                        <Grid className='auto vertically-tensed grid-align-items-center teaser-carousel-header'>
                            <Grid.Column className='col-grow'>
                                {header !== '' && (
                                    <Header as='h2' inverted={inverted} style={{ fontSize: '1.5rem' }}>
                                        {header}
                                    </Header>
                                )}
                            </Grid.Column>
                            {showViewAll && (
                                <Grid.Column className='col-shrink'>
                                    <Link to={link} inverted={inverted} style={{ fontSize: '1rem' }} className='view-all'>
                                        <Trans i18nKey='shoppingMallCarousel.viewAll'>View All</Trans>
                                    </Link>
                                </Grid.Column>
                            )}
                        </Grid>
                    )}
                </Grid.Column>
                <Grid.Column className='col-row'>
                    <Slick {...slickSettings}>
                        {content.map(({ key, ...rest }, index) => (
                            <div key={hasDuplicates ? index : key} className='teaser-carousel-slick-slide' onDragStart={(event) => event.preventDefault()}>
                                {type === 'shopping-mall' && (
                                    <ShoppingMallCarouselItem {...rest} inverted={inverted} preventSlickClickDuringChange={preventSlickClickDuringChange} />
                                )}
                            </div>
                        ))}
                    </Slick>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

ShoppingMallCarousel.defaultProps = {
    showViewAll: true
};

export default withTranslation('translations')(ShoppingMallCarousel);
