// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

// SEARCH_BY_KEYWORD ------------------
export type SearchByKeywordParams = {
    keyword: string,
    limit?: number,
    offset?: number,
    sort?: string,
    onlyBlee?: boolean
};

export const SEARCH_BY_KEYWORD = 'SEARCH_BY_KEYWORD';
export const searchByKeyword = wrapAPIActionCreator(
    ({ keyword, limit = 12, offset = 0, sort = 'latest', onlyBlee = false, only_registered = true }: SearchItemsByKeywordParams) => {
        return {
            [API_DATA]: {
                type: SEARCH_BY_KEYWORD,
                endpoint: '/v3/web/search',
                endpointParams: {
                    keyword,
                    limit,
                    offset,
                    sort,
                    onlyBlee,
                    only_registered
                },
                preprocess: ({ response }) => response,
                schema: {
                    items: [Schemas.item]
                }
            }
        };
    }
);
