// @flow
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as ActionTypes from '../../actions';

import orders from './orders';
import items from './items';
import settings from './settings';
import returnPolicies from './returnPolicies';
import shippingCosts from './shippingCosts';
import activity from './activity';
import sales from './sales';
import conversations from './conversations';
import bankAccounts from './bankAccounts';
import campaigns from './campaigns';

function updateAccessTokenFromLogin(state, action) {
    const { result, entities } = action.payload;
    const userEntity = entities.users[result.user];
    if (userEntity.userType === 1) {
        return result.accessToken;
    }
    return null;
}

function updateAccessTokenFromLoadMyAccounts(state, action) {
    const { result, entities } = action.payload;
    const merchantAccount = result.accounts.find((account) => entities.users[account.user].userType === 1);
    if (merchantAccount) {
        return merchantAccount.accessToken;
    }
    return null;
}

function accessToken(state = null, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:
        case ActionTypes.LOG_IN_FACEBOOK:
        case ActionTypes.RECOVER_RESET_PASSWORD:
            if (action.status === 'success') {
                return updateAccessTokenFromLogin(state, action);
            }
            return state;
        case ActionTypes.LOAD_MY_ACCOUNTS:
        case ActionTypes.CREATE_MERCHANT_ACCOUNT:
            if (action.status === 'success') {
                return updateAccessTokenFromLoadMyAccounts(state, action);
            }
            return state;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}

function updateMerchantUserFromLoadMyAccounts(state, action) {
    const { result, entities } = action.payload;
    const account = result.accounts.find((a) => entities.users[a.user].userType === 1);
    return account ? account.user : null;
}

function user(state = null, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_ACCOUNTS:
        case ActionTypes.CREATE_MERCHANT_ACCOUNT:
            if (action.status === 'success') {
                return updateMerchantUserFromLoadMyAccounts(state, action);
            }
            return state;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}

function isLoadingAccounts(state = false, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_ACCOUNTS:
            if (action.status === 'request') {
                return true;
            }
            return false;
        default:
            return state;
    }
}

export const userData = combineReducers({
    accessToken,
    user,
    isLoadingAccounts,
    activity,
    bankAccounts,
    orders,
    items,
    sales,
    settings,
    returnPolicies,
    shippingCosts,
    conversations,
    campaigns
});

const persistConfig = {
    key: 'merchantData',
    storage,
    whitelist: ['accessToken', 'user']
};

export default persistReducer(persistConfig, userData);
