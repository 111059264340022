import { API_DATA /*, Schemas*/, wrapAPIActionCreator, Authentication } from 'services/api';

export const SAVE_AGENT_SETTINGS = 'SAVE_AGENT_SETTINGS';

export const saveAgentSettings = wrapAPIActionCreator((agent) => ({
    [API_DATA]: {
        type: SAVE_AGENT_SETTINGS,
        endpoint: '/v4/agent/settings',
        authentication: Authentication.Agent,
        body: { agent },
        method: 'POST'
        /*schema: {
            conversations: [Schemas.conversation]
        }*/
    }
}));
