// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { EntityData, User } from 'store/types';
import { getUser, getIsLoggedIn } from 'store/utilities';
import { changeLanguage } from 'utilities/languages';
import FooterView from './FooterView';

type Props = {
    // from store
    isLoggedIn: boolean,
    updateProfile: typeof updateProfile,
    setLocale: typeof setLocale,
    user: EntityData<User>,
    locale: string
};

class FooterContainer extends Component<Props> {
    handleOnChangeLanguage = (language: string) => {
        changeLanguage(language, this.props.user);
    };

    render() {
        return <FooterView onChangeLanguage={this.handleOnChangeLanguage} {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    const user = getUser(state, state.userData.user);
    return {
        user,
        isLoggedIn: getIsLoggedIn(state),
        locale: state.ui.locale
    };
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FooterContainer);
