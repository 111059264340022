// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

// DELETE_BANK_ACCOUNT --------------------------------
export type DeleteBankAccountParams = {
    id: number
};

export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const deleteBankAccount = wrapAPIActionCreator(({ id }: DeleteBankAccountParams) => ({
    [API_DATA]: {
        type: DELETE_BANK_ACCOUNT,
        endpoint: `/v3/me/bank/account/${id}`,
        authentication: Authentication.Merchant,
        method: 'DELETE',
        reducerParams: { id }
    }
}));
