import React from 'react';
import { useSelector } from 'react-redux';

import StoreSettingsView from './StoreSettingsView';
import {getStore, getUser} from 'store/utilities';
import { getCategory } from 'store/utilities';
import {wrapAPIDispatch} from "../../services/api";
import * as actions from "../../store/actions";

const StoreSettingsContainer = () => {
    const categories = useSelector((state) => state.rootCategories.map((id) => getCategory(state, id).entity));
    const user = useSelector((state) => getUser(state, state.userData.user).entity);
    //const stores = useSelector((state) => getStore(state, state.userData.user).entity);
    const merchantUser = useSelector((state) => getUser(state, state.merchantData.user).entity);
    const countries = useSelector((state) => state.address.enabledCountries);
    const states = useSelector((state) => state.address.states);
    return <>{!!user && <StoreSettingsView user={user} merchantUser={merchantUser} countries={countries} states={states} categories={categories} />}</>;
};

// const mapDispatchToProps = (dispatch) => ({
//     loadActivity: wrapAPIDispatch(dispatch, actions.loadMyStoreActivity)
// });

export default StoreSettingsContainer;
