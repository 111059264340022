// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Schemas } from 'services/api';
import * as actions from 'store/actions';
import { getLandingComponent } from 'store/utilities';
import withNavigationAid from 'utilities/withNavigationAid';
import type { EntityData, LandingComponent } from 'store/types';
import type { NavigationAid } from 'utilities/withNavigationAid';

import LandingViewAllView from './LandingViewAllView';

type Props = {
    // from route params
    componentId: number,
    navigationAid: NavigationAid,
    // from store
    sort: string,
    component: EntityData<LandingComponent>,
    loadLandingComponentEntities: typeof actions.loadLandingComponentEntities
};

class LandingViewAllContainer extends Component<Props> {
    loadMoreItems = ({ offset, limit, customSort }) => {
        const { componentId, navigationAid, sort, loadLandingComponentEntities } = this.props;

        return loadLandingComponentEntities({
            componentId,
            componentEntitySchema: Schemas.item,
            offset,
            limit,
            sort: sort,
            onlyBlee: navigationAid.isBlee,
            collection: navigationAid.collection
        }).then(({ result }) => result.entities);
    };

    render() {
        const { componentId, ...rest } = this.props;
        return <LandingViewAllView loadMoreItems={this.loadMoreItems} componentId={componentId} {...rest} />;
    }
}

const mapStateToProps = (state, { match }) => {
    const { landingComponentId } = match.params;

    const component = getLandingComponent(state, Number(landingComponentId));
    const { sort } = state.filters;

    return {
        componentId: Number(landingComponentId),
        component,
        sort
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadLandingComponentEntities: wrapAPIDispatch(dispatch, actions.loadLandingComponentEntities)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withNavigationAid)(LandingViewAllContainer);
