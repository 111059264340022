// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';

import MyStoreCoverView from './MyStoreCoverView';

type Props = {
    updateMyStoreCoverPhoto: typeof actions.updateMyStoreCoverPhoto,
    loadMyAccounts: typeof actions.loadMyAccounts
};

class MyStoreCoverPhotoContainer extends Component<Props> {
    state = {
        isUploadingPicture: false
    };

    onSubmitCoverPhoto = (coverPhotoUrl) => {
        this.setState({ isUploadingPicture: true });

        const { updateMyStoreCoverPhoto, loadMyAccounts } = this.props;
        updateMyStoreCoverPhoto({ coverPhotos: [{ data: coverPhotoUrl }] })
            .then(loadMyAccounts)
            .then(() => this.setState({ isUploadingPicture: false }))
            .catch(() => this.setState({ isUploadingPicture: false }));
    };

    render() {
        const { user, btnFit } = this.props;
        const coverPhotos = user?.coverPhotos;
        return <MyStoreCoverView onSubmitCoverPhoto={this.onSubmitCoverPhoto} coverPhotos={coverPhotos} isUploadingPicture={this.state.isUploadingPicture} btnFit={btnFit} />;
    }
}

const mapStateToProps = (state) => ({
    user: getUser(state, state.merchantData.user).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateMyStoreCoverPhoto: wrapAPIDispatch(dispatch, actions.updateMyStoreCoverPhoto),
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreCoverPhotoContainer);
