// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadCartItemsParams = {
    forStep?: number
};

export const LOAD_CART_ITEMS = 'LOAD_CART_ITEMS';
export const loadCartItems = wrapAPIActionCreator(({ forStep }: LoadCartItemsParams = {}) => ({
    [API_DATA]: {
        type: LOAD_CART_ITEMS,
        endpoint: '/v3/cart_items',
        endpointParams: { forStep },
        method: 'GET',
        authentication: Authentication.User,
        schema: {
            cartItems: [Schemas.cartItem],
            addresses: [Schemas.userDeliveryAddress]
        }
    }
}));

export default loadCartItems;
