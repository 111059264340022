// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';

import ItemImageListItem from './components/ItemImageListItem';
import ItemImageAdd from './components/ItemImageAdd';

type Props = {
    list: Array,
    limit: number,
    addImage: () => void,
    deleteImage: () => void,
    moveImage: () => void
};

function ItemImageList({ list, limit, addImage, deleteImage, moveImage }: Props) {
    return (
        <Form.Field>
            <Grid className='tensed'>
                {list.map((item, index) => (
                    <Grid.Column key={item.index} width={4}>
                        <ItemImageListItem item={item} first={index === 0} last={index === list.length - 1} deleteImage={deleteImage} moveImage={moveImage} />
                    </Grid.Column>
                ))}
                {list.length < limit && (
                    <Grid.Column width={4}>
                        <ItemImageAdd addImage={addImage} />
                    </Grid.Column>
                )}
            </Grid>
        </Form.Field>
    );
}

export default withTranslation('translations')(ItemImageList);
