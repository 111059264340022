import Cookies from 'universal-cookie';

export const COOKIE_LANGUAGE = 'mybazar_language';

const EXPIRATION_DATE = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
const cookies = new Cookies();

export const getCookie = (name: string) => cookies.get(name);
export const setCookie = (name: string, value: string) => cookies.set(name, value, { expires: EXPIRATION_DATE });
export const removeCookie = (name: string) => cookies.remove(name);
