// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_PRODUCT_COMMENTS ------------------
export type LoadProductCommentsParams = {
    limit: number,
    offset: number,
    itemId: number
};

export const LOAD_PRODUCT_COMMENTS = 'LOAD_PRODUCT_COMMENTS';
export const loadProductComments = wrapAPIActionCreator(({ limit = 10, offset = 0, itemId }: LoadProductCommentsParams) => ({
    [API_DATA]: {
        type: LOAD_PRODUCT_COMMENTS,
        endpoint: `/v3/items/${itemId}/comments?limit=${limit}&offset=${offset}`,
        authentication: Authentication.User,
        reducerParams: {
            itemId,
            limit,
            offset
        },
        preprocess: (item) => ({ item: { id: itemId, ...item } }),
        method: 'GET',
        schema: {
            item: Schemas.item
        }
    }
}));
