// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getItem, getUser } from 'store/utilities';
import type { EntityData, Item, User } from 'store/types';
import StoreTeaserView from './StoreTeaserView';

type Props = {
    // from store
    merchant: EntityData<User>,
    items: EntityData<Item>[],
    parentId?: number
};

class StoreTeaserContainer extends Component<Props> {
    render() {
        return <StoreTeaserView {...this.props} />;
    }
}

const mapStateToProps = (state, { merchantId, itemIds }) => {
    if (merchantId) {
        const merchant = getUser(state, merchantId);
        const items = itemIds.map((id) => getItem(state, id));
        return {
            merchant,
            items
        };
    }
    return {};
};

const mapDispatchToProps = (dispatch) => ({});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoreTeaserContainer);
