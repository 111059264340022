// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';
import { any } from 'prop-types';

export type LoadPurchasesParams = {
    limit: number,
    offset: number
};

// LOAD_PURCHASES ----------------------
export const LOAD_PURCHASES = 'LOAD_PURCHASES';
export const loadPurchases = wrapAPIActionCreator(({ limit = 10, offset = 0 }: LoadPurchasesParams) => ({
    [API_DATA]: {
        type: LOAD_PURCHASES,
        endpoint: '/v3/web/tabs/orders/carts',
        endpointParams: { limit, offset },
        authentication: Authentication.User,
        method: 'GET',
        schema: {
            items: {}
        },
        reducerParams: { limit, offset }
    }
}));
