// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export * from './searchItemsByKeyword';
export * from './searchTagsByKeyword';
export * from './searchStoresByKeyword';
export * from './searchByKeyword';
export * from './searchUsersByKeyword';
export * from './handleTabChange';

// SEARCH_RECORD_BY_SLUG ------------------
export type SearchRecordBySlugParams = {
    slug: string
};

export const SEARCH_RECORD_BY_SLUG = 'SEARCH_RECORD_BY_SLUG';
export const searchRecordBySlug = wrapAPIActionCreator(({ slug }: SearchRecordBySlugParams) => ({
    [API_DATA]: {
        type: SEARCH_RECORD_BY_SLUG,
        endpoint: `/v3/search/${slug}`,
        optionalAuthentication: Authentication.User,
        schema: {
            data: Schemas.record
        }
    }
}));

// SEARCH_PRODUCT_BY_ITEM_SLUG_AND_STORE_SLUG ------------------
export type SearchProductByItemSlugAndStoreSlugParams = {
    itemSlug: string,
    storeSlug: string,
    bazar?: string
};

export const SEARCH_PRODUCT_BY_ITEM_SLUG_AND_STORE_SLUG = 'SEARCH_PRODUCT_BY_ITEM_SLUG_AND_STORE_SLUG';
export const searchProductByItemSlugAndStoreSlug = wrapAPIActionCreator(({ itemSlug, storeSlug, bazar='' }: SearchProductByItemSlugAndStoreSlugParams) => ({
    [API_DATA]: {
        type: SEARCH_PRODUCT_BY_ITEM_SLUG_AND_STORE_SLUG,
        endpoint: `/v3/search/item/${itemSlug}`,
        endpointParams: { storeSlug , bazar },
        schema: {
            data: Schemas.item
        }
    }
}));
