import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import BulkEdit from '../../components/BulkEdit';

function BulkEditScene() {
    return (
        <Container>
            <Header as='h1'>
                <Trans i18nKey='bulkEdit.header'>Bulk Edit</Trans>
            </Header>
            <BulkEdit />
        </Container>
    );
}

export default withTranslation('translations')(BulkEditScene);
