// @flow
import { normalize } from 'normalizr';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { identity } from 'lodash';

import { API_DATA, Authentication } from './constants';

import APIError from './APIError';
import { COOKIE_LANGUAGE, getCookie } from '../../utilities/cookies';
import { DEFAULT_LANGUAGE } from '../../utilities/languages';

const API_SERVER = (process.env.REACT_APP_MYBAZAR_API_SERVER || '').replace(/\/$/, '');
if (API_SERVER === '') {
    throw new Error('Must define REACT_APP_MYBAZAR_API_SERVER');
}

function toAPIError(msg) {
    return new APIError({ messages: msg.meta.message, code: msg.meta.code });
}

function decodeFetchJSON(res) {
    return res.ok ? res.json() : res.json().then((json) => Promise.reject(toAPIError(json)));
}

function toQueryString(params) {
    const paramKeys = Object.keys(params).filter((k) => params[k] !== null);
    const queryString = paramKeys.map((k) => [k, encodeURIComponent(String(params[k]))].join('=')).join('&');
    return paramKeys.length === 0 ? '' : `?${queryString}`;
}

/** Helper to map e.g. `entities: { users: [1, 2] }` to `entities: { users: { 1: {}, 2: {} } }` */
function actionEntitiesToEmptyBodies(entities) {
    const entitiesUpdate = {};
    Object.keys(entities).forEach((collection) => {
        entitiesUpdate[collection] = {};
        entities[collection].forEach((id) => {
            entitiesUpdate[collection][id] = {};
        });
    });
    return entitiesUpdate;
}

// Redux middleware ----------------------------------------
export default (store) => (next) => (action) => {
    if (!(API_DATA in action)) {
        return next(action);
    }

    let { endpoint } = action[API_DATA];
    const {
        type,
        schema = {},
        entities = {},
        // Process the API response before/after normalization
        preprocess = identity,
        postprocess = identity,
        decamelizeOptions = {},
        // HTTP specifics
        method = 'GET',
        body,
        endpointParams = {},
        authentication = null,
        optionalAuthentication = null,
        reducerParams = {},
        // callbacks
        callbacks = {
            onSuccess: () => {},
            onError: () => {}
        }
    } = action[API_DATA];

    if (typeof endpoint === 'function') {
        endpoint = endpoint(store.getState());
    }

    const actionWith = (data) => {
        const finalAction = { ...action, ...data };
        delete finalAction[API_DATA];
        return finalAction;
    };

    // Issue action for API request
    const entitiesUpdate = actionEntitiesToEmptyBodies(entities);
    next(
        actionWith({
            type,
            status: 'request',
            reducerParams,
            payload: {
                entities: entitiesUpdate
            }
        })
    );

    // Need to do an actual API request
    const url = [API_SERVER, endpoint, toQueryString(decamelizeKeys(endpointParams, decamelizeOptions))].join('');

    const cookie = getCookie(COOKIE_LANGUAGE);
    const selectedLanguage = cookie !== undefined ? cookie : DEFAULT_LANGUAGE;
    const microsoftCookie = getCookie('accessToken');
    const headers = {
        'Accept-Language': selectedLanguage
    };
    const opts = { method, headers };
    const userData = store.getState().userData;
    const merchantData = store.getState().merchantData;
    if (!authentication && microsoftCookie) {
        headers.access_token = microsoftCookie;
    }
    if (authentication) {
        switch (authentication) {
            case Authentication.User:
                headers.access_token = userData.accessToken || microsoftCookie || merchantData.accessToken;
                break;
            case Authentication.Merchant:
                headers.access_token = merchantData.accessToken || userData.accessToken;
                break;
            case Authentication.Agent:
                headers.access_token = userData.accessToken || merchantData.accessToken;
                break;
            default:
                break;
        }
    }
    if (optionalAuthentication) {
        switch (optionalAuthentication) {
            case Authentication.User: {
                const token = userData.accessToken;
                if (token) {
                    headers.access_token = token;
                }
                break;
            }
            case Authentication.Merchant: {
                const token = merchantData.accessToken;
                if (token) {
                    headers.access_token = token;
                }
                break;
            }
            default:
                break;
        }
    }

    if (body) {
        opts.body = JSON.stringify(decamelizeKeys(body, decamelizeOptions));
        headers['Content-Type'] = 'application/json';
    }

    const onFetchSuccess = (payload) => {
        next(actionWith({ type, status: 'success', payload, reducerParams }));
        setTimeout(() => {
            callbacks.onSuccess(payload);
        }, 0);
    };
    const onFetchError = (error) => {
        next(actionWith({ type, status: 'error', error, reducerParams }));
        setTimeout(() => {
            callbacks.onError(error);
        }, 0);
    };

    return fetch(url, opts)
        .then(decodeFetchJSON)
        .then((res) => res.results)
        .then(camelizeKeys)
        .then(preprocess)
        .then((res) => normalize(res, schema))
        .then(postprocess)
        .then(onFetchSuccess, onFetchError);
};
