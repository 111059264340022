// @flow
import { API_DATA, wrapAPIActionCreator } from 'services/api';

export type SignUpNewsletterParams = {
    email: string,
    name: string
};

export const SIGN_UP_NEWSLETTER = 'SIGN_UP_NEWSLETTER';
export const signUpNewsletter = wrapAPIActionCreator(({ email, name }: SignUpNewsletterParams) => ({
    [API_DATA]: {
        type: SIGN_UP_NEWSLETTER,
        endpoint: '/v3/news/letter/register',
        method: 'POST',
        body: {
            email,
            name
        },
        schema: {}
    }
}));
