// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
    direction: string,
    inverted: boolean,
    slickChanging: boolean,
    pauseOnHover: boolean,
    className: string,
    style: { [string]: string },
    onClick: () => void
};

function TeaserCarouselArrow({ direction, inverted, className, style, pauseOnHover, slickChanging, onClick }: Props) {
    return (
        <Button basic icon className={`${className} teaser-carousel-slick-arrow`} inverted={inverted} style={{ background: '#E9E9E9 !important', ...style }} onClick={onClick}>
            <Icon name={`${direction === 'prev' ? 'arrow left' : 'arrow right'}`} />
        </Button>
    );
}

export default withTranslation('translations')(TeaserCarouselArrow);
