// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Tab, Menu } from 'semantic-ui-react';

import Messages from 'components/Messages';
import Comments from 'components/Comments';
import { type MessagesTabOption } from './ProductMessagesTabContainer';
import { useSelector } from 'react-redux';
import {getIsNonTempUser} from "../../../../../../store/utilities";

type Props = {
    handleTabSelect: (tab: MessagesTabOption) => void,
    selectedTab: MessagesTabOption
};

function ProductMessagesTab(props: Props) {
    const state = useSelector((state) => state);
    const isRegularUser = getIsNonTempUser(state)
    const tabs = [
        {
            key: 'comments',
            title: 'Comments'
        },
        isRegularUser && {
            key: 'messages',
            title: 'Messages'
        }
    ].filter(Boolean);
    const { handleTabSelect, selectedTab, negotiationBox } = props;
    return !negotiationBox ? (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={tabs.map((tab) => ({
                menuItem: (
                    <Menu.Item key={tab.key} onClick={() => handleTabSelect(tab.title)}>
                        {tab.key === 'comments' ? 'Komentari' : 'Poruke'}
                    </Menu.Item>
                ),
                render: () => (
                    <Tab.Pane attached={false} className='simple'>
                        {selectedTab === 'Comments' && <Comments {...props} />}
                        {selectedTab === 'Messages' && <Messages {...props} />}
                    </Tab.Pane>
                )
            }))}
        />
    ) : (
        <Messages {...props} />
    );
}

export default withTranslation('translations')(ProductMessagesTab);
