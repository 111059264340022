// @flow
export * from './setIsLoggingIn';
export * from './setLocale';

export const ModalNames = {
    LOGIN: 'login',
    FORGOT_PASSWORD: 'forgotpassword',
    RESET_EMAIL_SENT: 'reset-email-sent',
    REGISTER_ALERT: 'register-alert',
    REGISTER_ACCOUNT: 'register-account',
    DELIVERY_ADDRESS_ADD: 'delivery-address-add',
    DELIVERY_ADDRESS_EDIT: 'delivery-address-edit',
    ADDRESS_ADD: 'address-add',
    ADDRESS_EDIT: 'address-edit',
    BANK_ACCOUNT_ADD: 'bank-account-add',
    BANK_ACCOUNT_EDIT: 'bank-account-edit',
    CART_ADD_PROMO_CODE: 'cart-add-promo-code',
    SENDFEEDBACK: 'send-feedback',
    ORDER_CANCEL: 'order-cancel',
    ORDER_ADD_TRACKING_NUMBER: 'order-add-tracking-number',
    LANGUAGE_SELECT: 'language-select',
    YOUTUBE: 'youtube',
    SHARE_CONTENT: 'share-content',
    REGISTER_SHIPMENT: 'register-shipment'
};

/** Open the modal with the specified internal name. */
export const OPEN_MODAL = 'OPEN_MODAL';
export function openModal(name: string, props: { [string]: * } = {}) {
    return {
        type: OPEN_MODAL,
        payload: { name, props }
    };
}

/** Close the modal with the specified internal name. */
export const CLOSE_MODAL = 'CLOSE_MODAL';
export function closeModal(name: string) {
    return {
        type: CLOSE_MODAL,
        payload: { name }
    };
}

/** Dismiss the PromoBar component on mobile devices */
export const DISMISS_PROMOBAR = 'DISMISS_PROMOBAR';
export function dismissPromoBar() {
    return {
        type: DISMISS_PROMOBAR
    };
}
