// @flow
import * as ActionTypes from 'store/actions';

const initialState = {
    hasMoreUsers: true
};

function usersByKeyword(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_USERS_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state,
                    usersByKeywordIsLoading: true
                };
            }
            if (action.status === 'success') {
                const { query, offset, result } = action.payload;
                const { users } = result;
                const { sort, limit } = action.reducerParams;
                let existingItems = [];
                let hasMoreUsers = true;

                if (state[query]) {
                    if (state[query].sort === sort) {
                        existingItems = [...state[query].existingItems];
                    }
                }

                if (offset === existingItems.length) {
                    existingItems = existingItems.concat(users);
                }

                if (users.length !== limit) {
                    hasMoreUsers = false;
                }

                return {
                    ...state,
                    hasMoreUsers,
                    [query]: { sort, existingItems },
                    usersByKeywordIsLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    usersByKeywordIsLoading: false
                };
            }
            break;
        default:
            break;
    }
    return state;
}

export default usersByKeyword;
