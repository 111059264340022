// @flow
import React from 'react';

import MyProfileInboxTabView from './MyProfileInboxTabView';

function MyProfileInboxTabContainer(props) {
    return <MyProfileInboxTabView {...props} />;
}

export default MyProfileInboxTabContainer;
