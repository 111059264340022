// @flow
import * as ActionTypes from '../../../actions';

import { shippingParamsToKey } from '../../../utilities';

const initialState = {};

function shippingCosts(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOOKUP_SHIPPING_COST: {
            const shippingParams = { ...action.reducerParams };
            const key = shippingParamsToKey(shippingParams);
            if (action.status === 'request') {
                return {
                    ...state,
                    [key]: {
                        details: null,
                        isLoading: true
                    }
                };
            }
            if (action.status === 'success') {
                return {
                    ...state,
                    [key]: {
                        details: { ...action.payload.result },
                        error: null,
                        isLoading: false
                    }
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    [key]: {
                        details: null,
                        error: action.error,
                        isLoading: false
                    }
                };
            }
            break;
        }
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default shippingCosts;
