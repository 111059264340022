// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import IframeView from 'components/IframeView';

const PromotionsPreviewView = ({ message }) => {
    const isLink = message.startsWith('http');
    if (isLink) {
        return <iframe style={{ border: '1px solid lightgray' }} src={message} title='promotions' width='100%' height={800} />;
    }
    return <IframeView sourceText={message} title='MyBazar' />;
};

export default PromotionsPreviewView;
