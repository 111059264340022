// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { push } from 'connected-react-router';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';
import { Redirect } from 'react-router-dom';

type Props = {
    navigateTo: typeof push
};

class PageNotFound extends Component<Props> {
    state = {
        redirect: false
    };

    handleSuccess = () => {
        const { navigateTo } = this.props;
        navigateTo('/');
    };

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                redirect: true
            });
        }, 2000);
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to='/' />;
        }

        return (
            <Container text className='narrow'>
                <Header as='h1'>
                    <Trans i18nKey='pageNotFound.header'>Page Not Found</Trans>
                </Header>
            </Container>
        );
    }
}

export default compose(withTranslation('translations'))(PageNotFound);
