// @flow

// HANDLE_TAB_CHANGE ------------------
export type HandleTabChange = {
    tab: number
};

export const HANDLE_TAB_CHANGE = 'HANDLE_TAB_CHANGE';
export const handleTabChange = (tab) => ({
    type: HANDLE_TAB_CHANGE,
    tab
});
