// @flow
import React, { Component } from 'react';
import { Grid, Button, Icon, Input } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import defaultCover from 'styles/assets/images/defaults/coverPhotoDefaultLow.jpg';
import { serializeFile } from 'utilities';

class MyStoreCover extends Component {
    state = {
        loading: false,
        file: {
            value: '',
            error: false
        },
        fileDataURI: null
    };

    handleChange = (event, { name, value }) => {
        const target = this.state[name];
        target.value = value;
        this.setState({ [name]: target });
    };

    handleChangeFile = (event, data) => {
        this.handleChange(event, data);
        const file = event.target.files[0];
        this.setState({ imageFile: file });
        serializeFile(file)
            .then((dataURI) => {
                this.props.onSubmitCoverPhoto(dataURI);
            })
            .catch(() => this.setState({ fileDataURI: null }));
    };

    render() {
        const { file, loading } = this.state;
        const { isUploadingPicture, coverPhotos, btnFit } = this.props;

        let coverPhotoUrl = defaultCover;
        if (coverPhotos && coverPhotos.length > 0) {
            coverPhotoUrl = coverPhotos[0].imageFull;
        }

        let btnClasses = btnFit ? 'btn-fit' : 'col-text-align-right';

        return (
            <Grid className='store-cover'>
                <div className='store-cover-image' style={{ backgroundImage: `url(${coverPhotoUrl})` }} />
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='tensed auto'>
                            <Grid.Column className={btnClasses}>
                                <Button as='label' icon labelPosition='left' htmlFor='my-store-cover-photo-file' disabled={isUploadingPicture} loading={isUploadingPicture}>
                                    <Icon name='camera' />
                                    <Trans i18nKey='myStore.cover.uploadCover'>Upload Cover</Trans>
                                </Button>
                                <Input
                                    required
                                    error={file.error}
                                    type='file'
                                    name='file'
                                    id='my-store-cover-photo-file'
                                    className='store-logo-input'
                                    value={file.value}
                                    disabled={loading}
                                    onChange={this.handleChangeFile}
                                />
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation('translations')(MyStoreCover);
