// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import ItemImageListItemMove from './components/ItemImageListItemMove';
import ItemImageListItemDelete from './components/ItemImageListItemDelete';

type Props = {
    item: Object,
    first: boolean,
    last: boolean,
    moveImage: () => void,
    deleteImage: () => void
};

function ItemImageListItem({ item, first, last, moveImage, deleteImage }: Props) {
    return (
        <div className='sell-item-image-list-item'>
            <Image fluid className='sell-item-image-list-item-image'>
                <img src={item.data} alt='' />
                <ItemImageListItemMove item={item} first={first} last={last} moveImage={moveImage} />
            </Image>
            <ItemImageListItemDelete item={item} deleteImage={deleteImage} />
        </div>
    );
}

export default withTranslation('translations')(ItemImageListItem);
