import React from 'react';
import { Grid } from 'semantic-ui-react';


const TagButtonsView = ({ tags }) => {
    return (
        <Grid className="auto">
            <Grid.Column>
                {tags.map(tag => {
                    return <a key={tag.toString()} href={`/search/tags/${tag}`} className="ui button product-tag-button tiny">{tag}</a>
                })}
            </Grid.Column>
        </Grid>
    )
}

export default TagButtonsView