// @flow

// SIGNUP_FACEBOOK_INFORMATION ------------------
export type HandleFacebookInformation = {
    information: {
        id: string,
        name: string,
        email: string
    }
};

export const SIGNUP_FACEBOOK_INFORMATION = 'SIGNUP_FACEBOOK_INFORMATION';
export const handleFacebookInformation = (information) => ({
    type: SIGNUP_FACEBOOK_INFORMATION,
    information
});
