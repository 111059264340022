// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type AddCartItemParams = {
    itemId: number,
    variantId?: string,
    offerId?: number,
    quantity?: number,
    forStep?: number
};

export const ADD_CART_ITEM = 'ADD_CART_ITEM';
export const addCartItem = wrapAPIActionCreator(({ itemId, variantId, offerId, quantity, forStep }: AddCartItemParams) => ({
    [API_DATA]: {
        type: ADD_CART_ITEM,
        endpoint: '/v3/cart_items',
        method: 'POST',
        authentication: Authentication.User,
        body: {
            itemId,
            variantId,
            offerId,
            quantity,
            forStep
        },
        schema: {
            updatedCartItems: [Schemas.cartItem]
        }
    }
}));

export default addCartItem;
