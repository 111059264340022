// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateCoverPhotoParams = {
    coverPhotos: [
        {
            data: string // Base64 url encoded image
        }
    ]
};

// UPDATE_COVER_PHOTO ----------------------
export const UPDATE_COVER_PHOTO = 'UPDATE_COVER_PHOTO';
export const updateCoverPhoto = wrapAPIActionCreator(({ coverPhotos }: UpdateCoverPhotoParams) => ({
    [API_DATA]: {
        type: UPDATE_COVER_PHOTO,
        endpoint: '/v3/me/profile/picture',
        authentication: Authentication.User,
        method: 'PUT',
        body: {
            coverPhotos
        }
    }
}));
