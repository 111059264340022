// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import ProductTeaser from 'components/ProductTeaser';

type Props = {
    items: number[],
    productId: number
};

function ProductList({ items, productId }: Props) {
    if (!(items && items.length)) {
        return null;
    }

    return (
        <Grid className='auto'>
            {items.map((id) => {
                if (id === productId) {
                    return null;
                } else {
                    return (
                        <Grid.Column key={id} className='full-width-list-item'>
                            <ProductTeaser itemId={id} />
                        </Grid.Column>
                    );
                }
            })}
        </Grid>
    );
}

export default ProductList;
