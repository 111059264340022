// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadCampaignsParams = {
    limit: number,
    offset: number
};

// LOAD_CAMPAIGNS ----------------------
export const LOAD_CAMPAIGNS = 'LOAD_CAMPAIGNS';
export const loadCampaigns = wrapAPIActionCreator(({ limit = 10, offset = 0 }: LoadCampaignsParams) => ({
    [API_DATA]: {
        type: LOAD_CAMPAIGNS,
        endpoint: '/v3/web/tabs/campaigns',
        endpointParams: { limit, offset },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: [Schemas.message],
        reducerParams: { limit, offset }
    }
}));
