// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

// LOAD_ITEMS_BY_USER ------------------
export type LoadItemsByUserParams = {
    userId: number,
    sort?: string,
    limit?: number,
    offset?: number,
    fields?: ('pictures' | 'merchant' | 'store' | 'is_liked' | 'comments')[]
};

export const LOAD_ITEMS_BY_USER = 'LOAD_ITEMS_BY_USER';
export const loadItemsByUser = wrapAPIActionCreator(({ userId, sort = 'latest', limit = 16, offset = 0, fields = [] }: LoadItemsByUserParams) => ({
    [API_DATA]: {
        type: LOAD_ITEMS_BY_USER,
        endpoint: `/v3/users/${userId}/items`,
        endpointParams: {
            sort,
            offset,
            limit,
            fields: fields.join(',')
        },
        reducerParams: {
            offset,
            limit,
            userId,
            sort
        },
        schema: {
            items: [Schemas.item]
        }
    }
}));

export default loadItemsByUser;
