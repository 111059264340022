import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withTranslation, useTranslation } from 'react-i18next';
import { Button, Grid, Header, Form, FormField, Message } from 'semantic-ui-react';
// import { useTranslation } from 'react-i18next';

//import ConnectedAccounts from 'components/ConnectedAccounts/ConnectedAccountsView';
import { BusinessTypes } from 'store/enums';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { history } from 'store';
// import { getCountry, getState, getCity } from 'store/utilities';
import BasicInfo from './BasicInfo';
import StoreSummary from './StoreSummary';
import StoreDetails from './StoreDetails';
import moment from 'moment';
import { formatDatePretty } from 'utilities/format';
import StoreProfileImages from 'components/StoreProfileImages';
// import { user } from 'services/api/schemas';
import StoreAddresses from 'components/StoreAddresses';
import BankAccounts from './scenes/MyStoreSettings/components/MyStoreSettingsForm/components/MyStoreSettingsBankAccount';
import { loadMyStoreSettings } from 'store/actions';

//import MyStoreSettingsBankView from './scenes/MyStoreSettings/components/MyStoreSettingsForm/components/MyStoreSettingsBankAccount';

const PAYOUT_TYPES = [
    {
        key: 'payoutTypes.startOfMonth.label',
        text: 'Every month on day 1',
        value: 0
    }
];

const defaultValidate = () => null;

const useInput = (initialValue, validate = defaultValidate) => {
    const [value, setValue] = useState(initialValue);
    const validationResult = useMemo(() => validate(value), [validate, value]);
    const [showError, setShowError] = useState(false);
    const error = (showError && validationResult) || null;
    return {
        value,
        setValue,
        valid: !validationResult,
        reset: () => {
            setValue('');
            setShowError(false);
        },
        fieldAttributes: {
            value,
            onChange: (e) => setValue(e.target.value),
            onBlur: () => setShowError(true),
            error
        }
    };
};

const PayoutSchedule = ({ payoutType, setPayoutType }) => {
    const { t } = useTranslation('translations');

    const payoutTypes = PAYOUT_TYPES.map(({ key, text, value }) => ({
        value,
        text: t(key, { defaultValue: text })
    }));
    return (
        <>
            <Form.Dropdown
                selection
                name='payout-type'
                id='payout-type'
                onChange={(_event, { value }) => {
                    setPayoutType(value);
                }}
                label={t('storeSettings.payoutSchedule.label', { defaultValue: 'Payout Schedule' })}
                value={payoutType}
                options={payoutTypes}
            />
            <FormField>
                <p>
                    {t('storeSettings.payoutSchedule.next', { defaultValue: 'Your next payout is estimated for' }) +
                        ' ' +
                        formatDatePretty(new Date(moment().add(1, 'month').startOf('month')), t)}
                </p>
                {/*<Header as='h5'>{t('storeSettings.payoutSchedule.viewBalance', { defaultValue: 'View your balance and payouts' })}</Header>*/}
            </FormField>
        </>
    );
};

const loadFreshMerchantStores = (setMerchantStores, loadMerchantStores) => {
    setMerchantStores('loading');
    loadMerchantStores()
        .then((res) => {
            setMerchantStores(res.result.stores);
        })
        .catch((err) => {
            setMerchantStores(false);
            console.log('ERROR(loadMerchantStores)', typeof err, err);
        });
};

const loadFreshMerchantBankAccounts = (setMerchantBankAccounts, loadMerchantBankAccounts) => {
    setMerchantBankAccounts('loading');
    loadMerchantBankAccounts()
        .then((res) => {
            // TODO DG Fix this, use selectors
            const bankAccountIds = res.result?.bankAccounts;
            const bankAccountsMap = res.entities?.bankAccounts;
            const bankAccounts = bankAccountIds.map((id) => bankAccountsMap[id]);

            console.log('Loaded bankAccounts', { res, bankAccountIds, bankAccountsMap, bankAccounts });
            setMerchantBankAccounts(bankAccounts);
        })
        .catch((err) => {
            setMerchantBankAccounts(false);
            console.log('ERROR(loadMerchantStores)', typeof err, err);
        });
};

const MyStoreSettingsView = ({ user, merchantUser, categories /* , countries, states */ }) => {
    const { t } = useTranslation('translations');

    //TODO: Don't use merchant property, we alredy have all users in /accounts respone
    const source = merchantUser || user;

    const [businessType, setBusinessType] = useState(merchantUser ? merchantUser.businessType : BusinessTypes.INDIVIDUAL);
    const [payoutType, setPayoutType] = useState(0);
    const [industry, setIndustry] = useState((merchantUser && typeof merchantUser.storeIndustry === 'number' && merchantUser.storeIndustry) || null);
    const [license, setLicense] = useState(
        (merchantUser && merchantUser.licenseName && merchantUser.licenseUrl && { name: merchantUser.licenseName, content: merchantUser.licenseUrl, saved: true }) || null
    );

    const dispatch = useDispatch();
    //const { t } = useTranslation();

    const updateMerchantAccount = wrapAPIDispatch(dispatch, actions.updateMerchantAccount);
    const createMerchantAccount = wrapAPIDispatch(dispatch, actions.createMerchantAccount);
    const loadBankAccounts = wrapAPIDispatch(dispatch, actions.loadBankAccounts);
    const createBankAccount = wrapAPIDispatch(dispatch, actions.createBankAccount);
    const updateBankAccount = wrapAPIDispatch(dispatch, actions.updateBankAccount);
    const deleteBankAccount = wrapAPIDispatch(dispatch, actions.deleteBankAccount);
    const updateProfile = wrapAPIDispatch(dispatch, actions.updateProfile);

    //TODO: Make preprocess work inside loadMerchantStores.js!! Add schema
    const loadMerchantStores = wrapAPIDispatch(dispatch, actions.loadMerchantStores);

    const emptyFieldValidation = (value) => (!value && t('storeSettings.requiredFieldError', { defaultValue: 'This field is required' })) || null;
    const validSlugRegex = /[^a-zA-Z0-9]/g;
    const validSlugValidation = (value) => {
        const isValid = value && !validSlugRegex.test(value);

        return (!isValid && t('storeSettings.invalidSlugError', { defaultValue: 'Invalid store slug' })) || null;
    };
    const validVatNumberRegex = /[^0-9]/g;
    const validVatNumberValidation = (value) => {
        const isValid = value && value.length === 13 && !validVatNumberRegex.test(value);
        return (!isValid && t('storeSettings.invalidVatError', { defaultValue: 'ID Number must contain 13 characters (digits)' })) || null;
    };

    const { value: alias, fieldAttributes: nameAttributes, valid: nameValid } = useInput(user.alias, emptyFieldValidation);
    const { value: email, fieldAttributes: emailAttributes, valid: emailValid } = useInput(source.email, emptyFieldValidation);
    const { value: phoneNumber, fieldAttributes: phoneAttributes, valid: phoneValid } = useInput(source.phoneNumber, emptyFieldValidation);
    const { value: name, fieldAttributes: businessNameAttributes, valid: businessNameValid } = useInput((merchantUser && merchantUser.alias) || '', emptyFieldValidation);
    const { value: vatNumber, fieldAttributes: vatAttributes, valid: vatValid } = useInput(source.gstRegistrationNumber || '', validVatNumberValidation);
    const { value: agentOfficeID, fieldAttributes: agentAttributes } = useInput(source.agentOfficeID || '');
    const { value: slug, fieldAttributes: slugAttributes, valid: slugValid } = useInput((merchantUser && merchantUser.slug) || '', validSlugValidation);
    const { value: description, fieldAttributes: bioAttributes } = useInput(source.description);
    const [visible, setVisible] = useState(source.openToCustomer === 1);
    const [negotiation, setNegotiation] = useState(source.allowNegotiate === 1);
    const [webNotification, setWebNotification] = useState(true);
    const [emailNotification, setEmailNotification] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [merchantStores, setMerchantStores] = useState(null);
    const [merchantBankAccounts, setMerchantBankAccounts] = useState(null);
    const [address, setAddress] = useState(null);
    const initialAddress = address && { ...address, houseNo: address.address, street: address.addressSecondary, zip: address.postal, id: 0 };
    const industries = useMemo(() => categories.map(({ id, name }) => ({ text: name, value: id })), [categories]);

    useEffect(() => {
        if (merchantStores === null) {
            loadFreshMerchantStores(setMerchantStores, loadMerchantStores);
        }
    }, [merchantStores, loadMerchantStores]);

    useEffect(() => {
        if (merchantBankAccounts === null) {
            loadFreshMerchantBankAccounts(setMerchantBankAccounts, loadBankAccounts);
        }
    }, [merchantBankAccounts, loadBankAccounts]);

    useEffect(() => {
        if (industry === null && industries.length) {
            setIndustry(industries[0].value);
        }
    }, [industries, industry]);

    const valid =
        businessType === 1
            ? nameValid && emailValid && phoneValid && businessNameValid && slugValid
            : nameValid && emailValid && phoneValid && businessNameValid && vatValid && slugValid;

    const onStoreAddressAdded = (results) => {
        console.log('onStoreAddressAdded', results);
        loadFreshMerchantStores(setMerchantStores, loadMerchantStores);
    };

    const onStoreAddressUpdated = (results) => {
        loadFreshMerchantStores(setMerchantStores, loadMerchantStores);
    };

    const onStoreAddressDeleted = (results) => {
        loadFreshMerchantStores(setMerchantStores, loadMerchantStores);
    };

    const refreshBankAccountsAfter = (action) => {
        return (params) =>
            action(params).then((actionRes) => {
                console.log({ actionRes });
                loadFreshMerchantBankAccounts(setMerchantBankAccounts, loadBankAccounts);
            });
    };

    const handleAddBankAccount = (params) => {
        dispatch(
            actions.openModal(actions.ModalNames.BANK_ACCOUNT_ADD, {
                onSubmit: refreshBankAccountsAfter(createBankAccount)
            })
        );
    };

    const handleEditBankAccount = (bankAccount) => {
        const { id } = bankAccount;
        console.log({ id, bankAccount });
        dispatch(
            actions.openModal(actions.ModalNames.BANK_ACCOUNT_EDIT, {
                bankAccount,
                onSubmit: refreshBankAccountsAfter(updateBankAccount),
                onDelete: refreshBankAccountsAfter(deleteBankAccount)
            })
        );
    };

    const handleSettingsSaved = () => {
        setIsSaving(false);
        setIsSaved(true);
        setTimeout(() => {
            setIsSaved(false);
            setIsError(false);
        }, 2000);
    };

    const handleSettingsError = () => {
        setIsSaving(false);
        setIsError(true);
        setTimeout(() => {
            setIsSaved(false);
            setIsError(false);
        }, 2000);
    };

    const handleSaveClick = () => {
        if (valid) {
            setIsSaving(true);
            setErrorMessage(false);
            const payload = {
                name,
                alias,
                slug,
                email,
                phoneNumber,
                description,
                allowNegotiate: negotiation,
                openToCustomer: visible,
                webNotification,
                emailNotification,
                businessType,
                vatNumber,
                agentOfficeID,
                storeIndustry: industry,
                address
            };
            if (license && !license.saved) {
                payload.licenseName = license.name;
                payload.licenseUrl = license.content;
            }
            if (!address && !merchantStores) {
                setErrorMessage('Molimo vas da unesete adresu kako bi mogli nastaviti sa kreiranjem profila ');
                setIsSaving(false);
                return;
            }
            if (merchantStores && merchantStores.length === 0) {
                setErrorMessage('Molimo vas da unesete adresu kako bi mogli nastaviti sa kreiranjem profila ');
                setIsSaving(false);
                return;
            }

            //TODO: We don't need to check if merchant property is set, we have all users in /accounts call response
            if (!!merchantUser) {
                const { email, username, phoneNumber } = user;
                const { alias } = payload;
                console.log('updateMerchantAccount', payload);
                if (alias !== user.alias) {
                    updateProfile({
                        alias,
                        username,
                        email,
                        phoneNumber,
                        password: undefined,
                        confirmPassword: undefined
                    }).catch((err) => {
                        console.log(err);
                    });
                }

                updateMerchantAccount(payload)
                    .then((result) => {
                        console.log('SUCCESS(updateMerchantAccount):', result);
                        setSuccessMessage('Promjene su uspješno spremljene.');
                        handleSettingsSaved();
                        //const { id } = result.result.item;
                        //this.setState({ isPosting: false, redirectTo: `/item/${id}` });
                    })
                    .catch((err) => {
                        console.log('ERROR(updateMerchantAccount):', err);
                        handleSettingsError();
                        setErrorMessage(err.messages[0]);
                        //this.setState({ isPosting: false, errorMessage: err.message })
                    });
            } else {
                payload.username = user.username.replace('@mybazar', '');
                console.log('createMerchantAccount', payload);
                createMerchantAccount(payload)
                    .then((result) => {
                        console.log('SUCCESS(createMerchantAccount):', result);
                        handleSettingsSaved();
                        setSuccessMessage('Profil uspješno kreiran.');
                        //const { id } = result.result.item;
                        //this.setState({ isPosting: false, redirectTo: `/item/${id}` });
                    })
                    .catch((err) => {
                        console.log('ERROR(createMerchantAccount):', err);
                        handleSettingsError();
                        setErrorMessage(err.messages[0]);
                        //this.setState({ isPosting: false, errorMessage: err.message })
                    });
            }
        }
    };

    return (
        <Form error={!!errorMessage} className={'store-settings-form'}>
            <Grid style={{ marginLeft: '45px', marginRight: '45px' }} stackable>
                <Message style={{ margin: '0 10px' }} error>
                    {errorMessage}
                </Message>
                {successMessage && (
                    <Message style={{ width: '100%' }} positive>
                        {successMessage}
                    </Message>
                )}
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h3'>{t('storeSettings.title', { defaultValue: 'Merchant Account Settings' })}</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column>
                        <>
                            {merchantStores === 'loading' ? (
                                <p>Loading photos...</p>
                            ) : !merchantStores ? (
                                ''
                            ) : (
                                <>
                                    <StoreProfileImages />
                                    <Button className='preview-profile-button' disabled={!merchantUser || !merchantUser.slug} onClick={() => history.push('/' + merchantUser.slug)}>
                                        {t('storeSettings.previewStore', { defaultValue: 'Preview Store' })}
                                    </Button>
                                </>
                            )}

                            <BasicInfo nameAttributes={nameAttributes} emailAttributes={emailAttributes} phoneAttributes={phoneAttributes} />
                            <StoreSummary
                                businessNameAttributes={businessNameAttributes}
                                businessType={businessType}
                                setBusinessType={setBusinessType}
                                vatAttributes={vatAttributes}
                                agentAttributes={agentAttributes}
                                license={license}
                                setLicense={setLicense}
                            />
                        </>
                    </Grid.Column>
                    <Grid.Column>
                        <StoreDetails
                            slugAttributes={slugAttributes}
                            bioAttributes={bioAttributes}
                            visible={visible}
                            setVisible={setVisible}
                            negotiation={negotiation}
                            setNegotiation={setNegotiation}
                            webNotification={webNotification}
                            setWebNotification={setWebNotification}
                            emailNotification={emailNotification}
                            setEmailNotification={setEmailNotification}
                            industry={industry}
                            setIndustry={setIndustry}
                            industries={industries}
                        />
                        {merchantStores === 'loading' ? (
                            <p>Loading stores...</p>
                        ) : !merchantStores ? (
                            <>
                                <StoreAddresses
                                    stores={[]}
                                    initialAddress={initialAddress}
                                    syncOnly={true}
                                    onStoreAddressAdded={(data) => setAddress(data)}
                                    onStoreAddressUpdated={(data) => setAddress(data)}
                                    onStoreAddressDeleted={() => Promise.resolve(setAddress(null))}
                                />
                            </>
                        ) : (
                            <>
                                <StoreAddresses
                                    stores={merchantStores}
                                    onStoreAddressAdded={onStoreAddressAdded}
                                    onStoreAddressUpdated={onStoreAddressUpdated}
                                    onStoreAddressDeleted={onStoreAddressDeleted}
                                />
                            </>
                        )}
                    </Grid.Column>
                    <Grid.Column>
                        <PayoutSchedule payoutType={payoutType} setPayoutType={setPayoutType} />
                        {merchantStores === 'loading' ? (
                            <p>Loading bank accounts...</p>
                        ) : merchantBankAccounts?.length > 0 ? (
                            <Form.Field>
                                <Button onClick={() => handleEditBankAccount(merchantBankAccounts[0])}>{t('bankAccount.edit.title', { defaultValue: 'Edit Bank Account' })}</Button>
                            </Form.Field>
                        ) : !merchantStores ? (
                            ''
                        ) : (
                            <Form.Field>
                                <Button onClick={handleAddBankAccount}>{t('bankAccount.add.title', { defaultValue: 'Add Bank Account' })}</Button>
                            </Form.Field>
                        )}
                        {/* <BankAccounts /> */}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button color='black' disabled={isSaving || isSaved} floated='right' onClick={handleSaveClick}>
                            {isSaving
                                ? t('saveChanges.doing', { defaultValue: 'Saving...' })
                                : isSaved
                                ? t('saveChanges.done', { defaultValue: 'Saved' })
                                : isError
                                ? t('saveChanges.error', { defaultValue: 'Error occurred' })
                                : !!merchantUser ? t('saveChanges.label', { defaultValue: 'Save changes' }) : 'Kreiraj profil'}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default withTranslation('translations')(MyStoreSettingsView);
