// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Header, Button, Loader, Segment } from 'semantic-ui-react';

import type { EntityData, LandingComponent as LandingComponentType } from 'store/types';

type Props = {
    component: EntityData<LandingComponentType>,
    title: string
};

function LandingTagsView({ component, title }: Props) {
    if (component.isFetching || component.entity == null) {
        return <Loader active inline='centered' />;
    }

    const { entities: tags } = component.entity;

    return (
        <Segment className='simple teaser-carousel tags'>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    <Grid className='auto vertically-tensed grid-align-items-center teaser-carousel-header'>
                        <Grid.Column className='col-row'>
                            <Header style={{ fontSize: '1.5rem' }} as='h3'>
                                {title}
                            </Header>
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            <Grid className='auto tensed'>
                                {tags.map(({ tag }) => (
                                    <Grid.Column key={tag}>
                                        <Button className='tag-button' basic fluid as={Link} to={`/search/tags/${tag}`}>
                                            {tag}
                                        </Button>
                                    </Grid.Column>
                                ))}
                            </Grid>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default LandingTagsView;
