// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/actions';

import SellItemImagesView from './SellItemImagesView';

type Props = {};

class SellItemFormItemContainer extends Component<Props> {
    render() {
        const { formId, ...rest } = this.props;
        return <SellItemImagesView {...rest} formId={`${formId}.images`} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(null, mapDispatchToProps)(SellItemFormItemContainer);
