import React, { Component } from 'react';

import type { EntityData, User } from 'store/types';

import ProductStoreView from './ProductStoreView';

type Props = {
    merchant: EntityData<User>
};

class ProductStoreContainer extends Component<Props> {
    render() {
        const { merchant } = this.props;

        if (merchant) {
            return <ProductStoreView merchant={merchant} />;
        }
        return null;
    }
}

export default ProductStoreContainer;
