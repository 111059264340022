// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type AddAddressParams = {
    fullName: string,
    emailAddress: string,
    contactNo: string,
    street: string,
    houseNo: string,
    zip: string,
    country: string,
    state: string,
    city: string,
    country_id: number,
    state_id: number,
    city_id: number
};

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const addAddress = wrapAPIActionCreator(
    ({ fullName, emailAddress, contactNo, street, houseNo, zip, country, state, city, country_id, state_id, city_id }: AddAddressParams) => ({
        [API_DATA]: {
            type: ADD_ADDRESS,
            endpoint: '/v3/addresses',
            method: 'POST',
            authentication: Authentication.User,
            body: {
                fullName,
                emailAddress,
                contactNo,
                street,
                houseNo,
                zip,
                country,
                state,
                city,
                country_id,
                state_id,
                city_id
            },
            schema: [Schemas.userDeliveryAddress]
        }
    })
);

export default addAddress;
