// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from '../../enums';

export type LoginUserParams = {
    email: string,
    password: string,
    deviceType?: $Values<typeof DeviceTypes>,
    pushNotificationToken?: string
};

export const LOGIN_USER = 'LOGIN_USER';
export const loginUser = wrapAPIActionCreator(({ email, password, deviceType = DeviceTypes.WEB, pushNotificationToken = 'x' }: LoginUserParams) => ({
    [API_DATA]: {
        type: LOGIN_USER,
        endpoint: '/v3/auth/login',
        method: 'POST',
        body: {
            email,
            password,
            deviceType,
            pushNotificationToken
        },
        preprocess: ({ user: { accessToken, ...rest } }) => ({ user: rest, accessToken }),
        schema: {
            user: Schemas.user
        }
    }
}));
