// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Tab, Menu } from 'semantic-ui-react';

import Campaigns from 'components/Campaigns';

const MyStoreCampaignsTabView = (props) => {
    const tabs = [
        {
            key: 'campaigns',
            title: 'Campaigns'
        }
    ];
    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={tabs.map((tab) => ({
                menuItem: (
                    <Menu.Item key={tab.key}>
                        <Trans i18nKey={`myStore.campaigns.tab.${tab.key}`}>{tab.title}</Trans>
                    </Menu.Item>
                ),
                render: () => (
                    <Tab.Pane attached={false} className='simple'>
                        <Campaigns {...props} />
                    </Tab.Pane>
                )
            }))}
        />
    );
};

export default withTranslation('translations')(MyStoreCampaignsTabView);
