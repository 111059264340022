// @flow
import React from 'react';
import { Button, Form, Grid, Message, Header, Icon, FormField } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import CommonForm from 'components/CommonForm';
import StoreAddresses from 'components/StoreAddresses';

import type { UpdateMerchantAccountParams } from 'store/actions';
import type { EntityData, User } from 'store/types';
import MyStoreSettingsFormStore from './components/MyStoreSettingsFormStore';
import MyStoreSettingsFormOptions from './components/MyStoreSettingsFormOptions';
import MyStoreSettingsFormBusinessType from './components/MyStoreSettingsFormBusinessType';
import MyStoreSettingsBankAccount from './components/MyStoreSettingsBankAccount';

type Props = {
    updateMerchantAccount: (UpdateMerchantAccountParams) => void,
    merchant: EntityData<User>,
    t: (string) => string
};

type State = {
    isPosting: false,
    errorMessage: ?string
};

class MyStoreSettingsFormView extends CommonForm<Props, State> {
    // state = {
    //   isPosting: false,
    //   errorMessage: null,
    //   formValues: {},
    // };

    // handleSubmit = (event) => {
    //   event.preventDefault();

    //   if (!this.validate()) {
    //     return;
    //   }

    //   const { getFormValues, updateMerchantAccount, loadMyAccounts, merchant } = this.props;
    //   const { email, username, phoneNumber } = merchant;

    //   const storeSection = getFormValues('myStore.form.store');
    //   const optionsSection = getFormValues('myStore.form.options');
    //   const businessTypeSection = getFormValues('myStore.form.businessType');

    //   if (!optionsSection || !storeSection || !businessTypeSection) {
    //     console.warn('Forms not connected properly to Redux store');
    //     return;
    //   }
    //   const {
    //     name: { value: name },
    //     slug: { value: slug },
    //     description: { value: description },
    //   } = storeSection.formValues;

    //   const {
    //     allowNegotiations: { value: allowNegotiations },
    //     isOpenToCustomers: { value: isOpenToCustomers },
    //   } = optionsSection.formValues;

    //   const {
    //     businessType: { value: businessType },
    //     vatNumber: { value: vatNumber },
    //     fileName,
    //     fileDataURI,
    //   } = businessTypeSection.formValues;

    //   const params = {
    //     name,
    //     phoneNumber,
    //     slug,
    //     email,
    //     description,
    //     businessType,
    //     username: username.replace('@mybazar', ''), // Note: Do not use formatUsername because that adds an @ to the username each time it's saved.
    //     openToCustomer: isOpenToCustomers ? 1 : 0,
    //     allowNegotiate: allowNegotiations ? 1 : 0,
    //     vatNumber,
    //   };

    //   if (fileName && fileDataURI) {
    //     params.licenseName = fileName;
    //     params.licenseUrl = fileDataURI;
    //   }
    //   this.setState({ isPosting: true });
    //   updateMerchantAccount(params)
    //     .then(() => loadMyAccounts({ fields: ['user_settings'] }))
    //     .then(() => this.setState({ isPosting: false, redirectTo: `/my-store` }))
    //     .catch(err => this.setState({ isPosting: false, errorMessage: err.message }));
    // }

    render() {
        // const { updateFormValues, merchant, settings, t } = this.props;
        // const { errorMessage, redirectTo, isPosting } = this.state;
        // const { stores } = settings;

        // if (redirectTo) {
        //   return (
        //     <Redirect to={redirectTo} />
        //   );
        // }

        return (
            <Form
            // onSubmit={this.handleSubmit} error={errorMessage != null}
            >
                <Form.Input label='Legal Name Of Business' value='' />
                <Form.Dropdown
                    selection
                    name='businessType'
                    id='-businessType'
                    onChange={() => {}}
                    // label={t('address.form.country.label')}
                    // placeholder={t('address.form.country.placeholder', {
                    //   defaultValue: '',
                    // })}
                    label={'Business Type'}
                    placeholder={'Registered business with license'}
                    value={''}
                    options={''}
                    required
                />
                <Button style={{ marginBottom: '15px' }}>
                    <Icon name='attach' />
                    Upload file
                </Button>
                <Form.Input label='VAT Number' />
                <Form.Input label='Agent ID' />

                <FormField>
                    <Header as='h5'>Member Since</Header>
                    <p style={{ marginTop: '-10px' }}>6 May, 2020</p>
                </FormField>

                <FormField>
                    <Header as='h5'>Current Plan</Header>
                    <p style={{ marginTop: '-10px' }}>Local Business/Monthly store fee</p>
                </FormField>

                <FormField>
                    <Header as='h5'>Status</Header>
                    <p style={{ marginTop: '-10px' }}>Active</p>
                </FormField>
                <Form.Dropdown
                    selection
                    name='payoutSchedule'
                    id='-payoutSchedule'
                    onChange={() => {}}
                    // label={t('address.form.country.label')}
                    // placeholder={t('address.form.country.placeholder', {
                    //   defaultValue: '',
                    // })}
                    label={'Payout Schedule'}
                    placeholder={'Every month on day 1'}
                    value={''}
                    options={''}
                    required
                />
                <FormField>
                    <p style={{ marginBottom: '-25px' }}>Your next payout is estimated for 1 April, 2020</p>
                    <Header as='h5'>View your balance and payouts</Header>
                </FormField>
            </Form>
        );
    }
}

export default withTranslation('translations')(MyStoreSettingsFormView);
