// @flow
import React, { Component } from 'react';
import { Button, Form, Label, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';

type FormFieldData = {
    value: string,
    error: boolean
};

type Props = {
    isLoggedIn: boolean,
    forgotPassword: () => void,
    t: (string) => string
};

type State = {
    loading: boolean,
    hasTriggered: boolean,
    errorMessage: ?string,
    username: FormFieldData
};

class ResetPassword extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hasTriggered: false,
            errorMessage: null,
            shouldRedirect: false,
            username: {
                value: '',
                error: false
            }
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isLoggedIn && !this.state.hasTriggered) {
        }
    }

    handleChange = (event) => {
        const target = this.state[event.target.name];
        target.value = event.target.value;
        this.setState({ [event.target.name]: target });
    };

    handleBlur = (event) => {
        this.validate(event.target.name).catch((err) => console.warn(err));
    };

    handleSubmit = (event) => {
        const { forgotPassword, passwordSent } = this.props;
        const {
            username: { value: username }
        } = this.state;

        event.preventDefault();
        this.setState({ loading: true });

        this.validate('username')
            .then(() => forgotPassword({ email: username }))
            .then(() => {
                this.setState({
                    loading: false
                });
                passwordSent();
            })
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    validate = (...inputs) => {
        let valid = true;
        inputs.forEach((input) => {
            const target = this.state[input];
            target.error = target.value.length === 0;
            if (target.error) {
                valid = false;
            }
            this.setState({ [input]: target });
        });
        return valid ? Promise.resolve() : Promise.reject(new Error('Some fields were invalid'));
    };

    render() {
        const { errorMessage, shouldRedirect } = this.state;
        const { t } = this.props;
        return (
            <div>
                {shouldRedirect && <Redirect to='/reset-email-sent' push />}
                <Form onSubmit={this.handleSubmit} error={errorMessage != null}>
                    <Message error>{errorMessage}</Message>
                    <Form.Field required error={this.state.username.error} className='forgot-password'>
                        <input
                            type='text'
                            name='username'
                            id='forgot-password'
                            placeholder={t('resetPasswordForm.email.placeholder', { defaultValue: 'Enter your email address' })}
                            value={this.state.username.value}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            disabled={this.state.loading}
                        />
                        {this.state.username.error && (
                            <Label basic color='red' pointing>
                                <Trans i18nKey='resetPasswordForm.username.error.required'>This field is required.</Trans>
                            </Label>
                        )}
                    </Form.Field>
                    <Form.Field>
                        <Button type='submit' fluid primary loading={this.state.loading} disabled={this.state.loading}>
                            <Trans i18nKey='resetPasswordForm.submit'>Reset Password</Trans>
                        </Button>
                    </Form.Field>
                </Form>
            </div>
        );
    }
}

export default withTranslation('translations')(ResetPassword);
