// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import { wrapAPIDispatch } from 'services/api';
// import * as actions from 'store/actions';
import { getMessage, getUser } from 'store/utilities';

import MessagesConversationMenuItemView from './MessagesConversationMenuItemView';

type Props = {};

class MessagesConversationMenuItemContainer extends Component<Props> {
    componentDidMount() {}

    render() {
        const { conversation, lastMessage, user } = this.props;
        return <MessagesConversationMenuItemView conversation={conversation.entity} lastMessage={lastMessage.entity} user={user.entity} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    const { conversation = {} } = ownProps;
    const { lastMessage: lastMessageId } = conversation.entity;
    const { user: userId } = conversation.entity;

    return {
        lastMessage: getMessage(state, lastMessageId),
        user: getUser(state, userId)
    };
};

export default compose(connect(mapStateToProps))(MessagesConversationMenuItemContainer);
