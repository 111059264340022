// @flow
import React from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';

import CommonForm from 'components/CommonForm';
import ItemFormItem from './components/ItemFormItem';
import ItemFormVariants from './components/ItemFormVariants';
import ItemFormPrice from './components/ItemFormPrice';
import ItemFormDelivery from './components/ItemFormDelivery';

import { shippingToListDefaults } from './components/ItemFormDelivery/components/ShippingTo';

type Props = {
    t: (string) => string
};

type State = {
    isPosting: boolean,
    errorMessage: ?string
};

class ItemFormView extends CommonForm<Props, State> {
    handleSubmit = () => {
        const { onSubmit, item, getCategoryAttribute } = this.props;

        if (!this.validate()) {
            return;
        }
        const { getFormValues } = this.props;

        const itemSection = getFormValues('sellItem.form.create.item');
        const imagesSection = getFormValues('sellItem.form.create.item.images');
        const priceSection = getFormValues('sellItem.form.create.price');
        const variantsSection = getFormValues('sellItem.form.edit.variants');
        const deliverySection = getFormValues('sellItem.form.create.delivery');
        const shippingToSection = getFormValues('sellItem.form.create.delivery.shippingTo');

        if (!itemSection || !imagesSection || !priceSection || !deliverySection || !shippingToSection || !variantsSection) {
            console.warn('Forms not connected properly to Redux store');
            return;
        }

        const { id } = item;

        const {
            title: { value: name },
            category: { value: categoryParent },
            subcategory: { value: category },
            subSubcategory: { value: categoryChild },
            description: { value: description },
            tags: { value: tags },
            isBanned: { value: isBanned }
        } = itemSection.formValues;

        let { pictures } = imagesSection.formValues;

        pictures = pictures.map(({ index, data, id }) => ({
            index,
            data,
            id
        }));

        const variantForms = variantsSection.formValues.variants;

        const variants = variantForms
            .filter((form) => !form.deleted)
            .map((form) => {
                const variantSection = getFormValues(form.formId);
                const {
                    sku: { value: sku },
                    quantity: { value: quantity },
                    id: { value: variantId },
                    ...attributeValues
                } = variantSection.formValues;
                const variant = {
                    sku,
                    quantity
                };
                if (variantId && variantId !== '') {
                    variant.id = variantId;
                }
                const variantBody = {};
                Object.values(attributeValues).forEach(({ value, id }) => {
                    const attribute = getCategoryAttribute(id);
                    variantBody[attribute.name] = value;
                });
                variant.variantBody = variantBody;
                return variant;
            });

        const inStock = variants.reduce((stock, variant) => {
            if (+variant.quantity) {
                stock = true;
            }
            return stock;
        }, false);

        const status = isBanned ? 4 : !inStock ? 1 : 0; // 4-banned, 1-soldOut, 0-active

        const {
            price: { value: price },
            applyVAT: { value: applyVAT },
            dealType: { value: dealType },
            salePrice: { value: salePrice }
        } = priceSection.formValues;

        const isDeal = dealType !== 'none';
        const isFixedPrice = false;

        const dealTypes = {
            buy_3_for_2: false,
            sale: dealType === 'sale' ? parseFloat(salePrice) : 0,
            saleGross: dealType === 'sale' ? parseFloat(salePrice) : 0,
            sample: false
        };

        const {
            weight: { value: weight },
            weightType: { value: weightType },
            shippingFrom: { value: shippingFrom }
        } = deliverySection.formValues;

        const shippingToOptions = shippingToSection.formValues;

        const shippingDetails = [];

        Object.keys(shippingToOptions).forEach((id) => {
            const field = shippingToOptions[id];
            if (field.value === true) {
                const optionValues = getFormValues(`sellItem.form.create.delivery.shippingTo.${id}.details`).formValues;
                const {
                    returnPolicy: { value: returnPolicy },
                    merchantWillCoverCost: { value: merchantWillCoverCost },
                    minimumDays: { value: minimumDays },
                    maximumDays: { value: maximumDays },
                    estimatedDeliveryCost: { value: estimatedDeliveryCost }
                } = optionValues;

                const shippingDefaults = shippingToListDefaults.find((item) => item.id === id);

                const optionDetails = {
                    delivery: shippingDefaults.delivery,
                    estimatedDeliveryCost: parseFloat(estimatedDeliveryCost),
                    estimatedDeliveryTimeEnd: Number(maximumDays),
                    estimatedDeliveryTimeStart: Number(minimumDays),
                    isMerchantPay: merchantWillCoverCost,
                    itemShippingDetailKey: shippingDefaults.key,
                    payment: shippingDefaults.payment,
                    returnPolicyId: returnPolicy,
                    shipFromId: shippingFrom,
                    shipToId: shippingDefaults.shipToId,
                    shipment: shippingDefaults.shipment,
                    title: shippingDefaults.title
                };
                shippingDetails.push(optionDetails);
            }
        });

        const params = {
            id,
            name,
            status,
            categoryParent,
            category,
            categoryChild,
            pictures,
            variants,
            dealTypes,
            description,
            tags,
            isDeal,
            isFixedPrice,
            price: parseFloat(price),
            gst: applyVAT,
            shippingDetails,
            storeId: shippingFrom,
            weight,
            weightType
        };

        if (onSubmit) {
            this.setState({ isPosting: true });
            onSubmit(params)
                .then(() => {
                    this.setState({ isPosting: false, redirectTo: `/item/${id}` }, () => {
                        this.props.resetEntity({ entityType: 'items', entityId: id });
                    });
                })
                .catch((err) => this.setState({ isPosting: false, errorMessage: err.message }));
        }
    };

    render() {
        const { errorMessage, redirectTo, isPosting } = this.state;

        const { item, updateFormValues } = this.props;

        if (redirectTo) {
            return <Redirect to={redirectTo} />;
        }

        return (
            <Form onSubmit={this.handleSubmit} error={errorMessage != null}>
                <Message error>{errorMessage}</Message>
                <Grid>
                    <Grid.Column>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ItemFormItem
                                                    formId='sellItem.form.create.item'
                                                    translationId='sellItem.form.item'
                                                    item={item}
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ItemFormVariants
                                                    item={item}
                                                    formId='sellItem.form.edit.variants'
                                                    translationId='sellItem.form.variant'
                                                    updateFormValues={updateFormValues}
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ItemFormPrice
                                                    item={item}
                                                    formId='sellItem.form.create.price'
                                                    translationId='sellItem.form.price'
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <ItemFormDelivery
                                                    item={item}
                                                    formId='sellItem.form.create.delivery'
                                                    translationId='sellItem.form.delivery'
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ justifyContent: 'center' }}>
                                <Grid.Column width={8}>
                                    {/* <Form.Field>
                    <Popup
                      position="top center"
                      trigger={
                        <Button fluid primary basic>
                          <Trans i18nKey="sellItem.form.schedule">Schedule to Publish Item</Trans>
                        </Button>
                      }
                    >
                      <Popup.Content>
                        <Trans i18nKey="sellItem.form.scheduleInformation">Schedule to publish item at a later date.</Trans>
                      </Popup.Content>
                    </Popup>
                  </Form.Field> */}
                                    <Form.Field>
                                        <Button type='submit' fluid primary loading={isPosting}>
                                            <Trans i18nKey='sellItem.form.edit.submit'>Save updates</Trans>
                                        </Button>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default withTranslation('translations')(ItemFormView);
