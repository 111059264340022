// @flow

export const PaymentStatus = {
    FAILURE: 0,
    SUCCESS: 1,
    CANCEL: 2
};
export type PaymentStatusType = $Values<typeof PaymentStatus>;

export const ShipmentOptions = {
    OWN_SHIPMENT: 0,
    GDEX: 1,
    POSLAJU: 2,
    TAQBIN: 3,
    POSTA_BIH: 4,
    EXPRESSONE: 5,
    EUROEXPRESS: 6
};

export const DeliveryOptions = {
    PICKUP: 1,
    MERCHANT: 2,
    MY_BAZAR: 3
};

export const MessageTypes = {
    MESSAGE: 1,
    RE_LISTED: 2,
    OFFER: 3,
    COUPON: 4,
    ORDER: 5,
    RECEIPT: 6,
    PICTURE: 7,
    ORDER_CANCELLED: 8
};

export const OfferStatus = {
    OPEN: 1,
    CLOSED: 2,
    ACCEPTED: 3,
    EXPIRED: 4
};

export const BusinessTypes = {
    INDIVIDUAL: 1,
    BUSINESS: 2
};

export const DeviceTypes = {
    IOS: 1,
    ANDROID: 2,
    WEB: 3
};

export const UserTypes = {
    MERCHANT: 1,
    SHOPPER: 2,
    ADMIN: 3
};
