import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';

import { COOKIE_LANGUAGE, getCookie } from 'utilities/cookies';
const cookie = getCookie(COOKIE_LANGUAGE);

import translationEN from './locales/en/translations.json';
import translationBS from './locales/bs/translations.json';
import translationSV from './locales/sv/translations.json';

const resources = {
    en: {
        translations: translationEN
    },
    bs: {
        translations: translationBS
    },
    sv: {
        translations: translationSV
    }
};

console.log('process.env.REACT_APP_FEATURE_LOCIZE', process.env.REACT_APP_FEATURE_LOCIZE === 'true', process.env);

const locizeOptions = {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
    referenceLng: 'en'
};

i18n.use(Backend)
    .use(initReactI18next)
    .init({
        resources,
        lng: 'bs',
        fallbackLng: 'bs',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',
        debug: false,
        saveMissing: false,

        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        backend: locizeOptions,
        locizeLastUsed: locizeOptions,
        editor: {
            // old locize editor (will be removed in future)
            ...locizeOptions,
            onEditorSaved: async (lng, ns) => {
                // reload that namespace in given language
                await i18n.reloadResources(lng, ns);
                // trigger an event on i18n which triggers a rerender
                // based on bindI18n below in react options
                i18n.emit('editorSaved');
            }
        },
        react: {
            bindI18n: 'languageChanged editorSaved'
        }
    });

export default i18n;
