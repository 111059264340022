// @flow

type Params = {
    code: number,
    messages: string[]
};

class APIError extends Error {
    code = 0;

    messages = [];

    constructor(params: Params) {
        const { code, messages } = params;
        super(messages.join(' '));
        this.code = code;
        this.messages = messages;
    }
}

export default APIError;
