import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonGroup, Checkbox, Grid, Header, Icon, Image } from 'semantic-ui-react';
import { ReactComponent as Agent } from '../../styles/assets/icons/agent-icon.svg';
import { ReactComponent as Store } from '../../styles/assets/icons/store-icon.svg';

const ConnectedAccounts = () => {
    const [isActiveProfile, setIsActiveProfile] = useState('');
    const { t } = useTranslation('translations');

    useEffect(() => {
        const activeProfile = localStorage.getItem('activeProfile');
        setIsActiveProfile(activeProfile);
    }, [localStorage.getItem('activeProfile')]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {isActiveProfile === 'Merchant' ? (
                        <>
                            {' '}
                            <Store onClick={() => setActiveProfile('Merchant')} className='select-profile-icon' style={{ marginBottom: '20px' }} />
                            <div
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    right: '-13rem',
                                    top: '11rem',
                                    width: '43px',
                                    height: '35px',
                                    backgroundColor: '#e9e9e9',
                                    color: '#888888'
                                }}
                            >
                                <Icon style={{ marginRight: '0px' }} size='big' name='photo' />
                            </div>
                        </>
                    ) : isActiveProfile === 'Agent' ? (
                        <>
                            <Agent onClick={() => setActiveProfile('Merchant')} className='select-profile-icon' style={{ marginBottom: '20px' }} />
                            <div
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    right: '-13rem',
                                    top: '11rem',
                                    width: '43px',
                                    height: '35px',
                                    backgroundColor: '#e9e9e9',
                                    color: '#888888'
                                }}
                            >
                                <Icon style={{ marginRight: '0px' }} size='big' name='photo' />
                            </div>
                        </>
                    ) : null}
                </Grid.Column>
                <Grid.Column computer={16}>
                    <Header as='h4'>{t('connectedAccounts.title', { defaultValue: 'Connected Accounts' })}</Header>
                </Grid.Column>
                <Grid.Column computer={16}>
                    <p>{t('connectedAccounts.inviteToConnect.label', { defaultValue: 'Connecting will allow your friends to find you on MyBazar.' })}</p>
                </Grid.Column>
                <Grid.Column computer={12} tablet={16} mobile={10}>
                    <Button className='social-network-btn' color='facebook' fluid>
                        <Icon name='facebook' />
                        <Trans i18nKey='logInFacebook.button'>Log in with Facebook</Trans>
                    </Button>
                    <Button className='instagram-btn' fluid>
                        <Icon name='instagram' />
                        <Trans i18nKey='logInInstagram.button'>Log in with Instagram</Trans>
                    </Button>
                    <Button className='social-network-btn' color='google plus' fluid>
                        <Icon name='google' />
                        <Trans i18nKey='logInGoogle.button'>Log in with Google</Trans>
                    </Button>

                    {isActiveProfile === 'Customer' && (
                        <>
                            {' '}
                            <Header as='h4'>{t('connectedAccounts.profileVisiblity.checkbox', { defaultValue: 'Profile Visibility' })}</Header>
                            <Checkbox
                                // checked={dimmed}
                                // onChange={(e, { checked }) =>
                                //   dispatch({ type: 'CHANGE_DIMMED', dimmed: checked })
                                // }
                                toggle
                            />{' '}
                        </>
                    )}

                    {isActiveProfile !== 'Merchant' && (
                        <>
                            {' '}
                            <Header as='h4'>{t('connectedAccounts.webNotifications.checkbox', { defaultValue: 'Web Notifications' })}</Header>
                            <Checkbox
                                // checked={dimmed}
                                // onChange={(e, { checked }) =>
                                //   dispatch({ type: 'CHANGE_DIMMED', dimmed: checked })
                                // }
                                toggle
                            />
                            <Header as='h4'>{t('connectedAccounts.emailNotifications.checkbox', { defaultValue: 'Email Notifications' })}</Header>
                            <Checkbox
                                // checked={dimmed}
                                // onChange={(e, { checked }) =>
                                //   dispatch({ type: 'CHANGE_DIMMED', dimmed: checked })
                                // }
                                toggle
                            />
                        </>
                    )}
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ConnectedAccounts;
