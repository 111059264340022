// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import AddressAddModalView from './AddressAddModalView';

type Props = {
    // from store
    addAddress: typeof actions.addAddress,
    openModal: typeof actions.openModal
};

class AddressAddModalContainer extends Component<Props> {
    // handleSubmit = ({
    //   name, email, phone, address, addressSecondary, postal, country, state, city,
    // }) => {
    //   const { addAddress } = this.props;
    //   return addAddress({
    //     fullName: name,
    //     emailAddress: email,
    //     contactNo: phone,
    //     street: address,
    //     houseNo: addressSecondary,
    //     zip: postal,
    //     country,
    //     state,
    //     city,
    //   });
    // }

    render() {
        return <AddressAddModalView {...this.props} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    addAddress: wrapAPIDispatch(dispatch, actions.addAddress),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(AddressAddModalContainer);
