// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import MyStoreItemsTab from './components/MyStoreItemsTab';

type Props = {
    merchant: EntityTypes<User>,
    items: {
        active: array,
        activeCount: number,
        banned: array,
        bannedCount: number,
        inactive: array,
        inactiveCount: number,
        soldout: array,
        soldoutCount: number
    },
    loadMoreItems: Function
};

function MyStoreItems({ merchant, items, loadMoreItems, location, authentication }: Props) {
    return (
        <Container className='my-store-items'>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Header as='h1'>
                            <Trans i18nKey='myStore.items.header'>My Store Items</Trans>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Grid className='tensed auto'>
                            <Grid.Column className='col-text-align-right'>
                                <Button icon labelPosition='left' as={Link} to='/bulk-edit'>
                                    <Icon name='file image outline' />
                                    <Trans i18nKey='myStore.actions.bulkEdit'>Bulk Edit</Trans>
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <MyStoreItemsTab items={items} loadMoreItems={loadMoreItems} location={location} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default withTranslation('translations')(MyStoreItems);
