// @flow
import React from 'react';
import { Form, Button, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm from 'components/CommonForm';
import ItemFormVariantDetails from '../ItemFormVariantDetails';

class ItemFormVariants extends CommonForm {
    constructor(props) {
        super(props);
        const { item, formId, updateFormValues } = props;

        const variants = item
            ? item.variants.map((id, index) => ({
                  id,
                  formId: `${formId}.${index}`,
                  expanded: index === 0,
                  editable: index !== 0,
                  deleted: false
              }))
            : [
                  {
                      formId: `${formId}.0`,
                      expanded: true,
                      editable: false,
                      deleted: false
                  }
              ];

        this.state = {
            formValues: {
                variants
            }
        };

        if (updateFormValues && formId) {
            updateFormValues({ formId, formValues: this.state.formValues });
        }
    }

    onFormValuesChanged = (newFormValues) => {
        const { updateFormValues, formId } = this.props;
        this.setState({
            formValues: newFormValues
        });
        if (updateFormValues && formId) {
            updateFormValues({ formId, formValues: newFormValues });
        }
    };

    addVariant = () => {
        const { formValues } = this.state;
        const { formId } = this.props;
        formValues.variants.push({ formId: `${formId}.${formValues.variants.length}`, editable: true, expanded: true, deleted: false });

        this.onFormValuesChanged(formValues);
    };

    deleteVariant = (index) => {
        const { formValues } = this.state;
        const form = formValues.variants[index];
        form.deleted = true;
        form.expanded = false;
        this.onFormValuesChanged(formValues);
    };

    render() {
        const { item, ...rest } = this.props;
        const { variants } = this.state.formValues;

        return (
            <Form.Field>
                {variants.map(({ id, formId, editable, expanded, deleted }, index) => (
                    <Form.Field key={formId}>
                        {/*{index === 0 && (*/}
                        {/*    <Header as='h2'>*/}
                        {/*        <Trans i18nKey='sellItem.form.details.header'>Item Details</Trans>*/}
                        {/*    </Header>*/}
                        {/*)}*/}
                        {/*{index === 1 && (*/}
                        {/*    <Header as='h2'>*/}
                        {/*        <Trans i18nKey='sellItem.form.variants.header'>Variants</Trans>*/}
                        {/*    </Header>*/}
                        {/*)}*/}
                        <ItemFormVariantDetails
                            {...rest}
                            item={item}
                            variantId={id}
                            variantIndex={index}
                            formId={formId}
                            editable={editable}
                            expanded={expanded}
                            deleted={deleted}
                            onExpandClicked={() => {
                                const { formValues } = this.state;
                                // const target = this.formValues.variants[index];
                                // target.expanded = true;
                                formValues.variants[index].expanded = true;
                                this.onFormValuesChanged(formValues);
                            }}
                            onSaveClicked={() => {
                                const { formValues } = this.state;
                                // const target = this.formValues.variants[index];
                                // target.expanded = true;
                                formValues.variants[index].expanded = false;
                                this.onFormValuesChanged(formValues);
                            }}
                            onDeleteClicked={() => this.deleteVariant(index)}
                        />
                    </Form.Field>
                ))}
                <div style={{ color: '#222222', fontWeight: '700' }}>
                    <Trans i18nKey='sellItem.variants.header'>Variants</Trans>
                </div>
                <p style={{ color: '#888888' }}>
                    <Trans i18nKey='sellItem.variants.description'>Add available options like color or size. Customers will choose from these during checkout.</Trans>
                </p>
                {/*<Button type='button' fluid primary basic onClick={this.addVariant}>*/}
                {/*    <Trans i18nKey='sellItem.variants.add.actions.add'>Add Another Variant</Trans>*/}
                {/*</Button>*/}
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        this.addVariant();
                    }}
                >
                    <Trans i18nKey='sellItem.variants.add.trigger'>Add Variant</Trans>
                </Button>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ItemFormVariants);
