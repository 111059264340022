// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

export const LOG_IN_GOOGLE = 'LOG_IN_GOOGLE';
export const logInGoogle = wrapAPIActionCreator((access_token) => ({
    [API_DATA]: {
        type: LOG_IN_GOOGLE,
        method: 'POST',
        endpoint: '/v4/auth/google',
        body: {
            access_token
        },
        preprocess: ({ accessToken, ...rest }) => ({ user: rest, accessToken }),
        schema: {
            user: Schemas.user
        }
    }
}));

export default logInGoogle;
