// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import ProductMessagesTabView from './ProductMessagesTabView';

export type MessagesTabOption = 'Comments' | 'Messages';

type State = {
    selectedTab: MessagesTabOption
};

class ProductMessagesTabContainer extends React.Component<{}, State> {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 'Comments'
        };
    }

    handleTabSelect = (tab: MessagesTabOption) => {
        this.setState({ selectedTab: tab });
    };

    render() {
        return <ProductMessagesTabView handleTabSelect={this.handleTabSelect} selectedTab={this.state.selectedTab} {...this.props} />;
    }
}

export default ProductMessagesTabContainer;
