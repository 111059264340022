// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Modal } from 'semantic-ui-react';

type Props = {
    isOpen: boolean,
    onClose: () => void,
    url: string
};

function getYouTubeIdFromUrl(url) {
    const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|v=)([^#]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
        return match[2];
    }
    return false;
}

function YouTubeModalView({ isOpen, onClose, url }: Props) {
    if (!url) {
        return null;
    }
    const id = getYouTubeIdFromUrl(url);
    if (!id) {
        return null;
    }
    return (
        <Modal size='large' closeIcon open={isOpen} onClose={onClose}>
            <Modal.Content>
                <div className='youtube-video'>
                    <iframe
                        className='youtube-video-iframe'
                        title='YouTube'
                        src={`https://www.youtube.com/embed/${id}?rel=0&autoplay=1`}
                        allow='autoplay; encrypted-media'
                        allowFullScreen
                    />
                </div>
            </Modal.Content>
        </Modal>
    );
}

export default compose(withTranslation('translations'))(YouTubeModalView);
