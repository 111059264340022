// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn } from 'store/utilities';
import type { Cart as CartT } from 'store/types';

import View from './view';

type Props = {
    cart: CartT,
    isLoggedIn: boolean,
    loadCartItems: typeof actions.loadCartItems
};

type State = {
    isLoading: boolean
};

class Cart extends Component<Props, State> {
    state = {
        isLoading: true
    };

    componentDidMount() {
        const { loadCartItems } = this.props;
        loadCartItems().then(
            () => this.setState({ isLoading: false }),
            () => this.setState({ isLoading: false })
        );
    }

    render() {
        const { isLoading } = this.state;
        const { isLoggedIn, ...rest } = this.props;
        if (isLoggedIn) {
            return <View {...{ isLoading }} {...rest} />;
        }
        return <Redirect to='/' />;
    }
}

const mapStateToProps = (state) => {
    const { cart } = state.userData;
    return {
        cart,
        isLoggedIn: getIsLoggedIn(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadCartItems: wrapAPIDispatch(dispatch, actions.loadCartItems)
});

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
