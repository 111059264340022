// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getEntityDataBySlug } from 'store/utilities';

import { UserTypes } from 'store/enums';
import type { EntityData } from 'store/types';

import LandingScene from 'scenes/Landing';
import PageNotFound from 'scenes/PageNotFound';
import ProfileScene from 'scenes/Profile';
import StoreScene from 'scenes/Store';

type Props = {
    slug: string,
    entityData: EntityData<*>,
    searchRecordBySlug: typeof actions.searchRecordBySlug
};

type State = {
    performedFirstLookupRequest: boolean
};

function loadData({ slug, searchRecordBySlug }: Props) {
    return searchRecordBySlug({ slug });
}

class SlugResolverContainer extends Component<Props, State> {
    state = {
        performedFirstLookupRequest: false
    };

    componentDidMount() {
        if (this.props.entityData.entity == null) {
            loadData(this.props)
                .then(() => this.setState({ performedFirstLookupRequest: true }))
                .catch(() => this.setState({ performedFirstLookupRequest: true }));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.slug !== this.props.slug) {
            loadData(nextProps);
        }
    }

    render() {
        const { entityData, slug } = this.props;
        const { performedFirstLookupRequest } = this.state;
        if (entityData.isFetching) {
            return null;
        }

        switch (entityData.collection) {
            case 'categories':
                return <Redirect to={`/categories/${slug}`} push />;
            case 'users': {
                if (entityData.entity.userType === UserTypes.MERCHANT) {
                    return <StoreScene {...this.props} />;
                }
                if (entityData.entity.userType === UserTypes.SHOPPER) {
                    return <ProfileScene {...this.props} />;
                }
                break;
            }
            case 'landingPages':
                return <LandingScene slug={slug} />;
            default:
                return performedFirstLookupRequest ? <PageNotFound /> : null;
        }
    }
}

const mapStateToProps = (state, { match }) => {
    const { slug } = match.params;
    const entityData = getEntityDataBySlug(state, slug);
    return { entityData, slug };
};

const mapDispatchToProps = (dispatch) => ({
    searchRecordBySlug: wrapAPIDispatch(dispatch, actions.searchRecordBySlug)
});

export default connect(mapStateToProps, mapDispatchToProps)(SlugResolverContainer);
