// @flow
import React from 'react';

import ProductMessagesTab from './components/ProductMessagesTab';

function ProductMessages(props) {
    return <ProductMessagesTab {...props} />;
}

export default ProductMessages;
