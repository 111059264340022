// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import AddressListItem from './components/AddressListItem';

type Props = {
    list: Array,
    selected: number,
    handleChange: Function,
    onOpenEditAddress: (number) => void,
    address: {
        houseNo: string,
        street: string,
        country: string,
        state: string
    }
};

function AddressList({ list, selected, handleChange, onOpenEditAddress }: Props) {
    return (
        <div>
            {list.map((address) => (
                <AddressListItem
                    key={address.id}
                    item={address}
                    selected={selected}
                    handleChange={handleChange}
                    onOpenEditAddress={() => onOpenEditAddress(address)} />
            ))}
        </div>
    );
}

export default withTranslation('translations')(AddressList);
