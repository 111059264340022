// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

// LOAD_COUNTRIES ----------------------
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const loadCountries = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_COUNTRIES,
        endpoint: '/v3/countries',
        method: 'GET',
        schema: {
            countries: [Schemas.country]
        }
    }
}));
