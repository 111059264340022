// @flow
import * as ActionTypes from '../../actions';

const initialState = {};

// Populates the root categories based on category-loading actions
export default function categoryAttributes(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_CATEGORY_ATTRIBUTES: {
            const { categoryId } = action.reducerParams;
            if (action.status === 'request') {
                return {
                    ...state,
                    [categoryId]: {
                        ...state[categoryId],
                        isLoading: true
                    }
                };
            }
            if (action.status === 'success') {
                const { attributes } = action.payload.result;
                return {
                    ...state,
                    [categoryId]: {
                        ...state[categoryId],
                        attributes,
                        isLoading: false
                    }
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    [categoryId]: {
                        ...state[categoryId],
                        isLoading: false
                    }
                };
            }
            break;
        }
        default:
        // do nothing
    }
    return state;
}
