// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { Button, Dropdown, Grid, Header, Icon, Popup, Image } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import type { EntityData, CartItem, Variant } from 'store/types';
import ProductMessages from 'scenes/Product/components/ProductMessages';
import { formatPrice } from 'utilities/format';
import QuantitySwitcher from './components/QuantitySwitcher';

export function getVariantHeading({ variantBody }, keys = Object.keys(variantBody || {})) {
    const toFirstUpperCase = (str) => str[0].toUpperCase() + str.slice(1);
    return keys.map(toFirstUpperCase).concat(['SKU']).join(' / ');
}

export function getVariantOptionText({ variantBody, sku }, keys = Object.keys(variantBody || {})) {
    const values = keys.map((key) => variantBody[key]);
    return values.concat([sku]).join(' / ');
}

const NegotiationBoxHeader = ({ product, onClose }) => {
    return (
        <>
            <div style={{ borderBottom: '1px solid lightgray', marginBottom: '15px' }}>
                <Header as='h1' style={{ fontSize: '18px', paddingTop: '10px' }}>
                    <Trans i18nKey='messages.conversation.offer.negotiationHeader'>Send Message / Negotiate Price</Trans>
                </Header>
                <Icon name='close' size='large' style={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }} onClick={onClose} />
            </div>
            <div
                style={{
                    display: 'flex',
                    gap: '30px',
                    alignItems: 'center',
                    paddingBottom: '10px',
                    borderBottom: '1px solid lightgray',
                    marginBottom: '15px'
                }}
            >
                <Image src={product.pictures[0].imageFull} size='tiny' />
                <div>
                    <Header as='h2'>{product.name}</Header>
                    <strong style={{ marginTop: '-30px', fontSize: '18px' }}>{formatPrice(product.price)}</strong>
                </div>
            </div>
        </>
    );
};

type Props = {
    product: object,
    selectedVariant: number,
    isLoadingAdd: boolean,
    isLoadingUpdate: boolean,
    isLoadingRemove: boolean,
    cartItem: EntityData<CartItem>,
    variants: EntityData<Variant>[],
    isInCart: boolean,
    isCartLoading: boolean,
    onChangeVariant: (?number) => void,
    onAddToCart: (?number) => void,
    onRemoveFromCart: () => void,
    onIncrementQuantity: () => void,
    onDecrementQuantity: () => void
};

class ProductActionsView extends Component<Props> {
    constructor(props) {
        super(props);
        this.popupRef = React.createRef();
    }

    state = {
        open: false
    };

    static defaultProps = {
        onAddToCart: () => {}
    };

    handleChangeVariant = (event, data) => {
        const { onChangeVariant } = this.props;
        onChangeVariant(data.value);
    };

    handleAddToCart = () => {
        const { onAddToCart } = this.props;
        onAddToCart();
    };

    handleRemoveFromCart = () => {
        const { onRemoveFromCart } = this.props;
        onRemoveFromCart();
    };

    handleIncrementQuantity = () => {
        const { onIncrementQuantity } = this.props;
        onIncrementQuantity();
    };

    handleDecrementQuantity = () => {
        const { onDecrementQuantity } = this.props;
        onDecrementQuantity();
    };

    render() {
        const {
            selectedVariant,
            isInCart,
            isCartLoading,
            cartItem,
            variants,
            isLoadingAdd,
            isLoadingUpdate,
            isLoadingRemove,
            showNegotiate,
            showMessage,
            onMessageAction,
            focusSendMessage,
            onFocusSendMessage,
            product,
            t,
            item,
            isLoggedIn
        } = this.props;
        const variantKeys =
            variants.length > 0
                ? Object.keys(variants[0].entity.variantBody || {})
                    // Strip keys where all values are empty
                    .filter((key) => variants.every(({ entity }) => entity.variantBody && entity.variantBody[key] !== ''))
                : [];

        const variantHeading = variants.length > 0 ? getVariantHeading(variants[0].entity, variantKeys) : '';

        return (
            <Grid className='auto tensed'>
                {variants.length > 0 && (
                    <Grid.Column className='col-row'>
                        <Header as='h2' size='small'>
                            {variantHeading}
                        </Header>
                        <Grid className='auto tensed'>
                            <Grid.Column>
                                <Dropdown
                                    selection
                                    fluid
                                    onChange={this.handleChangeVariant}
                                    value={selectedVariant}
                                    options={variants.map(({ entity }) => ({
                                        value: entity.id,
                                        text: getVariantOptionText(entity, variantKeys)
                                    }))}
                                />
                            </Grid.Column>
                            {isInCart && (
                                <Grid.Column className='col-shrink'>
                                    <QuantitySwitcher
                                        isLoading={isLoadingUpdate}
                                        cartItem={cartItem}
                                        onIncrement={this.handleIncrementQuantity}
                                        onDecrement={this.handleDecrementQuantity}
                                    />
                                </Grid.Column>
                            )}
                        </Grid>
                    </Grid.Column>
                )}
                <Grid.Column className='col-row'>
                    <Grid className='auto tensed'>
                        <Grid.Column>
                            {!isInCart && (
                                <Button
                                    fluid
                                    icon
                                    primary
                                    disabled={isCartLoading || item.entity.licenseUrl === null}
                                    loading={isCartLoading || isLoadingAdd}
                                    onClick={this.handleAddToCart}
                                >
                                    <Trans i18nKey='product.actions.addToCart'>Add to Cart</Trans> <Icon name='cart' />
                                </Button>
                            )}
                            {!isCartLoading && isInCart && (
                                <Button fluid icon negative loading={isLoadingRemove} onClick={this.handleRemoveFromCart}>
                                    <Trans i18nKey='product.actions.removeFromCart'>Remove from Cart</Trans> <Icon name='trash' />
                                </Button>
                            )}
                        </Grid.Column>
                        <Grid.Column>
                            {!isInCart && showNegotiate && (
                                /*<Button className='message-button' fluid onClick={onMessageAction}>
                                    <Trans i18nKey='product.negotiate'>Negotiate</Trans>
                                </Button>*/
                                <>
                                    <Button
                                        content={t('messages.conversation.offer.negotiationBtn', { defaultValue: 'Negotiate Price' })}
                                        fluid
                                        onClick={() => this.setState({ open: !this.state.open })}
                                    />
                                    <Popup
                                        content={
                                            <div className='product-negotiationBox'>
                                                <ProductMessages
                                                    product={product}
                                                    negotiationBox={true}
                                                    focusSendMessage={focusSendMessage}
                                                    onDidFocusSendMessage={onFocusSendMessage}
                                                />
                                            </div>
                                        }
                                        basic
                                        position='top center'
                                        on='click'
                                        open={this.state.open}
                                        style={{ zIndex: 500 }}
                                        context={this.popupRef}
                                        positionFixed
                                        flowing
                                        header={<NegotiationBoxHeader product={product} onClose={() => this.setState({ open: false })} />}
                                    />
                                    <strong ref={this.popupRef} className='product-negotiationBox-opener' style={{ position: 'fixed', bottom: '-14px', right: '250px' }}></strong>
                                </>
                            )}
                            {false && isInCart && !showNegotiate && showMessage && (
                                <Button className='message-button' fluid onClick={onMessageAction}>
                                    <Trans i18nKey='product.message'>Message</Trans>
                                </Button>
                            )}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    }
}

ProductActionsView.defaultProps = {
    onChangeVariant() {},
    onAddToCart() {},
    onRemoveFromCart() {},
    onIncrementQuantity() {},
    onDecrementQuantity() {}
};

export default compose(withTranslation('translations'))(ProductActionsView);
