import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import BankAccountList from 'scenes/MyStore/scenes/MyStoreSettings/components/MyStoreSettingsForm/components/MyStoreSettingsBankAccount/components/BankAccountList';
import { Button, Grid } from 'semantic-ui-react';
import * as actions from 'store/actions';

const MyAgentSettingsBankView = ({ bankAccount, setBankAccount }) => {
    const dispatch = useDispatch();
    const updateAccount = useCallback(
        ({ city, country, state, ...otherFields }) => {
            setBankAccount({ ...otherFields, cityId: city, city: { id: city }, countryId: country, country: { id: country }, stateId: state, state: { id: state } });
            return new Promise((resolve) => resolve());
        },
        [setBankAccount]
    );

    const handleOpenAddBankAccount = () => {
        dispatch(
            actions.openModal(actions.ModalNames.BANK_ACCOUNT_ADD, {
                onSubmit: updateAccount
            })
        );
    };
    const handleOpenEditBankAccount = (bankAccount) => {
        dispatch(
            actions.openModal(actions.ModalNames.BANK_ACCOUNT_EDIT, {
                bankAccount,
                onSubmit: updateAccount,
                onDelete: () => updateAccount(null)
            })
        );
    };

    return (
        <Grid style={{ marginTop: 20 }}>
            {!!bankAccount ? (
                <Grid.Column computer={16}>
                    {/*onOpenEditBankAccount={handleOpenEditBankAccount}*/}
                    <BankAccountList list={[bankAccount]} />
                </Grid.Column>
            ) : (
                <Grid.Column computer={8} tablet={8} mobile={10}>
                    <Button style={{ color: 'black' }} type='button' fluid onClick={handleOpenAddBankAccount}>
                        <Trans>Add Bank Account</Trans>
                    </Button>
                </Grid.Column>
            )}
        </Grid>
    );
};

export default MyAgentSettingsBankView;
