// @flow
import React from 'react';

import { Comment } from 'store/types';

import CommentView from './CommentView';

type Props = {
    comment: Comment
};

const CommentContainer = ({ comment }: Props) => <CommentView comment={comment} />;

export default CommentContainer;
