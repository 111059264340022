// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyProfilePromotionsTab from './components/MyProfilePromotionsTab';

const MyProfilePromotionsView = (props) => (
    <Container>
        <Grid className='auto'>
            <Grid.Column className='col-row'>
                <Header as='h1'>
                    <Trans i18nKey='myProfile.promotions.header'>My Profile Promotions</Trans>
                </Header>
            </Grid.Column>
            <Grid.Column className='col-row'>
                <MyProfilePromotionsTab {...props} />
            </Grid.Column>
        </Grid>
    </Container>
);

export default withTranslation('translations')(MyProfilePromotionsView);
