// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Menu } from 'semantic-ui-react';
import { externalSidebarSupport } from 'utilities/links';

import SidebarMenuSupportItem from './components/SidebarMenuSupportItem';

function SidebarMenuSupport() {
    return (
        <Grid.Row>
            <Grid.Column>
                <Menu className='simple icons-left' vertical borderless fluid>
                    {externalSidebarSupport
                        .find((category) => category.key === 'support')
                        .list.map(
                            (listItem) =>
                                !(listItem.key === 'merchantAgreement' || listItem.key === 'privacyPolicy') && <SidebarMenuSupportItem key={listItem.key} item={listItem} />
                        )}
                </Menu>
            </Grid.Column>
        </Grid.Row>
    );
}

export default SidebarMenuSupport;
