// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import ForgotPassword from '../../components/ForgotPassword';

type Props = {
    navigateTo: typeof push
};

class ForgotPasswordScene extends Component<Props> {
    handleSuccess = () => {
        const { navigateTo } = this.props;
        navigateTo('/');
    };

    render() {
        return (
            <Container text className='narrow'>
                <Header as='h1'>
                    <Trans i18nKey='forgotPassword.header'>Forgot password?</Trans>
                </Header>
                <ForgotPassword onSuccess={this.handleSuccess} />
            </Container>
        );
    }
}

export default compose(connect(null, { navigateTo: push }), withTranslation('translations'))(ForgotPasswordScene);
