// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import MyStoreOrdersTabView from './MyStoreOrdersTabView';

function MyStoreOrdersTabContainer(props) {
    return <MyStoreOrdersTabView {...props} />;
}

export default MyStoreOrdersTabContainer;
