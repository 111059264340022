import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Header, Modal, Button, Icon } from 'semantic-ui-react';
import { withTranslation, useTranslation } from 'react-i18next';
import ProfileCard from './ProfileCard';
import { getUser } from 'store/utilities';
import { history } from 'store';

const PROFILES = {
    Customer: 'Customer',
    Merchant: 'Merchant',
    Agent: 'Agent'
};
const profileDesc = {
    customer:
        'Customers on the platform have a wide range of products and the possibility of quick and easy shopping, without losing the feeling of real and traditional shopping.',
    merchant:
        'Through the platform, traders can effectively promote products, receive payments and deliver goods, and thus conquer new markets with minimal effort, great creativity and our support.',
    agent:
        'It is a long established that a reader will be distracted by the readable content of a page when at its layout. The point of Lorem Ipsum is that it has it look like readable English.'
};
const PROFILE_PROPS = {
    [PROFILES.Customer]: {
        homeRoute: '/discover',
        createRoute: '/my-profile/settings',
        messageKey: 'selectProfile.customer.message',
        message: 'Create your customer profile for better shopping experience',
        cancelLabelKey: 'discoverFirst.button',
        cancelLabel: 'Discover first',
        fieldToCheck: null,
        header: 'selectProfile.customer.header'
    },
    [PROFILES.Merchant]: {
        homeRoute: '/my-store',
        createRoute: '/store-settings',
        messageKey: 'selectProfile.merchant.message',
        message: 'Create your merchant profile and grow your business with MyBazar',
        cancelLabelKey: 'cancel.button',
        cancelLabel: 'Cancel',
        fieldToCheck: 'merchant',
        header: 'selectProfile.merchant.header'
    },
    [PROFILES.Agent]: {
        homeRoute: '/agent-office',
        createRoute: '/agent-settings',
        messageKey: 'selectProfile.agent.message',
        message: 'Create your Agent profile and earn commission on each sale',
        cancelLabelKey: 'cancel.button',
        cancelLabel: 'Cancel',
        fieldToCheck: 'selectProfile.agent.header',
        header: 'selectProfile.agent.header'
    }
};

const CreateProfileModal = ({ targetProfile, isVisible, onClose }) => {
    const { t } = useTranslation('translations');

    return (
        <Modal size='small' dimmer='blurring' open={isVisible} onClose={onClose}>
            <div className='modal-container'>
                <div className='modal-content-wrapper'>
                    <h3 className='modal-content-title' style={{ marginTop: '10px' }}>
                        {t(PROFILE_PROPS[targetProfile].header, { defaultValue: `${targetProfile} profile` })}
                    </h3>
                    <Icon className='modal-close-icon' name='close' size='large' onClick={onClose} />
                </div>
                <div className='modal-sub-content-wrapper'>
                    <div className='modal-sub-title'>{t(PROFILE_PROPS[targetProfile].messageKey, { defaultValue: PROFILE_PROPS[targetProfile].message })}</div>
                    <div className='modal-buttons'>
                        <Button
                            onClick={() => (targetProfile === PROFILES.Customer ? history.push(PROFILE_PROPS[PROFILES.Customer].homeRoute) : onClose())}
                            color='black'
                            basic
                            className='modal-transparent-button'
                        >
                            {t(PROFILE_PROPS[targetProfile].cancelLabelKey, { defaultValue: PROFILE_PROPS[targetProfile].cancelLabel })}
                        </Button>
                        <Button onClick={() => history.push(PROFILE_PROPS[targetProfile].createRoute)} className='modal-button' secondary>
                            {t('profile.create', { defaultValue: 'Create profile' })}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

function SelectYourProfile() {
    const user = useSelector((state) => ({ ...getUser(state, state.userData.user).entity, merchant: !!(state.merchantData && state.merchantData.user) }));
    const merchant = useSelector((state) => ({ ...getUser(state, state.merchantData.user).entity }));

    const [activeProfile, setActiveProfile] = useState(localStorage.getItem('activeProfile'));
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation('translations');

    const selectProfile = useCallback(
        (role) => {
            const profileProps = PROFILE_PROPS[role];
            if (profileProps) {
                if (!profileProps.fieldToCheck || !!user[profileProps.fieldToCheck]) {
                    // We can navigate directly to the home page
                    localStorage.setItem('activeProfile', role);
                    history.push(profileProps.homeRoute);
                    return;
                }
                setActiveProfile(role);
                setIsModalOpen(true);
                //localStorage.setItem('activeProfile', role);
            }
        },
        [user]
    );

    return (
        <>
            <Grid className='select-profile' centered stackable>
                <Grid.Row>
                    <Grid.Column verticalAlign='bottom'>
                        <Header as='h1'>
                            {t('profile.selectYourProfile', {
                                defaultValue: 'Select Your Profile'
                            })}
                        </Header>
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3} verticalAlign='middle'>
                    <Grid.Row centered>
                        <ProfileCard
                            profileDesc={profileDesc.customer}
                            activeProfile={activeProfile === PROFILES.Customer}
                            profileName='Customer'
                            user={user}
                            onClick={() => selectProfile(PROFILES.Customer)}
                        />
                    </Grid.Row>
                    <Grid.Row centered>
                        <ProfileCard
                            profileDesc={profileDesc.merchant}
                            activeProfile={activeProfile === PROFILES.Merchant}
                            profileName='Merchant'
                            user={merchant}
                            onClick={() => selectProfile(PROFILES.Merchant)}
                        />
                    </Grid.Row>
                    {/*<Grid.Row centered>*/}
                    {/*    <ProfileCard*/}
                    {/*        profileDesc={profileDesc.agent}*/}
                    {/*        activeProfile={activeProfile === PROFILES.Agent}*/}
                    {/*        profileName='Agent'*/}
                    {/*        user={merchant}*/}
                    {/*        onClick={() => selectProfile(PROFILES.Agent)}*/}
                    {/*    />*/}
                    {/*</Grid.Row>*/}
                </Grid.Row>
            </Grid>
            {!!activeProfile && <CreateProfileModal targetProfile={activeProfile} isVisible={isModalOpen} onClose={() => setIsModalOpen(false)} />}
        </>
    );
}

export default withTranslation('translations')(SelectYourProfile);
