// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type AddTrackingNumberParams = {
    orderId: number,
    trackingNumber: string,
    carrier: string,
    carrierUrl: string
};

export const ADD_TRACKING_NUMBER = 'ADD_TRACKING_NUMBER';
export const addTrackingNumber = wrapAPIActionCreator(({ orderId, trackingNumber, carrier, carrierUrl }: AddTrackingNumberParams) => ({
    [API_DATA]: {
        type: ADD_TRACKING_NUMBER,
        endpoint: `/v3/orders/${orderId}/addTrackingNumber`,
        method: 'PUT',
        authentication: Authentication.Merchant,
        body: {
            orderId,
            trackingNumber,
            carrier,
            carrierUrl
        },
        schema: {
            order: Schemas.order
        }
    }
}));

export default addTrackingNumber;
