// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';
import type { EntityData, User } from 'store/types';

import MyStoreSettingsFormView from './MyStoreSettingsFormView';

type Props = {
    isLoadingAccounts: boolean,
    merchantUser: EntityData<User>,
    loadMyAccounts: typeof actions.loadMyAccounts,
    updateMerchantAccount: typeof actions.updateMerchantAccount,
    loadCountries: typeof actions.loadCountries,
    loadStatesAndCities: typeof actions.loadStatesAndCities,
    updateFormValues: typeof actions.updateFormValues
};

class MyStoreSettingsFormContainer extends Component<Props> {
    render() {
        // const { settings } = this.props;
        // if (!settings || settings.isLoading) {
        //   return <Loader active/>;
        // }
        return <MyStoreSettingsFormView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    // merchantUser: getUser(state, state.merchantData.user).entity,
    // isLoadingAccounts: state.merchantData.isLoadingAccounts,
    // getFormValues: formId => state.ui.forms[formId],
});

const mapDispatchToProps = (dispatch) => ({
    // loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts),
    // loadCountries: wrapAPIDispatch(dispatch, actions.loadCountries),
    // loadStatesAndCities: wrapAPIDispatch(dispatch, actions.loadStatesAndCities),
    // updateMerchantAccount: wrapAPIDispatch(dispatch, actions.updateMerchantAccount),
    // updateFormValues: params => dispatch(actions.updateFormValues(params)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreSettingsFormContainer);
