// @flow
import React, { Component } from 'react';
import { Container, Grid, Loader, Header, Visibility } from 'semantic-ui-react';
import type { EntityData } from 'store/types';
import { withTranslation, Trans } from 'react-i18next';

import Product from 'components/Product';
import ProductList from 'components/ProductList';
import ProductMessages from './components/ProductMessages';
import ProductReportButton from './components/ProductReportButton';
import { getIsLoggedIn } from '../../store/utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';

type Props = {
    isFetching: boolean,
    slug: string,
    storeSlug: string,
    loadMoreItems: () => {},
    otherStoreItems: number[],
    comments: EntityData<CommentType>[] // TODO DG Resolve this...
};

class ProductView extends Component<Props> {
    state = {
        focusSendMessage: false
    };

    render() {
        const { focusSendMessage } = this.state;
        const { product, otherStoreItems } = this.props;
        const { isLoggedIn } = this.props;
        if (product === null) {
            return (
                <Container>
                    <Loader active inline='centered' />
                </Container>
            );
        }

        return (
            <Container>
                <Grid className='auto'>
                    <Grid.Column className='col-row'>
                        <Product
                            product={product}
                            onSendMessageAction={() => {
                                this.setState({ focusSendMessage: true });
                            }}
                            focusSendMessage={focusSendMessage}
                            onDidFocusSendMessage={() => {
                                this.setState({ focusSendMessage: false });
                            }}
                            isLoggedIn={isLoggedIn}
                        />
                    </Grid.Column>
                    <Grid.Column className='col-row col-text-align-right'>
                        <ProductReportButton product={product} isLoggedIn={isLoggedIn} />
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <Header as='h3'>
                            <Trans i18nKey='product.moreProducts'>More From This Store</Trans>
                        </Header>
                        <Visibility onUpdate={(e, { calculations }) => this.props.loadMoreItems(calculations)}>
                            <ProductList productId={product.id} items={otherStoreItems} />
                        </Visibility>
                    </Grid.Column>
                    {isLoggedIn && (
                        <Grid.Column className='col-row'>
                            <ProductMessages
                                product={product}
                                focusSendMessage={focusSendMessage}
                                onDidFocusSendMessage={() => {
                                    this.setState({ focusSendMessage: false });
                                }}
                            />
                        </Grid.Column>
                    )}
                </Grid>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const isLoggedIn = getIsLoggedIn(state);
    return { isLoggedIn };
};

export default compose(connect(mapStateToProps), withTranslation('translations'))(ProductView);
