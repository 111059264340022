// @flow
import React from 'react';
import { Icon, Button, Grid } from 'semantic-ui-react';
type Props = {
    url: string
};

const ShareButtonsView = (props: Props) => {
    const { url } = props;
    let facebookUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + url;
    let whatsappURL = 'https://wa.me/send?text=' + url;
    return (
        <Grid centered columns={3} style={{ justifyContent: 'unset' }}>
            <Grid.Row only='computer tablet'>
                <Button
                    style={{ marginRight: 10 }}
                    color='facebook'
                    href={facebookUrl}
                    target='_blank'
                    onClick={() => {
                        window.open(facebookUrl, 'targetWindow', 'toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250');
                        return false;
                    }}
                >
                    <Icon name='facebook' /> Facebook
                </Button>
                <Button color='green' href={whatsappURL} target='_blank'>
                    <Icon name='whatsapp' /> Whatsapp
                </Button>
            </Grid.Row>
            <Grid.Column only='mobile' style={{ width: 'auto' }}>
                <Button color='facebook' href={facebookUrl} target='_blank'>
                    <Icon name='facebook' /> Facebook
                </Button>
                <Button color='green' href={whatsappURL} target='_blank'>
                    <Icon name='whatsapp' /> Whatsapp
                </Button>
            </Grid.Column>
        </Grid>
    );
};

export default ShareButtonsView;
