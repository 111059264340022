// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';
import { getUser } from 'store/utilities';
import { compose } from 'redux';
import { connect } from 'react-redux';
import UserTeaser from 'components/TeaserCarousel/components/UserTeaser';

type Props = {
    items: {
        merchantId: number
    },
    state: object
};

function getUsers(items, state) {
    const users = items.map(({ merchantId }) => getUser(state, merchantId));
    return users;
}

function UserList({ items, state }: Props) {
    if (!(items && items.length)) {
        return null;
    }
    const users = getUsers(items, state);

    return (
        <Grid className='auto full-width-list'>
            {users
                .filter(({ entity }) => entity.userType === 2)
                .map((user) => (
                    <Grid.Column key={user.entity.id} className='full-width-list-item'>
                        <UserTeaser user={user} />
                    </Grid.Column>
                ))}
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    state
});

export default compose(connect(mapStateToProps))(UserList);
