// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateMyStoreCoverPhotoParams = {
    coverPhotos: [
        {
            data: string // Base64 url encoded image
        }
    ]
};

// UPDATE_MY_STORE_COVER_PHOTO ----------------------
export const UPDATE_MY_STORE_COVER_PHOTO = 'UPDATE_MY_STORE_COVER_PHOTO';
export const updateMyStoreCoverPhoto = wrapAPIActionCreator(({ coverPhotos }: UpdateMyStoreCoverPhotoParams) => ({
    [API_DATA]: {
        type: UPDATE_MY_STORE_COVER_PHOTO,
        endpoint: '/v3/me/profile/picture',
        authentication: Authentication.Merchant,
        method: 'PUT',
        body: {
            coverPhotos
        }
    }
}));
