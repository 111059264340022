// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import AddressEditModalView from './AddressEditModalView';

type Props = {
    onDelete: ({ addressId: number }) => void,
    onSubmit: () => void,
    // from store
    updateAddress: typeof actions.updateAddress,
    openModal: typeof actions.openModal
};

class AddressEditModalContainer extends Component<Props> {
    render() {
        const { onDelete, onSubmit, ...rest } = this.props;
        return <AddressEditModalView onDelete={onDelete} onSubmit={onSubmit} {...rest} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(AddressEditModalContainer);
