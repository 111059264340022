// @flow
import * as ActionTypes from 'store/actions';

export default function states(state = {}, action) {
    switch (action.type) {
        case ActionTypes.LOAD_STATES_AND_CITIES:
            if (action.status === 'request') {
                const { countryId } = action.reducerParams;
                return {
                    ...state,
                    [countryId]: {
                        ...state[countryId],
                        isLoading: true
                    }
                };
            }
            if (action.status === 'success') {
                const { result, countryId } = action.payload;
                return {
                    ...state,
                    [countryId]: {
                        ...state[countryId],
                        isLoading: false,
                        list: result.states
                    }
                };
            }
            if (action.status === 'error') {
                const { countryId } = action.reducerParams;
                return {
                    ...state,
                    [countryId]: {
                        ...state[countryId],
                        isLoading: true
                    }
                };
            }
            break;
        default:
        // do nothing
    }
    return state;
}
