// @flow
import React from 'react';
import { Form, Radio, Popup, Icon, Label } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import { BusinessTypes } from 'store/enums';

type Props = {
    state: obj,
    handleChange: Function
};

function CreateStoreFormBusinessType({ state, handleChange }: Props) {
    return (
        <Form.Field>
            <Form.Field required error={state.businessType.error}>
                <label htmlFor='create-store-business-type'>
                    <Trans i18nKey='createStore.form.businessType.label'>Business Type</Trans>
                </label>
            </Form.Field>
            <Form.Field error={state.businessType.error}>
                <Radio
                    name='businessType'
                    id='create-store-business-type-individual'
                    value={BusinessTypes.INDIVIDUAL}
                    checked={state.businessType.value === BusinessTypes.INDIVIDUAL}
                    disabled={state.loading}
                    label={
                        <label htmlFor='create-store-business-type-individual'>
                            <Trans i18nKey='createStore.form.businessType.individual.label'>Individual without Business License</Trans>
                            <Popup
                                horizontaloffset={10}
                                trigger={
                                    <Label className='inline-label-icon' circular size='mini'>
                                        <Icon name='question' />
                                    </Label>
                                }
                            >
                                <Popup.Header>
                                    <Trans i18nKey='createStore.form.businessType.individual.popup.header'>Individual Seller Without SSM</Trans>
                                </Popup.Header>
                                <Popup.Content>
                                    <Trans i18nKey='createStore.form.businessType.individual.popup.content'>
                                        Your products will be featured in the &quot;Classifieds&quot; section.
                                    </Trans>
                                </Popup.Content>
                            </Popup>
                        </label>
                    }
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field error={state.businessType.error}>
                <Radio
                    name='businessType'
                    id='create-store-business-type-business'
                    value={BusinessTypes.BUSINESS}
                    checked={state.businessType.value === BusinessTypes.BUSINESS}
                    disabled={state.loading}
                    label={
                        <label htmlFor='create-store-business-type-business'>
                            <Trans i18nKey='createStore.form.businessType.business.label'>Registered Business with License</Trans>
                            <Popup
                                horizontaloffset={10}
                                trigger={
                                    <Label className='inline-label-icon' circular size='mini'>
                                        <Icon name='question' />
                                    </Label>
                                }
                            >
                                <Popup.Header>
                                    <Trans i18nKey='createStore.form.businessType.business.popup.header'>Registered Business</Trans>
                                </Popup.Header>
                                <Popup.Content>
                                    <Trans i18nKey='createStore.form.businessType.business.popup.content'>
                                        Take full advantage of all tools and services to grow a successful online business with MyBazar.
                                    </Trans>
                                </Popup.Content>
                            </Popup>
                        </label>
                    }
                    onChange={handleChange}
                />
            </Form.Field>
        </Form.Field>
    );
}

export default withTranslation('translations')(CreateStoreFormBusinessType);
