export const externalSidebarSupport = [
    {
        key: 'support',
        text: 'Support',
        list: [
            {
                key: 'helpCenter',
                text: 'Help Center',
                icon: 'comments',
                link: 'https://mybazarsupport.zendesk.com/hc/en-us'
            },
            {
                key: 'sendFeedback',
                text: 'Send Feedback',
                icon: 'comment',
                link: 'https://mybazarsupport.zendesk.com/hc/en-us/requests/new'
            },
            {
                key: 'merchantAgreement',
                text: 'Merchant Agreement',
                icon: '',
                link: 'https://www.mybazarglobal.com/merchant-agreement/'
            },
            {
                key: 'privacyPolicy',
                text: 'Privacy Policy',
                icon: '',
                link: 'https://www.mybazarglobal.com/privacy-policy/'
            },
            {
                key: 'termsAndConditions',
                text: 'Terms & Conditions',
                icon: 'file text',
                link: 'https://www.mybazarglobal.com/terms-conditions/'
            }
        ]
    }
];
