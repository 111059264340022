// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getUserDeliveryAddress, getCountry, getState, getCity } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import DeliveryAddressModalView from './DeliveryAddressModalView';

import type { FormValues } from './index';

type Props = {
    name: string,
    onSubmit: (FormValues) => ?Promise<*>,
    onDelete: ({ addressId: string }) => ?Promise<*>,
    // from store
    closeModal: typeof actions.closeModal,
    loadCountries: typeof actions.loadCountries,
    loadStatesAndCities: typeof actions.loadStatesAndCities,
    countries: number[],
    states: {},
    addressId: number,
    backendError: string
};

type State = {
    deleteModalIsOpen: boolean,
    isDeleting: boolean,
    deleteError: string
};

class DeliveryAddressModalContainer extends Component<Props, State> {
    static defaultProps;

    constructor(props: Props) {
        super(props);
        this.state = {
            deleteModalIsOpen: false,
            isDeleting: false,
            deleteError: ''
        };
    }

    componentDidMount() {
        this.props.loadCountries();
    }

    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    handleOpenDeleteModal = () => {
        this.setState({ deleteModalIsOpen: true });
    };

    handleCloseDeleteModal = () => {
        this.setState({ deleteModalIsOpen: false, deleteError: '' });
    };

    handleCountryChange = ({ value }) => {
        const addressStates = this.props.addressStates[value];
        if (!addressStates || !addressStates.list) {
            if (!addressStates || !addressStates.isLoading) {
                this.props.loadStatesAndCities({ countryId: value });
            }
        }
    };

    handleDelete = () => {
        const { addressId, onDelete } = this.props;
        this.setState({ isDeleting: true });
        onDelete({ addressId })
            .then(() => {
                this.setState({ isDeleting: false });
                this.handleCloseDeleteModal();
                this.handleClose();
            })
            .catch(({ code, messages }) => {
                const errorMessage = messages[0] ? messages[0] : `Error: ${code}`;
                this.setState({ isDeleting: false, deleteError: errorMessage });
            });
    };

    handleSubmit = (event, data) => {
        const { closeModal, name, onSubmit } = this.props;
        const res = onSubmit(data);
        if (res) {
            res.then(() => closeModal(name));
        } else {
            closeModal(name);
        }
    };

    render() {
        const { name, onSubmit, closeModal, backendError, ...rest } = this.props;
        const { deleteModalIsOpen, isDeleting, deleteError } = this.state;
        return (
            <DeliveryAddressModalView
                onClose={this.handleClose}
                onSubmit={this.handleSubmit}
                onClickDelete={this.handleDelete}
                onCountryChange={this.handleCountryChange}
                deleteModalIsOpen={deleteModalIsOpen}
                isDeleting={isDeleting}
                deleteError={deleteError}
                onOpenDeleteModal={this.handleOpenDeleteModal}
                onCloseDeleteModal={this.handleCloseDeleteModal}
                backendError={backendError}
                {...rest}
            />
        );
    }
}

DeliveryAddressModalContainer.defaultProps = {
    onSubmit: () => {}
};

const mapStateToProps = (state, { name }) => {
    const newProps = {
        countries: state.address.enabledCountries,
        addressStates: state.address.addressStates,
        states: state.address.states,
        getCountry: (id) => getCountry(state, id),
        getState: (id) => getState(state, id),
        getCity: (id) => getCity(state, id)
    };

    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return {
            ...newProps,
            isOpen: false,
            address: getUserDeliveryAddress(state, -1)
        };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;

    const { addressId } = extraProps;
    const address = getUserDeliveryAddress(state, addressId);

    return {
        ...newProps,
        ...extraProps,
        isOpen: true,
        address
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    loadCountries: wrapAPIDispatch(dispatch, actions.loadCountries),
    loadStatesAndCities: wrapAPIDispatch(dispatch, actions.loadStatesAndCities)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeliveryAddressModalContainer);
