// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

import CategoryBreadcrumb from 'components/CategoryBreadcrumb';
import ProductTeaser from 'components/ProductTeaser';
import SortablePaginatedGrid from 'components/SortablePaginatedGrid';

import type { EntityData, Category } from 'store/types';

type Props = {
    category: EntityData<Category>,
    loadMoreItems: ({ offset: number, limit: number }) => Promise<number[]>,
    slug: string,
    childSlug: string,
    sort: string
};

const renderItem = (id) => <ProductTeaser itemId={id} />;
const keyExtractor = (id) => id;

function SubCategoryView({ category, loadMoreItems, sort }: Props) {
    const { id: categoryId } = category.entity || {};
    return (
        <Container>
            <SortablePaginatedGrid
                title={<CategoryBreadcrumb categoryId={categoryId} />}
                loadMoreItems={loadMoreItems}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                id={`${categoryId}-${sort}`}
            />
        </Container>
    );
}

export default SubCategoryView;
