// @flow
import ShoppingMallCarousel from 'components/ShoppingMallCarousel';
import React from 'react';
import { Container, Loader } from 'semantic-ui-react';

import type { EntityData, LandingComponent } from 'store/types';

type Props = {
    urlPrefix: string,
    component: EntityData<LandingComponent>,
    title: string
};

function LandingShoppingMallCarouselView({ component, title, urlPrefix }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }

    const { id: componentId, entities, ...rest } = component.entity;

    return (
        <ShoppingMallCarousel
            header={title}
            link={`${urlPrefix}/view-all/${componentId}`}
            type='shopping-mall'
            content={entities.map((entity) => ({
                key: entity.id,
                itemId: entity.id,
                entity,
                component
            }))}
        />
    );
}

LandingShoppingMallCarouselView.defaultProps = {
    urlPrefix: ''
};

export default LandingShoppingMallCarouselView;
