// @flow
import React from 'react';
import { List } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

function toFirstUpperCase(str) {
    return str[0].toUpperCase() + str.slice(1);
}

type Props = {
    variant: {
        variantBody: { [string]: string }
    }
};

/** Shows variant data for an item */
function VariantDataList({ variant }: Props) {
    return (
        <List horizontal>
            {Object.keys(variant.variantBody)
                .filter((key) => variant.variantBody[key] !== '')
                .map((key) => (
                    <List.Item key={key}>
                        <List.Header className='color-de-emphasized'>
                            <Trans i18nKey={`cart.list.item.${key}`}>{toFirstUpperCase(key)}</Trans>
                        </List.Header>
                        <List.Description>{variant.variantBody[key]}</List.Description>
                    </List.Item>
                ))}
        </List>
    );
}

export default VariantDataList;
