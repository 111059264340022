// @flow
import * as ActionTypes from 'store/actions';

export default function states(state = {}, action) {
    switch (action.type) {
        case ActionTypes.LOAD_STATES_AND_CITIES:
            if (action.status === 'success') {
                const { result, countryId } = action.payload;
                return {
                    ...state,
                    [countryId]: result.states
                };
            }
            break;
        default:
        // do nothing
    }
    return state;
}
