// @flow
import React from 'react';
import { Form } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    t: Function,
    state: obj,
    handleChange: Function
};

function CreateStoreFormReferralCode({ state, handleChange, t }: Props) {
    return (
        <Form.Field>
            <label htmlFor='create-store-referral-code'>
                <Trans i18nKey='createStore.form.referralCode.label'>Referral Code</Trans>
            </label>
            <Form.Input
                error={state.referralCode.error}
                type='text'
                name='referralCode'
                id='create-store-referral-code'
                onChange={handleChange}
                placeholder={t('createStore.form.referralCode.placeholder', { defaultValue: '' })}
                value={state.referralCode.value}
            />
        </Form.Field>
    );
}

export default withTranslation('translations')(CreateStoreFormReferralCode);
