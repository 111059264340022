import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export const CONFIRM_ORDER_MONRI = 'CONFIRM_ORDER_MONRI';
export const confirmOrderMonri = wrapAPIActionCreator(({ pan, expirationDate, cvv, secret, name, address }) => ({
    [API_DATA]: {
        type: CONFIRM_ORDER_MONRI,
        endpoint: '/v4/cart_items/finalize_order_monri',
        endpointParams: {
            orderFrom: DeviceTypes.WEB
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {
            payment_method: {
                type: 'card',
                data: {
                    pan,
                    expirationDate,
                    cvv,
                    tokenize_pan: false
                }
            },
            transaction: {
                ch_address: [address.street, address.houseNo || ''].join(' ').trimEnd(),
                ch_city: address.city,
                ch_country: address.country,
                ch_zip: address.zip,
                ch_email: address.emailAddress,
                ch_full_name: name,
                ch_phone: address.contactNo
                //order_info: 'Testna trx'
            },
            secret
        },
        schema: {
            cart: Schemas.cart
        }
    }
}));

export default confirmOrderMonri;
