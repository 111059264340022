import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import SellItem from '../../components/SellItem';

function SellItemScene() {
    return (
        <Container>
            <Header as='h1'>
                <Trans i18nKey='sellItemScene.header'>Sell Item</Trans>
            </Header>
            <SellItem />
        </Container>
    );
}

export default withTranslation('translations')(SellItemScene);
