// @flow
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Menu, Label } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    item: mixed,
    category: string
};

function SidebarMenuMainItem({ item, category, newMessages, newMessageCount }: Props) {
    const newMessagesLabel = (newMessageCount) => (
        <Label>
            <Icon name='mail' /> New
        </Label>
    );

    if (category.key === 'becomeAMerchant') {
        return (
            <Menu.Item as='a' href={item.link} target={item.target || '_blank'}>
                {item.icon && <Icon name={item.icon} />}
                <Trans i18nKey={`sidebar.menu.${category.key}.${item.key}`}>{item.text}</Trans>
            </Menu.Item>
        );
    }

    return (
        <Menu.Item
            // as={NavLink}
            // exact
            // to={item.link}
            key={item.key}
            as='a'
            href={item.link}
        >
            {item.icon && <Icon name={item.icon} />}
            <Trans i18nKey={`sidebar.menu.${category.key}.${item.key}`}>{item.text}</Trans>
            {newMessageCount > 0 ? newMessagesLabel(newMessageCount) : ''}
        </Menu.Item>
    );
}

export default withTranslation('translations')(SidebarMenuMainItem);
