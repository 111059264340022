// @flow
import React, { Component } from 'react';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { CreateMerchantAccountParams } from 'store/actions';
import type { User } from 'store/types';
import { serializeFile, formatICNumber } from 'utilities';
import { BusinessTypes } from 'store/enums';
import CreateStoreFormBusinessType from './components/CreateStoreFormBusinessType';
import CreateStoreFormStore from './components/CreateStoreFormStore';
import CreateStoreFormEusahawan from './components/CreateStoreFormEusahawan';
import CreateStoreFormReferralCode from './components/CreateStoreFormReferralCode';

type FormFieldData<T = string> = {
    value: T,
    error: boolean
};

type Props = {
    isLoggedIn: boolean,
    user: EntityTypes<User>,
    onSuccess: () => void,
    createMerchantAccount: (CreateMerchantAccountParams) => void,
    t: (string) => string,
    countries: [number],
    states: [],
    getCountry: Function,
    getState: Function,
    getCity: Function
};

type State = {
    loading: boolean,
    errorMessage: ?string,
    businessType: FormFieldData<string>,
    file: FormFieldData<string>,
    name: FormFieldData<string>,
    slug: FormFieldData<string>,
    address: FormFieldData<>,
    addressSecondary: FormFieldData<string>,
    postal: FormFieldData<string>,
    country: FormFieldData<?number>,
    state: FormFieldData<?number>,
    city: FormFieldData<?number>,
    eUsahawan: FormFieldData<boolean>,
    eUsahawanNumber: FormFieldData<string>,
    fileDataURI: ?string,
    fileName: ?string,
    referralCode: ?string
};

function createEmptyFormFieldData(value = '') {
    return {
        value,
        error: false
    };
}

class CreateStoreFormView extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errorMessage: null,
            businessType: {
                value: BusinessTypes.INDIVIDUAL,
                error: false
            },
            file: createEmptyFormFieldData(),
            name: createEmptyFormFieldData(),
            slug: createEmptyFormFieldData(),
            address: createEmptyFormFieldData(),
            addressSecondary: createEmptyFormFieldData(),
            postal: createEmptyFormFieldData(),
            country: createEmptyFormFieldData(null),
            state: createEmptyFormFieldData(null),
            city: createEmptyFormFieldData(null),
            eUsahawan: createEmptyFormFieldData(false),
            eUsahawanNumber: createEmptyFormFieldData(),
            fileDataURI: null,
            fileName: null,
            referralCode: createEmptyFormFieldData()
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event, { name, value, checked }) => {
        const target = this.state[name];
        target.value = value;

        switch (name) {
            case 'eUsahawan':
                target.value = checked;
                break;
            case 'country':
                this.validate('country').catch(() => {});
                break;
            case 'state':
                this.validate('state').catch(() => {});
                break;
            case 'city':
                this.validate('city').catch(() => {});
                break;
            case 'eUsahawanNumber': {
                target.value = formatICNumber(value);
                break;
            }
            default:
                break;
        }
        this.setState({ [name]: target });
    };

    handleChangeFile = (event, data) => {
        this.handleChange(event, data);
        const file = event.target.files[0];
        if (file) {
            this.setState({ fileName: file.name });
            serializeFile(file).then(
                (dataURI) => this.setState({ fileDataURI: dataURI }),
                () => this.setState({ fileDataURI: null })
            );
        } else {
            this.setState({ fileName: null, fileDataURI: null });
        }
    };

    handleCountryChange = (event, data) => {
        const { onCountryChange } = this.props;
        this.handleChange(event, data);
        onCountryChange(data);
    };

    handleStateChange = (event, data) => {
        const target = this.state.city;
        target.value = null;
        this.setState({ city: target });

        this.handleChange(event, data);
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true, errorMessage: null });

        const { createMerchantAccount, user, getCountry, getState, getCity } = this.props;
        const {
            address: { value: address },
            addressSecondary: { value: addressSecondary },
            name: { value: name },
            slug: { value: slug },
            businessType: { value: businessType },
            fileDataURI,
            fileName,
            postal: { value: postal },
            eUsahawan: { value: eUsahawan },
            eUsahawanNumber: { value: eUsahawanNumber },
            country: { value: country },
            state: { value: state },
            city: { value: city },
            referralCode: { value: referralCode }
        } = this.state;

        this.validate('name', 'slug', 'postal', 'address', eUsahawan ? 'eUsahawanNumber' : null, 'country', 'state', 'city')
            .then(() => {
                const { email, username } = user;

                const store = {
                    mailingAddress: {
                        houseNo: address,
                        street: addressSecondary,
                        zip: postal,
                        country: getCountry(country).entity.name,
                        state: getState(state).entity.district,
                        city: getCity(city).entity.name
                    }
                };

                const submitParams = {
                    name,
                    slug,
                    email,
                    store,
                    username: username.replace('@mybazar', ''),
                    businessType,
                    licenseName: fileName,
                    licenseUrl: fileDataURI,
                    referralCode
                };

                if (eUsahawan) {
                    submitParams.isBlee = eUsahawan;
                    submitParams.icNumber = eUsahawanNumber;
                }
                return createMerchantAccount(submitParams);
            })
            .then(() => this.setState({ loading: false }))
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    validate = (...inputs) => {
        let valid = true;
        let errorMessage = null;
        inputs.forEach((input) => {
            const target = this.state[input];
            if (target) {
                switch (input) {
                    case 'eUsahawanNumber':
                        target.error = target.value.length !== 14;
                        errorMessage = 'Invalid format';
                        break;
                    default:
                        target.error = target.value === null || target.value.length === 0;
                }

                if (target.error) {
                    valid = false;
                }
                this.setState({ [input]: target });
            }
        });
        return valid ? Promise.resolve() : Promise.reject(new Error(errorMessage || 'Some fields were invalid'));
    };

    render() {
        const { errorMessage, fileDataURI } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} error={errorMessage != null}>
                <Message error>{errorMessage}</Message>
                <Grid>
                    <Grid.Column>
                        <Grid stackable>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <CreateStoreFormBusinessType state={this.state} handleChange={this.handleChange} />
                                    <CreateStoreFormStore
                                        formState={this.state}
                                        handleChange={this.handleChange}
                                        handleChangeFile={this.handleChangeFile}
                                        handleCountryChange={this.handleCountryChange}
                                        handleStateChange={this.handleStateChange}
                                        fileDataURI={fileDataURI}
                                        countries={this.props.countries}
                                        states={this.props.states}
                                        getCountry={this.props.getCountry}
                                        getState={this.props.getState}
                                        getCity={this.props.getCity}
                                    />
                                    <CreateStoreFormReferralCode state={this.state} handleChange={this.handleChange} />
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <CreateStoreFormEusahawan state={this.state} handleChange={this.handleChange} />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ justifyContent: 'center' }}>
                                <Grid.Column width={8}>
                                    <Form.Field>
                                        <Button type='submit' fluid primary loading={this.state.loading} disabled={this.state.loading}>
                                            <Trans i18nKey='createStore.form.submit'>Create Store</Trans>
                                        </Button>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default withTranslation('translations')(CreateStoreFormView);
