// @flow
import * as ActionTypes from 'store/actions';

export default function follow(state = {}, action) {
    switch (action.type) {
        case ActionTypes.FOLLOW_USER:
            if (action.status === 'success') {
                const {
                    userId, // target user id, the teaser that is clicked
                    parentUserId, // the parent of the followers/following, optional. used in profile/store views.
                    currentUserId, // the current user
                    isFollowing
                } = action.reducerParams;

                const followState = state[userId] || {};
                const followers = followState.followers || {};
                const myFollowState = state[currentUserId] || {};
                const myFollowing = myFollowState.following || {};
                const myFollowers = myFollowState.followers || {};

                let myUsers = myFollowing.users || [];
                let users = followers.users || [];

                if (parentUserId === currentUserId) {
                    // browsing my own profile
                    myUsers = myFollowers.users || [];
                    const index = myUsers.findIndex((user) => user.id);
                    if (index >= 0) {
                        myUsers[index].isFollowing = isFollowing;
                        myFollowers.users = myUsers;
                        myFollowState.followers = myFollowers;
                    }
                } else if (parentUserId) {
                    // browsing some other profile
                    if (userId === parentUserId) {
                        if (isFollowing) {
                            // clicked follow on the parent
                            users.push({ id: currentUserId });
                        } else {
                            // clicked unfollow on the parent
                            users = users.filter((user) => user.id !== currentUserId);
                        }
                        followers.users = users;
                        followState.followers = followers;
                    } else {
                        // clicked follow on the other profiles followers/following
                        const parentState = state[parentUserId] || {};
                        const parentFollowers = parentState.followers || {};
                        const parentFollowing = parentState.following || {};

                        users = parentFollowers.users || [];
                        let index = users.findIndex((user) => user.id === userId);
                        if (index >= 0) {
                            users[index].isFollowing = isFollowing;
                        }

                        users = parentFollowing.users || [];
                        index = users.findIndex((user) => user.id === userId);
                        if (index >= 0) {
                            users[index].isFollowing = isFollowing;
                        }
                    }
                }

                return {
                    ...state,
                    [userId]: followState,
                    [currentUserId]: myFollowState
                };
            }
            break;
        case ActionTypes.LOAD_FOLLOWERS: {
            const { offset, userId } = action.reducerParams;
            const followState = state[userId] || {};
            const followers = followState.followers || {};

            if (action.status === 'request') {
                followers.isLoading = true;
                followState.followers = followers;
                return {
                    ...state,
                    [userId]: followState
                };
            }
            if (action.status === 'success') {
                let users = followers.users || [];
                if (users.length > 0 && offset !== 0) {
                    // filter on unique id
                    users = users.concat(action.payload.result.users).filter((user, index, self) => index === self.findIndex((t) => t.id === user.id));
                    followers.users = users;
                    followers.isLoading = false;
                    followState.followers = followers;
                    return {
                        ...state,
                        [userId]: followState
                    };
                }
                followState.followers = {
                    ...action.payload.result,
                    totalCount: followers.totalCount,
                    isLoading: false
                };
                return {
                    ...state,
                    [userId]: followState
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    [userId]: {
                        followers: {
                            isLoading: false
                        }
                    }
                };
            }
            break;
        }
        case ActionTypes.LOAD_FOLLOWING: {
            const { offset, userId } = action.reducerParams;
            const followState = state[userId] || {};
            const following = followState.following || {};

            if (action.status === 'request') {
                following.isLoading = true;
                followState.following = following;
                return {
                    ...state,
                    [userId]: followState
                };
            }
            if (action.status === 'success') {
                let users = following.users || [];
                if (users.length > 0 && offset !== 0) {
                    // filter on unique id
                    users = users.concat(action.payload.result.users).filter((user, index, self) => index === self.findIndex((t) => t.id === user.id));
                    following.users = users;
                    following.isLoading = false;
                    followState.following = following;
                    return {
                        ...state,
                        [userId]: followState
                    };
                }
                followState.following = {
                    ...action.payload.result,
                    isLoading: false
                };
                return {
                    ...state,
                    [userId]: followState
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    [userId]: {
                        following: {
                            isLoading: false
                        }
                    }
                };
            }
            break;
        }
        default:
            break;
    }
    return state;
}
