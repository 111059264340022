// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';
import { getCartItem } from 'store/utilities';

import CheckoutDetailsPaymentView from './CheckoutDetailsPaymentView';

type Props = {
    // from store
    setCartPromoCode: typeof actions.setCartPromoCode,
    openModal: typeof actions.openModal
};

class CheckoutDetailsPaymentContainer extends Component<Props> {
    handleOpenAddPromoCode = () => {
        const { openModal } = this.props;
        openModal(actions.ModalNames.CART_ADD_PROMO_CODE);
    };

    handleSubmitPromoCode = ({ promoCode }) => {
        const { setCartPromoCode } = this.props;
        return setCartPromoCode({
            promoCode
        });
    };

    render() {
        return <CheckoutDetailsPaymentView onOpenAddPromoCode={this.handleOpenAddPromoCode} onSubmitAddPromoCode={this.handleSubmitPromoCode} {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.userData.cart.cartItems.map((id) => getCartItem(state, id))
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (name) => dispatch(actions.openModal(name)),
    setCartPromoCode: wrapAPIDispatch(dispatch, actions.setCartPromoCode)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckoutDetailsPaymentContainer);
