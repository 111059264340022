// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { uniq } from 'lodash';
import * as actions from 'store/actions';
import { getCategory } from 'store/utilities';
import { Category } from './types';

import CategoriesDropdownView from './CategoriesDropdownView';

const getCategoryByCollection = (state, collection) => {
    const collectionKey = collection != null ? collection : 'all';
    const defaultResult = {
        isFetching: false,
        isDefault: true,
        ids: []
    };
    return state.categoriesByCollection[collectionKey] || defaultResult;
};

type Props = {
    loadCategoryTree: typeof actions.loadCategoryTree,
    rootCategories: Category[],
    navigateTo: typeof push,
    mode: 'all' | 'blee',
    collection: ?number,
    shownCategories: number[],
    bazar?: string
};

class CategoriesDropdownContainer extends Component<Props> {
    static defaultProps;

    componentDidMount() {
        const { mode, collection, loadCategoryTree, bazar } = this.props;
        loadCategoryTree({ onlyBlee: mode === 'blee', collection , bazar });
    }

    componentDidUpdate() {
        const { mode, collection, shownCategories, loadCategoryTree , bazar } = this.props;
        if (!shownCategories.isFetching && shownCategories.isDefault) {
            loadCategoryTree({ onlyBlee: mode === 'blee', collection , bazar });
        }
    }

    render() {
        return <CategoriesDropdownView {...this.props} />;
    }
}

const mapStateToProps = (state, { collection }) => {
    const shownCategories = getCategoryByCollection(state, collection);

    let bazar = '';
    if (state.router.location.query.bazar) {
        bazar = state.router.location.query.bazar;
    }

    function mapToCategory(parentSlugs, id) {
        const { isFetching, entity } = getCategory(state, id);
        if (isFetching || !entity) {
            return null;
        }
        const slugs = parentSlugs.concat([entity.slug]);
        const mapper = mapToCategory.bind(null, slugs);
        return {
            ...entity,
            fullSlug: slugs.join('/'),
            categorySlug: entity.slug,
            subcategories: uniq(entity.subcategories || [])
                .filter((cat) => shownCategories.ids.includes(cat))
                .map(mapper)
        };
    }

    const rootCategoryIDs = shownCategories.ids.filter((id) => getCategory(state, id).entity.parentId == null);
    const rootCategories = rootCategoryIDs.map(mapToCategory.bind(null, []));

    return {
        shownCategories,
        rootCategories,
        landingPageCategories: state.landingPageCategories,
        bazar
    };
};

CategoriesDropdownContainer.defaultProps = {
    mode: 'all',
    collection: undefined
};

const mapDispatchToProps = {
    loadCategoryTree: actions.loadCategoryTree,
    navigateTo: push
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CategoriesDropdownContainer);
