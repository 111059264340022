import React, { useState } from 'react';

import InputPassword from './InputPassword';
import { useHistory } from 'react-router';
import backgroundImg from '../../styles/assets/images/welcome-image.jpeg';
import bazarLogoImg from '../../styles/assets/images/bazarLogo.png';

const MerchantWelcome = () => {
    const [error, setError] = useState(false);
    const history = useHistory();

    const sendRequestHandler = (password) => {
        setError(false);
        const API_SERVER = (process.env.REACT_APP_MYBAZAR_API_SERVER || '').replace(/\/$/, '');
        if (API_SERVER === '') {
            throw new Error('Must define REACT_APP_MYBAZAR_API_SERVER');
        }

        fetch(`${API_SERVER}/v3/auth/merchant_login`, {
            method: 'POST',
            body: JSON.stringify({ merchantPassword: password }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Unsuccessful request');
                }

                localStorage.setItem('authentifiedMerchant', '1');
                history.replace('/');
            })
            .catch(() => {
                console.log('Incorrect merchant password');
                setError(true);
            });
    };

    return (
        <React.Fragment>
            <div className='merchant-welcome'>
                <img className='merchant-welcome-background' src={backgroundImg} />

                <div className='logo-top-left-container'>
                    <img className='logo-top-left' src={bazarLogoImg} />
                </div>
                <div className='welcome-header-main'>
                    <div className='welcome-header-bazarText'>
                        <h1>MyBazar je jedinstvena platforma za lokalne trgovce, poduzetnike i kupce koji traže raznovrsnu ponudu na jednom mjestu</h1>
                        <InputPassword onSubmit={sendRequestHandler} error={error} setError={setError} />
                    </div>
                </div>
            </div>
            <footer style={{ height: '200px', width: '100%', backgroundColor: 'lightgray', paddingTop: '50px' }}>
                <div style={{ textAlign: 'center', width: '20%', margin: '0 auto' }}>
                    <h3>Podijeli</h3>
                    <div style={{ display: 'flex', gap: '15px', justifyContent: 'center' }}>
                        <a target='_blank' rel='noreferrer' href='//www.facebook.com/sharer.php?u=https://mybazar.com'>
                            <i className='facebook icon' style={{ fontSize: '26px', cursor: 'pointer', color: 'black' }}></i>
                        </a>
                        <a target='_blank' rel='noreferrer' href='//twitter.com/share?text=&url=https://mybazar.com'>
                            <i className='twitter icon' style={{ fontSize: '26px', cursor: 'pointer', color: 'black' }}></i>
                        </a>
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='//pinterest.com/pin/create/button/?url=https://mybazar.com&amp;media=//cdn.shopify.com/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c_1024x1024.gif&amp;description='
                        >
                            <i className='pinterest icon' style={{ fontSize: '26px', cursor: 'pointer', color: 'black' }}></i>
                        </a>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

export default MerchantWelcome;
