// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

// LOAD_STATES_AND_CITIES ----------------------
export type LoadStatesAndCitiesParams = {
    countryId: number
};

export const LOAD_STATES_AND_CITIES = 'LOAD_STATES_AND_CITIES';
export const loadStatesAndCities = wrapAPIActionCreator(({ countryId = 124 }: LoadStatesAndCitiesParams) => ({
    [API_DATA]: {
        type: LOAD_STATES_AND_CITIES,
        endpoint: `/v3/countries/${countryId}/cities`,
        method: 'GET',
        schema: {
            states: [Schemas.state]
        },
        reducerParams: { countryId },
        postprocess: (res) => ({ ...res, countryId })
    }
}));
