// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadLandingComponentsParams = {
    tab: string,
    channel?: string,
    category?: string
};

export const LOAD_LANDING_COMPONENTS = 'LOAD_LANDING_COMPONENTS';
export const loadLandingComponents = wrapAPIActionCreator(({ tab, channel = 'web' }: LoadLandingComponentsParams) => ({
    [API_DATA]: {
        type: LOAD_LANDING_COMPONENTS,
        endpoint: '/v3/landing_components',
        optionalAuthentication: Authentication.User,
        endpointParams: { displayTab: tab, displayChannel: channel },
        schema: [Schemas.landingComponent],
        postprocess: (res) => ({ ...res, tab, channel })
    }
}));
