import React from 'react';
import MonriCardComponent from '../../../../../../../../utilities/monri/MonriCardComponent';
import { Form, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const CheckoutCardDetails = ({ monriComponents, name, setName }) => {
    const { t } = useTranslation('translations');
    return (
        <Grid.Row>
            <Grid.Column>
                <Form>
                    <Grid.Row className='address-input'>
                        <Grid.Column>
                            <Form.Input
                                input={<input value={name} onChange={(e) => setName(e.target.value)} />}
                                label={t('checkout.details.payment.name.label', { defaultValue: 'Name on card' })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <MonriCardComponent monriComponents={monriComponents} />
                        </Grid.Column>
                    </Grid.Row>
                </Form>
            </Grid.Column>
        </Grid.Row>
    );
};

export default CheckoutCardDetails;
