// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { Button, Container, Dimmer, Form, Header, Loader, Message, Icon } from 'semantic-ui-react';

type FormValues = {
    password: string,
    passwordConfirm: string
};

type Props = {
    isBadToken?: boolean,
    isLoading?: boolean,
    isSubmitting?: boolean,
    errorMessage?: string,
    onClickForgotPassword?: () => void,
    onSubmit?: (FormValues) => void,
    t: *
};

type State = {
    password: string,
    passwordConfirm: string,
    isConfirmMismatch: boolean
};

class RecoverScene extends Component<Props, State> {
    state = {
        password: '',
        passwordConfirm: '',
        isConfirmMismatch: false,
        showPassword: false,
        showPasswordConfirm: false
    };

    handleChange = (event, { name, value }) => {
        const { password, passwordConfirm } = { ...this.state, [name]: value };
        const isConfirmMismatch = passwordConfirm !== '' && password !== passwordConfirm;
        this.setState({ [name]: value, isConfirmMismatch });
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const { password, passwordConfirm } = this.state;
        onSubmit({ password, passwordConfirm });
    };

    handleClickForgotPassword = (event) => {
        const { onClickForgotPassword } = this.props;
        event.preventDefault();
        onClickForgotPassword();
    };

    togglePassword = (pass) => {
        if (pass === 'password') {
            this.setState({ showPassword: !this.state.showPassword });
        } else {
            this.setState({ showPasswordConfirm: !this.state.showPasswordConfirm });
        }
    };

    handleErrorMessage = (message: string) => {
        switch (message) {
            case 'UsernameAlreadyExist':
                return 'Korisničko ime je zauzeto.';
            case 'password value is out of range, must be between [6, 50]':
                return 'Šifra mora sadržavati između 6 i 50 karaktera.';
            default:
                return message;
        }
    };

    render() {
        const { isBadToken, isLoading, isSubmitting, errorMessage, t } = this.props;
        const { password, passwordConfirm, isConfirmMismatch } = this.state;

        if (isBadToken) {
            return (
                <Container text className='narrow'>
                    <Header size='small' textAlign='center'>
                        Postavite novu šifru
                    </Header>
                    <Message error>
                        Ups! Nešto je pošlo po zlu pri pokušaju oporavka vašeg računa. Vaš token za ponovno postavljanje šifre je možda istekao. Molimo{' '}
                        <Link to='/forgot-password' onClick={this.handleClickForgotPassword}>
                            pokušajte ponovo da resetujete
                        </Link>
                        , ili kontaktirajte korisničku podršku.
                    </Message>
                </Container>
            );
        }

        return (
            <Container text className='narrow'>
                <Dimmer active={isLoading} inverted>
                    <Loader />
                </Dimmer>
                <Header size='small' textAlign='center'>
                    Postavite novu šifru
                </Header>
                <p>Molimo odaberite novu šifru koja sadrži najmanje 6 znakova.</p>
                <Form error={errorMessage != null} onSubmit={this.handleSubmit}>
                    <Message error>{this.handleErrorMessage(errorMessage)}</Message>
                    <Form.Input
                        type={this.state.showPassword ? 'text' : 'password'}
                        name='password'
                        id='password'
                        onChange={this.handleChange}
                        label={t('passwordRecovery.form.password.label', { defaultValue: 'Nova šifra' })}
                        placeholder={t('passwordRecovery.form.password.placeholder', { defaultValue: '' })}
                        value={password}
                        required
                        icon={() => <Icon onClick={() => this.togglePassword('password')} style={{ cursor: 'pointer', pointerEvents: 'unset' }} name='eye' />}
                    />
                    <Form.Input
                        type={this.state.showPasswordConfirm ? 'text' : 'password'}
                        name='passwordConfirm'
                        id='password-confirm'
                        onChange={this.handleChange}
                        label={t('passwordRecovery.form.passwordConfirm.label', { defaultValue: 'Potvrdite novu šifru' })}
                        placeholder={t('passwordRecovery.form.passwordConfirm.placeholder', { defaultValue: '' })}
                        error={isConfirmMismatch}
                        value={passwordConfirm}
                        required
                        icon={() => <Icon onClick={() => this.togglePassword('passwordConfirm')} style={{ cursor: 'pointer', pointerEvents: 'unset' }} name='eye' />}
                    />
                    <Button primary fluid disabled={passwordConfirm === '' || isConfirmMismatch} loading={isSubmitting}>
                        <Trans i18nKey='passwordRecovery.form.acceptButton'>Promijeni šifru</Trans>
                    </Button>
                </Form>
            </Container>
        );
    }
}

RecoverScene.defaultProps = {
    isLoading: false,
    isBadToken: false,
    isSubmitting: false,
    errorMessage: null,
    onClickForgotPassword: () => {},
    onSubmit: () => {}
};

export default compose(withTranslation('translations'))(RecoverScene);
