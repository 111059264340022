// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// SEARCH_STORES_BY_KEYWORD ------------------
export type SearchStoresByKeywordParams = {
    query: string,
    limit?: number,
    offset?: number,
    sort?: string,
    onlyBlee?: boolean
};

export const SEARCH_STORES_BY_KEYWORD = 'SEARCH_STORES_BY_KEYWORD';
export const searchStoresByKeyword = wrapAPIActionCreator(
    ({ query, limit = 12, offset = 0, sort = 'latest', onlyBlee = false, only_registered = true }: SearchStoresByKeywordParams) => ({
        [API_DATA]: {
            type: SEARCH_STORES_BY_KEYWORD,
            endpoint: '/v3/web/search/stores',
            optionalAuthentication: Authentication.User,
            endpointParams: {
                query,
                limit,
                offset,
                sort,
                onlyBlee,
                only_registered
            },
            reducerParams: {
                sort,
                offset,
                limit,
                query
            },
            schema: {
                stores: [Schemas.user]
            },
            postprocess: (result) => ({ ...result, offset, query })
        }
    })
);
