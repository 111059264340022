// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import BankAccountAddModalView from './BankAccountAddModalView';

type Props = {
    // from store
    createBankAccount: typeof actions.createBankAccount,
    openModal: typeof actions.openModal
};

class BankAccountAddModalContainer extends Component<Props> {
    render() {
        return <BankAccountAddModalView {...this.props} formId='bankAccount.form.add' translationId='bankAccount.form' />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    createBankAccount: wrapAPIDispatch(dispatch, actions.createBankAccount),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(BankAccountAddModalContainer);
