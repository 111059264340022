// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { EntityData, User } from 'store/types';

import StoreView from './StoreView';

type Props = {
    entityData: EntityData<User>,
    searchRecordBySlug: typeof actions.searchRecordBySlug,
    loadItemsByUser: typeof actions.loadItemsByUser,
    loadUser: typeof actions.loadUser,
    storeItems: array,
    totalCount: number,
    hasMore: boolean,
    isLoadingItems: boolean
};

class StoreContainer extends Component<Props> {
    componentDidMount() {
        const { entityData, loadUser, storeItems } = this.props;

        if (entityData.entity) {
            if (!storeItems) {
                this.loadMoreItems();
            }
            if (!entityData.entity.settings) {
                loadUser({ id: entityData.entity.id, fields: ['cover_photos', 'user_settings'] }).catch((err) => console.error(err));
            }
        }
    }

    /*iskoristio sam SortDropdown komponentu u Store-u i na njoj je sve nužno za update sorta. onChange,value itd.
    Ovdje sam iz statea izvukao sort jer na onCHange na dropdownu se snima vrijednost i na svaku promjenu sorta u SortDropdown
    dobit ćemo je ovdje unutar didUpdate. Vrijednost se pravilno aktualizira, sad bi je trebalo nekako ubaciti prilikom loadanja itemsa.

    */
    componentDidUpdate(prevProps) {
        const { sort, entityData, loadItemsByUser } = this.props;

        if (prevProps.sort !== sort) {
            const offset = 0;
            loadItemsByUser({
                userId: entityData.entity.id,
                offset,
                sort,
                fields: ['pictures', 'entityData', 'store', 'is_liked', 'comments']
            })
            .catch((err) => console.error(err));
        }
    }

    loadMoreItems(calculations = { bottomVisible: true }) {
        const { entityData, storeItems, hasMore, isLoadingItems, sort } = this.props;

        if (isLoadingItems) {
            return;
        }
        let offset = 0;
        if (storeItems) {
            offset = storeItems.length;
        }
        if (calculations.bottomVisible && hasMore) {
            this.props
                .loadItemsByUser({
                    userId: entityData.entity.id,
                    offset,
                    sort,
                    fields: ['pictures', 'entityData', 'store', 'is_liked', 'comments']
                })
                .catch((err) => console.error(err));
        }
    }

    render() {
        const { entityData, ...rest } = this.props;

        const { entity: merchant } = entityData;

        if (!merchant || !merchant.settings) {
            return <Loader active />;
        }
        return <StoreView merchant={merchant} {...rest} loadMoreItems={(calculations) => this.loadMoreItems(calculations)} />;
    }
}

const mapStateToProps = (state, { entityData }) => {
    const { sort } = state.filters;
    const merchantDataState = state.merchants[entityData.entity.id] || {};
    const itemState = merchantDataState[sort] || { isLoading: false, hasMore: true };
    const { isLoading: isLoadingItems, hasMore, list, totalCount } = itemState;


    return {
        isLoadingItems,
        storeItems: list,
        totalCount,
        hasMore,
        entityData,
        sort
    };
};

const mapDispatchToProps = (dispatch) => ({
    searchRecordBySlug: wrapAPIDispatch(dispatch, actions.searchRecordBySlug),
    loadItemsByUser: wrapAPIDispatch(dispatch, actions.loadItemsByUser),
    loadUser: wrapAPIDispatch(dispatch, actions.loadUser)
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);
