// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Segment, Image, Label, Grid } from 'semantic-ui-react';

import type { EntityData, User } from 'store/types';
import FollowButton from 'components/FollowButton';
import { formatStoreName } from 'utilities/format';
import missingUserImage from 'styles/assets/images/missing-user-image.svg';

type Props = {
    user: EntityData<User>,
    parentUser?: User,
    preventSlickClickDuringChange: () => void
};

function UserTeaser({ user, parentUser, preventSlickClickDuringChange }: Props) {
    // TODO: handle loading better
    if (user.isFetching || user.entity == null) {
        return null;
    }
    const { alias, pictureThumb, slug, userSlug, isVerified, id, isFollowing } = user.entity;
    const storeURL = `/${slug || userSlug}`;

    return (
        <Segment className='store-teaser simple'>
            <Link to={storeURL} onClick={preventSlickClickDuringChange || null}>
                {/*<Image size="medium">*/}
                <Image
                    className='circular-image'
                    wrapped
                    alt={alias}
                    src={pictureThumb || missingUserImage}
                    onError={(event) => {
                        event.target.src = missingUserImage;
                    }}
                />
            </Link>
            <Header as='h3' className='store-teaser-header'>
                <Link to={storeURL} onClick={preventSlickClickDuringChange || null}>
                    {formatStoreName(alias)}
                </Link>
            </Header>
        </Segment>
    );
}

export default withTranslation('translations')(UserTeaser);
