// @flow
import { invert, map, mapValues, merge, pickBy } from 'lodash';

// Adds new slug=>entity mappings from any action with payload.entities set
export default function mapSlug(state = {}, action) {
    if (action.payload && action.payload.entities) {
        const mapCollectionToRefs = (obj, collection) =>
            // Constructs the mapping slug => { collection, id } for each new entity
            mapValues(invert(pickBy(mapValues(obj, (ent) => ent.slug))), (id) => ({ collection, id }));
        const update = Object.assign({}, ...map(action.payload.entities, mapCollectionToRefs));
        return merge({}, state, update);
    }
    return state;
}
