// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_CATEGORY_ATTRIBUTES ------------------
export type LoadCategoryAttributesParams = {
    categoryId: number
};

export const LOAD_CATEGORY_ATTRIBUTES = 'LOAD_CATEGORY_ATTRIBUTES';
export const loadCategoryAttributes = wrapAPIActionCreator(({ categoryId }: LoadCategoryAttributesParams) => ({
    [API_DATA]: {
        type: LOAD_CATEGORY_ATTRIBUTES,
        endpoint: `/v3/category/${categoryId}/attributes`,
        authentication: Authentication.Merchant,
        reducerParams: {
            categoryId
        },
        schema: {
            categories: [Schemas.category],
            attributes: [Schemas.categoryAttribute]
        },
        preprocess: ({ attributes }) => {
            const categories = {};
            const filteredAttributes = [];
            attributes.forEach(({ category, categoryId: id, ...attribute }) => {
                if (!categories[id]) {
                    categories[id] = {
                        id,
                        attributes: []
                    };
                }
                categories[id].attributes.push(attribute);
                filteredAttributes.push(attribute);
            });
            return {
                categories: Object.values(categories),
                attributes: filteredAttributes,
                categoryId
            };
        }
    }
}));

export default loadCategoryAttributes;
