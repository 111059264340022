// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Image, Label } from 'semantic-ui-react';
import missingUserImage from 'styles/assets/images/profile.png';

const Props = {
    user: {}
};

function ProfilePicture({ user }: Props) {
    return (
        <Grid.Column className='col-sm-16 col-shrink'>
            <Image
                style={{ padding: 15, borderRadius: '50%', height: 180, width: 180 }}
                fluid
                src={user.pictureThumb || missingUserImage}
                onError={(event) => {
                    event.target.src = missingUserImage;
                }}
            />
            {user.isVerified === true && <Label className='store-logo-label' circular basic={!user.pictureThumb} icon='check' />}
        </Grid.Column>
    );
}

export default ProfilePicture;
