// @flow
import * as ActionTypes from '../../actions';

const initialState = {};

const defaultItemsState = {
    isLoading: false,
    list: [],
    hasMore: true
};

export default function merchants(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_ITEMS_BY_USER: {
            const { userId, sort, offset, limit } = action.reducerParams;

            const merchantState = state[userId] || {};
            const itemsState = merchantState[sort] || { ...defaultItemsState };

            if (action.status === 'request') {
                itemsState.isLoading = true;
            }
            if (action.status === 'error') {
                itemsState.isLoading = false;
            }
            if (action.status === 'success') {
                const { items: storeItems, totalCount } = action.payload.result;

                itemsState.isLoading = false;
                itemsState.hasMore = storeItems.length === limit;

                const { list } = itemsState;

                itemsState.list = offset === list.length ? list.concat(storeItems) : storeItems;
                itemsState.totalCount = totalCount;
            }
            merchantState[sort] = itemsState;
            return {
                ...state,
                [userId]: merchantState
            };
        }
        default:
            return state;
    }
}
