// @flow
import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';
import ItemImages from './components/ItemImages';

type Props = {
    t: (string) => string
};

type State = {
    formValues: {
        title: FormFieldData<string>
    }
};

const defaultValuesFromItem = ({ item }) => {
    const { name, categoryParent, category, categoryChlid, descriptions, tags, status } = item;
    let body = descriptions.replace('<!DOCTYPE html><html><head></head><body>', '');
    body = body.replace('</body></html>', '');

    let descriptionEditorState = EditorState.createEmpty();
    if (body.length > 0) {
        const blocksFromHTML = convertFromHTML(body);
        const { contentBlocks } = blocksFromHTML;
        const { entityMap } = blocksFromHTML;

        if (contentBlocks) {
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            descriptionEditorState = EditorState.createWithContent(contentState);
        } else {
            descriptionEditorState = EditorState.createEmpty();
        }
    }

    return {
        title: createEmptyFormFieldData({ value: name, required: true }),
        category: createEmptyFormFieldData({ value: categoryParent, type: 'number', required: true }),
        subcategory: createEmptyFormFieldData({ value: category, type: 'number', required: true }),
        subSubcategory: createEmptyFormFieldData({ value: categoryChlid, type: 'number', required: true }),
        description: createEmptyFormFieldData({ value: `<!DOCTYPE html><html><head></head><body>${body}</body></html>` }),
        tags: createEmptyFormFieldData({ value: tags, required: false }),
        isBanned: createEmptyFormFieldData({ value: status === 4, type: 'boolean', required: false }), // TODO DG Fix hardcoded value
        descriptionEditorState
    };
};

class ItemFormItem extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { item, updateFormValues, formId } = props;

        let formValues = {};

        if (item) {
            formValues = defaultValuesFromItem({ item });
        } else {
            formValues = {
                title: createEmptyFormFieldData({ value: '', required: true }),
                category: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
                subcategory: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
                subSubcategory: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
                description: createEmptyFormFieldData({ value: '<!DOCTYPE html><html><head></head><body></body></html>' }),
                tags: createEmptyFormFieldData({ value: '', required: false }),
                isBanned: createEmptyFormFieldData({ value: false, type: 'boolean', required: false }),
                descriptionEditorState: EditorState.createEmpty()
            };
        }
        this.state = {
            formValues
        };

        updateFormValues({ formValues, formId });
    }

    onEditorStateChange = (editorState) => {
        const { formValues } = this.state;
        formValues.descriptionEditorState = editorState;
        this.setState({ formValues });
        const contentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(contentState);

        this.handleChange({}, { name: 'description', value: `<!DOCTYPE html><html><head></head><body>${markup}</body></html>` });
    };

    render() {
        const { rootCategories, getCategory } = this.props;

        const { formValues } = this.state;
        const {
            category: { value: categoryId },
            subcategory: { value: subcategoryId },
            descriptionEditorState
        } = formValues;
        console.log(formValues);

        const subcategories = categoryId != null ? getCategory(categoryId)?.subcategories.map((id) => getCategory(id)) ?? [] : [];

        const subSubcategories = subcategoryId != null ? getCategory(subcategoryId)?.subcategories.map((id) => getCategory(id)) ?? [] : [];
        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='sellItem.form.item.header'>Item</Trans>
                    </Header>
                </Form.Field>

                {this.renderInputField({ name: 'title', type: 'text' }, this.props)}

                {this.renderCheckboxField({ name: 'isBanned', type: 'boolean', width: 8 }, this.props)}

                <ItemImages {...this.props} />

                {this.renderDropdownField(
                    {
                        name: 'category',
                        options: rootCategories.map((category) => ({ text: category.name, value: category.id })),
                        onChange: (event, data) => {
                            const { formValues: newFormValues } = this.state;
                            newFormValues.subcategory.value = null;
                            newFormValues.subSubcategory.value = null;
                            this.setState({ formValues: newFormValues });
                            this.handleChange(event, data);
                        }
                    },
                    this.props
                )}

                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderDropdownField(
                            {
                                width: 8,
                                name: 'subcategory',
                                options: subcategories.map((category) => ({ text: category.name, value: category.id })),
                                onChange: (event, data) => {
                                    const { formValues: newFormValues } = this.state;
                                    newFormValues.subSubcategory.value = null;
                                    this.setState({ formValues: newFormValues });
                                    this.handleChange(event, data);
                                }
                            },
                            this.props
                        )}

                        {this.renderDropdownField(
                            { width: 8, name: 'subSubcategory', options: subSubcategories.map((category) => ({ text: category.name, value: category.id })) },
                            this.props
                        )}
                    </Form.Group>
                </Form.Field>

                <Form.Field>
                    <label htmlFor='sell-item-form-item-description'>
                        <Trans i18nKey='sellItem.form.item.description.label'>Description</Trans>
                    </label>

                    <Editor
                        editorState={descriptionEditorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'list', 'link'],
                            inline: {
                                options: ['italic', 'bold', 'underline', 'strikethrough']
                            }
                        }}
                    />
                </Form.Field>

                {this.renderInputField({ name: 'tags', type: 'text', defaultLabel: 'Tags' }, this.props)}
            </Form.Field>
        );
    }
}

export default withTranslation('translations', { withRef: true })(ItemFormItem);
