// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    list: []
};

function returnPolicies(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_RETURN_POLICIES:
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'success') {
                return {
                    ...state,
                    list: action.payload.result.returnPolicies,
                    isLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            break;
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default returnPolicies;
