// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import MyStoreMessagesTabView from './MyStoreMessagesTabView';

function MyStoreMessagesTabContainer(props) {
    return <MyStoreMessagesTabView {...props} />;
}

export default MyStoreMessagesTabContainer;
