// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';

import * as actions from 'store/actions';
import { getItem } from 'store/utilities';
import type { EntityData, Conversation } from 'store/types';
import { MessageTypes } from 'store/enums';

import SendMessageView from './SendMessageView';

type Props = {
    conversation: EntityData<Conversation>,
    myId: number,
    productId: number
};

class SendMessageContainer extends Component<Props> {
    sendMessage = ({ message, file }: { message: string, file: string | ArrayBuffer }) => {
        const { postMessage, conversation, myId, product, authentication = Authentication.User } = this.props;

        const messageParams = {
            message,
            myId,
            authentication
        };

        if (conversation && conversation.entity) {
            messageParams.itemId = conversation.entity.item;
            messageParams.recipientId = conversation.entity.user;
            messageParams.typeId = conversation.entity.typeId;
            messageParams.conversationKey = conversation.entity.conversationKey;
        } else if (product && product.entity) {
            messageParams.itemId = product.entity.id;
            messageParams.recipientId = product.entity.userId;
            messageParams.typeId = MessageTypes.MESSAGE;
        }

        if (file) {
            messageParams.pictures = [file];
        }

        return postMessage(messageParams);
    };

    render() {
        const { onPostMessageSuccess, focusSendMessage } = this.props;
        return <SendMessageView sendMessage={this.sendMessage} focusSendMessage={focusSendMessage} onPostMessageSuccess={onPostMessageSuccess} />;
    }
}

const mapStateToProps = (state, { productId }) => ({
    product: getItem(state, productId)
});

const mapDispatchToProps = (dispatch) => ({
    postMessage: wrapAPIDispatch(dispatch, actions.postMessage)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(SendMessageContainer);
