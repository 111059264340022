// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
    acceptedFiletypes: string,
    onFileChange: () => void
};

const FileUploaderView = ({ acceptedFiletypes, onFileChange, fileInputRef }: Props) => (
    <>
        <Button as='div' className='message-upload-button' icon onClick={() => fileInputRef.current.click()}>
            <Icon name='attach' />
            <Trans i18nKey='messages.conversation.actions.upload'>Upload file</Trans>
        </Button>
        <input hidden id='file' type='file' onChange={onFileChange} ref={fileInputRef} accept={acceptedFiletypes} />
    </>
);

export default withTranslation('translations')(FileUploaderView);
