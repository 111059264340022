// @flow
import React from 'react';
import { Menu, Tab } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import PromotionsMenuItem from './components/PromotionsMenuItem';
import PromotionsPreview from './components/PromotionsPreview';

const PromotionsView = ({ items }) => {
    let panes = [];
    if (items.length > 0) {
        panes = items.map((item) => ({
            menuItem: (
                <Menu.Item key={item.entity.conversationKey}>
                    <PromotionsMenuItem item={item} />
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false} className='simple'>
                    <PromotionsPreview message={item.entity.message} />
                </Tab.Pane>
            )
        }));
    } else {
        panes = [
            {
                menuItem: (
                    <Menu.Item key='no-promotions'>
                        <Trans i18nKey='promotions.noPromotionsFound.label'>No promotions found.</Trans>
                    </Menu.Item>
                )
            }
        ];
    }
    return (
        <Tab
            menu={{
                fluid: true,
                vertical: true,
                secondary: true,
                style: {
                    margin: 0
                }
            }}
            panes={panes}
        />
    );
};

export default withTranslation('translations')(PromotionsView);
