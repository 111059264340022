// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// CREATE_MERCHANT_STORE_LOCATION --------------------------------
type MailingAddressParams = {
    city: string,
    contactNo?: string, // Phone number
    country: string,
    houseNo: string,
    state: string,
    street: string,
    zip: string
};

export type CreateMerchantStoreLocationParams = {
    name: string,
    mailingAddress: MailingAddressParams
};

export const CREATE_MERCHANT_STORE_LOCATION = 'CREATE_MERCHANT_STORE_LOCATION';
export const createMerchantStoreLocation = wrapAPIActionCreator(({ name, mailingAddress }: CreateMerchantStoreLocationParams) => ({
    [API_DATA]: {
        type: CREATE_MERCHANT_STORE_LOCATION,
        endpoint: '/v3/me/stores',
        authentication: Authentication.Merchant,
        method: 'POST',
        body: {
            name,
            mailingAddress
        },
        schema: {
            store: Schemas.store
        }
    }
}));
