// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Link } from 'react-router-dom';

import { Button } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

type Props = {
    goBackToLogin: () => void
};

class ForgotPasswordScene extends Component<Props> {
    click = (event) => {
        const { goBackToLogin } = this.props;
        event.preventDefault();
        goBackToLogin();
    };

    render() {
        const { t } = this.props;

        return (
            <div>
                <p>{t('resetPasswordForm.part1', { defaultValue: 'You will receive a password reset link if this email is associated with your MyBazar Account.' })}</p>
                <p>{t('resetPasswordForm.part2', { defaultValue: 'Please check your email inbox for further instructions.' })}</p>
                <Link to='/login' onClick={this.click}>
                    <Button fluid primary>
                        {t('resetPasswordForm.back', { defaultValue: 'Back to Log In' })}
                    </Button>
                </Link>
            </div>
        );
    }
}

export default compose(connect(null, { navigateTo: push }), withTranslation('translations'))(ForgotPasswordScene);
