// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import type { EntityData, User } from 'store/types';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import ProfileView from './ProfileView';

type Props = {
    entityData: EntityData<User>
};

class ProfileContainer extends Component<Props> {
    componentDidMount() {
        const { entityData, loadUser } = this.props;

        if (entityData.entity) {
            if (!entityData.entity.coverPhotos) {
                loadUser({ id: entityData.entity.id, fields: ['cover_photos'] }).catch((err) => console.error(err));
            }
        }
    }

    render() {
        const { entityData, ...rest } = this.props;
        const { entity: user } = entityData;
        const profileAddress = user.mailingAddress?.city + ', ' + user.mailingAddress?.country;
        if (user) {
            return <ProfileView profileAddress={profileAddress} user={user} {...rest} />;
        }
        return null;
    }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
    loadUser: wrapAPIDispatch(dispatch, actions.loadUser)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProfileContainer);
