// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from '../../actions';

import forms from './forms';

function isLoggingIn(state = false, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_IS_LOGGING_IN:
            return action.isLoggingIn;
        default:
            return state;
    }
}

function locale(state = 'en', action) {
    switch (action.type) {
        case ActionTypes.UPDATE_LOCALE:
            return action.locale;
        default:
            return state;
    }
}

function modals(state = {}, action) {
    switch (action.type) {
        case ActionTypes.OPEN_MODAL: {
            const { name, props } = action.payload;
            return {
                ...state,
                [name]: {
                    ...state[name],
                    isOpen: true,
                    props
                }
            };
        }

        case ActionTypes.CLOSE_MODAL:
            return {
                ...state,
                [action.payload.name]: {
                    ...state[action.payload.name],
                    isOpen: false
                }
            };

        default:
        // do nothing
    }
    return state;
}

function promoDismissed(state = false, action) {
    switch (action.type) {
        case ActionTypes.DISMISS_PROMOBAR:
            return true;
        default:
            return state;
    }
}

export default combineReducers({
    modals,
    forms,
    isLoggingIn,
    locale,
    promoDismissed
});
