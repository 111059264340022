// @flow
import React from 'react';

import View from './RegisterAccountView';

const RegisterAccountScene = () => {
    return <View />;
};

export default RegisterAccountScene;
