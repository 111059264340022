// @flow
import React, { Component } from 'react';
import { Modal, Button, Icon, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import ReactCrop, { makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

/**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 * @param {String} fileName - Name of the returned file in Promise
 */
function getCroppedImg(image, pixelCrop) {
    const img = new Image();
    img.src = URL.createObjectURL(image);

    return new Promise((resolve, reject) => {
        img.onload = () => {
            const { naturalWidth, naturalHeight } = img;
            pixelCrop.x = pixelCrop.x * naturalWidth / 100;
            pixelCrop.y = pixelCrop.y * naturalHeight / 100;
            pixelCrop.width = pixelCrop.width * naturalWidth / 100;
            pixelCrop.height = pixelCrop.height * naturalHeight / 100;

            const canvas = document.createElement('canvas');
            canvas.width = pixelCrop.width;
            canvas.height = pixelCrop.height;
            const ctx = canvas.getContext('2d');

            ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);
            const base64Uri = canvas.toDataURL('image/jpeg', 0.8);

            resolve(base64Uri);
        };
    });
}

type Props = {
    onDone: Function,
    onCancel: Function,
    file: any,
    fileURI: string
};

type State = {
    crop: {
        x: number,
        y: number,
        aspect: number,
        width: number
    },
    pixelCrop: ?{
        x: number,
        y: number,
        aspect: number,
        width: number
    }
};

class CropFileModal extends Component<Props, State> {
    state = {
        crop: {
            x: 0,
            y: 0,
            aspect: 1 / 1,
            width: 50
        },
        pixelCrop: null
    };

    onImageLoaded = (image) => {
        const crop = makeAspectCrop(
            {
                x: 0,
                y: 0,
                aspect: 1 / 1,
                width: 50
            },
            image.width / image.height
        );

        this.setState({ crop });
    };

    onChange = (crop, pixelCrop) => {
        this.setState({ crop, pixelCrop });
    };

    onComplete = (crop, pixelCrop) => {
        this.setState({ crop, pixelCrop });
    };

    onSubmit = () => {
        const { onDone, file } = this.props;
        const { crop, pixelCrop } = this.state;
        getCroppedImg(file, pixelCrop).then((croppedFile) => onDone(croppedFile));
    };

    render() {
        const { onCancel, fileURI } = this.props;
        return (
            <Modal size='tiny' open>
                <Header>
                    <Icon fitted name='add' />
                    <Header.Content>
                        <Trans i18nKey='cropPicture.header'>Crop Picture</Trans>
                    </Header.Content>
                </Header>
                <Modal.Content>
                    <ReactCrop src={fileURI} crop={this.state.crop} onImageLoaded={this.onImageLoaded} onChange={this.onChange} onComplete={this.onComplete} />
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={onCancel}>
                        <Trans i18nKey='cropPicture.actions.cancel'>Cancel</Trans>
                    </Button>
                    <Button disabled={!this.state.pixelCrop} positive icon labelPosition='left' onClick={this.onSubmit}>
                        <Icon name='check' />
                        <Trans i18nKey='cropPicture.actions.save'>Save</Trans>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withTranslation('translations')(CropFileModal);
