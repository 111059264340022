// @flow

// SET_LOCALE ------------------
export type SetLocaleParams = {
    locale: string
};

export const UPDATE_LOCALE = 'UPDATE_LOCALE';
export const setLocale = (locale) => ({
    type: UPDATE_LOCALE,
    locale
});
