// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getCountry, getState, getCity } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import BankAccountModalView from './BankAccountModalView';

import type { FormValues } from './index';

type Props = {
    name: string,
    onSubmit: (FormValues) => ?Promise<*>,
    // from store
    closeModal: typeof actions.closeModal,
    loadCountries: typeof actions.loadCountries,
    loadStatesAndCities: typeof actions.loadStatesAndCities,
    countries: number[],
    states: {}
};

class BankAccountModalContainer extends Component<Props> {
    static defaultProps;

    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    handleCountryChange = ({ value }) => {
        const addressStates = this.props.addressStates[value];
        if (!addressStates || !addressStates.list) {
            if (!addressStates || !addressStates.isLoading) {
                this.props.loadStatesAndCities({ countryId: value });
            }
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bankAccount, addressStates } = nextProps;
        if (bankAccount) {
            const addressState = addressStates[bankAccount.countryId];
            if (!addressState || !addressState.list) {
                if (!addressState || !addressState.isLoading) {
                    this.props.loadStatesAndCities({ countryId: bankAccount.countryId });
                }
            }
        }
    }

    render() {
        const { name, closeModal, bankAccount, addressStates, ...rest } = this.props;

        return <BankAccountModalView onClose={this.handleClose} bankAccount={bankAccount} addressStates={addressStates} onCountryChange={this.handleCountryChange} {...rest} />;
    }
}

BankAccountModalContainer.defaultProps = {
    onSubmit: () => {}
};

const mapStateToProps = (state, { name }) => {
    const newProps = {
        countries: state.address.enabledCountries,
        addressStates: state.address.addressStates,
        getCountry: (id) => getCountry(state, id),
        getState: (id) => getState(state, id),
        getCity: (id) => getCity(state, id)
    };

    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return {
            ...newProps,
            isOpen: false
        };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;

    return {
        ...newProps,
        ...extraProps,
        isOpen: true
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    loadCountries: wrapAPIDispatch(dispatch, actions.loadCountries),
    loadStatesAndCities: wrapAPIDispatch(dispatch, actions.loadStatesAndCities),
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(BankAccountModalContainer);
