import React from 'react';
import { useEffect } from 'react';

const cardStyle = {
    style: {
        base: {
            fontFamily: 'Lato'
        },
        label: {
            base: {
                fontSize: '13px',
                lineHeight: '1.4285em',
                height: '12px',
                fontWeight: '700',
                color: 'rgba(0,0,0,.87)',
                letterSpacing: '0px',
                textTransform: 'capitalize'
            }
        },
        input: {
            base: {
                border: '1px solid rgba(34,36,38,.15)',
                borderRadius: '.28571429rem',
                padding: '.67857143em 1em',
                height: 'unset',
                fontSize: '14px',
                fontWeight: '300'
            }
        }
    }
};

export default function MonriCardComponent({ monriComponents }) {
    useEffect(() => {
        if (!monriComponents.instance) {
            return;
        }
        if (document.getElementById('monri-card').innerHTML.length > 0) {
            document.getElementById('monri-card').innerHTML = '';
        }
        const card = monriComponents.instance.create('card', cardStyle);
        card.mount('monri-card');
        monriComponents.componentList.push(card);
        card.onChange((e) => {
            monriComponents.changeListeners.forEach((listener) => listener(e));
        });
        return () => {
            monriComponents.componentList.splice(monriComponents.componentList.indexOf(card), 1);
        };
    }, [monriComponents]);
    return <div style={{ marginBottom: '-16px' }} id='monri-card' />;
}
