// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Segment } from 'semantic-ui-react';

import CategoryBreadcrumb from 'components/CategoryBreadcrumb';
import LandingComponent from 'components/LandingComponent';
import ProductTeaser from 'components/ProductTeaser';
import SortablePaginatedGrid from 'components/SortablePaginatedGrid';

import type { EntityData, Category, LandingPage } from 'store/types';

type Props = {
    category: EntityData<Category>,
    landingPage: EntityData<LandingPage>,
    sort: string,
    loadMoreItems: ({ offset: number, limit: number }) => Promise<number[]>
};

const renderItem = (id) => <ProductTeaser itemId={id} />;
const keyExtractor = (id) => id;

function TopCategoryView({ category, loadMoreItems, landingPage, sort }: Props) {
    const { id: categoryId } = category.entity || {};
    return (
        <Container>
            <CategoryBreadcrumb categoryId={categoryId} />
            {((landingPage.entity && landingPage.entity.components) || []).map((id) => (
                <LandingComponent key={id} landingComponentId={id} />
            ))}

            <Segment className='teaser-carousel teaser-reduced-top-margin' style={{ border: 'none', boxShadow: 'none' }}>
                <SortablePaginatedGrid loadMoreItems={loadMoreItems} renderItem={renderItem} keyExtractor={keyExtractor} id={`${categoryId}-${sort}`} />
            </Segment>
        </Container>
    );
}

export default TopCategoryView;
