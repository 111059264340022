// @flow
import { combineReducers } from 'redux';
import * as ActionTypes from 'store/actions';

function storeFacebookInformation(state = { information: {} }, action) {
    switch (action.type) {
        case ActionTypes.SIGNUP_FACEBOOK_INFORMATION:
            return {
                ...state,
                information: action.information
            };
        default:
            break;
    }
    return state;
}

export default combineReducers({
    storeFacebookInformation
});
