// @flow
import React, { PureComponent } from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

import { formatUsername } from 'utilities/format';
import type { User } from 'store/types';

import StoreTabBar from 'components/StoreTabBar';
import MyStoreCover from './components/MyStoreCover';
import MyStoreProfilePicture from './components/MyStoreProfilePicture';
import MyStoreActions from './components/MyStoreActions';
import { withTranslation } from 'react-i18next';

type Props = {
    isLoggedIn: boolean,
    merchant: User,
    user: User
};

class MyStoreView extends PureComponent<Props> {
    render() {
        const { merchant, user } = this.props;
        const { alias, username } = merchant;
        return (
            <Container>
                <Grid className='store'>
                    <Grid.Row>
                        <Grid.Column>
                            <MyStoreCover />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Grid className='auto'>
                                <Grid.Column className='col-sm-16 col-shrink'>
                                    <MyStoreProfilePicture />
                                </Grid.Column>
                                <Grid.Column className='col-sm-16'>
                                    <Header as='h1'>
                                        {alias}
                                        <Header.Subheader>{formatUsername(username)}</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column className='col-sm-16 col-shrink'>
                                    <MyStoreActions />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <StoreTabBar merchant={merchant} />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        );
    }
}

export default withTranslation('translations')(MyStoreView);
