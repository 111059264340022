// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { getLandingComponentBanner } from 'store/utilities';
import type { EntityData, LandingComponentBanner } from 'store/types';

import ShoppingMallCarouselItemView from './ShoppingMallCarouselItemView';

type Props = {
    // openModal: typeof actions.openModal,
    // banner: EntityData<LandingComponentBanner>,
};

class ShoppingMallCarouselItemContainer extends Component<Props> {
    render() {
        const { ...rest } = this.props;
        return <ShoppingMallCarouselItemView {...rest} />;
    }
}

// const mapStateToProps = (state, { bannerId }) => {
//   const banner = getLandingComponentBanner(state, bannerId);
//   return { banner };
// };

// const mapDispatchToProps = dispatch => ({
//   openModal: (...args) => dispatch(actions.openModal(...args)),
// });

export default ShoppingMallCarouselItemContainer;
