// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getIsLoggedIn, getUser } from 'store/utilities';
import * as actions from 'store/actions';

import PageSidebarView from './SidebarView';

type Props = {
    logoutUser: typeof actions.logoutUser
};

function PageSidebar({ logoutUser, ...rest }: Props) {
    const onLogout = () => {
        logoutUser();
    };

    return <PageSidebarView {...{ onLogout }} {...rest} />;
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    user: getUser(state, state.userData.user).entity,
    merchantUser: getUser(state, state.merchantData.user).entity
});

export default connect(mapStateToProps, { logoutUser: actions.logoutUser })(PageSidebar);
