// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getCountry, getState, getCity, getIsLoggedIn, getUser } from 'store/utilities';

import type { EntityData, User } from 'store/types';

import CreateStoreFormView from './CreateStoreFormView';

type State = {
    isLoadingAccounts: boolean
};

type Props = {
    isLoggedIn: boolean,
    isLoadingAccounts: boolean,
    merchantUser: EntityData<User>,
    user: EntityData<User>,
    loadMyAccounts: typeof actions.loadMyAccounts,
    createMerchantAccount: typeof actions.createMerchantAccount,
    loadCountries: typeof actions.loadCountries,
    loadStatesAndCities: typeof actions.loadStatesAndCities,
    countries: [number],
    states: {}
};

class CreateStoreFormContainer extends Component<Props, State> {
    state = {
        isLoadingAccounts: true
    };

    componentDidMount() {
        // The timeout is because we're waiting for Rehydration to loadAccounts too if it's a page reload
        setTimeout(() => {
            const { isLoggedIn, merchantUser, isLoadingAccounts, loadMyAccounts } = this.props;
            if (isLoggedIn && !merchantUser && !isLoadingAccounts) {
                loadMyAccounts()
                    .then(() => this.setState({ isLoadingAccounts: false }))
                    .catch(() => this.setState({ isLoadingAccounts: false }));
            } else {
                this.setState({ isLoadingAccounts: false });
            }
        }, 0);

        const { loadCountries } = this.props;

        loadCountries();
    }

    handleCountryChange = ({ value }) => {
        if (!this.props.states[value]) {
            this.props.loadStatesAndCities(value);
        }
    };

    render() {
        const { isLoggedIn, merchantUser } = this.props;
        if (this.state.isLoadingAccounts) {
            return <Loader active />;
        }

        if (!isLoggedIn) {
            return <Redirect to='/login' />;
        }
        if (merchantUser) {
            return <Redirect to='/my-store' />;
        }
        return <CreateStoreFormView onCountryChange={this.handleCountryChange} {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    user: getUser(state, state.userData.user).entity,
    merchantUser: getUser(state, state.merchantData.user).entity,
    isLoadingAccounts: state.merchantData.isLoadingAccounts,
    countries: state.address.enabledCountries,
    states: state.address.states,
    getCountry: (id) => getCountry(state, id),
    getState: (id) => getState(state, id),
    getCity: (id) => getCity(state, id)
});

const mapDispatchToProps = (dispatch) => ({
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts),
    loadCountries: wrapAPIDispatch(dispatch, actions.loadCountries),
    loadStatesAndCities: wrapAPIDispatch(dispatch, actions.loadStatesAndCities),
    createMerchantAccount: wrapAPIDispatch(dispatch, actions.createMerchantAccount)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CreateStoreFormContainer);
