// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Button, Grid, Segment, Radio } from 'semantic-ui-react';
import { Trans, translate } from 'react-i18next';

type Props = {
    item: Object,
    selected: number,
    handleChange: Function,
    onOpenEditAddress: () => void
};

function AddressListItem({ item, selected, handleChange, onOpenEditAddress }: Props) {
    const { fullName, houseNo, street, city, state, country } = item;
    return (
        <Segment className='semi-simple'>
            <Grid className='auto tensed'>
                <Grid.Column
                    style={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden'
                    }}
                >
                    {/*<Radio*/}
                    {/*    name='deliveryAddress'*/}
                    {/*    value={item.id}*/}
                    {/*    checked={selected === item.id}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    label={<label style={{ display: 'inline' }}>{[fullName, houseNo, street, city, state, country].filter(Boolean).join(', ')}</label>}*/}
                    {/*/>*/}
                    <span style={{ display: 'inline' }}>{[fullName, houseNo, street, city, state, country].filter(Boolean).join(', ')}</span>
                </Grid.Column>
                <Grid.Column className='col-shrink'>
                    <Button
                        basic
                        compact
                        type='button'
                        style={{
                            display: 'block',
                            margin: '-0.410714281428571em -0.410714281428571em -0.410714281428571em 0'
                        }}
                        onClick={onOpenEditAddress}
                    >
                        <Trans i18nKey='deliveryAddress.edit.trigger'>Edit</Trans>
                    </Button>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

export default compose(withTranslation('translations'))(AddressListItem);
