// @flow
import * as ActionTypes from '../../actions';

type Action = {
    type: string,
    status: ?string,
    payload: {
        categoryId?: ?number
    }
};

function categoryId(state: ?string = null, action: Action) {
    if (action.type === ActionTypes.SET_FILTER && 'categoryId' in action.payload) {
        // eslint-disable-next-line no-shadow
        const { categoryId } = action.payload;
        return categoryId;
    }
    return state;
}

export default categoryId;
