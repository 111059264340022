import React, { useState, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { isMobileOnly } from 'react-device-detect';
import { withTranslation } from 'react-i18next';


const CategoriesDropdownView = ({ rootCategories, landingPageCategories, urlPrefix, t, navigateTo }) => {
    const [isMobileCategoriesMenuHidden, setIsMobileCategoriesMenuHidden] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const bazarName = urlParams.get('bazar');

    const handleShowCategoriesMenu = () => {
        if (isMobileOnly) {
            setIsMobileCategoriesMenuHidden(false);
        } else {
            setTimeout(() => dropdownRef.current.close(), 10);
            navigateTo('/categories');
        }
    };

    const getText = (fullSlug, fallback) => t(`subBar.categories.dropdown.${fullSlug}`, { defaultValue: fallback });

    const renderCategoryMenuItem = (category, depth) => {
        const { name, fullSlug, categorySlug } = category;
        const subcategories = category.subcategories ?? category.items ?? [];
        const hasChildren = subcategories.length > 0;

        const handleClick = (ev) => {
            navigateTo(`/categories/${categorySlug}${bazarName ? '?bazar=' + bazarName : ''}`);
            if (!hasChildren) {
                setIsMobileCategoriesMenuHidden(true);
            }
            ev.stopPropagation();
        };

        const baseProps = { key: fullSlug, onClick: handleClick };
        const basePropsMobile = {
            key: `${fullSlug}-mb-mobile`,
            onClick: handleClick
        };

        if (!hasChildren) {
            // Third-level menu options
            return (
                <Dropdown.Item {...baseProps}>
                    <span className='name '>{t(`categories.${categorySlug}`, { defaultValue: name })}</span>
                </Dropdown.Item>
            );
        }

        const children = subcategories.map((cat) => renderCategoryMenuItem(cat, depth + 1));

        if (depth === 1) {
            // Top-level menu options
            return (
                <Dropdown.Item {...baseProps}>
                    <i className='dropdown icon' />
                    <span className='name menu-item-text'>{t(`categories.${categorySlug}`, { defaultValue: name })}</span>
                    <Dropdown.Menu>{children}</Dropdown.Menu>
                </Dropdown.Item>
            );
        }

        // Second-level menu options
        return (
            <React.Fragment key={baseProps.key}>
                <Dropdown {...baseProps} open={false} item simple text={t(`categories.${categorySlug}`, { defaultValue: name })} className='mb-mobile hidden'>
                    <Dropdown.Menu>{children}</Dropdown.Menu>
                </Dropdown>
                <Dropdown.Item {...basePropsMobile} className='mb-mobile only'>
                    <Dropdown open={false} item simple text={getText(fullSlug, name)} className='top right'>
                        <Dropdown.Menu>{children}</Dropdown.Menu>
                    </Dropdown>
                </Dropdown.Item>
            </React.Fragment>
        );
    };

    return (
        <Dropdown
            open={isDropdownOpen}
            ref={dropdownRef}
            icon={null}
            item
            simple
            text={t('subBar.main.categories', { defaultValue: name })}
            className={`categories-dropdown ${isMobileCategoriesMenuHidden && isMobileOnly ? 'categories-dropdown-hidden ' : ''}`}
            onClick={handleShowCategoriesMenu}
            onMouseEnter={() => setIsDropdownOpen(true)}
            onMouseLeave={() => setIsDropdownOpen(false)}
        >
            <Dropdown.Menu>{(landingPageCategories ?? rootCategories.filter((c) => c.isGlobal)).map((cat) => renderCategoryMenuItem(cat, 1))}</Dropdown.Menu>
        </Dropdown>
    )

}

export default withTranslation('translations')(CategoriesDropdownView);
