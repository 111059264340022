// @flow
import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';
import type { PaymentStatusType } from 'store/enums';

export type UpdatePaymentStatusParams = {
    cartId: number,
    paymentStatus: PaymentStatusType,
    transactionId: string,
    channel?: $Values<typeof DeviceTypes>
};

export const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';
export const updatePaymentStatus = wrapAPIActionCreator(({ cartId, paymentStatus, transactionId, channel = DeviceTypes.WEB }: UpdatePaymentStatusParams) => ({
    [API_DATA]: {
        type: UPDATE_PAYMENT_STATUS,
        endpoint: '/v3/cart_items/update_payment_status',
        endpointParams: {
            orderFrom: channel
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {
            cartId,
            paymentStatus,
            transactionId
        },
        schema: Schemas.cart
    }
}));

export default updatePaymentStatus;
