import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export const ORDER_MONRI = 'ORDER_MONRI';
export const orderMonri = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: ORDER_MONRI,
        endpoint: '/v4/cart_items/place_order_monri',
        endpointParams: {
            orderFrom: DeviceTypes.WEB
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {},
        schema: {
            cart: Schemas.cart
        }
    }
}));

export default orderMonri;
