// @flow
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Form, Grid, Message, Icon, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import ItemFormVariants from 'scenes/EditItem/components/EditItemForm/components/ItemFormVariants';
import ItemFormDelivery from 'scenes/EditItem/components/EditItemForm/components/ItemFormDelivery';
// import SellItemFormDelivery from './components/SellItemFormDelivery';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

import { shippingToListDefaults } from 'scenes/EditItem/components/EditItemForm/components/ItemFormDelivery/components/ShippingTo';
import SellItemFormPrice from './components/SellItemFormPrice';
import SellItemFormItem from './components/SellItemFormItem';

type Props = {
    t: (string) => string
};

type State = {
    isPosting: boolean,
    errorMessage: ?string,
    formValues: {
        publishingDate: FormFieldData<string>
    }
};

class SellItemFormView extends CommonForm<Props, State> {
    state = {
        formValues: {
            publishingDate: createEmptyFormFieldData('')
        }
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        const validates = this.validate();
        if (!validates) {
            return;
        }
        const { getFormValues, getCategoryAttribute } = this.props;
        const itemSection = getFormValues('sellItem.form.create.item');
        const imagesSection = getFormValues('sellItem.form.create.item.images');
        const variantsSection = getFormValues('sellItem.form.create.variants');
        const priceSection = getFormValues('sellItem.form.create.price');
        const deliverySection = getFormValues('sellItem.form.create.delivery');
        const shippingToSection = getFormValues('sellItem.form.create.delivery.shippingTo');

        if (!itemSection || !imagesSection || !variantsSection || !priceSection || !deliverySection || !shippingToSection) {
            console.warn('Forms not connected properly to Redux store');
            return;
        }
        const {
            title: { value: name },
            category: { value: categoryParent },
            subcategory: { value: category },
            subSubcategory: { value: categoryChild },
            description: { value: description },
        } = itemSection.formValues;

        const { pictures } = imagesSection.formValues;

        const variantForms = variantsSection.formValues.variants;


        const variants = variantForms
            .filter((form) => !form.deleted)
            .map((form) => {
                const variantSection = getFormValues(form.formId);
                const {
                    sku: { value: sku },
                    quantity: { value: quantity },
                    id: { value: variantId },
                    ...attributeValues
                } = variantSection.formValues;
                const variant = {
                    sku,
                    quantity
                };
                if (variantId && variantId !== '') {
                    variant.id = variantId;
                }
                const variantBody = {};
                Object.values(attributeValues).forEach(({ value, id }) => {
                    const attribute = getCategoryAttribute(id);
                    variantBody[attribute.name] = value;
                });
                variant.variantBody = variantBody;
                return variant;
            });

        const {
            price: { value: price },
            applyVAT: { value: applyVAT },
            dealType: { value: dealType },
            salePrice: { value: salePrice },
            tags: { value: tags }
        } = priceSection.formValues;

        const isDeal = dealType !== 'none';
        const isFixedPrice = false;

        const dealTypes = {
            buy_3_for_2: false,
            sale: dealType === 'sale' ? parseFloat(salePrice) : 0,
            saleGross: dealType === 'sale' ? parseFloat(salePrice) : 0,
            sample: false
        };

        const {
            weight: { value: weight },
            weightType: { value: weightType },
            shippingFrom: { value: shippingFrom }
        } = deliverySection.formValues;

        const shippingToOptions = shippingToSection.formValues;

        const shippingDetails = [];

        Object.keys(shippingToOptions).forEach((id) => {
            const field = shippingToOptions[id];
            if (field.value === true) {
                const optionValues = getFormValues(`sellItem.form.create.delivery.shippingTo.${id}.details`).formValues;
                const {
                    returnPolicy: { value: returnPolicy },
                    merchantWillCoverCost: { value: merchantWillCoverCost },
                    minimumDays: { value: minimumDays },
                    maximumDays: { value: maximumDays },
                    estimatedDeliveryCost: { value: estimatedDeliveryCost }
                } = optionValues;

                const shippingDefaults = shippingToListDefaults.find((item) => item.id === id);

                const optionDetails = {
                    delivery: shippingDefaults.delivery,
                    estimatedDeliveryCost,
                    estimatedDeliveryTimeEnd: Number(maximumDays),
                    estimatedDeliveryTimeStart: Number(minimumDays),
                    isMerchantPay: merchantWillCoverCost,
                    itemShippingDetailKey: shippingDefaults.key,
                    payment: shippingDefaults.payment,
                    returnPolicyId: returnPolicy,
                    shipFromId: shippingFrom,
                    shipToId: shippingDefaults.shipToId,
                    shipment: shippingDefaults.shipment,
                    title: shippingDefaults.title
                };

                shippingDetails.push(optionDetails);
            }
        });

        const params = {
            name,
            categoryParent,
            category,
            categoryChild,
            pictures,
            variants,
            dealTypes,
            description,
            tags,
            isDeal,
            isFixedPrice,
            price: parseFloat(price),
            gst: applyVAT,
            shippingDetails,
            storeId: shippingFrom,
            weight,
            weightType
        };

        const { loadItems } = this.props;

        if (onSubmit) {
            this.setState({ isPosting: true });
            onSubmit(params)
                .then((result) => {
                    loadItems({ limit: 10, offset: 0 });
                    const { id } = result.result.item;
                    this.setState({ isPosting: false, redirectTo: `/item/${id}` });
                })
                .catch((err) => this.setState({ isPosting: false, errorMessage: err.message }));
        }
    };

    render() {
        const { errorMessage, redirectTo, isPosting } = this.state;
        const { updateFormValues } = this.props;

        if (redirectTo) {
            return <Redirect to={redirectTo} />;
        }
        return (
            <Form onSubmit={this.handleSubmit} error={errorMessage != null}>
                <div style={{ height: '36px', display: 'flex', marginBottom: '35px', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex' }}>
                        <Link to={'/my-store'}>
                            <button className='ui compact icon button'>
                                <Icon name='arrow left' />
                            </button>
                        </Link>
                        <p style={{ marginLeft: '20px', marginTop: '8px' }}><Trans i18nKey='sellItemScene.status'>Sell Item</Trans></p>
                    </div>
                    <div style={{ display: 'flex' }}>
                        {/*<Button>Preview</Button>*/}
                        {/*<Button style={{ marginLeft: '10px' }}>Save Draft</Button>*/}
                        <Button type='submit' loading={isPosting} disabled={this.props.stores.length === 0} style={{ backgroundColor: '#000', color: '#fff', marginLeft: '10px' }}>
                            {' '}
                            <Trans i18nKey='sellItemScene.setAsActive'>Set as active</Trans>{' '}
                        </Button>
                    </div>
                </div>
                <Message error>{errorMessage === 'imageError' ? <Trans i18nKey='sellItem.form.errors.image'>Image field is required.</Trans> : errorMessage}</Message>
                {/*<Grid>*/}
                {/*    <Grid.Column>*/}
                {/*        <Grid stackable>*/}
                {/*            <Grid.Row>*/}
                {/*                <Grid.Column className='col-shrink'>*/}
                {/*                    <Grid>*/}
                {/*                        <Grid.Row />*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column width={8}>*/}
                {/*                                <Header as='h2'>*/}
                {/*                                    <Trans i18nKey='addMultipleItems.header'>Add Multiple Items at Once</Trans>*/}
                {/*                                </Header>*/}
                {/*                                <Button fluid icon labelPosition='left' as={Link} to='/bulk-import'>*/}
                {/*                                    <Icon name='file image outline' />*/}
                {/*                                    <Trans i18nKey='myStore.actions.bulkImport'>Bulk Import</Trans>*/}
                {/*                                </Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                </Grid.Column>*/}
                {/*            </Grid.Row>*/}
                {/*        </Grid>*/}
                {/*    </Grid.Column>*/}
                {/*</Grid>*/}
                <Grid>
                    <Grid.Column>
                        <Grid stackable>
                            <Grid.Row />
                            <Grid.Row className='three column row'>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row>
                                            <Grid.Column>
                                                {/*title,media,categori, subcat, escriptio i tags*/}
                                                {/*forma 1*/}
                                                <SellItemFormItem
                                                    formId='sellItem.form.create.item'
                                                    translationId='sellItem.form.item'
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        {/*product details sku quantity*/}
                                        <Grid.Row>
                                            <Grid.Column>
                                                <SellItemFormPrice
                                                    formId='sellItem.form.create.price'
                                                    translationId='sellItem.form.price'
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column>
                                    <Grid>
                                        <Grid.Row>
                                            {/*prajs, vat, full price*/}
                                            <Grid.Column>
                                                <ItemFormVariants
                                                    formId='sellItem.form.create.variants'
                                                    translationId='sellItem.form.variant'
                                                    updateFormValues={updateFormValues}
                                                    registerChildFormValidator={this.onRegisterChildFormValidator}
                                                    unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                                />
                                                {/*<SellItemFormPrice*/}
                                                {/*    formId='sellItem.form.create.price'*/}
                                                {/*    translationId='sellItem.form.price'*/}
                                                {/*    registerChildFormValidator={this.onRegisterChildFormValidator}*/}
                                                {/*    unregisterChildFormValidator={this.onUnregisterChildFormValidator}*/}
                                                {/*/>*/}
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column>
                                    <ItemFormDelivery
                                        formId='sellItem.form.create.delivery'
                                        translationId='sellItem.form.delivery'
                                        registerChildFormValidator={this.onRegisterChildFormValidator}
                                        unregisterChildFormValidator={this.onUnregisterChildFormValidator}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ justifyContent: 'center' }}>
                                <Grid.Column width={8}>
                                    {/* <Form.Field>
                    <Popup
                      position="top center"
                      trigger={
                        <Button fluid primary basic type="button" onClick={() => this.setState({ showScheduleField: !this.state.showScheduleField })}>
                          <Trans i18nKey="sellItem.form.schedule">Schedule to Publish Item</Trans>
                        </Button>
                      }
                    >
                      <Popup.Content>
                        <Trans i18nKey="sellItem.form.scheduleInformation">Schedule to publish item at a later date.</Trans>
                      </Popup.Content>
                    </Popup>
                  </Form.Field> */}
                                    {this.state.showScheduleField && (
                                        <Form.Field>
                                            <Form.Input />
                                        </Form.Field>
                                    )}
                                    <Form.Field>
                                        {/*<Button type='submit' fluid primary loading={isPosting}>*/}
                                        {/*    <Trans i18nKey='sellItem.form.submit'>Publish Item</Trans>*/}
                                        {/*</Button>*/}
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default withTranslation('translations')(SellItemFormView);
