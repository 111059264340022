// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Loader } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import type { EntityData, LandingComponent } from 'store/types';

import TeaserCarousel from 'components/TeaserCarousel';
import { getCategory } from '../../../../store/utilities';

type Props = {
    component: EntityData<LandingComponent>,
    title: string
};

function LandingCategoryCarouselView({ component, title }: Props) {
    const state = useSelector((state) => state);
    const rootCategories = useSelector((state) => state.rootCategories);
    const categories = rootCategories.map((categoryId) => getCategory(state, categoryId).entity);
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }
    const { entities } = component.entity;
    return <TeaserCarousel showViewAll={false} header={title} link='/' type='category' content={entities.map(({ id }) => ({ key: id, categoryId: id }))} />;
    /*
    return categories.map((category) => {
        return (
            <TeaserCarousel
                key={category.id}
                showViewAll={false}
                header={category.name}
                link='/'
                type='category'
                content={category.subcategories.map((id) => ({ key: id, categoryId: id }))}
            />
        );
    });

     */
}

export default LandingCategoryCarouselView;
