// @flow
import React, { Component } from 'react';
import { Button, Form, Icon, Label, Message, Input } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
class SignUpForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            hasTriggered: false,
            errorMessage: null,
            name: {
                value: '',
                error: false
            },
            username: {
                value: '',
                error: false
            },
            email: {
                value: '',
                error: false
            },
            phone: {
                value: '',
                error: false
            },
            password: {
                value: '',
                error: false
            },
            passwordShown: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.facebookInformation !== this.props.facebookInformation) {
            const { name, email } = nextProps.facebookInformation;
            this.setState({
                name: {
                    value: name,
                    error: false
                },
                email: {
                    value: email,
                    error: false
                }
            });
        }
        if (nextProps.isLoggedIn && !this.state.hasTriggered) {
            this.setState({ hasTriggered: true }, () => {
                this.props.onSuccess();
            });
        }
    }

    handleChange = (event) => {
        const target = this.state[event.target.name];
        target.value = event.target.value;
        this.setState({ [event.target.name]: target });
    };

    handleBlur = (event) => {
        this.validate(event.target.name).catch(() => {});
    };

    togglePassword = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    handleSubmit = (event) => {
        const { registerUser } = this.props;
        const {
            name: { value: alias },
            username: { value: username },
            email: { value: email },
            phone: { value: phoneNumber },
            password: { value: password }
        } = this.state;

        event.preventDefault();
        this.setState({ loading: true });

        this.validate('name', 'username', 'email', 'phone', 'password')
            .then(() =>
                registerUser({
                    email,
                    username,
                    phoneNumber,
                    password,
                    alias,
                    // TODO: support for merchant registration
                    userType: 'shopper'
                })
            )
            .then(() => this.setState({ loading: false }))
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    validate = (...inputs) => {
        let valid = true;
        inputs.forEach((input) => {
            const target = this.state[input];
            target.error = target.value.length === 0;
            if (target.error) {
                valid = false;
            }
            this.setState({ [input]: target });
        });
        return valid ? Promise.resolve() : Promise.reject(new Error('Some fields were invalid'));
    };
    handleErrorMessage(message: string) {
        switch (message) {
            case 'EmailAlreadyExist':
                return 'Email adresa se već koristi za drugi račun.';
            case 'UsernameAlreadyExist':
                return 'Korisničko ime je zauzeto.';
            case 'password value is out of range, must be between [6, 50]':
                return 'Šifra mora sadržavati između 6 i 50 karaktera.';
            case 'Some fields were invalid':
                return 'Neka polja su neispravno popunjena.';
            default:
                return message;
        }
    }

    render() {
        const { errorMessage } = this.state;
        return (
            <Form onSubmit={this.handleSubmit} error={errorMessage !== null}>
                <Message error>{this.handleErrorMessage(errorMessage)}</Message>
                <Form.Field required error={this.state.name.error}>
                    <label htmlFor='sign-up-name'>
                        <Trans i18nKey='signUpForm.name.label'>Name</Trans>
                    </label>
                    <input
                        type='text'
                        name='name'
                        id='sign-up-name'
                        value={this.state.name.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('signUpForm.name.placeholder', { defaultValue: '' })}
                        disabled={this.state.loading}
                    />
                    {this.state.name.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='signUpForm.name.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field required error={this.state.username.error}>
                    <label htmlFor='sign-up-username'>
                        <Trans i18nKey='signUpForm.username.label'>Username</Trans>
                    </label>
                    <input
                        type='text'
                        name='username'
                        id='sign-up-username'
                        value={this.state.username.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('signUpForm.username.placeholder', { defaultValue: '' })}
                        disabled={this.state.loading}
                    />
                    {this.state.username.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='signUpForm.username.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field required error={this.state.email.error}>
                    <label htmlFor='sign-up-email'>
                        <Trans i18nKey='signUpForm.email.label'>Email</Trans>
                    </label>
                    <input
                        type='email'
                        name='email'
                        id='sign-up-email'
                        value={this.state.email.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('signUpForm.email.placeholder', { defaultValue: '' })}
                        disabled={this.state.loading}
                    />
                    {this.state.email.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='signUpForm.email.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field required error={this.state.phone.error}>
                    <label htmlFor='sign-up-phone'>
                        <Trans i18nKey='signUpForm.phone.label'>Phone</Trans>
                    </label>
                    <input
                        type='text'
                        name='phone'
                        id='sign-up-phone'
                        value={this.state.phone.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('signUpForm.phone.placeholder', { defaultValue: '' })}
                        disabled={this.state.loading}
                    />
                    {this.state.phone.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='signUpForm.phone.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field required error={this.state.password.error}>
                    <label htmlFor='sign-up-password'>
                        <Trans i18nKey='signUpForm.password.label'>Password</Trans>
                    </label>
                    <Input
                        type={this.state.passwordShown ? 'text' : 'password'}
                        name='password'
                        id='sign-up-password'
                        value={this.state.password.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('signUpForm.password.placeholder', { defaultValue: '' })}
                        disabled={this.state.loading}
                        icon={() => <Icon onClick={() => this.togglePassword()} style={{ cursor: 'pointer', pointerEvents: 'unset' }} name='eye' />}

                    />
                    {this.state.password.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='signUpForm.password.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field>
                    <Button className='standard-color-button' type='submit' fluid primary loading={this.state.loading} disabled={this.state.loading}>
                        <Trans i18nKey='signUpForm.submit'>Sign Up</Trans>
                    </Button>
                </Form.Field>
            </Form>
        );
    }
}

export default withTranslation('translations')(SignUpForm);
