// @flow
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import address from './address';
import entities from './entities';
import filters from './filters';
import mapSlug from './mapSlug';
import rootCategories from './rootCategories';
import categoriesByCollection from './categoriesByCollection';
import landingPages from './landingPages';
import ui from './ui';
import userData from './userData';
import signup from './signup';
import merchantData from './merchantData';
import categoryAttributes from './categoryAttributes';
import searchResults from './searchResults';
import messages from './messages';
import merchants from './merchants';
import follow from './follow';
import likes from './likes';
import landingPageCategories from './landingPageCategories';

export default combineReducers({
    address,
    categoryAttributes,
    entities,
    filters,
    mapSlug,
    userData,
    merchantData,
    rootCategories,
    categoriesByCollection,
    landingPages,
    signup,
    ui,
    searchResults,
    messages,
    merchants,
    follow,
    likes,
    landingPageCategories,
    router: connectRouter(createBrowserHistory())
});
