// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_ACCOUNTS ------------------------------------
export type LoadMyAccountsParams = {
    fields?: 'cover_photos'[]
};

export const LOAD_MY_ACCOUNTS = 'LOAD_MY_ACCOUNTS';
export const loadMyAccounts = wrapAPIActionCreator(({ fields = ['cover_photos'] }: LoadMyAccountsParams = {}) => ({
    [API_DATA]: {
        type: LOAD_MY_ACCOUNTS,
        endpoint: '/v3/me/accounts',
        endpointParams: { fields: fields.join(',') },
        authentication: Authentication.User,
        schema: {
            accounts: [{ user: Schemas.user }]
        },
        preprocess: ({ accounts }) => ({
            accounts: accounts.map(({ accessToken, ...rest }) => ({ user: rest, accessToken }))
        })
    }
}));

export default loadMyAccounts;
