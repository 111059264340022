// @flow
import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export type CheckPaymentStatusParams = {
    cartId: number,
    channel?: $Values<typeof DeviceTypes>
};

export const CHECK_PAYMENT_STATUS = 'CHECK_PAYMENT_STATUS';
export const checkPaymentStatus = wrapAPIActionCreator(({ cartId, channel = DeviceTypes.WEB }: CheckPaymentStatusParams) => ({
    [API_DATA]: {
        type: CHECK_PAYMENT_STATUS,
        endpoint: '/v4/cart_items/check_payment_status',
        endpointParams: {
            cartId
        },
        method: 'GET',
        authentication: Authentication.User,
        schema: {
            cartItems: [Schemas.cartItem],
            addresses: [Schemas.userDeliveryAddress]
        }
    }
}));

export default checkPaymentStatus;
