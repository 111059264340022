// @flow
import React, { Component } from 'react';
import { Button, Grid } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import AddressListItem from './components/AddressList/components/AddressListItem';
import AddressList from './components/AddressList';

type Props = {
    addresses: number[],
    selectedAddress: number,
    getUserStoreAddress: (number) => Object,
    setCartAddress: (number) => void,
    onOpenAddAddress: () => void,
    onOpenEditAddress: () => void
};

class StoreAddressView extends Component<Props> {
    handleChange = (event, radio) => {
        // const { value } = radio;
        // const { setCartAddress } = this.props;
        // setCartAddress({ addressId: value });
    };

    render() {
        const { onOpenAddAddress, syncOnly, initialAddress, onOpenEditAddress, addresses = [], getUserStoreAddress, selectedAddress } = this.props;

        const addressesContent = (
            <AddressList
                list={syncOnly && initialAddress ? [initialAddress] : addresses}
                getUserStoreAddress={getUserStoreAddress}
                selected={selectedAddress}
                handleChange={this.handleChange}
                onOpenEditAddress={onOpenEditAddress}
            />
        );

        return (
            <div>
                <Grid className='tensed'>
                    <Grid.Row>
                        <Grid.Column>{addressesContent}</Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button style={{ display: syncOnly && initialAddress ? 'none' : 'block' }} type='button' fluid onClick={onOpenAddAddress}>
                                <Trans i18nKey='StoreAddress.add.trigger'>Add Store Address</Trans>
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

export default withTranslation('translations')(StoreAddressView);
