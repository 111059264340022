import React from 'react';
import { withTranslation } from 'react-i18next';

import SellItemForm from './components/SellItemForm';

function SellItem() {
    return (
        <div className='sell-item'>
            <SellItemForm />
        </div>
    );
}

export default withTranslation('translations')(SellItem);
