// @flow
import React from 'react';
import {} from 'react-i18next';
import { Grid, Segment, Radio, Button, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    item: Object
};

function BankAccountListItem({ item, selected, onOpenEditBankAccount, onSelectBankAccount }: Props) {
    return (
        // <Segment className="semi-simple">
        //   <Grid className="auto tensed">
        //     <Grid.Column
        //       style={{
        //         textOverflow: 'ellipsis',
        //         whiteSpace: 'nowrap',
        //         overflow: 'hidden',
        //       }}
        //     >
        //       <Radio
        //         name="deliveryBankAccount"
        //         value={item.id}
        //         checked={selected}
        //         onChange={(event, { checked }) => {
        //           if (checked) {
        //             onSelectBankAccount(item);
        //           }
        //         }}
        //         label={
        //           <label style={{ display: 'inline' }}>
        //             {item.bankName}
        //           </label>
        //         }
        //       />
        //     </Grid.Column>
        //     <Grid.Column className="col-shrink">
        //       <Button
        //         type="button"
        //         basic
        //         compact
        //         style={{
        //           display: 'block',
        //           margin: '-0.410714281428571em -0.410714281428571em -0.410714281428571em 0',
        //         }}
        //         onClick={() => onOpenEditBankAccount(item)}
        //       >
        //         <Trans i18nKey="myStore.bankAccount.edit.trigger">Edit</Trans>
        //       </Button>
        //       {/* <BankAccountEdit variant={item} /> */}
        //     </Grid.Column>
        //   </Grid>
        // </Segment>

        <Grid>
            <Grid.Row>
                <Grid.Column computer={12} tablet={16}>
                    <Header as='h4'>Payout Bank Account</Header>
                    <Grid.Column computer={12}>
                        <p className='address-form-added-address'>{item.bankName}</p>
                    </Grid.Column>
                    <Grid.Column computer={12}>
                        <p className='address-form-added-address'>{item.bankAddress}</p>
                    </Grid.Column>
                    <Grid.Column computer={12}>
                        <p className='address-form-added-address'>{item.postCode}</p>
                    </Grid.Column>
                    <Grid.Column computer={12}>
                        <p className='address-form-added-address'>{item.city.name}</p>
                    </Grid.Column>
                    <Grid.Column computer={12}>
                        <p className='address-form-added-address'>{item.country.name}</p>
                    </Grid.Column>
                </Grid.Column>
                <Grid.Column computer={4} tablet={16} className='address-form-btns-wrap'>
                    <Button floated='right' className='address-form-edit-btn' secondary disabled={!onOpenEditBankAccount} onClick={() => onOpenEditBankAccount(item)}>
                        Edit
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default withTranslation('translations')(BankAccountListItem);
