import React from 'react';
import { withTranslation } from 'react-i18next';

import BulkImportForm from './components/BulkImportForm';

function BulkImport() {
    return (
        <div className='sell-item'>
            <BulkImportForm />
        </div>
    );
}

export default withTranslation('translations')(BulkImport);
