// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';
import { getItem } from 'store/utilities';
import SearchView from './SearchView';

const PAGINATION_LIMIT_INITIAL = 32;
const PAGINATION_LIMIT_INITIAL_USERS = 48;
const PAGINATION_LIMIT_STEP = 12;

type Props = {
    match: {
        isExact: boolean,
        params: {
            value: string
        },
        path: string,
        url: string
    },
    searchItemsByKeyword: ({ keyword: string, sort: string }) => void,
    searchByKeyword: ({ keyword: string }) => void,
    searchTagsByKeyword: ({ keyword: string, sort: string }) => void,
    searchStoresByKeyword: ({ query: string, sort: string }) => void,
    searchUsersByKeyword: ({ query: string, sort: string }) => void,
    handleTabChange: (number) => void,
    itemsByKeyword: [object],
    tagsByKeyword: [object],
    itemsByKeywordIsLoading: boolean,
    storesByKeywordIsLoading: boolean,
    searchByKeywordResults: {
        itemCount: number,
        items: [object],
        searchByKeywordIsLoading: boolean,
        tagItemCount: number,
        userCount: number
    },
    currentSortWord: string,
    storesByKeyword: [object],
    usersByKeyword: [object],
    hasMoreUsersByKeyword: boolean,
    hasMoreItemsByKeyword: boolean,
    hasMoreTagsByKeyword: boolean,
    hasMoreStoresByKeyword: boolean
};

type State = {
    searchValue: string,
    searchTab: string,
    searchResults: *,
    isRequestError: boolean
};

class SearchContainer extends Component<Props, State> {
    state = {
        searchValue: this.props.match.params.value || '',
        searchTab: this.props.match.params.tab || '',
        searchResults: {},
        isRequestError: false
    };

    componentDidMount() {
        const { searchValue } = this.state;
        this.searchKeyword(searchValue);
    }
    // UNSAFE_componentWillMount() {
    //   const { searchValue } = this.state;
    //   this.searchKeyword(searchValue);
    // }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { searchByKeywordResults, navigateTo, match } = nextProps;
        const { searchResults, searchValue, searchTab } = this.state;
        const { value = '', tab } = match.params;

        if (searchResults !== searchByKeywordResults && !searchByKeywordResults.searchByKeywordIsLoading) {
            this.setState({
                searchResults: searchByKeywordResults
            });
            if (tab == null) {
                const tabs = [
                    { name: 'items', count: searchByKeywordResults.itemCount },
                    { name: 'stores', count: searchByKeywordResults.storeCount },
                    { name: 'users', count: searchByKeywordResults.userCount },
                    { name: 'tags', count: searchByKeywordResults.tagItemCount }
                ];
                const tabToFocus = tabs.filter(({ count }) => count > 0)[0] || { name: 'items' };
                navigateTo(`/search/${tabToFocus.name}/${encodeURIComponent(value)}`);
            }
        }

        if (value !== searchValue) {
            this.searchKeyword(value, this.props.currentSortWord);
            this.setState({
                searchValue: value,
                searchTab: tab
            });
        } else if (tab !== searchTab) {
            this.searchSingleTab(value, this.props.currentSortWord, tab);
            this.setState({
                searchValue: value,
                searchTab: tab
            });
        }
    }

    handleError = (err) => {
        this.setState({ isRequestError: true });
        console.error(err);
    };

    searchKeyword(searchword = '', sort = 'latest') {
        const { searchItemsByKeyword, searchByKeyword, searchTagsByKeyword, searchStoresByKeyword, searchUsersByKeyword } = this.props;

        searchItemsByKeyword({
            keyword: searchword,
            limit: PAGINATION_LIMIT_INITIAL,
            sort
        }).catch(this.handleError);

        searchByKeyword({ keyword: searchword }).catch(this.handleError);

        searchTagsByKeyword({
            keyword: searchword,
            limit: PAGINATION_LIMIT_INITIAL,
            sort
        }).catch(this.handleError);

        searchStoresByKeyword({
            query: searchword,
            limit: PAGINATION_LIMIT_INITIAL_USERS,
            sort
        }).catch(this.handleError);

        searchUsersByKeyword({
            query: searchword,
            limit: PAGINATION_LIMIT_INITIAL_USERS,
            sort
        }).catch(this.handleError);
    }

    searchSingleTab(query = '', sort = 'latest', tab = 'items') {
        const { searchItemsByKeyword, searchTagsByKeyword, searchStoresByKeyword, searchUsersByKeyword } = this.props;

        const searchFuncMap = {
            items: searchItemsByKeyword,
            tags: searchTagsByKeyword,
            stores: searchStoresByKeyword,
            users: searchUsersByKeyword
        };

        searchFuncMap[tab]({
            query,
            keyword: query,
            sort
        }).catch(this.handleError);
    }

    loadMoreItems(sortWord, calculations, manualLoad) {
        const { searchItemsByKeyword, itemsByKeyword, itemsByKeywordIsLoading, searchByKeywordResults, hasMoreItemsByKeyword } = this.props;
        if (
            !itemsByKeywordIsLoading &&
            itemsByKeyword.length !== searchByKeywordResults.itemCount &&
            ((calculations && calculations.bottomVisible) || manualLoad) &&
            hasMoreItemsByKeyword
        ) {
            const offset = itemsByKeyword.length;
            const limit = offset === 0 ? PAGINATION_LIMIT_INITIAL : PAGINATION_LIMIT_STEP;
            searchItemsByKeyword({
                keyword: this.state.searchValue,
                offset,
                limit,
                sort: sortWord
            }).catch(this.handleError);
        }
    }

    loadMoreItemsByTags(sortWord, calculations, manualLoad) {
        const { searchTagsByKeyword, tagsByKeyword, itemsByKeywordIsLoading, searchByKeywordResults, hasMoreTagsByKeyword } = this.props;
        if (!itemsByKeywordIsLoading && tagsByKeyword.length !== searchByKeywordResults.tagItemCount && ((calculations && calculations.bottomVisible) || manualLoad) && hasMoreTagsByKeyword) {
            const offset = tagsByKeyword.length;
            const limit = offset === 0 ? PAGINATION_LIMIT_INITIAL : PAGINATION_LIMIT_STEP;
            searchTagsByKeyword({
                keyword: this.state.searchValue,
                offset,
                limit,
                sort: sortWord
            }).catch(this.handleError);
        }
    }

    loadMoreStores(sortWord, calculations, manualLoad) {
        const { searchStoresByKeyword, storesByKeyword, searchByKeywordResults, storesByKeywordIsLoading, hasMoreStoresByKeyword } = this.props;
        if (!storesByKeywordIsLoading && storesByKeyword.length !== searchByKeywordResults.storeCount && ((calculations && calculations.bottomVisible) || manualLoad) && hasMoreStoresByKeyword) {
            const offset = storesByKeyword.length;
            const limit = offset === 0 ? PAGINATION_LIMIT_INITIAL : PAGINATION_LIMIT_STEP;
            searchStoresByKeyword({
                query: this.state.searchValue,
                offset,
                limit
            }).catch(this.handleError);
        }
    }

    loadMoreUsers(sortWord, calculations, manualLoad) {
        const { searchUsersByKeyword, usersByKeyword, searchByKeywordResults, storesByKeywordIsLoading, hasMoreUsersByKeyword } = this.props;
        if (
            !storesByKeywordIsLoading &&
            usersByKeyword.length !== searchByKeywordResults.storeCount &&
            ((calculations && calculations.bottomVisible) || manualLoad) &&
            hasMoreUsersByKeyword
        ) {
            const offset = usersByKeyword.length;
            const limit = offset === 0 ? PAGINATION_LIMIT_INITIAL : PAGINATION_LIMIT_STEP;
            searchUsersByKeyword({
                query: this.state.searchValue,
                offset,
                limit
            }).catch(this.handleError);
        }
    }

    sortItems(sort) {
        const { searchItemsByKeyword, searchTagsByKeyword } = this.props;
        searchItemsByKeyword({
            keyword: this.state.searchValue,
            offset: 0,
            limit: PAGINATION_LIMIT_INITIAL,
            sort
        }).catch(this.handleError);

        searchTagsByKeyword({
            keyword: this.state.searchValue,
            offset: 0,
            limit: PAGINATION_LIMIT_INITIAL,
            sort
        }).catch(this.handleError);
    }

    render() {
        const { isRequestError } = this.state;
        return (
            <SearchView
                {...this.props}
                loadMoreItems={(sortWord, calculations, isManualLoad) => this.loadMoreItems(sortWord, calculations, isManualLoad)}
                loadMoreItemsByTags={(sortWord, calculations, isManualLoad) => this.loadMoreItemsByTags(sortWord, calculations, isManualLoad)}
                loadMoreStores={(sortWord, calculations, isManualLoad) => this.loadMoreStores(sortWord, calculations, isManualLoad)}
                loadMoreUsers={(sortWord, calculations, isManualLoad) => this.loadMoreUsers(sortWord, calculations, isManualLoad)}
                sortItems={(sort) => this.sortItems(sort)}
                handleUpdate={this.handleUpdate}
                {...{ isRequestError }}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const keyword = ownProps.match.params.value || '';
    let itemsByKeyword = [];
    let tagsByKeyword = [];
    let storesByKeyword = [];
    let usersByKeyword = [];
    if (state.searchResults.itemsByKeyword[keyword]) {
        itemsByKeyword = state.searchResults.itemsByKeyword[keyword].existingItems;
    }
    if (state.searchResults.tagsByKeyword[keyword]) {
        tagsByKeyword = state.searchResults.tagsByKeyword[keyword].existingItems;
    }
    if (state.searchResults.storesByKeyword[keyword]) {
        storesByKeyword = state.searchResults.storesByKeyword[keyword].existingItems;
    }
    if (state.searchResults.usersByKeyword[keyword]) {
        usersByKeyword = state.searchResults.usersByKeyword[keyword].existingItems;
    }
    return {
        currentTab: state.searchResults.currentTabState.currentTab,
        itemsByKeyword: itemsByKeyword.map((id) => getItem(state, id)),
        tagsByKeyword: tagsByKeyword.map((id) => getItem(state, id)),
        storesByKeyword,
        usersByKeyword,
        searchByKeywordResults: state.searchResults.searchByKeyword,
        currentSortWord: state.searchResults.currentTabState.currentSortWord,
        itemsByKeywordIsLoading: state.searchResults.itemsByKeyword.itemsByKeywordIsLoading,
        storesByKeywordIsLoading: state.searchResults.storesByKeyword.storesByKeywordIsLoading,
        tagsByKeywordIsLoading: state.searchResults.tagsByKeyword.tagsByKeywordIsLoading,
        usersByKeywordIsLoading: state.searchResults.usersByKeyword.usersByKeywordIsLoading,
        hasMoreUsersByKeyword: state.searchResults.usersByKeyword.hasMoreUsers,
        hasMoreItemsByKeyword: state.searchResults.itemsByKeyword.hasMoreItems,
        hasMoreTagsByKeyword: state.searchResults.tagsByKeyword.hasMoreItemsByTags,
        hasMoreStoresByKeyword: state.searchResults.storesByKeyword.hasMoreStores
    };
};

const mapDispatchToProps = (dispatch) => ({
    searchItemsByKeyword: wrapAPIDispatch(dispatch, actions.searchItemsByKeyword),
    searchByKeyword: wrapAPIDispatch(dispatch, actions.searchByKeyword),
    searchTagsByKeyword: wrapAPIDispatch(dispatch, actions.searchTagsByKeyword),
    searchStoresByKeyword: wrapAPIDispatch(dispatch, actions.searchStoresByKeyword),
    searchUsersByKeyword: wrapAPIDispatch(dispatch, actions.searchUsersByKeyword),
    navigateTo: (...args) => dispatch(push(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
