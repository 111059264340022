// @flow
import { navigatePost } from '../../utilities';

// Environment variables for iPay88
export const PAYMENT_URL = process.env.REACT_APP_IPAY88_PAYMENT_URL || '';
if (PAYMENT_URL === '') {
    throw new Error('Must define REACT_APP_IPAY88_PAYMENT_URL');
}

const API_SERVER = (process.env.REACT_APP_MYBAZAR_API_SERVER || '').replace(/\/$/, '');
export const RESPONSE_URL = process.env.REACT_APP_IPAY88_RESPONSE_URL || `${API_SERVER}/gateway/checkout`;
export const BACKEND_URL = process.env.REACT_APP_IPAY88_BACKEND_URL || `${API_SERVER}/v4/cart_items/payment_callback`;

export const PaymentErrors = {
    // Documented by iPay88
    OVER_LIMIT: 'Overlimit per transaction',
    DUPLICATE_REFERENCE: 'Duplicate reference number',
    INVALID_MERCHANT: 'Invalid merchant',
    INVALID_PARAMS: 'Invalid parameters',
    BAD_PAYMENT_METHOD: 'Payment not allowed',
    BAD_REFERRER: 'Permission not allow',
    BAD_SIGNATURE: 'Signature not match',
    ACCOUNT_INACTIVE: 'Status not approved',
    // Undocumented errors
    USER_CANCEL: 'Customer Cancel Transaction'
};

// eslint-disable-next-line import/prefer-default-export
type GatewayData = {
    [string]: string
};
export function requestPayment(gatewayData: GatewayData) {
    const body = {
        PaymentId: '',
        Remark: '',
        Lang: 'UTF-8',
        ...gatewayData,
        ResponseURL: RESPONSE_URL,
        BackendURL: BACKEND_URL
    };
    navigatePost(PAYMENT_URL, { body });
}
