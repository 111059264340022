// @flow
import React, { Component } from 'react';
import { Grid, IconGroup, Image, Popup, Button } from 'semantic-ui-react';
import Slick from 'react-slick';
import { connect } from 'react-redux';

import { getIsNonTempUser, getUser } from 'store/utilities';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import missingImage from 'styles/assets/images/missing-image.svg';
import ProductCarouselArrow from './components/ProductCarouselArrow';
import { withTranslation, Trans } from 'react-i18next';

import shareIcon from '../../../../styles/assets/icons/share-square-solid.svg';
import ShareButtons from 'components/ShareButtons';

type Props = {
    product: object
};

class ProductCarousel extends Component<Props> {
    constructor(props) {
        super(props);
        this.state = {
            productCarousel: null,
            productCarouselNavigation: null,
            width: window.innerWidth
        };
    }

    componentDidMount() {
        this.setState({
            productCarousel: this.productCarousel,
            productCarouselNavigation: this.productCarouselNavigation
        });
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    addLikeHandler() {
        const { likeItem, product } = this.props;

        const liked = product.isLiked === false ? true : false;
        likeItem({ itemId: product.id, isLiked: liked, totalLikes: product.totalLikes });
    }

    zoomHandler(e, id) {
        const imgContainer = document.getElementById(`img-container-${id}`);

        const lensEl = document.getElementById(`zoomLens-${id}`);
        const imgEl = document.getElementById(`zoomImage-${id}`);

        lensEl.style.display = 'block';
        imgEl.style.transition = 'all 0.3s';
        imgEl.style.opacity = 0.8;

        const imgContainerRect = imgContainer.getBoundingClientRect();
        const imgRect = imgEl.getBoundingClientRect();
        const lensRect = lensEl.getBoundingClientRect();

        let x = e.clientX - imgContainerRect.left - lensRect.width / 2;
        let y = e.clientY - imgContainerRect.top - lensRect.height / 2;

        let minX = -50;
        let minY = -50;

        if (x <= minX) {
            x = minX;
        }
        if (y <= minY) {
            y = minY;
        }

        lensEl.style.left = x + 'px';
        lensEl.style.top = y + 'px';

        lensEl.style.backgroundSize = `${imgRect.width * 1.9}px ${imgRect.height * 1.9}px`;

        if (imgContainerRect.height < imgRect.height) {
            const difference = imgRect.height - imgContainerRect.height;
            if (x <= 0 && y <= 0) {
                lensEl.style.backgroundPosition = `${-x * 2 + x}px ${-y * 2 + y - difference}px`;
            } else if (x <= 0) {
                lensEl.style.backgroundPosition = `${-x * 2 + x}px -${y * 2 + difference}px`;
                return;
            } else if (y <= 0) {
                lensEl.style.backgroundPosition = `-${x * 2}px ${-y * 2 - difference + y}px`;
                return;
            }

            lensEl.style.backgroundPosition = `-${x * 2}px -${y * 2 + difference}px`;
        } else {
            if (x <= 0 && y <= 0) {
                lensEl.style.backgroundPosition = `${-x * 2 + x}px ${-y * 2 + y}px`;
            } else if (x <= 0) {
                lensEl.style.backgroundPosition = `${-x * 2 + x}px -${y * 2}px`;
                return;
            } else if (y <= 0) {
                lensEl.style.backgroundPosition = `-${x * 2}px ${-y * 2 + y}px`;
                return;
            }
            lensEl.style.backgroundPosition = `-${x * 2}px -${y * 2}px`;
        }
    }

    exitZoomHandler(id) {
        const lensEl = document.getElementById(`zoomLens-${id}`);
        const imgEl = document.getElementById(`zoomImage-${id}`);
        lensEl.style.display = 'none';
        imgEl.style.opacity = 1;
    }

    render() {
        const { product, merchant } = this.props;
        const mobile = this.state.width < 450;
        const { entity } = merchant;
        const images = product.pictures;
        const slickSettings = {
            ref: (ref) => (this.productCarousel = ref),
            className: 'product-carousel-slick-slider',
            asNavFor: this.state.productCarouselNavigation,
            swipeToSlide: mobile ? true : false,
            infinite: false,
            speed: 500,
            arrows: mobile ? false : true,
            nextArrow: mobile ? false : <ProductCarouselArrow />,
            prevArrow: mobile ? false : <ProductCarouselArrow direction='prev' />
        };
        const slickNavigationClass = 'product-carousel-navigation-slick-slider';
        const getSlickNavigationClass = (length) => `${slickNavigationClass}${images.length < length ? ` ${slickNavigationClass}-items-${images.length}` : ''}`;
        const slickNavigationSettings = {
            ref: (ref) => (this.productCarouselNavigation = ref),
            className: getSlickNavigationClass(5),
            asNavFor: this.state.productCarousel,
            speed: 500,
            slidesToShow: 5,
            swipeToSlide: true,
            infinite: false,
            touchThreshold: 20,
            focusOnSelect: true,
            arrows: false,
            vertical: true,
            verticalSwiping: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 4,
                        className: getSlickNavigationClass(4)
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 5,
                        className: getSlickNavigationClass(5)
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 5,
                        className: getSlickNavigationClass(5)
                    }
                }
            ]
        };
        if (!images.length) {
            return null;
        }

        return (
            <Grid className='auto'>
                <Grid.Column className='col-3'>
                    <div className='product-carousel-navigation-slick-slider-outer' style={{ height: '400px' }}>
                        <Slick {...slickNavigationSettings}>
                            {images.map((image) => (
                                <div key={image.id} className='product-carousel-navigation-slick-slide' onDragStart={(event) => event.preventDefault()}>
                                    <Image
                                        wrapped
                                        src={image.imageThumb || missingImage}
                                        onError={(event) => {
                                            event.target.src = missingImage;
                                        }}
                                    />
                                </div>
                            ))}
                        </Slick>
                    </div>
                </Grid.Column>
                <Grid.Column className='col-13'>
                    <Slick {...slickSettings}>
                        {images.map((image) => (
                            <div key={image.id} className='product-carousel-slick-slide' onDragStart={(event) => event.preventDefault()}>
                                {mobile ? (
                                    <Image
                                        wrapped
                                        src={image.imageFull || missingImage}
                                        onError={(event) => {
                                            event.target.src = missingImage;
                                        }}
                                    />
                                ) : (
                                    <div
                                        id={`img-container-${image.id}`}
                                        className='imgContainer'
                                        onMouseMove={(e) => this.zoomHandler(e, image.id)}
                                        onMouseLeave={() => this.exitZoomHandler(image.id)}
                                    >
                                        <img id={`zoomImage-${image.id}`} src={image.imageFull} alt='' />

                                        <div
                                            id={`zoomLens-${image.id}`}
                                            style={{
                                                width: '200px',
                                                height: '200px',
                                                backgroundImage: `url(${image.imageFull})`,
                                                border: '2px solid wheat',
                                                position: 'absolute',
                                                borderRadius: '50%',
                                                display: 'none',
                                                top: 0,
                                                left: 0,
                                                backgroundRepeat: 'no-repeat'
                                            }}
                                        ></div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </Slick>
                    <Grid className='auto'>
                        <Grid.Column className='col-8 fluid'>
                            <div className='ui labeled button ' tabIndex='0'>
                                <button className='ui gray button' onClick={this.addLikeHandler.bind(this)} disabled={!this.props.user || !this.props.IsNonTempUser}>
                                    <i className='heart icon'></i>
                                    {!this.props.user ? 'Likes' : product.isLiked ? 'Liked' : <Trans i18nKey='product.like'>Like</Trans>}
                                </button>
                                <a className='ui basic gray left pointing label'>{product.totalLikes}</a>
                            </div>
                        </Grid.Column>
                        <Grid.Column className='col-8'>
                            <div style={{ marginLeft: 'auto', float: 'right' }}>
                                <Popup
                                    trigger={
                                        <Button size='small' color='black' icon>
                                            <img style={{ paddingRight: 10, marginBottom: -2 }} src={shareIcon}></img>
                                            <label style={{ fontSize: 14 }}>
                                                <Trans i18nKey='product.share'>Share</Trans>
                                            </label>
                                        </Button>
                                    }
                                    flowing
                                    hoverable
                                    position='bottom right'
                                >
                                    <ShareButtons></ShareButtons>
                                </Popup>
                            </div>
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    }
}

const mapStateToProps = (state, { product }) => ({
    merchant: getUser(state, product.merchant),
    IsNonTempUser: getIsNonTempUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    likeItem: wrapAPIDispatch(dispatch, actions.likeItem)
});

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(ProductCarousel));
