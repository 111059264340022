// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Segment, Image, Grid, Label } from 'semantic-ui-react';

import type { EntityData, Item, User } from 'store/types';
import missingImage from 'styles/assets/images/missing-image.svg';
import missingStoreImage from 'styles/assets/images/missing-store-image.svg';
import FollowButton from 'components/FollowButton';

function renderItem({ isFetching, entity, preventSlickClickDuringChange }: EntityData<Item>) {
    // TODO: handle loading better
    if (isFetching || entity == null) {
        return null;
    }
    const { id, name, pictures, slug } = entity;

    // TODO: show placeholder if no picture
    const pictureURL = pictures[0] && pictures[0].imageThumb;
    return (
        <Grid.Column key={id} className='col-5-and-one-third' style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem' }}>
            <Link to={`/${slug}`} onClick={preventSlickClickDuringChange || null}>
                <Image
                    className='square-image'
                    wrapped
                    title={name}
                    alt={name}
                    src={pictureURL || missingImage}
                    onError={(event) => {
                        event.target.src = missingImage;
                    }}
                />
            </Link>
        </Grid.Column>
    );
}

type Props = {
    // from store
    merchant: EntityData<User>,
    items: EntityData<Item>[],
    preventSlickClickDuringChange: () => void,
    isLoggedIn: boolean,
    isLoading: boolean
};

function StoreTeaser({ merchant, items, preventSlickClickDuringChange, parentId }: Props) {
    // TODO: handle loading better
    if (merchant.isFetching || merchant.entity == null) {
        return null;
    }
    const { alias, pictureThumb, slug, userSlug, isVerified, id, isFollowing } = merchant.entity;
    const storeURL = `/${slug || userSlug}`;

    return (
        <Segment className='store-teaser simple'>
            <Link to={storeURL} onClick={preventSlickClickDuringChange || null}>
                {/*<Image size="medium">*/}
                <Image
                    className='circular-image'
                    wrapped
                    alt={alias}
                    src={pictureThumb || missingStoreImage}
                    onError={(event) => {
                        event.target.src = missingStoreImage;
                    }}
                />
                {!!isVerified && <Label className='store-teaser-verified' circular size='small' icon='check' />}
                {/*</Image>*/}
            </Link>
            <Header as='h3' className='store-teaser-header'>
                <Link to={storeURL} onClick={preventSlickClickDuringChange || null}>
                    {alias}
                </Link>
            </Header>
            {/*<Grid className="auto tensed">
         {items.length > 0 &&
          items.map(item => renderItem(
            { ...item, preventSlickClickDuringChange},
          ))
        }

        {items.length < 3 &&
          [...{ length: 3 - items.length }].map((item, index) =>
          renderItem(
            {
              entity: {
                id: `${id}-${index}`,
                alias,
                slug,
                pictures: [
                  { imageThumb: missingImage },
                ],
                preventSlickClickDuringChange,
              },
            },
          ))
        }
      </Grid>*/}
            {/*<Grid centered className="tensed">
        <FollowButton
          followId={id}
          isFollowing={isFollowing}
          parentId={parentId}
        />
      </Grid>
      */}
        </Segment>
    );
}

export default withTranslation('translations')(StoreTeaser);
