// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Loader, Button } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

import Message from './components/Message';
import MessageProductView from '../MessageProductView';

type Props = {
    messages: []
};

function MessageListView({ conversation, messages, me, isLoadingMessages, item, user, hasMore, onClickLoadMoreMessages, negotiationBox }: Props) {
    const sortedMessages = Object.values(messages).sort((a, b) => new Date(a.entity.createdAt) - new Date(b.entity.createdAt));

    const lastOfferMessage = sortedMessages
        .slice()
        .reverse()
        .find((message) => message.entity.offer > 0);
    const lastOfferId = lastOfferMessage && lastOfferMessage.entity.offer;
    return (
        <Grid className='auto tensed'>
            <Grid.Column className='col-row'>{item && item.entity && item.entity.id !== 0 && !negotiationBox && <MessageProductView item={item} user={user} />}</Grid.Column>
            <Grid.Column className='col-row'>
                {hasMore && !isLoadingMessages && (
                    <Button fluid onClick={onClickLoadMoreMessages}>
                        <Trans i18nKey='messages.loadMore'>Load more messages</Trans>
                    </Button>
                )}
            </Grid.Column>
            {isLoadingMessages && (
                <Grid.Column>
                    <Loader active inline='centered' />
                </Grid.Column>
            )}
            <Grid.Column className='col-row' style={{ marginTop: '20px', overflowY: 'auto', maxHeight: '300px' }}>
                <Grid className='auto tensed'>
                    {sortedMessages.map((message) => (
                        <Grid.Column className='col-row' key={message.entity.id}>
                            <Message
                                negotiationBox={negotiationBox}
                                message={message}
                                me={me}
                                otherUser={conversation.entity.user}
                                item={item}
                                conversation={conversation}
                                lastOfferId={lastOfferId}
                            />
                        </Grid.Column>
                    ))}
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default MessageListView;
