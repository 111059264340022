// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getStore, getCategoryAttribute } from 'store/utilities';

import SellItemFormView from './SellItemFormView';

type Props = {
    settings: {
        updatedAt: string
    },
    isLoadingSettings: boolean,
    addItem: typeof actions.addItem,
    loadMyStoreSettings: typeof actions.loadMyStoreSettings
};

class SellItemFormContainer extends Component<Props> {
    componentDidMount() {
        const { settings, isLoadingSettings, loadMyStoreSettings } = this.props;
        if (!settings.updatedAt && !isLoadingSettings) {
            loadMyStoreSettings().catch((error) => console.error(error));
        }
    }

    handleSubmit = (data) => {
        const { addItem } = this.props;
        return addItem(data);
    };

    render() {
        return <SellItemFormView onSubmit={this.handleSubmit} {...this.props} />;
    }
}

const mapStateToProps = (state) => {
    const { settings } = state.merchantData;
    const stores = settings.stores ? settings.stores.map((id) => getStore(state, id).entity) : [];
    return {
        isLoadingSettings: settings.isLoading,
        stores,
        settings,
        getFormValues: (formId) => state.ui.forms[formId],
        getCategoryAttribute: (id) => getCategoryAttribute(state, id).entity
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    addItem: wrapAPIDispatch(dispatch, actions.addItem),
    loadMyStoreSettings: wrapAPIDispatch(dispatch, actions.loadMyStoreSettings),
    loadItems: wrapAPIDispatch(dispatch, actions.loadMyStoreItems)
});

export default connect(mapStateToProps, mapDispatchToProps)(SellItemFormContainer);
