import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { useGoogleLogin } from '@react-oauth/google';

import * as actions from 'store/actions';

const LogInGoogle = () => {
    const isLoggingIn = useSelector((state) => state.ui.isLoggingIn);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onSuccess = (res) => {
        dispatch(actions.setIsLoggingIn(false));
        if (res && res.accessToken) {
            dispatch(actions.logInGoogle(res.accessToken));
        }
    };

    const onFailure = (res) => {
        dispatch(actions.setIsLoggingIn(false));
        // TODO need to ask about a generic way to handle these errors.
        console.log('Failed to login with Google', res);
    };

    const authenticateWithGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            dispatch(actions.logInGoogle(codeResponse.access_token));
        },
        onError: (error) => console.log('érror', error)
    });

    return (
        <Button color='google plus' fluid onClick={() => authenticateWithGoogle()} loading={isLoggingIn} disabled={isLoggingIn}>
            <Icon name='google' />
            {t('logInGoogle.button')}
        </Button>
    );
};

export default LogInGoogle;
