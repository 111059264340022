// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import SidebarProfileBackground from './components/SidebarProfileBackground';
import SidebarProfileUser from './components/SidebarProfileUser';
import SidebarProfileLogInSignUp from './components/SidebarProfileLogInSignUp';
import SidebarProfileActions from './components/SidebarProfileActions';

type Props = {
    isLoggedIn: boolean,
    user: EntityData<User>
};

function SidebarProfile({ isLoggedIn, user }: Props) {
    localStorage.setItem('isLoggedIn', isLoggedIn);
    localStorage.setItem('user', JSON.stringify(user));
    return (
        <div className='sidebar-profile'>
            <SidebarProfileBackground user={user} />
            {isLoggedIn ? <SidebarProfileUser user={user} /> : <SidebarProfileLogInSignUp />}
            <SidebarProfileActions user={user} />
        </div>
    );
}

export default SidebarProfile;
