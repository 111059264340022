// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false
};

function updateFromLoadActivity(state, action) {
    const { result } = action.payload;

    const { totalSaleCount: totalSalesCount, releasedSaleCount: releasedSalesCount, toReleasedSaleCount: toReleaseSalesCount } = result.sale;

    return {
        ...state,
        totalSalesCount: totalSalesCount || 0,
        releasedSalesCount,
        toReleaseSalesCount
    };
}

function activity(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_ACTIVITY: {
            if (action.status === 'success') {
                return updateFromLoadActivity(state, action);
            }
            break;
        }
        // Reset activity store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default activity;
