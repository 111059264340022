// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';

import * as actions from 'store/actions';
import { getItem } from 'store/utilities';
import type { EntityData, Conversation } from 'store/types';

import { MessageTypes } from 'store/enums';
import OfferActionFormView from './OfferActionFormView';

import { getVariant } from '../../../../../../store/utilities';

type Props = {
    conversation: EntityData<Conversation>,
    myId: number,
    productId: number
};

class OfferActionFormContainer extends Component<Props> {
    declineOffer = ({ offer }) => {
        const { rejectItemOffer, conversation, myId, authentication = Authentication.User } = this.props;

        const offerParams = {
            offerId: offer.id
        };

        if (conversation && conversation.entity) {
            offerParams.itemId = conversation.entity.item;
        }

        return rejectItemOffer(offerParams);
    };

    acceptOffer = ({ offer, productId, variantId }) => {
        const cartParams = {
            itemId: productId,
            variantId,
            offerId: offer.id,
            quantity: offer.quantity
        };
        return this.props.addCartItem(cartParams);
    };

    render() {
        const { onPostMessageSuccess, message, loadMyMessages } = this.props;
        return (
            <OfferActionFormView
                {...this.props}
                formId={`offer.form.message.${message.entity.id}`}
                translationId='offer.form'
                declineOffer={this.declineOffer}
                acceptOffer={this.acceptOffer}
                onPostMessageSuccess={onPostMessageSuccess}
                loadMyMessages={loadMyMessages}
            />
        );
    }
}

const mapStateToProps = (state, { message }) => {
    const product = getItem(state, message.entity.itemId);
    let variants = [];
    if (product.entity && product.entity.variants) {
        variants = product.entity.variants.map((id) => getVariant(state, id));
    }
    return {
        getFormValues: (id) => state.ui.formValues[id],
        product,
        variants
    };
};

const mapDispatchToProps = (dispatch) => ({
    postMessage: wrapAPIDispatch(dispatch, actions.postMessage),
    addCartItem: wrapAPIDispatch(dispatch, actions.addCartItem),
    rejectItemOffer: wrapAPIDispatch(dispatch, actions.rejectItemOffer),
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    loadMyMessages: wrapAPIDispatch(dispatch, actions.loadMyMessages)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(OfferActionFormContainer);
