// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import EditItemForm from './components/EditItemForm';

function EditItemView(props) {
    return (
        <Container>
            <Header as='h1'>
                <Trans i18nKey='editItem.header'>Edit Item</Trans>
            </Header>
            <EditItemForm {...props} />
        </Container>
    );
}

export default withTranslation('translations')(EditItemView);
