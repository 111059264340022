// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { Image } from 'semantic-ui-react';

import type { EntityData, LandingComponentBanner } from 'store/types';

type Props = {
    banner: EntityData<LandingComponentBanner>,
    onClickYouTube: () => void,
    getSlickChanging: () => void
};

function mapBannerToURL({ actionType, actionValue, targetSlug }) {
    switch (actionType) {
        case 'url':
        case 'youtube_url':
            return actionValue;
        case 'video':
            return actionValue;
        case 'tag':
            return `/search/tags/${actionValue}`;
        case 'store':
        case 'item':
            return `/${targetSlug}`;
        case 'category':
            return `/categories/${targetSlug}`;
        case 'tab':
            // TODO Investigate tab types on old front
            switch (actionValue) {
                case 'mypasar':
                    return '/classifieds';
                case 'classifieds':
                    return '/classifieds';
                default:
                    console.warn('Unimplemented tab type ', actionValue);
                    return `/${actionValue}`;
            }
        default:
            // Unimplemented actionType
            return '#';
    }
}

function PromotionalCarouselItem({ banner, onClickYouTube = () => {}, getSlickChanging = () => {} }: Props) {
    if (banner.isFetching || banner.entity == null) {
        return null;
    }
    const { banner: imageURL, bannerMobile: imageURLMobile } = banner.entity;
    const url = mapBannerToURL(banner.entity);
    const onClickLink = (event) => {
        // if (getSlickChanging()) {
        //     event.preventDefault();
        // }
        if (banner.entity.actionType === 'youtube_url') {
            event.preventDefault();
            onClickYouTube();
        }
    };

    if (banner.entity.actionType === 'video') {
        return (
            <>
                <video playsInline autoPlay muted loop className='login-video'>
                    <div className='mb-mobile hidden top-banner' />
                    <source src={url} type='video/mp4'></source>
                </video>
            </>
        );
    } else if (url.match(/^https?:/)) {
        return (
            <a href={url} onClick={onClickLink}>
                <div className='slideImgContainer'>
                    <Image src={imageURL} className='coverSlideImage' />
                    <div className='imageText'>
                        <Image fluid src={imageURLMobile} />
                    </div>
                </div>
            </a>
        );
    } else {
        return (
            <Link to={url} onClick={onClickLink}>
                <div className='slideImgContainer'>
                    <Image src={imageURL} className='coverSlideImage' />
                    <div className='imageText'>
                        <Image fluid src={imageURLMobile} />
                    </div>
                </div>
            </Link>
        );
    }
}

export default compose(withTranslation('translations'))(PromotionalCarouselItem);
