// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import BankAccountEditModalView from './BankAccountEditModalView';

type Props = {
    // from store
    updateBankAccount: typeof actions.updateBankAccount,
    openModal: typeof actions.openModal
};

class BankAccountEditModalContainer extends Component<Props> {
    render() {
        return <BankAccountEditModalView {...this.props} formId='bankAccount.form.edit' translationId='bankAccount.form' />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateBankAccount: wrapAPIDispatch(dispatch, actions.updateBankAccount),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(BankAccountEditModalContainer);
