// @flow
import * as ActionTypes from 'store/actions';

export default function likes(state = {}, action) {
    switch (action.type) {
        case ActionTypes.LOAD_LIKES: {
            if (action.status === 'request') {
                const { userId } = action.reducerParams;
                const likes = state[userId] || {};

                return {
                    ...state,
                    [userId]: {
                        isLoading: true,
                        items: likes.items || []
                    }
                };
            }
            if (action.status === 'success') {
                const { userId, offset } = action.reducerParams;
                const likes = state[userId];

                if (likes.items.length > 0 && offset !== 0) {
                    const items = [].concat(likes.items, action.payload.result.items);
                    return {
                        ...state,
                        [userId]: {
                            items,
                            isLoading: false
                        }
                    };
                }
                return {
                    ...state,
                    [userId]: {
                        ...action.payload.result,
                        isLoading: false
                    }
                };
            }
            break;
        }
        default:
            break;
    }
    return state;
}
