// @flow
export type SetFilterParams = {
    categoryId?: ?number,
    sort?: string
};

export const SET_FILTER = 'SET_FILTER';
export const setFilter = (params: SetFilterParams) => ({
    type: SET_FILTER,
    payload: params
});

export default setFilter;
