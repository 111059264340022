// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

export type RecoverResetPasswordParams = {
    resetToken: string,
    password: string
};

export const RECOVER_RESET_PASSWORD = 'RECOVER_RESET_PASSWORD';
export const recoverResetPassword = wrapAPIActionCreator(({ resetToken, password }: LoginUserParams) => ({
    [API_DATA]: {
        type: RECOVER_RESET_PASSWORD,
        endpoint: '/v3/auth/recover/reset_password',
        method: 'POST',
        body: {
            resetToken,
            password
        },
        preprocess: ({ user: { accessToken, ...rest } }) => ({ user: rest, accessToken }),
        schema: {
            user: Schemas.user
        }
    }
}));
