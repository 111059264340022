// @flow
import { uniq } from 'lodash';
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    hasMore: true,
    active: [],
    activeCount: 0,
    banned: [],
    bannedCount: 0,
    inactive: [],
    inactiveCount: 0,
    soldout: [],
    soldoutCount: 0
};

function updateFromLoadMyStoreItems(state, action) {
    const { result } = action.payload;

    const { offset } = action.reducerParams;

    const {
        activeItemCount: activeCount,
        activeItem: active,
        bannedItemCount: bannedCount,
        bannedItem: banned,
        inActiveItemCount: inactiveCount,
        inActiveItem: inactive,
        soldoutItemCount: soldoutCount,
        soldoutItem: soldout
    } = result.items;

    if (offset > 0) {
        return {
            ...state,
            activeCount,
            active: uniq(state.active.concat(active)),
            bannedCount,
            banned: uniq(state.banned.concat(banned)),
            inactiveCount,
            inactive: uniq(state.inactive.concat(inactive)),
            soldoutCount,
            soldout: uniq(state.soldout.concat(soldout)),
            isLoading: false
        };
    }

    return {
        ...state,
        activeCount,
        active,
        bannedCount,
        banned,
        inactiveCount,
        inactive,
        soldoutCount,
        soldout,
        isLoading: false
    };
}

function updateFromLoadMyStoreActivity(state, action) {
    const { result } = action.payload;

    const { activeItemCount: activeCount, bannedItemCount: bannedCount, inActiveItemCount: inactiveCount, soldoutItemCount: soldoutCount } = result.item;

    return {
        ...state,
        activeCount,
        bannedCount,
        inactiveCount,
        soldoutCount
    };
}

/** Update merchant item status based on Item entity changes */
const ItemStatus = {
    Available: 0,
    Sold: 1,
    SoldOut: 2
};

const ItemIsActive = {
    InActive: 0,
    Active: 1,
    Hidden: 2
};

function updateFromEntities(state, action) {
    // Local copy of state to modify
    const active = {
        predicate: (item) => item.status === ItemStatus.Available && [ItemIsActive.Active, ItemIsActive.Hidden].includes(item.is_active),
        ids: state.active.slice(),
        count: state.activeCount
    };
    const banned = {
        predicate: (item) => item.is_active === ItemIsActive.InActive,
        ids: state.banned.slice(),
        count: state.bannedCount
    };
    const inactive = {
        predicate: (item) => item.is_active === ItemIsActive.InActive,
        ids: state.inactive.slice(),
        count: state.inactiveCount
    };
    const collections = { active, banned, inactive };

    const { entities } = action.payload;

    // Helpers
    const findPreviousCollections = (colls, item) =>
        Object.keys(colls)
            .filter((key) => colls[key].ids.includes(item.id))
            .sort();
    const valuesOf = (obj) => Object.keys(obj).map((key) => obj[key]);
    const arrayEquals = (xs, ys) => xs.length === ys.length && xs.every((x, i) => x === ys[i]);
    const removeFrom = (coll, id) => {
        coll.ids.splice(coll.ids.indexOf(id), 1);
        coll.count -= 1;
    };
    const appendTo = (coll, id) => {
        coll.ids.push(id);
        coll.count += 1;
    };

    valuesOf(entities.items).forEach((item) => {
        if (item.isActive == null) {
            return;
        }
        const prevs = findPreviousCollections(collections, item);
        // TODO: this is very crude for now, should probably be rewritten. Very
        // specifically matches what the backend does currently, which is probably
        // wrong (treats banned == inactive, e.g.)
        if (arrayEquals(prevs, ['active']) && item.isActive === 0) {
            // Deactivated
            removeFrom(collections.active, item.id);
            appendTo(collections.banned, item.id);
            appendTo(collections.inactive, item.id);
        } else if (arrayEquals(prevs, ['banned', 'inactive']) && item.isActive === 1) {
            // Activated
            appendTo(collections.active, item.id);
            removeFrom(collections.banned, item.id);
            removeFrom(collections.inactive, item.id);
        }
    });

    return {
        ...state,
        active: uniq(collections.active.ids),
        activeCount: collections.active.count,
        banned: uniq(collections.banned.ids),
        bannedCount: collections.banned.count,
        inactive: uniq(collections.inactive.ids),
        inactiveCount: collections.inactive.count
    };
}

function items(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_ACTIVITY:
            if (action.status === 'success') {
                return updateFromLoadMyStoreActivity(state, action);
            }
            break;
        case ActionTypes.LOAD_MY_STORE_ITEMS:
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            if (action.status === 'success') {
                return updateFromLoadMyStoreItems(state, action);
            }
            break;
        // Reset items store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            if (((action.payload || {}).entities || {}).items) {
                return updateFromEntities(state, action);
            }
            break;
    }
    return state;
}

export default items;
