import { combineReducers } from 'redux';
import countries from './countries';
import enabledCountries from './enabledCountries';
import states from './states';
import addressStates from './addressStates';

export const address = combineReducers({
    countries,
    enabledCountries,
    states,
    addressStates
});

export default address;
