import React from 'react';
import { useSelector } from 'react-redux';

import MyAgentSettingsView from './MyAgentSettingsView';
import { getUser } from 'store/utilities';

const MyAgentSettingsContainer = () => {
    const user = useSelector((state) => getUser(state, state.userData.user).entity);
    return <>{!!user && <MyAgentSettingsView user={user} />}</>;
};

export default MyAgentSettingsContainer;
