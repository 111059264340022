// @flow
import React from 'react';
import { Form, Segment, Grid, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';

import ShippingToDetailsForm from './components/ShippingToDetailsForm';

import { shippingToListDefaults } from './index';

const defaultValuesFromItem = ({ item }) => {
    const { shippingDetails } = item;
    const formValues = {};

    shippingToListDefaults.forEach(({ id, key }) => {
        if (shippingDetails.find(({ itemShippingDetailKey }) => itemShippingDetailKey === key)) {
            formValues[id] = createEmptyFormFieldData({ value: true, type: 'boolean' });
        } else {
            formValues[id] = createEmptyFormFieldData({ value: false, type: 'boolean' });
        }
    });
    return formValues;
};

class ShippingToView extends CommonForm {
    constructor(props) {
        super(props);

        const { item, updateFormValues, formId } = props;

        let formValues = {};

        if (item) {
            formValues = defaultValuesFromItem({ item });
            //console.log('ShippingToView: constructor item', item, formValues);
        } else {
            shippingToListDefaults.forEach(({ id, selected }) => {
                formValues[id] = createEmptyFormFieldData({ value: selected, type: 'boolean' });
            });
            //console.log('ShippingToView: constructor NOT item', shippingToListDefaults, formValues);
        }

        this.state = {
            formValues
        };
        updateFormValues({ formValues, formId });
    }

    handleShippingToChange = (event, data) => {
        const { name, checked } = data;
        const { weight, shippingFrom, onMissingValue } = this.props;

        const { formValues } = this.state;

        if (checked) {
            if (!shippingFrom || !weight) {
                onMissingValue();
                return;
            }

            const defaultsForTarget = shippingToListDefaults.find((item) => item.id === name);

            if (defaultsForTarget) {
                shippingToListDefaults.forEach((item) => {
                    if (item.shipToId === defaultsForTarget.shipToId) {
                        if (item.id !== name) {
                            const otherTarget = formValues[item.id];
                            otherTarget.value = false;
                            formValues[item.id] = otherTarget;
                        }
                    }
                });
            }
        }

        this.setState({ formValues }, () => {
            this.handleChange(name, data);
        });
    };

    render() {
        const { formValues } = this.state;
        const { formId, item } = this.props;

        //console.log('shippingto forvalues', formValues);
        return (
            <Form.Field>
                <Form.Field>
                    <label htmlFor='sell-item-form-delivery-shipping-to'>
                        <Trans i18nKey='sellItem.form.delivery.shippingTo.label'>Shipping</Trans>
                    </label>
                </Form.Field>

                <Form.Field>
                    {Object.keys(formValues).map((id) => {
                        const field = formValues[id];
                        const { value } = field;
                        const shippingDefaults = shippingToListDefaults.find((item) => item.id === id);
                        //const title = shippingDefaults.title;

                        let itemShippingDetails = null;
                        let merchantShippingDetail = null;
                        if (item && shippingDefaults) {
                            const { shippingDetails } = item;

                            itemShippingDetails = shippingDetails.find(({ merchantShippingDetail: detail }) => detail.itemShippingDetailKey === shippingDefaults.key);
                            if (itemShippingDetails) {
                                merchantShippingDetail = itemShippingDetails.merchantShippingDetail;
                            }
                        }

                        //console.log('shippingDefaults', shippingDefaults);

                        return (
                            <Segment className='semi-simple' key={id}>
                                <Grid className='auto tensed'>
                                    <Grid.Row>
                                        <Grid.Column
                                            style={{
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                paddingBottom: '10px'
                                            }}
                                        >
                                            <Header as='h4' disabled>
                                                BIH (EuroExpress)
                                            </Header>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>

                                <ShippingToDetailsForm
                                    {...this.props}
                                    shippingDefaults={shippingDefaults}
                                    merchantShippingDetail={merchantShippingDetail}
                                    formId={`${formId}.${id}.details`}
                                />
                            </Segment>
                        );
                    })}
                </Form.Field>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ShippingToView);
