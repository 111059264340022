// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LikeItemParams = {
    itemId: number,
    totalLikes: number,
    isLiked: boolean
};

// LIKE_ITEM ----------------------
export const LIKE_ITEM = 'LIKE_ITEM';
export const likeItem = wrapAPIActionCreator(({ itemId, isLiked, totalLikes }: LikeItemParams) => ({
    [API_DATA]: {
        type: LIKE_ITEM,
        endpoint: `/v3/items/${itemId}/like`,
        authentication: Authentication.User,
        method: 'GET',
        schema: {
            item: Schemas.item
        },
        preprocess: () => ({
            item: {
                id: itemId,
                isLiked,
                totalLikes: (totalLikes += isLiked ? 1 : -1)
            }
        })
    }
}));
