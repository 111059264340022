import React, { useMemo, useRef } from 'react';
import { Form, Button, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { BusinessTypes } from 'store/enums';
import { serializeFile } from 'utilities';

const BUSINESS_TYPES = [
    {
        key: 'createStore.form.businessType.individual.label',
        value: BusinessTypes.INDIVIDUAL
    },
    {
        key: 'createStore.form.businessType.business.label',
        value: BusinessTypes.BUSINESS
    }
];

const StoreSummary = ({ businessNameAttributes, businessType, setBusinessType, vatAttributes, agentAttributes, license, setLicense }) => {
    const { t } = useTranslation();
    const fileRef = useRef(null);
    const businessTypes = useMemo(() => BUSINESS_TYPES.map(({ key, value }) => ({ text: t(key), value })), [t]);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            serializeFile(file).then((content) =>
                setLicense({
                    name: file.name,
                    content
                })
            );
        } else {
            setLicense(null);
        }
    };
    return (
        <>
            <Form.Input name='name' label={t('createStore.form.store.name.label')} {...businessNameAttributes} required />
            <Form.Dropdown
                selection
                name='business-type'
                id='business-type'
                options={businessTypes}
                onChange={(_event, { value }) => {
                    setBusinessType(value);
                }}
                label={t('createStore.form.businessType.label')}
                value={businessType}
            />
            {businessType === 2 && (
                <>

                    <Form.Field required>
                        <Button style={{ marginBottom: '15px' }} onClick={() => fileRef.current.click()}>
                            <Icon name='attach' />
                            {t('uploadFile.button', { defaultValue: 'Upload file' })}
                        </Button>
                        <input type='file' accept='.pdf, .jpg, .jpeg' hidden onChange={handleFileChange} ref={fileRef} required />
                    </Form.Field>

                    <label style={{ marginLeft: '20px' }}>{license ? license.name : t('createStore.form.store.file.placeholder')}</label>
                    <Form.Input label={t('myStore.form.businessType.idNumber.label', { defaultValue: 'ID Number' })} {...vatAttributes} required />
                </>
            )}
            {/*<Form.Input label={t('myStore.form.agentOfficeID.label')} {...agentAttributes} />*/}
        </>
    );
};

export default StoreSummary;
