// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';

import MyStoreProfilePictureView from './MyStoreProfilePictureView';

type Props = {
    updateMyStoreProfilePicture: typeof actions.updateMyStoreProfilePicture,
    loadMyAccounts: typeof actions.loadMyAccounts
};

class MyStoreProfilePictureContainer extends Component<Props> {
    state = {
        isUploadingPicture: false
    };

    onSubmitProfilePicture = (profilePictureUrl) => {
        this.setState({ isUploadingPicture: true });

        const { updateMyStoreProfilePicture, loadMyAccounts } = this.props;
        updateMyStoreProfilePicture({ profilePictureUrl })
            .then(loadMyAccounts)
            .then(() => this.setState({ isUploadingPicture: false }))
            .catch(() => this.setState({ isUploadingPicture: false }));
    };

    render() {
        const { user } = this.props;

        if(!user) {
            return <></>;
        }
        const { picture } = user;
        return <MyStoreProfilePictureView onSubmitProfilePicture={this.onSubmitProfilePicture} profilePictureUrl={picture} isUploadingPicture={this.state.isUploadingPicture} />;
    }
}

const mapStateToProps = (state) => ({
    user: getUser(state, state.merchantData.user).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateMyStoreProfilePicture: wrapAPIDispatch(dispatch, actions.updateMyStoreProfilePicture),
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreProfilePictureContainer);
