// @flow
import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';
import SellItemImages from './components/SellItemImages';

type Props = {
    t: (string) => string
};

type State = {
    title: FormFieldData<string>
};

class SellItemFormItem extends CommonForm<Props, State> {
    state = {
        formValues: {
            title: createEmptyFormFieldData({ value: '', required: true }),
            category: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
            subcategory: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
            subSubcategory: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
            description: createEmptyFormFieldData({ value: '<!DOCTYPE html><html><head></head><body></body></html>' }),
            tags: createEmptyFormFieldData({ value: '', required: false }),
            descriptionEditorState: EditorState.createEmpty()
        }
    };

    onEditorStateChange = (editorState) => {
        const { formValues } = this.state;
        formValues.descriptionEditorState = editorState;
        this.setState({ formValues });
        const contentState = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(contentState);

        this.handleChange({}, { name: 'description', value: `<!DOCTYPE html><html><head></head><body>${markup}</body></html>` });
    };

    render() {
        const { rootCategories, getCategory } = this.props;
        const { formValues } = this.state;
        const {
            category: { value: categoryId },
            subcategory: { value: subcategoryId },
            descriptionEditorState
        } = formValues;

        const subcategories = categoryId != null ? getCategory(categoryId).subcategories.map((id) => getCategory(id)) : [];

        const subSubcategories = subcategoryId != null ? getCategory(subcategoryId).subcategories.map((id) => getCategory(id)) : [];
        return (
            <Form.Field>
                {/*<Form.Field>*/}
                {/*    <Header as='h2'>*/}
                {/*        <Trans i18nKey='sellItem.form.addSingleItem.header'>Add a Single Item</Trans>*/}
                {/*    </Header>*/}
                {/*</Form.Field>*/}

                {this.renderInputField({ name: 'title', type: 'text' }, this.props)}
                {/*ovo je za description*/}
                <Form.Field>
                    <label htmlFor='sell-item-form-item-description'>
                        <Trans i18nKey='sellItem.form.item.description.label'>Description</Trans>
                    </label>

                    <Editor
                        editorState={descriptionEditorState}
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                            options: ['inline', 'list', 'link'],
                            inline: {
                                options: ['italic', 'bold', 'underline', 'strikethrough']
                            }
                        }}
                    />
                </Form.Field>

                <SellItemImages {...this.props} />

                {this.renderDropdownField(
                    {
                        name: 'category',
                        options: rootCategories.map((category) => ({ text: <Trans i18nKey={`categories.${category.slug}`}>{category.name}</Trans>, value: category.id })),
                        onChange: (event, data) => {
                            const { formValues: newFormValues } = this.state;
                            newFormValues.subcategory.value = null;
                            newFormValues.subSubcategory.value = null;
                            this.setState({ formValues: newFormValues });
                            this.handleChange(event, data);
                        }
                    },
                    this.props
                )}

                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderDropdownField(
                            {
                                width: 8,
                                name: 'subcategory',
                                options: subcategories.map((category) => ({ text: <Trans i18nKey={`categories.${category.slug}`}>{category.name}</Trans>, value: category.id })),
                                onChange: (event, data) => {
                                    const { formValues: newFormValues } = this.state;
                                    newFormValues.subSubcategory.value = null;
                                    this.setState({ formValues: newFormValues });
                                    this.handleChange(event, data);
                                }
                            },
                            this.props
                        )}

                        {this.renderDropdownField(
                            {
                                width: 8,
                                name: 'subSubcategory',
                                options: subSubcategories.map((category) => ({ text: <Trans i18nKey={`categories.${category.slug}`}>{category.name}</Trans>, value: category.id }))
                            },
                            this.props
                        )}
                    </Form.Group>
                </Form.Field>

                {/*<Form.Field>*/}
                {/*<label htmlFor='sell-item-form-item-description'>*/}
                {/*    <Trans i18nKey='sellItem.form.item.description.label'>Description</Trans>*/}
                {/*</label>*/}

                {/*<Editor*/}
                {/*    editorState={descriptionEditorState}*/}
                {/*    onEditorStateChange={this.onEditorStateChange}*/}
                {/*    toolbar={{*/}
                {/*        options: ['inline', 'list', 'link'],*/}
                {/*        inline: {*/}
                {/*            options: ['italic', 'bold', 'underline', 'strikethrough']*/}
                {/*        }*/}
                {/*    }}*/}
                {/*/>*/}
                {/*</Form.Field>*/}
                {/*//ovdje treba ubaciti price i ovo*/}
                {/*{this.renderInputField({ name: 'tags', type: 'text', defaultLabel: 'Tags' }, this.props)}*/}
            </Form.Field>
        );
    }
}

export default withTranslation('translations', { withRef: true })(SellItemFormItem);
