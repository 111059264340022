// @flow
import * as ActionTypes from '../../actions';

const initialState = {};

function parsePostMessageAction(state, action) {
    if (action.status === 'success') {
        const { result } = action.payload;
        const entity = action.payload.entities.messages[result];
        const { conversationKey } = entity;

        const messageState = state[conversationKey] || {
            isLoading: false,
            list: []
        };
        const { list } = messageState;

        return {
            ...state,
            [conversationKey]: {
                ...messageState,
                isLoading: false,
                list: [result, ...list],
                hasMore: true,
                error: undefined
            }
        };
    }
    return state;
}

function parseLoadProductConversationsAction(state, action) {
    const { offset } = action.reducerParams;

    if (action.status === 'success') {
        const { conversations: conversationKey } = action.payload.result;

        if (conversationKey) {
            const messageState = state[conversationKey] || {
                isLoading: false,
                list: [],
                hasMore: true
            };
            const list = offset === 0 ? [] : messageState.list;

            const conversation = action.payload.entities.conversations[conversationKey];
            const { lastMessage } = conversation;

            if (!list.includes(lastMessage)) {
                const message = action.payload.entities.messages[lastMessage];
                if (!message.params || message.params.length === 0) {
                    list.push(conversation.lastMessage);
                }
            }

            return {
                ...state,
                [conversationKey]: {
                    ...messageState,
                    isLoading: false,
                    list,
                    error: undefined,
                    hasMore: true
                }
            };
        }
    }
    return state;
}

function messages(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_CONVERSATIONS:
        case ActionTypes.LOAD_MY_STORE_CONVERSATIONS:
        case ActionTypes.LOAD_MY_AGENT_CONVERSATIONS:
            if (action.status === 'success') {
                const newState = { ...state };
                const { entities } = action.payload;
                const { conversations } = action.payload.result;
                conversations.forEach((conversationKey) => {
                    const conversation = entities.conversations[conversationKey];
                    const messageState = state[conversationKey] || {
                        isLoading: false,
                        list: [],
                        hasMore: true
                    };
                    if (!messageState.list.includes(conversation.lastMessage)) {
                        const message = action.payload.entities.messages[conversation.lastMessage];
                        if (!message.params || message.params.length === 0) {
                            messageState.list.push(conversation.lastMessage);
                        }
                    }
                    newState[conversationKey] = messageState;
                });
                return newState;
            }
            break;
        case ActionTypes.LOAD_PRODUCT_CONVERSATIONS:
            return parseLoadProductConversationsAction(state, action);
        case ActionTypes.LOAD_MY_MESSAGES:
        case ActionTypes.LOAD_MY_STORE_MESSAGES:
        case ActionTypes.LOAD_MY_AGENT_MESSAGES: {
            const { conversationKey, offset, limit } = action.reducerParams;
            const messageState = state[conversationKey] || {
                isLoading: false,
                list: []
            };
            const list = offset === 0 ? [] : messageState.list;
            if (action.status === 'request') {
                return {
                    ...state,
                    [conversationKey]: {
                        ...messageState,
                        isLoading: true,
                        error: undefined,
                        hasMore: true
                    }
                };
            }
            if (action.status === 'success') {
                const { result } = action.payload;
                return {
                    ...state,
                    [conversationKey]: {
                        ...messageState,
                        isLoading: false,
                        list: offset === list.length ? list.concat(result) : list,
                        error: undefined,
                        hasMore: result.length === limit
                    }
                };
            }
            if (action.status === 'error') {
                const { error } = action;
                return {
                    ...state,
                    [conversationKey]: {
                        ...messageState,
                        isLoading: false,
                        error
                    }
                };
            }
            break;
        }
        case ActionTypes.POST_MESSAGE:
            return parsePostMessageAction(state, action);
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default messages;
