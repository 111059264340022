import ConnectedAccounts from 'components/ConnectedAccounts/ConnectedAccountsView';
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import MyAgentSettingsBankView from 'scenes/MyAgent/components/MyAgentSettingsBankView';
import { Button, Grid, Header } from 'semantic-ui-react';

import MyStoreSettingsForm from './components/MyStoreSettingsForm';

const MyStoreSettingsView = () => (
    <Grid style={{ marginLeft: '45px', marginRight: '45px' }} stackable>
        <Grid.Column>
            <Header as='h1'>
                <Trans>Account Settings</Trans>
            </Header>
        </Grid.Column>
        <Grid.Row>
            <Grid.Column computer={5}>
                <ConnectedAccounts />
            </Grid.Column>
            <Grid.Column computer={5}>
                <MyStoreSettingsForm />
            </Grid.Column>
            <Grid.Column computer={6}>
                <MyAgentSettingsBankView />
            </Grid.Column>
            <Grid.Column computer={16}>
                <Button color='black' floated='right'>
                    Save changes
                </Button>
            </Grid.Column>
        </Grid.Row>
    </Grid>
);

export default withTranslation('translations')(MyStoreSettingsView);
