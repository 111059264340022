// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadPromotionsParams = {
    limit: number,
    offset: number
};

// LOAD_PROMOTIONS ----------------------
export const LOAD_PROMOTIONS = 'LOAD_PROMOTIONS';
export const loadPromotions = wrapAPIActionCreator(({ limit = 10, offset = 0 }: LoadPromotionsParams) => ({
    [API_DATA]: {
        type: LOAD_PROMOTIONS,
        endpoint: '/v3/web/tabs/campaigns',
        endpointParams: { limit, offset },
        authentication: Authentication.User,
        method: 'GET',
        schema: [Schemas.message],
        reducerParams: { limit, offset }
    }
}));
