// @flow

// UPDATE_FORM_VALUES ------------------
export type UpdateFormValuesParams = {
    formId: number,
    formValues?: any
};

export const UPDATE_FORM_VALUES = 'UPDATE_FORM_VALUES';
export const updateFormValues = ({ formId, formValues = {} }: UpdateFormValuesParams) => ({
    type: UPDATE_FORM_VALUES,
    payload: {
        formId,
        formValues
    }
});

export default updateFormValues;
