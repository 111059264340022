// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getIsLoggedIn } from 'store/utilities';
import SignUpSceneView from './SignUpSceneView';

type Props = {
    isLoggedIn: boolean
};

class SignUpSceneContainer extends Component<Props> {
    render() {
        return <SignUpSceneView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state)
});

export default compose(connect(mapStateToProps))(SignUpSceneContainer);
