// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

type Props = {};

/** 'remove item' button of a CartList item */
function RemoveButton({ ...rest }: Props) {
    return (
        <Button basic icon labelPosition='left' {...rest}>
            <Icon name='remove' />
            <Trans i18nKey='cartProducts.remove'>Remove</Trans>
        </Button>
    );
}

export default RemoveButton;
