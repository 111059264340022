// @flow
import React, { Component } from 'react';
import { Button, Grid, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import DeliveryAddressList from './components/DeliveryAddressList';
import DeliveryAddressForm from './components/DeliveryAddressList/components/DeliveryAddressListItem/DeliveryAddressForm';

type Props = {
    showCartSelector?: boolean,
    addresses: number[],
    selectedAddress: number,
    getUserDeliveryAddress: (number) => Object,
    setCartAddress: (number) => void,
    onOpenAddAddress: () => void,
    onOpenEditAddress: () => void
};

class DeliveryAddressView extends Component<Props> {
    static defaultProps;

    handleChange = (event, radio) => {
        const { value } = radio;
        const { setCartAddress } = this.props;
        setCartAddress({ addressId: value });
    };

    render() {
        const { showCartSelector, onOpenAddAddress, onOpenEditAddress, addresses = [], getUserDeliveryAddress, selectedAddress } = this.props;
        return (
            <div>
                <Grid className='tensed'>
                    <Grid.Row>
                        <Grid.Column>
                            <DeliveryAddressList
                                list={addresses}
                                showCartSelector={showCartSelector}
                                getUserDeliveryAddress={getUserDeliveryAddress}
                                selected={selectedAddress}
                                handleChange={this.handleChange}
                                onOpenEditAddress={onOpenEditAddress}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button type='button' style={{ color: 'black', fontWeight: 'bold' }} onClick={onOpenAddAddress}>
                                <Trans i18nKey='deliveryAddress.add.trigger'>Add New Address</Trans>
                            </Button>
                            {/* <DeliveryAddressForm /> */}
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        );
    }
}

DeliveryAddressView.defaultProps = {
    showCartSelector: false
};

export default withTranslation('translations')(DeliveryAddressView);
