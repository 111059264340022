// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import ProductDetailListView from './ProductDetailListView';

function ProductDetailListContainer(props) {
    return <ProductDetailListView {...props} />;
}

export default ProductDetailListContainer;
