// @flow
import { API_DATA, Authentication, Schemas, wrapAPIActionCreator } from 'services/api';

export type LoadLandingPageParams = {
    slug: string,
    channel?: string,
    categoryId?: number,
    bazar?: string
};

export const LOAD_LANDING_PAGE = 'LOAD_LANDING_PAGE';
export const loadLandingPage = wrapAPIActionCreator(({ slug, channel = 'web', categoryId = null, bazar = '' }: LoadLandingPageParams) => ({
    [API_DATA]: {
        type: LOAD_LANDING_PAGE,
        endpoint: '/v3/landing_page',
        endpointParams: { slug, displayChannel: channel, categoryId, bazar },
        schema: {
            landingPage: Schemas.landingPage
        },
        optionalAuthentication: Authentication.User,
        postprocess: (res) => ({ ...res, slug, channel })
    }
}));
