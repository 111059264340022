// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';
import { Trans, translate } from 'react-i18next';

const FollowButtonView = ({ isLoggedIn, isFollowing, onFollow, followId, isLoading, currentUser, isNonTempUser }) => {
    if (!isLoggedIn || process.env.REACT_APP_FEATURE_FOLLOW !== 'true' || !currentUser || currentUser.id === followId) {
        return null;
    }
    console.log('isnontmp', isNonTempUser)

    return (
        <Button disabled={!isNonTempUser}  color='black' loading={isLoading} basic={isFollowing} onClick={() => onFollow(!isFollowing)}>
            {isFollowing ? <Trans i18nKey='store.tab.following'>Following</Trans> : <Trans i18nKey='store.actions.follow'>Follow</Trans>}
        </Button>
    );
};

export default withTranslation('translations')(FollowButtonView);
