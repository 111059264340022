// @flow
/** Rename properties in `obj` according to `mapping` (mapping new keys to old). */
export function renameProperties(obj, mapping) {
    const res = { ...obj };
    Object.keys(mapping).forEach((to) => {
        const from = mapping[to];
        res[to] = res[from];
        delete res[from];
    });
    return res;
}

export function isObject(any) {
    return typeof any === 'object' && any != null;
}

/** Perform a POST request to the given URL, with the specified parameters.
 *  e.g. navigatePost('/some/path', { body: { lorem: 'ipsum', another: 123 } });
 */
export function navigatePost(url, opts) {
    const { body = {} } = opts;

    const form = document.createElement('form');
    form.action = url;
    form.method = 'POST';

    Object.keys(body).forEach((key) => {
        const value = body[key];
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = isObject(value) ? JSON.stringify(value) : String(value);
        form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
}

/** Serializes a file to base64, asyncronously */
export function serializeFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (ev) => resolve(ev.target.result);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
    });
}

const identity = (x) => x;

/** Parses a query string of the form 'foo=1&bar=baz' into an object.  The
 *  optional `parseFunctions` lets you define functions for specific keys
 *  which transform the corresponding value before returning it.
 */
export function parseQueryString(str, parseFunctions = {}) {
    const res = {};
    str.trim()
        .replace(/^\?/, '')
        .split('&')
        .forEach((pair) => {
            const index = pair.indexOf('=');
            const key = pair.slice(0, index);
            const value = decodeURIComponent(pair.slice(index + 1)).replace(/\+/g, ' ');
            res[key] = (parseFunctions[key] || identity)(value);
        });
    return res;
}

export function formatICNumber(input = '') {
    const numbersOnly = /^\d+$/;
    const filtered = input
        .trim()
        .split('')
        .filter((c) => numbersOnly.test(c));
    if (filtered.length > 8) {
        filtered.splice(8, 0, '-');
    }
    if (filtered.length > 6) {
        filtered.splice(6, 0, '-');
    }
    if (filtered.length > 14) {
        filtered.splice(14);
    }
    return filtered.join('');
}

/** Find the ranges ({ from, length }) of all substrings `substrings` occurring in `string`. */
export function findSubstringRanges(string: string, substrings: string[]) {
    const ranges = [];
    substrings.forEach((term) => {
        let fromIndex = 0;
        while (fromIndex < string.length && fromIndex !== -1) {
            fromIndex = string.indexOf(term, fromIndex);
            if (fromIndex !== -1) {
                ranges.push({ from: fromIndex, length: term.length });
                fromIndex += 1;
            }
        }
    });
    const compareFrom = (a, b) => a.from - b.from;
    return ranges.sort(compareFrom);
}

/** Debounces a function with the given delay */
export function debounce<T, U>(fn: (...T) => U, delay: number) {
    let timeout = null;
    return function debouncedFunction(...args: T) {
        if (timeout != null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => fn.apply(this, args), delay);
    };
}
