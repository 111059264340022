// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getItem, getUser } from 'store/utilities';
import type { EntityData, Item } from 'store/types';

import ProductTeaserView from './ProductTeaserView';

type Props = {
    // from store
    item: EntityData<Item>
};

function ProductTeaserContainer(props: Props) {
    return <ProductTeaserView {...props} />;
}

const mapStateToProps = (state, { itemId }) => {
    const item = getItem(state, itemId);
    const user = getUser(state, item.entity.userId);

    return { item, user };
};

export default compose(connect(mapStateToProps))(ProductTeaserContainer);
