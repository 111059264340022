// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateCartItemParams = {
    cartItemId: number,
    quantity?: number,
    delivery?: number,
    payment?: number,
    forStep?: number
};

export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const updateCartItem = wrapAPIActionCreator(({ cartItemId, quantity, delivery, payment, forStep }: UpdateCartItemParams = {}) => ({
    [API_DATA]: {
        type: UPDATE_CART_ITEM,
        endpoint: `/v3/cart_items/${cartItemId}`,
        method: 'PUT',
        authentication: Authentication.User,
        body: {
            quantity,
            delivery,
            payment,
            forStep
        },
        schema: {
            updatedCartItems: [Schemas.cartItem]
        }
    }
}));

export default updateCartItem;
