// @flow
import React, { Component } from 'react';
import type { User } from 'store/types';
import { connect } from 'react-redux';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import LikesTabView from './LikesTabView';

type Props = {
    user: User
};

class LikesTabContainer extends Component<Props> {
    componentDidMount() {
        const { likes, user } = this.props;
        if (!likes) {
            this.load(user.id, 0);
        }
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        const { user } = this.props;
        if (user && props.user && user.totalItemsLiked !== props.user.totalItemsLiked) {
            this.load(user.id, 0);
        }
    }

    load = (userId: number, offset: number) => {
        this.props
            .loadLikes({
                userId,
                offset
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    loadMore = (calculations = { bottomVisible: true }) => {
        const { user, likes } = this.props.user;

        if (user && likes.items && !likes.isLoading) {
            if (calculations.bottomVisible && likes.items.length < user.totalItemsLiked) {
                this.loadLikes(user.id, likes.items.length);
            }
        }
    };

    render() {
        const { likes } = this.props;
        if (!likes) {
            return null;
        }

        return <LikesTabView user={this.props.user} loadMore={(calculations) => this.loadMore(calculations)} likes={likes} />;
    }
}

const mapStateToProps = (state, { user }) => ({
    likes: state.likes[user.id]
});

const mapDispatchToProps = (dispatch) => ({
    loadLikes: wrapAPIDispatch(dispatch, actions.loadLikes)
});

export default connect(mapStateToProps, mapDispatchToProps)(LikesTabContainer);
