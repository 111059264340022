// @flow
import * as ActionTypes from 'store/actions';

const initialState = {
    hasMoreItems: true
};

function itemsByKeyword(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_ITEMS_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state,
                    itemsByKeywordIsLoading: true
                };
            }
            if (action.status === 'success') {
                const { keyword, offset, result } = action.payload;
                const { items } = result;
                const { sort, limit } = action.reducerParams;
                let existingItems = [];
                let hasMoreItems = true;

                if (state[keyword]) {
                    if (state[keyword].sort === sort) {
                        existingItems = [...state[keyword].existingItems];
                    }
                }

                if (offset === existingItems.length) {
                    existingItems = existingItems.concat(items);
                }

                if (items.length !== limit) {
                    hasMoreItems = false;
                }

                return {
                    ...state,
                    [keyword]: { sort, existingItems },
                    hasMoreItems,
                    itemsByKeywordIsLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    itemsByKeywordIsLoading: false
                };
            }
            break;
        default:
            break;
    }
    return state;
}

export default itemsByKeyword;
