// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn, getUser } from 'store/utilities';

import OrderDetails from 'scenes/OrderDetails';
import MyProfileView from './MyProfileView';

import MyProfileInboxView from './scenes/MyProfileInbox';
import MyProfilePromotionsView from './scenes/MyProfilePromotions';
import MyProfileSettingsContainer from './scenes/MyProfileSettings/MyProfileSettingsContainer';
import OrderByStore from './scenes/PurchasesByOrder';
import Purchases from './scenes/Purchases';
import PurchasesFromMail from './scenes/PurchasesFromMail';

type Props = {
    isLoggedIn: boolean,
    isLoadingAccounts: boolean,
    loadMyAccounts: typeof actions.loadMyAccounts
};

function loadData({ loadMyAccounts }: Props) {
    return loadMyAccounts();
}

class MyProfileContainer extends Component<Props> {
    state = {
        isLoadingAccounts: true
    };

    componentDidMount() {
        setTimeout(() => {
            const { isLoggedIn, userId, isLoadingAccounts } = this.props;
            if (!isLoggedIn || !userId) {
                this.setState({ isLoadingAccounts: false });
                return;
            }

            if (!isLoadingAccounts) {
                loadData(this.props)
                    .then(() => this.setState({ isLoadingAccounts: false }))
                    .catch(() => this.setState({ isLoadingAccounts: false }));
            }
        }, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user && this.state.isLoadingAccounts) {
            this.setState({ isLoadingAccounts: false });
        }
    }

    render() {
        const { isLoggedIn, user, location } = this.props;
        if (!this.state.isLoadingAccounts) {
            if (isLoggedIn && user) {
                return (
                    <Switch>
                        <Route exact path='/my-profile/purchases/cart/:cartId' render={() => <PurchasesFromMail />} />
                        <Route exact path='/my-profile' render={() => <MyProfileView {...this.props} />} />
                        <Route path='/my-profile/settings' render={() => <MyProfileSettingsContainer />} />
                        <Route path='/my-profile/inbox' render={() => <MyProfileInboxView {...this.props} />} />
                        <Route path='/my-profile/promotions' render={() => <MyProfilePromotionsView {...this.props} />} />
                        <Route path='/my-profile/purchases/order/:cartId/:storeId' render={() => <OrderByStore />} />
                        <Route path='/my-profile/purchases/:orderId' render={(matchProps) => <OrderDetails {...this.props} type='purchase' {...matchProps} />} />
                        <Route path='/my-profile/purchases' render={() => <Purchases />} />
                    </Switch>
                );
            }
            return <Redirect to={{ pathname: '/login', state: { from: location.pathname } }} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: getIsLoggedIn(state),
        userId: state.userData.user,
        user: getUser(state, state.userData.user).entity,
        isLoadingAccounts: state.userData.isLoadingAccounts
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);
