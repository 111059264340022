const API_DATA = 'Sym:MyBazar API Data';
const Authentication = {
    User: 'user',
    Merchant: 'merchant',
    Agent: 'agent'
};

// declare type SuccessCallback = () => void;
// declare type ErrorCallback = (Error) => void;

export { API_DATA, Authentication };
