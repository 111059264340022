// @flow
import React from 'react';
// import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { useLocation } from 'react-router-dom';

import withNavigationAid from 'utilities/withNavigationAid';
// import type { NavigationAid } from 'utilities/withNavigationAid';

import SubBarMain from './components/SubBarMain';
// import SubBarMyProfile from './components/SubBarMyProfile';
// import SubBarMyStore from './components/SubBarMyStore';

function SubBar({ showPromo, asDropdown }) {
    const location = useLocation();

    return <SubBarMain showPromo={showPromo} path={location.pathname} asDropdown={asDropdown} />;
    //return (
    //    <ConnectedRouter history={history}>
    //        <SubBarMain showPromo={showPromo} path={location.pathname} asDropdown={asDropdown} />
    /*{/* <Switch>
                {/* TODO: remove /blee case, rely on the base case below instead. * /}
                <Route path='/blee' render={() => <SubBarBlee asDropdown={asDropdown} />} />

                {/* <Route path='/my-profile' component={SubBarMyProfile} /> * /}
                {/* <Route path='/my-store' component={SubBarMyStore} /> * /}
                <Route
                    render={(props) =>
                        navigationAid.collection != null ? (
                            <SubBarBazar navigationAid={navigationAid} path={props.match.path} asDropdown={asDropdown} />
                        ) : (
                            <SubBarMain showPromo={showPromo} path={props.match.path} asDropdown={asDropdown} />
                        )
                    }
                />
            </Switch> */ //}
    //    </ConnectedRouter>
    //);
}

export default compose(withNavigationAid)(SubBar);
