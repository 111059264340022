// @flow
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Container, Grid, Icon, Menu } from 'semantic-ui-react';

import SearchInput from './components/SearchInput';
import TopBarLogo from './components/TopBarLogo';
import TopBarCart from './components/TopBarCart';
import SubBar from '../SubBar';
import Users from './components/User';

function TopBar({ isLoggedIn, user, merchant, quantity, onLogout, showPromo, openModal, totalUnreadMessageUser, totalUnreadMessageMerchant }) {
    const activeProfile = localStorage.getItem('activeProfile');
    const totalUnreadMessage = isLoggedIn ? (activeProfile === 'Merchant' ? totalUnreadMessageMerchant : totalUnreadMessageUser) : 0;
    const [isSearchActive, setIsSearchActive] = useState(false);
    return (
        <Menu className={`top-bar ${isSearchActive ? 'white-background' : ''}${showPromo ? 'top-bar-promo' : ''}`} fixed='top' inverted borderless>
            <Container fluid>
                {isSearchActive && (
                    <Grid className='main-grid top-bar-grid'>
                        <Grid.Column className='tob-bar-column'>
                            <SearchInput isSearchActive={isSearchActive} onCloseSearch={() => setIsSearchActive(false)} />
                        </Grid.Column>
                    </Grid>
                )}
                {!isSearchActive && (
                    <Grid className='main-grid top-bar-grid'>
                        <Grid.Column mobile={4} tablet={4} computer={6} className='tob-bar-column'>
                            <Grid className='top-bar-grid'>
                                <Grid.Column mobile={16} tablet={16} computer={3} className='tob-bar-column'>
                                    <Menu.Menu className='logo-item'>
                                        <TopBarLogo />
                                    </Menu.Menu>
                                </Grid.Column>
                                <Grid.Column computer={13} only='computer' className='tob-bar-column'>
                                    <SubBar showPromo={showPromo} asDropdown={false} />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column mobile={12} tablet={12} computer={10} textAlign='right' className='tob-bar-column'>
                            <Menu.Menu position='right' className='icons-right-section'>
                                <Menu.Item name='search' position='right' active={false} onClick={() => setIsSearchActive(true)} className='top-bar-right-icons'>
                                    <Icon className='top-bar-right-icons' name='search' size='small' />
                                </Menu.Item>

                                <Users user={user} merchant={merchant} onLogout={onLogout} isLoggedIn={isLoggedIn} totalUnreadMessage={totalUnreadMessage} />
                                <TopBarCart quantity={quantity} isLoggedIn={isLoggedIn} openModal={openModal} />
                            </Menu.Menu>
                        </Grid.Column>
                    </Grid>
                )}
            </Container>
        </Menu>
    );
}

export default withRouter(TopBar);
