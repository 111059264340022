// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withTranslation } from 'react-i18next';
import { Container } from 'semantic-ui-react';

import LogIn from '../../components/LogIn';
import onLogin from '../../common/onLogin';

type Props = {
    navigateTo: typeof push
};

class LogInScene extends Component<Props> {
    handleSuccess = () => {
        const { navigateTo, location } = this.props;
        onLogin();
        const { state } = location;
        const from = state ? state.from : '/select-profile';
        navigateTo(from);
    };

    render() {
        return (
            <Container className='login-container'>
                <LogIn onSuccess={this.handleSuccess} />
            </Container>
        );
    }
}

export default compose(connect(null, { navigateTo: push }), withTranslation('translations'))(LogInScene);
