// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_BANK_ACCOUNTS --------------------------------

export const LOAD_BANK_ACCOUNTS = 'LOAD_BANK_ACCOUNTS';
export const loadBankAccounts = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_BANK_ACCOUNTS,
        endpoint: '/v3/me/bank/accounts',
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            bankAccounts: [Schemas.bankAccount]
        }
    }
}));
