// @flow
import * as ActionTypes from 'store/actions';

export default function countries(state = [], action) {
    switch (action.type) {
        case ActionTypes.LOAD_COUNTRIES:
            if (action.status === 'success') {
                const { result } = action.payload;
                return result.countries;
            }
            break;
        default:
        // do nothing
    }
    return state;
}
