// @flow
import React from 'react';
import { Grid, List, Header, Loader, Button, Icon, Card, Image } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPrice } from 'utilities/format';

const MyAgentOfficeView = () => {
    // const activityLoaded = activity.newFollowersCount !== undefined;
    const registeredStores = [0, 0, 0, 0];
    const products = [0, 0, 0, 0, 0, 0];

    return (
        <Grid className='agent-office-wrap'>
            <Grid.Column computer={16}>
                <Header as='h2' size='large'>
                    Agent Office ID: ABC123
                </Header>
            </Grid.Column>
            <Grid.Column computer={16}>
                <Button floated='left' style={{ color: 'black' }}>
                    <Icon name='calendar' />
                    Last 90 days
                </Button>
                <Button floated='right' color='black'>
                    Agent support
                </Button>
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2'>
                    <Trans>Sales</Trans>
                </Header>
                {/* {
          activityLoaded ? */}
                <List>
                    <List.Item>
                        {/* {activity.newMessagesCount} */}
                        <Header as='h3'>
                            <Trans>Balance: </Trans>RM 3,000.90
                        </Header>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Total sales: </Trans> RM 5,321.90
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Your next payour is estimated for 1 April.</Trans>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px' }}>
                        <Trans>Paid 1 March, 2021: RM 1,321.00</Trans>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px' }}>
                        <Trans>Paid 1 February, 2021: RM 1,000.00</Trans>
                    </List.Item>
                </List>
                {/* : */}

                {/* } */}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2'>
                    <Trans>Customers</Trans>
                </Header>
                {/* {
          activityLoaded ? */}
                <List>
                    <List.Item>
                        {/* {activity.newMessagesCount} */}
                        <Header as='h3'>
                            <Trans>Total sessions: </Trans>2,389
                        </Header>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Returning customer rate: </Trans>21,33 %
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>New customers: </Trans> 76
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Total followers: </Trans> 23,987
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>New followers: </Trans>25
                    </List.Item>
                </List>
                {/* : */}

                {/* } */}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2'>
                    <Trans>Orders</Trans>
                </Header>
                {/* {
          activityLoaded ? */}
                <List>
                    <List.Item>
                        {/* {activity.newMessagesCount} */}
                        <Header as='h3'>
                            <Trans>Total orders: </Trans>98
                        </Header>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Average order value: </Trans>RM 116.00
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Open: </Trans>5
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Closed: </Trans>768
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Cancelled: </Trans>12
                    </List.Item>
                </List>
                {/* : */}

                {/* } */}
            </Grid.Column>
            <Grid.Column mobile={8} tablet={4} computer={4}>
                <Header as='h2'>
                    <Trans>Products</Trans>
                </Header>
                {/* {
          activityLoaded ? */}
                <List>
                    <List.Item>
                        {/* {activity.newMessagesCount} */}
                        <Header as='h3'>
                            <Trans>Total products: </Trans>1,239
                        </Header>
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Most sold out product: </Trans>Ribbed Merino Sock
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Active: </Trans>998
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Draft: </Trans>14
                    </List.Item>
                    <List.Item style={{ fontSize: '15px', fontWeight: 500 }}>
                        <Trans>Sold out: </Trans>457
                    </List.Item>
                </List>
                {/* : */}

                {/* } */}
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
                <Card style={{ width: '100%', height: '35rem', padding: '1.5rem', overflowY: 'scroll' }}>
                    <Card.Header>
                        <Header as='h3' size='large'>
                            Registered Stores
                        </Header>
                    </Card.Header>
                    <p>Show best selling stores.</p>
                    <Grid style={{ marginTop: '10px' }}>
                        {registeredStores.map((store) => {
                            return (
                                <>
                                    <Grid.Column computer={10}>
                                        <Header>SimplySiti</Header>
                                        <p style={{ marginBottom: 0 }}>Melawati Mall, Unit No, GF-10</p>
                                        <p style={{ marginBottom: 0 }}>Jalan Bandar Melawati 355</p>
                                        <p style={{ marginBottom: 0 }}>53100 Kuala Lumpur</p>
                                        <p style={{ marginBottom: 0 }}>Malasyia</p>
                                        <p style={{ marginBottom: 0 }}>+60 73 524 20 08</p>
                                    </Grid.Column>
                                    <Grid.Column computer={6}>
                                        <Button floated='right' style={{ color: 'black' }}>
                                            View store
                                        </Button>
                                    </Grid.Column>
                                </>
                            );
                        })}

                        <Grid.Column computer={16}>
                            <Button style={{ color: 'black' }}>Add Store</Button>
                        </Grid.Column>
                    </Grid>
                </Card>
            </Grid.Column>
            <Grid.Column computer={5} tablet={8} mobile={16}>
                <Card style={{ width: '100%', height: '35rem', padding: '1.5rem', overflowY: 'scroll' }}>
                    <Card.Header>
                        <Header as='h3' size='large' style={{ marginBottom: '40px' }}>
                            Best Selling Products
                        </Header>
                    </Card.Header>
                    {products.map((product) => {
                        return (
                            <Grid>
                                <Grid.Row>
                                    <Grid.Column computer={3}>
                                        <Image
                                            size='medium'
                                            src='https://media.istockphoto.com/vectors/image-preview-icon-picture-placeholder-for-website-or-uiux-design-vector-id1222357475?k=6&m=1222357475&s=612x612&w=0&h=p8Qv0TLeMRxaES5FNfb09jK3QkJrttINH2ogIBXZg-c='
                                        />
                                    </Grid.Column>
                                    <Grid.Column computer={13} style={{ paddingLeft: 0 }}>
                                        <p style={{ fontWeight: 500 }}>Gold Edition Cushion Foundation Light Babybazar</p>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                            <p style={{ marginRight: '5px', fontWeight: 'bold' }}>RM 20.00</p>
                                            <p style={{ marginRight: '5px', fontWeight: 400, textDecoration: 'line-through' }}>RM 64.90.00</p>
                                            <p style={{ marginRight: '5px', color: 'red', fontWeight: 'bold' }}>60%</p>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        );
                    })}
                </Card>
            </Grid.Column>
            <Grid.Column computer={6} tablet={8} mobile={16}>
                <Card style={{ width: '100%', height: '35rem' }}>
                    <Image src='https://assets.materialup.com/uploads/35484a7f-dfd7-4852-aaca-0c14ab02978b/preview.jpg' style={{ height: '35rem' }} />
                </Card>
            </Grid.Column>
        </Grid>
    );
};

export default withTranslation('translations')(MyAgentOfficeView);
