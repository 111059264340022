// @flow
import React from 'react';
import { Button, Form, Modal, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';

const createFormValues = ({ bankAccount = {} }) => {
    const {
        accountHolder = '',
        accountNo = '',
        bankAddress = '',
        bankName = '',
        postCode: postalCode = '',
        countryId: country = '',
        stateId: state = '',
        cityId: city = ''
    } = bankAccount;

    return {
        accountHolder: createEmptyFormFieldData({ value: accountHolder, required: true }),
        accountNo: createEmptyFormFieldData({ value: accountNo, required: true }),
        bankAddress: createEmptyFormFieldData({ value: bankAddress, required: true }),
        bankName: createEmptyFormFieldData({ value: bankName, required: true }),
        postalCode: createEmptyFormFieldData({ value: postalCode, required: true }),
        country: createEmptyFormFieldData({ value: country, type: 'number', required: true }),
        state: createEmptyFormFieldData({ value: state, type: 'number', required: true }),
        city: createEmptyFormFieldData({ value: city, type: 'number', required: true })
    };
};

class BankAccountModalView extends CommonForm {
    constructor(props) {
        super(props);
        const { bankAccount = {} } = props;

        const formValues = createFormValues({ bankAccount });
        this.state = {
            formValues,
            errorMessage: null,
            isPosting: false,
            deleteModalOpen: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { bankAccount: nextBankAccount } = nextProps;
        const { bankAccount: currentBankAccount } = this.props;
        if (nextBankAccount) {
            if (!currentBankAccount || nextBankAccount.id !== currentBankAccount.id) {
                const { updateFormValues, formId } = this.props;
                const formValues = createFormValues({ bankAccount: nextBankAccount });

                this.setState(
                    {
                        formValues,
                        errorMessage: null,
                        deleteModalOpen: false,
                        deleteErrorMessage: null,
                        isPosting: false
                    },
                    () => {
                        updateFormValues({ formValues, formId });
                    }
                );
            }
        }
    }

    handleCountryChange = (event, data) => {
        const { onCountryChange } = this.props;
        this.handleChange(event, data);
        onCountryChange(data);
    };

    handleStateChange = (event, data) => {
        const { formValues } = this.state;
        formValues.city.value = '';
        this.setState({ formValues }, () => {
            this.handleChange(event, data);
        });
    };

    handleSubmit = () => {
        const { bankAccount, onSubmit } = this.props;
        if (!this.validate()) {
            return;
        }
        const { formValues } = this.state;

        const {
            accountHolder: { value: accountHolder },
            accountNo: { value: accountNo },
            bankName: { value: bankName },
            bankAddress: { value: bankAddress },
            postalCode: { value: postalCode },
            country: { value: country },
            state: { value: state },
            city: { value: city }
        } = formValues;

        this.setState({ isPosting: true });

        onSubmit({
            id: bankAccount?.id,
            accountHolder,
            accountNo,
            bankName,
            bankAddress,
            postalCode,
            country,
            state,
            city,
            isPrimary: 0
        })
            .then(() => {
                this.setState({
                    isPosting: false
                });
                this.handleClose();
            })
            .catch((err) => this.setState({ isPosting: false, errorMessage: err.message }));
    };

    handleDelete = () => {
        const { onDelete, bankAccount } = this.props;

        if (onDelete) {
            onDelete({ id: bankAccount?.id })
                .then(() => this.handleClose())
                .catch((error) => this.setState({ deleteErrorMessage: error.message }));
        }
    };

    handleClose = () => {
        const { updateFormValues, formId, onClose } = this.props;
        const formValues = createFormValues({});

        this.setState({ formValues, deleteModalOpen: false });
        updateFormValues({ formValues, formId });
        onClose();
    };

    render() {
        const {
            isOpen,
            title,
            cancelButtonLabel,
            acceptButtonLabel,
            deleteButtonLabel,
            confirmDeleteLabel,
            // from store
            countries,
            addressStates,
            getCountry,
            getState,
            getCity
        } = this.props;

        const { formValues, errorMessage, isPosting, deleteModalOpen, deleteErrorMessage } = this.state;

        const {
            country: { value: countryId },
            state: { value: stateId }
        } = formValues;

        const countryStates = addressStates[countryId] || {};
        const statesOptions = countryId && countryStates && countryStates.list ? countryStates.list : [];
        const selectedState = getState(stateId);

        const citiesOptions = selectedState.entity ? selectedState.entity.cities : [];

        return (
            <Modal size='tiny' open={isOpen} onClose={this.handleClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Form error={errorMessage !== null}>
                        <Message error>{errorMessage}</Message>
                        {this.renderInputField({ name: 'bankName', type: 'text' }, this.props)}
                        {this.renderInputField({ name: 'accountHolder', type: 'text' }, this.props)}
                        {this.renderInputField({ name: 'accountNo', type: 'text' }, this.props)}
                        {this.renderInputField({ name: 'bankAddress', type: 'text' }, this.props)}
                        {this.renderInputField({ name: 'postalCode', type: 'text' }, this.props)}

                        {this.renderDropdownField(
                            {
                                name: 'country',
                                options: countries.map(getCountry).map(({ entity }) => ({ text: entity.name, value: entity.id })),
                                onChange: this.handleCountryChange
                            },
                            this.props
                        )}

                        {this.renderDropdownField(
                            {
                                name: 'state',
                                options: statesOptions.map(getState).map(({ entity }) => ({ text: entity.district, value: entity.id })),
                                onChange: this.handleStateChange
                            },
                            this.props
                        )}

                        {this.renderDropdownField(
                            {
                                name: 'city',
                                options: citiesOptions.map(getCity).map(({ entity }) => ({ text: entity.name, value: entity.id }))
                            },
                            this.props
                        )}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={this.handleClose}>
                        {cancelButtonLabel}
                    </Button>
                    {deleteButtonLabel && (
                        <Modal
                            open={deleteModalOpen}
                            onClose={() => {
                                this.setState({ deleteModalOpen: false });
                            }}
                            size='tiny'
                            trigger={
                                <Button
                                    type='button'
                                    loading={isPosting}
                                    negative
                                    icon
                                    labelPosition='right'
                                    onClick={() => {
                                        this.setState({ deleteModalOpen: true });
                                    }}
                                >
                                    {deleteButtonLabel}
                                </Button>
                            }
                        >
                            <Modal.Content>
                                {confirmDeleteLabel}
                                {deleteErrorMessage && <Message error>{deleteErrorMessage}</Message>}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button
                                    type='button'
                                    basic
                                    onClick={() => {
                                        this.setState({ deleteModalOpen: false });
                                    }}
                                >
                                    {cancelButtonLabel}
                                </Button>
                                <Button type='button' loading={isPosting} negative icon labelPosition='right' onClick={this.handleDelete}>
                                    {deleteButtonLabel}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    )}
                    <Button type='button' loading={isPosting} primary icon labelPosition='right' onClick={this.handleSubmit}>
                        {acceptButtonLabel}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

BankAccountModalView.defaultProps = {
    title: 'Bank Account',
    cancelButtonLabel: <Trans i18nKey='bankAccount.cancelButton'>Cancel</Trans>,
    acceptButtonLabel: <Trans i18nKey='bankAccount.acceptButton'>Accept</Trans>
};

export default withTranslation('translations')(BankAccountModalView);
