// @flow
import React, { useState } from 'react';
import { Grid, Header, Tab, Menu, Label, Visibility, Loader, Message, Image, Button, Popup, Dropdown, Rating } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { formatBigNumber } from 'utilities/format';
import shareIcon from '../../styles/assets/icons/share-square-solid.svg';
import ProductList from 'components/ProductList';
import ShareButtons from 'components/ShareButtons';
import FollowButton from 'components/FollowButton';
import FollowerTab from 'components/FollowerTab';
import FollowingTab from 'components/FollowingTab';
import { shortBy } from 'utilities/languages';
import StoreLogo from './components/StoreLogo';
import SortDropdown from '../SortDropdown';
import StoreCover from './components/StoreCover';
import Logo from '../../styles/assets/images/profile.png';
import Images from '../../styles/assets/images/images.png';

const Props = {
    merchant: {},
    loadMoreItems: () => {}
};
function Store({ merchant, loadMoreItems, storeItems, totalCount, itemsIsLoading, width }: Props) {
    const { coverPhotos, settings = {}, description, id, isFollowing, totalItemsLiked, totalFollowers, totalFollowing, openToCustomer } = merchant;
    const { stores } = settings;
    const hasMailingAddress = (stores && stores[0] && stores[0].mailingAddress) != null;
    const { city, state, zip, country } = (stores && stores[0] && settings.stores[0].mailingAddress) || {};
    const coverUrl = coverPhotos && coverPhotos.length > 0 ? coverPhotos[0].imageFull : null;
    const [activeTab, setActiveTab] = useState(0);
    const tabs = [
        {
            name: 'all-items',
            label: <Trans i18nKey='store.tab.products'>Products</Trans>,
            count: formatBigNumber(totalCount),
            renderElement: () => (
                <Tab.Pane attached={false} className='simple'>
                    <Visibility onUpdate={(e, { calculations }) => loadMoreItems(calculations)}>
                        <ProductList items={storeItems} />
                    </Visibility>
                </Tab.Pane>
            )
        },
        {
            name: 'followers',
            label: <Trans i18nKey='store.tab.followers'>Followers</Trans>,
            count: formatBigNumber(totalFollowers),
            renderElement: () => <FollowerTab user={merchant} />
        },

    ];
    const isMobile = width <= 500;
    const menuProps = { secondary: true };
    if (isMobile) {
        menuProps.widths = tabs.length;
    }
    let userData = JSON.parse(localStorage.getItem('user'));
    return (
        <Grid className='store'>
            <Grid.Row>
                <Grid.Column style={{ height: 350 }}>
                    <StoreCover coverUrl={coverUrl} />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row only='computer tablet' style={{ paddingLeft: 72, paddingRight: 68 }}>
                <div
                    style={{
                        border: '1px solid #E6E6E7',
                        width: '100%'
                    }}
                >
                    <Grid.Column className='profileres'>
                        <Grid className='auto boxShadow'>
                            <Grid.Column className='col-sm-16 col-shrink'>
                                <Image
                                    style={{ width: 140, borderRadius: '50%', border: '1px solid #F7F7F7', margin: '15px 0px 0px 15px', height: 140 }}
                                    fluid
                                    src={merchant.pictureThumb || Logo}
                                    onError={(event) => {
                                        event.target.src = missingStoreImage;
                                    }}
                                />
                            </Grid.Column>
                            <Grid.Column className='topSpace'>
                                <Grid.Column width={12}>
                                    <Header size='tiny' as='h1' style={{ paddingTop: 21 }}>
                                        {merchant.alias}
                                        <Rating style={{ paddingLeft: 5, display: 'none' }} icon='star' defaultRating={3} maxRating={4} />
                                        <Header.Subheader style={{ paddingTop: 10 }}>{description}</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Grid.Row
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            paddingTop: 25,
                                            paddingBottom: 15
                                        }}
                                    >
                                        <FollowButton followId={id} isFollowing={isFollowing} parentId={id} />
                                        <Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>
                                            <i className='tag icon' />
                                            {totalCount} <Trans i18nKey='store.tab.products'>Products</Trans>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>
                                            <i className='user icon' />
                                            {merchant.totalFollowers} <Trans i18nKey='store.tab.followers'>Followers</Trans>
                                        </Grid.Column>
                                        {/*<Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>*/}
                                        {/*    <i className='user icon' />*/}
                                        {/*    {merchant.totalFollowing} <Trans i18nKey='store.tab.following'>Following</Trans>*/}
                                        {/*</Grid.Column>*/}
                                        <div style={{ marginLeft: 'auto', paddingRight: 20 }}>
                                            <Popup
                                                trigger={
                                                    <Button size='small' color='black' icon>
                                                        <img style={{ paddingRight: 10, marginBottom: -2 }} src={shareIcon}></img>
                                                        <label style={{ fontSize: 14 }}>
                                                            <Trans i18nKey='store.actions.share'>Share</Trans>
                                                        </label>
                                                    </Button>
                                                }
                                                flowing
                                                hoverable
                                            >
                                                <ShareButtons></ShareButtons>
                                            </Popup>
                                        </div>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </div>
            </Grid.Row>

            <Grid.Row only='mobile'>
                <div
                    style={{
                        border: '1px solid #E6E6E7',
                        marginLeft: 30,
                        marginRight: 28,
                        width: '100%'
                    }}
                >
                    <Grid.Column style={{ paddingLeft: 10 }} className='profileres'>
                        <Grid className='auto boxShadow'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Image
                                        style={{ width: 140, borderRadius: '50%', border: '1px solid #F7F7F7', margin: '15px 0px 0px 15px' }}
                                        fluid
                                        src={merchant.pictureThumb || Logo}
                                        onError={(event) => {
                                            event.target.src = missingStoreImage;
                                        }}
                                    />
                                </Grid.Column>
                                <Grid.Column style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                    <div>
                                        <Popup
                                            style={{ width: 'min-content' }}
                                            trigger={
                                                <Button size='small' color='black' icon>
                                                    <img style={{ paddingRight: 10, marginBottom: -2 }} src={shareIcon}></img>
                                                    <label style={{ fontSize: 14 }}>
                                                        <Trans i18nKey='store.actions.share'>Share</Trans>
                                                    </label>
                                                </Button>
                                            }
                                        >
                                            <ShareButtons></ShareButtons>
                                        </Popup>
                                    </div>
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Column className='topSpace'>
                                <Grid.Column width={12}>
                                    <Header size='tiny' as='h1'>
                                        {merchant.alias}
                                        {/*<Rating style={{ paddingLeft: 5 }} icon='star' defaultRating={3} maxRating={4} />*/}
                                        <Header.Subheader style={{ paddingTop: 10 }}>{description}</Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Grid.Row
                                        style={{
                                            flexDirection: 'row',
                                            display: 'flex',
                                            paddingTop: 25,
                                            paddingBottom: 15
                                        }}
                                    >
                                        <FollowButton followId={id} isFollowing={isFollowing} parentId={id} />
                                        <Grid.Column style={{ marginLeft: 15 }}>
                                            <i className='tag icon' />
                                            {totalCount} <Trans i18nKey='store.tab.products'>Products</Trans>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginLeft: 15 }}>
                                            <i className='user icon' />
                                            {merchant.totalFollowers} <Trans i18nKey='store.tab.followers'>Followers</Trans>
                                        </Grid.Column>
                                        <Grid.Column style={{ marginLeft: 15 }}>
                                            <i className='user icon' />
                                            {merchant.totalFollowing} <Trans i18nKey='store.tab.following'>Following</Trans>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid.Column>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </div>
            </Grid.Row>

            <Grid.Row only='computer tablet' style={{ paddingLeft: 58, paddingRight: 55 }}>
                <Grid.Column>
                    <div
                        className='displayNone wrapcss'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <Tab
                                menu={menuProps}
                                panes={tabs.map((tab) => ({
                                    menuItem: (
                                        <Menu.Item key={tab.name}>
                                            {tab.label}
                                            <Label>{tab.count}</Label>
                                        </Menu.Item>
                                    ),
                                    render: () => tab.renderElement()
                                }))}
                                onTabChange={(e: any, tab) => setActiveTab(tab.activeIndex)}
                            />
                        </div>
                        <div style={{ width: 200, marginLeft: -200 }}>
                            {activeTab === 0 &&
                            <SortDropdown />
                            }
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row only='mobile' style={{ paddingLeft: 18, paddingRight: 18 }}>
                <Grid.Column>
                    <div style={{ width: 165, marginBottom: 10 }}>
                        {activeTab === 0 && <Dropdown fluid selection options={shortBy} defaultValue='Sortiraj po' placeholder='Sortiraj po' />}
                    </div>
                    <div
                        className='displayNone wrapcss'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ width: '100%' }}>
                            <Tab
                                menu={menuProps}
                                panes={tabs.map((tab) => ({
                                    menuItem: (
                                        <Menu.Item key={tab.name}>
                                            {tab.label}
                                            <Label>{tab.count}</Label>
                                        </Menu.Item>
                                    ),
                                    render: () => tab.renderElement()
                                }))}
                                onTabChange={(e: any, tab) => setActiveTab(tab.activeIndex)}
                            />
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
            {itemsIsLoading && <Loader active inline='centered' />}
        </Grid>
    );
}

export default withTranslation('translations')(Store);
