// @flow
import React from 'react';
import { Grid, Header, Dropdown } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import { formatPrice } from 'utilities/format';

type Props = {
    product: object
};

function ProductShipping({ product, t }: Props) {
    const { shippingDetails = [] } = product;
    const { merchantShippingDetail = {} } = shippingDetails.length > 0 ? shippingDetails[0] : {};
    const { shipFrom = {} } = merchantShippingDetail;
    const { mailingAddress = {} } = shipFrom;
    const { city, state, country } = mailingAddress;

    if (!product.shippingDetails.length) {
        return (
            <div>
                <Header as='h2' size='medium'>
                    <Trans i18nKey='product.shipping.notAvaiable.header'>Shipping</Trans>
                </Header>
                <p>
                    <Trans i18nKey='product.shipping.notAvaiable.body'>No details available.</Trans>
                </p>
            </div>
        );
    }
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2' size='medium'>
                        <Trans i18nKey='product.shipping.from.header'>Shipping From</Trans>
                    </Header>
                    <p>
                        {[city, state, country].filter(Boolean).join(', ')}
                        {'.'}
                    </p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2' size='medium'>
                        <Trans i18nKey='product.shipping.to.header'>Shipping To</Trans>
                    </Header>
                    <Dropdown
                        selection
                        fluid
                        defaultValue={product.shippingDetails.length ? product.shippingDetails[0].id : ''}
                        options={product.shippingDetails.map(({ id, merchantShippingDetail }) => {
                            const { estimatedDeliveryCost, shipTo, estimatedDeliveryTimeStart, estimatedDeliveryTimeEnd } = merchantShippingDetail;
                            return {
                                value: id,
                                text: `
                  ${formatPrice(parseFloat(estimatedDeliveryCost))},
                  ${t(`product.shipping.to.location.${shipTo.locationId}.name`, { defaultValue: shipTo.locationName })},
                  ${t('product.shipping.to.estimation', {
                      defaultValue: 'Est. delivery {{start}}-{{end}} days',
                      start: estimatedDeliveryTimeStart,
                      end: estimatedDeliveryTimeEnd
                  })}`
                            };
                        })}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default withTranslation('translations')(ProductShipping);
