// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { EntityData, User } from 'store/types';

import MyStoreOrdersView from './MyStoreOrdersView';

type Props = {
    merchant: EntityData<User>,
    loadOrders: typeof actions.loadOrders,
    orders: {
        isLoading: boolean,
        open: ?[],
        openCount: number,
        delivered: ?[],
        deliveredCount: number,
        cancelled: ?[],
        cancelledCount: number
    }
};

class MyStoreOrdersContainer extends Component<Props> {
    componentDidMount() {
        // TODO: Handle pagination
        this.props.loadOrders({ limit: 10, offset: 0 }).catch((err) => console.error(err));
    }

    loadMoreOrders = () => {
        const { orders, loadOrders } = this.props;
        const { isLoading, open, openCount, delivered, deliveredCount, cancelled, cancelledCount } = orders;

        if (!open || !delivered || !cancelled) {
            return;
        }
        const mostLoaded = Math.max(open.length, delivered.length, cancelled.length);
        const mostTotal = Math.max(openCount, deliveredCount, cancelledCount);
        if (!isLoading && mostTotal > mostLoaded) {
            loadOrders({ limit: 10, offset: mostLoaded });
        }
    };

    render() {
        return <MyStoreOrdersView loadMoreOrders={this.loadMoreOrders} {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    orders: state.merchantData.orders
});

const mapDispatchToProps = (dispatch) => ({
    loadOrders: wrapAPIDispatch(dispatch, actions.loadOrders)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreOrdersContainer);
