// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withNavigationAid from 'utilities/withNavigationAid';
import { getLandingComponent } from 'store/utilities';
import type { EntityData, LandingComponent } from 'store/types';

import LandingComponentView from './LandingComponentView';

type Props = {
    landingComponentId: number,
    // from route
    urlPrefix: string,
    // from store
    component: EntityData<LandingComponent>
};

function LandingContainer({ component, urlPrefix }: Props) {
    return <LandingComponentView {...{ component, urlPrefix }} />;
}

const mapStateToProps = (state, { landingComponentId, navigationAid }) => {
    const component = getLandingComponent(state, landingComponentId);
    return {
        component,
        urlPrefix: navigationAid.urlPrefix
    };
};

export default compose(withNavigationAid, connect(mapStateToProps))(LandingContainer);
