// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Image, Header } from 'semantic-ui-react';

import logo from 'styles/assets/images/MB_app_icon.png';
import { formatDatestamp } from '../../../../utilities/format';

const PromotionsMenuItemView = ({ item, user, t }) => {
    const message = item.entity;
    const sender = user.entity;
    return (
        <Grid className='auto tensed grid-align-items-center'>
            <Grid.Column className='col-sm-3 col-4'>
                <Image
                    className='circular-image'
                    wrapped
                    src={logo}
                    alt='logo'
                    onError={(event) => {
                        event.target.src = logo;
                    }}
                />
            </Grid.Column>
            <Grid.Column className='col-sm-13 col-12'>
                <Grid className='auto very-tensed'>
                    <Grid.Column className='col-row'>
                        <Header size='tiny' className='margin-0 line-height-1 text-ellipsis'>
                            {sender.alias}
                        </Header>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <div className='color-de-emphasized font-size-small line-height-1 text-ellipsis'>{formatDatestamp(message.createdAt, t)}</div>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <p className='line-height-1 text-ellipsis'>{message.message.startsWith('http') ? '' : message.message}</p>
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
};

export default withTranslation('translations')(PromotionsMenuItemView);
