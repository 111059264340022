// @flow
import { removeCookie } from '../../../utilities/cookies';

export const LOGOUT_USER = 'LOGOUT_USER';
export function logoutUser() {
    console.log('brisem cooke');
    removeCookie('accessToken');
    return {
        type: LOGOUT_USER
    };
}
