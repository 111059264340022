// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// UPDATE_BANK_ACCOUNT --------------------------------
export type UpdateBankAccountParams = {
    id: number,
    accountHolder: string,
    accountNo: string,
    bankAddress: string,
    bankName: string,
    isPrimary?: 0 | 1,
    postalCode: string,
    country: number,
    state: number,
    city: number
};

const excludeDecamelizeKeys = ['countryId', 'stateId', 'cityId'];

export const UPDATE_BANK_ACCOUNT = 'UPDATE_BANK_ACCOUNT';
export const updateBankAccount = wrapAPIActionCreator(
    ({ id, accountHolder, accountNo, bankAddress, bankName, isPrimary, postalCode, country, state, city }: UpdateBankAccountParams) => ({
        [API_DATA]: {
            type: UPDATE_BANK_ACCOUNT,
            endpoint: `/v3/me/bank/account/${id}`,
            authentication: Authentication.Merchant,
            method: 'PUT',
            body: {
                id,
                accountHolder,
                accountNo,
                bankAddress,
                bankName,
                isPrimary,
                postCode: postalCode,
                countryId: country,
                stateId: state,
                cityId: city
            },
            schema: {
                bankAccount: Schemas.bankAccount
            },
            decamelizeOptions: {
                process: (key, convert) => (excludeDecamelizeKeys.includes(key) ? key : convert(key))
            }
        }
    })
);
