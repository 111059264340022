// @flow
import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';

type Props = {
    sourceText: string,
    title: string
};

type State = {
    isLoading: boolean,
    height: number
};

class IframeView extends Component<Props, State> {
    state = {
        isLoading: false,
        height: 0
    };

    componentDidMount() {
        this.updateContent();
        window.addEventListener('resize', this.setHeight);
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (this.props.sourceText !== nextProps.sourceText) {
            this.updateContent(nextProps);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setHeight);
    }

    setStyles = () => {
        const styleSheets = [...document.styleSheets];
        const { contentDocument } = this.iframe;

        function promiseInjectHead(ownerNode) {
            const newNode = contentDocument.importNode(ownerNode, true);
            contentDocument.head.appendChild(newNode);
            return new Promise((resolve, reject) => {
                newNode.onload = () => resolve();
                newNode.onerror = (err) => reject(err);
            });
        }

        function createStyle(body) {
            const style = document.createElement('style');
            style.type = 'text/css';
            style.appendChild(document.createTextNode(body));
            return style;
        }

        const promiseDelay = (delay) => (...args) => new Promise((resolve) => setTimeout(() => resolve(...args), delay));

        const nodes = [...styleSheets.map(({ ownerNode }) => ownerNode), createStyle('body { min-width: 0; background-color: transparent; }')];

        return Promise.all(nodes.map(promiseInjectHead)).then(promiseDelay(200));
    };

    setHeight = () => {
        const container = this.iframe.contentDocument.querySelector('div');
        const height = container && container.scrollHeight;
        this.setState({ height });
    };

    iframe = null;

    updateContent = (props: Props = this.props) => {
        const { sourceText } = props;
        const { iframe } = this;

        iframe.contentDocument.open();
        iframe.contentDocument.write(`<div>${sourceText}</div>`);
        iframe.contentDocument.close();

        this.setHeight();

        this.setState({ isLoading: true });
        this.setStyles()
            .then(() => {
                this.setHeight();
                this.setState({ isLoading: false });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    handleRef = (ref) => {
        this.iframe = ref;
    };

    render() {
        const { sourceText, title, ...rest } = this.props;
        const { isLoading, height } = this.state;
        return (
            <div
                style={{
                    width: '100%',
                    height: `${height}px`
                }}
            >
                <Loader active={isLoading} />
                <iframe
                    ref={this.handleRef}
                    title={title}
                    frameBorder={0}
                    width='100%'
                    height='100%'
                    style={{
                        display: 'block',
                        visibility: isLoading ? 'hidden' : 'visible'
                    }}
                    {...rest}
                />
            </div>
        );
    }
}

export default IframeView;
