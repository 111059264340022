// @flow
import React from 'react';
import type { Node } from 'react';
import { compose } from 'redux';
import { Button, Form, Message, Modal, Dimmer, Loader } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Authentication } from 'services/api';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';
import CancelOrderStoreDescription from './components/CancelOrderStoreDescription';
import CancelOrderPurchaseDescription from './components/CancelOrderPurchaseDescription';

type FormParams = {
    message: string
};

type Props = {
    formId: string,
    isOpen: boolean,
    orderId: number,
    isSubmitting: boolean,
    errorMessage: ?string,
    errorOrderId: ?number,
    onClose: () => void,
    onSubmit: (FormParams) => Promise<*>,
    cancelButtonLabel?: Node,
    acceptButtonLabel?: Node
};

class OrderCancelModalView extends CommonForm<Props> {
    static defaultProps;

    state = {
        formValues: {
            message: createEmptyFormFieldData({
                value: '',
                type: 'string',
                required: true,
                error: false,
                rest: null
            })
        }
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        if (!this.validate()) {
            return;
        }

        const {
            message: { value: message }
        } = this.state.formValues;

        onSubmit({ message });
    };

    renderDescription() {
        const { authentication } = this.props;
        if (authentication === Authentication.User) {
            return <CancelOrderPurchaseDescription />;
        }
        return <CancelOrderStoreDescription />;
    }

    render() {
        const { isOpen, cartId, isSubmitting, errorMessage, errorOrderId, cancelButtonLabel, acceptButtonLabel, onClose } = this.props;
        return (
            <Modal as={Form} onSubmit={this.handleSubmit} error={errorMessage != null} onClose={onClose} size='tiny' open={isOpen}>
                <Modal.Header>
                    <Trans i18nKey='modal.orderCancel.header'>Cancel Order</Trans>
                </Modal.Header>
                <Modal.Content>
                    {errorMessage != null && errorOrderId === cartId && <Message error>{errorMessage}</Message>}
                    <Modal.Description>
                        {this.renderDescription()}
                        {this.renderTextAreaField({ name: 'message' }, this.props)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic type='button' onClick={onClose}>
                        {cancelButtonLabel}
                    </Button>
                    <Button positive>
                        {isSubmitting ? (
                            <Dimmer active inverted>
                                <Loader inverted>Proizvodi iz otkazane narudžbe biće smješteni u vašoj korpi!</Loader>
                            </Dimmer>
                        ) : (
                            acceptButtonLabel
                        )}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

OrderCancelModalView.defaultProps = {
    cancelButtonLabel: <Trans i18nKey='modal.orderCancel.cancelButton modal.generic.cancelButton'>Back</Trans>,
    acceptButtonLabel: <Trans i18nKey='modal.orderCancel.acceptButton modal.generic.acceptButton'>Cancel Order</Trans>
};

export default compose(withTranslation('translations'))(OrderCancelModalView);
