// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import defaultCover from 'styles/assets/images/defaults/coverPhotoDefaultLow.jpg';

type Props = {
    coverUrl: string
};

function ProfileCover(props: Props) {
    const { coverUrl } = props;
    const url = coverUrl || defaultCover;
    return (
        <Grid className='store-cover'>
            <div className='store-cover-image' style={{ backgroundImage: `url(${url})` }} />
        </Grid>
    );
}

export default withTranslation('translations')(ProfileCover);
