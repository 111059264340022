// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

function CancelOrderStoreDescription() {
    return (
        <p>
            <Trans i18nKey='modal.orderCancel.question.merchant'>
                You can cancel the order before the the item has been shipped. Please tell the shopper why you want to cancel this order.
            </Trans>
        </p>
    );
}

export default withTranslation('translations')(CancelOrderStoreDescription);
