// @flow
import React from 'react';
import { Container, Loader } from 'semantic-ui-react';

import TeaserCarousel from 'components/TeaserCarousel';
import type { EntityData, LandingComponent } from 'store/types';

type Props = {
    component: EntityData<LandingComponent>,
    title: string,
    urlPrefix: string
};

function LandingUserCarouselView({ component, title, urlPrefix }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }

    const { id: componentId, entities } = component.entity;
    return (
        <TeaserCarousel
            header={title}
            link={`${urlPrefix}/view-all/${componentId}`}
            type='user'
            content={entities.map(({ id }) => ({
                key: id.merchant,
                merchantId: id.merchant,
                itemIds: id.items.slice(0, 3)
            }))}
            showViewAll
        />
    );
}

LandingUserCarouselView.defaultProps = {
    urlPrefix: ''
};

export default LandingUserCarouselView;
