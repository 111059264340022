// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import SignUp from '../../components/SignUp';

type Props = {
    isLoggedIn: boolean
};

type State = {
    loggedInOnInit: boolean
};

class SignUpScene extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            loggedInOnInit: props.isLoggedIn
        };
    }

    render() {
        const { loggedInOnInit } = this.state;
        const { isLoggedIn } = this.props;

        if (loggedInOnInit) {
            return <Redirect to='/my-profile' />;
        }

        if (isLoggedIn) {
            return <Redirect to='/' />;
        }

        return (
            <Container text className='narrow'>
                <Header as='h1'>
                    <Trans i18nKey='signUpScene.header'>Sign up to MyBazar</Trans>
                </Header>
                <SignUp />
            </Container>
        );
    }
}

export default compose(withTranslation('translations'))(SignUpScene);
