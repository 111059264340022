// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type DeleteAddressParams = {
    addressId: number
};

export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const deleteAddress = wrapAPIActionCreator(({ addressId }: DeleteAddressParams) => ({
    [API_DATA]: {
        type: DELETE_ADDRESS,
        endpoint: `/v3/addresses/${addressId}`,
        method: 'DELETE',
        authentication: Authentication.User,
        schema: [Schemas.userDeliveryAddress]
    }
}));

export default deleteAddress;
