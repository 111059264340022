// @flow
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import * as ActionTypes from '../../actions';

import cart from './cart';
import purchases from './purchases';
import purchasesByOrder from './purchasesByOrder';
import promotions from './promotions';
import conversations from './conversations';
import productConversations from './productConversations';
import storeConversations from './storeConversations';

function updateAccessTokenFromLogin(state, action) {
    const { result, entities } = action.payload;
    const userEntity = entities.users[result.user];
    if (userEntity.userType == 2) {
        return result.accessToken;
    }
    return null;
}

function updateAccessTokenFromLoadMyAccounts(state, action) {
    const { result, entities } = action.payload;
    const shopperAccount = result.accounts.find((account) => entities.users[account.user].userType === 2);
    if (shopperAccount) {
        return shopperAccount.accessToken;
    }
    return null;
}

function accessToken(state = null, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:
        case ActionTypes.LOG_IN_FACEBOOK:
        case ActionTypes.LOG_IN_GOOGLE:
        case ActionTypes.RECOVER_RESET_PASSWORD:
            if (action.status === 'success') {
                return updateAccessTokenFromLogin(state, action);
            }
            return state;
        case ActionTypes.REGISTER_USER:
            if (action.status === 'success') {
                return action.payload.result.accessToken;
            }
            return state;
        case ActionTypes.LOAD_MY_ACCOUNTS:
        case ActionTypes.CREATE_MERCHANT_ACCOUNT:
            if (action.status === 'success') {
                return updateAccessTokenFromLoadMyAccounts(state, action);
            }
            return state;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}

function updateUserFromLoadMyAccounts(state, action) {
    const { result, entities } = action.payload;
    const account = result.accounts.find((a) => entities.users[a.user].userType === 2);
    return account ? account.user : null;
}

function user(state = null, action) {
    switch (action.type) {
        case ActionTypes.LOGIN_USER:
        case ActionTypes.LOG_IN_FACEBOOK:
        case ActionTypes.LOG_IN_GOOGLE:
        case ActionTypes.REGISTER_USER:
        case ActionTypes.LOAD_MY_PROFILE:
        case ActionTypes.RECOVER_RESET_PASSWORD:
            if (action.status === 'success') {
                return action.payload.result.user;
            }
            return state;
        case ActionTypes.LOAD_MY_ACCOUNTS:
        case ActionTypes.CREATE_MERCHANT_ACCOUNT:
            if (action.status === 'success') {
                return updateUserFromLoadMyAccounts(state, action);
            }
            return state;
        case ActionTypes.LOGOUT_USER:
            return null;
        default:
            return state;
    }
}

function isLoadingAccounts(state = false, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_ACCOUNTS:
            if (action.status === 'request') {
                return true;
            }
            return false;
        default:
            return state;
    }
}

export const userData = combineReducers({
    accessToken,
    user,
    cart,
    conversations,
    productConversations,
    storeConversations,
    isLoadingAccounts,
    purchases,
    purchasesByOrder,
    promotions
});

const persistConfig = {
    key: 'userData',
    storage,
    whitelist: ['accessToken', 'user', 'cart']
};

export default persistReducer(persistConfig, userData);
