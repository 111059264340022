// @flow
import * as ActionTypes from '../../actions';

type Action = {
    type: string,
    status: ?string,
    payload: {
        sort?: string
    }
};

function sort(state: string = 'latest', action: Action) {
    if (action.type === ActionTypes.SET_FILTER && 'sort' in action.payload) {
        // eslint-disable-next-line no-shadow
        const { sort } = action.payload;
        return sort;
    }
    return state;
}

export default sort;
