import React, { PureComponent } from 'react';
import { history } from 'store';

import TopBar from './components/TopBar';
// import SubBar from './components/SubBar';
import PromoBar from './components/PromoBar';
import Sidebar from './components/Sidebar';
import SidebarOverlay from './components/SidebarOverlay';

class Header extends PureComponent {
    state = {
        sidebarActive: false
    };

    toggleSidebar = () => {
        this.setState({ sidebarActive: !this.state.sidebarActive });
    };

    listenToHistory = history.listen(() => {
        if (this.state.sidebarActive) {
            this.toggleSidebar();
        }
    });

    render() {
        const { showPromo, onDismissPromo, isLoggedIn, onLogout } = this.props;
        const { sidebarActive } = this.state;
        return (
            <div className='page-header'>
                {showPromo && <PromoBar onDismissPromo={onDismissPromo} />}
                <TopBar toggleSidebar={this.toggleSidebar} showPromo={showPromo} onLogout={onLogout} isLoggedIn={isLoggedIn} />
                {/* <SubBar showPromo={showPromo} /> */}
                <Sidebar active={sidebarActive} toggle={this.toggleSidebar} />
                <SidebarOverlay active={sidebarActive} toggle={this.toggleSidebar} />
            </div>
        );
    }
}

export default Header;
