// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_BULK_IMPORT_SETTINGS ------------------

export const LOAD_BULK_IMPORT_SETTINGS = 'LOAD_BULK_IMPORT_SETTINGS';
export const loadBulkImportSettings = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_BULK_IMPORT_SETTINGS,
        endpoint: '/v3/bulk-import/settings',
        authentication: Authentication.Merchant,
        method: 'GET'
        // DG TODO
        // schema: {
        //   categoryTemplates: [Schemas.categoryTemplate]
        // },
    }
}));
