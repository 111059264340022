// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    stores: null
};

function settings(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_SETTINGS:
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'success') {
                return {
                    ...action.payload.result.settings,
                    isLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            break;

        // Handle store address creation
        case ActionTypes.CREATE_MERCHANT_STORE_LOCATION:
            if (action.status === 'success') {
                const { store } = action.payload.result;
                const { stores } = state;
                if (stores) {
                    if (!stores.includes(store)) {
                        return {
                            ...state,
                            stores: [...stores, store]
                        };
                    }
                }
            }
            break;

        // Handle store address deletion
        case ActionTypes.DELETE_MERCHANT_STORE_LOCATION:
            if (action.status === 'success') {
                const { storeId } = action.payload.result;
                const { stores } = state;
                if (stores) {
                    return {
                        ...state,
                        stores: stores.filter((id) => id !== storeId)
                    };
                }
            }
            break;

        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;

        default:
            break;
    }
    return state;
}

export default settings;
