// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Loader } from 'semantic-ui-react';

import type { EntityData, LandingComponent } from 'store/types';

import TeaserCarousel from 'components/TeaserCarousel';

type Props = {
    urlPrefix: string,
    component: EntityData<LandingComponent>,
    title: string
};

function LandingItemCarouselView({ urlPrefix, component, title }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }

    const { id: componentId, entities } = component.entity;

    return (
        <TeaserCarousel
            header={title}
            link={`view-all/${componentId}`}
            type='product'
            content={entities.map(({ id }) => ({
                key: id,
                itemId: id
            }))}
        />
    );
}

LandingItemCarouselView.defaultProps = {
    urlPrefix: ''
};

export default LandingItemCarouselView;
