//import ConnectedAccounts from 'components/ConnectedAccounts/ConnectedAccountsView';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Grid, Header, Form, Icon } from 'semantic-ui-react';

import MyAgentSettingsBankView from '../MyAgentSettingsBankView';
import * as actions from 'store/actions';
import { ReactComponent as Agent } from '../../../../styles/assets/icons/agent-icon.svg';

const payoutTypes = [
    {
        text: 'Every month on day 1',
        value: 0
    }
];

const getAgentDto = (agent, alias, email, phoneNumber, payoutType, bankAccount) => ({ ...agent, alias, email, phoneNumber, payoutType, bankAccount });

const MyAgentSettingsView = ({ user }) => {
    const [alias, setAlias] = useState(user.alias);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phoneNumber);
    const [payoutType, setPayoutType] = useState(user.agent && typeof user.agent.payoutType === 'number' ? user.agent.payoutType : null); // TODO check for typeof
    const [bankAccount, setBankAccount] = useState((user.agent && user.agent.bankAccount) || null);

    const disable = !(alias && email && phone && payoutType !== null);

    const dispatch = useDispatch();

    const handleSubmit = useCallback(() => {
        dispatch(actions.saveAgentSettings(getAgentDto(user.agent, alias, email, phone, payoutType, bankAccount)));
    }, [dispatch, user.agent, alias, email, phone, payoutType, bankAccount]);

    return (
        <Grid style={{ marginLeft: '45px', marginRight: '45px' }} stackable>
            <Grid.Column computer={5}>
                <Header as='h3'>Agent Account Settings</Header>
            </Grid.Column>
            <Grid.Row>
                <Grid.Column computer={5}>
                    {/*<ConnectedAccounts />*/}
                    <>
                        <Agent className='select-profile-icon' style={{ marginBottom: '20px' }} />
                        <div
                            style={{
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                right: '26rem',
                                top: '11rem',
                                width: '43px',
                                height: '35px',
                                backgroundColor: '#e9e9e9',
                                color: '#888888'
                            }}
                        >
                            <Icon style={{ marginRight: '0px' }} size='big' name='photo' />
                        </div>
                    </>
                    <h4>Agent Office ID</h4>
                    <h3>{user.agent && user.agent.publicId}</h3>
                </Grid.Column>
                <Grid.Column computer={5}>
                    <Form>
                        <Form.Input label='Full Name' required onChange={(e) => setAlias(e.target.value)} value={alias} />
                        <Form.Input label='Phone Number' required onChange={(e) => setPhone(e.target.value)} value={phone} />
                        <Form.Input label='Email' required onChange={(e) => setEmail(e.target.value)} value={email} />

                        <Form.Dropdown
                            selection
                            name='language'
                            id='-language'
                            onChange={() => {}}
                            // label={t('address.form.country.label')}
                            // placeholder={t('address.form.country.placeholder', {
                            //   defaultValue: '',
                            // })}
                            label={'Language'}
                            placeholder={'Language'}
                            value={''}
                            options={''}
                            required
                        />
                        <Form.Dropdown
                            selection
                            name='currency'
                            id='-currency'
                            onChange={() => {}}
                            // label={t('address.form.country.label')}
                            // placeholder={t('address.form.country.placeholder', {
                            //   defaultValue: '',
                            // })}
                            label={'Currency'}
                            placeholder={'Currency'}
                            value={''}
                            options={''}
                            required
                        />
                    </Form>
                </Grid.Column>
                <Grid.Column computer={6}>
                    <Form>
                        <Form.Dropdown
                            selection
                            name='payout-type'
                            id='payout-type'
                            onChange={(_event, { value }) => {
                                setPayoutType(value);
                            }}
                            label={'Payout Schedule'}
                            value={payoutType}
                            options={payoutTypes}
                            required
                        />
                    </Form>
                    <MyAgentSettingsBankView bankAccount={bankAccount} setBankAccount={setBankAccount} />
                </Grid.Column>
                <Grid.Column computer={16}>
                    <Button color='black' floated='right' onClick={handleSubmit} disabled={disable}>
                        Save changes
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default MyAgentSettingsView;
