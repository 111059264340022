// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import YouTubeModalView from './YouTubeModalView';

type Props = {
    name: string,
    closeModal: typeof actions.closeModal,
    url: string
};

class YouTubeModalContainer extends Component<Props, State> {
    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    render() {
        const { name, closeModal, ...rest } = this.props;
        return <YouTubeModalView onClose={this.handleClose} {...rest} />;
    }
}

const mapStateToProps = (state, { name }) => {
    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return { isOpen: false };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;
    const { url } = extraProps;

    return {
        isOpen: true,
        url
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeModal: (...args) => dispatch(actions.closeModal(...args))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(YouTubeModalContainer);
