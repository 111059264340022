// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Header, Loader } from 'semantic-ui-react';

import type { User } from 'store/types';
import { formatUsername } from 'utilities/format';
import SidebarProfileUserImage from './components/SidebarProfileUserImage';

type Props = {
    user: User
};

function SidebarProfileUser({ user }: Props) {
    if (!user) {
        return <Loader active inverted />;
    }
    return (
        <Grid className='auto tensed grid-align-items-center'>
            <Grid.Column className='col-shrink'>
                <SidebarProfileUserImage />
            </Grid.Column>
            <Grid.Column>
                <Header inverted size='medium'>
                    <Link to='/my-profile' className='inherit-color'>
                        {user.alias}
                    </Link>
                    <Header.Subheader>
                        <Link to='/my-profile' className='inherit-color'>
                            {formatUsername(user.username)}
                        </Link>
                    </Header.Subheader>
                </Header>
            </Grid.Column>
        </Grid>
    );
}

export default SidebarProfileUser;
