// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import ResetPasswordForm from './ResetPasswordForm';

type Props = {
    onSuccess: () => void
};

function LogIn({ onSuccess }: Props) {
    return (
        <div className='log-in'>
            <ResetPasswordForm onSuccess={onSuccess} />
        </div>
    );
}

export default withTranslation('translations')(LogIn);
