import React from 'react';
import { Menu } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CategoriesDropdown from '../CategoriesDropdown';
import { NavLink } from 'react-router-dom';
import { history } from 'store';

function SubBarMainView({ path, asDropdown }) {
    return asDropdown ? (
        <>
            <div className='dd-item' onClick={() => history.push('/discover')}>
                <Trans i18nKey='subBar.main.discover'>Discover</Trans>
            </div>
            <div className='dd-item' onClick={() => history.push('/latest')}>
                <Trans i18nKey='subBar.main.latest'>Latest</Trans>
            </div>
            <div onClick={() => history.push('/categories')} className='dd-item'>
                <Trans i18nKey='subBar.main.categories'>Categories</Trans>
            </div>
        </>
    ) : (
        <Menu.Menu position='left'>
            <Menu.Item as={NavLink} to='/discover' className='sub-bar-item' active={path === '/discover'}>
                <Trans i18nKey='subBar.main.discover'>Discover</Trans>
            </Menu.Item>
            <Menu.Item as={NavLink} to='/latest' className='sub-bar-item' active={path === '/latest'}>
                <Trans i18nKey='subBar.main.latest'>Latest</Trans>
            </Menu.Item>
            <CategoriesDropdown />
        </Menu.Menu>
    );
}

export default withTranslation('translations')(SubBarMainView);
