// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyStoreOrdersTab from './components/MyStoreOrdersTab';

type Props = {
    orders: {
        open: number[],
        openCount: number,
        delivered: number[],
        deliveredCount: number,
        cancelled: number[],
        cancelledCount: number
    },
    loadMoreOrders: () => void
};

function MyStoreOrders({ orders, loadMoreOrders, location }: Props) {
    return (
        <Container className='my-store-purchases'>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h1'>
                            <Trans i18nKey='myStore.orders.header'>My Store Orders</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <MyStoreOrdersTab orders={orders} loadMoreOrders={loadMoreOrders} location={location} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default withTranslation('translations')(MyStoreOrders);
