// @flow
import React from 'react';
import { Menu, Tab } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CampaignsMenuItem from './components/CampaignsMenuItem';
import CampaignsPreview from './components/CampaignsPreview';

const CampaignsView = ({ items }) => {
    let panes = [];
    if (items.length > 0) {
        panes = items.map((item) => ({
            menuItem: (
                <Menu.Item key={item.entity.conversationKey}>
                    <CampaignsMenuItem item={item} />
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false} className='simple'>
                    <CampaignsPreview message={item.entity.message} />
                </Tab.Pane>
            )
        }));
    } else {
        panes = [
            {
                menuItem: (
                    <Menu.Item key='no-campaigns'>
                        <Trans i18nKey='campaigns.noCampaignsFound.label'>No campaigns found.</Trans>
                    </Menu.Item>
                )
            }
        ];
    }
    return (
        <Tab
            menu={{
                fluid: true,
                vertical: true,
                secondary: true,
                style: {
                    margin: 0
                }
            }}
            panes={panes}
        />
    );
};

export default withTranslation('translations')(CampaignsView);
