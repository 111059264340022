// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { openModal, closeModal } from 'store/actions';
import { ModalNames } from 'store/actions/ui';

import ResetPasswordView from './view';

type Props = {
    forgotPassword: typeof actions.forgotPassword
};

class ResetPassword extends Component<Props> {
    render() {
        return <ResetPasswordView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.userData.accessToken != null
});

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: wrapAPIDispatch(dispatch, actions.forgotPassword),
    passwordSent: () => {
        dispatch(closeModal(ModalNames.FORGOT_PASSWORD));
        dispatch(openModal(ModalNames.RESET_EMAIL_SENT));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
