// @flow
import { ShipmentOptions, DeliveryOptions } from 'store/enums';
import Container from './ShippingToContainer';

export { default as Container } from './ShippingToContainer';
export { default as View } from './ShippingToView';

export default Container;

export const shippingToListDefaults = [
    {
        key: 100,
        id: 'myBazarEuroExpress',
        title: 'BIH (EuroExpress)',
        poslajuActive: false,
        shipToId: 100,
        delivery: DeliveryOptions.MY_BAZAR,
        shipment: ShipmentOptions.EUROEXPRESS,
        paymentDelivery: null,
        returnPolicyId: null,
        isMerchantPay: 0,
        selected: true
    }
    // {
    //     key: 100,
    //     id: 'myBazarExpressOne',
    //     title: 'BIH (ExpressOne)',
    //     poslajuActive: false,
    //     shipToId: 100,
    //     delivery: DeliveryOptions.MY_BAZAR,
    //     shipment: ShipmentOptions.EXPRESSONE,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     selected: true
    // }
    // {
    //     key: 1,
    //     id: 'myBazarDeliveryWestMalaysia',
    //     title: 'West Malaysia (POSlaju)',
    //     poslajuActive: true,
    //     shipToId: 2,
    //     delivery: DeliveryOptions.MY_BAZAR,
    //     shipment: ShipmentOptions.POSLAJU,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     excludes: 'own-delivery-west-malaysia',
    //     selected: false
    // },
    // {
    //     key: 2,
    //     id: 'myBazarDeliveryEastMalaysia',
    //     title: 'East Malaysia (POSlaju)',
    //     poslajuActive: true,
    //     shipToId: 1,
    //     delivery: DeliveryOptions.MY_BAZAR,
    //     shipment: ShipmentOptions.POSLAJU,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     selected: false
    // },
    // {
    //     key: 3,
    //     id: 'ownDeliveryWestMalaysia',
    //     title: 'West Malaysia (Own Delivery)',
    //     poslajuActive: false,
    //     shipToId: 2,
    //     delivery: DeliveryOptions.MERCHANT,
    //     shipment: ShipmentOptions.OWN_SHIPMENT,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     selected: false
    // },
    // {
    //     key: 4,
    //     id: 'ownDeliveryEastMalaysia',
    //     title: 'East Malaysia (Own Delivery)',
    //     poslajuActive: false,
    //     shipToId: 1,
    //     delivery: DeliveryOptions.MERCHANT,
    //     shipment: ShipmentOptions.OWN_SHIPMENT,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     selected: false
    // },
    // {
    //     key: 5,
    //     id: 'ownDeliveryWorldwide',
    //     title: 'Worldwide (Own Delivery)',
    //     poslajuActive: false,
    //     shipToId: 99,
    //     delivery: DeliveryOptions.MERCHANT,
    //     shipment: ShipmentOptions.OWN_SHIPMENT,
    //     paymentDelivery: null,
    //     returnPolicyId: null,
    //     isMerchantPay: 0,
    //     selected: false
    // }
];

// {id: 1, title: 'West Malaysia (POSlaju)', ship_to_id: 2, shipment: 2, payment_delivery: null, payment: 2, poslaju_active: true, delivery: 3, is_merchant_pay: 0, return_policy_id: 0},
// {id: 2, title: 'East Malaysia (POSlaju)', ship_to_id: 1, shipment: 2,payment_delivery: null, payment: 2, poslaju_active: true, delivery: 3, is_merchant_pay: 0, return_policy_id: 0},
// {id: 3, title: 'West Malaysia (Own Delivery)', ship_to_id: 2, shipment: 0,payment_delivery: null, payment: 2, poslaju_active: false, delivery: 2, is_merchant_pay: 0, return_policy_id: 0},
// {id: 4, title: 'East Malaysia (Own Delivery)', ship_to_id: 1, shipment: 0,payment_delivery: null, payment: 2, poslaju_active: false, delivery: 2, is_merchant_pay: 0, return_policy_id: 0},
// {id: 5, title: 'Worldwide (Own Delivery)', ship_to_id: 99, shipment: 0,payment_delivery: null, payment: 2, poslaju_active: false, delivery: 2, is_merchant_pay: 0, return_policy_id: 0}
