// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type DeleteCartItemParams = {
    cartItemId: number
};

export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const deleteCartItem = wrapAPIActionCreator(({ cartItemId }: DeleteCartItemParams = {}) => ({
    [API_DATA]: {
        type: DELETE_CART_ITEM,
        endpoint: `/v3/cart_items/${cartItemId}`,
        method: 'DELETE',
        authentication: Authentication.User,
        preprocess: (res) => ({ ...res, cartItemId }),
        schema: {
            updatedCartItems: [Schemas.cartItem]
        }
    }
}));

export default deleteCartItem;
