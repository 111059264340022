// @flow
import React, { Component } from 'react';
import { Button, Form, Modal, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { EntityData, UserDeliveryAddress } from 'store/types';
import type { FormValues } from './index';

type FormFieldData<T = string> = {
    value: T,
    error: boolean
};

type Props = {
    isOpen: boolean,
    address: EntityData<UserDeliveryAddress>,
    onClose: () => void,
    onSubmit: (SyntheticEvent<>, FormValues) => void,
    title?: React.Node,
    acceptButtonLabel?: React.Node,
    cancelButtonLabel?: React.Node,
    deleteButtonLabel?: React.Node,
    confirmDeleteLabel?: React.Node,
    // from translation
    t: (string, { defaultValue: string }) => string,
    // from store
    countries: array,
    states: array,
    cities: array,
    onCountryChange: (SyntheticEvent, { name: string, value: string }) => void,
    getCountry: (number) => void,
    getState: (number) => void,
    getCity: (number) => void,
    deleteModalIsOpen: boolean,
    isDeleting: boolean,
    deleteError: string,
    onOpenDeleteModal: () => void,
    onCloseDeleteModal: () => void,
    onClickDelete: () => void,
    errorMessage: string,
    backendError: string
};

type State = {
    loadedId: ?number,
    name: FormFieldData<>,
    email: FormFieldData<>,
    phone: FormFieldData<>,
    address: FormFieldData<>,
    addressSecondary: FormFieldData<>,
    postal: FormFieldData<>,
    country: FormFieldData<number>,
    state: FormFieldData<number>,
    city: FormFieldData<number>,
    errorMessage: ?string,
    backendError?: string
};

function createEmptyFormFieldData(value = '') {
    return {
        value,
        error: false
    };
}

class DeliveryAddressModalView extends Component<Props, State> {
    state = {
        loadedId: null,
        name: createEmptyFormFieldData(),
        email: createEmptyFormFieldData(),
        phone: createEmptyFormFieldData(),
        address: createEmptyFormFieldData(),
        addressSecondary: createEmptyFormFieldData(),
        postal: createEmptyFormFieldData(),
        country: createEmptyFormFieldData(),
        state: createEmptyFormFieldData(),
        city: createEmptyFormFieldData(),
        backendError: this.props.backendError
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { address } = nextProps;
        if (address == null) {
            this.setState({ loadedId: null });
        } else if (address.entity && address.entity.id !== this.state.loadedId) {
            this.setState({ loadedId: address.entity.id }, () => {
                this.setDataFromAddressEntityData(address, nextProps, true);
            });
        } else if (this.state.loadedId != null) {
            this.setDataFromAddressEntityData(address, nextProps, false);
        }
    }

    setDataFromAddressEntityData = ({ entity: addressEntity }, props = this.props, forceUpdate = false) => {
        const { countries, states, getCountry, getState, getCity } = props;
        if (addressEntity == null) {
            return;
        }

        // Update unedited fields with values from received address
        const mapping = {
            name: 'fullName',
            email: 'emailAddress',
            phone: 'contactNo',
            address: 'street',
            addressSecondary: 'houseNo',
            postal: 'zip'
        };
        Object.keys(mapping).forEach((targetKey) => {
            // Copy values from source (existing address entity) to target (our
            // local state in the form).
            const sourceKey = mapping[targetKey];
            const sourceValue = addressEntity[sourceKey];
            const targetValue = this.state[targetKey].value;
            if (targetValue === '' || forceUpdate) {
                this.setField(targetKey, sourceValue || '');
            }
        });

        if (forceUpdate) {
            // Clear these fields so that they'll be loaded once we have all the
            // data necessary to load them (i.e. have fetched state & city data)
            this.setField('country', '');
            this.setField('state', '');
            this.setField('city', '');
        }

        // Country
        const country = (countries || []).map(getCountry).find(({ entity }) => entity.name === addressEntity.country);
        const countryId = country && country.entity && country.entity.id;
        if (countryId == null) {
            return;
        }

        if (this.state.country.value === '' || forceUpdate) {
            this.setField('country', countryId);
        }

        if (states[countryId] == null) {
            // fetch states & cities
            const { onCountryChange } = this.props;
            onCountryChange({ value: countryId });
            return;
        }

        // State
        const state = states[countryId].map(getState).find(({ entity }) => entity.district === addressEntity.state);
        const stateId = state && state.entity && state.entity.id;
        if (stateId == null) {
            return;
        }

        if (this.state.state.value === '' || forceUpdate) {
            this.setField('state', stateId);
        }

        // City
        const city = state.entity.cities.map(getCity).find(({ entity }) => entity.name === addressEntity.city);
        const cityId = city && city.entity && city.entity.id;
        if (cityId == null) {
            return;
        }

        if (this.state.city.value === '' || forceUpdate) {
            this.setField('city', cityId);
        }
    };

    setField = (key, value) => {
        // FIXME
        const target = this.state[key];
        target.value = value;
        this.setState({ [key]: target });
    };

    handleChange = (event, { name, value }) => {
        this.setField(name, value);
    };

    handleCountryChange = (event, data) => {
        const { onCountryChange } = this.props;
        this.handleChange(event, data);
        onCountryChange(data);
    };

    handleStateChange = (event, data) => {
        const target = this.state.city;
        target.value = null;
        this.setState({ city: target });
        this.handleChange(event, data);
    };

    handleBlur = (event) => {
        this.validate(event.target.name).catch(() => {});
    };

    handleSubmit = (event) => {
        const { onSubmit, getCountry, getState, getCity } = this.props;
        const {
            loadedId,
            name: { value: name },
            email: { value: email },
            phone: { value: phone },
            address: { value: address },
            addressSecondary: { value: addressSecondary },
            postal: { value: postal },
            country: { value: country },
            state: { value: state },
            city: { value: city }
        } = this.state;

        this.validate('name', 'email', 'phone', 'address', 'addressSecondary', 'postal', 'country', 'state', 'city')
            .then(() =>
                onSubmit(event, {
                    addressId: loadedId,
                    name,
                    email,
                    phone,
                    address,
                    addressSecondary,
                    postal,
                    country: getCountry(country).entity.name,
                    state: getState(state).entity.district,
                    city: getCity(city).entity.name,
                    country_id: country,
                    state_id: state,
                    city_id: city
                })
            )
            .then(() => this.setState({ loading: false, errorMessage: null }))
            .catch((err) => this.setState({ loading: false, errorMessage: err.message }));
    };

    validate = (...inputs) => {
        const { t } = this.props;

        let valid = true;
        inputs
            .filter((input) => input !== 'addressSecondary')
            .forEach((input) => {
                const target = this.state[input];
                target.error = target.value === null || target.value.length === 0;
                if (target.error) {
                    valid = false;
                }
                this.setState({ [input]: target });
            });

        const errorMessage = t('address.validationErrors.invalidFields', { defaultValue: 'Some fields were invalid' });
        return valid ? Promise.resolve() : Promise.reject(new Error(errorMessage));
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    render() {
        const {
            isOpen,
            title,
            cancelButtonLabel,
            acceptButtonLabel,
            deleteButtonLabel,
            confirmDeleteLabel,
            deleteModalIsOpen,
            isDeleting,
            deleteError,
            onOpenDeleteModal,
            onCloseDeleteModal,
            onClickDelete,
            t,
            // from store
            countries,
            states,
            getCountry,
            getState,
            getCity
        } = this.props;

        const statesOptions = this.state.country.value && states[this.state.country.value] ? states[this.state.country.value] : [];
        const selectedState = this.state.state.value && getState(this.state.state.value);

        const citiesOptions = selectedState ? selectedState.entity.cities : [];

        const {
            name: { value: name },
            email: { value: email },
            phone: { value: phone },
            address: { value: address },
            addressSecondary: { value: addressSecondary },
            postal: { value: postal },
            country: { value: country },
            state: { value: state },
            city: { value: city },
            errorMessage
        } = this.state;
        return (
            <Modal size='tiny' open={isOpen} onClose={this.handleClose}>
                <Modal.Header>{title}</Modal.Header>
                <Modal.Content>
                    <Form error={errorMessage != null || !!this.props.backendError}>
                        {!!this.props.backendError && <Message error>{this.props.backendError}</Message>}
                        <Message error>{errorMessage}</Message>
                        {!!this.props.errorMessage && <Message error>{this.state.errorMessage}</Message>}
                        <Form.Input
                            error={this.state.name.error}
                            type='text'
                            name='name'
                            id='delivery-address-name'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label={t('deliveryAddress.form.name.label')}
                            placeholder={t('deliveryAddress.form.name.placeholder', { defaultValue: '' })}
                            value={name}
                            required
                        />
                        <Form.Input
                            error={this.state.email.error}
                            type='email'
                            name='email'
                            id='delivery-address-email'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label={t('deliveryAddress.form.email.label')}
                            placeholder={t('deliveryAddress.form.email.placeholder', { defaultValue: '' })}
                            value={email}
                            required
                        />
                        <Form.Input
                            error={this.state.phone.error}
                            type='text'
                            name='phone'
                            id='delivery-address-phone'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label={t('deliveryAddress.form.phone.label')}
                            placeholder={t('deliveryAddress.form.phone.placeholder', { defaultValue: '' })}
                            value={phone}
                            required
                        />
                        <Form.Input
                            error={this.state.address.error}
                            type='text'
                            name='address'
                            id='delivery-address-address'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label='Adresa'
                            placeholder={t('deliveryAddress.form.address.placeholder', { defaultValue: '' })}
                            value={address}
                            required
                        />
                        {/*<Form.Input*/}
                        {/*    error={this.state.addressSecondary.error}*/}
                        {/*    type='text'*/}
                        {/*    name='addressSecondary'*/}
                        {/*    id='delivery-address-address-secondary'*/}
                        {/*    onChange={this.handleChange}*/}
                        {/*    onBlur={this.handleBlur}*/}
                        {/*    label={t('deliveryAddress.form.addressSecondary.label')}*/}
                        {/*    placeholder={t('deliveryAddress.form.addressSecondary.placeholder', { defaultValue: '' })}*/}
                        {/*    value={addressSecondary}*/}
                        {/*/>*/}
                        <Form.Input
                            error={this.state.postal.error}
                            type='text'
                            name='postal'
                            id='delivery-address-postal'
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            label={t('deliveryAddress.form.postal.label')}
                            placeholder={t('deliveryAddress.form.postal.placeholder', { defaultValue: '' })}
                            value={postal}
                            required
                        />
                        <Form.Dropdown
                            error={this.state.country.error}
                            selection
                            name='country'
                            id='delivery-address-country'
                            onChange={this.handleCountryChange}
                            onBlur={() => this.validate('country').catch(() => {})}
                            label={t('deliveryAddress.form.country.label')}
                            placeholder={t('deliveryAddress.form.country.placeholder', { defaultValue: '' })}
                            value={country}
                            options={countries.map(getCountry).map(({ entity }) => ({ text: entity.name, value: entity.id }))}
                            required
                        />
                        <Form.Dropdown
                            error={this.state.state.error}
                            selection
                            name='state'
                            id='delivery-address-state'
                            onChange={this.handleStateChange}
                            onBlur={() => this.validate('state').catch(() => {})}
                            label={t('deliveryAddress.form.state.label')}
                            placeholder={t('deliveryAddress.form.state.placeholder', { defaultValue: '' })}
                            value={state}
                            options={statesOptions.map(getState).map(({ entity }) => ({ text: entity.district, value: entity.id }))}
                            required
                        />
                        <Form.Dropdown
                            error={this.state.city.error}
                            selection
                            name='city'
                            id='delivery-address-city'
                            onChange={this.handleChange}
                            onBlur={() => this.validate('city').catch(() => {})}
                            label={t('deliveryAddress.form.city.label')}
                            placeholder={t('deliveryAddress.form.city.placeholder', { defaultValue: '' })}
                            value={city}
                            options={citiesOptions.map(getCity).map(({ entity }) => ({ text: entity.name, value: entity.id }))}
                            required
                        />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic onClick={this.handleClose}>
                        {cancelButtonLabel}
                    </Button>
                    {deleteButtonLabel && (
                        <Modal
                            open={deleteModalIsOpen}
                            onClose={() => {
                                this.setState({ deleteModalOpen: false });
                            }}
                            size='tiny'
                            trigger={
                                <Button type='button' loading={isDeleting} negative icon labelPosition='right' onClick={onOpenDeleteModal}>
                                    {deleteButtonLabel}
                                </Button>
                            }
                        >
                            <Modal.Content>
                                {confirmDeleteLabel}
                                {deleteError && <Message error>{deleteError}</Message>}
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type='button' basic onClick={onCloseDeleteModal}>
                                    {cancelButtonLabel}
                                </Button>
                                <Button type='button' loading={isDeleting} negative icon labelPosition='right' onClick={onClickDelete}>
                                    {deleteButtonLabel}
                                </Button>
                            </Modal.Actions>
                        </Modal>
                    )}
                    <Button positive icon labelPosition='right' onClick={this.handleSubmit}>
                        {acceptButtonLabel}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

DeliveryAddressModalView.defaultProps = {
    title: 'Delivery Address',
    cancelButtonLabel: <Trans i18nKey='deliveryAddress.cancelButton'>Cancel</Trans>,
    acceptButtonLabel: <Trans i18nKey='deliveryAddress.acceptButton'>Accept</Trans>
};

export default withTranslation('translations')(DeliveryAddressModalView);
