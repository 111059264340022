// @flow
import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import LogInFacebook from './components/LogInFacebook';
import LogInForm from './components/LogInForm';
import LogInGoogle from './components/LogInGoogle';

function LogIn({ onSuccess, onClickForgotPasswordButton }) {
    const click = (event) => {
        event.preventDefault();
        onClickForgotPasswordButton();
    };

    return (
        <Grid stackable columns='2' className='login-main-grid'>
            <Grid.Row verticalAlign='middle' className='login-row'>
                <Grid.Column className='login-left-column'>
                    <div className='login-video-container'>
                        <video playsInline autoPlay muted loop className='login-video'>
                            <source src='https://mybazarglobal.com/wp-content/uploads/2021/04/bg-video.mp4' type='video/mp4'></source>
                        </video>
                    </div>
                </Grid.Column>
                <Grid.Column textAlign='center' className='login-right-column'>
                    <div className='right-content'>
                        <Header as='h1' className='login-header'>
                            <Trans i18nKey='logIn.header'>Join the e-commerce evolution!</Trans>
                        </Header>
                        <div className='login-buttons-container'>
                            {/*<LogInFacebook />*/}
                            <LogInGoogle />
                            <LogInForm onSuccess={onSuccess} />
                        </div>
                        <Grid columns='2' className='register-and-reset-buttons'>
                            <Grid.Row>
                                <Grid.Column>
                                    <Link to='/forgot-password' onClick={click} className='standard-color-link'>
                                        <Trans i18nKey='logIn.forgotPassword'>Forgot password?</Trans>
                                    </Link>
                                </Grid.Column>
                                <Grid.Column>
                                    <Link to='/signup' className='standard-color-link'>
                                        <Trans i18nKey='logIn.signUp'>Sign up!</Trans>
                                    </Link>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <div className='terms-container'>
                            <span>
                                <Trans i18nKey='logIn.acknowledgeOne'>By registering and/or logging in to the Platform, I confirm that I have read and agree to the </Trans>{' '}
                                <a rel='noreferrer' href='https://mybazarglobal.com/terms-of-use/' target='_blank'>
                                    <Trans i18nKey='logIn.termsOfUse'>Terms of Use Rules </Trans>
                                </a>{' '}
                                <Trans i18nKey='logIn.acknowledgeTwo'>and the </Trans>{' '}
                                <a rel='noreferrer' href='https://mybazarglobal.com/merchant-agreement/' target='_blank'>
                                    <Trans i18nKey='logIn.merchantAgreement'>Rules on the Use and Protection of Personal Data </Trans>
                                </a>{' '}
                                <Trans i18nKey='logIn.acknowledgeThree'>. </Trans>{' '}
                            </span>
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default withTranslation('translations')(LogIn);
