// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import Slick from 'react-slick';

import TeaserCarouselArrow from './components/TeaserCarouselArrow';
import CategoryTeaser from './components/CategoryTeaser';
import StoreTeaser from './components/StoreTeaser';
import ProductTeaser from '../ProductTeaser';
import UserTeaserView from './components/UserTeaser/UserTeaserView';

type Props = {
    header: string,
    link: string,
    type: 'store' | 'product' | 'category' | 'user',
    content: array,
    inverted: boolean,
    invertedType: string,
    showViewAll?: boolean
};

function TeaserCarousel({ header, link, type, content, inverted, invertedType, showViewAll }: Props) {
    let slickChanging = false;
    const preventSlickClickDuringChange = (event) => {
        if (slickChanging) {
            event.preventDefault();
        }
    };
    const slickClass = 'teaser-carousel-slick-slider';
    const getSlickClass = (length) => `${slickClass}${content.length < length ? ` ${slickClass}-fixed-items-${content.length}` : ''}`;
    const getSlidesToShow = (n) => (n > content.length ? content.length : n);
    const getSlidesToScroll = (n) => (n > content.length ? content.length : n);

    // Adjust number of items shown depending on carousel width
    const responsive = [
        [2400, 11],
        [2200, 10],
        [2000, 9],
        [1800, 5],
        [1600, 5],
        [1400, 5],
        [1200, 5],
        [1000, 3],
        [800, 3],
        [768, 2],
        [668, 2],
        [500, 2]
    ].map(([breakpoint, n]) => ({
        breakpoint,
        touchThreshold: n * 4,
        settings: {
            className: getSlickClass(n),
            slidesToShow: getSlidesToShow(n),
            slidesToScroll: getSlidesToScroll(n)
        }
    }));

    const slickSettings = {
        className: getSlickClass(12),
        slidesToShow: 12,
        slidesToScroll: 12,
        speed: 500,
        lazyLoad: true,
        touchThreshold: 12 * 4,
        nextArrow: <TeaserCarouselArrow direction='next' inverted={inverted} />,
        prevArrow: <TeaserCarouselArrow direction='prev' inverted={inverted} />,
        beforeChange: () => {
            slickChanging = true;
        },
        afterChange: () => {
            slickChanging = false;
        },
        responsive
    };

    const seen = new Set();
    const hasDuplicates = content.some((currentObject) => {
        if (seen.size === seen.add(currentObject.key).size) {
            return true;
        }
        return false;
    });

    return (
        <Segment className={`simple teaser-carousel${inverted ? ` teaser-carousel-inverted teaser-carousel-inverted-${invertedType}` : ''}`}>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    {(header !== '' || showViewAll) && (
                        <Grid className='auto vertically-tensed grid-align-items-center teaser-carousel-header'>
                            <Grid.Column className='col-grow'>
                                {header !== '' && (
                                    <Header as='h2' inverted={inverted} style={{ fontSize: '1.5rem' }}>
                                        {header}
                                    </Header>
                                )}
                            </Grid.Column>
                            {showViewAll && (
                                <Grid.Column className='col-shrink'>
                                    <Link to={link} inverted={inverted} style={{ fontSize: '1rem' }} className='view-all'>
                                        <Trans i18nKey='teaserCarousel.viewAll'>View All</Trans>
                                    </Link>
                                </Grid.Column>
                            )}
                        </Grid>
                    )}
                </Grid.Column>
                <Grid.Column className='col-row'>
                    <Slick {...slickSettings}>
                        {content.map(({ key, ...rest }, index) => (
                            <div key={hasDuplicates ? index : key} className='teaser-carousel-slick-slide' onDragStart={(event) => event.preventDefault()}>
                                {type === 'store' && <StoreTeaser {...rest} inverted={inverted} preventSlickClickDuringChange={preventSlickClickDuringChange} />}
                                {type === 'user' && <UserTeaserView {...rest} inverted={inverted} preventSlickClickDuringChange={preventSlickClickDuringChange} />}
                                {type === 'product' && <ProductTeaser {...rest} inverted={inverted} preventSlickClickDuringChange={preventSlickClickDuringChange} />}
                                {type === 'category' && <CategoryTeaser {...rest} inverted={inverted} />}
                            </div>
                        ))}
                    </Slick>
                </Grid.Column>
            </Grid>
        </Segment>
    );
}

TeaserCarousel.defaultProps = {
    showViewAll: true
};

export default withTranslation('translations')(TeaserCarousel);
