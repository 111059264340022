// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ProductReportButtonView from './ProductReportButtonView';

type Props = {
    product: EntityData<Item>
};

class ProductReportButtonContainer extends Component<Props, {}> {
    render() {
        const { isLoggedIn, product, t } = this.props;

        const subjectSuffix = t('product.report.mail.subject_suffix', { defaultValue: 'PRODUCT REPORT' });
        const textPlaceholder = t('product.report.mail.text_placeholder', { defaultValue: 'Type your report here' });
        const productLink = t('product.report.mail.product_link', { defaultValue: 'Product link' });
        const linkLabel = t('product.report.mail.link_label', { defaultValue: 'Report this Product' });

        const supportMailAddress = 'support@mybazar.com';
        const mailSubject = `[${subjectSuffix}] ${product.name}`;
        const mailBody = encodeURIComponent(`<${textPlaceholder}>\n\n\n${productLink}: ${window.location.href}`);
        const mailLink = `mailto:${supportMailAddress}?subject=${mailSubject}&body=${mailBody}`;

        return <ProductReportButtonView mailLink={mailLink} linkLabel={linkLabel} isLoggedIn={isLoggedIn} />;
    }
}

export default withTranslation('translations')(ProductReportButtonContainer);
