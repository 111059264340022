// @flow
import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';

export type SwitchCartParams = {
    cartId: number
};

export const SWITCH_CART = 'SWITCH_CART';
export const switchCart = wrapAPIActionCreator(({ cartId }: SwitchCartParams) => {
    return {
        [API_DATA]: {
            type: SWITCH_CART,
            endpoint: '/v3/cart_items/switch_cart',
            method: 'POST',
            authentication: Authentication.User,
            body: {
                cartId
            },
            schema: Schemas.cart
        }
    };
});

export default switchCart;
