// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import { compose } from 'redux';
import { Grid, Loader, Visibility } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props<T> = {
    items: T[],
    isLoading: boolean,
    onLoadMore: ({}) => void,
    renderItem: (T) => Node,
    placeholder: Node,
    renderPlaceholder: (Props<T>) => Node,
    keyExtractor: (T, number) => string
};

class PaginatedGridView<T> extends Component<Props<T>> {
    componentDidUpdate() {
        const { onLoadMore } = this.props;
        const { bottom } = this.ref.getBoundingClientRect();
        if (false && bottom < window.innerHeight && window.scrollY === 0) {
            onLoadMore();
        }
    }

    ref;

    handleRef = (ref) => {
        this.ref = ref;
    };

    render() {
        const { items, isLoading, onLoadMore, renderItem, keyExtractor, renderPlaceholder } = this.props;
        return (
            <div ref={this.handleRef}>
                {items.length > 0 && (
                    <Visibility continuous fireOnMount onBottomVisible={onLoadMore}>
                        <Grid columns={6} className='auto'>
                            {items.map((item, index) => (
                                <Grid.Column key={keyExtractor(item, index)} className='full-width-list-item'>
                                    {renderItem(item)}
                                </Grid.Column>
                            ))}
                        </Grid>
                    </Visibility>
                )}
                {!isLoading && items.length === 0 && <p style={{ textAlign: 'center', margin: '2em' }}>{renderPlaceholder(this.props)}</p>}
                {isLoading && (
                    <div className='infinite-scrolling-throbber-container'>
                        <Loader active inline='centered' className='infinite-scrolling-throbber' />
                    </div>
                )}
            </div>
        );
    }
}

PaginatedGridView.defaultProps = {
    keyExtractor: ({ key }, index) => key || String(index),
    renderPlaceholder: ({ placeholder }) => placeholder,
    placeholder: <Trans i18nKey='paginatedGrid.placeholder.generic'>No items to show.</Trans>
};

export default compose(withTranslation('translations'))(PaginatedGridView);
