// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
    direction: string,
    className: string,
    style: { [string]: string },
    onClick: () => void
};

function ProductCarouselArrow({ direction, className, style, onClick }: Props) {
    return (
        <Button basic icon className={`${className} product-carousel-slick-arrow`} style={{ ...style }} onClick={onClick}>
            <Icon name={`chevron ${direction === 'prev' ? 'left' : 'right'}`} />
        </Button>
    );
}

export default withTranslation('translations')(ProductCarouselArrow);
