// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import OrderAddTrackingNumberModalView from './OrderAddTrackingNumberModalView';

type Props = {
    name: string,
    // from store
    orderId: number,
    addTrackingNumber: typeof actions.addTrackingNumber,
    closeModal: typeof actions.closeModal
};

type State = {
    isSubmitting: boolean,
    errorMessage: ?string,
    errorOrderId: ?number
};

class OrderAddTrackingNumberModalContainer extends Component<Props, State> {
    static defaultProps;

    state = {
        isSubmitting: false,
        errorMessage: null,
        errorOrderId: null
    };

    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    handleSubmit = ({ trackingNumber, carrier, carrierUrl }) => {
        const { closeModal, name, addTrackingNumber, orderId } = this.props;
        this.setState({ isSubmitting: true });
        addTrackingNumber({
            orderId,
            trackingNumber,
            carrier,
            carrierUrl
        })
            .then(
                () => closeModal(name),
                (err) => this.setState({ errorMessage: err.message, errorOrderId: orderId })
            )
            .then(() => this.setState({ isSubmitting: false }));
    };

    render() {
        const { name, closeModal, ...rest } = this.props;
        const { isSubmitting, errorMessage, errorOrderId } = this.state;
        return (
            <OrderAddTrackingNumberModalView
                formId='order-add-tracking-number'
                translationId='modal.addTrackingNumber'
                onClose={this.handleClose}
                onSubmit={this.handleSubmit}
                {...{ isSubmitting, errorMessage, errorOrderId }}
                {...rest}
            />
        );
    }
}

const mapStateToProps = (state, { name }) => {
    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return { isOpen: false };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;
    const { orderId } = extraProps;

    return {
        ...extraProps,
        isOpen: true,
        orderId
    };
};

const mapDispatchToProps = (dispatch) => ({
    addTrackingNumber: wrapAPIDispatch(dispatch, actions.addTrackingNumber),
    closeModal: (...args) => dispatch(actions.closeModal(...args))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrderAddTrackingNumberModalContainer);
