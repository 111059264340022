// @flow
import React from 'react';
import { Form, Header, Input, Label, TextArea } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

// import { Editor } from 'react-draft-wysiwyg';
// import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
// import draftToHtml from 'draftjs-to-html';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

type Props = {
    t: Function,
    state: mixed,
    handleChange: Function
};

type State = {
    isPosting: false,
    errorMessage: ?string,
    formValues: {
        name: FormFieldData<string>,
        slug: FormFieldData<string>,
        description: FormFieldData<string>
    }
};

class MyStoreSettingsFormStore extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { merchant = {}, updateFormValues, formId } = props;

        const { slug, alias, description = '' } = merchant;

        // let body = description.replace('<!DOCTYPE html><html><head></head><body>', '');
        // body = body.replace('</body></html>', '');

        // let descriptionEditorState = EditorState.createEmpty();
        // if (body.length > 0) {
        //   const blocksFromHTML = convertFromHTML(description);
        //   const contentState = ContentState.createFromBlockArray(
        //     blocksFromHTML.contentBlocks,
        //     blocksFromHTML.entityMap
        //   );
        //   descriptionEditorState = EditorState.createWithContent(contentState);
        // }

        this.state = {
            formValues: {
                name: createEmptyFormFieldData({ value: alias || '', required: true }),
                slug: createEmptyFormFieldData({ value: slug || '', required: true }),
                description: createEmptyFormFieldData({ value: description })
                // descriptionEditorState,
            }
        };

        // updateFormValues({ formValues: this.state.formValues, formId });
    }

    onDescriptionChange = (event, { value }) => {
        const { formValues } = this.state;

        formValues.description.value = value;

        this.setState({ formValues });
    };

    // onEditorStateChange = (editorState) => {
    //   const { formValues } = this.state;
    //   formValues.descriptionEditorState = editorState;
    //   this.setState({ formValues });
    //   const contentState = convertToRaw(editorState.getCurrentContent());
    //   const markup = draftToHtml(
    //     contentState,
    //   );

    //   this.handleChange({}, { name: 'description', value: `<!DOCTYPE html><html><head></head><body>${markup}</body></html>` });
    // }

    render() {
        const { t } = this.props;
        const { formValues } = this.state;
        const {
            slug,
            // descriptionEditorState,
            description
        } = formValues;

        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='myStore.settings.form.store.header'>Store</Trans>
                    </Header>
                </Form.Field>
                {this.renderInputField({ name: 'name' }, this.props)}
                <Form.Field required error={slug.error}>
                    <label htmlFor='my-store-settings-form-store-slug'>
                        <Trans i18nKey='myStore.settings.form.store.slug.label'>Store Url</Trans>
                    </label>
                    <Input
                        type='text'
                        name='slug'
                        id='my-store-settings-form-store-slug'
                        value={slug.value}
                        placeholder={t('myStore.settings.form.store.slug.placeholder', { defaultValue: '' })}
                        labelPosition='left'
                        onChange={this.handleChange}
                    >
                        <Label basic>www.mybazar.com/</Label>
                        <input />
                    </Input>
                </Form.Field>
                {/* <Form.Field>
          <label htmlFor="sell-item-form-item-description">
            <Trans i18nKey="sellItem.form.item.description.label">Description</Trans>
          </label>

          <Editor
            editorState={descriptionEditorState}
            onEditorStateChange={this.onEditorStateChange}
            toolbar={{
              options: ['inline', 'list', 'link'],
              inline: {
                options: ['italic', 'bold', 'underline', 'strikethrough'],
              },
            }}
          />
        </Form.Field> */}
                <Form.Field>
                    <label htmlFor='my-store-form-store-description'>
                        <Trans i18nKey='myStore.form.store.description.label'>Description</Trans>
                    </label>
                    <TextArea
                        control={TextareaAutosize}
                        onChange={this.onDescriptionChange}
                        value={description.value}
                        name='description'
                        id='my-store-form-store-description'
                        rows={5} />
                </Form.Field>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(MyStoreSettingsFormStore);
