// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/actions';
import { getCategory } from 'store/utilities';

import SellItemFormItemView from './SellItemFormItemView';

type Props = {};

class SellItemFormItemContainer extends Component<Props> {
    render() {
        return <SellItemFormItemView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    rootCategories: state.rootCategories.map((id) => getCategory(state, id).entity),
    getCategory: (id) => getCategory(state, id).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(SellItemFormItemContainer);
