// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/actions';

import ItemImagesView from './ItemImagesView';

type Props = {};

class ItemFormItemContainer extends Component<Props> {
    render() {
        const { formId, ...rest } = this.props;
        return <ItemImagesView {...rest} formId={`${formId}.images`} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(null, mapDispatchToProps)(ItemFormItemContainer);
