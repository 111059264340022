// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn, getUser } from 'store/utilities';
import type { EntityData, User } from 'store/types';

import OrderDetails from 'scenes/OrderDetails';
import MyStoreView from './MyStoreView';

import MyStoreSettingsView from './scenes/MyStoreSettings';
import MyStoreOrdersView from './scenes/MyStoreOrders';
import MyStoreItemsView from './scenes/MyStoreItems';
import MyStoreCampaignsView from './scenes/MyStoreCampaigns';
import MyStoreMessagesView from './scenes/MyStoreMessages';

type State = {
    isLoadingAccounts: boolean
};

type Props = {
    isLoggedIn: boolean,
    isLoadingAccounts: boolean,
    merchantId: number,
    merchant: EntityData<User>,
    loadMyAccounts: typeof actions.loadMyAccounts,
    loadMyStoreSettings: typeof actions.loadMyStoreSettings
};

function loadData({ loadMyAccounts }: Props) {
    return loadMyAccounts();
}

class MyStoreContainer extends Component<Props, State> {
    state = {
        isLoadingAccounts: true
    };

    componentDidMount() {
        setTimeout(() => {
            const { isLoggedIn, merchantId, isLoadingAccounts, loadMyStoreSettings } = this.props;
            if (!isLoggedIn || !merchantId) {
                this.setState({ isLoadingAccounts: false });
                return;
            }

            if (!isLoadingAccounts) {
                loadData(this.props)
                    .then(() => this.setState({ isLoadingAccounts: false }))
                    .catch(() => this.setState({ isLoadingAccounts: false }));
            }

            loadMyStoreSettings({ fields: ['cover_photos', 'pictures', 'comments', 'merchant', 'store', 'is_liked'] }).catch((error) => console.error(error));
        }, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.merchant && this.state.isLoadingAccounts) {
            this.setState({ isLoadingAccounts: false });
        }
    }

    renderOrders = (matchProps) => {
        const { match } = matchProps;
        const { orderId } = match.params;

        const isNumeric = (str) => {
            if (typeof str != 'string') return false;
            return !isNaN(str) && !isNaN(parseFloat(str));
        };

        if (isNumeric(orderId)) {
            return <OrderDetails {...this.props} type='order' {...matchProps} />;
        } else {
            return <MyStoreOrdersView {...this.props} />;
        }
    };

    render() {
        const { isLoggedIn, merchant, location, user } = this.props;
        if (!this.state.isLoadingAccounts) {
            if (isLoggedIn && merchant) {
                return (
                    <Switch>
                        <Route exact path='/my-store' render={() => <MyStoreView {...this.props} />} />
                        <Route path='/my-store/settings' render={() => <MyStoreSettingsView {...this.props} />} />
                        <Route path='/my-store/orders/:orderId' render={this.renderOrders} />
                        <Route path='/my-store/orders' render={() => <MyStoreOrdersView {...this.props} />} />
                        <Route path='/my-store/inbox' render={() => <MyStoreMessagesView {...this.props} />} />
                        {/* <Route path="/my-store/campaigns" render={() => <MyStoreCampaignsView {...this.props} />} /> */}
                        <Route path='/my-store/items' render={() => <MyStoreItemsView {...this.props} />} />
                    </Switch>
                );
            }
            return <Redirect to={{ pathname: user ? '/select-profile' : '/login', state: { from: location.pathname } }} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
        isLoggedIn: getIsLoggedIn(state),
        merchantId: state.merchantData.user,
        settings: state.merchantData.settings,
        merchant: getUser(state, state.merchantData.user).entity,
        isLoadingAccounts: state.merchantData.isLoadingAccounts,
        user: getUser(state, state.userData.user).entity
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts),
    loadMyStoreSettings: wrapAPIDispatch(dispatch, actions.loadMyStoreSettings)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreContainer);
