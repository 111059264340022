// @flow
import React from 'react';
import { Form, Loader, Button, Label } from 'semantic-ui-react';
import { isEqual } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import { camelizeKeys } from 'humps';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';

const defaultValuesFromVariant = ({ variant, attributes, variantIndex }) => {
    const { sku, quantity, id } = variant;
    const formValues = {
        sku: createEmptyFormFieldData({ value: sku, required: true }),
        quantity: createEmptyFormFieldData({ value: quantity, type: 'number', required: true }),
        id: createEmptyFormFieldData({ value: id, type: 'number' })
    };
    attributes.forEach((attribute) => {
        const key = Object.keys(camelizeKeys({ [attribute.name]: '' })).pop();
        const value = variant.variantBody && variant.variantBody[key];
        if (value) {
            formValues[`attribute-${attribute.id}`] = createEmptyFormFieldData({ value, id: attribute.id });
        }
    });
    return formValues;
};

class ItemFormVariantDetails extends CommonForm {
    constructor(props) {
        super(props);
        const { variant, attributes, updateFormValues, formId, variantIndex } = props;

        let formValues = {};

        if (variant) {
            formValues = defaultValuesFromVariant({ variant, attributes, variantIndex });
        } else {
            formValues = {
                sku: createEmptyFormFieldData({ value: '', required: true }),
                quantity: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
                id: createEmptyFormFieldData({ value: '', type: 'number' })
            };
        }
        this.state = {
            formValues
        };
        updateFormValues({ formValues, formId });
    }

    validate = () => {
        const { deleted } = this.props;
        if (deleted) {
            return true;
        }

        let validates = true;

        const { formValues } = this.state;

        Object.values(this.childFormValidators).forEach((validate) => {
            validates = validates && validate();
        });

        Object.keys(formValues).forEach((field) => {
            validates = validates && this.validateField(field);
            formValues[field].error = !validates;
        });

        this.setState({ formValues });

        return validates;
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { formValues } = this.state;
        const { attributes } = nextProps;
        let hasUpdates = false;
        if (!isEqual(attributes, this.props.attributes)) {
            this.props.attributes.forEach((attribute) => {
                delete formValues[`attribute-${attribute.id}`];
            });
            hasUpdates = true;
        }
        attributes.forEach((attribute) => {
            if (!formValues[`attribute-${attribute.id}`]) {
                formValues[`attribute-${attribute.id}`] = createEmptyFormFieldData({ value: '', id: attribute.id });
                hasUpdates = true;
            }
        });
        if (hasUpdates) {
            this.setState({ formValues });
        }
    }

    render() {
        const { formValues } = this.state;
        const {
            id: { value: id }
        } = formValues;

        const { selectedSubSubcategoryId, attributeState, getCategoryAttribute, editable, expanded, deleted, onExpandClicked, onDeleteClicked, onSaveClicked } = this.props;

        const { attributes = [], isLoading = false } = attributeState;

        const attributeValues = attributes.map((id) => {
            const attVal = formValues[`attribute-${id}`] || {};
            return attVal.value;
        });

        if (editable && !expanded) {
            if (deleted && id === '') {
                return null;
            }
            return (
                <Form.Field style={{ paddingTop: '20px', paddingBottom: '20px' }}>
                    {attributeValues.join(', ')} ({formValues.quantity.value})
                    {deleted ? (
                        <Label negative>To be deleted</Label>
                    ) : (
                        <Button type='button' basic floated='right' onClick={onExpandClicked}>
                            <Trans i18nKey='sellItem.variants.actions.edit'>Edit</Trans>
                        </Button>
                    )}
                </Form.Field>
            );
        }
        return (
            <Form.Field>
                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderInputField({ name: 'sku', type: 'text', width: 8 }, this.props)}

                        {this.renderInputField({ name: 'quantity', type: 'number', width: 8 }, this.props)}
                    </Form.Group>
                </Form.Field>

                {selectedSubSubcategoryId && attributes && (
                    <div>
                        {attributes
                            .map((id) => {
                                const attribute = getCategoryAttribute(id);
                                const { name } = attribute;
                                return formValues[`attribute-${attribute.id}`]
                                    ? this.renderInputField(
                                        { name: `attribute-${attribute.id}`, defaultLabel: name.replace(/_/g, ' '), defaultPlaceholder: '', type: 'text', width: 8 },
                                        this.props
                                    )
                                    : null;
                            })
                            .filter(Boolean)
                            .reduce((pairs, current, index) => {
                                if (index % 2 === 0) {
                                    pairs.push([current]);
                                } else {
                                    pairs[pairs.length - 1].push(current);
                                }
                                return pairs;
                            }, [])
                            .map((pair, index) => (
                                <Form.Field key={index}>
                                    <Form.Group unstackable>
                                        {pair[0]}
                                        {pair.length === 2 && pair[1]}
                                    </Form.Group>
                                </Form.Field>
                            ))}
                    </div>
                )}
                {editable && expanded && (
                    <Form.Field style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                        <Button type='button' negative basic floated='left' onClick={onDeleteClicked}>
                            <Trans i18nKey='sellItem.variants.actions.delete'>Delete</Trans>
                        </Button>
                        <Button type='button' positive basic floated='right' onClick={onSaveClicked}>
                            <Trans i18nKey='sellItem.variants.actions.save'>Save</Trans>
                        </Button>
                    </Form.Field>
                )}

                {isLoading && <Loader active />}
            </Form.Field>
        );
    }
}
export default withTranslation('translations')(ItemFormVariantDetails);
