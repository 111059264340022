import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export const CREATE_MERCHANT_ACCOUNT = 'CREATE_MERCHANT_ACCOUNT';
export const createMerchantAccount = wrapAPIActionCreator(({ // alias,
    phoneNumber, description, webNotification, emailNotification, allowNegotiate = true, businessType, email, icNumber, isBlee, licenseName, licenseUrl, name, openToCustomer = true, slug, store, storeIndustry, username, referralCode, vatNumber, agentOfficeID, address }) => ({
    [API_DATA]: {
        type: CREATE_MERCHANT_ACCOUNT,
        endpoint: '/v3/me/accounts',
        authentication: Authentication.User,
        method: 'POST',
        body: {
            agentOfficeId: agentOfficeID,
            alias: name,
            allowNegotiate,
            businessType,
            description,
            email,
            emailNotification,
            gstRegistrationNumber: vatNumber,
            icNumber,
            isBlee,
            licenseName,
            licenseUrl,
            // name,
            openToCustomer,
            phoneNumber,
            referralCode,
            store,
            storeIndustry,
            userSlug: slug,
            username,
            webNotification,
            address
        },
        preprocess: ({ accounts }) => ({
            accounts: accounts.map(({ accessToken, ...rest }) => ({ user: rest, accessToken }))
        }),
        schema: {
            accounts: [{ user: Schemas.user }]
        }
    }
}));
