// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// SEARCH_USERS_BY_KEYWORD ------------------
export type SearchUsersByKeywordParams = {
    query: string,
    limit?: number,
    offset?: number,
    sort?: string,
    onlyBlee?: boolean
};

export const SEARCH_USERS_BY_KEYWORD = 'SEARCH_USERS_BY_KEYWORD';
export const searchUsersByKeyword = wrapAPIActionCreator(({ query, limit = 12, offset = 0, sort = 'latest', onlyBlee = false }: SearchUsersByKeywordParams) => ({
    [API_DATA]: {
        type: SEARCH_USERS_BY_KEYWORD,
        endpoint: '/v3/web/search/users',
        optionalAuthentication: Authentication.User,
        endpointParams: {
            query,
            limit,
            offset,
            sort,
            onlyBlee
        },
        reducerParams: {
            sort,
            offset,
            limit,
            query
        },
        schema: {
            users: [Schemas.user]
        },
        postprocess: (result) => ({ ...result, offset, query })
    }
}));
