export const sidebarLinkList = [
    {
        key: 'main',
        text: 'Main',
        list: [
            /* // Once /categories has been implemented
      {
        key: 'categories',
        text: 'Categories',
        icon: 'align left',
        link: '/categories',
      },
      */
            {
                key: 'discover',
                text: 'Discover',
                icon: 'search',
                link: 'http://www.mybazar.com/'
            },
            {
                key: 'categories',
                text: 'Categories',
                icon: 'accusoft',
                link: 'https://www.mybazar.com/categories'
            },
            {
                key: 'myPasar',
                text: 'Classifieds',
                icon: 'tag',
                link: 'https://www.mybazar.com/classifieds'
            },
            {
                key: 'latest',
                text: 'Latest',
                icon: 'heart outline',
                link: 'https://www.mybazar.com/latest'
            },
            {
                key: 'shoppingCart',
                text: 'Shopping Cart',
                icon: 'shopping cart',
                link: 'https://www.mybazar.com/cart'
            }
        ]
    },
    {
        key: 'user',
        text: 'User',
        list: [
            {
                key: 'myProfile',
                text: 'My Profile',
                icon: 'user',
                link: 'https://www.mybazar.com/my-profile'
            },
            {
                key: 'profileSettings',
                text: 'Profile Settings',
                icon: 'options',
                link: 'https://www.mybazar.com/my-profile/settings'
            },
            {
                key: 'shopperInbox',
                text: 'Shopper Inbox',
                icon: 'inbox',
                link: 'https://www.mybazar.com/my-profile/inbox'
            },
            {
                key: 'promotions',
                text: 'Promotions',
                icon: 'bullhorn',
                link: 'https://www.mybazar.com/my-profile/promotions'
            },
            {
                key: 'purchases',
                text: 'Purchases',
                icon: 'shopping bag',
                link: 'http://mybazar.com/my-profile/purchases'
            }
        ]
    },
    {
        key: 'merchant',
        text: 'Merchant',
        list: [
            {
                key: 'myStore',
                text: 'My Store',
                icon: 'building',
                link: 'https://www.mybazar.com/my-store'
            },
            {
                key: 'storeSettings',
                text: 'Store Settings',
                icon: 'settings',
                link: 'http://mybazar.com/my-profile/settings'
            },
            /*
      {
        key: 'previewStore',
        text: 'Preview Store',
        icon: 'eye',
        link: '/store-preview',
      },
      */
            {
                key: 'sellItem',
                text: 'Sell Item',
                icon: 'money',
                link: 'https://www.mybazar.com/sell-item'
            },
            {
                key: 'merchantInbox',
                text: 'Merchant Inbox',
                icon: 'inbox',
                link: '/my-store/inbox'
            },
            {
                key: 'campaigns',
                text: 'Campaigns',
                icon: 'bullhorn',
                link: '/my-store/campaigns'
            },
            {
                key: 'items',
                text: 'Items',
                icon: 'zip',
                link: '/my-store/items'
            },
            {
                key: 'orders',
                text: 'Orders',
                icon: 'list',
                link: '/my-store/orders'
            }
        ]
    },
    {
        key: 'becomeAMerchant',
        text: 'Become a Merchant',
        list: [
            {
                key: 'SellOnMyBazar',
                text: 'Sell on MyBazar',
                icon: 'money',
                link: 'https://www.mybazarglobal.com/sell/'
            }
        ]
    }
];
