// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

function SidebarProfileLogInSignUp() {
    return (
        <Grid className='auto tensed'>
            <Grid.Row>
                <Grid.Column>
                    <Button as={Link} to='/login' basic inverted fluid>
                        <Trans i18nKey='sidebar.profile.logIn'>Log In</Trans>
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Button as={Link} to='/signup' basic inverted fluid>
                        <Trans i18nKey='sidebar.profile.signUp'>Sign Up</Trans>
                    </Button>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default withTranslation('translations')(SidebarProfileLogInSignUp);
