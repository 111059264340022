// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getIsLoggedIn, getIsNonTempUser, getUser } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';
import FollowButtonView from './FollowButtonView';

type Props = {
    followId: number,
    isFollowing: boolean,
    isLoggedIn: boolean,
    followUser: typeof actions.followUser,
    parentId?: number
};

class FollowButtonContainer extends Component<Props> {
    state = {
        isLoading: false
    };

    handleOnFollow = (isFollowing: boolean) => {
        const { followId, currentUser, parentUser, parentId } = this.props;

        this.setState({ isLoading: true });
        this.props
            .followUser({
                userId: followId,
                currentUserId: currentUser.id,
                parentUserId: parentId,
                isFollowing,
                totalFollowers: parentUser ? parentUser.totalFollowers : undefined,
                totalFollowing: parentUser ? parentUser.totalFollowing : undefined
            })
            .then(() => {
                this.setState({ isLoading: false });
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    render() {
        return <FollowButtonView {...this.props} isLoading={this.state.isLoading} onFollow={this.handleOnFollow} />;
    }
}

const mapStateToProps = (state, { parentId }) => ({
    isLoggedIn: getIsLoggedIn(state),
    currentUser: getUser(state, state.userData.user).entity,
    parentUser: parentId ? getUser(state, parentId).entity : undefined,
    isNonTempUser: getIsNonTempUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    followUser: wrapAPIDispatch(dispatch, actions.followUser)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FollowButtonContainer);
