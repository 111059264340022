// @flow
import React, { Component } from 'react';
import { Image, Label, Input, Button } from 'semantic-ui-react';

import { serializeFile } from 'utilities';
import CropFileModal from 'components/Profile/components/CropFileModal';
import { withTranslation } from 'react-i18next';

class MyStoreProfilePicture extends Component {
    state = {
        loading: false,
        file: {
            value: '',
            error: false
        },
        fileDataURI: null
    };

    handleChange = (event, { name, value }) => {
        const target = this.state[name];
        target.value = value;
        this.setState({ [name]: target });
    };

    handleChangeFile = (event, data) => {
        this.handleChange(event, data);
        const file = event.target.files[0];
        this.setState({ imageFile: file });
        serializeFile(file).then(
            (dataURI) => this.setState({ fileDataURI: dataURI }),
            () => this.setState({ fileDataURI: null })
        );
    };

    render() {
        const verified = true;
        const { file, loading, fileDataURI } = this.state;
        const { onSubmitProfilePicture, isUploadingPicture, profilePictureUrl } = this.props;
        return (
            <div className={`store-logo${profilePictureUrl ? '' : ' store-logo-add'}`}>
                <div className='store-logo-circle'>
                    {profilePictureUrl && <Image className='store-logo-image' fluid src={profilePictureUrl} />}
                    <Button
                        as='label'
                        className='store-logo-button'
                        icon='camera'
                        size='massive'
                        loading={isUploadingPicture}
                        disabled={isUploadingPicture}
                        htmlFor='my-profile-picture-file'
                    />
                    <Input
                        required
                        error={file.error}
                        type='file'
                        name='file'
                        id='my-profile-picture-file'
                        className='store-logo-input'
                        value={file.value}
                        disabled={loading}
                        onChange={this.handleChangeFile}
                    />
                    {fileDataURI && (
                        <CropFileModal
                            fileURI={fileDataURI}
                            file={this.state.imageFile}
                            onCancel={() => this.setState({ file: { value: '', error: false }, fileDataURI: null, filename: null })}
                            onDone={(croppedFile) => {
                                this.setState({ file: { value: '', error: false }, fileDataURI: null, filename: null });
                                onSubmitProfilePicture(croppedFile);
                            }}
                        />
                    )}
                </div>
                {verified && <Label className='store-logo-label' circular icon='check' basic={!profilePictureUrl} />}
            </div>
        );
    }
}

export default withTranslation('translations')(MyStoreProfilePicture);
