// @flow
import React, { Component } from 'react';
import { Form, Image, Label, Dropdown } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    t: Function,
    formState: mixed,
    getCountry: (number) => void,
    handleChange: Function,
    handleChangeFile: Function,
    handleCountryChange: Function,
    handleStateChange: Function,
    handleBlur: Function
};

type InputFieldParams = {
    name: string,
    type: string,
    onChange?: Function,
    width?: number
};

class CreateStoreFormStore extends Component<Props, State> {
    constructor(props) {
        super(props);

        this.renderInputField = this.renderInputField.bind(this);
    }

    renderInputField(params: InputFieldParams) {
        const { name, type, onChange, width } = params;

        const { t, formState, handleChange, handleBlur } = this.props;

        return (
            <Form.Input
                width={width || 16}
                error={formState[name].error}
                type={type}
                name={name}
                id={`create-store-form-store-${name}`}
                onChange={onChange || handleChange}
                onBlur={handleBlur}
                label={t(`createStore.form.store.${name}.label`)}
                placeholder={t(`createStore.form.store.${name}.placeholder`, { defaultValue: '' })}
                value={formState[name].value}
                required
            />
        );
    }

    render() {
        const { t, formState, handleChange, handleChangeFile, handleCountryChange, handleStateChange, fileDataURI, countries, states, getCountry, getState, getCity } = this.props;

        const statesOptions = formState.country.value && states[formState.country.value] ? states[formState.country.value] : [];

        const selectedState = formState.state.value && getState(formState.state.value);

        const citiesOptions = selectedState ? selectedState.entity.cities : [];

        return (
            <Form.Field>
                <Form.Field error={formState.file.error}>
                    <label htmlFor='create-store-file'>
                        {formState.businessType.value === 'individual' ? (
                            <Trans i18nKey='createStore.form.store.file.labels.individual'>Id or Passport</Trans>
                        ) : (
                            <Trans i18nKey='createStore.form.store.file.labels.business'>Business License (SSM)</Trans>
                        )}
                    </label>
                    <Form.Input
                        error={formState.file.error}
                        type='file'
                        name='file'
                        id='create-store-file'
                        className={fileDataURI != null ? 'attached top' : ''}
                        value={formState.file.value}
                        disabled={formState.loading}
                        placeholder={t('createStore.form.store.file.placeholder', { defaultValue: '' })}
                        icon='file image outline'
                        iconPosition='left'
                        onChange={handleChangeFile}
                    />
                    {fileDataURI != null && <Image className='attached bottom' bordered rounded src={fileDataURI} />}
                </Form.Field>

                {this.renderInputField({ name: 'name', type: 'text' })}

                <Form.Field>
                    <Form.Input
                        required
                        error={formState.slug.error}
                        type='text'
                        name='slug'
                        id='create-store-slug'
                        value={formState.slug.value}
                        disabled={formState.loading}
                        label={t('createStore.form.store.slug.label')}
                        placeholder={t('createStore.form.store.slug.placeholder', { defaultValue: '' })}
                        labelPosition='left'
                        onChange={handleChange}
                    >
                        <Label basic>www.mybazar.com/</Label>
                        <input />
                    </Form.Input>
                </Form.Field>

                {this.renderInputField({ name: 'address', type: 'text' })}

                {this.renderInputField({ name: 'addressSecondary', type: 'text' })}

                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderInputField({ name: 'postal', type: 'text', width: 8 })}

                        <Form.Field required error={formState.country.error} width={8}>
                            <label htmlFor='create-store-country'>
                                <Trans i18nKey='createStore.form.store.country.label'>Country</Trans>
                            </label>
                            <Dropdown
                                selection
                                name='country'
                                id='create-store-country'
                                disabled={formState.loading}
                                placeholder={t('createStore.form.store.country.placeholder', { defaultValue: '' })}
                                options={countries.map((id) => {
                                    const { entity } = getCountry(id);
                                    return {
                                        text: entity.name,
                                        value: entity.id
                                    };
                                })}
                                onChange={handleCountryChange}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <Form.Group unstackable>
                        <Form.Field required error={formState.state.error} width={8}>
                            <label htmlFor='create-store-state'>
                                <Trans i18nKey='createStore.form.store.state.label'>State</Trans>
                            </label>
                            <Dropdown
                                selection
                                name='state'
                                id='create-store-state'
                                disabled={formState.loading}
                                placeholder={t('createStore.form.store.state.placeholder', { defaultValue: '' })}
                                options={statesOptions.map((id) => {
                                    const { entity } = getState(id);
                                    return {
                                        text: entity.district,
                                        value: entity.id
                                    };
                                })}
                                onChange={handleStateChange}
                            />
                        </Form.Field>
                        <Form.Field width={8}>
                            <label htmlFor='create-store-city'>
                                <Trans i18nKey='createStore.form.store.city.label'>City</Trans>
                            </label>
                            <Form.Dropdown
                                required
                                error={formState.city.error}
                                selection
                                name='city'
                                id='create-store-city'
                                disabled={formState.loading}
                                placeholder={t('createStore.form.store.city.placeholder', { defaultValue: '' })}
                                options={citiesOptions.map((id) => {
                                    const { entity } = getCity(id);
                                    return {
                                        text: entity.name,
                                        value: entity.id
                                    };
                                })}
                                onChange={handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form.Field>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(CreateStoreFormStore);
