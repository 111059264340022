// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

// import MessagesConversationMore from './components/MessagesConversationMore';
import MessageList from './components/MessageList';
import SendMessageForm from './components/SendMessageForm';
import MakeOfferForm from './components/MakeOfferForm';

type Props = {
    messages: []
};

function MessagesConversationView(props: Props) {
    const { type } = props;
    return (
        <Grid className='auto tensed'>
            {/* <Grid.Column className="col-row">
        <Grid className="auto tensed">
          <Grid.Column>
            <Header as="h2">Name</Header>
          </Grid.Column>
          <Grid.Column className="col-shrink">
            <MessagesConversationMore />
          </Grid.Column>
        </Grid>
      </Grid.Column> */}
            <Grid.Column className='col-row'>
                <MessageList {...props} />
            </Grid.Column>
            {type === 'myStoreMessages' && (
                <Grid.Column className='col-row'>
                    <MakeOfferForm {...props} />
                </Grid.Column>
            )}
            <Grid.Column className='col-row'>
                <SendMessageForm {...props} />
            </Grid.Column>
        </Grid>
    );
}

export default MessagesConversationView;
