// @flow
import React, { useState } from 'react';
import { Button, Form, Label, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { getUserDeliveryAddress } from 'store/utilities';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalNames, registerUser } from 'store/actions';
import { push } from 'connected-react-router';

const RegisterAccountView = (props) => {
    const [loading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [modalData, setModalData] = useState({ username: { value: '', error: false }, password: { value: '', error: false } });

    const dispatch = useDispatch();
    const { t } = props;
    const deliveryAddress = useSelector((state) => getUserDeliveryAddress(state, state.userData.cart.addressId));
    const handleChange = (event) => {
        setModalData({ ...modalData, [event.target.name]: { ...modalData[event.target.name], value: event.target.value } });
    };

    const handleBlur = (event) => {
        validate(event.target.name).catch(() => {});
    };

    const handleSubmit = (event) => {
        const alias = deliveryAddress.entity.fullName;
        const email = deliveryAddress.entity.emailAddress;
        const phoneNumber = deliveryAddress.entity.contactNo;
        const username = modalData.username.value;
        const password = modalData.password.value;

        event.preventDefault();
        setIsLoading(true);

        validate('username', 'password')
            .then(() =>
                dispatch(
                    registerUser({
                        email,
                        username,
                        phoneNumber,
                        password,
                        alias,
                        userType: 'shopper'
                    })
                )
            )
            .then(() => {
                setIsLoading(false);
                dispatch(closeModal(ModalNames.REGISTER_ACCOUNT));
                dispatch(push('/signup'));
            })
            .catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.message);
            });
    };

    const validate = (...inputs) => {
        let valid = true;
        inputs.forEach((input) => {
            const target = modalData[input];
            target.error = target.value.length === 0;
            if (target.error) {
                valid = false;
            }
            setModalData({ ...modalData, [input]: target });
        });
        return valid ? Promise.resolve() : Promise.reject(new Error('Some fields were invalid'));
    };

    return (
        <Form onSubmit={handleSubmit} error={errorMessage !== null}>
            <Message error>{errorMessage}</Message>
            <Form.Field required error={modalData.username.error}>
                <label htmlFor='sign-up-username'>
                    <Trans i18nKey='signUpForm.username.label'>Username</Trans>
                </label>
                <input
                    type='text'
                    name='username'
                    id='sign-up-username'
                    value={modalData.username.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('signUpForm.username.placeholder', { defaultValue: '' })}
                    disabled={loading}
                />
                {modalData.username.error && (
                    <Label basic color='red' pointing>
                        <Trans i18nKey='signUpForm.username.error.required'>This field is required.</Trans>
                    </Label>
                )}
            </Form.Field>
            <Form.Field required error={modalData.password.error}>
                <label htmlFor='sign-up-password'>
                    <Trans i18nKey='signUpForm.password.label'>Password</Trans>
                </label>
                <input
                    type='password'
                    name='password'
                    id='sign-up-password'
                    value={modalData.password.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder={t('signUpForm.password.placeholder', { defaultValue: '' })}
                    disabled={loading}
                />
                {modalData.password.error && (
                    <Label basic color='red' pointing>
                        <Trans i18nKey='signUpForm.password.error.required'>This field is required.</Trans>
                    </Label>
                )}
            </Form.Field>
            <Form.Field>
                <Button className='standard-color-button' type='submit' onClick={handleSubmit} fluid primary loading={loading} disabled={loading}>
                    <Trans i18nKey='signUpForm.submit'>Sign Up</Trans>
                </Button>
            </Form.Field>
        </Form>
    );
};

export default compose(withTranslation('translations'))(RegisterAccountView);
