// @flow
import React from 'react';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import DeliveryAddressModal from '../DeliveryAddressModal';

type Props = {
    onSubmit: () => void,

    backendError: string
};

function DeliveryAddressAddModalView({ onSubmit, backendError, ...rest }: Props) {
    return (
        <DeliveryAddressModal
            title={<Trans i18nKey='deliveryAddress.add.title'>Add Delivery Address</Trans>}
            acceptButtonLabel={[
                <Trans key='trans' i18nKey='deliveryAddress.add.acceptButton'>
                    Add Delivery Address
                </Trans>,
                <Icon key='icon' name='check' />
            ]}
            backendError={backendError}
            onSubmit={onSubmit}
            {...rest}
        />
    );
}

export default compose(withTranslation('translations'))(DeliveryAddressAddModalView);
