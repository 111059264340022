// @flow
import React from 'react';
import { Form, Label, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

type Props = {
    formId: string
};

type State = {
    errorMessage: ?string,
    price: FormFieldData<number>,
    applyVAT: FormFieldData<boolean>,
    dealType: FormFieldData<string>,
    salePrice: FormFieldData<number>
};

export const dealTypes = [
    {
        value: 'none'
    },
    {
        value: 'sale'
    }
];

const differenceInPercent = (priceString, salePriceString) => {
    let percent = 100;
    if (!priceString || !salePriceString) {
        return `${percent}%`;
    }
    const price = parseFloat(priceString);
    const salePrice = parseFloat(salePriceString);
    if (salePrice === 0) {
        return `${percent}%`;
    }
    if (salePrice < price) {
        percent = `-${(((price - salePrice) / price) * 100).toFixed(2)}`;
    }
    return `${percent}%`;
};

const defaultValuesFromItem = ({ item }) => {
    const { price, isGstEnabled, dealTypes, isDeal } = item;

    let dealType = 'none';
    let salePrice = price;
    if (isDeal) {
        if (dealTypes.buy3For2) {
            dealType = 'buy_3_for_2';
        } else if (dealTypes.sample) {
            dealType = 'sample';
        } else {
            dealType = 'sale';
            salePrice = dealTypes.sale;
        }
    }

    return {
        price: createEmptyFormFieldData({ value: price, type: 'number', required: true }),
        applyVAT: createEmptyFormFieldData({ value: isGstEnabled === 1 || isGstEnabled === true, type: 'boolean' }),
        dealType: createEmptyFormFieldData({ value: dealType }),
        salePrice: createEmptyFormFieldData({ value: salePrice, type: 'number' })
    };
};

class ItemFormPrice extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { item, updateFormValues, formId } = props;

        let formValues = {};

        if (item) {
            formValues = defaultValuesFromItem({ item });
        } else {
            formValues = {
                price: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
                applyVAT: createEmptyFormFieldData({ value: false, type: 'boolean' }),
                dealType: createEmptyFormFieldData({ value: 'none' }),
                salePrice: createEmptyFormFieldData({ value: '', type: 'number' })
            };
        }
        this.state = {
            formValues
        };
        updateFormValues({ formValues, formId });
    }

    render() {
        const { formValues } = this.state;
        const { formId, translationId, t } = this.props;

        const dealOptions = dealTypes.map(({ value }) => {
            const text = t(`sellItem.form.price.dealType.options.${value}`);
            return { value, text };
        });

        const {
            dealType: { value: dealType },
            price: { value: price },
            salePrice: { value: salePrice }
        } = formValues;

        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='sellItem.form.price.header'>Price</Trans>
                    </Header>
                </Form.Field>

                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderInputField({ name: 'price', type: 'number', step: '0.01', width: 8 }, this.props)}

                        {this.renderCheckboxField({ name: 'applyVAT', type: 'boolean', width: 8 }, this.props)}
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderDropdownField({ name: 'dealType', options: dealOptions, width: 8 }, this.props)}

                        {(() => {
                            switch (dealType) {
                                case 'none':
                                    return null;
                                case 'sale':
                                    return (
                                        <Form.Field width={8}>
                                            <label htmlFor={`${formId}-saleprice`}>
                                                <Trans i18nKey={`${translationId}.salePrice.label`}>Sale Price</Trans>
                                            </label>
                                            <Form.Input
                                                type='text'
                                                name='salePrice'
                                                value={salePrice}
                                                step='0.01'
                                                id={`${formId}-saleprice`}
                                                labelPosition='right'
                                                onChange={(event, { value, ...data }) => {
                                                    let newValue = value;
                                                    if (parseFloat(value) > parseFloat(price)) {
                                                        newValue = salePrice;
                                                    }
                                                    this.handleChange(event, { ...data, value: newValue });
                                                }}
                                                placeholder={`KM ${salePrice}`}
                                            >
                                                <input />
                                                <Label basic>{differenceInPercent(price, salePrice)}</Label>
                                            </Form.Input>
                                        </Form.Field>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </Form.Group>
                </Form.Field>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ItemFormPrice);
