// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadOrderByIdParams = {
    orderId: number,
    authentication: Authentication.User | Authentication.Merchant
};

// LOAD_ORDER_BY_ID ----------------------
export const LOAD_ORDER_BY_ID = 'LOAD_ORDER_BY_ID';
export const loadOrderById = wrapAPIActionCreator(({ orderId, authentication }: LoadOrderByIdParams) => ({
    [API_DATA]: {
        type: LOAD_ORDER_BY_ID,
        endpoint: `/v3/orders/${orderId}`,
        authentication,
        method: 'GET',
        schema: {
            order: Schemas.order
        },
        reducerParams: { authentication, orderId }
    }
}));
