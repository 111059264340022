// @flow
import * as ActionTypes from 'store/actions';

const initialState = {
    hasMoreItemsByTags: true
};

function tagsByKeyword(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_TAGS_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state,
                    tagsByKeywordIsLoading: true
                };
            }
            if (action.status === 'success') {
                const { keyword, offset, result } = action.payload;
                const { items } = result;
                const { sort, limit } = action.reducerParams;
                let existingItems = [];
                let hasMoreItemsByTags = true;

                if (state[keyword]) {
                    if (state[keyword].sort === sort) {
                        existingItems = [...state[keyword].existingItems];
                    }
                }

                if (offset === existingItems.length) {
                    existingItems = existingItems.concat(items);
                }

                if (items.length !== limit) {
                    hasMoreItemsByTags = false;
                }
                return {
                    ...state,
                    hasMoreItemsByTags,
                    [keyword]: { sort, existingItems },
                    tagsByKeywordIsLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    tagsByKeywordIsLoading: false
                };
            }
            break;
        default:
            break;
    }
    return state;
}

export default tagsByKeyword;
