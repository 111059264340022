// @flow
import { Component } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

type Props = {
    location: {},
    children: {}
};

class ScrollToTop extends Component<Props> {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }

    render() {
        return this.props.children;
    }
}
export default compose(withRouter, withTranslation('translations'))(ScrollToTop);
