// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type SetCartPromoCodeParams = {
    promoCode: number
};

export const SET_CART_PROMO_CODE = 'SET_CART_PROMO_CODE';
export const setCartPromoCode = wrapAPIActionCreator(({ promoCode }: SetCartPromoCodeParams) => ({
    [API_DATA]: {
        type: SET_CART_PROMO_CODE,
        endpoint: '/v3/cart_items/set_promo_code',
        method: 'POST',
        authentication: Authentication.User,
        body: {
            promoCode
        },
        schema: {
            cartItems: [Schemas.cartItem],
            addresses: [Schemas.userDeliveryAddress]
        }
    }
}));

export default setCartPromoCode;
