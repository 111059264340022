// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import ShippingToView from './ShippingToView';

type Props = {};

class ShippingToContainer extends Component<Props> {
    componentDidMount() {
        this.props.loadShippingLocations().catch((err) => console.error(err));
        this.props.loadReturnPolicies().catch((err) => console.error(err));
    }

    render() {
        const { formId, translationId, ...rest } = this.props;
        //console.log('ShippingToContainer', this.props);
        return <ShippingToView formId={`${formId}.shippingTo`} translationId={`${translationId}.shippingTo`} {...rest} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    loadShippingLocations: wrapAPIDispatch(dispatch, actions.loadShippingLocations),
    loadReturnPolicies: wrapAPIDispatch(dispatch, actions.loadReturnPolicies)
});

export default connect(null, mapDispatchToProps)(ShippingToContainer);
