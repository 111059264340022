// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    list: undefined,
    isLoading: false,
    primaryBankAccount: undefined
};

function shippingCosts(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_BANK_ACCOUNTS: {
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'success') {
                const { bankAccounts = [] } = action.payload.entities;
                const primaryBankAccount = Object.values(bankAccounts).reduce((acc, cur) => (cur.isPrimary === 1 ? cur.id : acc), null);

                return {
                    ...state,
                    list: action.payload.result.bankAccounts,
                    isLoading: false,
                    primaryBankAccount
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            break;
        }
        case ActionTypes.CREATE_BANK_ACCOUNT: {
            if (action.status === 'success') {
                const list = state.list || [];
                const { bankAccount } = action.payload.result;
                return {
                    ...state,
                    list: [...list, bankAccount],
                    primaryBankAccount: bankAccount
                };
            }
            break;
        }
        case ActionTypes.DELETE_BANK_ACCOUNT: {
            if (action.status === 'success') {
                const list = state.list || [];
                const { id } = action.reducerParams;
                return {
                    ...state,
                    list: list.filter((accountId) => accountId !== id)
                };
            }
            break;
        }
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default shippingCosts;
