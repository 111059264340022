// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn, getCartItem, getItem, getVariant, getOffer } from 'store/utilities';
import type { EntityData, Item } from 'store/types';

import ProductActionsView from './ProductActionsView';

type Props = {
    itemId: number,
    selectedVariant: ?number,
    // from store
    cartItem: EntityData<Item>,
    isInStore: boolean,
    isLoggedIn: boolean,
    openModal: typeof actions.openModal,
    addCartItem: typeof actions.addCartItem,
    updateCartItem: typeof actions.updateCartItem,
    deleteCartItem: typeof actions.deleteCartItem
};

type State = {
    isLoadingAdd: boolean,
    isLoadingUpdate: boolean,
    isLoadingRemove: boolean
};

class ProductActionsContainer extends Component<Props, State> {
    state = {
        isLoadingAdd: false,
        isLoadingUpdate: false,
        isLoadingRemove: false
    };

    handleAddToCart = () => {
        const { selectedVariant, itemId, isLoggedIn, addCartItem, loginUser, registerUser } = this.props;

        this.setState({ isLoadingAdd: true });

        let authPromise = Promise.resolve();
        if (!isLoggedIn) {
            // openModal(actions.ModalNames.LOGIN);
            const baseName = `tempuser${Date.now().toString()}`;
            authPromise = registerUser({
                email: `${baseName}@mybazar.com`,
                username: baseName,
                password: baseName,
                alias: 'Anonimni kupac',
                // TODO: support for merchant registration
                userType: 'shopper',
                sendWelcomeEmail: false
            }).then(() => loginUser({ email: `${baseName}@mybazar.com`, password: baseName }));
        }

        authPromise.then(() => {
            addCartItem({ itemId, variantId: selectedVariant }).then(
                () => this.setState({ isLoadingAdd: false, isLoadingUpdate: false }),
                () => this.setState({ isLoadingAdd: false, isLoadingUpdate: false })
            );
        });
    };

    handleRemoveFromCart = () => {
        const { cartItem, deleteCartItem } = this.props;
        this.setState({ isLoadingRemove: true });
        deleteCartItem({ cartItemId: cartItem.entity.id }).then(
            () => this.setState({ isLoadingRemove: false, isLoadingUpdate: false }),
            () => this.setState({ isLoadingRemove: false, isLoadingUpdate: false })
        );
    };

    handleIncrementQuantity = () => {
        const { cartItem, updateCartItem } = this.props;
        const { id, quantity } = cartItem.entity || {};
        this.setState({ isLoadingUpdate: true });
        updateCartItem({ cartItemId: id, quantity: quantity + 1 }).then(
            () => this.setState({ isLoadingUpdate: false }),
            () => this.setState({ isLoadingUpdate: false })
        );
    };

    handleDecrementQuantity = () => {
        const { selectedVariant, cartItem, deleteCartItem, updateCartItem } = this.props;
        const { id, quantity } = cartItem.entity || {};
        this.setState({ isLoadingUpdate: true });
        (quantity <= 1 ? deleteCartItem({ cartItemId: id, variantId: selectedVariant }) : updateCartItem({ cartItemId: id, quantity: quantity - 1 })).then(
            () => this.setState({ isLoadingUpdate: false }),
            () => this.setState({ isLoadingUpdate: false })
        );
    };

    render() {
        const { ...rest } = this.props;
        const { isLoadingAdd, isLoadingUpdate, isLoadingRemove } = this.state;
        return (
            <ProductActionsView
                onAddToCart={this.handleAddToCart}
                onRemoveFromCart={this.handleRemoveFromCart}
                onIncrementQuantity={this.handleIncrementQuantity}
                onDecrementQuantity={this.handleDecrementQuantity}
                {...{ isLoadingAdd, isLoadingUpdate, isLoadingRemove }}
                {...rest}
            />
        );
    }
}

const mapStateToProps = (state, { itemId, selectedVariant }) => {
    const cartItems = state.userData.cart.cartItems.map((id) => getCartItem(state, id));

    const cartItem = cartItems.find(({ entity }) => entity && entity.itemId === itemId && entity.variantId === selectedVariant);
    const isInCart = cartItem != null && cartItem.entity != null;

    const item = getItem(state, itemId);
    const variants = item.entity ? item.entity.variants.map((id) => getVariant(state, id)) : [];

    const isCartLoading = !cartItems.every(({ entity }) => entity != null);

    return {
        cartItem,
        variants,
        isInCart,
        isCartLoading,
        item,
        isLoggedIn: getIsLoggedIn(state)
    };
};

const mapDispatchToProps = (dispatch) => ({
    addCartItem: wrapAPIDispatch(dispatch, actions.addCartItem),
    updateCartItem: wrapAPIDispatch(dispatch, actions.updateCartItem),
    deleteCartItem: wrapAPIDispatch(dispatch, actions.deleteCartItem),
    openModal: (...args) => dispatch(actions.openModal(...args)),
    registerUser: wrapAPIDispatch(dispatch, actions.registerUser),
    loginUser: wrapAPIDispatch(dispatch, actions.loginUser),
    loadCartItems: wrapAPIDispatch(dispatch, actions.loadCartItems),
    setIsLoggingIn: wrapAPIDispatch(dispatch, actions.setIsLoggingIn)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProductActionsContainer);
