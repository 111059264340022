// @flow
import React from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import { registerUser } from 'store/actions';

import SignUpFormView from './view';

function SignUpForm(props) {
    return <SignUpFormView {...props} />;
}

const mapStateToProps = (state) => ({
    facebookInformation: state.signup.storeFacebookInformation.information,
    isLoggedIn: state.userData.accessToken !== null
});
const mapDispatchToProps = (dispatch) => ({
    registerUser: wrapAPIDispatch(dispatch, registerUser)
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);
