// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import MyProfilePromotionsView from './MyProfilePromotionsView';

type Props = {
    loadPromotions: typeof actions.loadPromotions,
    loadMyConversations: typeof actions.loadMyConversations
};

class MyProfilePromotionsContainer extends Component<Props, {}> {
    componentDidMount() {
        this.loadPromotions();
        this.loadConversations().then(() => {
            this.props.markAllSystemMessagesAsRead();
        });
    }

    loadConversations = () => {
        const { loadMyConversations, isLoadingConversations } = this.props;
        if (!isLoadingConversations) {
            loadMyConversations({ offset: 0 }).catch((error) => console.error(error));
        }
        return Promise.resolve(true);
    };

    loadPromotions = () => {
        const { loadPromotions, isLoadingPromotions } = this.props;
        if (!isLoadingPromotions) {
            loadPromotions({ offset: 0, limit: 10 }).catch((error) => console.error(error));
        }
    };

    render() {
        return <MyProfilePromotionsView />;
    }
}

const mapStateToProps = (state) => ({
    isLoadingConversations: state.userData.conversations.isLoading,
    isLoadingPromotions: state.userData.promotions.isLoading
});

const mapDispatchToProps = (dispatch) => ({
    loadPromotions: wrapAPIDispatch(dispatch, actions.loadPromotions),
    loadMyConversations: wrapAPIDispatch(dispatch, actions.loadMyConversations),
    markAllSystemMessagesAsRead: wrapAPIDispatch(dispatch, actions.markAllSystemMessagesAsRead)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfilePromotionsContainer);
