// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, List } from 'semantic-ui-react';

import type { EntityData, UserDeliveryAddress } from 'store/types';

type Props = {
    deliveryAddress: ?EntityData<UserDeliveryAddress>
};

function CheckoutAddressDetails({ deliveryAddress }: Props) {
    const { fullName, houseNo, street, zip, city, state, country, contactNo } = deliveryAddress.entity || {};
    return (
        <Grid.Column>
            {deliveryAddress && (
                <List>
                    <List.Item>
                        <List.Content>
                            <strong>{fullName}</strong>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            {houseNo && `${houseNo}, `}
                            {street}, {city}
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>
                            {zip} {state}
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>{country}</List.Content>
                    </List.Item>
                    <List.Item>
                        <List.Content>{contactNo}</List.Content>
                    </List.Item>
                </List>
            )}
        </Grid.Column>
    );
}

export default withTranslation('translations')(CheckoutAddressDetails);
