// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Segment, Header, Image, Label, Button, Divider, GridColumn } from 'semantic-ui-react';
import RichTextView from 'components/RichTextView';

import { formatPrice, formatDatePretty } from 'utilities/format';
import { OfferStatus } from 'store/enums';

import OfferActionForm from '../../../OfferActionForm';

import customerIcon from 'styles/assets/icons/customer-icon.svg';
import storeIcon from 'styles/assets/icons/store-icon.svg';
import agentIcon from 'styles/assets/icons/agent-icon.svg';
import { UserTypes } from 'store/enums';

type Props = {
    message: {},
    t: (string) => string
};

function MessagesConversationListItemView(props: Props) {
    const { message: messageData, me: meData, otherUser: otherUserData, offer: offerData, lastOfferId, negotiate, order: orderData, t } = props;
    const { entity: message } = messageData;
    const {
        entity: { pictures }
    } = messageData;
    const { entity: me } = meData;
    const { entity: otherUser } = otherUserData;
    const { entity: offer } = offerData;
    const { entity: order } = orderData;

    const isOwnMessage = message.sender === me.id;

    let offeredPrice;
    if (offer) {
        const { offerPrice } = offer;
        const formattedOfferPrice = formatPrice(Number(offerPrice));
        offeredPrice = formattedOfferPrice;
    }

    const sender = isOwnMessage ? me : otherUser;

    const missingUserImage = sender.userType === UserTypes.SHOPPER ? customerIcon : sender.userType === UserTypes.MERCHANT ? storeIcon : agentIcon;

    const profileUrl = isOwnMessage ? me.pictureThumb : otherUser.pictureThumb;
    const alias = isOwnMessage ? me.alias : otherUser.alias;

    let { message: messageBody = '' } = message;
    if (messageBody && !messageBody.startsWith('<!DOCTYPE html>')) {
        messageBody = `<!DOCTYPE html><html><body>${messageBody}</body></html>`;
    }

    return (
        <Grid className={'auto' + ' tensed' + ' grid-flex-direction-row-reverse' + ' message-conversation-list-item'}>
            <Grid.Column className={`${negotiate ? 'col-14' : 'col-15'}`}>
                <div className='message-conversation-list-item-message'>
                    <Grid className='auto tensed'>
                        <Grid.Column className='col-row'>
                            <Grid className='auto very-tensed'>
                                <Grid.Column className='col-row'>
                                    <Header as='h3' size='tiny'>
                                        {alias}
                                        <Header.Subheader as='span' className='color-de-emphasized font-size-small message-timestamp'>
                                            {formatDatePretty(new Date(message.createdAt), t, true)}
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                {pictures &&
                                    pictures.map((picture) => (
                                        <Grid.Column className='col-row' key={picture.imageThumb}>
                                            <Image rounded wrapped as='a' target='_blank' href={picture.imageFull} src={picture.imageThumb} />
                                        </Grid.Column>
                                    ))}
                                <Grid.Column className='col-row message-text'>
                                    <RichTextView sourceText={messageBody} />
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            {((offer && offer.status === OfferStatus.OPEN && offer.id === lastOfferId) ||
                                (offer && offer.status === OfferStatus.ACCEPTED) ||
                                (offer && offer.status === OfferStatus.CLOSED) ||
                                (offer && offer.status === OfferStatus.EXPIRED) ||
                                order) && <Divider fitted />}
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            {!isOwnMessage && offer && offer.status === OfferStatus.OPEN && offer.id === lastOfferId && <OfferActionForm {...props} />}
                            {isOwnMessage && offer && offer.status === OfferStatus.OPEN && offer.id === lastOfferId && <OfferActionForm {...props} merchantSide='merchant' />}
                            {offer && offer.status === OfferStatus.EXPIRED && (
                                <Grid.Column className='col-row'>
                                    <p style={{ marginBottom: '-20px' }}>
                                        <strong>
                                            <Trans i18nKey='messages.conversation.offer.offeredPrice'>Offered Price</Trans>: {offeredPrice}
                                        </strong>
                                    </p>
                                    <Header as='h5' style={{ color: 'black' }}>
                                        <Trans i18nKey='messages.conversation.offer.offerExp'>Offer expired</Trans>
                                    </Header>
                                    <Label color='gray'>
                                        <Trans i18nKey='messages.conversation.offer.labelExp'>Expired</Trans>
                                    </Label>
                                </Grid.Column>
                            )}

                            {offer && offer.status === OfferStatus.ACCEPTED && (
                                <GridColumn className='col-row'>
                                    <p style={{ marginBottom: '-20px' }}>
                                        <strong>
                                            <Trans i18nKey='messages.conversation.offer.offeredPrice'>Offered Price</Trans>: {offeredPrice}
                                        </strong>
                                    </p>
                                    {isOwnMessage ? (
                                        <Header as='h5' style={{ color: 'black' }}>
                                            <Trans i18nKey='messages.conversation.offer.offerAcc'>Your offer has been accepted</Trans>
                                        </Header>
                                    ) : (
                                        <Header as='h5' style={{ color: 'black' }}>
                                            <Trans i18nKey='messages.conversation.offer.offerAccCustomer'>You have accepted the offer</Trans>
                                        </Header>
                                    )}
                                    <Label color='black'>
                                        <Trans i18nKey='messages.conversation.offer.labelAccepted'>Accepted</Trans>
                                    </Label>
                                </GridColumn>
                            )}
                            {offer && offer.status === OfferStatus.CLOSED && (
                                <GridColumn className='col-row'>
                                    <p style={{ marginBottom: '-20px' }}>
                                        <strong>
                                            <Trans i18nKey='messages.conversation.offer.offeredPrice'>Offered Price</Trans>: {offeredPrice}
                                        </strong>
                                    </p>
                                    {isOwnMessage ? (
                                        <Header as='h5' style={{ color: 'black' }}>
                                            <Trans i18nKey='messages.conversation.offer.offerDec'>Your offer has been rejected</Trans>
                                        </Header>
                                    ) : (
                                        <Header as='h5' style={{ color: 'black' }}>
                                            <Trans i18nKey='messages.conversation.offer.offerDecCustomer'>You have rejected the offer</Trans>
                                        </Header>
                                    )}
                                    <Label color='gray'>
                                        <Trans i18nKey='messages.conversation.offer.labelDeclined'>Declined</Trans>
                                    </Label>
                                </GridColumn>
                            )}
                            {order && (
                                <Button as={Link} to={`/my-profile/purchases/${order.id}`} primary floated='right'>
                                    Pogledajte narudžbu
                                </Button>
                            )}
                        </Grid.Column>
                    </Grid>
                </div>
            </Grid.Column>

            <Grid.Column className={`${negotiate ? 'col-2' : 'col-1'}`}>
                <Image
                    className='circular-image'
                    wrapped
                    src={profileUrl || missingUserImage}
                    onError={(event) => {
                        event.target.src = missingUserImage;
                    }}
                />
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(MessagesConversationListItemView);
