// @flow
import React, { Component } from 'react';
import { serializeFile } from 'utilities';
import FileUploaderView from './FileUploaderView';

type Props = {
    acceptedFiletypes?: [string],
    onFileChange: (file: string | ArrayBuffer) => void
};

type State = {
    file: ?File
};

class FileUploaderContainer extends Component<Props, State> {
    static defaultProps = {
        acceptedFiletypes: 'image/png, image/jpeg, image/jpg'
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            file: undefined
        };
    }

    fileInputRef = React.createRef();

    handleFileChange = async ({ target }: { target: HTMLInputElement }) => {
        const { onFileChange } = this.props;
        if (target.files.length) {
            const file: File = target.files[0];
            this.setState({ file });
            const data: string | ArrayBuffer = await serializeFile(file);
            onFileChange(data);
        }
        this.setState({ file: undefined });
        return '';
    };

    render() {
        const { acceptedFiletypes } = this.props;
        const { file } = this.state;
        return <FileUploaderView acceptedFiletypes={acceptedFiletypes} onFileChange={this.handleFileChange} file={file} fileInputRef={this.fileInputRef} />;
    }
}

export default FileUploaderContainer;
