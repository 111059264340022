// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_STORE_ACTIVITY ----------------------
export const LOAD_MY_STORE_ACTIVITY = 'LOAD_MY_STORE_ACTIVITY';
export const loadMyStoreActivity = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_MY_STORE_ACTIVITY,
        endpoint: '/v3/me/profile/activity',
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {},
        preprocess: ({ activity }) => activity
    }
}));
