// @flow
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Divider, Header, Icon, Popup, Image, Grid } from 'semantic-ui-react';

import { formatItemName } from 'utilities/format';

import Price from 'components/Price';
import type { EntityData, CartItem, Item as ItemT, Store, User, Offer } from 'store/types';
import missingImage from 'styles/assets/images/missing-image.svg';
import OptionDropdown from './components/OptionDropdown';
import QuantityDropdown from './components/QuantityDropdown';
import RemoveButton from './components/RemoveButton';
import VariantDataList from './components/VariantDataList';

import type { Control } from './index';
import { withTranslation } from 'react-i18next';

type Props = {
    showQuantity?: boolean,
    step?: number,
    controls: Control[],
    // control: remove
    isRemoving: boolean,
    onRemoveItem: () => void,
    // control: quantity
    isQuantityLoading: boolean,
    onQuantityChange: (number) => void,
    // control: delivery options
    isDeliveryOptionLoading: boolean,
    onDeliveryOptionChange: (number) => void,
    // control: payment options
    isPaymentOptionLoading: boolean,
    onPaymentOptionChange: (number) => void,
    // from store
    cartItem: EntityData<CartItem>,
    item: EntityData<ItemT>,
    offer: EntityData<Offer>,
    store: EntityData<Store>,
    merchant: EntityData<User>,
    // translation
    t: (string) => string
};

class CartListItem extends Component<Props> {
    renderRemoveButton() {
        const { onRemoveItem, isRemoving } = this.props;
        return <RemoveButton key='remove' onClick={onRemoveItem} loading={isRemoving} />;
    }

    renderQuantityDropdown() {
        const { cartItem, isQuantityLoading, onQuantityChange, t } = this.props;
        if (cartItem.isFetching || cartItem.entity == null) {
            return null;
        }
        const { quantity, minQuantity, maxQuantity } = cartItem.entity;
        return (
            <QuantityDropdown
                key='quantity'
                min={minQuantity}
                max={maxQuantity}
                value={quantity}
                loading={isQuantityLoading}
                onChange={onQuantityChange}
                label={t('cart.quantityDropdown.label', { defaultValue: 'Quantity' })}
            />
        );
    }

    renderDeliveryDropdown() {
        const { cartItem, isDeliveryOptionLoading, onDeliveryOptionChange, t } = this.props;
        if (cartItem.isFetching || cartItem.entity == null) {
            return null;
        }
        const { delivery, deliveryOptions } = cartItem.entity;
        return (
            <OptionDropdown
                key='delivery'
                options={deliveryOptions.map((value) => ({ text: t(`cart.deliveryOption.${value}`), value }))}
                value={delivery}
                loading={isDeliveryOptionLoading}
                onChange={onDeliveryOptionChange}
                style={{ visibility: 'hidden' }}
            />
        );
    }

    renderPaymentDropdown() {
        const { cartItem, isPaymentOptionLoading, onPaymentOptionChange, t } = this.props;
        if (cartItem.isFetching || cartItem.entity == null) {
            return null;
        }
        const { payment, paymentOptions } = cartItem.entity;
        return (
            <OptionDropdown
                key='payment'
                options={paymentOptions.map((value) => ({ text: t(`cart.paymentOption.${value}`), value }))}
                value={payment}
                loading={isPaymentOptionLoading}
                onChange={onPaymentOptionChange}
                style={{ visibility: 'hidden' }}
            />
        );
    }

    renderControl(key: Control) {
        switch (key) {
            case 'remove':
                return this.renderRemoveButton();
            case 'quantity':
                return this.renderQuantityDropdown();
            case 'delivery-option':
                return this.renderDeliveryDropdown();
            case 'payment-option':
                return this.renderPaymentDropdown();
            default:
                return null;
        }
    }

    render() {
        const {
            showQuantity,
            controls,
            step,
            // from store
            cartItem,
            item,
            offer,
            store,
            merchant
        } = this.props;
        if (cartItem.isFetching || cartItem.entity == null) {
            return null;
        }

        // TODO: support handling if entity data is missing.
        if (item.entity == null || store.entity == null || merchant.entity == null) {
            return null;
        }

        const { name, slug, pictures, storeSlug } = item.entity;
        const { variant, error, quantity } = cartItem.entity;
        const { alias } = merchant.entity;
        const isErrorRelevant = error && error.forStep <= step + 1;

        // TODO: Use a placeholder image for missing pictures
        const imageThumb = pictures[0] ? pictures[0].imageThumb : '';

        return (
            <Grid className='auto tensed'>
                <Grid.Column className='col-4'>
                    <Image
                        as={Link}
                        to={`/${slug}`}
                        className='square-image'
                        wrapped
                        alt={formatItemName(name)}
                        src={imageThumb || missingImage}
                        onError={(event) => {
                            event.target.src = missingImage;
                        }}
                    />
                </Grid.Column>
                <Grid.Column className='col-12'>
                    <Grid className='auto tensed'>
                        <Grid.Column className='col-row'>
                            <Grid className='auto tensed'>
                                <Grid.Column>
                                    <Header as='h3'>
                                        <Link to={`/${slug}`} className='inherit-color'>
                                            {name}
                                        </Link>
                                        <Header.Subheader>
                                            <Link to={`/${storeSlug}`} className='inherit-color'>
                                                <strong>{alias}</strong>
                                            </Link>
                                        </Header.Subheader>
                                    </Header>
                                    {showQuantity && <p>Quantity: {quantity}</p>}
                                </Grid.Column>
                                {error && isErrorRelevant && (
                                    <Grid.Column className='col-shrink'>
                                        <Popup trigger={<Icon className='margin-0' name='warning circle' color='red' size='large' />} content={error.message} />
                                    </Grid.Column>
                                )}
                                <Grid.Column className='col-shrink'>
                                    <Header size='medium'>
                                        <Price multiline item={item.entity} offer={offer.entity} />
                                    </Header>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            {variant && variant.variantBody && <VariantDataList variant={variant} />}
                            {controls.length > 0 && <Divider />}
                            {controls.length > 0 && <div>{controls.map((key) => this.renderControl(key))}</div>}
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    }
}

CartListItem.defaultProps = {
    showQuantity: false,
    step: 0
};

export default withTranslation('translations')(CartListItem);
