// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// CREATE_BANK_ACCOUNT --------------------------------
export type CreateBankAccountParams = {
    accountHolder: string,
    accountNo: string,
    bankAddress: string,
    bankName: string,
    isPrimary?: 0 | 1,
    postalCode: string,
    country: number,
    state: number,
    city: number
};

const excludeDecamelizeKeys = ['countryId', 'stateId', 'cityId'];

export const CREATE_BANK_ACCOUNT = 'CREATE_BANK_ACCOUNT';
export const createBankAccount = wrapAPIActionCreator(
    ({ accountHolder, accountNo, bankAddress, bankName, isPrimary, postalCode, country, state, city }: CreateBankAccountParams) => ({
        [API_DATA]: {
            type: CREATE_BANK_ACCOUNT,
            endpoint: '/v3/me/bank/account',
            authentication: Authentication.Merchant,
            method: 'POST',
            body: {
                accountHolder,
                accountNo,
                bankAddress,
                bankName,
                isPrimary,
                postCode: postalCode,
                countryId: country,
                stateId: state,
                cityId: city
            },
            schema: {
                bankAccount: Schemas.bankAccount
            },
            decamelizeOptions: {
                process: (key, convert) => (excludeDecamelizeKeys.includes(key) ? key : convert(key))
            }
        }
    })
);
