// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Switch, Route, Link } from 'react-router-dom';
import { Menu, Image } from 'semantic-ui-react';
import { ConnectedRouter } from 'connected-react-router';

import { history } from 'store';

// import myBazarLogo from 'styles/assets/images/logo-white.svg';
//import myBazarLogo from 'styles/assets/images/mb_logo.svg';
import myBazarLogoBeta from 'styles/assets/images/mb_logo-beta.svg'
import bleeLogo from 'styles/assets/images/blee-white.svg';

function renderLogo({ src, alt, to, className }) {
    return (
        <Menu.Item href='/' header>
            <Image className={`top-bar-logo top-bar-logo-${className}`} src={src} alt={alt} />
        </Menu.Item>
    );
}

function TopBarLogo() {
    const myBazar = {
        src: myBazarLogoBeta,
        alt: 'MyBazar',
        to: '/user',
        className: 'my-bazar'
    };
    const blee = {
        src: bleeLogo,
        alt: 'Blee',
        to: '/blee',
        className: 'blee'
    };
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path='/blee' render={() => renderLogo(blee)} />
                <Route render={() => renderLogo(myBazar)} />
            </Switch>
        </ConnectedRouter>
    );
}

export default TopBarLogo;
