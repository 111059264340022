import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal, Button } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

class RegisterShipmentModal extends PureComponent {
    handleClose = () => {
        const { name, closeModal } = this.props;
        closeModal(name);
    };

    handleRegister = () => {
        const { name, closeModal, orderId, registerShipment } = this.props;
        registerShipment({ orderId: orderId, carrier: 'EuroExpress' })
            .then(() => closeModal(name))
            .catch((err) => closeModal(name));
    };

    render() {
        const { isOpen, orderId } = this.props;

        return (
            <Modal size='tiny' closeIcon open={isOpen} onClose={this.handleClose}>
                <Modal.Header>
                    <Trans i18nKey='registerShipmentModal.header'>Register Shipment</Trans>
                </Modal.Header>
                <Modal.Content>
                    <Trans i18nKey='registerShipmentModal.content' orderId={orderId}>
                        Register shipment for Order ID <strong>{{ orderId }}</strong> with the carrier?
                    </Trans>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic type='button' onClick={this.handleClose}>
                        <Trans i18nKey='registerShipmentModal.cancel'>Cancel</Trans>
                    </Button>
                    <Button positive onClick={this.handleRegister}>
                        OK
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

const mapStateToProps = (state, { name }) => {
    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return { isOpen: false };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;
    const { orderId } = extraProps;

    return {
        ...extraProps,
        isOpen: true,
        orderId
    };
};

const mapDispatchToProps = (dispatch) => ({
    routePush: () => dispatch(push),
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    registerShipment: wrapAPIDispatch(dispatch, actions.registerShipment)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(RegisterShipmentModal);
