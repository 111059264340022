// @flow
import React from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    isLoggedIn: boolean,
    onLogout: () => void
};

function SidebarMenuLogOut({ isLoggedIn, onLogout }: Props) {
    if (!isLoggedIn) {
        return null;
    }
    return (
        <Grid.Row>
            <Grid.Column>
                <Menu className='simple' vertical borderless fluid>
                    <Menu.Item onClick={onLogout}>
                        <Trans i18nKey='sidebar.menu.logOut'>Log Out</Trans>
                    </Menu.Item>
                </Menu>
            </Grid.Column>
        </Grid.Row>
    );
}

export default withTranslation('translations')(SidebarMenuLogOut);
