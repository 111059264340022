// @flow
import React from 'react';
import { Label } from 'semantic-ui-react';
import { Trans } from 'react-i18next';

import type { Item } from 'store/types';

function toPercentage(salePrice, basePrice) {
    const ratio = (basePrice - salePrice) / basePrice;
    const percentage = (ratio * 100).toFixed(0);
    return `${percentage}%`;
}

function dealDataFor({ price, dealTypes }, dealType): { caption?: string, details?: string } {
    switch (dealType) {
        case 'sale':
            return { caption: 'Sale', details: toPercentage(dealTypes.sale, price) };
        case 'buy3for2':
            return { caption: 'Buy 3 For 2' };
        case 'sample':
            return { caption: 'Sample' };
        default:
            return {};
    }
}

type Props = {
    item: Item,
    size: ?string
};

function DealLabel({ item, size }: Props) {
    const { isDeal, dealTypes } = item;

    const isSale = isDeal && dealTypes && dealTypes.sale != null;
    const isBuy3For2 = isDeal && dealTypes && dealTypes.buy3For2;
    const isSample = isDeal && dealTypes && dealTypes.sample;

    const dealType = ['none', 'sale', 'buy3for2', 'sample'][[isSale, isBuy3For2, isSample].indexOf(true) + 1];
    const { caption, details } = dealDataFor(item, dealType);

    if (caption == null) {
        return null;
    }
    // return (
    //   <Label
    //     className="deal-label"
    //     size={size || 'medium'}
    //   >
    //     {/*<Trans i18nKey={`productTeaser.deal.${dealType}`}>{caption}</Trans>*/}
    //     { details && <Label.Detail>{details}</Label.Detail> }
    //   </Label>
    // );
    return details;
}

export default DealLabel;
