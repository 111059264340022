// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Header } from 'semantic-ui-react';

import Checkout from '../../components/Checkout';

type Props = {
    t: mixed,
    location: {
        state: { [string]: mixed }
    }
};

function CheckoutScene({ t, location }: Props) {
    const { initialStep, error } = location.state || {};
    return (
        <Container>
            <Helmet>
                <title>{t('checkoutScene.head.title')}</title>
            </Helmet>
            <Header as='h1'>
                <Trans i18nKey='checkoutScene.header'>Checkout</Trans>
            </Header>
            <Checkout {...{ initialStep, error }} />
        </Container>
    );
}

export default withTranslation('translations')(CheckoutScene);
