// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { getLandingComponentBanner } from 'store/utilities';
import type { EntityData, LandingComponentBanner } from 'store/types';

import PromotionalCarouselItemView from './PromotionalCarouselItemView';

type Props = {
    openModal: typeof actions.openModal,
    banner: EntityData<LandingComponentBanner>
};

class PromotionalCarouselItemContainer extends Component<Props> {
    handleClickYoutube = () => {
        const { openModal } = this.props;
        openModal(actions.ModalNames.YOUTUBE, { url: this.props.banner.entity.actionValue });
    };

    render() {
        const { ...rest } = this.props;
        return <PromotionalCarouselItemView onClickYouTube={this.handleClickYoutube} {...rest} />;
    }
}

const mapStateToProps = (state, { bannerId }) => {
    const banner = getLandingComponentBanner(state, bannerId);
    return { banner };
};

const mapDispatchToProps = (dispatch) => ({
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(PromotionalCarouselItemContainer);
