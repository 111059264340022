import React, { useContext, useEffect, useMemo, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUserDeliveryAddress, getCartItem } from 'store/utilities';

import CheckoutDetailsReviewView from './CheckoutDetailsReviewView';
import { PaymentMethod } from 'components/Checkout';
import { MonriContext, useMonriComponents } from '../../../../../../utilities/monri/useMonri';
import { PaymentStatus } from '../../../../../../store/enums';

function CheckoutDetailsReviewContainer(props) {
    const { paymentMethod, orderCoD, loadCartItems, deliveryAddress, navigateTo, orderMonri, cart, checkPaymentStatusMonri, unlockCart } = props;
    const [isSubmitLoading, setSubmitLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(null);
    const monriComponents = useMonriComponents();
    const [name, setName] = useState('');
    const monri = useContext(MonriContext);
    const [cardError, setCardError] = useState('empty');

    useEffect(() => {
        const listener = (e) => {
            setCardError(e.error);
        };
        monriComponents.addListener(listener);
        return () => {
            monriComponents.removeListener(listener);
        };
    }, [monriComponents, setCardError]);

    const formValid = useMemo(() => {
        return paymentMethod !== PaymentMethod.CreditCard || (!cardError && name.length > 0);
    }, [paymentMethod, cardError, name]);

    const handleClickSubmitCC = async () => {
        if (deliveryAddress.isFetching || !deliveryAddress.entity) {
            throw new Error('Delivery address not in Redux store.');
        }
        setSubmitLoading(true);
        const cardComponent = monriComponents.getCardComponent();

        const transactionData = {
            address: [deliveryAddress.entity.street, deliveryAddress.entity.houseNo || ''].join(' ').trimEnd(),
            city: deliveryAddress.entity.city,
            country: deliveryAddress.entity.country,
            zip: deliveryAddress.entity.zip,
            email: deliveryAddress.entity.emailAddress,
            fullName: name,
            phone: deliveryAddress.entity.contactNo
        };

        try {
            const orderRes = await orderMonri();
            transactionData.clientSecret = orderRes.result.clientSecret;
            const res = await monri.confirmPayment(cardComponent, transactionData);
            if (res.error) {
                setCardError(res.error);
                return;
            }
            if (res.result.status === 'approved') {
                while (true) {
                    const res = await checkPaymentStatusMonri({ cartId: cart.cartId });
                    if (res.result.paymentStatus === PaymentStatus.SUCCESS) {
                        await loadCartItems();
                        return navigateTo('/checkout-complete');
                    }
                    if (res.result.paymentStatus === PaymentStatus.FAILURE) {
                        await unlockCart(transactionData.clientSecret);
                        return setPaymentError(new Error('Payment failed'));
                    }
                    await new Promise((resolve) => setTimeout(resolve, 1000));
                }
            } else {
                setPaymentError(new Error('Payment denied'));
            }
        } catch (e) {
            if (transactionData.clientSecret) {
                await unlockCart(transactionData.clientSecret);
            }
            setPaymentError(e);
            console.error(e);
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleClickSubmitCoD = async () => {
        if (deliveryAddress.isFetching || !deliveryAddress.entity) {
            throw new Error('Delivery address not in Redux store.');
        }
        setSubmitLoading(true);
        try {
            await orderCoD({ address: deliveryAddress.entity });
            await loadCartItems();
            navigateTo('/checkout-complete');
        } catch (e) {
            setPaymentError(e);
        } finally {
            setSubmitLoading(false);
        }
    };

    const handleClickSubmit = paymentMethod === PaymentMethod.CreditCard ? handleClickSubmitCC : handleClickSubmitCoD;

    return (
        <CheckoutDetailsReviewView
            cardName={name}
            setCardName={setName}
            monriComponents={monriComponents}
            onClickSubmit={handleClickSubmit}
            isSubmitLoading={isSubmitLoading}
            {...props}
            paymentError={paymentError}
            formValid={formValid}
        />
    );
}

const mapStateToProps = (state) => {
    const deliveryAddress = getUserDeliveryAddress(state, state.userData.cart.addressId);
    return {
        deliveryAddress,
        cartItems: state.userData.cart.cartItems.map((id) => getCartItem(state, id))
    };
};

const mapDispatchToProps = (dispatch) => ({
    orderMonri: wrapAPIDispatch(dispatch, actions.orderMonri),
    orderCoD: wrapAPIDispatch(dispatch, actions.orderCoD),
    loadCartItems: wrapAPIDispatch(dispatch, actions.loadCartItems),
    checkPaymentStatusMonri: wrapAPIDispatch(dispatch, actions.checkPaymentStatusMonri),
    unlockCart: wrapAPIDispatch(dispatch, actions.unlockCart),
    navigateTo: (...args) => dispatch(push(...args))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(CheckoutDetailsReviewContainer);
