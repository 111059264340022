// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn, getUser } from 'store/utilities';

import MyAgentInboxView from './scenes/MyAgentInbox';

type Props = {
    isLoggedIn: boolean,
    isLoadingAccounts: boolean,
    loadMyAccounts: typeof actions.loadMyAccounts
};

function loadData({ loadMyAccounts }: Props) {
    return loadMyAccounts();
}

class MyAgentContainer extends Component<Props> {
    state = {
        isLoadingAccounts: true
    };

    componentDidMount() {
        setTimeout(() => {
            const { isLoggedIn, userId, isLoadingAccounts } = this.props;
            if (!isLoggedIn || !userId) {
                this.setState({ isLoadingAccounts: false });
                return;
            }

            if (!isLoadingAccounts) {
                loadData(this.props)
                    .then(() => this.setState({ isLoadingAccounts: false }))
                    .catch(() => this.setState({ isLoadingAccounts: false }));
            }
        }, 0);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.user && this.state.isLoadingAccounts) {
            this.setState({ isLoadingAccounts: false });
        }
    }

    render() {
        const { isLoggedIn, user } = this.props;
        if (!this.state.isLoadingAccounts) {
            // need to add condition if agent
            if (isLoggedIn && user) {
                return (
                    <Switch>
                        <Route path='/my-agent/inbox' render={() => <MyAgentInboxView {...this.props} />} />
                    </Switch>
                );
            }
            return <Redirect to='/' />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    userId: state.userData.user,
    user: getUser(state, state.userData.user).entity,
    isLoadingAccounts: state.userData.isLoadingAccounts
});

const mapDispatchToProps = (dispatch) => ({
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyAgentContainer);
