// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Divider } from 'semantic-ui-react';

import SidebarMenuMain from './components/SidebarMenuMain';
import SidebarMenuSupport from './components/SidebarMenuSupport';
import SidebarMenuLogOut from './components/SidebarMenuLogOut';

type Props = {
    isLoggedIn: boolean,
    onLogout: () => void,
    user: EntityData<User>,
    merchantUser: EntityData<User>
};

function SidebarMenu({ isLoggedIn, onLogout, user, merchantUser }: Props) {
    return (
        <Grid className='tensed sidebar-menu'>
            <SidebarMenuMain isLoggedIn={isLoggedIn} user={user} merchantUser={merchantUser} />
            <SidebarMenuSupport />
            {isLoggedIn && (
                <Grid.Row>
                    <Grid.Column>
                        <Divider fitted />
                    </Grid.Column>
                </Grid.Row>
            )}
            <SidebarMenuLogOut isLoggedIn={isLoggedIn} onLogout={onLogout} />
        </Grid>
    );
}

export default SidebarMenu;
