// @flow
import React, { PureComponent } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyProfileSettingsForm from './components/MyProfileSettingsForm';

type Props = {
    isLoggedIn: boolean,
    user: EntityTypes<User>
};

class MyProfileSettingsView extends PureComponent<Props> {
    render() {
        return (
            <Grid style={{ marginLeft: '45px', marginRight: '45px' }}>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h1'>
                            <Trans i18nKey='myProfile.settings.title'>Account Settings</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <MyProfileSettingsForm />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}

export default withTranslation('translations')(MyProfileSettingsView);
