// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Form, Label, Message, Loader } from 'semantic-ui-react';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';
import { DeliveryOptions } from 'store/enums';

type Props = {
    formId: string
};

type State = {
    errorMessage: ?string,
    formValues: {
        returnPolicy: FormFieldData<number>,
        merchantWillCoverCost: FormFieldData<boolean>,
        estimatedDeliveryCost: FormFieldData<number>,
        minimumDays: FormFieldData<number>,
        maximumDays: FormFieldData<number>
    }
};

const defaultValuesFromItem = ({ merchantShippingDetail }) => {
    const { returnPolicyId, isMerchantPay, estimatedDeliveryTimeStart, estimatedDeliveryTimeEnd, estimatedDeliveryCost } = merchantShippingDetail;

    return {
        returnPolicy: createEmptyFormFieldData({ value: returnPolicyId, type: 'number', required: true }),
        merchantWillCoverCost: createEmptyFormFieldData({ value: isMerchantPay === 1 || isMerchantPay === true, type: 'boolean' }),
        estimatedDeliveryCost: createEmptyFormFieldData({ value: estimatedDeliveryCost, type: 'number', required: true }),
        minimumDays: createEmptyFormFieldData({ value: estimatedDeliveryTimeStart, type: 'number', required: true }),
        maximumDays: createEmptyFormFieldData({ value: estimatedDeliveryTimeEnd, type: 'number', required: true })
    };
};

class ShippingToDetailsForm extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { item, merchantShippingDetail, ownFormValues, updateFormValues, formId } = props;

        let formValues = {};

        if (false && ownFormValues) {
            formValues = ownFormValues;
        } else if (item && merchantShippingDetail) {
            formValues = defaultValuesFromItem({ item, merchantShippingDetail });
        } else {
            formValues = {
                returnPolicy: createEmptyFormFieldData({ value: null, type: 'number', required: true }),
                merchantWillCoverCost: createEmptyFormFieldData({ value: false, type: 'boolean' }),
                estimatedDeliveryCost: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
                minimumDays: createEmptyFormFieldData({ value: 0, type: 'number', required: true }),
                maximumDays: createEmptyFormFieldData({ value: 0, type: 'number', required: true })
            };
        }

        this.state = {
            formValues,
            costs: undefined
        };
        updateFormValues({ formValues, formId });
    }

    UNSAFE_componentWillReceiveProps(props) {
        const { costs, formId } = props;
        if (costs === null || costs === undefined) {
            return;
        }

        const previousCosts = this.state.costs;
        const { estimateDayMin = 0, estimateDayMax = 0, estimateCost = 0.0 } = costs;
        const { formValues } = this.state;
        const {
            minimumDays: { value: currentMin = 0 },
            maximumDays: { value: currentMax = 0 }
        } = formValues;

        let shouldUpdateState = false;
        if (!previousCosts || previousCosts.estimateDayMin !== estimateDayMin || previousCosts.estimateDayMax !== estimateDayMax || previousCosts.estimateCost !== estimateCost) {
            shouldUpdateState = true;
            formValues.minimumDays.value = Math.max(estimateDayMin, currentMin);
            formValues.maximumDays.value = Math.max(estimateDayMax, currentMax);
            formValues.estimatedDeliveryCost.value = estimateCost;
        }

        if (shouldUpdateState) {
            this.setState({ formValues, costs }, () => {
                this.props.updateFormValues({ formValues, formId });
            });
        }
    }

    validateMaximumDays = () => {
        const { formValues, costs } = this.state;
       
        const {t} = this.props
        if (!costs) {
            this.validate();
            return;
        }
        let {
            minimumDays: { value: minimumDays },
            maximumDays: { value: maximumDays }
        } = formValues;

        minimumDays = parseInt(minimumDays, 10);
        maximumDays = parseInt(maximumDays, 10);

        if (minimumDays < costs.estimateDayMin) {
            formValues.minimumDays.errorMessage =t('sellItem.form.errors.minDays', {
                defaultValue: "Minimum days cannot be less than estimate. ({{count}} days)",
                count: costs.estimateDayMin,
              
            });;
        } else {
            formValues.minimumDays.errorMessage = null;
            formValues.minimumDays.error = false;
        }

        if (maximumDays < minimumDays) {
            formValues.maximumDays.errorMessage = <Trans i18nKey='sellItem.form.errors.maxDays.lessMin'>Maximum days cannot be less then minimum days</Trans>;
        } else if (maximumDays < costs.estimateDayMax) {
           
            formValues.maximumDays.errorMessage = t('sellItem.form.errors.maxDays.lessEstimate', {
                defaultValue: "Maximum days cannot be less than estimate. ({{count}} days)",
                count: costs.estimateDayMax,
              
            });
        } else {
            formValues.maximumDays.errorMessage = null;
            formValues.maximumDays.error = false;
        }
        this.setState({ formValues }, /*() => {
            this.validate();
            */
    );
    };

    onMerchantWillCoverCostChange = (event, data) => {
        const { checked } = data;
        const { shippingDefaults } = this.props;
        const { formValues } = this.state;
        if (shippingDefaults.delivery === DeliveryOptions.MERCHANT && checked) {
            formValues.estimatedDeliveryCost.value = 0;
        }
        this.setState({ formValues }, () => {
            this.handleChange(event, data);
        });
    };

    render() {
        const { formValues } = this.state;
        const {
            merchantWillCoverCost: { value: merchantWillCoverCost }
        } = formValues;

        const { returnPolicies, costs, shippingDefaults, costsError, isLoadingCosts } = this.props;
        const { estimateDayMin = 0, estimateDayMax = 0 } = costs || {};

        return (
            <Form.Field>
                {costsError && (
                    <Message visible error>
                        {costsError.message}
                    </Message>
                )}
                {(costs || shippingDefaults.delivery === DeliveryOptions.MERCHANT) && (
                    <Form.Field>
                        <Form.Group unstackable>
                            {isLoadingCosts ? (
                                <Loader active />
                            ) : (
                                this.renderInputField(
                                    {
                                        name: 'estimatedDeliveryCost',
                                        type: 'number',
                                        step: '0.01',
                                        required: false,
                                        disabled: shippingDefaults.delivery === DeliveryOptions.MY_BAZAR || merchantWillCoverCost
                                    },
                                    this.props
                                )
                            )}

                            {this.renderCheckboxField(
                                {
                                    name: 'merchantWillCoverCost',
                                    onChange: this.onMerchantWillCoverCostChange,
                                    noLabel: true,
                                    width: 8
                                },
                                this.props
                            )}
                        </Form.Group>
                        <Form.Group unstackable>
                            <Form.Field width={8}>
                                {this.renderInputField({ name: 'minimumDays', minValue: estimateDayMin, type: 'number', width: 8, onBlur: this.validateMaximumDays  }, this.props)}
                                {this.state.formValues.minimumDays.errorMessage && (
                                    <Label basic color='red' pointing='above' width={8}>
                                        {this.state.formValues.minimumDays.errorMessage}
                                    </Label>
                                )}
                            </Form.Field>
                            <Form.Field width={8}>
                                {this.renderInputField({ name: 'maximumDays', minValue: estimateDayMax, type: 'number', width: 8, onBlur: this.validateMaximumDays }, this.props)}
                                {this.state.formValues.maximumDays.errorMessage && (
                                    <Label basic color='red' pointing='above' width={8}>
                                        {this.state.formValues.maximumDays.errorMessage}
                                    </Label>
                                )}
                            </Form.Field>
                        </Form.Group>
                    </Form.Field>
                )}

                {this.renderDropdownField(
                    {
                        name: 'returnPolicy',
                        options: returnPolicies.map((policy) => ({ value: policy.id, text: policy.name }))
                    },
                    this.props
                )}
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ShippingToDetailsForm);
