// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes, UserTypes } from '../../enums';

export type RegisterUserParams = {
    email: string,
    username: string,
    phoneNumber: string,
    password: string,
    deviceType?: $Values<typeof DeviceTypes>,
    pushNotificationToken?: string,
    alias: string,
    userType: $Values<typeof UserTypes>,
    locations?: {
        formattedAddress: string,
        lon: number,
        lat: number,
        country: string
    }[],
    sendWelcomeEmail?: boolean
};

export const REGISTER_USER = 'REGISTER_USER';
export const registerUser = wrapAPIActionCreator(
    ({
        email,
        username,
        phoneNumber,
        password,
        alias,
        userType,
        deviceType = DeviceTypes.WEB,
        pushNotificationToken = 'x',
        locations = [],
        sendWelcomeEmail = true
    }: RegisterUserParams) => ({
        [API_DATA]: {
            type: REGISTER_USER,
            endpoint: '/v3/auth/register',
            method: 'POST',
            body: {
                email,
                username,
                phoneNumber,
                password,
                alias,
                userType,
                deviceType,
                pushNotificationToken,
                locations,
                sendWelcomeEmail
            },
            preprocess: ({ user: { accessToken, ...rest } }) => ({ user: rest, accessToken }),
            schema: {
                user: Schemas.user
            }
        }
    })
);
