// @flow
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Form, Button, Icon, Ref, Grid } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

import FileUploader from './components/FileUploader';

type State = {
    isPosting: boolean,
    formValues: {
        message: {
            value: string
        },
        file: string | ArrayBuffer
    }
};

class SendMessageView extends Component<{}, State> {
    constructor(props) {
        super(props);

        this.textAreaWrapper = null;

        this.state = {
            isPosting: false,
            formValues: {
                message: { value: '' },
                file: ''
            }
        };
    }

    componentDidMount() {
        this.focusTextInput();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { focusSendMessage } = nextProps;

        if (focusSendMessage) {
            this.focusTextInput();
        }
    }

    onSendMessage = () => {
        if (this.state.isPosting) {
            return;
        }
        const { sendMessage, onPostMessageSuccess = () => {} } = this.props;

        const { formValues } = this.state;
        const {
            message: { value: message },
            file
        } = formValues;

        this.setState({ isPosting: true });
        sendMessage({ message, file })
            .then(() => {
                const { formValues } = this.state;
                formValues.message.value = '';
                this.setState({ formValues, isPosting: false }, () => {
                    onPostMessageSuccess();
                });
            })
            .catch((error) => {
                console.error({ error });
                this.setState({ isPosting: false });
            });
    };

    setTextAreaRef = (element) => {
        this.textAreaWrapper = element;
    };

    focusTextInput = () => {
        if (this.textAreaWrapper && this.textAreaWrapper.firstChild) {
            this.textAreaWrapper.firstChild.focus();
        }
        if (this.props.onDidFocusSendMessage) {
            this.props.onDidFocusSendMessage();
        }
    };

    handleChange = (event) => {
        //console.log('mmessage view', event.target);
        const { name, value, ...rest } = event.target;
        const { formValues } = this.state;

        if (name === 'message') {
            formValues.message.value = value;
        }
        this.setState({ formValues });
    };

    handleFileChange = (file: string | ArrayBuffer) => {
        const { formValues } = this.state;
        formValues.file = file;
        this.setState({ formValues });
    };

    render() {
        const { formValues, isPosting } = this.state;
        const {
            message: { value: message }
        } = formValues;
        const { t } = this.props;

        return (
            <Form>
                <Grid className='auto tensed'>
                    <Grid.Column className='col-row'>
                        <Grid className='auto tensed'>
                            <Grid.Column className='col-row'>
                                <Ref innerRef={this.setTextAreaRef}>
                                    <Form.TextArea
                                        value={message}
                                        control={TextareaAutosize}
                                        rows={8}
                                        name='message'
                                        id='messages-conversation-send-message'
                                        onChange={this.handleChange}
                                        placeholder={t('messages.input.placeholder', { defaultValue: 'Unesite poruku ili zalijepite link' })}
                                    />
                                </Ref>
                            </Grid.Column>
                            <Grid.Column className='col-row'>
                                <Button className='message-button' loading={isPosting} onClick={() => this.onSendMessage()}>
                                    <Trans i18nKey='messages.conversation.actions.send'>Send</Trans>
                                </Button>
                                {/*<FileUploader onFileChange={this.handleFileChange} />*/}
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid>
            </Form>
        );
    }
}

export default withTranslation('translations')(SendMessageView);
