// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    list: [],
    hasMore: true
};

function campaigns(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_CAMPAIGNS: {
            const { offset, limit } = action.reducerParams;

            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'success') {
                const { result } = action.payload;
                const { list } = state;
                return {
                    ...state,
                    list: offset === list.length ? list.concat(result) : result,
                    isLoading: false,
                    hasMore: result.length === limit
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            break;
        }
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default campaigns;
