// @flow
import React from 'react';

import { ModalNames } from 'store/actions/ui';
import LogInModal from './components/LogInModal';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import ResetEmailSent from './components/ResetEmailSent';
import DeliveryAddressAddModal from './components/DeliveryAddressAddModal';
import DeliveryAddressEditModal from './components/DeliveryAddressEditModal';
import SendFeedbackModal from './components/SendFeedbackModal';
import AddressAddModal from './components/AddressAddModal';
import AddressEditModal from './components/AddressEditModal';
import OrderCancelModal from './components/OrderCancelModal';
import OrderAddTrackingNumberModal from './components/OrderAddTrackingNumberModal';
import BankAccountAddModal from './components/BankAccountAddModal';
import BankAccountEditModal from './components/BankAccountEditModal';
import LanguageSelectModal from './components/LanguageSelectModal';
import YouTubeModal from './components/YouTubeModal';
import RegisterShipmentModal from './components/RegisterShipmentModal';
import RegisterAlert from './components/RegisterAlert';
import RegisterAccount from './components/RegisterAccountModal';

function ModalsView({ languages }) {
    return [
        <LogInModal key='login' name={ModalNames.LOGIN} />,
        <ForgotPasswordModal key='forgot-password' name={ModalNames.FORGOT_PASSWORD} />,
        <ResetEmailSent key='reset-email-sent' name={ModalNames.RESET_EMAIL_SENT} />,
        <SendFeedbackModal key='send-feedback' name={ModalNames.SENDFEEDBACK} />,
        <DeliveryAddressAddModal key='add-delivery-address' name={ModalNames.DELIVERY_ADDRESS_ADD} />,
        <DeliveryAddressEditModal key='edit-delivery-address' name={ModalNames.DELIVERY_ADDRESS_EDIT} />,
        <AddressAddModal key='add-address' name={ModalNames.ADDRESS_ADD} />,
        <AddressEditModal key='edit-address' name={ModalNames.ADDRESS_EDIT} />,
        <OrderCancelModal key='order-cancel' name={ModalNames.ORDER_CANCEL} />,
        <OrderAddTrackingNumberModal key='order-add-tracking-number' name={ModalNames.ORDER_ADD_TRACKING_NUMBER} />,
        <BankAccountAddModal key='add-bank-account' name={ModalNames.BANK_ACCOUNT_ADD} />,
        <BankAccountEditModal key='edit-bank-account' name={ModalNames.BANK_ACCOUNT_EDIT} />,
        // <LanguageSelectModal key='select-language' name={ModalNames.LANGUAGE_SELECT} languages={languages} />,
        <YouTubeModal key='youtube' name={ModalNames.YOUTUBE} />,
        <RegisterShipmentModal key='register-shipment' name={ModalNames.REGISTER_SHIPMENT} />,
        <RegisterAlert key='register-alert' name={ModalNames.REGISTER_ALERT} />,
        <RegisterAccount key='register-account' name={ModalNames.REGISTER_ACCOUNT} />
    ];
}

export default ModalsView;
