// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type DeleteCartPromoCodeParams = {
    promoCode: number
};

export const DELETE_CART_PROMO_CODE = 'DELETE_CART_PROMO_CODE';
export const deleteCartPromoCode = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: DELETE_CART_PROMO_CODE,
        endpoint: '/v3/cart_items/remove_promo_code',
        method: 'DELETE',
        authentication: Authentication.User,
        schema: {
            cartItems: [Schemas.cartItem],
            addresses: [Schemas.userDeliveryAddress]
        }
    }
}));

export default deleteCartPromoCode;
