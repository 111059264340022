// @flow
import React, { Component } from 'react';
import { Divider, Grid, Header, List, Segment } from 'semantic-ui-react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import { formatDate, formatDatePretty, formatItemName, formatPrice } from 'utilities/format';
import type { EntityData, Item, Order } from 'store/types';
import ProductDetailListItemImage from './components/ProductDetailListItemImage/ProductDetailListItemImageView';
import Price from 'components/Price';
import CheckoutAddressDetails from '../../../Checkout/components/CheckoutDetails/components/CheckoutAddressDetails/CheckoutAddressDetails';

type Props = {
    type: 'item' | 'order' | 'purchase',
    tab: string,
    item: EntityData<Item>,
    order: EntityData<Order>
};

type State = {
    detailsActive: boolean,
    redirectTo?: string
};

class ProductDetailListItemDetail extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            detailsActive: props.detailsActive !== undefined ? props.detailsActive : false,
            redirectTo: null
        };
    }

    toggleDetails = () => {
        const { type, order, item } = this.props;
        const { detailsActive } = this.state;
        const { slug: productSlug } = item?.entity;
        if (type === 'purchase') {
            this.setState({ redirectTo: detailsActive ? `/${productSlug}` : `/my-profile/purchases/${order.entity.id}` });
        } else if (type === 'order') {
            this.setState({ redirectTo: detailsActive ? `/${productSlug}` : `/my-store/orders/${order.entity.id}` });
        }
    };

    getPaymentStatusFromId = (id) => {
        return {
            0: 'Failed',
            1: 'Paid',
            2: 'Cancelled'
        }[id];
    };

    getShipmentProviderById = (id) => {
        return {
            '1': 'GDEX',
            '2': 'Poslaju',
            '3': 'Taqbin',
            '4': 'Posta BIH',
            '5': 'ExpressOne',
            '6': 'EuroExpress'
        }[id];
    };

    renderGridItem = () => {
        const { redirectTo } = this.state;
        const { item, type, t, ...rest } = this.props;
        if (redirectTo) {
            this.props.history.push(redirectTo);
        }
        // if(!this.state.detailsActive) {
        //     return <span>Fetching..</span>;
        // }

        const { name, storeSlug } = item.entity || {};
        const order = this.props.order || {};
        const { id, createdAt, sellingPrice, shipmentRate, paymentStatus, quantity, itemGstAmount, orderStatusTitle, shipmentProvider, discountAmount } =
            ((type === 'order' || type !== 'item') && order.entity) || {};
        const vat = quantity * itemGstAmount;
        const subTotal = sellingPrice - vat;
        const total = sellingPrice + shipmentRate;
        return (
            <Segment className={`my-store-product-list-item${this.state.detailsActive ? ' my-store-product-list-item-details-active' : ''}`}>
                <Grid onClick={() => type === 'purchase' && this.toggleDetails()} style={{ cursor: 'pointer' }} className='item auto tensed'>
                    <Grid.Column className='col-sm-4 col-md-3 col-2 item-image'>
                        <ProductDetailListItemImage item={item} />
                    </Grid.Column>
                    <Grid.Column className='col-sm-4 col-md-3 col-2 item-details'>
                        <p className={'store'}>{storeSlug}</p>
                        <h2 className={'title'}>{name}</h2>
                        <div className={'bottom-line'}>
                            <Price item={item?.entity} />
                        </div>
                    </Grid.Column>
                    <Grid.Column className=' item-price'>
                        {/*{formatPrice(sellingPrice)} ({quantity} <Trans i18nKey='myStore.orders.orderDetails.pcs'>pcs</Trans> )*/}
                        {formatPrice(sellingPrice / quantity)} x {quantity}
                    </Grid.Column>
                </Grid>
                <Grid style={{ marginTop: '30px' }}>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Header size='small'>
                            <Trans i18nKey='myStore.orders.orderDetails.orderInfo'>Order Information</Trans>
                        </Header>
                        <List>
                            <List.Item>
                                <Trans i18nKey={`myStore.orders.ordersListCol.date`}>Date</Trans>: {formatDatePretty(new Date(createdAt), t)}
                            </List.Item>
                            <List.Item>
                                <Trans i18nKey={`myStore.orders.ordersListCol.quantity`}>Quantity</Trans>: {quantity}{' '}
                                {quantity === 1 ? (
                                    <Trans i18nKey={`myStore.orders.ordersListItem.amount`}>Product</Trans>
                                ) : (
                                    <Trans i18nKey={`myStore.orders.ordersListItem.amounts`}>Product</Trans>
                                )}
                            </List.Item>
                            <List.Item>
                                <Trans i18nKey={`myStore.orders.ordersListCol.store`}>Store</Trans>: {storeSlug}
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Header size='small'>
                            <Trans i18nKey={`myStore.orders.ordersListCol.paidByCustomer`}>Paid By Customer</Trans>
                        </Header>
                        <List>
                            <List.Item>
                                <Trans i18nKey={`checkout.details.payment.subTotal`}>Subtotal</Trans>: {formatPrice(subTotal)}
                            </List.Item>
                            {discountAmount > 0 && (
                                <List.Item>
                                    <Trans i18nKey={`checkout.details.payment.discount`}>Popust</Trans>: {formatPrice(discountAmount)}
                                </List.Item>
                            )}
                            <List.Item>
                                <Trans i18nKey={`checkout.details.payment.shippingFee`}>Shipping</Trans>: {formatPrice(shipmentRate)}
                            </List.Item>
                            <List.Item>
                                <Trans i18nKey={`checkout.details.payment.tax`}>Shipping</Trans>: {formatPrice(vat)}
                            </List.Item>
                            <List.Item>
                                <b>
                                    <Trans i18nKey={`checkout.details.payment.total`}>Total</Trans>: {formatPrice(total - discountAmount)}
                                </b>
                            </List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Header size='small'>
                            <Trans i18nKey={`deliveryAddress.title`}>Shipping Address</Trans>:
                        </Header>
                        <CheckoutAddressDetails deliveryAddress={{ entity: order.entity.shopperAddress }} />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={8} computer={4}>
                        <Header size='small'>
                            <Trans i18nKey='myStore.orders.ordersListCol.fulfillment'>Fulfillment</Trans>
                        </Header>
                        <List.Item>
                            <Trans i18nKey='myStore.orders.ordersListCol.fulfillment'>Fulfillment</Trans>: {order.entity.orderStatusTitle}
                        </List.Item>
                        <List.Item>
                            <Trans i18nKey='myStore.orders.ordersListCol.shippingCarrier'>Shipping Carrier</Trans>: {this.getShipmentProviderById(order.entity.shipmentOptions[0])}
                        </List.Item>
                        <List.Item>
                            <Trans i18nKey='myStore.orders.ordersListCol.trackingNumber'>Tracking number</Trans>: {order.entity.trackingNumber}
                        </List.Item>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    };

    render() {
        const { item, type, ...rest } = this.props;
        return type === 'item' ? <Link to={`/item/${item?.entity?.id}`}>{this.renderGridItem()}</Link> : <span>{this.renderGridItem()}</span>;
    }
}

export default withTranslation('translations')(withRouter(ProductDetailListItemDetail));
