// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Loader } from 'semantic-ui-react';
import { parseQueryString } from 'utilities';

type Props = {
    location: {
        search: string,
        navigateTo: typeof push
    }
};

const IPAY88_ERROR = 0;
const IPAY88_SUCCESS = 1;

function CheckoutReturnScene({ location, navigateTo }: Props) {
    const gatewayParams = parseQueryString(location.search, {
        Status: (str) => Number(str),
        Validates: (str) => str === 'true'
    });
    const { Status, ErrDesc } = gatewayParams;

    switch (Status) {
        case IPAY88_SUCCESS:
            navigateTo('/checkout-complete', {
                gatewayParams
            });
            break;

        case IPAY88_ERROR:
        default:
            navigateTo('/checkout', {
                initialStep: 3,
                error: {
                    message: ErrDesc,
                    gatewayParams
                }
            });
            break;
    }

    return <Loader active size='massize' />;
}

export default compose(connect(null, { navigateTo: push }))(CheckoutReturnScene);
