import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import CreateStore from '../../components/CreateStore';

function CreateStoreScene() {
    return (
        <Container>
            <Header as='h1'>
                <Trans i18nKey='createStoreScene.header'>Create Store</Trans>
            </Header>
            <CreateStore />
        </Container>
    );
}

export default withTranslation('translations')(CreateStoreScene);
