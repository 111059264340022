import React from 'react';
import { withTranslation } from 'react-i18next';

import BulkEditForm from './components/BulkEditForm';

function BulkEdit() {
    return (
        <div className='sell-item'>
            <BulkEditForm />
        </div>
    );
}

export default withTranslation('translations')(BulkEdit);
