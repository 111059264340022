// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tab, Visibility, Container, Loader } from 'semantic-ui-react';

import FollowerList from 'components/FollowerList';

const FollowerTabView = ({ user, loadMore, followers }) => {
    if (!followers) {
        return null;
    }

    return (
        <Tab.Pane attached={false} className='simple'>
            <Visibility
                onUpdate={(e, { calculations }) => {
                    loadMore(calculations);
                }}
            >
                {followers.users && (
                    <FollowerList
                        users={followers.users.filter((follower) => follower.userType !== undefined)}
                        usersMissingUserType={followers.users.filter((follower) => follower.userType === undefined)}
                        parentUser={user}
                    />
                )}
                {followers.isLoading && (
                    <Container>
                        <Loader active inline='centered' />
                    </Container>
                )}
            </Visibility>
        </Tab.Pane>
    );
};

export default FollowerTabView;
