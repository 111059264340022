// @flow
import Container from './AddCartPromoCodeModalContainer';

export type FormValues = {
    promoCode: string
};

export { default as Container } from './AddCartPromoCodeModalContainer';
export { default as View } from './AddCartPromoCodeModalView';

export default Container;
