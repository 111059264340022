// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

export type CancelOrderParams = {
    orderId: number,
    message: string,
    authentication: string
};

export const CANCEL_ORDER = 'CANCEL_ORDER';
export const CANCEL_CART_ORDERS = 'CANCEL_CART_ORDERS';
export const cancelOrder = wrapAPIActionCreator(({ orderId, message, authentication }: CancelOrderParams) => ({
    [API_DATA]: {
        type: CANCEL_ORDER,
        endpoint: `/v3/orders/${orderId}/cancelOrder`,
        method: 'POST',
        authentication,
        body: {
            orderId,
            message
        },
        schema: {
            order: Schemas.order
        }
    }
}));

export const cancelCartOrders = wrapAPIActionCreator(({ cartId, message, authentication }) => ({
    [API_DATA]: {
        type: CANCEL_CART_ORDERS,
        endpoint: `/v3/orders/${cartId}/cancelCartOrders`,
        authentication,
        method: 'POST',
        body: {
            message
        },
        preprocess: (res) => ({ ...res, cartId })
    }
}));

export default cancelOrder;
