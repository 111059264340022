// @flow
import React, { Component } from 'react';

import type { EntityData, Comment } from 'store/types';

import CommentsView from './CommentsView';

type Props = {
    onLoadMoreComments: (offset: number) => void,
    comments: EntityData<Comment>[]
};

class CommentsContainer extends Component<Props> {
    handleMoreComments = () => {
        const comments = Object.values(this.props.comments);
        const offset = comments.length;
        this.props.onLoadMoreComments(offset);
    };

    render() {
        return <CommentsView {...this.props} onMoreComments={this.handleMoreComments} />;
    }
}

export default CommentsContainer;
