// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader } from 'semantic-ui-react';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getCategory, getCategoryAttribute, getVariant } from 'store/utilities';

import ItemFormVariantDetailsView from './ItemFormVariantDetailsView';

type Props = {
    selectedSubSubcategoryId: number,
    attributeState: {
        hasLoadedAttributes: boolean,
        isLoading: boolean
    },
    loadCategoryAttributes: typeof actions.loadCategoryAttributes
};

class ItemFormDetailsContainer extends Component<Props> {
    state = {
        categoryAttributesForItem: null
    };

    componentWillReceiveProps(props) {
        const { selectedSubSubcategoryId, attributeState, loadCategoryAttributes } = props;
        const { attributes, isLoading = false } = attributeState;

        if (selectedSubSubcategoryId) {
            if (!attributes && !isLoading) {
                loadCategoryAttributes({ categoryId: selectedSubSubcategoryId }).catch((err) => console.error(err));
            }
        }
    }

    render() {
        const { formId, translationId, item, attributeState } = this.props;
        const { attributes } = attributeState;

        if (!item) {
            return <ItemFormVariantDetailsView formId={formId} translationId={translationId} {...this.props} />;
        }

        if (item && attributes) {
            return <ItemFormVariantDetailsView formId={formId} translationId={translationId} {...this.props} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state, { variantId, item }) => {
    const itemForm = state.ui.forms['sellItem.form.create.item'] || {};

    let selectedSubSubcategoryIdDefault = null;
    if (item) {
        selectedSubSubcategoryIdDefault = item.categoryChlid;
    }

    const { formValues: itemFormValues = {} } = itemForm;
    const { subSubcategory } = itemFormValues;
    const selectedSubSubcategoryId = subSubcategory ? subSubcategory.value : selectedSubSubcategoryIdDefault;
    const attributeState = state.categoryAttributes[selectedSubSubcategoryId] || {};
    const { attributes } = attributeState;

    return {
        rootCategories: state.rootCategories.map((id) => getCategory(state, id).entity),
        getCategory: (id) => getCategory(state, id).entity,
        itemFormValues,
        selectedSubSubcategoryId,
        attributeState,
        attributes: attributes ? attributes.map((id) => getCategoryAttribute(state, id).entity) : [],
        getCategoryAttribute: (id) => getCategoryAttribute(state, id).entity,
        variant: getVariant(state, variantId).entity
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    loadCategoryAttributes: wrapAPIDispatch(dispatch, actions.loadCategoryAttributes)
});

export default connect(mapStateToProps, mapDispatchToProps)(ItemFormDetailsContainer);
