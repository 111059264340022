import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Container, Grid, Header, Label, Loader, Menu, Segment, Tab, Visibility } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDatePretty, formatPrice } from '../../../../utilities/format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { loadPurchasesByStore } from '../../../../store/actions/myProfile/loadPurchasesByStore';
import { getPaymentStatusFromId } from '../../../../components/ProductDetailList/components/ProductDetailListItem/ProductDetailListItemView';

export default function OrderByStore() {
    const { t } = useTranslation('translations');
    const params = useParams();
    const { cartId, storeId } = params;
    const dispatch = useDispatch();
    const data = useSelector((state) => state.userData.purchasesByOrder.list);
    const isLoading = useSelector((state) => state.userData.purchasesByOrder.isLoading);

    const Columns = [
        {
            key: 'cartId',
            title: 'Narudžba'
        },
        {
            key: 'date',
            title: 'Datum'
        },
        {
            key: 'store',
            title: 'Trgovina'
        },
        {
            key: 'saldo',
            title: 'Ukupno'
        },
        {
            key: 'payment',
            title: 'Plaćanje'
        },
        {
            key: 'quantity',
            title: 'Količina'
        },
        {
            key: 'status',
            title: 'Stanje'
        },
        {
            key: 'shipment',
            title: 'Kurirska služba'
        }
    ];

    function renderValue(el, index) {
        switch (index) {
            case 0:
                return formatDatePretty(new Date(el.createdAt), t);
            case 1:
                return el.item.store.name;
            case 2:
                return formatPrice(el.sellingPrice - el.discountAmount);
            case 3:
                return getPaymentStatusFromId(el.paymentStatus);
            case 4:
                return el.quantity;
            case 5:
                return el.orderStatusTitle;
            case 6:
                return el.item.shippingDetails[0].merchantShippingDetail.title;
            default:
                return '/';
        }
    }

    function loadData() {
        dispatch(
            loadPurchasesByStore(
                { limit: 10, offset: 10, cartId, storeId },
                () => {},
                (e) => console.log(e)
            )
        );
    }

    useEffect(() => {
        loadData();
    }, []);

    if (isLoading) {
        return <Loader active />;
    }

    if (Object.keys(data).length === 0) {
        return null;
    }
    return (
        <>
            <Container className='my-profile-purchases'>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Header as='h1'>
                                <Trans i18nKey='myProfile.purchases.header'>My OrderByStore</Trans>
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Visibility continuous fireOnMount>
                                <div>
                                    <Segment.Group className='simple'>
                                        <div className='table-header custom-table-header' style={{ display: 'flex' }}>
                                            {Columns &&
                                                Columns.map((c, index) => (
                                                    <div
                                                        key={c.key}
                                                        style={{
                                                            width: `${100 / Columns.length}%`,
                                                            margin: 'auto',
                                                            whiteSpace: 'nowrap',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Trans i18nKey={`myStore.orders.ordersListCol.${c.key}`}>{c.title}</Trans>
                                                    </div>
                                                ))}
                                        </div>

                                        {Object.values(data.items).map((el) => (
                                            <>
                                                <div className={'only-desktop'} key={el.id}>
                                                    <Link to={`/my-profile/purchases/${el.orderId}`} className={`my-store-product-list-item`}>
                                                        <div style={{ display: 'flex' }}>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>#{el.orderId}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{formatDatePretty(new Date(el.createdAt), t)}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{el.item.store.name}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{formatPrice(el.sellingPrice - el.discountAmount)}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{getPaymentStatusFromId(el.paymentStatus)}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{el.quantity}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{el.orderStatusTitle}</span>
                                                            </Grid.Column>
                                                            <Grid.Column className='table-row-order'>
                                                                <span>{el.item.shippingDetails[0].merchantShippingDetail.title}</span>
                                                            </Grid.Column>
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div key={el.id} className={'only-mobile'}>
                                                    <Link to={`/my-profile/purchases/${el.orderId}`} className={`my-store-product-list-item`}>
                                                        <div style={{ marginBottom: '10px' }} className='ui card'>
                                                            <div style={{ backgroundColor: '#f7f7f7' }} className='content'>
                                                                <div className='header'>{el.orderId}</div>
                                                            </div>
                                                            <div className='content'>
                                                                <div className='description'>
                                                                    {Columns.slice(1).map((column, index) => (
                                                                        <div key={column.key} className='card-item'>
                                                                            <div style={{ fontWeight: '700' }}>
                                                                                <Trans i18nKey={`myStore.orders.ordersListCol.${column.key}`}>{column.title}</Trans>
                                                                            </div>
                                                                            <div>{renderValue(el, index)}</div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='extra content'>
                                                                <div className='ui bottom attached button'>
                                                                    {' '}
                                                                    <Trans i18nKey='myStore.orders.showOrder'>Pogledaj narudžbu</Trans>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </>
                                        ))}
                                    </Segment.Group>
                                </div>
                            </Visibility>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </>
    );
}
