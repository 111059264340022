// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { wrapAPIDispatch } from 'services/api';
// import * as actions from 'store/actions';
// import type { EntityData, User } from 'store/types';

import MyProfileSettingsView from './MyProfileSettingsView';

type Props = {
    user: EntityData<User>
};

class MyProfileSettingsContainer extends Component<Props> {
    render() {
        return <MyProfileSettingsView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileSettingsContainer);
