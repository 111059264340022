// @flow
import Container from './DeliveryAddressModalContainer';

export type FormValues = {
    name: string,
    email: string,
    phone: string,
    address: string,
    addressSecondary: string,
    postal: string,
    country: number,
    state: number,
    city: number
};

export { default as Container } from './DeliveryAddressModalContainer';
export { default as View } from './DeliveryAddressModalView';

export default Container;
