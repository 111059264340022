import React, { useEffect } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { history } from 'store';
import { closeModal } from 'store/actions';

import RegisterAlert from 'components/RegisterAlert';

const RegisterAlertComponent = ({ name }) => {
    const isOpen = useSelector((state) => (state.ui.modals[name] || {}).isOpen);
    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeModal(name));
    };

    useEffect(() => {
        history.listen(() => {
            handleClose();
        });
    }, []);

    return (
        <Modal size='tiny' closeIcon open={isOpen} onClose={handleClose}>
            <Modal.Header>Registruj se</Modal.Header>
            <Modal.Content>
                <RegisterAlert />
            </Modal.Content>
        </Modal>
    );
};

export default compose(withTranslation('translations'))(RegisterAlertComponent);
