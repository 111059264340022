// @flow
import React, { PureComponent } from 'react';
import { Grid, Container, Loader, Header, Popup, Button } from 'semantic-ui-react';
import type { User } from 'store/types';
import shareIcon from '../../styles/assets/icons/share-square-solid.svg';
import FollowButton from 'components/FollowButton';
import ShareButtons from 'components/ShareButtons';
import ProfileTabBar from 'components/ProfileTabBar';
import ProfilePicture from './components/ProfilePicture';
import ProfileCover from './components/ProfileCover';

type Props = {
    user: User
};

class ProfileView extends PureComponent<Props> {
    render() {
        const { user, profileAddress } = this.props;
        const checkedAddress = profileAddress.includes('undefined') ? 'Korisnik nije unio adresu.' : profileAddress
        const { coverPhotos, alias, id, isFollowing } = user;
        const coverUrl = coverPhotos && coverPhotos.length > 0 ? coverPhotos[0].imageFull : null;
        if (user === null) {
            return (
                <Container>
                    <Loader active inline='centered' />
                </Container>
            );
        }
        return (
            <Grid className='store'>
                <Grid.Row>
                    <Grid.Column style={{ height: 350 }}>
                        <ProfileCover coverUrl={coverUrl} />
                    </Grid.Column>
                </Grid.Row>

                <Grid.Row only='computer tablet' style={{ paddingLeft: 72, paddingRight: 68 }}>
                    <div
                        style={{
                            border: '1px solid #E6E6E7',
                            width: '100%'
                        }}
                    >
                        <Grid.Column className='profileres'>
                            <Grid stackable className='auto boxShadow'>
                                <Grid.Column className='col-sm-16 col-shrink'>
                                    <ProfilePicture user={user} />
                                </Grid.Column>
                                <Grid.Column className='topSpace'>
                                    <Grid.Column style={{ paddingTop: 20 }} width={12} className='col-sm-16'>
                                        <Header as='h2' size='medium'>
                                            {alias}
                                        </Header>
                                        {checkedAddress}
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Grid.Row
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                                paddingTop: 66,
                                                paddingBottom: 15
                                            }}
                                        >
                                            <Grid.Column>
                                                <FollowButton followId={id} isFollowing={isFollowing} parentId={id} />
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>
                                                <i className='heart icon' />
                                                {user.totalItemsLiked} Likes
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>
                                                <i className='user icon' />
                                                {user.totalFollowers} Followers
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20, marginTop: 10 }}>
                                                <i className='user icon' />
                                                {user.totalFollowing} Following
                                            </Grid.Column>
                                            <div style={{ marginLeft: 'auto', paddingRight: 20 }}>
                                                <Popup
                                                    trigger={
                                                        <Button size='small' color='black' icon>
                                                            <img style={{ paddingRight: 10, marginBottom: -2 }} src={shareIcon}></img>
                                                            <label style={{ fontSize: 14 }}>Share</label>
                                                        </Button>
                                                    }
                                                    flowing
                                                    hoverable
                                                >
                                                    <ShareButtons></ShareButtons>
                                                </Popup>
                                            </div>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </div>
                </Grid.Row>

                <Grid.Row only='mobile'>
                    <div
                        style={{
                            border: '1px solid #E6E6E7',
                            marginLeft: 32,
                            marginRight: 28,
                            paddingLeft: 15,
                            paddingRight: 18,
                            width: '100%'
                        }}
                    >
                        <Grid.Column className='profileres'>
                            <Grid stackable className='auto boxShadow'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <ProfilePicture user={user} />
                                    </Grid.Column>
                                    <Grid.Column style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                                        <div>
                                            <Popup
                                                style={{ width: 'min-content' }}
                                                trigger={
                                                    <Button size='small' color='black' icon>
                                                        <img style={{ paddingRight: 10, marginBottom: -2 }} src={shareIcon}></img>
                                                        <label style={{ fontSize: 14 }}>Share</label>
                                                    </Button>
                                                }
                                            >
                                                <ShareButtons></ShareButtons>
                                            </Popup>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Column className='topSpace'>
                                    <Grid.Column width={12}>
                                        <Header as='h2' size='medium'>
                                            <Header size='tiny' as='h1'>
                                                {alias}
                                            </Header>
                                            {checkedAddress}
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column width={4}>
                                        <Grid.Row
                                            style={{
                                                flexDirection: 'row',
                                                display: 'flex',
                                                paddingTop: 25,
                                                paddingBottom: 15
                                            }}
                                        >
                                            <Grid.Column>
                                                <FollowButton followId={id} isFollowing={isFollowing} parentId={id} />
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20 }}>
                                                <i className='heart icon' />
                                                {user.totalItemsLiked} Likes
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20 }}>
                                                <i className='user icon' />
                                                {user.totalFollowers} Followers
                                            </Grid.Column>
                                            <Grid.Column style={{ marginLeft: 20 }}>
                                                <i className='user icon' />
                                                {user.totalFollowing} Following
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid.Column>
                            </Grid>
                        </Grid.Column>
                    </div>
                </Grid.Row>
                <Grid.Row only='computer tablet' style={{ paddingLeft: 85, paddingRight: 53 }}>
                    <ProfileTabBar user={user} />
                </Grid.Row>
                <Grid.Row only='mobile' style={{ paddingLeft: 32 }}>
                    <ProfileTabBar user={user} />
                </Grid.Row>
            </Grid>
        );
    }
}

export default ProfileView;
