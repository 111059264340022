// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MERCHANT_STORES ----------------------
export const LOAD_MERCHANT_STORES = 'LOAD_MERCHANT_STORES';
export const loadMerchantStores = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_MERCHANT_STORES,
        endpoint: '/v3/me/stores',
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {},
        //preprocess: ({ activity }) => activity
    }
}));
