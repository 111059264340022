// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image } from 'semantic-ui-react';

import SellItemImageListItemMove from './components/SellItemImageListItemMove';
import SellItemImageListItemDelete from './components/SellItemImageListItemDelete';

type Props = {
    item: Object,
    first: boolean,
    last: boolean,
    moveImage: () => void,
    deleteImage: () => void
};

function SellItemImageListItem({ item, first, last, moveImage, deleteImage }: Props) {
    return (
        <div className='sell-item-image-list-item'>
            <Image fluid className='sell-item-image-list-item-image'>
                <img src={item.data} alt='' />
                <SellItemImageListItemMove item={item} first={first} last={last} moveImage={moveImage} />
            </Image>
            <SellItemImageListItemDelete item={item} deleteImage={deleteImage} />
        </div>
    );
}

export default withTranslation('translations')(SellItemImageListItem);
