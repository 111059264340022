import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Container, Header, Segment } from 'semantic-ui-react';
import { navigatePost } from 'utilities';
import { RESPONSE_URL } from 'services/paymentGateway/ipay88';

function postPaymentResponseCancel() {
    const body = {
        ActionType: '',
        Amount: '1.00',
        AuthCode: '',
        Currency: 'MYR',
        ErrDesc: 'Customer+Cancel+Transaction',
        ErrStatus: '',
        MerchantCode: 'M11166',
        PaymentId: '2',
        RefNo: 'CART#2372',
        Remark: '',
        Signature: '94fe0f76f8dcc17756075d9d8dce874a9f8bc2440c23e78fab676a347c19cfcf',
        Status: 0,
        TransId: 'T138617698000'
    };
    navigatePost(RESPONSE_URL, { body });
}

function DevTestPanelScene() {
    return (
        <Container>
            <Header as='h1'>Dev testpanel</Header>
            <Segment>
                <Header as='h2'>iPay88 POST tests</Header>
                <Button onClick={postPaymentResponseCancel}>Customer Cancel Transaction</Button>
            </Segment>
        </Container>
    );
}

export default process.env.NODE_ENV === 'development' ? DevTestPanelScene : () => null;
