// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import type { EntityData, User } from 'store/types';
import UserTeaserView from './UserTeaserView';

type Props = {
    user: EntityData<User>
};

const UserTeaserContainer = (props: Props) => <UserTeaserView {...props} />;

export default UserTeaserContainer;
