// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

const DEVICE_TYPES = {
    ios: 1,
    android: 2,
    web: 3
};

// LOG_IN_FACEBOOK ------------------
export type LogInFacebook = {
    fbToken: string,
    pushNotificationToken?: string,
    deviceType?: $Keys<typeof DEVICE_TYPES>,
    action?: string,
    deviceType?: string,
    pushNotificationToken?: string,
    userType?: number
};

export const LOG_IN_FACEBOOK = 'LOG_IN_FACEBOOK';
export const logInFacebook = wrapAPIActionCreator(({ deviceType = 'web', pushNotificationToken = 'x', fbToken }: LogInFacebook) => {
    console.log('Function logInFacebook is called.');  // Dodajte ovu liniju za proveru

    const apiData = {
        [API_DATA]: {
            type: LOG_IN_FACEBOOK,
            method: 'GET',
            endpoint: '/v3/auth/microsoft',
            body: {
                access_token: fbToken
            },
            preprocess: ({ accessToken, ...rest }) => {
                return { user: rest, accessToken };
            },
            schema: {
                user: Schemas.user
            }
        }
    };
    return apiData;
});

// Dodajte poziv funkcije kako biste proverili da li se preprocessing poziva
// logInFacebook({ fbToken: 'yourFacebookToken' });

export default logInFacebook;
