// @flow
import { API_DATA, wrapAPIActionCreator } from 'services/api';

export type ForgotPasswordParams = {
    email: string
};

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = wrapAPIActionCreator(({ email }: ForgotPasswordParams) => ({
    [API_DATA]: {
        type: FORGOT_PASSWORD,
        endpoint: '/v3/auth/forgot_password',
        method: 'POST',
        body: {
            email
        }
    }
}));
