// @flow
import { compose } from 'redux';
import * as moment from 'moment';

const reverseString = (str) => str.split('').reverse().join('');

const capitalizeWords = (str: string) => {
    const titleCaseWord = (word) => word[0].toUpperCase() + word.slice(1).toLowerCase();
    const isArticle = (word) => ['a', 'an', 'of', 'the'].includes(word);

    // Apply auto-capitalisation
    return str.replace(/[\w']+/g, (word) => (isArticle(word) ? word : titleCaseWord(word)));
};

const removeStyleAttributes = (description: String) => description.replace(/style="[^"]*"/g, '');
// const injectHashtagLinks = (description: String) => description.replace(/#([\w_.-]+)/g, '<a href="/search/tags/$1">#$1</a>');
const injectAtCharLinks = (description: String) => description.replace(/@([\w_.-]+)/g, '<a href="/$1">@$1</a>');
const injectAnytagLinks = (tags: String) => tags.replace(/([#@]?)([\w_.-]+)/g, '<a href="/search/tags/$2">$1$2</a>');

type FormatNumberOptions = {
    decimals?: number,
    decimalSeparator?: string,
    thousandSeparator?: string,
    fallback?: string
};
export function formatNumber(num: number, options: FormatNumberOptions = {}) {
    const { decimals = null, decimalSeparator = '.', thousandSeparator = '', fallback = '--' } = options;

    if (typeof num !== 'number') {
        return fallback;
    }

    const str = decimals == null ? String(num) : num.toFixed(decimals);
    const [integralPart, decimalPart] = str.split('.');

    let integralPretty = reverseString(reverseString(integralPart).replace(/\d{3}/g, `$&${thousandSeparator}`));
    if (integralPretty[0] === thousandSeparator) {
        integralPretty = integralPretty.slice(1);
    }

    return decimalPart == null ? integralPretty : [integralPretty, decimalPart].join(decimalSeparator);
}

/** Used to pretty-print prices everywhere on the site */
export function formatPrice(value: number, currency: string = 'KM') {
    const formatOptions = {
        decimals: 2,
        decimalSeparator: '.',
        thousandSeparator: ','
    };
    switch (currency) {
        case 'MYR':
            return `RM ${formatNumber(value, formatOptions)}`;
        case 'BAM':
        case 'KM':
            return `${formatNumber(value, formatOptions)} KM`;
        case 'EUR':
            return `€${formatNumber(value, formatOptions)}`;
        default:
            return `${formatNumber(value, formatOptions)} ${currency}`;
    }
}

/** Used to pretty-print usernames everywhere on the site */
export function formatUsername(name: string) {
    const pretty = name.replace(/@mybazar$/, '');
    return `@${pretty}`;
}

/** Used to pretty-print product names */
export function formatItemName(name: string) {
    const letters = name.match(/[A-Za-z]/g);
    if (letters != null && letters.length > 4) {
        return capitalizeWords(name);
    }
    return name;
}

export function formatStoreName(name: string) {
    return capitalizeWords(name);
}

export function formatUserAlias(str: string) {
    return str;
}

export function formatItemDescription(description: string) {
    return compose(injectAtCharLinks, removeStyleAttributes)(description);
}

export function formatItemTags(tags: string) {
    return compose(injectAnytagLinks, removeStyleAttributes)(tags);
}

export function formatDate(date: Date) {
    const month = date.getMonth() + 1;
    const yyyy = date.getFullYear();
    const mm = (month < 10 ? '0' : '') + month;
    const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    return `${yyyy}-${mm}-${dd}`;
}

export function formatDatePretty(date: Date, t: any, time = '') {
    const monthNames = [
        t('months.January', { defaultValue: 'January' }),
        t('months.February', { defaultValue: 'February' }),
        t('months.March', { defaultValue: 'March' }),
        t('months.April', { defaultValue: 'April' }),
        t('months.May', { defaultValue: 'May' }),
        t('months.June', { defaultValue: 'June' }),
        t('months.July', { defaultValue: 'July' }),
        t('months.August', { defaultValue: 'August' }),
        t('months.September', { defaultValue: 'September' }),
        t('months.October', { defaultValue: 'October' }),
        t('months.November', { defaultValue: 'November' }),
        t('months.December', { defaultValue: 'December' })
    ];

    const hours = time && (date.getHours() < 10 ? '0' : '') + date.getHours();
    const minutes = time && (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
    const formattedTime = `u ${hours}:${minutes}`;

    const yyyy = date.getFullYear();
    const dd = (date.getDate() < 10 ? '0' : '') + date.getDate();
    return `${dd}. ${monthNames[date.getMonth()]} ${yyyy}. ${time && formattedTime}`;
}

export function formatBigNumber(num: number) {
    if (num >= 1000000) {
        return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
    }
    if (num >= 1000) {
        return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}K`;
    }
    return num;
}

function translateDay(string) {
    const translations = {
        Monday: 'Ponedjeljak',
        Tuesday: 'Utorak',
        Wednesday: 'Srijeda',
        Thursday: 'Četvrtak',
        Friday: 'Petak',
        Saturday: 'Subota',
        Sunday: 'Nedjelja'
    };
    return string.replace(/Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday/g, (match) => translations[match]);
}

export function formatDatestamp(date: number, t: any) {
    const now = moment();
    const targetDate = moment(date);
    const minutesDiff = now.diff(moment(date), 'minutes');

    if (minutesDiff > 60) {
        return translateDay(
            targetDate.calendar(null, {
                sameDay: `[${t('timestamp.today', { defaultValue: 'Today' })}] HH:mm`,
                lastDay: `[${t('timestamp.yesterday', { defaultValue: 'Yesterday' })}] HH:mm`,
                lastWeek: `[${t('timestamp.last', { defaultValue: 'Last' })}] dddd HH:mm`,
                sameElse: 'DD/MM/YYYY HH:mm'
            })
        );
    }

    if (minutesDiff > 1) {
        return `${minutesDiff} ${t('timestamp.minutesAgo', { defaultValue: 'minutes ago' })}`;
    }
    return t('timestamp.justNow', { defaultValue: 'Just now' });
}

export function formatOfferDuration(timeLeft, t) {
    const { daysLeft: days, hoursLeft: hours, minutesLeft: minutes } = timeLeft;

    let time = '';
    if (days < 1 && hours < 1) {
        time = `${minutes === 0 ? minutes + 1 : minutes}min`;
    } else if (days < 1 && hours >= 1) {
        time = `${hours}h ${minutes}min`;
    } else if (days >= 1) {
        time = `${days}d ${hours}h ${minutes}min`;
    }
    return `${time} ${t('messages.conversation.offer.offerStamp', { defaultValue: 'until offer expires' })}`;
}
