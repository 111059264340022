// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import { getIsLoggedIn, getUser } from 'store/utilities';
import { onPageRefresh } from 'utilities/languages';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import { withTranslation } from 'react-i18next';
import Header from './components/Header';
import Modals from './components/Modals';
import Footer from './components/Footer';
class Page extends Component {
    constructor() {
        super();
        this.state = {
            isMobile: window.innerWidth <= 500
        };
        this.handleDismissPromo = this.handleDismissPromo.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    // UNSAFE_componentWillMount() {
    //   window.addEventListener('resize', this.handleWindowSizeChange);
    // }

    handleWindowSizeChange = () => {
        this.setState({ isMobile: window.innerWidth <= 500 });
    };

    componentDidMount() {
        onPageRefresh(this.props.isLoggedIn);
        this.timer = setInterval(() => this.getAllConversations(), 300000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    getAllConversations() {
        const { isLoggedIn, merchantUser } = this.props;
        if (isLoggedIn) {
            this.props.loadMyConversations({ offset: 0 });
            if (merchantUser) {
                this.props.loadMyStoreConversations({ offset: 0 });
            }
        }
    }

    handleDismissPromo() {
        const { dismissPromoBar } = this.props;
        dismissPromoBar();
    }

    render() {
        const { t, children, isLoggedIn, onLogout, location, languages } = this.props;
        const showPromo = false;
        const isFooterVisible = !(location?.pathname === '/login' || location?.pathname === '/select-profile' || location?.pathname === '/password');
        const isHeaderVisible = !(location?.pathname === '/password');

        return (
            <div className='page'>
                <Helmet
                    titleTemplate={`%s - ${t('head.title.name', {
                        defaultValue: 'MyBazar'
                    })}`}
                    defaultTitle={`${t('head.title.name', {
                        defaultValue: 'MyBazar'
                    })} - ${t('head.title.slogan', {
                        defaultValue: 'World’s Local Bazar'
                    })}`}
                />
                {isHeaderVisible && <Header showPromo={showPromo} onDismissPromo={this.handleDismissPromo} onLogout={onLogout} isLoggedIn={isLoggedIn} onClick={onLogout} />}
                <div
                    className={`${location?.pathname !== '/login' ? 'page-main' : ''} ${showPromo ? 'page-main page-main-promo' : ''}`}
                    style={{ margin: location?.pathname === '/password' && '0' }}
                >
                    {children}
                </div>
                {isFooterVisible && <Footer languages={languages} />}
                <Modals languages={languages} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: getIsLoggedIn(state),
    merchantUser: getUser(state, state.merchantData.user).entity,
    promoDismissed: state.ui.promoDismissed
});

const mapDispatchToProps = (dispatch) => ({
    loadMyConversations: wrapAPIDispatch(dispatch, actions.loadMyConversations),
    loadMyStoreConversations: wrapAPIDispatch(dispatch, actions.loadMyStoreConversations),
    dismissPromoBar: () => dispatch(actions.dismissPromoBar())
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(Page);
