import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsLoggedIn, getUser } from 'store/utilities';

import MyProfileSettingsFormView from './MyProfileSettingsFormView';

import { makeSetFormValuesReducer } from './reducers';

class MyProfileSettingsFormContainer extends Component {
    constructor(props) {
        super(props);
        const { alias, username, email, phoneNumber } = props.user.entity;

        this.state = {
            errorMessage: null,
            successMessage: null,
            fieldData: {
                name: { value: alias },
                username: { value: username },
                email: { value: email },
                phone: { value: phoneNumber },
                newPassword: { value: '' },
                currentPassword: { value: '' }
            }
        };
    }

    handleChange = (event, { name, value }) => {
        this.setState(makeSetFormValuesReducer({ [name]: value }));
    };

    handleSubmit = () => {
        const { updateProfile } = this.props;
        const { fieldData } = this.state;
        const {
            name: { value: name },
            username: { value: username },
            email: { value: email },
            phone: { value: phone },
            newPassword: { value: newPassword },
            currentPassword: { value: currentPassword }
        } = fieldData;

        const currentPw = this.state.fieldData.currentPassword.value;
        const newPw = this.state.fieldData.newPassword.value;

        if (!!currentPw.length || !!newPw.length) {
            if (!!currentPw.length && !newPw.length) {
                if (currentPw.length < 5 || currentPw.length > 50) {
                    this.setState({
                        isPosting: false,
                        errorMessage: 'Šifra mora imati više od 5 a manje od 50 karaktera.',
                        successMessage: null
                    });
                    return;
                }
            } else if (!!currentPw && !!newPw.length) {
                if (currentPw.length < 5 || currentPw.length > 50) {
                    this.setState({
                        isPosting: false,
                        errorMessage: 'Trenutna šifra mora imati više od 5 a manje od 50 karaktera.',
                        successMessage: null
                    });
                    return;
                }
                if (newPw.length < 8 ||
                    newPw.length > 50 ||
                    !/[a-zA-Z]/.test(newPw) ||
                    !/\d/.test(newPw) ||
                    !/[A-Z]/.test(newPw)) {
                    this.setState({
                        isPosting: false,
                        errorMessage: 'Nova šifra mora imati između 8 i 50 karakter te sadržavati barem jedno veliko slovo, jedan znak i jedan broj.',
                        successMessage: null
                    });
                    return;
                }
            } else {
                this.setState({
                    isPosting: false,
                    errorMessage: 'Unesite trenutnu šifru za nasavak.',
                    successMessage: null
                });
                return;
            }
        }
        this.setState({
            isPosting: true,
            errorMessage: null,
            successMessage: null
        });
        updateProfile({
            alias: name,
            username,
            email,
            phoneNumber: phone,
            password: newPassword || undefined,
            confirmPassword: currentPassword || undefined
        })
            .then(() => {
                fieldData.newPassword.value = '';
                fieldData.currentPassword.value = '';
                this.setState({
                    isPosting: false,
                    successMessage: 'Changes saved',
                    fieldData
                });
            })
            .catch((err) => {
                console.warn('catch', err);
                this.setState({ isPosting: false, errorMessage: err.message });
            });
    };

    render() {
        const { ...rest } = this.props;
        const { errorMessage, successMessage, isPosting, fieldData } = this.state;
        return <MyProfileSettingsFormView onChange={this.handleChange} onSubmit={this.handleSubmit} {...{ errorMessage, successMessage, isPosting, fieldData }} {...rest} />;
    }
}

const mapStateToProps = (state) => {
    const isLoggedIn = getIsLoggedIn(state);
    const user = getUser(state, state.userData.user);
    return { isLoggedIn, user };
};

const mapDispatchToProps = (dispatch) => ({
    updateProfile: wrapAPIDispatch(dispatch, actions.updateProfile)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyProfileSettingsFormContainer);
