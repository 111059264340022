// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Image, Label } from 'semantic-ui-react';

import missingUserImage from 'styles/assets/images/missing-user-image.svg';

const Props = {
    user: {}
};

function SidebarProfileUserImageView({ user }: Props) {
    return (
        <div className='store-logo' style={{ margin: 0, width: '7rem' }}>
            <div className='store-logo-circle' style={{ backfaceVisibility: 'hidden' }}>
                <Image
                    className='store-logo-image'
                    fluid
                    src={user.pictureThumb || missingUserImage}
                    onError={(event) => {
                        event.target.src = missingUserImage;
                    }}
                />
            </div>
            {user.isVerified === true && (
                <Label className='store-logo-label' circular basic={!user.pictureThumb} icon='check' size='tiny' style={{ top: '0.25rem', right: '0.25rem' }} />
            )}
        </div>
    );
}

export default SidebarProfileUserImageView;
