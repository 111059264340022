// @flow
import React from 'react';
import { Form, Label, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

type Props = {
    formId: string
};

type State = {
    errorMessage: ?string,
    price: FormFieldData<number>,
    applyVAT: FormFieldData<boolean>,
    dealType: FormFieldData<string>,
    salePrice: FormFieldData<number>
};

export const dealTypes = [
    {
        value: 'none'
    },
    {
        value: 'sale'
    }
    // {
    //   value: 'gift',
    // },
    // {
    //   value: 'buy_3_for_2',
    // }
    // TODO: After first release
];

const differenceInPercent = (priceString, salePriceString) => {
    let percent = 100;
    if (!priceString || !salePriceString) {
        return `${percent}%`;
    }
    const price = parseFloat(priceString);
    const salePrice = parseFloat(salePriceString);
    if (salePrice === 0) {
        return `${percent}%`;
    }
    if (salePrice < price) {
        percent = `-${(((price - salePrice) / price) * 100).toFixed(2)}`;
    }
    return `${percent}%`;
};

class SellItemFormPrice extends CommonForm<Props, State> {
    state = {
        formValues: {
            price: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
            applyVAT: createEmptyFormFieldData({ value: false, type: 'boolean' }),
            dealType: createEmptyFormFieldData({ value: 'none' }),
            salePrice: createEmptyFormFieldData({ value: '', type: 'number' }),
            tags: createEmptyFormFieldData({ value: '', required: false })
        }
    };

    render() {
        const { formValues } = this.state;
        const { formId, translationId, t } = this.props;
        console.log(translationId);

        const dealOptions = dealTypes.map(({ value }) => {
            const text = t(`sellItem.form.price.dealType.options.${value}`);
            return { value, text };
        });

        const {
            dealType: { value: dealType },
            price: { value: price },
            salePrice: { value: salePrice }
        } = formValues;

        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='sellItem.form.price.header'>Price</Trans>
                    </Header>
                </Form.Field>

                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderInputField({ name: 'price', type: 'number', step: '0.01', width: 8 }, this.props)}

                        {this.renderCheckboxField({ name: 'applyVAT', type: 'boolean', width: 8 }, this.props)}
                    </Form.Group>
                </Form.Field>
                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderDropdownField({ name: 'dealType', options: dealOptions, width: 8 }, this.props)}

                        {(() => {
                            switch (dealType) {
                                case 'none':
                                    return null;
                                case 'sale':
                                    return (
                                        <Form.Field width={8}>
                                            <label htmlFor={`${formId}-saleprice`}>
                                                <Trans i18nKey={`${translationId}.salePrice.label`}>Sale Price</Trans>
                                            </label>
                                            <Form.Input
                                                type='text'
                                                name='salePrice'
                                                value={salePrice}
                                                id={`${formId}-saleprice`}
                                                step='0.01'
                                                labelPosition='right'
                                                onChange={(event, { value, ...data }) => {
                                                    let newValue = value;
                                                    if (parseFloat(value) > parseFloat(price)) {
                                                        newValue = salePrice;
                                                    }
                                                    this.handleChange(event, { ...data, value: newValue });
                                                }}
                                                placeholder={`KM ${salePrice}`}
                                            >
                                                <input />
                                                <Label basic>{differenceInPercent(price, salePrice)}</Label>
                                            </Form.Input>
                                        </Form.Field>
                                    );
                                default:
                                    return null;
                            }
                        })()}
                    </Form.Group>
                </Form.Field>
                <Form.Field>{this.renderInputField({ name: 'tags', type: 'text', defaultLabel: t('sellItem.attributes.tags') }, this.props)}</Form.Field>
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(SellItemFormPrice);
