// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Button } from 'semantic-ui-react';

type Props = {
    user: EntityData<User>
};

function SidebarProfileActions({ user }: Props) {
    if (!user) {
        return null;
    }
    return (
        <Grid className='auto tensed sidebar-profile-actions'>
            <Grid.Column className='col-shrink col-offset-left-auto'>
                <Button as={Link} to='/my-profile/settings' basic inverted icon='edit' size='tiny' />
            </Grid.Column>
        </Grid>
    );
}

export default SidebarProfileActions;
