// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { wrapAPIDispatch } from 'services/api';

import DeliveryAddressEditModalView from './DeliveryAddressEditModalView';

type Props = {
    // from store
    updateAddress: typeof actions.updateAddress,
    deleteAddress: typeof actions.deleteAddress,
    openModal: typeof actions.openModal
};

class DeliveryAddressEditModalContainer extends Component<Props> {
    handleSubmit = ({ addressId, name, email, phone, address, addressSecondary, postal, country, state, city, country_id, state_id, city_id }) => {
        const { updateAddress } = this.props;
        return updateAddress({
            addressId,
            fullName: name,
            emailAddress: email,
            contactNo: phone,
            street: address,
            houseNo: addressSecondary,
            zip: postal,
            country_id: country_id,
            state_id: state_id,
            city_id: city_id,
            country,
            state,
            city
        });
    };

    handleDelete = ({ addressId }) => {
        const { deleteAddress } = this.props;
        return deleteAddress({ addressId });
    };

    render() {
        const { ...rest } = this.props;
        return <DeliveryAddressEditModalView onSubmit={this.handleSubmit} onDelete={this.handleDelete} {...rest} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateAddress: wrapAPIDispatch(dispatch, actions.updateAddress),
    deleteAddress: wrapAPIDispatch(dispatch, actions.deleteAddress),
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(DeliveryAddressEditModalContainer);
