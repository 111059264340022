import React, { useState, useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import type { EntityData, Item } from 'store/types';

import { debounce } from 'utilities';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getItem } from 'store/utilities';
import SearchInputView from './SearchInputView';
import { Icon, Menu } from 'semantic-ui-react';

type Props = {
    // From store
    searchItems: EntityData<Item>[],
    searchCounts: { [string]: number },
    searchByKeyword: typeof actions.searchByKeyword,
    isSearchActive: boolean,
    onCloseSearch: () => void,
    // From middleware
    history: {
        push: (string) => void
    }
};

const SearchInputContainer = ({
                                  searchItems,
                                  searchCounts,
                                  searchByKeyword,
                                  isSearchActive,
                                  onCloseSearch,
                                  history,
                              }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const performDebouncedSearch = useCallback(
        debounce((query) => {
            searchByKeyword({
                keyword: query,
                limit: 5
        })
            .then(() => {
                setIsLoading(false);
            })
            .catch((err) => {
                console.error(err);
                    setIsLoading(false);
            });
    }, 400), []);

    const handleSearchChange = useCallback((value) => {
        setSearchQuery(value);
        if (value.length >= 1) {
                setIsLoading(true);
                performDebouncedSearch(value);
        }
    }, [performDebouncedSearch, searchQuery]);

    const handleResultSelect = useCallback((event, { result }) => {
        if (result.link != null) {
            history.push(result.link);
        }
        onCloseSearch();
    }, [history, onCloseSearch]);


    const handleSubmit = () => {
        if (searchQuery.length > 0) {
            history.push(`/search/${encodeURIComponent(searchQuery)}`);
        }
    }




    return (
        <SearchInputView
            onSearchChange={handleSearchChange}
            onResultSelect={handleResultSelect}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            searchQuery={searchQuery}
            searchItems={searchItems}
            searchCounts={searchCounts}
            onCloseSearch={onCloseSearch}
            opened={isSearchActive}
        />
    );
};

const mapStateToProps = (state) => {
    const { searchResults } = state;
    const { items, itemCount, tagItemCount, storeCount, userCount , classifiedItemCount, classifiedTagItemCount, classifiedUserCount, classifiedStoreCount } = searchResults.searchByKeyword;
    const searchItems = (items || []).map((id) => getItem(state, id));
    return {
        searchItems,
        searchCounts: {
            items: itemCount,
            tagItems: tagItemCount,
            stores: storeCount,
            users: userCount,
            cItems: classifiedItemCount,
            cTags: classifiedTagItemCount,
            cUsers: classifiedUserCount,
            cStores: classifiedStoreCount
        }
    };
};

const mapDispatchToProps = (dispatch) => ({
    searchByKeyword: wrapAPIDispatch(dispatch, actions.searchByKeyword)
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SearchInputContainer);
