import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export const UPDATE_MERCHANT_ACCOUNT = 'UPDATE_MERCHANT_ACCOUNT';
export const updateMerchantAccount = wrapAPIActionCreator(({ // alias,
    phoneNumber, description, webNotification, emailNotification, allowNegotiate = true, businessType, email, icNumber, isBlee, licenseName, licenseUrl, name, openToCustomer = true, slug, store, storeIndustry, username, referralCode, vatNumber, agentOfficeID }) => ({
    [API_DATA]: {
        type: UPDATE_MERCHANT_ACCOUNT,
        endpoint: '/v3/me/web/settings',
        authentication: Authentication.Merchant,
        method: 'PUT',
        body: {
            agentOfficeId: agentOfficeID,
            alias: name,
            allowNegotiate,
            businessType,
            description,
            email,
            emailNotification,
            gstRegistrationNumber: vatNumber,
            icNumber,
            isBlee,
            licenseName,
            licenseUrl,
            // name,
            openToCustomer,
            phoneNumber,
            referralCode,
            store,
            storeIndustry,
            userSlug: slug,
            username,
            webNotification
        },
        schema: {
            // user: Schemas.user,
        },
        preprocess: () => ({})
    }
}));
