import React, { Component } from 'react'

import TagButtonsView from './TagButtonsView'

type Props = {
    tags: any
};

class TagButtonsContainer extends Component<Prop> {
    render() {
        const { tags } = this.props
        return <TagButtonsView tags={tags} />
    }
}

export default TagButtonsContainer