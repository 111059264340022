// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Image, Header } from 'semantic-ui-react';

import type { EntityData, Item, Offer } from 'store/types';
import Price from 'components/Price';
import { formatPrice } from 'utilities/format';

import missingImage from 'styles/assets/images/missing-image.svg';

type Props = {
    item: EntityData<Item>,
    offer: EntityData<Offer>
};

function MessageProductView({ item, user, offer }: Props) {
    const { entity: itemEntity } = item;
    const { entity: offerEntity } = offer || {};

    if (!itemEntity) {
        return null;
    }
    const { name } = itemEntity;
    const itemImageUrl = itemEntity.pictures && itemEntity.pictures[0] ? itemEntity.pictures[0].imageThumb : '';

    const { quantity = 0, offerTotalPrice } = offerEntity || {};

    return (
        <Grid className='auto tensed'>
            <Grid.Column className='col-3'>
                <Image
                    as={Link}
                    to={`/item/${itemEntity.id}`}
                    className='square-image'
                    wrapped
                    alt={name}
                    src={itemImageUrl || missingImage}
                    onError={(event) => {
                        event.target.src = missingImage;
                    }}
                />
            </Grid.Column>
            <Grid.Column className='col-13'>
                <Grid className='auto very-tensed'>
                    <Grid.Column className='col-row'>
                        <label className='message-user-alias'>{user.entity.alias}</label>
                        <Header as='h2' size='medium' style={{ marginTop: '5px' }}>
                            <Link className='inherit-color message-product-title' to={`/item/${itemEntity.id}`}>
                                {name}
                            </Link>
                            <Header.Subheader>
                                <Price item={itemEntity} offer={offerEntity} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    {quantity > 0 && offerTotalPrice && (
                        <Grid.Column className='col-row'>
                            <Grid className='auto absolutely-tensed'>
                                <Grid.Column className='col-row'>x{quantity}</Grid.Column>
                                <Grid.Column className='col-row'>{formatPrice(Number(offerTotalPrice))}</Grid.Column>
                            </Grid>
                        </Grid.Column>
                    )}
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default MessageProductView;
