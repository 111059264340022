// @flow
import React from 'react';
import { Container, Loader } from 'semantic-ui-react';

import type { EntityData, LandingComponent } from 'store/types';

import PromotionalCarousel from 'components/PromotionalCarousel';

type Props = {
    component: EntityData<LandingComponent>
};

function LandingBannerCarouselView({ component }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }

    const { id: key, entities } = component.entity;

    return <PromotionalCarousel key={key} content={entities.map(({ id }) => id)} />;
}

export default LandingBannerCarouselView;
