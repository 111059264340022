// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';
import { renameProperties } from '../../../utilities';

// LOAD_CATEGORY_TREE ---------------------------
type LoadCategoryTreeParams = {
    onlyBlee?: boolean,
    collection?: ?number,
    bazar?: string
};

export const LOAD_CATEGORY_TREE = 'LOAD_CATEGORY_TREE';
export const loadCategoryTree = wrapAPIActionCreator(({ onlyBlee = false, collection = undefined , bazar='' }: LoadCategoryTreeParams) => {
    const renameItemsToSubcategories = (obj) => renameProperties(obj, { subcategories: 'items' });
    return {
        [API_DATA]: {
            type: LOAD_CATEGORY_TREE,
            endpoint: '/v3/all_categories',
            endpointParams: {
                onlyBlee,
                collection,
                includeAll: true,
                bazar
            },
            reducerParams: {
                collection
            },
            preprocess: (res) =>
                res.map((category) => ({
                    ...category,
                    // Normalizes so that Category subcategories are always in 'subcategories'
                    subcategories: category.subcategories.map(renameItemsToSubcategories)
                })),
            schema: [Schemas.category],
            postprocess: ({ ...rest }) => ({ collection, ...rest })
        }
    };
});

export default loadCategoryTree;
