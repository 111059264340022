// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getIsNonTempUser } from 'store/utilities';

import LogInFormView from './view';

class LogInForm extends Component {
    render() {
        return <LogInFormView {...this.props} />;
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.userData.accessToken !== null,
    isLoggingIn: state.ui.isLoggingIn,
    isNonTempUser: getIsNonTempUser(state)
});

const mapDispatchToProps = (dispatch) => ({
    loginUser: wrapAPIDispatch(dispatch, actions.loginUser),
    loadCartItems: wrapAPIDispatch(dispatch, actions.loadCartItems),
    setIsLoggingIn: wrapAPIDispatch(dispatch, actions.setIsLoggingIn)
});

export default connect(mapStateToProps, mapDispatchToProps)(LogInForm);
