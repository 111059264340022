import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

const BasicInfo = ({ nameAttributes, emailAttributes, phoneAttributes }) => {
    const { t } = useTranslation('translations');
    return (
        <>
            <Form.Input name='name' label={t('signUpForm.name.label', { defaultValue: 'Full name' })} {...nameAttributes} required />
            <Form.Input name='phone' label={t('signUpForm.phone.label', { defaultValue: 'Phone number' })} {...phoneAttributes} required />
            <Form.Input name='email' label={t('signUpForm.email.label', { defaultValue: 'Email' })} {...emailAttributes} required />
        </>
    );
};

export default BasicInfo;
