import store from 'store';
import * as actions from 'store/actions';
import { syncLanguage } from 'utilities/languages';
import { getUser } from 'store/utilities';
import { wrapAPIDispatch } from '../services/api';

export default function onLogin() {
    const loadMyAccounts = wrapAPIDispatch(store.dispatch, actions.loadMyAccounts);
    const loadCartItems = wrapAPIDispatch(store.dispatch, actions.loadCartItems);
    const logoutUser = wrapAPIDispatch(store.dispatch, actions.logoutUser);
    const checkPaymentStatus = wrapAPIDispatch(store.dispatch, actions.checkPaymentStatus);
    const loadMyConversations = wrapAPIDispatch(store.dispatch, actions.loadMyConversations);
    const loadMyStoreConversations = wrapAPIDispatch(store.dispatch, actions.loadMyStoreConversations);

    const { cart } = store.getState().userData;

    loadMyAccounts()
        .then((accounts) => {
            const apiActions = [loadCartItems(), syncLanguage(accounts), loadMyConversations({ offset: 0 })];
            const state = store.getState();
            const merchantUser = state.merchantData.user ? getUser(state, state.merchantData.user).entity : null;
            if (merchantUser) {
                apiActions.push(loadMyStoreConversations({ offset: 0 }));
            }
            return Promise.all(apiActions);
        })
        .catch((err) => {
            switch (err.code) {
                case 401:
                    logoutUser();
                    break;
                case 402: {
                    let { cartId } = cart;
                    if (!cartId && err.extra && err.extra.info) {
                        cartId = err.extra.info;
                    }
                    checkPaymentStatus({ cartId }).catch((error) => console.error({ error }));
                    break;
                }
                default:
                    console.error(err);
            }
        });
}
