// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, Container } from 'semantic-ui-react';

import { getMessage } from 'store/utilities';
import PromotionsView from './PromotionsView';

type Props = {
    // from store
    isLoading: boolean,
    items: [object]
};

class PromotionsContainer extends Component<Props, {}> {
    render() {
        const { isLoading, items } = this.props;
        if (isLoading) {
            return (
                <Container>
                    <Loader active />;
                </Container>
            );
        }

        return <PromotionsView items={items} />;
    }
}

const mapStateToProps = (state) => ({
    isLoading: state.userData.promotions.isLoading,
    items: state.userData.promotions.list.map((id) => getMessage(state, id))
});

export default connect(mapStateToProps)(PromotionsContainer);
