// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';

import MyProfileCoverView from './MyProfileCoverView';

type Props = {
    updateCoverPhoto: typeof actions.updateCoverPhoto,
    loadMyAccounts: typeof actions.loadMyAccounts
};

class MyProfileCoverPhotoContainer extends Component<Props> {
    state = {
        isUploadingPicture: false
    };

    onSubmitCoverPhoto = (coverPhotoUrl) => {
        this.setState({ isUploadingPicture: true });

        const { updateCoverPhoto, loadMyAccounts } = this.props;
        updateCoverPhoto({ coverPhotos: [{ data: coverPhotoUrl }] })
            .then(() => loadMyAccounts({ fields: ['cover_photos'] }))
            .then(() => this.setState({ isUploadingPicture: false }))
            .catch((error) => {
                console.error(error);
                this.setState({ isUploadingPicture: false });
            });
    };

    render() {
        const { user } = this.props;
        const { coverPhotos } = user;
        return <MyProfileCoverView onSubmitCoverPhoto={this.onSubmitCoverPhoto} coverPhotos={coverPhotos} isUploadingPicture={this.state.isUploadingPicture} />;
    }
}

const mapStateToProps = (state) => ({
    user: getUser(state, state.userData.user).entity
});

const mapDispatchToProps = (dispatch) => ({
    updateCoverPhoto: wrapAPIDispatch(dispatch, actions.updateCoverPhoto),
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyProfileCoverPhotoContainer);
