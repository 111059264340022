// @flow
import React from 'react';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import AddressModal from '../AddressModal';

type Props = {
    onSubmit: () => void
};

function AddressAddModalView({ onSubmit, ...rest }: Props) {
    return (
        <AddressModal
            title={<Trans i18nKey='address.add.title'>Add Address</Trans>}
            acceptButtonLabel={[
                <Trans key='trans' i18nKey='address.add.acceptButton'>
                    Add Address
                </Trans>,
                <Icon key='icon' name='check' />
            ]}
            onSubmit={onSubmit}
            {...rest}
        />
    );
}

export default compose(withTranslation('translations'))(AddressAddModalView);
