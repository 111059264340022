import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { closeModal as closeModalAction } from 'store/actions';

class SendFeedbacknModal extends PureComponent {
    handleClose = () => {
        const { name, closeModal } = this.props;
        closeModal(name);
    };

    render() {
        const { isOpen } = this.props;
        return (
            <Modal size='tiny' closeIcon open={isOpen} onClose={this.handleClose}>
                <Modal.Header>
                    <Trans i18nKey='sendFeedbackModal.header'>Send Feedback</Trans>
                </Modal.Header>
                <Modal.Content>Send Feedback</Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state, { name }) => ({
    isOpen: (state.ui.modals[name] || {}).isOpen
});

const mapDispatchToProps = {
    routePush: push,
    closeModal: closeModalAction
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(SendFeedbacknModal);
