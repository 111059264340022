// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { wrapAPIDispatch, Authentication } from 'services/api';
import * as actions from 'store/actions';

import { getOrder } from 'store/utilities';

import OrderDetailsView from './OrderDetailsView';

type Props = {
    loadOrderById: typeof actions.loadOrderById
};

class OrderDetailsContainer extends Component<Props> {
    state = {
        isLoadingOrder: false
    };

    componentDidMount() {
        const { order, match } = this.props;
        const { orderId } = match.params;

        const activeProfile = localStorage.getItem('activeProfile');

        if (orderId && !order.entity && !this.state.isLoadingOrder) {
            this.setState({ isLoadingOrder: true });

            const authentication = activeProfile === 'Merchant'
            ? Authentication.Merchant
            : Authentication.User;

            this.props
                .loadOrderById({ orderId, authentication: authentication })
                .then(() => this.setState({ isLoadingOrder: false }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingOrder: false });
                });
        }
    }

    render() {
        const { order } = this.props;

        if (order.entity) {
            return <OrderDetailsView {...this.props} />;
        }
        return <Loader active />;
    }
}

const mapStateToProps = (state, { match }) => {
    const { orderId } = match.params;
    const order = getOrder(state, orderId);

    return {
        order
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadOrderById: wrapAPIDispatch(dispatch, actions.loadOrderById)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(OrderDetailsContainer);
