// @flow
import React from 'react';
import { Segment, Grid, Header, Button, Divider, Icon, List } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import * as actions from 'store/actions';

import { formatPrice } from 'utilities/format';

import DeliveryAddress from 'components/DeliveryAddress';

type Props = {
    nextStep: Function,
    shippingFee: number,
    vat: number,
    cart: {
        grandTotalPrice: number
    },
    openModal: Function
};

function CheckoutDetailsDelivery({ subtotal, itemCount, shippingFee, vat, nextStep, cart, isNonTempUser, openModal }: Props) {
    const { grandTotalPrice, error, cartId } = cart;
    const isErrorRelevant = error && error.forStep <= 2;
    const click = () => openModal(actions.ModalNames.LOGIN, { action: 'switchCart', cartId });

    return (
        <Segment className='checkout-details-delivery semi-simple'>
            <Grid className='tensed'>
                <Grid.Row>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(subtotal)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.subTotal' count={itemCount}>
                                        Subtotal ({{ count: itemCount }} items)
                                    </Trans>
                                    :
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(shippingFee)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.payment.shippingFee'>Shipping Fee</Trans>:
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(vat)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.vat'>VAT</Trans>:
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h2'>
                            <Trans i18nKey='checkout.details.delivery.title'>Delivery Address</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <DeliveryAddress updateCartAddress showCartSelector />
                    </Grid.Column>
                </Grid.Row>
                {!isNonTempUser && (
                    <Grid.Row>
                        <Grid.Column textAlign='right'>
                            <p>
                                <Trans i18nKey='logIn.alreadyUser'>Already a user?</Trans>{' '}
                                <a onClick={click} style={{ cursor: 'pointer' }}>
                                    <Trans i18nKey='logIn.logIn'>Please log in.</Trans>
                                </a>
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='auto tensed'>
                            <Grid.Column>
                                <Header size='medium'>
                                    <Trans i18nKey='checkout.details.delivery.total'>Total</Trans>:
                                </Header>
                            </Grid.Column>
                            <Grid.Column className='col-text-align-right'>
                                <Header size='medium'>{formatPrice(grandTotalPrice)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button primary fluid icon disabled={isErrorRelevant} onClick={nextStep}>
                            <Trans i18nKey='checkout.details.delivery.button'>Continue</Trans> <Icon name='chevron right' />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default withTranslation('translations')(CheckoutDetailsDelivery);
