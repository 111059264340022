// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_STORE_MESSAGES ------------------
export type LoadMyStoreMessagesParams = {
    limit: number,
    offset: number,
    conversationKey: string,
    typeId: number
};

export const LOAD_MY_STORE_MESSAGES = 'LOAD_MY_STORE_MESSAGES';
export const loadMyStoreMessages = wrapAPIActionCreator(({ limit = 999, offset = 0, conversationKey, typeId }: LoadMyStoreMessagesParams) => ({
    [API_DATA]: {
        type: LOAD_MY_STORE_MESSAGES,
        endpoint: `/v3/messages/${conversationKey}`,
        endpointParams: { typeId, limit, offset },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: [Schemas.message],
        reducerParams: {
            limit,
            offset,
            conversationKey,
            typeId
        }
    }
}));
