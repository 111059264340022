// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { updateProfile, setLocale } from 'store/actions';
import { wrapAPIDispatch } from 'services/api';
import { Modal, Dropdown, List } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { history } from 'store';
import { DEFAULT_LANGUAGE, changeLanguage, currencyOptions } from 'utilities/languages';
import * as actions from 'store/actions';
import { getUser } from 'store/utilities';
class LanguageSelectModalContainer extends Component {
    constructor() {
        super();
        this.state = {
            language: DEFAULT_LANGUAGE
        };
    }

    handleClose = () => {
        const { name, closeModal } = this.props;
        changeLanguage(this.state.language);
        closeModal(name);
    };
    handleOnChange = (event, language) => {
        event.preventDefault();
        this.setState({ language: language.value });
    };
    listenToHistory = history.listen(() => {
    });
    render() {
        const { isOpen, languages } = this.props;
        return (
            <Modal style={{ top: 167 }} size='mini' dimmer='inverted' centered={false} closeOnDimmerClick={false} open={isOpen} onClose={this.handleClose}>
                <div style={{ padding: 20 }}>
                    <p style={{ fontSize: 13, fontWeight: 'bold' }}>
                        <Trans i18nKey='language.button.language'>Language</Trans>
                    </p>
                    <List.Item>
                        <Dropdown fluid selection options={languages} onChange={this.handleOnChange} defaultValue='en' placeholder='Select Language' />
                    </List.Item>
                    <p style={{ fontSize: 13, paddingTop: 20, fontWeight: 'bold' }}>
                        <Trans i18nKey='language.button.currency'>Currency</Trans>
                    </p>
                    <List.Item>
                        <Dropdown fluid selection options={currencyOptions} defaultValue='MYR' placeholder='Select Currency' />
                    </List.Item>
                    <div style={{ paddingTop: 20 }}>
                        <span>
                            <button className='ui secondary button' basic onClick={this.handleClose}>
                                <Trans i18nKey='language.button.save'>Save</Trans>
                            </button>
                        </span>
                        <span style={{ paddingLeft: 10 }}>
                            <button className='ui button' basic onClick={this.handleClose}>
                                <Trans i18nKey='language.button.cancel'>Cancel</Trans>
                            </button>
                        </span>
                    </div>
                </div>
            </Modal>
        );
    }
}
const mapStateToProps = (state, { name }) => {
    const user = getUser(state, state.userData.user);
    return {
        user,
        isOpen: (state.ui.modals[name] || {}).isOpen
    };
};
const mapDispatchToProps = (dispatch) => ({
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    updateProfile: wrapAPIDispatch(dispatch, updateProfile),
    setLocale: wrapAPIDispatch(dispatch, setLocale)
});
export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(LanguageSelectModalContainer);
