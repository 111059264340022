// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getCountry, getState, getCity } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import AddressModalView from './AddressModalView';

import type { FormValues } from './index';

type Props = {
    name: string,
    onDelete: ({ addressId: number }) => ?Promise<*>,
    onSubmit: (FormValues) => ?Promise<*>,
    // from store
    closeModal: typeof actions.closeModal,
    loadCountries: typeof actions.loadCountries,
    loadStatesAndCities: typeof actions.loadStatesAndCities,
    countries: number[],
    states: {}
};

class AddressModalContainer extends Component<Props> {
    static defaultProps;

    componentDidMount() {
        this.props.loadCountries();
    }

    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    handleCountryChange = ({ value }) => {
        const addressStates = this.props.addressStates[value];
        if (!addressStates || !addressStates.list) {
            if (!addressStates || !addressStates.isLoading) {
                this.props.loadStatesAndCities({ countryId: value });
            }
        }
    };

    handleDelete = (event, data) => {
        const { closeModal, name, onDelete } = this.props;
        const res = onDelete(data);
        if (res) {
            res.then(() => closeModal(name));
        } else {
            closeModal(name);
        }
        return res;
    };

    handleSubmit = (event, data) => {
        const { closeModal, name, onSubmit } = this.props;
        const res = onSubmit(data);
        if (res) {
            res.then(() => closeModal(name));
        } else {
            closeModal(name);
        }
        return res;
    };

    render() {
        const { name, onSubmit, closeModal, ...rest } = this.props;
        return <AddressModalView {...rest} onClose={this.handleClose} onDelete={this.handleDelete} onSubmit={this.handleSubmit} onCountryChange={this.handleCountryChange} />;
    }
}

AddressModalContainer.defaultProps = {
    onSubmit: () => {}
};

const mapStateToProps = (state, { name }) => {
    const newProps = {
        countries: state.address.enabledCountries,
        addressStates: state.address.addressStates,
        getCountry: (id) => getCountry(state, id),
        getState: (id) => getState(state, id),
        getCity: (id) => getCity(state, id)
    };

    const modalData = state.ui.modals[name] || {};
    if (!modalData.isOpen) {
        return {
            ...newProps,
            isOpen: false
        };
    }

    // Props passed to openModal action
    const { props: extraProps } = modalData;

    // const { addressId } = extraProps;
    // const address = getUserDeliveryAddress(state, addressId);

    return {
        ...newProps,
        ...extraProps,
        isOpen: true
        // address,
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    loadCountries: wrapAPIDispatch(dispatch, actions.loadCountries),
    loadStatesAndCities: wrapAPIDispatch(dispatch, actions.loadStatesAndCities)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddressModalContainer);
