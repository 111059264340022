// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Container, Header, Loader, Segment } from 'semantic-ui-react';

import ProductTeaser from 'components/ProductTeaser';
import SortablePaginatedGrid from 'components/SortablePaginatedGrid';

import type { EntityData, LandingComponent } from 'store/types';

type Props = {
    categoryId: number,
    component: EntityData<LandingComponent>,
    sort: string,
    loadMoreItems: ({ offset: number, limit: number }) => Promise<number[]>
};

const renderItem = (id) => <ProductTeaser itemId={id} />;
const keyExtractor = (id) => id;

function LandingItemListView({ component, loadMoreItems, sort, categoryId }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }

    const { title } = component.entity;

    return (
        <Segment className='teaser-carousel' style={{ border: 'none', boxShadow: 'none' }}>
            <SortablePaginatedGrid
                title={
                    <Header as='h2' style={{ fontSize: '1.2857rem' }}>
                        {title}
                    </Header>
                }
                loadMoreItems={loadMoreItems}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                id={`${categoryId}-${sort}`}
            />
        </Segment>
    );
}

export default LandingItemListView;
