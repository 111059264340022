import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPurchases } from '../../../../store/actions';
import { Container, Grid, Header, Label, Menu, Segment, Tab, Visibility } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDatePretty, formatPrice } from '../../../../utilities/format';
import { element } from 'prop-types';
import { useTranslation } from 'react-i18next';

export default function Purchases() {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const items = useSelector((state) => state.userData.purchases);

    const { open = [], openCount = 0, delivered = [], deliveredCount = 0, cancelled = [], cancelledCount = 0 } = items;
    const descending = (a, b) => b - a;
    const tabs = [
        {
            key: 'all',
            title: 'All',
            count: openCount + deliveredCount + cancelledCount,
            orders: [...open, ...delivered, ...cancelled].slice().sort(descending)
        },
        {
            key: 'open',
            title: 'Open',
            count: openCount,
            orders: open.slice().sort(descending)
        },
        {
            key: 'delivered',
            title: 'Closed',
            count: deliveredCount,
            orders: delivered.slice().sort(descending)
        },
        {
            key: 'cancelled',
            title: 'Cancelled',
            count: cancelledCount,
            orders: cancelled.slice().sort(descending)
        }
    ];

    const Columns = [
        {
            key: 'store',
            title: 'Prodavnica'
        },
        {
            key: 'status',
            title: 'Status'
        },
        {
            key: 'date',
            title: 'Datum'
        },
        {
            key: 'count',
            title: 'Broj Artikala'
        },
        {
            key: 'delivery',
            title: 'Dostava'
        },
        {
            key: 'saldo',
            title: 'Ukupno'
        }
    ];

    function loadOrders() {
        dispatch(
            loadPurchases(
                { limit: 10, offset: cancelled.length + open.length + delivered.length },
                () => {},
                (e) => console.log(e)
            )
        );
    }

    useEffect(() => {
        loadOrders();
    }, []);

    function renderValue(el, index) {
        switch (index) {
            case 0:
                return el.orderStatusTitle;
            case 1:
                return formatDatePretty(new Date(el.orderDate), t);
            case 2:
                return el.sTORECARTITEMS;
            case 3:
                return formatPrice(el.storeShipment);
            case 4:
                return formatPrice(el.storeTotal + el.storeShipment - el.storeDiscount);
            default:
                return '/';
        }
    }

    return (
        <Container className='my-profile-purchases'>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h1'>
                            <Trans i18nKey='myProfile.purchases.header'>My Purchases</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Tab
                            menu={{ secondary: true, pointing: true }}
                            panes={tabs.map((tab) => ({
                                menuItem: (
                                    <Menu.Item key={tab.key}>
                                        <Trans i18nKey={`myProfile.purchases.tab.${tab.key}`}>{tab.title}</Trans>
                                        <Label>{tab.count}</Label>
                                    </Menu.Item>
                                ),
                                render: () => (
                                    <Tab.Pane attached={false} className='simple'>
                                        {/*<Visibility continuous fireOnMount onBottomVisible={loadMoreOrders}>*/}
                                        <Visibility continuous fireOnMount onBottomVisible={() => loadOrders()}>
                                            <div>
                                                <Segment.Group className='simple'>
                                                    <div className='table-header custom-table-header' style={{ display: 'flex' }}>
                                                        {Columns &&
                                                            Columns.map((c, index) => (
                                                                <div
                                                                    key={c.key}
                                                                    style={{
                                                                        width: `${100 / Columns.length}%`,
                                                                        margin: 'auto',
                                                                        whiteSpace: 'nowrap',
                                                                        textAlign: 'center'
                                                                    }}
                                                                >
                                                                    <Trans i18nKey={`myStore.orders.ordersListCol.${c.key}`}>{c.title}</Trans>
                                                                </div>
                                                            ))}
                                                    </div>
                                                    {tab.orders.map((el) => (
                                                        <>
                                                            <div className={'only-desktop'} key={el.id}>
                                                                <Link to={`/my-profile/purchases/order/${el.cartId}/${el.storeId}`} className={`my-store-product-list-item`}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{el.storeName}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{el.orderStatusTitle}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{formatDatePretty(new Date(el.orderDate), t)}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{el.sTORECARTITEMS}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{formatPrice(el.storeShipment)}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order-custom'>
                                                                            <span>{formatPrice(el.storeTotal + el.storeShipment - el.storeDiscount)}</span>
                                                                        </Grid.Column>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div key={el.id} className={'only-mobile'}>
                                                                <Link to={`/my-profile/purchases/order/${el.cartId}/${el.storeId}`} className={`my-store-product-list-item`}>
                                                                    <div style={{ marginBottom: '10px' }} className='ui card'>
                                                                        <div style={{ backgroundColor: '#f7f7f7' }} className='content'>
                                                                            <div className='header'>{el.storeName}</div>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <div className='description'>
                                                                                {Columns.slice(1).map((column, index) => (
                                                                                    <div key={column.key} className='card-item'>
                                                                                        <div style={{ fontWeight: '700' }}>
                                                                                            <Trans i18nKey={`myStore.orders.ordersListCol.${column.key}`}>{column.title}</Trans>
                                                                                        </div>
                                                                                        <div>{renderValue(el, index)}</div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className='extra content'>
                                                                            <div className='ui bottom attached button'>
                                                                                {' '}
                                                                                <Trans i18nKey='myStore.orders.showOrder'>Pogledaj narudžbu</Trans>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    ))}
                                                </Segment.Group>
                                            </div>
                                        </Visibility>
                                    </Tab.Pane>
                                )
                            }))}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}
