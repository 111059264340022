// @flow
import React from 'react';
import { Form, Header, Button, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';
import { ItemWeightTypes } from 'store/actions';

import ShippingTo from './components/ShippingTo';
import { Link } from 'react-router-dom';

type Props = {
    formId: string
};

type State = {
    errorMessage: ?string,
    formValues: {
        weight: FormFieldData<number>,
        weightType: FormFieldData<string>,
        shippingFrom: FormFieldData<number>
    }
};

const defaultValuesFromItem = ({ item }) => {
    const { weight, weightType, storeId } = item;
    return {
        weight: createEmptyFormFieldData({ value: weight, type: 'number', required: true }),
        weightType: createEmptyFormFieldData({ value: weightType, type: 'string' }),
        shippingFrom: createEmptyFormFieldData({ value: storeId, required: true })
    };
};

class ItemFormDeliveryView extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { item, updateFormValues, formId } = props;

        let formValues = {};

        if (item) {
            formValues = defaultValuesFromItem({ item });
        } else {
            formValues = {
                weight: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
                weightType: createEmptyFormFieldData({ value: ItemWeightTypes.Grams, type: 'string' }),
                shippingFrom: createEmptyFormFieldData({ value: null, required: true })
            };
        }
        this.state = {
            formValues
        };
        updateFormValues({ formValues, formId });
    }

    render() {
        const { stores } = this.props;
        const { formValues } = this.state;
        const storeOptions = stores.map(({ entity }) => {
            const { mailingAddress } = entity;
            const { houseNo, street, zip, city, state } = mailingAddress;
            const text = `${houseNo}${street ? `, ${street}` : ''}, ${zip}, ${city}, ${state}`;
            return {
                value: entity.id,
                text
            };
        });
        const {
            shippingFrom: { value: shippingFrom },
            weight: { value: weight },
            weightType: { value: weightType }
        } = formValues;

        const shippingProps = {
            shippingFrom,
            weight,
            weightType,
            onMissingValue: () => this.validate()
        };
        return (
            <Form.Field>
                {/*<Form.Field>*/}
                {/*<Header as='h2'>*/}
                {/*<Trans i18nKey='sellItem.form.delivery.header'>Delivery</Trans>*/}
                {/*</Header>*/}
                {/*</Form.Field>*/}
                <Form.Field>
                    <Form.Group unstackable>
                        {this.renderInputField({ name: 'weight', type: 'number', step: '0.01', width: 6 }, this.props)}

                        {this.renderDropdownField(
                            {
                                name: 'weightType',
                                type: 'number',
                                width: 2,
                                options: [
                                    { text: 'g', value: ItemWeightTypes.Grams },
                                    { text: 'kg', value: ItemWeightTypes.Kilograms }
                                ]
                            },
                            this.props
                        )}
                    </Form.Group>
                </Form.Field>
                {storeOptions.length === 0 ? (
                    <Message visible error>
                        Kako bi mogli objaviti artikal,
                        <Link to={'/store-settings'}> dodajte adresu.</Link>
                    </Message>
                ) : (
                    this.renderDropdownField({ name: 'shippingFrom', options: storeOptions }, this.props)
                )}
                <ShippingTo {...shippingProps} {...this.props} />
                {/*<Button> Add Delivery Options </Button>*/}
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ItemFormDeliveryView);
