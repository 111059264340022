// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { Container } from 'semantic-ui-react';

import ResetEmailSent from 'components/ResetEmailSent';
import { withTranslation } from 'react-i18next';

type Props = {
    goBackToLogin: () => void
};

class ForgotPasswordScene extends Component<Props> {
    render() {
        return (
            <Container text className='narrow'>
                <ResetEmailSent />
            </Container>
        );
    }
}

export default compose(connect(null, { navigateTo: push }), withTranslation('translations'))(ForgotPasswordScene);
