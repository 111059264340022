// @flow
import Container from './CheckoutContainer';

export const CheckoutStep = {
    DELIVERY: 1,
    PAYMENT: 2,
    REVIEW: 3
};

export const PaymentMethod = {
    CreditCard: 1, 
    CashOnDelivery: 2
};

export { default as Container } from './CheckoutContainer';
export { default as View } from './CheckoutView';

export default Container;
