// @flow
import { uniq } from 'lodash';

import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    cancelled: [],
    cancelledCount: 0,
    delivered: [],
    deliveredCount: 0,
    open: [],
    openCount: 0
};

function updateFromLoadPurchases(state, action) {
    const { result } = action.payload;

    const { offset } = action.reducerParams;

    const {
        cancalledOrderCount: cancelledCount,
        cancalledOrder: cancelled,
        deliveredOrderCount: deliveredCount,
        deliveredOrder: delivered,
        openOrderCount: openCount,
        openOrder: open
    } = result.items;

    if (offset > 0) {
        return {
            ...state,
            cancelledCount: cancelledCount,
            cancelled: (state.cancelled || []).concat(cancelled || []),
            deliveredCount: deliveredCount,
            delivered: (state.delivered || []).concat(delivered || []),
            openCount: openCount,
            open: (state.open || []).concat(open || []),
            isLoading: false
        };
    }
    return {
        ...state,
        cancelledCount,
        cancelled,
        deliveredCount,
        delivered,
        openCount,
        open,
        isLoading: false
    };
}

/** Update order status based on Purchases entity changes */
const statusToCollection = {
    ORDER_CREATED: 'open',
    SHIP_TO_DC: 'open',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled'
};

function purchases(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_PURCHASES:
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            if (action.status === 'success') {
                return updateFromLoadPurchases(state, action);
            }
            break;
        // Reset orders store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default purchases;
