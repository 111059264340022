// @flow
import React from 'react';

import type { Item, Offer } from 'store/types';

import { formatPrice } from 'utilities/format';
import DealLabel from '../DealLabel';

type Props = {
    item: Item,
    multiline?: boolean,
    offer: ?Offer
};

/** Shows the price for a product, taking care of products on sales automatically */
function Price({ item, multiline, offer }: Props) {
    const { price, isDeal, dealTypes } = item;
    const isSale = isDeal && dealTypes && dealTypes.sale != null;
    let dealPrice = isSale ? dealTypes.sale : null;
    if (offer) {
        dealPrice = Number(offer.offerPrice);
    }

    return (
        <div className={`price${multiline ? ' price-multiline' : ''}`}>
            {dealPrice != null && <span className='price-deal-value'>{formatPrice(dealPrice)}</span>}
            <span className='price-value'>{formatPrice(price)}</span>
            <span className='percentage-value'>
                <DealLabel item={item} />
            </span>
        </div>
    );
}

Price.defaultProps = {
    multiline: false
};

export default Price;
