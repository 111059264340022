// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyStoreCampaignsTab from './components/MyStoreCampaignsTab';

const MyStoreCampaignsView = (props) => (
    <Container>
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h1'>
                        <Trans i18nKey='myStore.campaign.header'>My Store Campaigns</Trans>
                    </Header>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <MyStoreCampaignsTab {...props} />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Container>
);

export default withTranslation('translations')(MyStoreCampaignsView);
