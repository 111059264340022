// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadPurchasesParams = {
    limit: number,
    offset: number,
    cartId: string,
    storeId: string,

};

// LOAD_PURCHASES ----------------------
export const LOAD_PURCHASES_BY_STORE = 'LOAD_PURCHASES_BY_STORE';
export const loadPurchasesByStore = wrapAPIActionCreator(({ limit = 10, offset = 0, cartId, storeId }: LoadPurchasesParams) => ({
    [API_DATA]: {
        type: LOAD_PURCHASES_BY_STORE,
        endpoint: `/v3/web/tabs/orders/carts/${cartId}/stores/${storeId}`,
        endpointParams: { limit, offset },
        authentication: Authentication.User,
        method: 'GET',
        schema: {
            items: {}
        },
        reducerParams: { limit, offset }
    }
}));
