// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as actions from 'store/actions';

import SellItemFormPriceView from './SellItemFormPriceView';

type Props = {};

class SellItemFormItemContainer extends Component<Props> {
    render() {
        return <SellItemFormPriceView {...this.props} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params))
});

export default connect(null, mapDispatchToProps)(SellItemFormItemContainer);
