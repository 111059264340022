// @flow
import React, { Component } from 'react';

import ProductDetailListItemDetail from 'components/ProductDetailList/components/ProductDetailListItemDetail';
import { withTranslation } from 'react-i18next';

class OrderDetailsView extends Component {
    render() {
        const { order, type } = this.props;
        return <ProductDetailListItemDetail type={type} id={order.entity.id} detailsActive />;
    }
}

export default withTranslation('translations')(OrderDetailsView);
