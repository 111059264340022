// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type RegisterShipmentParams = {
    orderId: number,
    carrier: string
};

export const REGISTER_SHIPMENT = 'REGISTER_SHIPMENT';
export const registerShipment = wrapAPIActionCreator(({ orderId, carrier }: RegisterShipmentParams) => ({
    [API_DATA]: {
        type: REGISTER_SHIPMENT,
        endpoint: `/v4/shipping/create`,
        method: 'POST',
        authentication: Authentication.Merchant,
        body: {
            orderId,
            carrier
        },
        schema: {
            order: Schemas.order
        }
    }
}));

export default registerShipment;
