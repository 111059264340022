// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_CONVERSATIONS ------------------
export type LoadMyConversationsParams = {
    limit: number,
    offset: number,
    withUser?: number
};

export const LOAD_MY_CONVERSATIONS = 'LOAD_MY_CONVERSATIONS';
export const loadMyConversations = wrapAPIActionCreator(({ limit = 10, offset = 0, withUser }: LoadMyConversationsParams) => ({
    [API_DATA]: {
        type: LOAD_MY_CONVERSATIONS,
        endpoint: '/v3/conversations',
        endpointParams: { limit, offset, withUser },
        reducerParams: { limit, offset, withUser },
        authentication: Authentication.User,
        method: 'GET',
        schema: {
            conversations: [Schemas.conversation]
        }
    }
}));
