// @flow
import * as ActionTypes from '../../actions';

const initialState = [];

// Populates the root categories based on category-loading actions
export default function rootCategories(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_CATEGORY_TREE:
            if (action.status === 'success') {
                return action.payload.result;
            }
            break;
        default:
        // do nothing
    }
    return state;
}
