// @flow
import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';

import { routerMiddleware } from 'connected-react-router';

import { persistStore } from 'redux-persist';
import Raven from 'raven-js';
import createRavenMiddleware from 'raven-for-redux';
import { apiMiddleware } from '../services/api';
import rootReducer from './reducers';
import onLogin from '../common/onLogin';
import { getCookie } from '../utilities/cookies';

export const history = createBrowserHistory();

// Enable devtools if available
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// eslint-disable-next-line
const SENTRY = (process.env.REACT_APP_SENTRY || '').replace(/\s+/, '');
const hasSentry = !['', '0', 'false'].includes(SENTRY);
if (hasSentry) {
    const SENTRY_URL = (process.env.REACT_APP_SENTRY_URL || '').replace(/\/$/, '');
    const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV || 'production';
    Raven.config(SENTRY_URL, { environment: SENTRY_ENV }).install();
}

const noopMiddleware = () => (next) => (action) => next(action);

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(hasSentry ? createRavenMiddleware(Raven, {}) : noopMiddleware, routerMiddleware(history), apiMiddleware)));

function onRehydration() {
    const { accessToken } = store.getState().userData;
    if (accessToken != null || getCookie('accessToken')) {
        onLogin();
    }
}

export const persistor = persistStore(store, null, onRehydration);

export default store;
