// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_SHOPPER_STORE_CONVERSATIONS ------------------
export type LoadShopperStoreConversationsParams = {
    limit: number,
    offset: number,
    itemId: number,
    merchantId: number,
    myId: number
};

export const LOAD_SHOPPER_STORE_CONVERSATIONS = 'LOAD_SHOPPER_STORE_CONVERSATIONS';
export const loadShopperStoreConversations = wrapAPIActionCreator(({ limit = 10, offset = 0, itemId, merchantId, myId }: LoadShopperStoreConversationsParams) => {
    const orderedUsers = [myId, merchantId]
        .map(Number)
        .sort((a, b) => a - b)
        .join('-');
    return {
        [API_DATA]: {
            type: LOAD_SHOPPER_STORE_CONVERSATIONS,
            endpoint: `/v3/conversation/${itemId}-${orderedUsers}?showSoldOutToo=true&showClosedToCustomersToo=true&limit=${limit}&offset=${offset}`,
            authentication: Authentication.User,
            reducerParams: {
                itemId,
                limit,
                offset,
                merchantId,
                myId
            },
            method: 'GET',
            schema: {
                conversations: Schemas.conversation
            }
        }
    };
});
