// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getMessage, getItem } from 'store/utilities';

import MessageListView from './MessageListView';

type Props = {};

class MessageListContainer extends Component<Props> {
    state = {
        isLoadingMore: false
    };

    componentDidMount() {
        this.onNewProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.onNewProps(props);
    }

    onNewProps = (props) => {
        const { conversation, messages, onLoadMoreMessages = () => {}, isLoadingMessages, hasMore } = props;
        if (hasMore && !isLoadingMessages && messages.length < 20) {
            onLoadMoreMessages({ conversation, offset: messages.length });
        }
    };

    handleLoadMoreMessages = () => {
        const { conversation, messages } = this.props;
        this.props.onLoadMoreMessages({ conversation, offset: messages.length });
    };

    render() {
        const { conversation, negotiationBox, messages, me, isLoadingMessages, item, user, hasMore } = this.props;
        return (
            <MessageListView
                conversation={conversation}
                messages={messages}
                me={me}
                isLoadingMessages={isLoadingMessages}
                hasMore={hasMore}
                item={item}
                user={user}
                negotiationBox={negotiationBox}
                onClickLoadMoreMessages={this.handleLoadMoreMessages}
            />
        );
    }
}

const mapStateToProps = (state, { conversation }) => {
    const { conversationKey } = conversation.entity;
    const messagesState = state.messages[conversationKey] || { isLoading: false, hasMore: false, list: [] };
    const { list: messages, isLoading: isLoadingMessages, hasMore } = messagesState;
    return {
        messages: messages.map((id) => getMessage(state, id)),
        isLoadingMessages,
        item: getItem(state, conversation.entity.item),
        hasMore
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadMyStoreMessages: wrapAPIDispatch(dispatch, actions.loadMyStoreMessages)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MessageListContainer);
