import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getEntityDataBySlug } from 'store/utilities';
import type { EntityData, Item } from 'store/types';

import PageNotFound from 'scenes/PageNotFound';
import ProductView from './ProductView';

type Props = {
    storeSlug: string,
    itemSlug: string,
    product: EntityData<Item>,
    hasMore: boolean,
    isLoadingItems: boolean,
    otherStoreItems: number[],
    loadItemsByUser: typeof actions.loadItemsByUser,
    searchProductByItemSlugAndStoreSlug: typeof actions.searchProductByItemSlugAndStoreSlug,
    loadItemByItemIdWithUserAuth: typeof actions.loadItemByItemIdWithUserAuth,
    bazar?: string
};

function loadData({ itemSlug, storeSlug, searchProductByItemSlugAndStoreSlug , bazar }: Props) {
    return searchProductByItemSlugAndStoreSlug({ itemSlug, storeSlug , bazar });
}

class StoreContainer extends Component<Props> {
    state = {
        performedFirstLookupRequest: false,
        performedExtraFieldsRequest: false,
        isLookingUpExtraFields: false
    };

    componentDidMount() {
        const { product } = this.props;

        if (!product || !product.merchant) {
            loadData(this.props)
                .then(() => this.setState({ performedFirstLookupRequest: true }))
                .catch(() => this.setState({ performedFirstLookupRequest: true }));
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { product, loadItemByItemIdWithUserAuth, itemSlug } = nextProps;
        const { performedExtraFieldsRequest, isLookingUpExtraFields } = this.state;

        if (itemSlug !== this.props.itemSlug) {
            this.setState({ performedFirstLookupRequest: false, performedExtraFieldsRequest: false }, () => {
                loadData(nextProps)
                    .then(() => this.setState({ performedFirstLookupRequest: true }))
                    .catch(() => this.setState({ performedFirstLookupRequest: true }));
            });
        }

        if (product && !product.merchant && !isLookingUpExtraFields && !performedExtraFieldsRequest) {
            this.setState({ isLookingUpExtraFields: true }, () => {
                loadItemByItemIdWithUserAuth({ itemId: product.id, fields: ['pictures', 'comments', 'merchant', 'store', 'is_liked'] })
                    .then(() => this.setState({ performedExtraFieldsRequest: true, isLookingUpExtraFields: false }))
                    .catch(() => this.setState({ performedExtraFieldsRequest: true, isLookingUpExtraFields: false }));
            });
        }
    }

    loadMoreItems(calculations = { bottomVisible: true }) {
        const { otherStoreItems, hasMore, isLoadingItems, merchantId } = this.props;

        if (isLoadingItems) {
            return;
        }
        let offset = 0;
        if (otherStoreItems) {
            offset = otherStoreItems.length;
        }
        if (calculations.bottomVisible && hasMore) {
            this.props
                .loadItemsByUser({
                    userId: merchantId,
                    offset,
                    fields: ['pictures', 'entityData', 'store', 'is_liked', 'comments']
                })
                .catch((err) => console.error(err));
        }
    }

    render() {
        if (this.state.performedFirstLookupRequest && !this.props.product) {
            return <PageNotFound />;
        }
        return (
            <ProductView
                {...this.props}
                productNotFound={this.state.performedFirstLookupRequest && !this.props.product}
                loadMoreItems={(calculations) => this.loadMoreItems(calculations)}
            />
        );
    }
}

const mapStateToProps = (state, { match }) => {
    let bazar = '';
    if (state.router.location.query.bazar) {
        bazar = state.router.location.query.bazar;
    }
    const { storeSlug, itemSlug } = match.params;
    const { isFetching, entity: product } = getEntityDataBySlug(state, `${storeSlug}/${itemSlug}`);
    const merchantId = product?.userId;
    const merchantData = state.merchants[merchantId] || {};
    const itemMerchantState = merchantData.latest || { isLoading: false, hasMore: true };
    const { list = [], isLoading: isLoadingItems, hasMore } = itemMerchantState;
    const otherStoreItems = list;

    return { isFetching, product, storeSlug, itemSlug, otherStoreItems, isLoadingItems, hasMore, merchantId , bazar };
};

const mapDispatchToProps = (dispatch) => ({
    searchProductByItemSlugAndStoreSlug: wrapAPIDispatch(dispatch, actions.searchProductByItemSlugAndStoreSlug),
    loadItemByItemIdWithUserAuth: wrapAPIDispatch(dispatch, actions.loadItemByItemIdWithUserAuth),
    loadItemsByUser: wrapAPIDispatch(dispatch, actions.loadItemsByUser)
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreContainer);
