// @flow
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Tab, Menu, Label, Visibility, Loader } from 'semantic-ui-react';

import ProductDetailList from 'components/ProductDetailList';

type Props = {
    orders: {
        open: array,
        openCount: number,
        delivered: array,
        deliveredCount: number,
        cancelled: array,
        cancelledCount: number,
        isLoading: boolean
    }
};

class MyStoreOrdersTab extends Component<Props> {
    state = {
        redirectTo: null
    };

    onTabChange = (event, { activeIndex, defaultActiveIndex }) => {
        if (activeIndex !== defaultActiveIndex) {
            let redirectTo = '/my-store/orders';
            switch (activeIndex) {
                case 0:
                    redirectTo = '/my-store/orders';
                    break;
                case 1:
                    redirectTo = '/my-store/orders/open';
                    break;
                case 2:
                    redirectTo = '/my-store/orders/delivered';
                    break;
                case 3:
                    redirectTo = '/my-store/orders/cancelled';
                    break;
                default:
                    redirectTo = '/my-store/orders';
            }
            this.setState({ redirectTo });
        }
    };

    render() {
        const { orders, loadMoreOrders, location } = this.props;
        const { redirectTo } = this.state;

        const { open, openCount, delivered, deliveredCount, cancelled, cancelledCount, isLoading } = orders;

        const descending = (a, b) => b - a;
        const tabs = [
            {
                key: 'all',
                title: 'All',
                count: openCount + deliveredCount + cancelledCount,
                orders: [...open, ...delivered, ...cancelled].slice().sort(descending)
            },
            {
                key: 'open',
                title: 'Open',
                count: openCount,
                orders: open.slice().sort(descending)
            },
            {
                key: 'delivered',
                title: 'Delivered',
                count: deliveredCount,
                orders: delivered.slice().sort(descending)
            },
            {
                key: 'cancelled',
                title: 'Cancelled',
                count: cancelledCount,
                orders: cancelled.slice().sort(descending)
            }
        ];

        let defaultActiveIndex = 0;
        switch (location.pathname) {
            case '/my-store/orders/open':
                defaultActiveIndex = 1;
                break;
            case '/my-store/orders/delivered':
                defaultActiveIndex = 2;
                break;
            case '/my-store/orders/cancelled':
                defaultActiveIndex = 3;
                break;
            default:
                break;
        }

        if (redirectTo && redirectTo !== location.pathname) {
            return <Redirect to={redirectTo} />;
        }

        return (
            <Tab
                defaultActiveIndex={defaultActiveIndex}
                menu={{ secondary: true, pointing: true }}
                onTabChange={this.onTabChange}
                ref={(c) => {
                    this.tab = c;
                }}
                panes={tabs.map((tab) => ({
                    menuItem: (
                        <Menu.Item key={tab.key}>
                            <Trans i18nKey={`myStore.orders.tab.${tab.key}`}>{tab.title}</Trans>
                            <Label>{tab.count}</Label>
                        </Menu.Item>
                    ),
                    render: () => (
                        <Tab.Pane attached={false} className='simple'>
                            <Visibility continuous fireOnMount onBottomVisible={loadMoreOrders}>
                                <ProductDetailList type='order' tab={tab.key} idList={tab.orders} />
                                <Loader active={isLoading} />
                            </Visibility>
                        </Tab.Pane>
                    )
                }))}
            />
        );
    }
}

export default withTranslation('translations')(MyStoreOrdersTab);
