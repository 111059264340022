// @flow
import React from 'react';
import { compose } from 'redux';
import { Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import AddressModal from '../AddressModal';

type Props = {
    onDelete: ({ addressId: number }) => void,
    onSubmit: () => void
};

function AddressEditModalView({ onDelete, onSubmit, ...rest }: Props) {
    return (
        <AddressModal
            title={<Trans i18nKey='address.edit.title'>Edit Address</Trans>}
            showCancelButton={false}
            showDeleteButton
            deleteButtonLabel={[
                <Trans key='trans' i18nKey='address.edit.deleteButton'>
                    Delete
                </Trans>,
                <Icon key='icon' name='trash' />
            ]}
            acceptButtonLabel={[
                <Trans key='trans' i18nKey='address.edit.acceptButton'>
                    Save Changes
                </Trans>,
                <Icon key='icon' name='check' />
            ]}
            onDelete={onDelete}
            onSubmit={onSubmit}
            {...rest}
        />
    );
}

export default compose(withTranslation('translations'))(AddressEditModalView);
