// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateProfileParams = {
    email: string,
    firstName?: string,
    lastName?: string,
    alias?: string,
    // username?: string,
    gender?: 0 | 1 | 2,
    description?: string,
    phoneNumber: string,
    fbPublish?: 0 | 1,
    twitterPublish?: 0 | 1,
    userSlug?: string,
    sellingChannel?: number[],
    locations?: Object[],
    mailingAddress?: Object,

    password?: string,
    confirmPassword?: string,

    // TODO
    businessType?: number,
    confirmPassword?: string,
    licenseName?: string,
    licenseUrl?: string,
    skipOldPassword?: boolean,
    website?: string,

    negotiateToggle?: boolean,
    statusStoreToggle?: boolean,
    sendWelcomeEmail?: boolean
};

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const updateProfile = wrapAPIActionCreator(
    ({
        email,
        firstName,
        lastName,
        alias,
        gender,
        description,
        phoneNumber,
        fbPublish,
        twitterPublish,
        userSlug,
        sellingChannel,
        locations,
        mailingAddress,
        password,
        confirmPassword,
        sendWelcomeEmail = false
    }: UpdateProfileParams = {}) => ({
        [API_DATA]: {
            type: UPDATE_PROFILE,
            endpoint: '/v3/me/profile',
            method: 'PUT',
            authentication: Authentication.User,
            body: {
                email,
                firstName,
                lastName,
                alias,
                gender,
                description,
                phoneNumber,
                fbPublish,
                twitterPublish,
                userSlug,
                sellingChannel,
                locations,
                mailingAddress,
                password,
                confirmPassword,
                sendWelcomeEmail
            },
            schema: {
                user: Schemas.user
            }
        }
    })
);

export default updateProfile;
