// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Tab, Menu } from 'semantic-ui-react';

import Messages from 'components/Messages';

function MyStoreMessagesTab(props) {
    const tabs = [
        {
            key: 'messages',
            title: 'Poruke'
        }
    ];
    return (
        <Tab
            menu={{ secondary: true, pointing: true }}
            panes={tabs.map((tab) => ({
                menuItem: (
                    <Menu.Item key={tab.key}>
                        <Trans i18nKey={`myStore.messages.tab.${tab.key}`}>{tab.title}</Trans>
                    </Menu.Item>
                ),
                render: () => (
                    <Tab.Pane attached={false} className='simple'>
                        <Messages {...props} />
                    </Tab.Pane>
                )
            }))}
        />
    );
}

export default withTranslation('translations')(MyStoreMessagesTab);
