// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_AGENT_CONVERSATIONS ------------------
export type LoadMyAgentConversationsParams = {
    limit: number,
    offset: number,
    withUser?: number
};

export const LOAD_MY_AGENT_CONVERSATIONS = 'LOAD_MY_AGENT_CONVERSATIONS';
export const loadMyAgentConversations = wrapAPIActionCreator(({ limit = 10, offset = 0, withUser }: LoadMyAgentConversationsParams) => ({
    [API_DATA]: {
        type: LOAD_MY_AGENT_CONVERSATIONS,
        endpoint: '/v3/conversations',
        endpointParams: { limit, offset, withUser },
        reducerParams: { limit, offset, withUser },
        authentication: Authentication.Agent,
        method: 'GET',
        schema: {
            conversations: [Schemas.conversation]
        }
    }
}));
