// @flow
import React from 'react';
import {} from 'react-i18next';
import { Link } from 'react-router-dom';
import { Grid, Button, Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

function MyStoreActions() {
    return (
        <Grid className='tensed auto'>
            <Grid.Column className='col-shrink'>
                <Button primary as={Link} to='/sell-item'>
                    <Trans i18nKey='myStore.actions.sellItem'>Sell Item</Trans>
                </Button>
            </Grid.Column>
            <Grid.Column className='col-shrink'>
                <Button basic icon labelPosition='left' as={Link} to='/bulk-import'>
                    <Icon name='file image outline' />
                    <Trans i18nKey='myStore.actions.bulkImport'>Bulk Import</Trans>
                </Button>
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(MyStoreActions);
