// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Dropdown } from 'semantic-ui-react';

type Props = {
    options: {
        value: string,
        text: string
    }[],
    value: string,
    onChange: (SyntheticEvent<>, { value: string }) => void
};

function SortDropdownView({ options, value, onChange }: Props) {
    return <Dropdown text='Sortiraj po' scrolling selection {...{ options, value, onChange }} style={{ fontSize: '0.7857rem', color: '#DEDEDF' }} />;
}

export default SortDropdownView;
