// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type DeleteMerchantStoreLocationParams = {
    storeId: number
};

export const DELETE_MERCHANT_STORE_LOCATION = 'DELETE_MERCHANT_STORE_LOCATION';
export const deleteMerchantStoreLocation = wrapAPIActionCreator(({ storeId }: UpdateMerchantStoreLocationParams) => ({
    [API_DATA]: {
        type: DELETE_MERCHANT_STORE_LOCATION,
        endpoint: `/v3/me/stores/${storeId}`,
        authentication: Authentication.Merchant,
        method: 'DELETE',
        preprocess: ({ ...rest }) => ({
            storeId,
            ...rest
        }),
        schema: {}
    }
}));
