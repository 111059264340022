// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal, closeModal } from 'store/actions';
import { ModalNames } from 'store/actions/ui';
import * as actions from 'store/actions';

import LoginView from './LoginView';

type Props = {
    forgotPassword: typeof actions.forgotPassword
};

class LoginContainer extends Component<Props> {
    render() {
        return <LoginView {...this.props} />;
    }
}

const mapDispatchToProps = (dispatch) => ({
    onClickForgotPasswordButton: () => {
        dispatch(closeModal(ModalNames.LOGIN));
        dispatch(openModal(ModalNames.FORGOT_PASSWORD));
    }
});

export default connect(null, mapDispatchToProps)(LoginContainer);
