// @flow
import * as ActionTypes from '../../actions';

export default function landingPages(state = {}, action) {
    switch (action.type) {
        case ActionTypes.LOAD_LANDING_COMPONENTS:
            if (action.status === 'success') {
                const { tab, result } = action.payload;
                return {
                    ...state,
                    [tab]: {
                        ...state[tab],
                        components: result
                    }
                };
            }
            break;
        default:
        // do nothing
    }
    return state;
}
