// @flow
import * as ActionTypes from 'store/actions';

const initialState = {};

function currentTabState(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_ITEMS_BY_KEYWORD:
        case ActionTypes.SEARCH_TAGS_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state
                };
            }
            if (action.status === 'success') {
                return {
                    ...state,
                    currentSortWord: action.reducerParams.sort
                };
            }
            if (action.states === 'error') {
                return {
                    ...state
                };
            }
            break;
        case ActionTypes.HANDLE_TAB_CHANGE:
            return {
                ...state,
                currentTab: action.tab.activeIndex
            };
        default:
            break;
    }
    return state;
}

export default currentTabState;
