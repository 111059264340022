// @flow
import React from 'react';
import type { Node } from 'react';
import { compose } from 'redux';
import { Button, Form, Message, Modal } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import { ShipmentOptions } from 'store/enums';
import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';

type FormParams = {
    trackingNumber: string,
    carrier: number
};

type Props = {
    formId: string,
    isOpen: boolean,
    orderId: number,
    isSubmitting: boolean,
    errorMessage: ?string,
    errorOrderId: ?number,
    onClose: () => void,
    onSubmit: (FormParams) => Promise<*>,
    cancelButtonLabel?: Node,
    acceptButtonLabel?: Node
};

const CARRIER_OPTIONS = [
    { key: 'poslaju', value: ShipmentOptions.POSLAJU, text: 'POSlaju' },
    { key: 'gdex', value: ShipmentOptions.GDEX, text: 'GDEX' },
    { key: 'taqbin', value: ShipmentOptions.TAQBIN, text: 'TA-Q-BIN' },
    { key: 'postabih', value: ShipmentOptions.POSTA_BIH, text: 'Posta BIH' },
    { key: 'expressone', value: ShipmentOptions.EXPRESSONE, text: 'ExpressOne' },
    { key: 'euroexpress', value: ShipmentOptions.EUROEXPRESS, text: 'EuroExpress' },
    { key: 'own', value: ShipmentOptions.OWN_SHIPMENT, text: 'Ostalo' }
];

const CARRIER_URL_PREFILL = {
    [ShipmentOptions.POSLAJU]: 'https://www.poslaju.com.my/track-trace-v2/',
    [ShipmentOptions.GDEX]: 'http://www.gdexpress.com/malaysia/e-tracking',
    [ShipmentOptions.TAQBIN]: 'http://my.ta-q-bin.com/v2/',
    [ShipmentOptions.POSTA_BIH]: 'https://posta.ba/tracking/',
    [ShipmentOptions.EXPRESSONE]: 'https://expressone.ba/en/tracking/',
    [ShipmentOptions.EUROEXPRESS]: 'https://www.euroexpress.ba/pracenje-posiljke/',
    [ShipmentOptions.OWN_SHIPMENT]: ''
};

class OrderAddTrackingNumberModalView extends CommonForm<Props> {
    static defaultProps;

    state = {
        formValues: {
            trackingNumber: createEmptyFormFieldData({
                value: '',
                type: 'string',
                required: true,
                error: false,
                rest: null
            }),
            carrier: createEmptyFormFieldData({
                value: '',
                type: 'number',
                required: true,
                error: false,
                rest: null
            }),
            carrierUrl: createEmptyFormFieldData({
                value: '',
                type: 'string',
                required: true,
                error: false,
                rest: null
            })
        }
    };

    handleSubmit = () => {
        const { onSubmit } = this.props;
        if (!this.validate()) {
            return;
        }

        const {
            trackingNumber: { value: trackingNumber },
            carrier: { value: carrier },
            carrierUrl: { value: carrierUrl }
        } = this.state.formValues;

        onSubmit({ trackingNumber, carrier, carrierUrl });
    };

    handleChangeCarrier = (event, data) => {
        const { formValues } = this.state;
        const { value } = data;
        this.handleChange(event, data);

        const {
            carrierUrl: { value: carrierUrl }
        } = formValues;

        const prefillUrl = CARRIER_URL_PREFILL[value];

        if (carrierUrl === '' || Object.values(CARRIER_URL_PREFILL).includes(carrierUrl)) {
            this.handleChange(event, {
                name: 'carrierUrl',
                value: prefillUrl
            });
        }
    };

    render() {
        const { isOpen, orderId, isSubmitting, errorMessage, errorOrderId, cancelButtonLabel, acceptButtonLabel, onClose } = this.props;
        return (
            <Modal as={Form} onSubmit={this.handleSubmit} error={errorMessage != null} onClose={onClose} size='tiny' open={isOpen}>
                <Modal.Header>
                    <Trans i18nKey='modal.orderAddTrackingNumber.header'>Add Tracking Number</Trans>
                </Modal.Header>
                <Modal.Content>
                    {errorMessage != null && errorOrderId === orderId && <Message error>{errorMessage}</Message>}
                    <Modal.Description>
                        {this.renderInputField({ name: 'trackingNumber', type: 'text' }, this.props)}
                        {this.renderDropdownField({ name: 'carrier', options: CARRIER_OPTIONS, onChange: this.handleChangeCarrier }, this.props)}
                        {this.renderInputField({ name: 'carrierUrl', type: 'text' }, this.props)}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic type='button' onClick={onClose}>
                        {cancelButtonLabel}
                    </Button>
                    <Button positive loading={isSubmitting}>
                        {acceptButtonLabel}
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

OrderAddTrackingNumberModalView.defaultProps = {
    cancelButtonLabel: <Trans i18nKey='modal.orderAddTrackingNumber.cancelButton modal.generic.cancelButton'>Back</Trans>,
    acceptButtonLabel: <Trans i18nKey='modal.orderAddTrackingNumber.acceptButton modal.generic.acceptButton'>Add</Trans>
};

export default compose(withTranslation('translations'))(OrderAddTrackingNumberModalView);
