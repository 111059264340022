// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadOrdersParams = {
    limit: number,
    offset: number
};

// LOAD_ORDERS ----------------------
export const LOAD_ORDERS = 'LOAD_ORDERS';
export const loadOrders = wrapAPIActionCreator(({ limit, offset }: LoadOrdersParams) => ({
    [API_DATA]: {
        type: LOAD_ORDERS,
        endpoint: '/v3/web/tabs/orders',
        endpointParams: { limit, offset },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            items: {
                openOrder: [Schemas.order],
                deliveredOrder: [Schemas.order],
                cancalledOrder: [Schemas.order]
            }
        },
        reducerParams: { limit, offset }
    }
}));
