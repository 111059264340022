// @flow
import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import type { User } from 'store/types';

import ProfileTabBarView from './ProfileTabBarView';

type Props = {
    user: User
};

class ProfileTabBarContainer extends Component<Props> {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    // UNSAFE_componentWillMount() {
    //   window.addEventListener('resize', this.handleWindowSizeChange);
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { user } = this.props;
        if (!user) {
            return <Loader active />;
        }

        return <ProfileTabBarView user={user} width={this.state.width} />;
    }
}

export default ProfileTabBarContainer;
