// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Tab, Visibility, Container, Loader } from 'semantic-ui-react';

import ProductList from 'components/ProductList';

const LikesTabView = ({ user, loadMore, likes }) => (
    <Tab.Pane attached={false} className='simple'>
        <Visibility
            onUpdate={(e, { calculations }) => {
                loadMore(calculations);
            }}
        >
            <ProductList items={likes.items || []} />
            {likes.isLoading && (
                <Container>
                    <Loader active inline='centered' />
                </Container>
            )}
        </Visibility>
    </Tab.Pane>
);

export default LikesTabView;
