// @flow
import React from 'react';
import { Form, Radio, Popup, Icon, Image, Input, Header, Label, Button } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { serializeFile } from 'utilities';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

import { BusinessTypes } from 'store/enums';

type Props = {
    t: Function,
    updateFormValues: Function,
    formId: string
};

type State = {
    errorMessage: ?string,
    formValues: {
        fileDataURI: ?string,
        businessType: FormFieldData<number>,
        licenseFile: FormFieldData<string>,
        vatNumber: FormFieldData<string>,
        existingLicenseUrl: ?string
    },
    showExistingLicenseImage: boolean
};

const defaultValuesFromMerchant = ({ merchant }) => {
    const { businessType, licenseUrl, licenseName, gstRegistrationNumber } = merchant;

    return {
        businessType: createEmptyFormFieldData({ value: businessType, type: 'number' }),
        fileDataURI: null,
        fileName: null,
        licenseFile: createEmptyFormFieldData({ value: '' }),
        existingLicenseUrl: licenseUrl,
        existingLicenseName: licenseName,
        vatNumber: createEmptyFormFieldData({ value: gstRegistrationNumber || '' })
    };
};

class MyStoreSettingsFormBusinessType extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { merchant, updateFormValues, formId } = props;

        let formValues = {};

        if (merchant) {
            formValues = defaultValuesFromMerchant({ merchant });
        } else {
            formValues = {
                businessType: createEmptyFormFieldData({ value: BusinessTypes.INDIVIDUAL, type: 'number' }),
                fileDataURI: null,
                fileName: null,
                existingLicenseUrl: null,
                licenseFile: createEmptyFormFieldData({ value: '' }),
                vatNumber: createEmptyFormFieldData({ value: '' })
            };
        }
        this.state = {
            formValues,
            showExistingLicenseImage: false
        };
        // updateFormValues({ formValues, formId });
    }

    handleChangeFile = (event, data) => {
        const { updateFormValues, formId } = this.props;
        this.handleChange(event, data);
        const file = event.target.files[0];
        if (file) {
            serializeFile(file).then(
                (dataURI) => {
                    const { formValues } = this.state;
                    formValues.fileDataURI = dataURI;
                    formValues.fileName = file.name;
                    this.setState({ formValues }, () => {
                        updateFormValues({ formValues, formId });
                    });
                },
                () => {}
            );
        } else {
            const { formValues } = this.state;
            formValues.fileDataURI = null;
            formValues.fileName = null;
            this.setState({ formValues }, () => {
                updateFormValues({ formValues, formId });
            });
        }
    };

    render() {
        const { t } = this.props;
        const { formValues, isPosting, showExistingLicenseImage } = this.state;
        const { fileDataURI, businessType, existingLicenseUrl, licenseFile, existingLicenseName } = formValues;
        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='myStore.settings.form.businessType.header'>Business Type</Trans>
                    </Header>
                </Form.Field>
                <Form.Field required error={businessType.error}>
                    <label>
                        <Trans i18nKey='myStore.settings.form.businessType.label'>Business Type</Trans>
                    </label>
                </Form.Field>
                <Form.Field error={businessType.error}>
                    <Radio
                        name='businessType'
                        id='my-store-settings-form-business-type-individual'
                        value={BusinessTypes.INDIVIDUAL}
                        checked={businessType.value === BusinessTypes.INDIVIDUAL}
                        disabled={isPosting}
                        onChange={this.handleChange}
                        label={
                            <label htmlFor='my-store-settings-form-business-type-individual'>
                                <Trans i18nKey='myStore.settings.form.businessType.individual.label'>Individual without Business License</Trans>
                                <Popup
                                    horizontaloffset={10}
                                    trigger={
                                        <Label className='inline-label-icon' circular size='mini'>
                                            <Icon name='question' />
                                        </Label>
                                    }
                                >
                                    <Popup.Header>
                                        <Trans i18nKey='myStore.settings.form.businessType.individual.popup.header'>Individual Seller Without SSM</Trans>
                                    </Popup.Header>
                                    <Popup.Content>
                                        <Trans i18nKey='myStore.settings.form.images.information'>Your products will be featured in the &quot;Classifieds&quot; section.</Trans>
                                    </Popup.Content>
                                </Popup>
                            </label>
                        }
                    />
                </Form.Field>
                <Form.Field error={businessType.error}>
                    <Radio
                        name='businessType'
                        id='my-store-settings-form-business-type-business'
                        value={BusinessTypes.BUSINESS}
                        checked={businessType.value === BusinessTypes.BUSINESS}
                        disabled={isPosting}
                        onChange={this.handleChange}
                        label={
                            <label htmlFor='my-store-settings-form-business-type-business'>
                                <Trans i18nKey='myStore.settings.form.businessType.business.label'>Registered Business with License</Trans>
                                <Popup
                                    horizontaloffset={10}
                                    trigger={
                                        <Label className='inline-label-icon' circular size='mini'>
                                            <Icon name='question' />
                                        </Label>
                                    }
                                >
                                    <Popup.Header>
                                        <Trans i18nKey='myStore.settings.form.businessType.business.popup.header'>Registered Business</Trans>
                                    </Popup.Header>
                                    <Popup.Content>
                                        <Trans i18nKey='myStore.settings.form.images.information'>
                                            Take full advantage of all tools and services to grow a successful online business with MyBazar.
                                        </Trans>
                                    </Popup.Content>
                                </Popup>
                            </label>
                        }
                    />
                </Form.Field>
                <Form.Field>
                    <label htmlFor='my-store-settings-licensefile'>
                        {businessType.value === BusinessTypes.INDIVIDUAL ? (
                            <Trans i18nKey='myStore.settings.form.store.file.label.individual'>Id or Passport</Trans>
                        ) : (
                            <Trans i18nKey='myStore.settings.form.store.file.label.business'>Business License (SSM)</Trans>
                        )}
                    </label>
                    <Input
                        type='file'
                        name='licenseFile'
                        id='my-store-settings-licensefile'
                        className={fileDataURI != null ? 'attached top' : ''}
                        value={licenseFile.value}
                        disabled={isPosting}
                        placeholder={t('myStore.settings.form.store.licenseFile.placeholder', { defaultValue: '' })}
                        icon='file image outline'
                        iconPosition='left'
                        onChange={this.handleChangeFile}
                    />
                    {fileDataURI != null && <Image className='attached bottom' bordered rounded src={fileDataURI} />}
                    {fileDataURI === null && showExistingLicenseImage && (
                        <Image
                            className='attached bottom'
                            bordered
                            rounded
                            src={existingLicenseUrl}
                            onClick={() => {
                                this.setState({ showExistingLicenseImage: !showExistingLicenseImage });
                            }}
                        />
                    )}
                    {fileDataURI === null && existingLicenseUrl && !showExistingLicenseImage && (
                        <Button
                            type='button'
                            onClick={() => {
                                this.setState({ showExistingLicenseImage: !showExistingLicenseImage });
                            }}
                        >
                            <Trans i18nKey='myStore.settings.form.licenseFile.showPreview'>{existingLicenseName}</Trans>
                        </Button>
                    )}
                </Form.Field>

                {this.renderInputField({ name: 'vatNumber' }, this.props)}
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(MyStoreSettingsFormBusinessType);
