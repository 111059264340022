// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { User } from 'store/types';
import ShareButtonsView from './ShareButtonsView';

type Props = {
    name: string
};

const ShareButtonsContainer = (props: Props) => {
    const { name, t } = props;
    return <ShareButtonsView url={window.location.href} title={t('shareButtons.title', { defaultValue: 'Check out {{name}} at MyBazar', name })} />;
};

export default withTranslation('translations')(ShareButtonsContainer);
