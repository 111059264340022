// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
    close: () => void
};

function SidebarClose({ close }: Props) {
    return (
        <Button className='main-sidebar-close' onClick={close} basic inverted icon>
            <Icon className='fitted-height' name='close' fitted size='large' />
        </Button>
    );
}

export default SidebarClose;
