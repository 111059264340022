// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false
};

function updateFromLoadActivity(state, action) {
    const { result } = action.payload;

    const { newFollowersCount, newMessageCount: newMessagesCount, newCommentCount: newCommentsCount } = result.storeActivity;

    return {
        ...state,
        newFollowersCount,
        newMessagesCount,
        newCommentsCount,
        isLoadingActivity: false
    };
}

function activity(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_ACTIVITY: {
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoadingActivity: true
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoadingActivity: false
                };
            }
            if (action.status === 'success') {
                return updateFromLoadActivity(state, action);
            }
            break;
        }
        // Reset activity store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default activity;
