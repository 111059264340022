// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as actions from 'store/actions';

import { withTranslation } from 'react-i18next';
import SortDropdownView from './SortDropdownView';

type Props = {
    selectedValue: string,
    setFilter: typeof actions.setFilter
};

class SortDropdownContainer extends Component<Props> {
    constructor(props) {
        super(props);
    }

    /*componentDidMount() {
        const { t, componentId, componentType } = this.props;
        console.log(componentType);
        const options =
            componentId === 205 || componentId === 221 // bazar-carousel
                ? [
                      {
                          value: 'az',
                          text: t(`sortDropdown.options.ascendingOrder.label`, {
                              defaultValue: 'Ascending (A-Z)'
                          })
                      },
                      {
                          value: 'za',
                          text: t(`sortDropdown.options.descendingOrder.label`, {
                              defaultValue: 'Descending (Z-A)'
                          })
                      }
                  ]
                : [
                      {
                          value: 'latest',
                          text: t(`sortDropdown.options.latestAdded.label`, {
                              defaultValue: 'Latest Added'
                          })
                      },
                      {
                          value: 'deal',
                          text: t(`sortDropdown.options.bestDeals.label`, {
                              defaultValue: 'Best Deals'
                          })
                      },
                      {
                          value: 'price_min',
                          text: t(`sortDropdown.options.priceMinToMax.label`, {
                              defaultValue: 'Price: Low to High'
                          })
                      },
                      {
                          value: 'price_max',
                          text: t(`sortDropdown.options.priceMaxToMin.label`, {
                              defaultValue: 'Price: High to Low'
                          })
                      }
                  ];
        //  this.setState({ options });
    }*/

    handleChange = (event, { value }) => {
        const { setFilter } = this.props;
        setFilter({ sort: value });
    };

    render() {
        const { selectedValue, t, componentId } = this.props;

        const sortIds = [205, 221, 195, 201, 219, 229, 240];
        const azSorting = sortIds.includes(componentId);

        const options = azSorting
            ? [
                  {
                      value: 'az',
                      text: t(`sortDropdown.options.ascendingOrder.label`, {
                          defaultValue: 'Ascending (A-Z)'
                      })
                  },
                  {
                      value: 'za',
                      text: t(`sortDropdown.options.descendingOrder.label`, {
                          defaultValue: 'Descending (Z-A)'
                      })
                  }
              ]
            : [
                  {
                      value: 'latest',
                      text: t(`sortDropdown.options.latestAdded.label`, {
                          defaultValue: 'Latest Added'
                      })
                  },
                  {
                      value: 'deal',
                      text: t(`sortDropdown.options.bestDeals.label`, {
                          defaultValue: 'Best Deals'
                      })
                  },
                  {
                      value: 'price_min',
                      text: t(`sortDropdown.options.priceMinToMax.label`, {
                          defaultValue: 'Price: Low to High'
                      })
                  },
                  {
                      value: 'price_max',
                      text: t(`sortDropdown.options.priceMaxToMin.label`, {
                          defaultValue: 'Price: High to Low'
                      })
                  }
              ];

        return <SortDropdownView options={options} value={selectedValue} onChange={this.handleChange} />;
    }
}

const mapStateToProps = (state) => {
    const { sort } = state.filters;
    return { selectedValue: sort };
};

const mapDispatchToProps = (dispatch) => ({
    setFilter: (...args) => dispatch(actions.setFilter(...args))
});

export default withTranslation('translations')(compose(connect(mapStateToProps, mapDispatchToProps))(SortDropdownContainer));
