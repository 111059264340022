// @flow
import { merge } from 'lodash';

// These are reducers for local component state, not for Redux state: they are
// to be passed to `setState` to perform a state update.

/**
 * Utility for creating objects describing updates to the field values of a form.
 * Given { name: 'Me', username: 'me' } it returns
 * { name: { value: 'Me' }, username: { value: 'me' } }.
 */
function makeFieldValueUpdateObject<K, V>(mapping: { [K]: V }): { [K]: { value: V } } {
    const res = {};
    Object.keys(mapping).forEach((key) => {
        res[key] = { value: mapping[key] };
    });
    return res;
}

export function loadUserToStateReducer({ fieldData, ...rest }, { user }) {
    const { alias: name, username, email, phoneNumber: phone } = user.entity || {};
    return {
        ...rest,
        hasLoaded: true,
        fieldData: merge(
            fieldData,
            makeFieldValueUpdateObject({
                name,
                username,
                email,
                phone,
                password: ''
            })
        )
    };
}

export function makeSetFormValuesReducer(mapping) {
    return ({ fieldData, ...rest }) => ({
        ...rest,
        fieldData: merge(fieldData, makeFieldValueUpdateObject(mapping))
    });
}
