// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Grid, Form, Button, Icon, Modal, Message } from 'semantic-ui-react';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';
import MessageProductView from '../MessageProductView';
import TextareaAutosize from 'react-textarea-autosize';

class MakeOfferFormView extends CommonForm {
    state = {
        isPosting: false,
        errorMessage: null,
        formValues: {
            price: createEmptyFormFieldData({ value: '', type: 'number', required: true }),
            quantity: createEmptyFormFieldData({ value: 1, type: 'number', required: true }),
            message: createEmptyFormFieldData({ value: '', required: true }),
            duration: createEmptyFormFieldData({ value: '5min', type: 'string', required: false })
        },
        showModal: false
    };

    handleDurationChange = (event, data) => {
        const { formValues } = this.state;
        const copiedValues = { ...formValues };
        copiedValues.duration.value = data.value;
        this.setState({ formValues: copiedValues });
    };

    /*handleDurationChange = (event, data) => {
        const { formValues } = this.state;
        formValues.duration.value = '6h';
        this.setState({ formValues }, () => {
            this.handleChange(event, data);
        });
    };*/

    handleSubmit = (event) => {
        event.preventDefault();

        const { formValues } = this.state;

        if (!this.validate()) {
            return;
        }

        const {
            price: { value: price },
            quantity: { value: quantity },
            message: { value: message },
            duration: { value: duration }
        } = formValues;

        const offer = {
            deliveryOptions: [],
            grossPrice: price,
            offerPrice: price,
            paymentOptions: [],
            quantity,
            duration
        };

        this.props
            .sendMessage({ message, offer })
            .then(() => this.onSuccess())
            .catch((error) => {
                this.setState({ errorMessage: error.message, isPosting: false });
            });
    };

    onSuccess = () => {
        const { formValues } = this.state;
        const { onPostMessageSuccess = () => {} } = this.props;
        formValues.price.value = '';
        formValues.quantity.value = 1;
        formValues.message.value = '';
        formValues.duration.value = '5min';
        this.setState({ formValues, showModal: false, isPosting: false });
        onPostMessageSuccess();
    };

    onCancel = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { isPosting, showModal, formValues, errorMessage } = this.state;

        const { message } = formValues;

        const { item, formId, t, user } = this.props;
        console.log(formId);

        const { price: originalPrice } = item.entity || {};
        const offerDurationMinAndH = ['5min', '10min', '15min', '30min', '1h', '6h', '12h'];
        const offerDurationDays = ['1 day', '2 days', '3 days', '4 days', '5 days', '6 days', '7 days'].map((time, index) => {
            return {
                text: `${index + 1}` + t(`messages.conversation.offer.duration.${time.split(' ')[1]}`, { defaultValue: 'days' }),
                value: time
            };
        });

        return (
            <Modal
                open={showModal}
                size='mini'
                onClose={() => this.setState({ showModal: false })}
                trigger={
                    <Button icon labelPosition='left' loading={isPosting} floated='right' onClick={() => this.setState({ showModal: true })}>
                        <Icon name='add' />
                        <Trans i18nKey='messages.conversation.actions.makeOffer'>Make Offer</Trans>
                    </Button>
                }
            >
                <Modal.Header>
                    <Trans i18nKey='messages.conversation.makeOffer.header'>Make Offer</Trans>
                </Modal.Header>
                <Modal.Content>
                    <Grid className='auto tensed'>
                        <Grid.Column className='col-row'>
                            <MessageProductView item={item} user={user} />
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            <Form error={errorMessage !== null}>
                                <Message error>{errorMessage}</Message>
                                <Form.Field>{this.renderInputField({ name: 'price', type: 'number', step: '0.01', maxValue: originalPrice }, this.props)}</Form.Field>
                                <Form.Field>{this.renderInputField({ name: 'quantity', type: 'number', step: '1', maxValue: 5, minValue: 1 }, this.props)}</Form.Field>

                                {this.renderDropdownField(
                                    {
                                        name: 'duration',

                                        options: [...offerDurationMinAndH, ...offerDurationDays].map((time) => {
                                            return {
                                                text: typeof time === 'string' ? time : time.text,
                                                value: typeof time === 'string' ? time : time.value
                                            };
                                        }),
                                        onChange: this.handleDurationChange
                                    },
                                    this.props
                                )}

                                <Form.Field required>
                                    <label htmlFor='messages-conversation-makeoffer-message'>
                                        <Trans i18nKey='messages.conversation.offer.offerMsgLabel'>Message</Trans>
                                    </label>
                                    <Form.TextArea
                                        required
                                        error={message.error}
                                        value={message.value}
                                        control={TextareaAutosize}
                                        rows={3}
                                        name='message'
                                        id='messages-conversation-makeoffer-message'
                                        onChange={(e) => this.handleChange(e, { name: 'message', value: e.target.value, type: 'textarea' })}
                                        placeholder='Ovdje unesite vašu poruku..'
                                    />
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                    </Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Button basic type='button' onClick={this.onCancel}>
                        <Trans i18nKey='messages.conversation.makeOffer.actions.cancel'>Cancel</Trans>
                    </Button>
                    <Button primary icon labelPosition='left' type='submit' onClick={this.handleSubmit}>
                        <Icon name='check' />
                        <Trans i18nKey='messages.conversation.makeOffer.actions.makeOffer'>Make Offer</Trans>
                    </Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withTranslation('translations')(MakeOfferFormView);
