// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Loader } from 'semantic-ui-react';

import type { EntityData, LandingComponent as LandingComponentType } from 'store/types';
import LandingComponent from '../..';

type Props = {
    component: EntityData<LandingComponentType>
};

function LandingChildComponentsView({ component }: Props) {
    if (component.isFetching || component.entity == null) {
        return <Loader active inline='centered' />;
    }

    const ids = component.entity.entities.map(({ id }) => id);

    return (
        <Grid className='landing-child-components' columns={ids.length} stackable>
            <Grid.Row>
                {ids.map((id) => (
                    <Grid.Column key={id}>
                        <LandingComponent landingComponentId={id} />
                    </Grid.Column>
                ))}
            </Grid.Row>
        </Grid>
    );
}

export default LandingChildComponentsView;
