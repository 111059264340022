import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { Button, Grid, Radio } from 'semantic-ui-react';

function DeliveryAddressListItem({ showCartSelector, item, selected, handleChange, onOpenEditAddress }) {
    const { t } = useTranslation('translations');
    return (
        <Grid>
            <Grid.Column
                // style={{
                //   textOverflow: 'ellipsis',
                //   whiteSpace: 'nowrap',
                //   overflow: 'hidden',
                // }}
                computer={11} tablet={10}
            >
                {showCartSelector && (
                    <Radio
                        name='deliveryAddress'
                        value={item.id}
                        checked={selected === item.id}
                        onChange={handleChange}
                        label={<label style={{ display: 'inline' }}>{`${item.fullName}, ${item.street}, ${item.city}, ${item.state}, ${item.country}`}</label>}
                    />
                )}
                {!showCartSelector && <p>{`${item.fullName}, ${item.street}, ${item.city}, ${item.state}, ${item.country}`}</p>}
            </Grid.Column>

            <Grid.Column className='address-form-btns-wrap'>
                <Button.Group basic size='small'>
                    {/*<Button icon='star outline' />*/}
                    <Button icon='edit' onClick={onOpenEditAddress}/>
                </Button.Group>
            </Grid.Column>
        </Grid>
    );
}

export default compose(withTranslation('translations'))(DeliveryAddressListItem);
