// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { Grid, Image, Loader, Search, Header, Icon } from 'semantic-ui-react';
import { Trans, withTranslation } from 'react-i18next';
import _ from 'lodash';

import Price from 'components/Price';
import { findSubstringRanges } from 'utilities';
import { formatItemName } from 'utilities/format';

import missingImage from 'styles/assets/images/missing-image.svg';

type Props = {
    isLoading: boolean,
    onResultSelect: (SyntheticEvent<*>, *) => void,
    onSearchChange: (SyntheticEvent<*>, *) => void,
    onSubmit: () => void,
    searchQuery: string,
    searchItems: EntityData<ItemType>[],
    searchCounts: {
        items: number,
        tagItems: number,
        users: number,
        stores: number
    },
    // From middleware
    onCloseSearch: () => void,
    t: (string) => string,
    opened: boolean
};

function itemEntityToResult({ entity }) {
    return {
        type: 'entity',
        key: `entity-${entity.id}`,
        entity,
        link: `/${entity.slug}`
    };
}

function searchCountToResult({ tab, label, count }, query) {
    return {
        type: 'count',
        key: `count-${tab}`,
        label,
        count,
        link: `/search/${tab}/${encodeURIComponent(query)}`
    };
}

function renderItemNameWithEmphasis(name, highlightTerms) {
    const prettyName = formatItemName(name);
    const lowercase = (str) => str.toLowerCase();

    const ranges = findSubstringRanges(lowercase(prettyName), highlightTerms.map(lowercase));

    const content = [];
    let prevIndex = 0;
    ranges.forEach(({ from, length }) => {
        if (from < prevIndex) {
            return;
        }
        content.push(prettyName.slice(prevIndex, from));
        content.push(<strong key={from}>{prettyName.slice(from, from + length)}</strong>);
        prevIndex = from + length;
    });
    content.push(prettyName.slice(prevIndex));

    return content.filter(Boolean);
}

class SearchInputView extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = { searchValue: '' };
        this.searchInputRef = React.createRef();
    }

    state = { searchValue: '' };

    componentDidMount() {
        this.searchInputRef.current.focus();
    }

    renderItemEntityResult = ({ entity }) => {
        const { searchQuery } = this.props;
        const { name, pictures } = entity;
        const image = pictures.length ? pictures[0].imageThumb : undefined;

        return (
            <Grid className='auto tensed'>
                <Grid.Column className='col-2'>
                    <Image
                        className='square-image'
                        wrapped
                        src={image || missingImage}
                        onError={(event) => {
                            event.target.src = missingImage;
                        }}
                    />
                </Grid.Column>
                <Grid.Column className='col-14'>
                    <Grid className='auto absolutely-tensed'>
                        <Grid.Column className='col-row'>
                            <Header size='small'>{renderItemNameWithEmphasis(name, searchQuery.match(/\S+/g) || [])}</Header>
                        </Grid.Column>
                        <Grid.Column className='col-row'>
                            <Price item={entity} offer={null} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        );
    };

    renderSearchCountResult = ({ label, count }) => {
        const { searchQuery } = this.props;

        return (
            <Grid className='auto'>
                <Grid.Column>
                    <Header size='tiny'>
                        <strong>{searchQuery}</strong>
                        <Trans i18nKey={`topbar.search.categoryCount.labels.${label.toLowerCase()}`}>in {{ label }}</Trans>
                    </Header>
                </Grid.Column>
                <Grid.Column className='col-text-align-right'>
                    <Header size='tiny'>
                        <Trans i18nKey='topbar.search.categoryCount.count' count={count}>
                            <strong>{{ count }}</strong> results
                        </Trans>
                    </Header>
                </Grid.Column>
            </Grid>
        );
    };

    renderLoadingResult = () => <Loader active inline='centered' />;

    renderResult = ({ type, ...rest }) => {
        switch (type) {
            case 'entity':
                return this.renderItemEntityResult(rest);
            case 'count':
                return this.renderSearchCountResult(rest);
            case 'loading':
                return this.renderLoadingResult();
            default:
                throw new Error(`Unimplemented search result type to render: ${type}`);
        }
    };

    handleSearchChange = (e, { value }) => {
        this.props.onSearchChange(value);
        this.setState({ searchValue: value });
    };
    render() {
        const { isLoading, searchQuery, searchItems, searchCounts, onSearchChange, onResultSelect, onSubmit, t, opened, onCloseSearch } = this.props;
        const classifiedCount = searchCounts.cItems + searchCounts.cTags + searchCounts.cUsers + searchCounts.cStores;
        const countResults = [
            { tab: 'items', label: 'Items', count: searchCounts.items },
            { tab: 'stores', label: 'Stores', count: searchCounts.stores },
            { tab: 'users', label: 'Users', count: searchCounts.users },
            { tab: 'tags', label: 'Tags', count: searchCounts.tagItems }
            // Classifieds is shoiwng users without business licence. This is later stage project and need to be hidden so far from all places across platform
            /*{ tab: 'classified', label: 'Classified', count: classifiedCount }*/
        ]
            .map((obj) => searchCountToResult(obj, searchQuery))
            .filter(({ count }) => count > 0);

        const results = isLoading ? [{ type: 'loading', key: 'loading' }] : [...searchItems.map(itemEntityToResult), ...countResults];

        return (
            <>
                <Search
                    className='top-bar-search'
                    input={{
                        icon: 'search',
                        size: 'large',
                        iconPosition: 'left',
                        onKeyDown: (event) => {
                            if (event.keyCode === 13 || event.which === 13) {
                                onSubmit();
                            }
                        },
                        ref: this.searchInputRef
                    }}
                    defaultOpen={false}
                    placeholder={t('topbar.search.placeholder', { defaultValue: '' })}
                    minCharacters={0}
                    loading={isLoading}
                    results={results}
                    resultRenderer={this.renderResult}
                    onSearchChange={_.debounce(this.handleSearchChange, 500)}
                    onResultSelect={onResultSelect}
                    noResultsMessage={this.state.searchValue === '' ? '' : 'Nema rezultata'}
                />
                <Icon className='close-right-icon' name='close' fitted onClick={onCloseSearch} />
            </>
        );
    }
}

export default compose(withTranslation('translations'))(SearchInputView);
