// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyStoreMessagesTab from './components/MyStoreMessagesTab';

function MyStoreMessages(props) {
    return (
        <Container>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    <Header as='h1'>
                        <Trans i18nKey='myStore.messages.header'>My Store Messages</Trans>
                    </Header>
                </Grid.Column>
                <Grid.Column className='col-row'>
                    <MyStoreMessagesTab {...props} />
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default withTranslation('translations')(MyStoreMessages);
