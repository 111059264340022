import React from 'react';

import ProfilePicture from 'scenes/MyProfile/components/MyProfilePicture';
import ProfileCover from 'scenes/MyProfile/components/MyProfileCover';

const ProfileImages = () => {
    return (
        <div className='profile-images-container'>
            <ProfilePicture />
            <ProfileCover />
        </div>
    );
};

export default ProfileImages;
