// @flow
import React, { Component } from 'react';
import IframeView from 'components/IframeView';
import HtmlWhitelistSanitizer from 'utilities/HtmlWhitelistSanitizer';

type Props = {
    sourceText: string
};

class RichTextView extends Component<Props> {
    sanitizer = new HtmlWhitelistSanitizer(true);

    render() {
        const { sourceText } = this.props;

        // HTML item description, sanitize and render
        const sanitizedText = this.sanitizer.sanitizeString(sourceText);
        return <IframeView title='Rich Text Description' sourceText={sanitizedText} />;
    }
}

export default RichTextView;
