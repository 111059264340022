// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { closeModal } from 'store/actions';
import { ModalNames } from 'store/actions/ui';

import View from './RegisterAlertView';

const RegisterAlertScene = () => {
    const dispatch = useDispatch();

    const register = () => {
        dispatch(closeModal(ModalNames.REGISTER_ALERT));
        dispatch(push('/signup'));
    };

    return <View Register={register} />;
};

export default RegisterAlertScene;
