// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Segment, Button } from 'semantic-ui-react';

import Slick from 'react-slick';

import PromotionalCarouselArrow from './components/PromotionalCarouselArrow';
import PromotionalCarouselItem from './components/PromotionalCarouselItem';

type Props = {
    content: number[]
};

function PromotionalCarousel({ content }: Props) {
    let slickChanging = false;
    const getSlickChanging = () => slickChanging;
    const slickSettings = {
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000,
        adaptiveHeight: true,
        dots: true,
        dotsClass: 'slick-dots promotional-carousel-slick-dots',
        customPaging: () => <Button basic circular inverted icon compact size='mini' className='promotional-carousel-slick-dot-button' />,
        nextArrow: <PromotionalCarouselArrow direction='next' />,
        prevArrow: <PromotionalCarouselArrow direction='prev' />,
        beforeChange: () => {
            slickChanging = true;
        },
        afterChange: () => {
            slickChanging = false;
        }
    };
    return (
        <Segment className='simple promotional-carousel'>
            <Slick {...slickSettings} className='promotional-carousel-slick-slider'>
                {content.map((id) => (
                    <div key={id} className='promotional-carousel-slick-slide' onDragStart={(event) => event.preventDefault()}>
                        <PromotionalCarouselItem bannerId={id} getSlickChanging={getSlickChanging} />
                    </div>
                ))}
            </Slick>
        </Segment>
    );
}

export default withTranslation('translations')(PromotionalCarousel);
