// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';

import * as actions from 'store/actions';
import { getCategory } from 'store/utilities';
import withNavigationAid from 'utilities/withNavigationAid';
import type { EntityData, Category } from 'store/types';

import CategoryTeaserView from './CategoryTeaserView';

type Props = {
    // from middleware
    location: *,
    navigationAid: *,
    navigateTo: typeof push,
    // from store
    category: EntityData<Category>,
    setFilter: typeof actions.setFilter
};

function CategoryTeaserContainer(props: Props) {
    const { location, navigationAid, category } = props;
    const { landingPage, urlPrefix } = navigationAid;
    const { pathname } = location;

    const currentCategorySlug = /categories\/[^/]+/.test(pathname) ? pathname.match(/categories\/[^/]+/)[0].replace(/categories\//, '') : '';

    const handleClickSetFilter = (event, { categoryId }) => {
        const { setFilter } = props;
        setFilter({ categoryId });
    };

    const handleClickNavigateCategory = (event, { categorySlug }) => {
        const { navigateTo } = props;
        navigateTo(`${urlPrefix}/categories/${categorySlug}`);
    };

    const handleClick = (landingPage || {}).slug === 'classifieds' ? handleClickSetFilter : handleClickNavigateCategory;

    const isActive = currentCategorySlug === (category.entity && category.entity.slug);

    return <CategoryTeaserView onClick={handleClick} isActive={isActive} {...props} />;
}

const mapStateToProps = (state, { categoryId }) => {
    const category = getCategory(state, categoryId);
    return { category };
};

const mapDispatchToProps = {
    setFilter: actions.setFilter,
    navigateTo: push
};

export default compose(withRouter, withNavigationAid, connect(mapStateToProps, mapDispatchToProps))(CategoryTeaserContainer);
