// @flow
import { Schemas, API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

import { ItemWeightTypes } from './addItem';

export type UpdateItemParams = {
    id: number,
    // TODO: name & description are mandatory only because the backend errors
    // badly if they aren't supplied.  Once this is fixed in backend, they
    // should be made optional.
    name: string,
    description: string,
    tags?: string,
    brand?: string,
    category?: number,
    categoryChild?: number,
    categoryParent?: number,
    categoryTags?: string,
    dealTypes?: {
        buy_3_for_2: boolean,
        sale: number,
        saleGross: number,
        sample: boolean
    },
    gst?: boolean,
    isActive?: 0 | 1,
    status?: number,
    isDeal?: boolean,
    isFixedPrice?: boolean,
    pictures?: {
        data: string, // Base64 encoded image data
        index: number
    }[],
    price?: number,
    shippingDetails?: {
        id: number,
        delivery: number, // TODO set possible values here
        estimatedDeliveryCost: number,
        estimatedDeliveryTimeEnd: number, // Maximum Days
        estimatedDeliveryTimeStart: number, // Minimum Days
        isMerchantPay: 0 | 1,
        itemShippingDetailKey: number,
        payment: number,
        returnPolicyId: number,
        shipFromId: number, // Store Id
        shipToId: number,
        shipment: number, // TODO set possible values here
        title: string // eg. "West Malaysia (POSlaju)"
    }[],
    storeId?: number,
    variants?: {
        sku: string,
        quantity: number,
        id: number
        // TODO: This will also have more options depending on category selected
    }[],
    weight?: number, // Can be grams or kilograms,
    weightType?: ItemWeightTypes.Grams | ItemWeightTypes.Kilograms
};

export const UPDATE_ITEM = 'UPDATE_ITEM';
export const updateItem = wrapAPIActionCreator(
    ({
        id,
        brand,
        category,
        categoryChild,
        categoryParent,
        categoryTags,
        dealTypes,
        description,
        tags,
        gst,
        isActive,
        status,
        isDeal,
        isFixedPrice,
        name,
        pictures,
        price,
        shippingDetails,
        storeId,
        variants,
        weight, // Can be grams or kilograms,
        weightType
    }: UpdateItemParams) => ({
        [API_DATA]: {
            type: UPDATE_ITEM,
            endpoint: `/v3/items/${id}`,
            endpointParams: { newRequestMode: true }, // "new_request_mode" is some flag on backend used instead of creating a v4 route
            method: 'PUT',
            authentication: Authentication.Merchant,
            body: {
                brand,
                category,
                categoryChlid: categoryChild, // Yeah, it's a type on backend.
                categoryParent,
                categoryTags,
                dealTypes,
                descriptions: description,
                tags,
                gst,
                isActive,
                status,
                isDeal,
                isFixedPrice,
                isGstEnabled: gst,
                name,
                pictures,
                price,
                shippingDetails,
                storeId,
                variants,
                weight, // Can be grams or kilograms,
                weightType
            },
            schema: {
                item: Schemas.item
            },
            decamelizeOptions: {
                process: (key, convert) => (/^[A-Z]/.test(key) ? key : convert(key))
            }
        }
    })
);

export default updateItem;
