// @flow
import { Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Checkbox, Image } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { formatDatePretty, formatPrice } from 'utilities/format';
import type { EntityData, Item, Order } from 'store/types';
import { Authentication } from '../../../../services/api';
import { useSelector } from 'react-redux';
import { getCategory } from '../../../../store/utilities';
import Price from '../../../Price';
import DefaultThumb from '../../../../../src/styles/assets/images/defaults/default-item-image.jpg';

type Props = {
    type: 'item' | 'order' | 'purchase',
    tab: string,
    item: EntityData<Item>,
    order: EntityData<Order>,
    width: number
};

export const getPaymentStatusFromId = (id) => {
    return {
        0: <Trans i18nKey='myStore.orders.ordersListItem.faild'>Faild</Trans>,
        1: <Trans i18nKey='myStore.orders.ordersListItem.paid'>Paid</Trans>,
        2: <Trans i18nKey='myStore.orders.ordersListItem.cancelled'>Cancelled</Trans>
    }[id];
};

export const getShipmentProviderById = (id) => {
    return {
        1: 'GDEX',
        2: 'Poslaju',
        3: 'Taqbin',
        4: 'Posta BIH',
        5: 'ExpressOne',
        6: 'EuroExpress'
    }[id];
};
const ProductDetailListItem = (props: Props) => {
    const { type } = props;
    const state = useSelector((state) => state);
    const [detailsActive, setDetailsActive] = useState(false);
    useEffect(() => {
        setDetailsActive(props.detailsActive !== undefined ? props.detailsActive : false);
    }, []);
    const generateUrl = () => {
        const { item, type, order, authentication } = props;
        if (type === 'purchase') {
            if (detailsActive) {
                return '/my-profile/purchases/';
            } else {
                return `/my-profile/purchases/${order.entity.id}`;
            }
        } else if (type === 'order') {
            if (detailsActive) {
                return '/my-store/orders/';
            } else {
                return `/my-store/orders/${order.entity.id}`;
            }
        } else {
            if (authentication === Authentication.Merchant) {
                return `/edit-item/${item.entity.id}`;
            } else {
                return `/item/${item.entity.id}`;
            }
        }
    };

    //TODO: Load from DB instead

    const renderProduct = () => {
        const { item, type, ...rest } = props;
        const { name, storeSlug, status, statusTitle, soldCount, category, pictures } = item.entity || {};
        console.log('ajtem entiti', item.entity);
        const thumb = pictures[0]?.imageThumb ?? DefaultThumb;
        const order = props.order || {};
        const { id, createdAt, price, quantity } = type === 'order' || type === 'purchase' ? order.entity || {} : item.entity || {};
        const categoryNameEntity = state.entities.categories[category];
        const categoryName = categoryNameEntity ? categoryNameEntity.entity.name : '/';

        const Columns = [
            {
                key: 'title',
                title: 'Title',
                value: name
            },
            {
                key: 'status',
                title: 'status',
                value: statusTitle
            },
            {
                key: 'inventory',
                title: 'inventory',
                value: quantity
            },
            {
                key: 'sold',
                title: 'sold',
                value: soldCount
            },
            {
                key: 'price',
                title: 'price',
                value: formatPrice(price)
            },
            {
                key: 'category',
                title: 'category',
                value: categoryName
            }
        ];
        if (props.width > 768) {
            return (
                <Link to={generateUrl} className={`my-store-product-list-item${detailsActive ? ' my-store-product-list-item-details-active' : ''}`}>
                    <div style={{ display: 'flex' }}>
                        <Grid.Column className='table-row'>
                            {/*<Checkbox style={{ margin: 'auto 16px' }} />*/}
                            <Image src={thumb} style={{ width: '44px', height: '44px' }} />
                            <span style={{ marginLeft: '10px' }}>{name}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row custom'>
                            <span>{statusTitle}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row custom'>
                            <span>
                                {quantity + ' '}
                                {quantity === 1 ? (
                                    <Trans i18nKey='myStore.orders.ordersListItem.amount'>product</Trans>
                                ) : (
                                    <Trans i18nKey='myStore.orders.ordersListItem.amounts'>products</Trans>
                                )}
                            </span>
                        </Grid.Column>
                        <Grid.Column className='table-row custom'>
                            <span>{soldCount}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row custom'>
                            <span>{formatPrice(price)}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row custom'>
                            <span>{categoryName}</span>
                        </Grid.Column>
                    </div>
                </Link>
            );
        } else {
            return (
                <div
                    style={{ marginBottom: '10px', width: '100%' }}
                    className={`ui card my-store-product-list-item${detailsActive ? ' my-store-product-list-item-details-active' : ''}`}
                >
                    <Link to={generateUrl()} style={{ backgroundColor: '#f7f7f7' }} className='content'>
                        <div className='header'>#{id}</div>
                    </Link>
                    <Link to={generateUrl()} className='content'>
                        <div className='description'>
                            {Columns.map((column) => (
                                <div key={column.key} className='card-item'>
                                    <div className='card-item-key'>
                                        <Trans i18nKey={`myStore.items.itemsListCol.${column.key}`}>{column.title}</Trans>
                                    </div>
                                    <div>{column.value}</div>
                                </div>
                            ))}
                        </div>
                    </Link>
                    <div className='extra content' style={{ display: 'flex' }}>
                        <Link to={generateUrl()} className='ui bottom attached button' style={{ width: '100%', marginRight: '5px' }}>
                            <Trans i18nKey='product.showProduct'>Pogledaj proizvod</Trans>
                        </Link>
                    </div>
                </div>
            );
        }
    };

    const renderOrder = () => {
        const { item, type, t, ...rest } = props;
        const { storeSlug } = item.entity || {};
        const order = props.order || {};
        const shopper = type === 'order' ? order.entity.shopperAddress.fullName : '';
        const { id, createdAt, sellingPrice, shipmentRate, paymentStatus, quantity, orderStatusTitle } =
            type === 'order' || type === 'purchase' ? order.entity || {} : item.entity || {};
        const statusTrans = orderStatusTitle.replace(' ', '').toLowerCase();
        const shipmentProvider = item.entity.shippingDetails[0].merchantShippingDetail.title;
        const Columns = [
            {
                key: 'date',
                title: 'Date',
                value: formatDatePretty(new Date(createdAt), t)
            },
            {
                key: type === 'order' ? 'shopper' : 'store',
                title: 'Store',
                value: type === 'order' ? shopper : storeSlug
            },
            {
                key: 'total',
                title: 'Total',
                value: formatPrice(sellingPrice + shipmentRate)
            },
            {
                key: 'payment',
                title: 'Payment',
                value: getPaymentStatusFromId(paymentStatus)
            },
            {
                key: 'quantity',
                title: 'Quantity',
                value:
                    quantity === 1 ? (
                        <>
                            {quantity + ' '}
                            <Trans i18nKey='myStore.orders.ordersListItem.amount'>product</Trans>
                        </>
                    ) : (
                        <>
                            {quantity + ' '}
                            <Trans i18nKey='myStore.orders.ordersListItem.amounts'>products</Trans>
                        </>
                    )
            },
            {
                key: 'fulfillment',
                title: 'Fulfillment',
                value: <Trans i18nKey={`myStore.orders.ordersListItem.${statusTrans}`}>{orderStatusTitle}</Trans>
            },
            {
                key: 'shippingCarrier',
                title: 'Shipping Carrier',
                value: <Grid.Column className='col-sm-4 col-md-3 col-2'>{shipmentProvider ?? ''}</Grid.Column>
            }
        ];
        if (props.width > 768) {
            return (
                <Link to={generateUrl} className={`my-store-product-list-item${detailsActive ? ' my-store-product-list-item-details-active' : ''}`}>
                    <div style={{ display: 'flex' }}>
                        <Grid.Column className='table-row-order'>
                            <span>#{id}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>{formatDatePretty(new Date(createdAt), t)}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>{type === 'order' ? shopper : storeSlug}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>{formatPrice(sellingPrice + shipmentRate)}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>{getPaymentStatusFromId(paymentStatus)}</span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>
                                {quantity}{' '}
                                {quantity === 1 ? (
                                    <Trans i18nKey='myStore.orders.ordersListItem.amount'>product</Trans>
                                ) : (
                                    <Trans i18nKey='myStore.orders.ordersListItem.amounts'>products</Trans>
                                )}
                            </span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>
                                <Trans i18nKey={`myStore.orders.ordersListItem.${statusTrans}`}>{orderStatusTitle}</Trans>
                            </span>
                        </Grid.Column>
                        <Grid.Column className='table-row-order'>
                            <span>{shipmentProvider ?? ''}</span>
                        </Grid.Column>
                    </div>
                </Link>
            );
        } else {
            return (
                <Link to={generateUrl} className={`my-store-product-list-item${detailsActive ? ' my-store-product-list-item-details-active' : ''}`}>
                    <div style={{ marginBottom: '10px' }} className='ui card'>
                        <div style={{ backgroundColor: '#f7f7f7' }} className='content'>
                            <div className='header'>#{id}</div>
                        </div>
                        <div className='content'>
                            <div className='description'>
                                {Columns.map((column) => (
                                    <div key={column.key} className='card-item'>
                                        <div style={{ fontWeight: '700' }}>
                                            <Trans i18nKey={`myStore.orders.ordersListCol.${column.key}`}>{column.title}</Trans>
                                        </div>
                                        <div>{column.value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='extra content'>
                            <div className='ui bottom attached button'>
                                {' '}
                                <Trans i18nKey='myStore.orders.showOrder'>Pogledaj narudžbu</Trans>
                            </div>
                        </div>
                    </div>
                </Link>
            );
        }
    };
    if (type === 'item') {
        return <div>{renderProduct()}</div>;
    } else {
        return <div>{renderOrder()}</div>;
    }
};

export default withTranslation('translations')(ProductDetailListItem);
