// @flow

// RESET_ENTITY ------------------
export type ResetEntityParams = {
    entityType: string,
    entityId: string
};

export const RESET_ENTITY = 'RESET_ENTITY';
export const resetEntity = ({ entityType, entityId }: ResetEntityParams) => ({
    type: RESET_ENTITY,
    payload: {
        entityType,
        entityId
    }
});
