// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';
import { merge } from 'lodash';

// LOAD_CATEGORY_ITEMS_BY_SLUG ------------------
type LoadCategoryItemsBySlugParams = {
    slug: string,
    parentSlug: string,
    childSlug?: string,
    offset: number,
    limit: number,
    sort?: '' | 'deal' | 'price_min' | 'price_max',
    onlyBlee?: boolean,
    collection?: ?number,
    bazar?: string
};

export const LOAD_CATEGORY_ITEMS_BY_SLUG = 'LOAD_CATEGORY_ITEMS_BY_SLUG';
export const loadCategoryItemsBySlug = wrapAPIActionCreator(
    ({ slug, parentSlug = null, childSlug = null, offset, limit, sort = '', onlyBlee = false, collection = undefined , bazar='' }: LoadCategoryItemsBySlugParams) => ({
        [API_DATA]: {
            type: LOAD_CATEGORY_ITEMS_BY_SLUG,
            endpoint: `/v3/search/category/${slug}/items`,
            endpointParams: {
                parentSlug: parentSlug === 'categories' ? null : parentSlug,
                offset,
                limit,
                sort,
                onlyBlee,
                childSlug,
                collection,
                bazar
            },
            schema: {
                parentCategory: Schemas.category,
                category: Schemas.category,
                items: [Schemas.item]
            },
            preprocess: (res) =>
                merge({}, res, {
                    category: {
                        items: res.items,
                        itemsCount: res.itemsCount
                    }
                })
        }
    })
);
