// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type LoadMyStoreItemsParams = {
    limit: number,
    offset: number
};

// LOAD_MY_STORE_ITEMS ----------------------
export const LOAD_MY_STORE_ITEMS = 'LOAD_MY_STORE_ITEMS';
export const loadMyStoreItems = wrapAPIActionCreator(({ limit, offset }: LoadMyStoreItemsParams) => ({
    [API_DATA]: {
        type: LOAD_MY_STORE_ITEMS,
        endpoint: '/v3/web/tabs/items',
        endpointParams: { limit, offset },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            items: {
                activeItem: [Schemas.item],
                bannedItem: [Schemas.item],
                inActiveItem: [Schemas.item],
                soldoutItem: [Schemas.item]
            }
        },
        reducerParams: { limit, offset }
    }
}));
