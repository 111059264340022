// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export const MARK_ALL_AGENT_MESSAGES_AS_READ = 'MARK_ALL_AGENT_MESSAGES_AS_READ';
export const markAllAgentMessagesAsRead = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: MARK_ALL_AGENT_MESSAGES_AS_READ,
        endpoint: '/v3/markallusermessagesasread',
        method: 'POST',
        authentication: Authentication.Agent,
        body: {},
        schema: {}
    }
}));
