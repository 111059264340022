// @flow
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import * as actions from 'store/actions';

export type CommonFormProps = {
    updateFormValues: typeof actions.updateFormValues
};

export type FormFieldData<T = string> = {
    value: T,
    type: string,
    required: boolean,
    error: boolean,
    id?: string,
    rest: *
};

export type InputFieldParams = {
    name: string,
    type: string,
    onChange?: Function,
    width?: number
};

export const createEmptyFormFieldData = ({ value = '', type = 'string', required = false, error = false, id, ...rest }: FormFieldData<>) => ({
    value,
    type,
    required,
    error,
    id,
    ...rest
});

type CommonFormState = {
    formValues: {
        [string]: {
            required: boolean,
            type: string,
            value: *
        }
    }
};


export default class CommonForm<Props, State = {}> extends Component<Props & CommonFormProps, State & CommonFormState> {
    constructor(props) {
        super(props);
        this.state = {
            formValues: {}
        };

        this.childFormValidators = {};
    }

    onRegisterChildFormValidator = ({ formId, validator }) => {
        this.childFormValidators[formId] = validator;
    };

    onUnregisterChildFormValidator = ({ formId }: { formId: number }) => {
        delete this.childFormValidators[formId];
    };

    componentDidMount() {
        const { registerChildFormValidator, formId } = this.props;
        if (registerChildFormValidator) {
            registerChildFormValidator({ formId, validator: this.validate });
        }
    }

    // UNSAFE_componentWillMount() {
    //   const { registerChildFormValidator, formId } = this.props;
    //   if (registerChildFormValidator) {
    //     registerChildFormValidator({ formId, validator: this.validate });
    //   }
    // }

    componentWillUnmount() {
        const { unregisterChildFormValidator, formId } = this.props;
        if (unregisterChildFormValidator) {
            unregisterChildFormValidator({ formId, validator: this.validate });
        }
    }

    validateField = (field) => {
        const { formValues } = this.state;
        const formValue = formValues[field];
        const { required, type, value } = formValue;
        if (required) {
            switch (type) {
                case 'boolean':
                    return value === true;
                case 'string':
                    return !(value === null || value.length === 0);
                case 'number':
                    return value != null && value !== '' && value > 0;
                default:
                    break;
            }
        }
        return true;
    };

    errorMapper = (error) => {
        switch (error) {
            case 'sellItem.form.create.item.images':
                return <Trans i18nKey='sellItem.form.errors.image'>Image field is required.</Trans>;
            case 'sellItem.form.create.item':
                return <Trans i18nKey='sellItem.form.errors.item'>Item details are required.</Trans>;
            case 'sellItem.form.create.delivery':
                return <Trans i18nKey='sellItem.form.errors.shipping'>Shipping from location field is required.</Trans>;
            case 'sellItem.form.create.delivery.shippingTo.myBazarEuroExpress.details':
                return <Trans i18nKey='sellItem.form.errors.exchange'>Return and exchanges type field is required.</Trans>;
            default:
                return <Trans i18nKey='sellItem.form.errors.default'>Required field is missing.</Trans>;
        }
    };

    validate = () => {
        let validates = true;

        const { formValues } = this.state;

        let error = '';
        const childFormsValidate = Object.values(this.childFormValidators).every((validateChild) => {
            if (validateChild() === false) {
                error = this.errorMapper(Object.keys(this.childFormValidators).find((key) => this.childFormValidators[key] === validateChild));
            }
            return validateChild();
        });

        if (!childFormsValidate) {
            this.setState({ errorMessage: error });
        }

        Object.keys(formValues).forEach((field) => {
            validates = validates && this.validateField(field);
            //TODO: test why there is no this field
            if(formValues && formValues[field]) {
                formValues[field].error = !validates;
            }
        });

        this.setState({ formValues });
        return validates && childFormsValidate;
    };

    handleBlur = (event) => {
        const { name } = event.target;
        const validates = this.validateField(name);
        const { formValues } = this.state;
        formValues[name].error = !validates;
        this.setState({ formValues });
    };

    handleChange = (event, data) => {
        const { name, value, type, checked, options } = data;
        const { formValues } = this.state;
        const { updateFormValues, formId } = this.props;

        const target = formValues[name];

        //console.log('CommonForm: target', { name, value, type, checked, options });
        //console.log('CommonForm: state.formValues', formValues, name);

        switch (type) {
            case 'checkbox':
                target.value = checked;
                break;
            default:
                //TODO: test why this fails
                if(target) {
                    target.value = value;
                }
                break;
        }

        formValues[name] = target;

        const newFormValues = {
            ...formValues,
            [name]: target
        };

        this.setState({ formValues: newFormValues }, () => {
            if (updateFormValues && formId) {
                updateFormValues({ formId, formValues: newFormValues });
                if (options) {
                    this.validate();
                }
            }
        });
    };

    // handleSubmit = () => {
    //   const { onSubmit } = this.props;
    //   const { formValues } = this.state;
    //   const {
    //     title: { value: title },
    //   } = formValues;

    //   if (this.validates()) {
    //     if (onSubmit) {
    //       onSubmit();
    //     }
    //   }
    // }

    renderInputField = (params: InputFieldParams, props) => {
        const { formValues } = this.state;

        const {
            name,
            type,
            onChange,
            onBlur,
            width,
            defaultLabel = name,
            defaultPlaceholder = name,
            minValue = 0,
            maxValue = undefined,
            disabled = false,
            value = formValues[name].value,
            required = formValues[name].required,
            step
        } = params;

        const { t, translationId = 'generic', formId } = props;

        const labelKey = `${translationId}.${name}.label`;
        let label = t(labelKey);
        if (label === labelKey) {
            label = defaultLabel;
        }

        const placeholderKey = `${translationId}.${name}.placeholder`;
        let placeholder = t(placeholderKey);
        if (placeholder === placeholderKey) {
            placeholder = defaultPlaceholder;
        }
        return (
            <Form.Input
                width={width || 16}
                // error={formValues[name].error}
                error={
                    formValues[name].error && {
                        content: this.props.i18n.language === 'bs' ? 'Ovo polje je obavezno.' : 'This field is required.'
                    }
                }
                type={type}
                name={name}
                min={minValue}
                max={maxValue}
                step={step}
                disabled={disabled}
                id={`${formId.toLowerCase()}-${name}`}
                onChange={(_event, data) => {
                    if (onChange) {
                        onChange(_event, data);
                    } else {
                        this.handleChange(_event, data);
                    }
                }}
                onBlur={onBlur || this.handleBlur}
                label={label}
                placeholder={placeholder}
                value={value}
                required={required}
            />
        );
    };

    renderCheckboxField = (params: InputFieldParams, props) => {
        const { formValues } = this.state;

        const { name, onChange, width, noLabel = false, defaultLabel = name, defaultCheckboxLabel = name, toggle = false } = params;

        const { t, translationId = 'generic', formId } = props;

        const labelKey = `${translationId}.${name}.label`;
        let label = t(labelKey);
        if (label === labelKey) {
            label = defaultLabel;
        }

        const checkboxLabelKey = `${translationId}.${name}.checkboxLabel`;
        let checkboxLabel = t(checkboxLabelKey);
        if (checkboxLabel === checkboxLabelKey) {
            checkboxLabel = defaultCheckboxLabel;
        }
        return (
            <Form.Field width={width || 16}>
                {!noLabel && (
                    <label htmlFor={labelKey}>
                        <Trans i18nKey={labelKey}>{label}</Trans>
                    </label>
                )}
                <Form.Checkbox
                    error={formValues[name].error}
                    name={name}
                    id={`${formId.toLowerCase()}-${name}`}
                    onChange={(_event, data) => {
                        if(onChange) {
                            onChange(_event, data);
                        }else {
                            this.handleChange(_event, data);
                        }
                    }}
                    label={checkboxLabel}
                    toggle={toggle}
                    checked={formValues[name].value}
                    required={formValues[name].required}
                    style={{ margin: '10px 0 0' }}
                />
            </Form.Field>
        );
    };

    renderDropdownField = (params, props) => {
        const { formValues } = this.state;

        const { name, type, onChange, width, options } = params;
        const { t, translationId = 'generic', formId } = props;

        //console.log('renderDropdownField: state.formValues', formValues);
        //console.log('renderDropdownField: params', params);

        return (
            <Form.Dropdown
                width={width || 16}
                error={formValues[name].error}
                type={type}
                name={name}
                id={`${formId.toLowerCase()}-${name}`}
                onChange={(_event, data) => {
                    if(onChange) {
                        onChange(_event, data);
                    }else {
                        this.handleChange(_event, data);
                    }
                }}
                selection
                options={options}
                label={t(`${translationId}.${name}.label`)}
                placeholder={t(`${translationId}.${name}.placeholder`)}
                value={formValues[name].value}
                required={formValues[name].required}
            />
        );
    };

    renderTextAreaField = (params, props) => {
        const { formValues } = this.state;

        const { name, onChange } = params;

        const { t, translationId = 'generic', formId } = props;

        return (
            <Form.TextArea
                error={formValues[name].error}
                name={name}
                id={`${formId.toLowerCase()}-${name}`}
                onChange={(_event, data) => {
                    if(onChange) {
                        onChange(_event, data);
                    }else {
                        this.handleChange(_event, data);
                    }
                }}
                label={t(`${translationId}.${name}.label`)}
                placeholder={t(`${translationId}.${name}.placeholder`)}
                value={formValues[name].value}
                required={formValues[name].required}
            />
        );
    };
}
