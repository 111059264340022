// @flow
import React from 'react';
import { Icon, Step } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    step: number,
    stepCompleted: number,
    changeStep: Function
};

function CheckoutSteps({ step, stepCompleted, changeStep }: Props) {
    return (
        <Step.Group fluid className='checkout-steps'>
            <Step onClick={() => changeStep(1)} disabled={stepCompleted < 1} completed={stepCompleted > 1} active={step === 1}>
                <Icon name='truck' />
                <Step.Content>
                    <Step.Title>
                        <Trans i18nKey='checkout.steps.delivery.title'>Delivery</Trans>
                    </Step.Title>
                    <Step.Description>
                        <Trans i18nKey='checkout.steps.delivery.description'>Option selected by merchant</Trans>
                    </Step.Description>
                </Step.Content>
            </Step>
            <Step onClick={() => changeStep(2)} disabled={stepCompleted < 2} completed={stepCompleted > 2} active={step === 2}>
                <Icon name='credit card' />
                <Step.Content>
                    <Step.Title>
                        <Trans i18nKey='checkout.steps.payment.title'>Payment</Trans>
                    </Step.Title>
                    <Step.Description>
                        <Trans i18nKey='checkout.steps.payment.description'>Pay with card/bank transfer</Trans>
                    </Step.Description>
                </Step.Content>
            </Step>
            <Step onClick={() => changeStep(3)} disabled={stepCompleted < 3} completed={stepCompleted > 3} active={step === 3}>
                <Icon name='info' />
                <Step.Content>
                    <Step.Title>
                        <Trans i18nKey='checkout.steps.review.title'>Review</Trans>
                    </Step.Title>
                    <Step.Description>
                        <Trans i18nKey='checkout.steps.review.description'>Verify order details</Trans>
                    </Step.Description>
                </Step.Content>
            </Step>
        </Step.Group>
    );
}

export default withTranslation('translations')(CheckoutSteps);
