// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    cartItems: []
};

function addCartItem(state, action) {
    const { result } = action.payload;
    const { updatedCartItems, ...cartState } = result;
    const newCartItems = updatedCartItems.filter((id) => !state.cartItems.includes(id));
    return {
        ...state,
        ...cartState,
        cartItems: state.cartItems.concat(newCartItems)
    };
}

function deleteCartItem(state, action) {
    const { result } = action.payload;
    const { updatedCartItems, cartItemId, ...cartState } = result;
    const cartItems = state.cartItems.filter((id) => id !== cartItemId);
    return {
        ...state,
        ...cartState,
        cartItems
    };
}

function loadCartState(state, action) {
    const { result } = action.payload;
    const { updatedCartItems, ...cartState } = result;
    return {
        ...state,
        ...cartState,
        errorMessage: null
    };
}

export function cart(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.ADD_CART_ITEM:
            if (action.status === 'success') {
                return addCartItem(state, action);
            }
            break;

        case ActionTypes.DELETE_CART_ITEM:
            if (action.status === 'success') {
                return deleteCartItem(state, action);
            }
            break;

        case ActionTypes.LOAD_CART_ITEMS:
        case ActionTypes.UPDATE_CART_ITEM:
        case ActionTypes.SET_CART_ADDRESS:
        case ActionTypes.SET_CART_PROMO_CODE:
        case ActionTypes.DELETE_CART_PROMO_CODE:
            if (action.status === 'success') {
                return loadCartState(state, action);
            }
            if (action.status === 'error') {
                if (action.error.code === 402) {
                    return {
                        ...state,
                        errorMessage: action.error.messages[0]
                    };
                }
            }
            break;
        case ActionTypes.CHECK_PAYMENT_STATUS: {
            if (action.status === 'success') {
                return loadCartState(state, action);
            }
            break;
        }
        case ActionTypes.ADD_ADDRESS:
        case ActionTypes.DELETE_ADDRESS:
            if (action.status === 'success') {
                return {
                    ...state,
                    addresses: action.payload.result
                };
            }
            break;
        // Reset cart store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default cart;
