// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export const MARK_ALL_USER_MESSAGES_AS_READ = 'MARK_ALL_USER_MESSAGES_AS_READ';
export const markAllUserMessagesAsRead = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: MARK_ALL_USER_MESSAGES_AS_READ,
        endpoint: '/v3/markallusermessagesasread',
        method: 'POST',
        authentication: Authentication.User,
        body: {},
        schema: {}
    }
}));
