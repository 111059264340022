// @flow
import React from 'react';
import { Form, Header } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm, { FormFieldData, createEmptyFormFieldData } from 'components/CommonForm';

type Props = {
    t: Function,
    updateFormValues: Function,
    formId: string
};

type State = {
    errorMessage: ?string,
    price: FormFieldData<number>,
    allowNegotiations: FormFieldData<boolean>,
    isOpenToCustomers: FormFieldData<boolean>
};

const defaultValuesFromMerchant = ({ merchant }) => {
    const { allowNegotiate: allowNegotiations, openToCustomer: isOpenToCustomers } = merchant;

    return {
        allowNegotiations: createEmptyFormFieldData({ value: allowNegotiations === 1 || allowNegotiations === true, type: 'boolean' }),
        isOpenToCustomers: createEmptyFormFieldData({ value: isOpenToCustomers === 1 || isOpenToCustomers === true, type: 'boolean' })
    };
};

class MyStoreFormStoreOptions extends CommonForm<Props, State> {
    constructor(props) {
        super(props);

        const { merchant, updateFormValues, formId } = props;

        let formValues = {};

        if (merchant) {
            formValues = defaultValuesFromMerchant({ merchant });
        } else {
            formValues = {
                allowNegotiations: createEmptyFormFieldData({ value: true, type: 'boolean' }),
                isOpenToCustomers: createEmptyFormFieldData({ value: true, type: 'boolean' })
            };
        }
        this.state = {
            formValues
        };
        // updateFormValues({ formValues, formId });
    }

    render() {
        return (
            <Form.Field>
                <Form.Field>
                    <Header as='h2'>
                        <Trans i18nKey='myStore.settings.form.price.header'>Options</Trans>
                    </Header>
                </Form.Field>
                {this.renderCheckboxField({ name: 'allowNegotiations', toggle: true, type: 'boolean' }, this.props)}

                {this.renderCheckboxField({ name: 'isOpenToCustomers', toggle: true, type: 'boolean' }, this.props)}

                {/* <Form.Field error={state.name.error}>
          <label htmlFor="my-store-settings-form-store-vat">
            <Trans i18nKey="myStore.settings.form.price.vat.label">Negotiations</Trans>
            <Popup
              horizontaloffset={10}
              trigger={
                <Label
                  className="inline-label-icon"
                  circular
                  size="mini"
                >
                  <Icon name="question" />
                </Label>
              }
            >
              <Popup.Header>
                <Trans i18nKey="createStore.form.options.negotiations.popup.header">Negotiations</Trans>
              </Popup.Header>
              <Popup.Content>
                <Trans i18nKey="myStore.settings.images.information">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna.</Trans>
              </Popup.Content>
            </Popup>
          </label>
          <Checkbox
            toggle
            name="allowNegotiations"
            id="my-store-settings-form-store-vat"
            label="Allow shoppers to negotiate their price"
          />
        </Form.Field> */}
                {/* <Form.Field error={state.slug.error}>
          <label htmlFor="my-store-settings-form-store-vat">
            <Trans i18nKey="myStore.settings.form.price.vat.label">Store Status</Trans>
            <Popup
              horizontaloffset={10}
              trigger={
                <Label
                  className="inline-label-icon"
                  circular
                  size="mini"
                >
                  <Icon name="question" />
                </Label>
              }
            >
              <Popup.Header>
                <Trans i18nKey="createStore.form.businessType.business.popup.header">Store Status</Trans>
              </Popup.Header>
              <Popup.Content>
                <Trans i18nKey="myStore.settings.images.information">Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Maecenas sed diam eget risus varius blandit sit amet non magna.</Trans>
              </Popup.Content>
            </Popup>
          </label>
          <Checkbox
            toggle
            name="isOpenToCustomers"
            id="my-store-settings-form-store-vat"
            label="Open to customers"
          />
        </Form.Field> */}
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(MyStoreFormStoreOptions);
