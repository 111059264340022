// @flow
import { compose } from 'redux';
import * as Schemas from './schemas';
import {
    API_DATA,
    Authentication
    // ErrorCallback,
    // SuccessCallback
} from './constants';
import apiMiddleware from './middleware';

// declare type SuccessCallback = () => void;
// declare type ErrorCallback = (Error) => void;

/**
 * Utility which wraps an API action creator (taking an object with
 * parameters, returning an action with [API_DATA] defined) such that it
 * receives optional onSuccess/onError callbacks as second and third parameter.
 */
function wrapAPIActionCreator<T>(actionCreator: (T) => any) {
    return (params: T, onSuccess: () => void = function () {}, onError: (Error) => void = function () {}) => {
        const action = actionCreator(params);
        return {
            ...action,
            [API_DATA]: {
                ...action[API_DATA],
                callbacks: { onSuccess, onError }
            }
        };
    };
}

/**
 * Utility which wraps an API action with onSuccess/onError callbacks,
 * composing it with `dispatch` and returning a bound action creator which
 * returns a promise instead.
 */
function wrapAPIDispatch(dispatch: (any) => void, actionCreator) {
    return (params) => new Promise(compose(dispatch, actionCreator).bind(null, params));
}

export { Schemas, API_DATA, Authentication, apiMiddleware, wrapAPIDispatch, wrapAPIActionCreator };
