// @flow
import React, { PureComponent } from 'react';
import { Container, Loader } from 'semantic-ui-react';
import type { User } from 'store/types';

import Store from '../../components/Store';
// Hiding message tab for now, it is mostly working tho
// import StoreMessages from './components/StoreMessages';

type Props = {
    merchant: User,
    storeItems: number[],
    loadMoreItems: () => void
};

class StoreView extends PureComponent<Props> {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    // UNSAFE_componentWillMount() {
    //   window.addEventListener('resize', this.handleWindowSizeChange);
    // }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { merchant, storeItems } = this.props;
        if (merchant === null || !storeItems) {
            return (
                <Container>
                    <Loader active inline='centered' />
                </Container>
            );
        }
        return <Store {...this.props} width={this.state.width} />;
    }
}

export default StoreView;
