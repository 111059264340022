import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export const PLACE_ORDER_MONRI = 'PLACE_ORDER_MONRI';
export const placeOrderMonri = wrapAPIActionCreator(({ channel = DeviceTypes.WEB } = {}) => ({
    [API_DATA]: {
        type: PLACE_ORDER_MONRI,
        endpoint: '/v4/cart_items/place_order_monri',
        endpointParams: {
            orderFrom: channel
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {},
        schema: {
            cart: Schemas.cart
        }
    }
}));

export default placeOrderMonri;
