// @flow
import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export type PlaceOrderParams = {
    channel?: $Values<typeof DeviceTypes>
};

export const PLACE_ORDER = 'PLACE_ORDER';
export const placeOrder = wrapAPIActionCreator(({ channel = DeviceTypes.WEB } = {}) => ({
    [API_DATA]: {
        type: PLACE_ORDER,
        endpoint: '/v4/cart_items/place_order',
        endpointParams: {
            orderFrom: channel
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {},
        schema: {
            cart: Schemas.cart
        }
    }
}));

export default placeOrder;
