// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import Messages from 'components/Messages';

function MyAgentInboxTabView(props) {
    return <Messages {...props} />;
}

export default withTranslation('translations')(MyAgentInboxTabView);
