// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { Segment } from 'semantic-ui-react';

import ProductDetailListItem from './components/ProductDetailListItem';

type Props = {
    type: 'item' | 'order' | 'purchase',
    tab: string,
    idList: [number]
};

function ProductDetailList({ type, idList, tab, authentication }: Props) {
    if (!(idList && idList.length)) {
        return null;
    }

    const orderListColumns = [
        {
            key: 'order',
            title: 'Order'
        },
        {
            key: 'date',
            title: 'Date'
        },
        {
            key: type === 'order' ? 'shopper' : 'store',
            title: type === 'order' ? 'Shopper' : 'Store'
        },
        {
            key: 'total',
            title: 'Total'
        },
        {
            key: 'payment',
            title: 'Payment'
        },
        {
            key: 'quantity',
            title: 'Quantity'
        },
        {
            key: 'fulfillment',
            title: 'Fulfillment'
        },
        {
            key: 'shippingCarrier',
            title: 'Shipping Carrier'
        }
    ];

    const productListColumns = [
        {
            key: 'product',
            title: 'Product'
        },
        {
            key: 'status',
            title: 'Status'
        },
        {
            key: 'inventory',
            title: 'Inventory'
        },
        {
            key: 'sold',
            title: 'Sold'
        },
        {
            key: 'price',
            title: 'Price'
        },
        {
            key: 'category',
            title: 'Category'
        }
    ];

    const columns = type === 'item' ? productListColumns : orderListColumns;

    return (
        <div>
            <Segment.Group className='simple'>
                <div className='table-header custom-table-header' style={{ display: 'flex' }} >
                    {columns &&
                        columns.map((c, index) => (
                            <div key={c.key} style={{ width: type === 'item' && index === 0 ? `50%` : type === 'item' ? `${50 / columns.length}%` : `${100 / columns.length}%`, margin: 'auto', whiteSpace: 'nowrap', textAlign: type === 'item' ? 'unset' : 'center'}}>
                                {type === 'item' ? (
                                    <Trans i18nKey={`myStore.items.itemsListCol.${c.key}`}>{c.title}</Trans>
                                ) : (
                                    <Trans i18nKey={`myStore.orders.ordersListCol.${c.key}`}>{c.title}</Trans>
                                )}
                            </div>
                        ))}
                </div>
                {idList.map((id) => (
                    <ProductDetailListItem key={id} {...{ type, id, tab, authentication }} />
                ))}
            </Segment.Group>
        </div>
    );
}

export default withTranslation('translations')(ProductDetailList);
