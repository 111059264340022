// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Tab, Menu, Label } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { formatBigNumber } from 'utilities/format';

import FollowerTab from 'components/FollowerTab';
import FollowingTab from 'components/FollowingTab';
import MyStoreDashboard from '../../scenes/MyStore/components/MyStoreDashboard';

const StoreTabBarView = ({ merchant, follow }) => {
    const { totalFollowers } = merchant;


    const tabs = [
        {
            name: 'dashboard',
            label: <Trans i18nKey='myStore.tab.dashboard'>Dashboard</Trans>,
            renderElement: () => <MyStoreDashboard />
        },
        {
            name: 'followers',
            label: <Trans i18nKey='store.tab.followers'>Followers</Trans>,
            count: formatBigNumber(totalFollowers),
            renderElement: () => <FollowerTab user={merchant} />
        },
    ];

    return (
        <Grid.Row>
            <Grid.Column>
                <Tab
                    menu={{ secondary: true, pointing: true }}
                    panes={tabs.map((tab) => ({
                        menuItem: (
                            <Menu.Item key={tab.name}>
                                {tab.label}
                                {tab.name === 'dashboard' ? null : <Label>{tab.count}</Label>}
                            </Menu.Item>
                        ),
                        render: () => tab.renderElement()
                    }))}
                />
            </Grid.Column>
        </Grid.Row>
    );
};

export default StoreTabBarView;
