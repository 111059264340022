import React, { useState, useEffect } from 'react';
import { Icon, Image, Label } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import agentIcon from '../../../../../../../../styles/assets/icons/agent-icon.svg';
import customerIcon from '../../../../../../../../styles/assets/icons/customer-icon.svg';
import storeIcon from '../../../../../../../../styles/assets/icons/store-icon.svg';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import { history } from 'store';
import SubBar from '../../../SubBar';
import { Link, useLocation } from 'react-router-dom';

const user = ({ isLoggedIn, user, merchant, onLogout, totalUnreadMessage, t }) => {
    const activeProfile = localStorage.getItem('activeProfile');
    const [visible, setVisible] = useState(false);
    const location = useLocation();
    useEffect(() => {
        setVisible(false);
    }, [location]);

    const menuItems = () => {
        if (!(user && isLoggedIn)) {
            return (
                <div onMouseLeave={() => setVisible(!visible)}>
                    <div className='dd-profile-bar'>
                        <div className='dd-profile-item'>{t('sidebar.profile.guest', { defaultValue: 'Guest' })}</div>
                        <div onClick={() => history.push('/login')} className='switch-profile-item'>
                            {t('sidebar.profile.logIn')}
                        </div>
                    </div>

                    <SubBar showPromo={false} asDropdown={true} />
                </div>
            );
        } else if (user && isLoggedIn) {
            return (
                <div onMouseLeave={() => setVisible(!visible)}>
                    <div className='dd-profile-bar'>
                        {activeProfile === 'Merchant' ? (
                            <div onClick={() => history.push('/my-store')} className='dd-profile-item'>
                                {user.entity && user.entity.alias}
                            </div>
                        ) : (
                            activeProfile === 'Customer' && (
                                <div onClick={() => history.push(`/${user.entity.slug}`)} className='dd-profile-item'>
                                    {user.entity && user.entity.alias}
                                </div>
                            )
                        )}
                        <div onClick={() => history.push('/select-profile')} className='switch-profile-item'>
                            {merchant.entity ? (
                                <>
                                    <Icon name='exchange' size='small' color='blue' />
                                    {t('sidebar.profile.switch', {
                                        defaultValue: 'Switch profiles'
                                    })}
                                </>
                            ) : (
                                <>
                                    <Icon name='info circle' color='blue' size='small' />
                                    {t('sidebar.profile.becomeMerchant', {
                                        defaultValue: 'Create your Merchant Account'
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                    {activeProfile === 'Agent' ? (
                        <>
                            <div className='dd-item' onClick={() => history.push('/agent-office')}>
                                {t('sidebar.user.agentOffice', {
                                    defaultValue: 'Agent Office'
                                })}
                            </div>
                            {/* <div style={messagesWrapper}> */}
                            <div className='dd-item' onClick={() => history.push('/my-agent/inbox')}>
                                {t('sidebar.user.messages', { defaultValue: 'Messages' })}
                            </div>
                            {/* <div style={messagesNotification}>8</div> */}
                            {/* </div> */}
                            <div className='dd-item' onClick={() => history.push('/agent-settings')}>
                                {t('sidebar.profile.accSettings', {
                                    defaultValue: 'Account Settings'
                                })}
                            </div>
                            <div className='dd-item'>
                                <a className='external-link' href='http://www.mybazar.zendesk.com/' target='_blank' rel='noreferrer'>
                                    {t('sidebar.user.agentSupport', {
                                        defaultValue: 'Agent Support'
                                    })}
                                </a>
                            </div>
                        </>
                    ) : activeProfile === 'Merchant' ? (
                        <>
                            <div onClick={() => history.push('/my-store')} className='dd-item'>
                                {t('sidebar.profile.merchantOffice', {
                                    defaultValue: 'Merchant Office'
                                })}
                            </div>
                            <div onClick={() => history.push('/my-store/inbox')} className='dd-item'>
                                {t('sidebar.profile.messages', { defaultValue: 'Messages' })}
                                {totalUnreadMessage > 0 && <div className='message-indicator'>{totalUnreadMessage}</div>}
                            </div>

                            {/* <div style={messagesStyle}>8</div> */}

                            <div onClick={() => history.push('/my-store/orders/open')} className='dd-item'>
                                {t('sidebar.profile.orders', { defaultValue: 'Orders' })}
                            </div>

                            <div className='dd-item' onClick={() => history.push('/my-store/items')}>
                                {t('sidebar.profile.products', { defaultValue: 'Products' })}
                            </div>

                            <div className='dd-item' onClick={() => history.push('/sell-item')}>
                                {t('sidebar.profile.addProduct', {
                                    defaultValue: 'Add product'
                                })}
                            </div>

                            <div className='dd-item' onClick={() => history.push('/store-settings')}>
                                {t('sidebar.profile.accSettings', {
                                    defaultValue: 'Account Settings'
                                })}
                            </div>
                        </>
                    ) : (
                        activeProfile === 'Customer' && (
                            <>
                                <SubBar showPromo={false} asDropdown={true} />
                                <div onClick={() => history.push('/my-profile/inbox')} className='dd-item'>
                                    {t('users.details.messages', { defaultValue: 'Messages' })}
                                    {totalUnreadMessage > 0 && <div className='message-indicator'>{totalUnreadMessage}</div>}
                                </div>

                                <div onClick={() => history.push('/my-profile/purchases')} className='dd-item'>
                                    {t('users.details.purchases', {
                                        defaultValue: 'Purchases'
                                    })}
                                </div>

                                {/* <div className='dd-item' onClick={() => history.push('/my-profile')}>
                                    {t('users.details.profileSettings', {
                                        defaultValue: 'Profile Settings'
                                    })}
                                </div> */}

                                <div className='dd-item' onClick={() => history.push('/my-profile/settings')}>
                                    {t('sidebar.profile.accSettings', {
                                        defaultValue: 'Account Settings'
                                    })}
                                </div>
                                <div className='dd-item'>
                                    <Link className='external-link' to='/information/customer-support'>
                                        {t('users.details.customerSupport', {
                                            defaultValue: 'Customer Support'
                                        })}
                                    </Link>
                                </div>
                            </>
                        )
                    )}

                    <div
                        className='dd-item'
                        onClick={() => {
                            localStorage.setItem('activeProfile', 'Customer');
                            onLogout();
                        }}
                    >
                        {t('users.details.signOut', { defaultValue: 'Sign Out' })}
                    </div>
                </div>
            );
        }
    };

    const menu = <div className='dd-menu'>{menuItems()}</div>;

    const getSrc = () => {
        if (activeProfile === 'Merchant') {
            const pictureThumb = merchant?.entity?.pictureThumb || storeIcon;
            return pictureThumb;
        } else if (activeProfile === 'Agent') {
            return agentIcon || customerIcon;
        } else if (activeProfile === 'Customer') {
            const pictureThumb = user?.entity?.pictureThumb || customerIcon;
            return pictureThumb;
        }
        return customerIcon;
    };

    return (
        <div style={{ position: 'relative' }}>
            {totalUnreadMessage > 0 && (
                <Label className='message-indicator-header' floating size='mini' circular>
                    {totalUnreadMessage}
                </Label>
            )}
            <Dropdown onClick={() => setVisible(!visible)} overlay={menu} visible={visible} overlayClassName={'test'}>
                {!(user && isLoggedIn) ? (
                    <div className='top-bar-profile-circle-icon'>
                        <Icon name='user' fitted size='large' style={{ color: '#888888' }} />
                    </div>
                ) : (
                    <div className='top-bar-profile-circle'>
                        <Image src={getSrc()} circular />
                    </div>
                )}
            </Dropdown>
        </div>
    );
};

export default withTranslation('translations')(user);
