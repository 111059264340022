// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Divider, Grid, Message } from 'semantic-ui-react';

import CartList from 'components/CartList';
import type { Cart as CartT } from 'store/types';
import CartCheckout from './components/CartCheckout';

type Props = {
    cart: CartT,
    isLoading: boolean
};

function Cart({ cart, isLoading }: Props) {
    const { message, forStep } = cart.error || {};
    const { errorMessage, totalShipping, totalVat, subtotal, shippableItemsCount } = cart;
    const isErrorRelevant = cart.error && forStep <= 1;

    const handleErrorMessage = (message: string) => {
        switch (message) {
            case 'Cart is empty':
                return 'Korpa je prazna.';
            case 'Please remove all unavailable items':
                return 'Molimo uklonite sve nedostupne artikle.';
            default:
                return message;
        }
    };

    return (
        <div className='cart'>
            <Grid>
                <Grid.Column>
                    <Grid stackable>
                        <Grid.Column width={10}>
                            {errorMessage && <Message error>{handleErrorMessage(errorMessage)}</Message>}
                            {cart.error && isErrorRelevant && <Message error>{handleErrorMessage(message)}</Message>}
                            <CartList list={cart.cartItems} isLoading={isLoading} controls={['remove', 'quantity']} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <CartCheckout itemCount={shippableItemsCount} total={cart.grandTotalPrice} shippingFee={totalShipping} vat={totalVat} subtotal={subtotal} />
                        </Grid.Column>
                    </Grid>

                    {/*
                    <Grid.Column width={16}>
                        {errorMessage && <Message error>{errorMessage}</Message>}
                        {cart.error && isErrorRelevant && <Message error>{message}</Message>}
                        <CartList isCart list={cart.cartItems} isLoading={isLoading} controls={['remove', 'quantity']} />
                        <Divider />
                        <CartCheckout itemCount={shippableItemsCount} total={cart.grandTotalPrice} shippingFee={totalShipping} vat={totalVat} subtotal={subtotal} />
                    </Grid.Column>
                         */}
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default withTranslation('translations')(Cart);
