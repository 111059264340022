// @flow
import React from 'react';
import { Grid, Segment, Header, Button, Divider, List, Icon, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { EntityData, UserDeliveryAddress, CartItem } from 'store/types';
import { formatPrice } from 'utilities/format';

import CheckoutDetailsPCIDSS from '../CheckoutDetailsPCIDSS';
import CheckoutAddressDetails from '../CheckoutAddressDetails';
import { PaymentMethod } from '../../../../index';
import CheckoutCardDetails from '../CheckoutDetailsPayment/components/CheckoutCardDetails';
import * as actions from 'store/actions';

type Props = {
    subtotal: number,
    shippingFee: number,
    cart: {
        promoCode: string,
        displayDiscount: string,
        grandTotalPrice: number
    },
    cartItems: EntityData<CartItem>[],
    vat: number,
    nextStep: Function,
    deliveryAddress: ?EntityData<UserDeliveryAddress>,
    onClickSubmit: () => void,
    isSubmitLoading: boolean,
    monriComponents: any,
    cardName: string,
    setCardName: (string) => void,
    formValid: boolean,
    openModal: typeof actions.openModal
};

function CheckoutDetailsReview({
                                   cart,
                                   shippingFee,
                                   subtotal,
                                   vat,
                                   deliveryAddress,
                                   onClickSubmit,
                                   isSubmitLoading,
                                   cartItems,
                                   paymentError,
                                   monriComponents,
                                   paymentMethod,
                                   cardName,
                                   setCardName,
                                   formValid
                               }: Props) {
    const { promoCode, displayDiscount, grandTotalPrice, error } = cart;
    const isErrorRelevant = !!paymentError || (error && error.forStep <= 4) || !formValid;
    const itemCount = cartItems.reduce((count, { entity = { quantity: 0 } }) => count + entity.quantity, 0);

    return (
        <Segment className='checkout-details-review semi-simple'>
            <Grid className='tensed'>
                {!!paymentError && (
                    <Grid.Row>
                        <Grid.Column>
                            <Message negative>
                                <Message.Header>
                                    <Trans i18nKey='checkout.details.review.paymentError.message'>Error(s) encountered while processing payment</Trans>
                                </Message.Header>
                                {paymentError.messages && paymentError.messages.length && paymentError.messages.map(({ message }, index) => <p key={index}>{message}</p>)}
                            </Message>
                        </Grid.Column>
                    </Grid.Row>
                )}
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h2'>
                            <Trans i18nKey='checkout.details.review.title'>Review Order</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>{deliveryAddress && <CheckoutAddressDetails deliveryAddress={deliveryAddress} />}</Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(subtotal)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.subTotal' count={itemCount}>
                                        Subtotal ({{ count: itemCount }} items)
                                    </Trans>
                                    :
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(shippingFee)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.shippingFee'>Shipping Fee</Trans>:
                                </List.Content>
                            </List.Item>
                            {promoCode && (
                                <List.Item style={{ color: 'red' }}>
                                    <List.Content floated='right'>{displayDiscount}</List.Content>
                                    <List.Content>
                                        <Trans i18nKey='checkout.details.review.promoCode'>Promo Code</Trans>
                                        {': '}
                                        {promoCode}
                                    </List.Content>
                                </List.Item>
                            )}
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(vat)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.vat'>VAT</Trans>:
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='auto tensed'>
                            <Grid.Column>
                                <Header size='medium'>
                                    <Trans i18nKey='checkout.details.review.total'>Order Total</Trans>:
                                </Header>
                            </Grid.Column>
                            <Grid.Column className='col-text-align-right'>
                                <Header size='medium'>{formatPrice(grandTotalPrice)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                {paymentMethod === PaymentMethod.CreditCard && <CheckoutCardDetails name={cardName} setName={setCardName} monriComponents={monriComponents} />}
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button positive fluid icon disabled={isErrorRelevant || isSubmitLoading} onClick={onClickSubmit} loading={isSubmitLoading}>
                            <Trans i18nKey='checkout.details.review.button'>Place Order</Trans> <Icon name='check' />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CheckoutDetailsPCIDSS />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default withTranslation('translations')(CheckoutDetailsReview);
