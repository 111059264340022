// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid, Menu, Label, Dropdown, Tab } from 'semantic-ui-react';
import { Trans } from 'react-i18next';
import { formatBigNumber } from 'utilities/format';
import { shortBy } from 'utilities/languages';
import FollowerTab from 'components/FollowerTab';
import FollowingTab from 'components/FollowingTab';
import LikesTab from 'components/LikesTab';

const ProfileTabBarView = ({ user, follow, width }) => {
    const { totalFollowers, totalFollowing, totalItemsLiked } = user;
    const tabs = [
        {
            name: 'likes',
            label: <Trans i18nKey='store.tab.likes'>Likes</Trans>,
            count: formatBigNumber(totalItemsLiked),
            renderElement: () => <LikesTab user={user} />
        },
        {
            name: 'followers',
            label: <Trans i18nKey='store.tab.followers'>Followers</Trans>,
            count: formatBigNumber(totalFollowers),
            renderElement: () => <FollowerTab user={user} />
        },
        {
            name: 'following',
            label: <Trans i18nKey='store.tab.followings'>Following</Trans>,
            count: formatBigNumber(totalFollowing),
            renderElement: () => <FollowingTab user={user} />
        }
    ];

    const isMobile = width <= 500;
    const menuProps = { secondary: true };
    if (isMobile) {
        menuProps.widths = tabs.length;
    }

    return (
        <Grid
            style={{
                width: '100%'
            }}
        >
            <Grid.Row only='computer tablet'>
                <div
                    className='displayNone wrapcss'
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <div style={{ width: '100%' }}>
                        <Tab
                            style={{ width: '100%' }}
                            menu={menuProps}
                            panes={tabs.map((tab) => ({
                                menuItem: (
                                    <Menu.Item key={tab.name}>
                                        {tab.label}
                                        <Label>{tab.count}</Label>
                                    </Menu.Item>
                                ),
                                render: () => tab.renderElement()
                            }))}
                        />
                    </div>
                    {/*<div style={{ width: 200, marginLeft: -200 }}>*/}
                    {/*    <Dropdown fluid selection options={shortBy} defaultValue='Sortiraj po' placeholder='Sortiraj po' />*/}
                    {/*</div>*/}
                </div>
            </Grid.Row>
            <Grid.Row only='mobile'>
                <Grid.Column>
                    {/*<div style={{ width: 165, marginBottom: 10 }}>*/}
                    {/*    <Dropdown fluid selection options={shortBy} defaultValue='Sortiraj po' placeholder='Sortiraj po' />*/}
                    {/*</div>*/}
                    <div
                        className='displayNone wrapcss'
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div style={{ width: '-webkit-fill-available' }}>
                            <Tab
                                menu={menuProps}
                                panes={tabs.map((tab) => ({
                                    menuItem: (
                                        <Menu.Item key={tab.name}>
                                            {tab.label}
                                            <Label>{tab.count}</Label>
                                        </Menu.Item>
                                    ),
                                    render: () => tab.renderElement()
                                }))}
                            />
                        </div>
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default ProfileTabBarView;
