// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import type { Item, Variant } from 'store/types';

import { getVariant, getUser } from 'store/utilities';
import ProductView from './ProductView';

type Props = {
    product: Item,
    variants: Variant[]
};

type State = {
    selectedVariant: ?number
};

class ProductContainer extends Component<Props, State> {
    componentDidMount() {
        const { loadUser, product } = this.props;
        const merchantId = product?.userId;

        if (merchantId) {
            loadUser({ id: merchantId, fields: ['cover_photos', 'user_settings'] }).catch((err) => console.error(err));
        }
    }

    constructor(props: Props) {
        super(props);
        const { variants } = props.product;
        this.state = {
            selectedVariant: variants.length > 0 ? variants[0] : null
        };
    }

    handleChangeVariant = (selectedVariant: ?number) => {
        this.setState({ selectedVariant });
    };

    render() {
        const { ...rest } = this.props;

        const { selectedVariant } = this.state;
        return <ProductView onChangeVariant={this.handleChangeVariant} {...{ selectedVariant }} {...rest} />;
    }
}

const mapStateToProps = (state, { product }) => ({
    user: state.userData.user,
    variants: product.variants.map((id) => getVariant(state, id)),
    merchant: getUser(state, product.merchant)
});

const mapDispatchToProps = (dispatch) => ({
    loadUser: wrapAPIDispatch(dispatch, actions.loadUser)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProductContainer);
