// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getEntityDataBySlug } from 'store/utilities';
import { Redirect } from 'react-router';
import LandingView from './LandingView';
import loadCategoryTree from "../../store/actions/category/loadCategoryTree";

type Props = {
    /** slug of landing page to fetch (provide this or tab) */
    slug?: string,
    /** display tab of landing page to fetch (legacy support) */
    tab?: string,
    channel?: string,
    category?: string,
    // from store
    loadLandingComponents: typeof actions.loadLandingComponents,
    loadLandingPage: typeof actions.loadLandingPage,
    loadCategoryTree: typeof actions.loadCategoryTree,
    bazar?: string
};

async function loadData({ slug, tab = 'discover', channel, category, loadLandingComponents, loadLandingPage , loadCategoryTree ,  bazar }: Props) {
    if (slug != null) {
        loadLandingPage({ slug, channel, bazar });
        loadCategoryTree({ bazar });
    } else {
        loadLandingComponents({ tab, channel, category });
    }
}

class LandingContainer extends Component<Props> {
    componentDidMount() {
        loadData(this.props);
        if (this.props.bazar.length === 0) {
            this.props.setLandingPageCategories(null);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.tab !== this.props.tab || nextProps.category !== this.props.category || nextProps.slug !== this.props.slug) {
            loadData(nextProps);
        }
    }

    componentDidUpdate() {
        if (this.props.landingPage?.entity?.categories) {
            this.props.setLandingPageCategories(this.props.landingPage?.entity?.categories ?? null);
        }
    }

    render() {
        // const authentifiedMerchant = localStorage.getItem('authentifiedMerchant');
        // if (authentifiedMerchant !== '1') {
        //     return <Redirect to='/password' />;
        // }
        return <LandingView {...this.props} />;
    }
}

const mapStateToProps = (state, ownProps) => {
    let landingPage;
    let bazar = '';
    if (state.router.location.query.bazar) {
        bazar = state.router.location.query.bazar;
    }
    if (ownProps.slug != null) {
        landingPage = getEntityDataBySlug(state, ownProps.slug);
    } else {
        landingPage = {
            isFetching: false,
            entity: state.landingPages[ownProps.tab || 'discover']
        };
    }

    if (landingPage.isFetching || landingPage.entity == null || landingPage.entity.components == null) {
        return { isLoading: true, components: [], bazar: bazar };
    }

    return {
        landingPage,
        components: landingPage.entity.components,
        bazar: bazar
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadLandingComponents: wrapAPIDispatch(dispatch, actions.loadLandingComponents),
    loadLandingPage: wrapAPIDispatch(dispatch, actions.loadLandingPage),
    loadCategoryTree: wrapAPIDispatch(dispatch , actions.loadCategoryTree),
    setLandingPageCategories: (categories) => dispatch(actions.setLandingPageCategories(categories))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LandingContainer);
