// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_ITEM_BY_ITEM_ID ------------------
export type LoadItemByItemIdWithUserAuth = {
    itemId: number,
    fields?: ('pictures' | 'merchant' | 'store' | 'is_liked' | 'comments')[]
};

export const LOAD_ITEM_BY_ITEM_ID_WITH_USER_AUTH = 'LOAD_ITEM_BY_ITEM_ID_WITH_USER_AUTH';
export const loadItemByItemIdWithUserAuth = wrapAPIActionCreator(({ itemId, fields = [] }: LoadItemByItemIdWithUserAuth) => ({
    [API_DATA]: {
        type: LOAD_ITEM_BY_ITEM_ID_WITH_USER_AUTH,
        endpoint: `/v3/items/${itemId}`,
        optionalAuthentication: Authentication.User,
        endpointParams: { fields: fields.join(',') },
        schema: {
            item: Schemas.item
        }
    }
}));

export default loadItemByItemIdWithUserAuth;
