import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';
import { DeviceTypes } from 'store/enums';

export const ORDER_COD = 'ORDER_COD';
export const orderCoD = wrapAPIActionCreator(({ address }) => ({
    [API_DATA]: {
        type: ORDER_COD,
        endpoint: '/v4/cart_items/place_order_cod',
        endpointParams: {
            orderFrom: DeviceTypes.WEB
        },
        method: 'POST',
        authentication: Authentication.User,
        body: {
            payment_method: {
                type: 'COD',
            },
            transaction: {
                ch_address: [address.street, address.houseNo || ''].join(' ').trimEnd(),
                ch_city: address.city,
                ch_country: address.country,
                ch_zip: address.zip,
                ch_email: address.emailAddress,
                ch_full_name: "TEMP",
                ch_phone: address.contactNo
                //order_info: 'Testna trx'
            }
        },
        schema: {
            cart: Schemas.cart
        }
    }
}));

export default orderCoD;
