// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getCartItem, getItem, getStore, getUser, getOffer } from 'store/utilities';
import type { EntityData, CartItem, Item, Store, User } from 'store/types';

import View from './view';
import type { Control } from './index';

type Props = {
    id: number,
    controls: Control[],
    // from store
    cartItem: EntityData<CartItem>,
    item: EntityData<Item>,
    store: EntityData<Store>,
    merchant: EntityData<User>,
    deleteCartItem: typeof actions.deleteCartItem,
    updateCartItem: typeof actions.updateCartItem
};

type State = {
    isRemoving: boolean,
    isQuantityLoading: boolean,
    isDeliveryOptionLoading: boolean,
    isPaymentOptionLoading: boolean
};

class CartListItem extends Component<Props, State> {
    state = {
        isRemoving: false,
        isQuantityLoading: false,
        isDeliveryOptionLoading: false,
        isPaymentOptionLoading: false
    };

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    handleRemoveItem = () => {
        const { deleteCartItem, id } = this.props;
        this.setState({ isRemoving: true });
        deleteCartItem({ cartItemId: id }).then(
            () => this._isMounted && this.setState({ isRemoving: false }),
            () => this._isMounted && this.setState({ isRemoving: false })
        );
    };

    handleQuantityChange = (quantity) => {
        const { updateCartItem, id } = this.props;
        this.setState({ isQuantityLoading: true });
        updateCartItem({ cartItemId: id, quantity }).then(
            () => this._isMounted && this.setState({ isQuantityLoading: false }),
            () => this._isMounted && this.setState({ isQuantityLoading: false })
        );
    };

    handleDeliveryOptionChange = (delivery) => {
        const { updateCartItem, id } = this.props;
        this.setState({ isQuantityLoading: true });
        updateCartItem({ cartItemId: id, delivery }).then(
            () => this._isMounted && this.setState({ isDeliveryOptionLoading: false }),
            () => this._isMounted && this.setState({ isDeliveryOptionLoading: false })
        );
    };

    handlePaymentOptionChange = (payment) => {
        const { updateCartItem, id } = this.props;
        this.setState({ isQuantityLoading: true });
        updateCartItem({ cartItemId: id, payment }).then(
            () => this._isMounted && this.setState({ isPaymentOptionLoading: false }),
            () => this._isMounted && this.setState({ isPaymentOptionLoading: false })
        );
    };

    render() {
        const { deleteCartItem, updateCartItem, ...rest } = this.props;
        const { isRemoving, isQuantityLoading, isDeliveryOptionLoading, isPaymentOptionLoading } = this.state;
        return (
            <View
                onRemoveItem={this.handleRemoveItem}
                onQuantityChange={this.handleQuantityChange}
                onDeliveryOptionChange={this.handleDeliveryOptionChange}
                onPaymentOptionChange={this.handlePaymentOptionChange}
                {...{
                    isRemoving,
                    isQuantityLoading,
                    isDeliveryOptionLoading,
                    isPaymentOptionLoading
                }}
                {...rest}
            />
        );
    }
}

const mapStateToProps = (state, { id }) => {
    const cartItem = getCartItem(state, id);
    const { entity } = cartItem;
    const item = getItem(state, entity && entity.item);
    const offer = getOffer(state, entity && entity.offer);
    const store = getStore(state, item && item.entity && item.entity.store);
    const merchant = getUser(state, item && item.entity && item.entity.merchant);

    return {
        cartItem,
        offer,
        item,
        store,
        merchant
    };
};

const mapDispatchToProps = (dispatch) => ({
    deleteCartItem: wrapAPIDispatch(dispatch, actions.deleteCartItem),
    updateCartItem: wrapAPIDispatch(dispatch, actions.updateCartItem)
});

export default connect(mapStateToProps, mapDispatchToProps)(CartListItem);
