// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_AGENT_MESSAGES ------------------
export type LoadMyAgentMessagesParams = {
    limit: number,
    offset: number,
    conversationKey: string,
    typeId: number
};

export const LOAD_MY_AGENT_MESSAGES = 'LOAD_MY_AGENT_MESSAGES';
export const loadMyAgentMessages = wrapAPIActionCreator(({ limit = 999, offset = 0, conversationKey, typeId }: LoadMyAgentMessagesParams) => ({
    [API_DATA]: {
        type: LOAD_MY_AGENT_MESSAGES,
        endpoint: `/v3/messages/${conversationKey}`,
        endpointParams: { typeId, limit, offset },
        authentication: Authentication.Agent,
        method: 'GET',
        schema: [Schemas.message],
        reducerParams: {
            limit,
            offset,
            conversationKey,
            typeId
        }
    }
}));
