// @flow
import React, { PureComponent } from 'react';
import { Container, Grid, Header } from 'semantic-ui-react';

import { formatUsername } from 'utilities/format';
import type { User } from 'store/types';

import ShareButtons from 'components/ShareButtons';
import ProfileTabBar from 'components/ProfileTabBar';
import MyProfileCover from './components/MyProfileCover';
import MyProfilePicture from './components/MyProfilePicture';

type Props = {
    isLoggedIn: boolean,
    user: User
};

class MyProfileView extends PureComponent<Props> {
    render() {
        // const { user } = this.props;
        // const { alias, username } = user;
        return (
            // <Container>
            //     <Grid className='store'>
            //         <Grid.Row>
            //             <Grid.Column>
            //                 <MyProfileCover />
            //             </Grid.Column>
            //         </Grid.Row>
            //         <Grid.Row>
            //             <Grid.Column>
            //                 <Grid className='auto'>
            //                     <Grid.Column className='col-sm-16 col-shrink'>
            //                         <MyProfilePicture />
            //                     </Grid.Column>
            //                     <Grid.Column className='col-sm-16'>
            //                         <Header as='h1'>
            //                             {alias}
            //                             <Header.Subheader>{formatUsername(username)}</Header.Subheader>
            //                         </Header>
            //                     </Grid.Column>
            //                     <Grid.Column>
            //                         <ShareButtons name={`@${alias}`} />
            //                     </Grid.Column>
            //                 </Grid>
            //             </Grid.Column>
            //         </Grid.Row>
            //         <ProfileTabBar user={user} />
            //     </Grid>
            // </Container>
            <h1>Dovabr an</h1>
        );
    }
}

export default MyProfileView;
