// @flow

// UPDATE_IS_LOGGING_IN ------------------
export type SetIsLoggingIn = {
    isLoggingIn: boolean
};

export const UPDATE_IS_LOGGING_IN = 'UPDATE_IS_LOGGING_IN';
export const setIsLoggingIn = (isLoggingIn) => ({
    type: UPDATE_IS_LOGGING_IN,
    isLoggingIn
});
