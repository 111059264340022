// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

import { ItemWeightTypes } from '../item/addItem';

export type LookupShippingCostParams = {
    delivery: number,
    isMerchantCoverCost: boolean,
    payment: number,
    shipFromId: number, // Store id
    shipToId: number,
    weight: string,
    weightType: ItemWeightTypes.Grams | ItemWeightTypes.Kilograms
};

// LOOKUP_SHIPPING_COST ----------------------
export const LOOKUP_SHIPPING_COST = 'LOOKUP_SHIPPING_COST';
export const lookupShippingCost = wrapAPIActionCreator(({ delivery, isMerchantCoverCost, payment, shipFromId, shipToId, weight, weightType }: LookupShippingCostParams) => {
    const body = {
        delivery,
        isMerchantCoverCost,
        payment,
        shipFromId,
        shipToId,
        weight,
        weightType
    };
    return {
        [API_DATA]: {
            type: LOOKUP_SHIPPING_COST,
            endpoint: '/v3/shipping/cost',
            authentication: Authentication.Merchant,
            method: 'POST',
            body,
            reducerParams: body,
            schema: {},
            preprocess: ({ response }) => {
                const { estimateDayMin, estimateDayMax, ...rest } = response;
                const min = Math.min(estimateDayMin, estimateDayMax);
                const max = Math.max(estimateDayMin, estimateDayMax);
                return {
                    estimateDayMin: min,
                    estimateDayMax: max,
                    ...rest
                };
            }
        }
    };
});
