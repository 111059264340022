// @flow
import React from 'react';
import { Grid, Segment, Header, Button, Divider, List, Icon, Form, Checkbox } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import type { EntityData, UserDeliveryAddress } from 'store/types';
import { ModalNames } from 'store/actions';
import { formatPrice } from 'utilities/format';

import CheckoutDetailsPCIDSS from '../CheckoutDetailsPCIDSS';
import CheckoutAddressDetails from '../CheckoutAddressDetails';

import PromoCode from './components/PromoCode';
import { PaymentMethod } from 'components/Checkout';

type Props = {
    subtotal: number,
    shippingFee: number,
    cart: {
        promoCode: string,
        displayDiscount: string,
        grandTotalPrice: number
    },
    cartItems: EntityData<CartItem>[],
    vat: number,
    nextStep: Function,
    deliveryAddress: ?EntityData<UserDeliveryAddress>,
    deleteCartPromoCode: Function,
    onOpenAddPromoCode: Function,
    paymentMethod: Number,
    setPaymentMethod: Function,
    t: (string) => string
};

function CheckoutDetailsPayment(props: Props) {
    const { cart, shippingFee, vat, subtotal, nextStep, deleteCartPromoCode, onOpenAddPromoCode, deliveryAddress, cartItems, paymentMethod, setPaymentMethod, t } = props;
    const { promoCode, displayDiscount, grandTotalPrice, error } = cart;
    const isErrorRelevant = error && error.forStep <= 3;
    const itemCount = cartItems.reduce((count, { entity = { quantity: 0 } }) => count + entity?.quantity ?? 0, 0);

    const handleProceedClick = () => {
        nextStep();
    };

    const ENVIRONMENT = process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV || 'production';
    console.log({ ENVIRONMENT });

    return (
        <Segment className='checkout-details-payment semi-simple'>
            <Grid className='tensed'>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h2'>
                            <Trans i18nKey='checkout.details.payment.title'>Payment Information</Trans>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>{deliveryAddress && <CheckoutAddressDetails deliveryAddress={deliveryAddress} />}</Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(subtotal)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.subTotal' count={itemCount}>
                                        Subtotal ({{ count: itemCount }} items)
                                    </Trans>
                                    :
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(shippingFee)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.payment.shippingFee'>Shipping Fee</Trans>:
                                </List.Content>
                            </List.Item>
                            {promoCode && (
                                <List.Item style={{ color: 'red' }}>
                                    <List.Content floated='right'>{displayDiscount}</List.Content>
                                    <List.Content>
                                        <Button icon='x' negative compact size='tiny' onClick={deleteCartPromoCode} />{' '}
                                        <Trans i18nKey='checkout.details.payment.promoCode.label'>Promo Code</Trans>
                                        {': '}
                                        {promoCode}
                                    </List.Content>
                                </List.Item>
                            )}
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(vat)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.vat'>VAT</Trans>:
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Form>
                            <Form.Field>
                                <Checkbox
                                    radio
                                    label={t('checkout.details.payment.method.card', { defaultValue: 'Pay with credit card' })}
                                    name='checkboxRadioGroup'
                                    value={PaymentMethod.CreditCard}
                                    checked={paymentMethod === PaymentMethod.CreditCard}
                                    onChange={() => setPaymentMethod(PaymentMethod.CreditCard)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    radio
                                    label={t('checkout.details.payment.method.cash', { defaultValue: 'Pay cash on delivery' })}
                                    name='checkboxRadioGroup'
                                    value={PaymentMethod.CashOnDelivery}
                                    checked={paymentMethod === PaymentMethod.CashOnDelivery}
                                    onChange={() => setPaymentMethod(PaymentMethod.CashOnDelivery)}
                                />
                            </Form.Field>
                        </Form>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        {!promoCode && (
                            <Button fluid onClick={onOpenAddPromoCode}>
                                <Trans i18nKey='checkout.details.payment.promoCode.trigger'>Add Promo Code</Trans>
                            </Button>
                        )}
                        {!promoCode && <PromoCode name={ModalNames.CART_ADD_PROMO_CODE} />}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='auto tensed'>
                            <Grid.Column>
                                <Header size='medium'>
                                    <Trans i18nKey='checkout.details.payment.total'>Total</Trans>:
                                </Header>
                            </Grid.Column>
                            <Grid.Column className='col-text-align-right'>
                                <Header size='medium'>{formatPrice(grandTotalPrice)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Button primary fluid icon disabled={isErrorRelevant} onClick={handleProceedClick}>
                            <Trans i18nKey='checkout.details.payment.button'>Continue</Trans> <Icon name='chevron right' />
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <CheckoutDetailsPCIDSS />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    );
}

export default withTranslation('translations')(CheckoutDetailsPayment);
