// @flow
import React, { Component } from 'react';

import PaginatedGridView from './PaginatedGridView';

type Props<T> = {
    loadMoreItems: ({ offset: number, limit: number }) => Promise<T[]>,
    pageLimit: number,
    id: ?(string | number)
};

type State<T> = {
    generation: number,
    items: T[],
    isLoading: boolean,
    hasReachedEnd: boolean,
    id: ?(string | number)
};

class PaginatedGridContainer<T = number> extends Component<Props<T>, State<T>> {
    static defaultProps;

    state = {
        generation: 0,
        items: [],
        isLoading: false,
        hasReachedEnd: false,
        id: null
    };

    componentDidMount() {
        this.loadMoreAtEnd();
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props<T>) {
        const { id, componentType } = nextProps;
        // if ((id && id !== this.state.id) && (componentType && componentType !== this.props.componentType)) {
        if ((id && id !== this.state.id) || (id && componentType !== this.props.componentType && componentType === 'popular_items_carousel')) {
            const { generation } = this.state;
            this.loadCounter = 0;
            this.setState(
                {
                    generation: generation + 1,
                    id,
                    items: [],
                    hasReachedEnd: false,
                    isLoading: false
                },
                this.loadMoreAtEnd
            );
        }
    }

    loadCounter = 0;

    loadMoreAtEnd = () => {
        const { loadMoreItems, pageLimit, componentType } = this.props;
        const customSort = componentType === 'popular_items_carousel' ? 'total_likes' : undefined;
        const { generation: requestGeneration, isLoading, hasReachedEnd } = this.state;
        if (isLoading || hasReachedEnd) {
            return;
        }

        const offset = this.loadCounter;
        const limit = offset === 0 ? pageLimit * 2 : pageLimit;

        this.loadCounter += limit;
        this.setState({ isLoading: true }, () => {
            loadMoreItems({ offset, limit, customSort }).then((newItems) =>
                this.setState(({ items, ...rest }) => {
                    // Make sure to ignore results from calls made during a previous
                    // generation of this component (state has been reset in-between)
                    const ok = requestGeneration === rest.generation;
                    if (!ok) {
                        return { ...rest, items };
                    }
                    return {
                        ...rest,
                        isLoading: false,
                        items: items.concat(newItems),
                        hasReachedEnd: newItems.length < limit
                    };
                })
            );
        });
    };

    render() {
        const { ...rest } = this.props;
        const { isLoading, items } = this.state;
        return <PaginatedGridView onLoadMore={this.loadMoreAtEnd} {...{ isLoading, items }} {...rest} />;
    }
}

PaginatedGridContainer.defaultProps = {
    pageLimit: 24,
    id: null
};

export default PaginatedGridContainer;
