import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Form, Checkbox } from 'semantic-ui-react';

const Toggle = ({ label, checked, setChecked, t, ...other }) => (
    <Grid.Column>
        <Form.Field>
            <label>{label}</label>
            <Checkbox
                {...other}
                toggle
                checked={checked}
                label={t(checked ? 'on.label' : 'off.label', { defaultValue: checked ? 'On' : 'Off' })}
                onChange={(_e, { checked }) => setChecked(checked)}
            />
        </Form.Field>
    </Grid.Column>
);

const StoreDetails = ({
    slugAttributes,
    bioAttributes,
    visible,
    setVisible,
    negotiation,
    setNegotiation,
    webNotification,
    setWebNotification,
    emailNotification,
    setEmailNotification,
    industry,
    setIndustry,
    industries
}) => {
    const { t } = useTranslation('translations');

    const storeSlug = slugAttributes.value.replace(/[^a-zA-Z0-9]/g, '');

    return (
        <>
            <Form.Input name='slug' label={t('storeSettings.storeSlug', { defaultValue: 'Store Slug' })} {...slugAttributes} required />
            <Form.Input name='url' label={t('storeSettings.storeURL', { defaultValue: 'Store URL' })} value={'www.mybazar.com/' + storeSlug} readOnly />
            { /*
                <Form.Dropdown
                selection
                name='industry'
                id='industry'
                onChange={(_event, { value }) => {
                    setIndustry(value);
                }}
                label={t('storeSettings.storeIndustry', { defaultValue: 'Store Industry' })}
                value={industry}
                options={industries}
                />
            */ }
            <Form.TextArea
                name='bio'
                label={t('storeSettings.storeBio.label', { defaultValue: 'Store Bio' })}
                {...bioAttributes}
                placeholder={t('storeSettings.storeBio.placeholder', { defaultValue: 'Tell your story' })}
                rows={4}
            />
            <Form.Field>
                <Grid>
                    <Grid.Row columns={2}>
                        <Toggle t={t} label={t('storeSettings.storeVisibility.label', { defaultValue: 'Store Visibility' })} checked={visible} setChecked={setVisible} />
                        <Toggle
                            t={t}
                            label={t('storeSettings.webNotifications.label', { defaultValue: 'Web Notifications' })}
                            checked={webNotification}
                            setChecked={setWebNotification}
                            readOnly
                        />
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Toggle t={t} label={t('storeSettings.negotiatePrice.label', { defaultValue: 'Negotiate Price' })} checked={negotiation} setChecked={setNegotiation} />
                        <Toggle
                            t={t}
                            label={t('storeSettings.emailNotifications.label', { defaultValue: 'Email Notifications' })}
                            checked={emailNotification}
                            setChecked={setEmailNotification}
                            readOnly
                        />
                    </Grid.Row>
                </Grid>
            </Form.Field>
        </>
    );
};

export default StoreDetails;
