// @flow
import { API_DATA, wrapAPIActionCreator } from 'services/api';

export type ValidateForgotPasswordTokenParams = {
    resetToken: string
};

export const VALIDATE_FORGOT_PASSWORD_TOKEN = 'VALIDATE_FORGOT_PASSWORD_TOKEN';
export const validateForgotPasswordToken = wrapAPIActionCreator(({ resetToken }: ValidateForgotPasswordTokenParams) => ({
    [API_DATA]: {
        type: VALIDATE_FORGOT_PASSWORD_TOKEN,
        endpoint: '/v3/auth/recover/validate_token',
        method: 'POST',
        body: {
            resetToken
        }
    }
}));
