// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_USER ------------------
export type LoadUserParams = {
    id: number,
    fields?: 'cover_photos'[]
};

export const LOAD_USER = 'LOAD_USER';
export const loadUser = wrapAPIActionCreator(({ id, fields = [] }: LoadUserParams) => ({
    [API_DATA]: {
        type: LOAD_USER,
        endpoint: `/v3/users/${id}`,
        optionalAuthentication: Authentication.User,
        endpointParams: { fields: fields.join(',') },
        entities: {
            users: [id]
        },
        schema: {
            user: Schemas.user
        }
    }
}));

export default loadUser;
