// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getEntityDataBySlug } from 'store/utilities';
import withNavigationAid from 'utilities/withNavigationAid';
import type { EntityData, Category } from 'store/types';
import type { NavigationAid } from 'utilities/withNavigationAid';

import SubCategoryView from './SubCategoryView';

type Props = {
    // from route params
    slug: string,
    parentSlug: string,
    childSlug: string,
    page: number,
    navigationAid: NavigationAid,
    // from store
    category: EntityData<Category>,
    sort: string,
    loadCategoryItemsBySlug: typeof actions.loadCategoryItemsBySlug,
    bazar?: string
};

class SubCategoryContainer extends Component<Props> {
    loadMoreItems = ({ offset, limit }) => {
        const { sort } = this.props;
        const { loadCategoryItemsBySlug, slug, parentSlug, navigationAid, childSlug, bazar } = this.props;
        return loadCategoryItemsBySlug({
            slug,
            parentSlug,
            childSlug,
            offset,
            limit,
            sort,
            onlyBlee: navigationAid.isBlee,
            bazar
            // collection: navigationAid.collection
        }).then(({ result }) => result.items);
    };

    render() {
        const { ...rest } = this.props;
        return <SubCategoryView loadMoreItems={this.loadMoreItems} {...rest} />;
    }
}

const mapStateToProps = (state, { match }) => {
    const { top, slug, child } = match.params;
    let bazar = '';
    if (state.router.location.query.bazar) {
        bazar = state.router.location.query.bazar;
    }

    const category = getEntityDataBySlug(state, child || slug);
    const { sort } = state.filters;

    return {
        slug,
        parentSlug: top,
        childSlug: child,
        category,
        sort,
        bazar
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadCategoryItemsBySlug: wrapAPIDispatch(dispatch, actions.loadCategoryItemsBySlug)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withNavigationAid)(SubCategoryContainer);
