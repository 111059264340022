import React, { useState, useEffect } from 'react';

const InputPassword = ({ onSubmit, error, setError }) => {
    const [enteredPassword, setEnteredPassword] = useState('');

    useEffect(() => {
        const timeout = setTimeout(() => {
            setError(false);
        }, 2500);

        return () => {
            clearTimeout(timeout);
        };
    }, [error]);

    const submitPasswordHandler = (e) => {
        e.preventDefault();
        onSubmit(enteredPassword);
    };

    return (
        <div>
            <div className='welcome-input-container'>
                <h2>Budi prvi na MyBazaru!</h2>
                <p>(Unesi svoju šifru)</p>
                <form className='welcome-password-form' onSubmit={submitPasswordHandler}>
                    <input type='password' placeholder='Šifra' value={enteredPassword} onChange={(e) => setEnteredPassword(e.target.value)} />
                    <button>Otvori</button>
                </form>
            </div>
            <p
                style={{
                    padding: '7px',
                    width: '160px',
                    margin: '10px auto',
                    border: '1px solid red',
                    backgroundColor: 'lightcoral',
                    color: 'darkred',
                    fontWeight: 'bold',
                    borderRadius: '6px',
                    visibility: error ? 'visible' : 'hidden'
                }}
            >
                Pogrešna šifra
            </p>
        </div>
    );
};

export default InputPassword;
