import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';

import BulkImport from '../../components/BulkImport';

function BulkImportScene() {
    return (
        <Container>
            <Header as='h1'>
                <Trans i18nKey='bulkImport.header'>Bulk Import</Trans>
            </Header>
            <BulkImport />
        </Container>
    );
}

export default withTranslation('translations')(BulkImportScene);
