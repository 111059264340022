// @flow
import React from 'react';
import { Form, Image, Checkbox } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    t: Function,
    state: mixed,
    handleChange: Function,
    handleBlur: Function
};

function CreateStoreFormEusahawan(props: Props) {
    const { state, t, handleChange, handleBlur } = props;
    const {
        eUsahawan: { value: eUsahawan },
        eUsahawanNumber: { value: eUsahawanNumber }
    } = state;

    return (
        <Form.Field>
            <Form.Field>
                <label>
                    <Trans i18nKey='createStore.form.eUsahawan.eUsahawan.label'>Are You an eUsahawan Merchant?</Trans>
                </label>
            </Form.Field>
            <Form.Field>
                <Checkbox
                    name='eUsahawan'
                    id='create-store-eUsahawan'
                    checked={eUsahawan}
                    disabled={state.loading}
                    label={
                        <label htmlFor='create-store-eUsahawan'>
                            <Trans i18nKey='createStore.form.eUsahawan.eUsahawan.checkboxLabel'>Yes, I am an eUsahawan Mercahnt</Trans>
                        </label>
                    }
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Input
                required={state.eUsahawan.value}
                error={state.eUsahawanNumber.error}
                style={{ display: state.eUsahawan.value ? 'block' : 'none' }}
                type='text'
                name='eUsahawanNumber'
                id='create-store-eUsahawanNumber'
                onChange={handleChange}
                onBlur={handleBlur}
                label={t('createStore.form.eUsahawan.eUsahawanNumber.label')}
                placeholder={t('createStore.form.eUsahawan.eUsahawanNumber.placeholder', { defaultValue: '' })}
                value={eUsahawanNumber}
            />
            <Form.Field>
                <Image
                    size='small'
                    src='https://s3.amazonaws.com/s3-mybazaar-development/static/eusa.png'
                    href='http://eusahawan.mdec.my/'
                    target='_blank'
                    style={{ margin: '0 0 1em' }}
                />
                <p>
                    <Trans i18nkey='createStore.form.eUsahawan.eUsahawan.description'>
                        Jika anda seorang peserta eUsahawan, anda juga seorang usahawan Blee. Apa itu Blee? Blee adalah satu platform pasaran online yang diuruskan oleh MyBazar
                        yang membantu peniaga eUsahawan seperti anda untuk memasarkan jualan anda ke seluruh Malaysia sambil menikmati promosi dan sokongan sepanjang perjalanan
                        perniagaan online anda.
                    </Trans>
                </p>
            </Form.Field>
        </Form.Field>
    );
}

export default withTranslation('translations')(CreateStoreFormEusahawan);
