// @flow
import { API_DATA, Schemas, wrapAPIActionCreator } from 'services/api';

// SEARCH_ITEMS_BY_KEYWORD ------------------
export type SearchItemsByKeywordParams = {
    keyword: string,
    limit?: number,
    offset?: number,
    sort?: string,
    onlyBlee?: boolean
};

export const SEARCH_ITEMS_BY_KEYWORD = 'SEARCH_ITEMS_BY_KEYWORD';
export const searchItemsByKeyword = wrapAPIActionCreator(
    ({ keyword, limit = 12, offset = 0, sort = 'latest', onlyBlee = false, only_registered = true }: SearchItemsByKeywordParams) => ({
        [API_DATA]: {
            type: SEARCH_ITEMS_BY_KEYWORD,
            endpoint: '/v3/web/search/items/name',
            endpointParams: {
                keyword,
                limit,
                offset,
                sort,
                onlyBlee,
                only_registered
            },
            reducerParams: {
                sort,
                offset,
                limit,
                keyword
            },
            schema: {
                items: [Schemas.item]
            },
            postprocess: (result) => ({ ...result, offset, keyword })
        }
    })
);
