// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';

type Props = {
    mailLink: string,
    linkLabel: string,
    isLoggedIn: boolean
};

function ProductReportButtonView({ mailLink, linkLabel, isLoggedIn }: Props) {
    return (
        isLoggedIn && (
            <a target='_blank' href={mailLink}>
                {linkLabel} <Icon name='info circle' />
            </a>
        )
    );
}

export default ProductReportButtonView;
