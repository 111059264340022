// @flow
import React,{ useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { getItem, getOrder, getUser } from 'store/utilities';
import type { EntityData, Item, Order, User } from 'store/types';

import ProductDetailListItemView from './ProductDetailListItemView';

type Props = {
    // from store
    type: 'item' | 'order' | 'purchase',
    item: EntityData<Item>,
    order: EntityData<Order>,
    seller: EntityData<User>
};

function ProductDetailListItemContainer(props: Props) {
    const [width, setWidth] = useState(0);
    useLayoutEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return (
        <>
            <ProductDetailListItemView width={width} {...props} />
        </>
    );
}

const mapStateToProps = (state, { type, id }) => {
    {
        const order = getOrder(state, type === 'order' || type === 'purchase' ? id : -1);
        const item = getItem(state, type === 'item' ? id : order.entity && order.entity.item);
        const seller = getUser(state, item.entity.merchant);
        return { type, item, order, seller };
    }
};

export default compose(connect(mapStateToProps, null))(ProductDetailListItemContainer);
