// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_PROFILE ------------------------------------
export const LOAD_MY_PROFILE = 'LOAD_MY_PROFILE';
export const loadMyProfile = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_MY_PROFILE,
        endpoint: '/v3/me/profile',
        authentication: Authentication.User,
        schema: {
            user: Schemas.user
        }
    }
}));

export default loadMyProfile;
