import React, { PureComponent } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Modal } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import { history } from 'store';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import LogIn from 'components/LogIn';
import onLogin from 'common/onLogin';

class LogInModal extends PureComponent {
    handleClose = () => {
        const { name, closeModal } = this.props;
        closeModal(name);
    };

    handleSuccess = () => {
        const { props, switchCart } = this.props;
        let cartPromise = Promise.resolve();
        if (props && props.action === 'switchCart') {
            const { cartId } = props;
            cartPromise = switchCart({ cartId });
        }

        cartPromise.then(() => {
            onLogin();
            this.handleClose();
        });
    };

    listenToHistory = history.listen(() => {
        this.handleClose();
    });

    render() {
        const { isOpen } = this.props;
        return (
            <Modal size='tiny' closeIcon open={isOpen} onClose={this.handleClose}>
                <Modal.Header>
                    <Trans i18nKey='logInModal.header'>Log in to MyBazar Marketplace</Trans>
                </Modal.Header>
                <Modal.Content>
                    <LogIn onSuccess={this.handleSuccess} />
                </Modal.Content>
            </Modal>
        );
    }
}

const mapStateToProps = (state, { name }) => ({
    isOpen: (state.ui.modals[name] || {}).isOpen,
    props: (state.ui.modals[name] || {}).props
});

const mapDispatchToProps = (dispatch) => ({
    routePush: () => dispatch(push),
    closeModal: (...args) => dispatch(actions.closeModal(...args)),
    switchCart: wrapAPIDispatch(dispatch, actions.switchCart)
});

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslation('translations'))(LogInModal);
