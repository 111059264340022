// @flow
import * as ActionTypes from '../../../actions';

const initialState = {};

function conversations(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_CONVERSATIONS: {
            const { withUser } = action.reducerParams;
            if (!withUser) {
                return state;
            }
            if (action.status === 'request') {
                return {
                    ...state,
                    [withUser]: {
                        isLoading: true
                    }
                };
            }
            if (action.status === 'success') {
                const { conversations: list } = action.payload.result;
                return {
                    ...state,
                    [withUser]: {
                        isLoading: false,
                        list
                    }
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    [withUser]: {
                        isLoading: false
                    }
                };
            }
            break;
        }
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default conversations;
