// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getCategory } from 'store/utilities';

import type { EntityData, Category } from 'store/types';

import CategoryBreadcrumbView from './CategoryBreadcrumbView';

type Props = {
    categories: EntityData<Category>[]
};

function CategoryBreadcrumbContainer({ categories }: Props) {
    return <CategoryBreadcrumbView categories={categories} />;
}

const mapStateToProps = (state, { categoryId }) => {
    const categories = [];

    let curr = getCategory(state, categoryId);
    while (curr.entity != null && curr.entity.parentId != null) {
        categories.unshift(curr);
        curr = getCategory(state, curr.entity.parentId);
    }
    if (curr.entity != null) {
        categories.unshift(curr);
    }

    return {
        categories
    };
};

export default compose(connect(mapStateToProps))(CategoryBreadcrumbContainer);
