// @flow
import { schema } from 'normalizr';
import { renameProperties } from '../../utilities';

// Declare entity types
export const category = new schema.Entity(
    'categories',
    {},
    {
        processStrategy: (obj) => renameProperties(obj, { slug: 'categorySlug' })
    }
);
export const categoryAttribute = new schema.Entity('categoryAttributes', {}, {});

export const user = new schema.Entity(
    'users',
    {},
    {
        processStrategy: (obj) => renameProperties(obj, { slug: 'userSlug' })
    }
);
export const userDeliveryAddress = new schema.Entity('userDeliveryAddresses', {}, {});

export const store = new schema.Entity('stores', {}, {});
export const shippingLocation = new schema.Entity('shippingLocations', {}, {});
export const returnPolicy = new schema.Entity('returnPolicies', {}, {});
export const bankAccount = new schema.Entity('bankAccounts', {}, {});

export const item = new schema.Entity('items', {}, {});
export const variant = new schema.Entity('variants', {}, {});

export const cart = new schema.Entity('cart', {}, {});
export const cartItem = new schema.Entity('cartItems', {}, {});
export const offer = new schema.Entity('offers', {}, {});

export const order = new schema.Entity('orders', {}, {});

export const landingPage = new schema.Entity('landingPages', {}, {});
export const landingComponent = new schema.Entity('landingComponents', {}, {});
export const landingComponentBanner = new schema.Entity('landingComponentBanners', {}, {});
const landingComponentTypeMap = {
    items_carousel: 'items',
    items_list: 'items',
    stores_carousel: 'stores',
    banners_carousel: 'banners',
    categories_carousel: 'categories',
    categories_list: 'categories',
    child_components: 'components',
    custom_bazar_carousel: 'landingPages'
};
export const landingComponentEntities = new schema.Array({}, (_, parent) => landingComponentTypeMap[parent.displayType]);
export const landingComponentEntitiesSibling = new schema.Array({}, (_, parent) => landingComponentTypeMap[parent.component.displayType]);

export const country = new schema.Entity('countries', {}, {});
export const state = new schema.Entity('states', {}, {});
export const city = new schema.Entity('cities', {}, {});

export const conversation = new schema.Entity(
    'conversations',
    {},
    {
        idAttribute: 'conversationKey'
    }
);
export const message = new schema.Entity('messages', {}, {});

export const comment = new schema.Entity('comments', {}, {});

export const categoryTemplate = new schema.Entity('categoryTemplates', {}, {});

// Search result
const recordTypeOf = (record) => {
    if (record.isCategory) {
        return 'category';
    }
    if (record.isUser) {
        return 'user';
    }
    if (record.isStore) {
        return 'user';
    }
    if (record.isLandingPage) {
        return 'landingPage';
    }
    return null;
};
export const record = new schema.Union({ category, user, landingPage }, (_, parent) => recordTypeOf(parent));

// Define schemas for types
category.define({
    items: [item],
    subcategories: [category],
    attributes: [categoryAttribute]
});

item.define({
    store,
    merchant: user,
    variants: [variant],
    comments: [comment]
});

cartItem.define({
    item,
    offer
});

landingPage.define({
    components: [landingComponent]
});

landingComponentEntities.define({
    items: item,
    stores: { merchant: user, items: [item] },
    banners: landingComponentBanner,
    categories: category,
    components: landingComponent
});

landingComponentEntitiesSibling.define({
    items: item,
    stores: { merchant: user, items: [item] },
    banners: landingComponentBanner,
    categories: category,
    components: landingComponent,
    landingPages: landingPage
});

landingComponent.define({
    entities: landingComponentEntities
});

state.define({
    cities: [city]
});

order.define({
    item
});

message.define({
    offer,
    order
});

conversation.define({
    user,
    lastMessage: message,
    item
});
