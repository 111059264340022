// @flow

import type { LookupShippingCostParams } from '../actions';

// Store projection functions -------------------
export function getIsLoggedIn(state: Object) {
    return state.userData.accessToken != null;
}

export function getIsNonTempUser(state: Object) {
    const user = state.userData.user ? getUser(state, state.userData.user).entity : null;
    const email = user ? user.email : null;
    return email && !(email.startsWith('tempuser') && email.endsWith('mybazar.com'));
}

// (Meta)data representing an entity missing from the store
const ENTITY_NOT_FOUND = {
    isFetching: false,
    lastUpdated: 0,
    entity: null,
    collection: null
};

/**
 * Fetches the (meta)data for an entity given its collection key & ID.
 * Returns an object of the shape { ...metadata, entity }, where `entity` is
 * the actual entity in question (or `null` if not found).
 */
export function getEntityData(state: Object, key: string, id: ?(string | number)) {
    if (key == null || state.entities[key] == null) {
        return ENTITY_NOT_FOUND;
    }
    const entityData = state.entities[key][id] || ENTITY_NOT_FOUND;
    return { ...entityData, collection: key };
}

export function getEntityDataBySlug(state: Object, slug: string) {
    // const { collection, id } = state.mapSlug[slug] || {};
    const { collection, id } = state.mapSlug[Object.keys(state.mapSlug).find((key) => key.toLowerCase() === slug.toLowerCase())] || {};
    return collection ? getEntityData(state, collection, id) : ENTITY_NOT_FOUND;
}

export const getCategory = (state: Object, id: ?number) => getEntityData(state, 'categories', id);
export const getCategoryAttribute = (state: Object, id: ?number) => getEntityData(state, 'categoryAttributes', id);
export const getComment = (state: Object, id: ?number) => getEntityData(state, 'comments', id);
export const getCountry = (state: Object, id: ?number) => getEntityData(state, 'countries', id);
export const getUser = (state: Object, id: ?number) => getEntityData(state, 'users', id);
export const getStore = (state: Object, id: ?number) => getEntityData(state, 'stores', id);
export const getReturnPolicy = (state: Object, id: ?number) => getEntityData(state, 'returnPolicies', id);
export const getItem = (state: Object, id: ?number) => getEntityData(state, 'items', id);
export const getVariant = (state: Object, id: ?number) => getEntityData(state, 'variants', id);
export const getOrder = (state: Object, id: ?number) => getEntityData(state, 'orders', id);
export const getCartItem = (state: Object, id: ?number) => getEntityData(state, 'cartItems', id);
export const getLandingPage = (state: Object, id: ?number) => getEntityData(state, 'landingPages', id);
export const getLandingComponent = (state: Object, id: ?number) => getEntityData(state, 'landingComponents', id);
export const getLandingComponentBanner = (state: Object, id: ?number) => getEntityData(state, 'landingComponentBanners', id);
export const getState = (state: Object, id: ?number) => getEntityData(state, 'states', id);
export const getCity = (state: Object, id: ?number) => getEntityData(state, 'cities', id);
export const getUserDeliveryAddress = (state: Object, id: ?number) => getEntityData(state, 'userDeliveryAddresses', id);
export const getConversation = (state: Object, id: ?number) => getEntityData(state, 'conversations', id);
export const getMessage = (state: Object, id: ?number) => getEntityData(state, 'messages', id);
export const getOffer = (state: Object, id: ?number) => getEntityData(state, 'offers', id);
export const getBankAccount = (state: Object, id: ?number) => getEntityData(state, 'bankAccounts', id);

export function shippingParamsToKey(shippingParams: LookupShippingCostParams) {
    const { delivery, isMerchantCoverCost, payment, shipFromId, shipToId, weight, weightType } = shippingParams;
    return `${delivery}-${isMerchantCoverCost ? 1 : 0}-${payment}-${shipFromId}-${shipToId}-${weight}-${weightType}`;
}
