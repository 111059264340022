// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import defaultCover from 'styles/assets/images/defaults/coverPhotoDefaultLow.jpg';

function StoreCover({ coverUrl }) {
    const url = coverUrl || defaultCover;
    return (
        <Grid className='store-cover'>
            <div className='store-cover-image' style={{ backgroundImage: `url(${url})` }} />
        </Grid>
    );
}

export default withTranslation('translations')(StoreCover);
