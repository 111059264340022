import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react';

import DeliveryAddressListItem from './components/DeliveryAddressListItem';

function DeliveryAddressList({ showCartSelector, list, selected, handleChange, getUserDeliveryAddress, onOpenEditAddress }) {
    const { t } = useTranslation('translations');
    return (
        <div>
            {list.map((item) => {
                const { entity } = getUserDeliveryAddress(item);
                if (entity) {
                    return (
                        <DeliveryAddressListItem
                            key={entity.id}
                            item={entity}
                            selected={selected}
                            handleChange={handleChange}
                            showCartSelector={showCartSelector}
                            onOpenEditAddress={() => onOpenEditAddress(entity.id)}
                        />
                    );
                }
                return null;
            })}
        </div>
    );
}

export default withTranslation('translations')(DeliveryAddressList);
