// @flow
import React from 'react';
import { Form, Popup, Label, Icon, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CommonForm from 'components/CommonForm';
import ItemImageList from './components/ItemImageList';

const defaultValuesFromItem = ({ item }) => {
    const { pictures } = item;

    return {
        pictures: pictures.map(({ imageThumb, id }, index) => ({
            index: index + 1,
            id,
            data: imageThumb,
            key: index + 1
        }))
    };
};

class ItemImagesView extends CommonForm {
    constructor(props) {
        super(props);

        const { item } = props;

        let formValues = {};

        if (item) {
            formValues = defaultValuesFromItem({ item });
        } else {
            formValues = {
                pictures: []
            };
        }
        this.state = {
            formValues,
            errorMessage: null
        };

        const { updateFormValues, formId } = props;

        if (updateFormValues && formId) {
            updateFormValues({ formId, formValues: this.state.formValues });
        }
    }

    validate = () => {
        const { formValues } = this.state;
        const { pictures } = formValues;
        const validates = pictures && pictures.length > 0;
        this.setState({ errorMessage: validates ? null : 'Add at least one image' });
        return validates;
    };

    onFormValuesChanged = (newFormValues) => {
        const { updateFormValues, formId } = this.props;
        this.setState({
            formValues: newFormValues
        });
        if (updateFormValues && formId) {
            updateFormValues({ formId, formValues: newFormValues });
        }
    };

    addImage = ({ fileDataURI, id }) => {
        const { formValues } = this.state;
        const { pictures } = formValues;
        pictures.push({
            index: pictures.length + 1,
            data: fileDataURI,
            id,
            key: pictures.length + 1
        });

        this.onFormValuesChanged(formValues);
    };

    deleteImage = (index) => {
        const { formValues } = this.state;
        formValues.pictures = formValues.pictures.filter((image) => image.index !== index).map((item, i) => ({ ...item, index: i, key: i }));

        this.onFormValuesChanged(formValues);
    };

    moveImage = (index, direction) => {
        const { formValues } = this.state;
        const { pictures } = formValues;
        const position = pictures.findIndex((image) => image.index === index);
        if (direction === 'left') {
            pictures.splice(position - 1, 0, pictures.splice(position, 1)[0]);
        } else if (direction === 'right') {
            pictures.splice(position + 1, 0, pictures.splice(position, 1)[0]);
        }
        formValues.pictures = pictures.map((item, i) => ({ ...item, index: i, key: i }));
        this.onFormValuesChanged(formValues);
    };

    render() {
        const { formValues, errorMessage } = this.state;
        const { pictures } = formValues;
        const imageLimit = 7;
        return (
            <Form.Field>
                <Form.Field error={errorMessage != null}>
                    <label htmlFor='sell-item-pictures'>
                        <Trans i18nKey='sellItem.pictures.label'>Images</Trans>
                        <Popup
                            horizontaloffset={10}
                            trigger={
                                <Label className='inline-label-icon' circular size='mini'>
                                    <Icon name='question' />
                                </Label>
                            }
                        >
                            <Popup.Content>
                                <Trans i18nKey='sellItem.form.item.pictures.tooltip' />
                            </Popup.Content>
                        </Popup>
                    </label>
                </Form.Field>
                {/*{errorMessage && <Message negative>{errorMessage}</Message>}*/}
                <ItemImageList list={pictures} limit={imageLimit} addImage={this.addImage} deleteImage={this.deleteImage} moveImage={this.moveImage} />
            </Form.Field>
        );
    }
}

export default withTranslation('translations')(ItemImagesView);
