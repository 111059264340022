// @flow
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Form, Button, Icon, Ref } from 'semantic-ui-react';
import TextareaAutosize from 'react-textarea-autosize';

class SendCommentView extends Component {
    constructor(props) {
        super(props);

        this.textAreaWrapper = null;

        this.state = {
            isPosting: false,
            formValues: {
                comment: { value: '' }
            }
        };
    }

    onSendComment = () => {
        if (this.state.isPosting) {
            return;
        }
        const { sendComment, onPostCommentSuccess = () => {} } = this.props;

        const { formValues } = this.state;
        const {
            comment: { value: comment }
        } = formValues;

        this.setState({ isPosting: true });
        sendComment({ comment })
            .then(() => {
                const { formValues } = this.state;
                formValues.comment.value = '';
                this.setState({ formValues, isPosting: false }, () => {
                    onPostCommentSuccess();
                });
            })
            .catch((error) => {
                console.error({ error });
                this.setState({ isPosting: false });
            });
    };

    setTextAreaRef = (element) => {
        this.textAreaWrapper = element;
    };

    handleChange = (event, { name, value, ...rest }) => {
        const { formValues } = this.state;

        if (name === 'comment') {
            formValues.comment.value = value;
        }
        this.setState({ formValues });
    };

    handleCommentChange = (e) => {
        const { formValues } = this.state;

        formValues.comment.value = e.target.value;

        this.setState({ formValues });
    };

    render() {
        const { formValues, isPosting } = this.state;
        const {
            comment: { value: comment }
        } = formValues;
        const { t } = this.props;

        return (
            <Form>
                <Ref innerRef={this.setTextAreaRef}>
                    <Form.TextArea
                        value={comment}
                        control={TextareaAutosize}
                        rows={3}
                        name='comment'
                        id='comments-conversation-send-comment'
                        onChange={this.handleCommentChange}
                        placeholder={t('comments.input.placeholder', { defaultValue: 'Unesite vaš komentar..' })}
                    />
                </Ref>
                <Button className='message-button' icon loading={isPosting} labelPosition='left' floated='right' onClick={() => this.onSendComment()}>
                    <Icon name='send' />
                    <Trans i18nKey='comments.conversation.actions.send'>Pošalji</Trans>
                </Button>
            </Form>
        );
    }
}

export default withTranslation('translations')(SendCommentView);
