// @flow
import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

type Props = {
    item: mixed
};

function SidebarMenuSupportItem({ item }: Props) {
    return (
        <Menu.Item as='a' href={item.link} target={item.target || '_blank'}>
            {item.icon && <Icon name={item.icon} />}
            <Trans i18nKey={`sidebar.menu.support.${item.key}`}>{item.text}</Trans>
        </Menu.Item>
    );
}

export default withTranslation('translations')(SidebarMenuSupportItem);
