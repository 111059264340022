// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getUser, getIsNonTempUser } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import DeliveryAddressAddModalView from './DeliveryAddressAddModalView';

type Props = {
    updateCartAddress?: boolean,
    // from store
    addAddress: typeof actions.addAddress,
    openModal: typeof actions.openModal,
    setCartAddress: typeof actions.setCartAddress
};

class DeliveryAddressAddModalContainer extends Component<Props, State> {
    static defaultProps;

    state = {
        error: null
    };
    handleSubmit = ({ name, email, phone, address, addressSecondary, postal, country, state, city, country_id, state_id, city_id }) => {
        const { addAddress, setCartAddress, updateCartAddress, updateProfile, loadMyAccounts, isNonTempUser } = this.props;
        const params = {
            fullName: name,
            emailAddress: email,
            contactNo: phone,
            street: address,
            houseNo: addressSecondary,
            zip: postal,
            country_id: country_id,
            state_id: state_id,
            city_id: city_id,
            country,
            state,
            city
        };
        const matchesAll = (obj1, obj2) => Object.keys(obj1).every((key) => obj1[key] === obj2[key]);

        const updateUserProfile = (name, email) => {
            if (isNonTempUser) {
                return Promise.resolve();
            }
            return updateProfile({
                alias: name,
                email,
                sendWelcomeEmail: true
            }).then(loadMyAccounts);
        };


        return updateUserProfile(name, email)
            .then(() => addAddress(params))
            .then(({ entities, result }) => {
                const { userDeliveryAddresses: addresses } = entities;
                const addressId = result.filter((id) => matchesAll(params, addresses[id]))[0];
                if (updateCartAddress) {
                    setCartAddress({ addressId });
                }
            })
            .catch((err) => {
                this.setState({
                    error:
                        err.message === 'EmailAlreadyExist'
                            ? 'Adresa e-pošte koju ste unijeli već postoji u našoj bazi podataka. Molimo unesite drugu adresu e-pošte.'
                            : err.message
                });
                throw err;
            });
    };

    render() {
        const { ...rest } = this.props;
        return <DeliveryAddressAddModalView backendError={this.state.error} onSubmit={this.handleSubmit} {...rest} />;
    }
}

DeliveryAddressAddModalContainer.defaultProps = {
    updateCartAddress: false
};

const mapStateToProps = (state, { name }) => {
    const modalData = state.ui.modals[name] || {};
    // Props passed to openModal action
    const { props: extraProps } = modalData;
    const isNonTempUser = getIsNonTempUser(state);
    return {
        ...extraProps,
        isNonTempUser,
        user: getUser(state, state.userData.user).entity
    };
};

const mapDispatchToProps = (dispatch) => ({
    addAddress: wrapAPIDispatch(dispatch, actions.addAddress),
    openModal: (...args) => dispatch(actions.openModal(...args)),
    setCartAddress: wrapAPIDispatch(dispatch, actions.setCartAddress),
    updateProfile: wrapAPIDispatch(dispatch, actions.updateProfile),
    loadMyAccounts: wrapAPIDispatch(dispatch, actions.loadMyAccounts)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeliveryAddressAddModalContainer);
