// @flow
import React from 'react';
import { Menu, Tab } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import MessagesConversationMenuItem from './components/MessagesConversationMenuItem';
import MessagesConversation from './components/MessagesConversation';

import SendMessageForm from './components/MessagesConversation/components/SendMessageForm';

function MessagesView(props) {
    const { conversations, me, product, negotiationBox } = props;

    let panes = [];
    let negotiationBoxContent;
    if (conversations.length > 0) {
        negotiationBoxContent = <MessagesConversation {...props} conversation={conversations[0]} me={me} negotiationBox />;
        panes = conversations.map((conversation) => ({
            menuItem: (
                <Menu.Item key={conversation.entity.conversationKey}>
                    <MessagesConversationMenuItem {...props} conversation={conversation} me={me} />
                </Menu.Item>
            ),
            render: () => (
                <Tab.Pane attached={false} className='simple'>
                    <MessagesConversation {...props} conversation={conversation} me={me} />
                </Tab.Pane>
            )
        }));
    } else if (product && product.entity) {
        negotiationBoxContent = <SendMessageForm {...props} />;
        panes = [
            {
                menuItem: (
                    <Menu.Item key='no-messages'>
                        <Trans i18nKey='messages.noMessagesFound.label'>Nije pronađena nijedna poruka.</Trans>
                    </Menu.Item>
                ),
                render: () => (
                    <Tab.Pane attached={false} className='simple'>
                        <SendMessageForm {...props} />
                    </Tab.Pane>
                )
            }
        ];
    }
    return !negotiationBox ? (
        <Tab
            className='messages'
            menu={{
                fluid: true,
                vertical: true,
                secondary: true,
                style: {
                    margin: 0,
                    overflowY: 'auto',
                    height: 700
                }
            }}
            panes={panes}
        />
    ) : (
        <div style={{ maxWidth: '500px', width: '400px' }}>{negotiationBoxContent}</div>
    );
}

export default withTranslation('translations')(MessagesView);
