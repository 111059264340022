import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { Container, Grid, Header, Loader, Segment, Visibility } from 'semantic-ui-react';
import { formatDatePretty, formatPrice } from '../../../../utilities/format';
import { Icon } from 'semantic-ui-react';
import { useTranslation, Trans } from 'react-i18next';
import store from '../../../../../src/store/index';
import * as actions from 'store/actions';
import { Authentication } from 'services/api';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getPaymentStatusFromId } from 'components/ProductDetailList/components/ProductDetailListItem/ProductDetailListItemView';

const tableCol = [
    {
        key: 'cartId',
        title: 'Narudžba'
    },
    {
        key: 'date',
        title: 'Datum'
    },
    {
        key: 'saldo',
        title: 'Ukupno'
    },
    {
        key: 'payment',
        title: 'Plaćanje'
    },
    {
        key: 'quantity',
        title: 'Količina'
    },
    {
        key: 'status',
        title: 'Stanje'
    },
    {
        key: 'shipment',
        title: 'Kurirska služba'
    }
];

const PurchasesFromMail = ({ onCancelOrder, openModal }) => {
    const { accessToken } = store.getState().userData;
    const [orders, setOrders] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const { cartId } = useParams();
    const { t } = useTranslation('translations');
    const url = `${process.env.REACT_APP_MYBAZAR_API_SERVER}/v3/orders/cart/${cartId}`;

    const fetchPurchases = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(url, {
                headers: {
                    access_token: accessToken
                }
            });
            const responseData = await response.json();
            console.log(responseData);

            if (!response.ok) {
                throw new Error('Could not fetch cart items!');
            }
            setOrders(responseData.results);
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    function renderValue(item, index) {
        switch (index) {
            case 0:
                return formatDatePretty(new Date(item.created_at), t);
            case 1:
                return formatPrice(item.selling_price - item.discount_amount);
            case 2:
                return getPaymentStatusFromId(item.payment_status);
            case 3:
                return item.quantity;
            case 4:
                return item.order_status_title;
            case 5:
                return item.item.shipping_details[0].merchant_shipping_detail.title;
            default:
                return '/';
        }
    }

    useEffect(() => {
        fetchPurchases();
    }, [cartId]);

    if (isLoading) {
        return <Loader active />;
    }
    return (
        <>
            <Container className='my-profile-purchases'>
                <Grid>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as='h1'>Kupljeno</Header>
                            </Grid.Column>
                        </Grid.Row>

                        <button style={{ cursor: 'pointer' }} onClick={() => onCancelOrder({ openModal, cartId }, Authentication.User)}>
                            <Trans i18nKey='myStore.orders.orderDetails.btn.cancel'>Cancel Order</Trans>
                        </button>
                    </div>

                    {orders?.map((order) => {
                        return (
                            <Grid.Row key={order.store_id}>
                                <Grid.Column>
                                    <Visibility continuous fireOnMount>
                                        <Grid.Column>
                                            <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                                                <Icon name='warehouse' circular style={{ marginTop: '22px' }} />
                                                <Header as='h4' style={{ color: 'gray' }}>
                                                    {order.store_name}
                                                </Header>
                                            </div>
                                        </Grid.Column>
                                        <div style={{ marginTop: '1em' }}>
                                            <Segment.Group className='simple'>
                                                <div className='table-header custom-table-header' style={{ display: 'flex' }}>
                                                    {tableCol &&
                                                        tableCol.map((c) => (
                                                            <div
                                                                key={c.key}
                                                                style={{
                                                                    width: `${100 / tableCol.length}%`,
                                                                    margin: 'auto',
                                                                    whiteSpace: 'nowrap',
                                                                    textAlign: 'center'
                                                                }}
                                                            >
                                                                <Trans i18nKey={`myStore.orders.ordersListCol.${c.key}`}>{c.title}</Trans>
                                                            </div>
                                                        ))}
                                                </div>
                                                {order.items.map((item) => {
                                                    return (
                                                        <>
                                                            <div className={'only-desktop'} key={item.item_id}>
                                                                <Link to={`/my-profile/purchases/${item.order_id}`} className={`my-store-product-list-item`}>
                                                                    <div style={{ display: 'flex' }}>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>#{item.order_id}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{formatDatePretty(new Date(item.created_at), t)}</span>
                                                                        </Grid.Column>

                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{formatPrice(item.selling_price - item.discount_amount)}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{getPaymentStatusFromId(item.payment_status)}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{item.quantity}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{item.order_status_title}</span>
                                                                        </Grid.Column>
                                                                        <Grid.Column className='table-row-order'>
                                                                            <span>{item.item.shipping_details[0].merchant_shipping_detail.title}</span>
                                                                        </Grid.Column>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                            <div key={item.id} className={'only-mobile'}>
                                                                <Link to={`/my-profile/purchases/${item.order_id}`} className={`my-store-product-list-item`}>
                                                                    <div style={{ marginBottom: '10px' }} className='ui card'>
                                                                        <div style={{ backgroundColor: '#f7f7f7' }} className='content'>
                                                                            <div className='header'>{item.order_id}</div>
                                                                        </div>
                                                                        <div className='content'>
                                                                            <div className='description'>
                                                                                {tableCol.slice(1).map((column, index) => (
                                                                                    <div key={column.key} className='card-item'>
                                                                                        <div style={{ fontWeight: '700' }}>
                                                                                            <Trans i18nKey={`myStore.orders.ordersListCol.${column.key}`}>{column.title}</Trans>
                                                                                        </div>
                                                                                        <div>{renderValue(item, index)}</div>
                                                                                    </div>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                        <div className='extra content'>
                                                                            <div className='ui bottom attached button'>
                                                                                {' '}
                                                                                <Trans i18nKey='myStore.orders.showOrder'>Pogledaj narudžbu</Trans>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                        </>
                                                    );
                                                })}
                                            </Segment.Group>
                                        </div>
                                    </Visibility>
                                </Grid.Column>
                            </Grid.Row>
                        );
                    })}
                </Grid>
            </Container>
        </>
    );
};

const mapDispatchToProps = (dispatch) => ({
    onCancelOrder: (props, authentication) => {
        const { openModal, cartId } = props;
        return dispatch(
            openModal(actions.ModalNames.ORDER_CANCEL, {
                orderId: cartId,
                authentication
            })
        );
    },
    openModal: (...args) => dispatch(actions.openModal(...args))
});

export default compose(connect(null, mapDispatchToProps))(PurchasesFromMail);
