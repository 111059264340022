// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type SetCartAddressParams = {
    addressId: number
};

export const SET_CART_ADDRESS = 'SET_CART_ADDRESS';
export const setCartAddress = wrapAPIActionCreator(({ addressId }: SetCartAddressParams) => ({
    [API_DATA]: {
        type: SET_CART_ADDRESS,
        endpoint: '/v3/cart_items/set_address',
        method: 'POST',
        authentication: Authentication.User,
        body: {
            addressId
        },
        schema: {
            cartItems: [Schemas.cartItem],
            addresses: [Schemas.userDeliveryAddress]
        }
    }
}));

export default setCartAddress;
