// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import BankAccountListItem from './components/BankAccountListItem';

type Props = {
    list: Array,
    onOpenEditBankAccount: Function
};

function BankAccountList({ list, onOpenEditBankAccount, primaryBankAccount, onSelectBankAccount }: Props) {
    if (!(list && list.length)) {
        return null;
    }

    return (
        <Form.Field>
            {list.map((item) => (
                <BankAccountListItem
                    key={item.id}
                    item={item}
                    onOpenEditBankAccount={onOpenEditBankAccount}
                    selected={item.id === primaryBankAccount}
                    onSelectBankAccount={onSelectBankAccount}
                />
            ))}
        </Form.Field>
    );
}

export default withTranslation('translations')(BankAccountList);
