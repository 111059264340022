// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import { getUser } from 'store/utilities';
import CampaignsMenuItemView from './CampaignsMenuItemView';

type Props = {
    item: object
};

class CampaignsMenuItemContainer extends Component<Props> {
    render() {
        const { user } = this.props;
        if (user.isFetching || user.entity === null) {
            return <Loader active />;
        }

        return <CampaignsMenuItemView {...this.props} />;
    }
}

const mapStateToProps = (state, { item }) => ({
    user: getUser(state, item.entity.sender)
});

export default connect(mapStateToProps)(CampaignsMenuItemContainer);
