// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import AddPromoCodeModalView from './AddCartPromoCodeModalView';

type Props = {
    name: string,
    onSubmit: (FormValues) => ?Promise<*>,
    closeModal: typeof actions.closeModal,
    setCartPromoCode: typeof actions.setCartPromoCode
};

class AddPromoCodeModalContainer extends Component<Props, State> {
    handleClose = () => {
        const { closeModal, name } = this.props;
        closeModal(name);
    };

    handleSubmit = (data) => {
        const { setCartPromoCode } = this.props;
        return setCartPromoCode(data);
    };

    render() {
        return <AddPromoCodeModalView onClose={this.handleClose} onSubmit={this.handleSubmit} {...this.props} />;
    }
}

const mapStateToProps = (state, { name }) => {
    return {
        isOpen: (state.ui.modals[name] || {}).isOpen
    };
};

const mapDispatchToProps = (dispatch) => ({
    closeModal: (name) => dispatch(actions.closeModal(name)),
    setCartPromoCode: wrapAPIDispatch(dispatch, actions.setCartPromoCode)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddPromoCodeModalContainer);
