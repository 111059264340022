// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_FOLLOWING ------------------
export type loadLikesParams = {
    userId: number,
    offset: number,
    totalCount: number,
    limit: number
};

export const LOAD_LIKES = 'LOAD_LIKES';
export const loadLikes = wrapAPIActionCreator(({ userId, offset, limit, totalCount }: loadLikesParams) => ({
    [API_DATA]: {
        type: LOAD_LIKES,
        endpoint: `/v3/users/${userId}/liked_items`,
        optionalAuthentication: Authentication.User,
        /* 
    TODO : Bug fix, any end point params results in HTTP 500 Internal Server Error

    endpointParams: {
      offset: offset,
      limit: limit || 16,
    }, */
        reducerParams: {
            offset,
            userId
        },
        schema: {
            items: [Schemas.item]
        }
    }
}));

export default loadLikes;
