// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Header, Image, Segment } from 'semantic-ui-react';

import Dotdotdot from 'react-dotdotdot';

import type { EntityData, Item } from 'store/types';
import { formatItemName } from 'utilities/format';

import DealLabel from 'components/DealLabel';
import Price from 'components/Price';

import DefaultThumb from '../../styles/assets/images/defaults/default-item-image.jpg'


type Props = {
    item: EntityData<Item>,
    inverted: boolean,
    preventSlickClickDuringChange: () => void
};

function ProductTeaser({ item, inverted, preventSlickClickDuringChange, user }: Props) {
    // TODO
    if (item.isFetching || item.entity == null) {
        return null;
    }

    let alias;
    if (user.entity) {
        alias = user.entity.alias;
    }

    const { name, slug, pictures, storeSlug } = item.entity;
    const firstPicture = pictures[0];

    return (
        <Segment className='simple product-teaser'>
            <Link to={`/${slug}`} onClick={preventSlickClickDuringChange || null}>
                <Image className={firstPicture ? 'square-image' : 'square-missing-image'}>
                    <img
                        alt={name}
                        src={firstPicture ? firstPicture.imageThumb : DefaultThumb}
                        onError={(event) => {
                            event.target.src = '';
                        }}
                    />
                </Image>
            </Link>
            <Header as='h3' inverted={inverted} className='product-teaser-header'>
                <Link to={`/${storeSlug}`} onClick={preventSlickClickDuringChange || null} className='store-slug'>
                    <Dotdotdot clamp={2}>{alias}</Dotdotdot>
                </Link>
                <Link to={`/${slug}`} onClick={preventSlickClickDuringChange || null}>
                    <Dotdotdot clamp={4} className='product-slug'>
                        {name}
                    </Dotdotdot>
                    <Header.Subheader>
                        <Price item={item.entity} />
                    </Header.Subheader>
                </Link>
            </Header>
        </Segment>
    );
}

export default withTranslation('translations')(ProductTeaser);
