// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

function CancelOrderPurchaseDescription() {
    return (
        <p>
            <Trans i18nKey='modal.orderCancel.question.merchant'>
                You are able to cancel your order only if the item has not been shipped, or you can return the item purchased up to 14 days after receiving it. Please tell the
                store why you want to cancel.
            </Trans>
        </p>
    );
}

export default withTranslation('translations')(CancelOrderPurchaseDescription);
