// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';
import type { EntityData, CartItem } from 'store/types';

type Props = {
    isLoading: boolean,
    cartItem: EntityData<CartItem>,
    onIncrement: () => void,
    onDecrement: () => void
};

function QuantitySwitcherView({ isLoading, cartItem, onIncrement, onDecrement }: Props) {
    const { quantity, minQuantity, maxQuantity } = cartItem.entity || {};

    return (
        <Button.Group>
            <Button icon onClick={onDecrement} disabled={isLoading || quantity <= minQuantity}>
                <Icon name='minus' />
            </Button>
            <Button disabled loading={isLoading}>
                {quantity}
            </Button>
            <Button icon onClick={onIncrement} disabled={isLoading || quantity >= maxQuantity}>
                <Icon name='plus' />
            </Button>
        </Button.Group>
    );
}

export default QuantitySwitcherView;
