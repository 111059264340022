// @flow
import React from 'react';
import IframeView from 'components/IframeView';

const CampaignsPreviewView = ({ message }) => {
    const isLink = message.startsWith('http');
    if (isLink) {
        return <iframe style={{ border: '1px solid lightgray' }} src={message} title='campaigns' width='100%' height={800} />;
    }
    return <IframeView sourceText={message} title='MyBazar' />;
};

export default CampaignsPreviewView;
