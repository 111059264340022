// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Label } from 'semantic-ui-react';

type Props = {
    item: Object,
    deleteImage: () => void
};

function SellItemImageListItemDelete({ item, deleteImage }: Props) {
    return (
        <Label as={Button} className='sell-item-image-list-item-delete' circular negative onClick={() => deleteImage(item.index)}>
            <Icon name='remove' />
        </Label>
    );
}

export default withTranslation('translations')(SellItemImageListItemDelete);
