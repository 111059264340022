// @flow
import React, { Component } from 'react';
import type { User } from 'store/types';
import { connect } from 'react-redux';
import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import FollowingTabView from './FollowingTabView';

type Props = {
    user: User
};

class FollowingTabContainer extends Component<Props> {
    componentDidMount() {
        const { follow, user } = this.props;
        if (!follow || !follow.following) {
            this.load(user.id, 0);
        }
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        const { user } = this.props;
        if (user && props.user && user.totalFollowing !== props.user.totalFollowing) {
            this.load(user.id, 0);
        }
    }

    load = (userId: number, offset: number, totalCount: number) => {
        this.props
            .loadFollowing({
                userId,
                offset
            })
            .catch((error) => {
                console.warn(error);
            });
    };

    loadMore = (calculations = { bottomVisible: true }) => {
        const { user } = this.props;
        const { following } = this.props.follow;

        if (user && following.users && !following.isLoading) {
            if (calculations.bottomVisible && following.users.length < user.totalFollowing) {
                this.load(user.id, following.users.length);
            }
        }
    };

    render() {
        const { follow } = this.props;
        if (!follow) {
            return null;
        }

        return <FollowingTabView user={this.props.user} loadMore={(calculations) => this.loadMore(calculations)} following={follow.following} />;
    }
}

const mapStateToProps = (state, { user }) => ({
    follow: state.follow[user.id]
});

const mapDispatchToProps = (dispatch) => ({
    loadFollowing: wrapAPIDispatch(dispatch, actions.loadFollowing)
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowingTabContainer);
