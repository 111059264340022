// @flow
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

type Props = {
    options: {}[],
    onChange: (number) => void
};

/** 'select quantity' dropdown of a CartList item */
function OptionDropdown({ options, onChange, ...rest }: Props) {
    return <Dropdown style={{ float: 'right' }} selection onChange={(ev, data) => onChange(data.value)} options={options} {...rest} />;
}

export default OptionDropdown;
