// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getItem, getUser } from 'store/utilities';

import MessagesConversationView from './MessagesConversationView';

type Props = {
    loadItemByItemId: typeof actions.loadItemByItemId
};

type State = {
    isLoadingItem: boolean
};

class MessagesConversationContainer extends Component<Props, State> {
    state = {
        isLoadingItem: false
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { type, item } = nextProps;
        if (type === 'myStoreMessages' || type === 'myProfileInbox') {
            if (item.entity && !item.entity.price) {
                this.loadItem(item.entity.id);
            }
        }
    }

    loadItem = (id) => {
        if (this.state.isLoadingItem) {
            return;
        }
        this.setState({ isLoadingItem: true });
        this.props
            .loadItemByItemId({ itemId: id, fields: ['pictures'] })
            .then(() => this.setState({ isLoadingItem: false }))
            .catch(() => this.setState({ isLoadingItem: false }));
    };

    render() {
        return <MessagesConversationView {...this.props} />;
    }
}

const mapStateToProps = (state, { conversation }) => {
    const { item: itemId, user: userId } = conversation ? conversation.entity : {};
    return {
        item: getItem(state, itemId),
        user: getUser(state, userId)
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadItemByItemId: wrapAPIDispatch(dispatch, actions.loadItemByItemId)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MessagesConversationContainer);
