// @flow
import React, { Component } from 'react';
import { Loader } from 'semantic-ui-react';
import type { User } from 'store/types';

import StoreTabBarView from './StoreTabBarView';

type Props = {
    merchant: Merchant
};

class StoreTabBarContainer extends Component<Props> {
    render() {
        const { merchant } = this.props;
        if (!merchant) {
            return <Loader active />;
        }

        return <StoreTabBarView merchant={merchant} />;
    }
}

export default StoreTabBarContainer;
