// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Container, Header } from 'semantic-ui-react';

import Cart from '../../components/Cart';

type Props = {
    t: mixed
};

function CartScene({ t }: Props) {
    return (
        <Container>
            <Helmet>
                <title>{t('cartScene.head.title')}</title>
            </Helmet>
            <Header as='h1'>
                <Trans i18nKey='cartScene.header'>Shopping Cart</Trans>
            </Header>
            <Cart />
        </Container>
    );
}

export default withTranslation('translations')(CartScene);
