// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

import { Grid, Container, Loader } from 'semantic-ui-react';

import type { EntityData, LandingComponent } from 'store/types';

import LandingBannerCarousel from './components/LandingBannerCarousel';
import LandingCategoryCarousel from './components/LandingCategoryCarousel';
import LandingCategoryList from './components/LandingCategoryList';
import LandingChildComponents from './components/LandingChildComponents';
import LandingItemCarousel from './components/LandingItemCarousel';
import LandingItemList from './components/LandingItemList';
import LandingStoreCarousel from './components/LandingStoreCarousel';
import LandingShoppingMallCarousel from './components/LandingShoppingMallCarousel';
import LandingTags from './components/LandingTags';
import LandingUserCarouselView from './components/LandingUserCarousel';

type Props = {
    urlPrefix: string,
    // from store
    component: EntityData<LandingComponent>
};

const transformBackgroundColor = (color) => (/^[0-9a-f]{3,}$/.test(color) ? `#${color}` : color);

function LandingComponentView({ component, urlPrefix, t }: Props) {
    if (component.isFetching || component.entity == null) {
        return (
            <Container>
                <Loader active />
            </Container>
        );
    }
    const { displayType, title, id, backgroundColor, background: backgroundImage } = component.entity;

    const props = {
        component,
        urlPrefix,
        title: t(`landingComponent.${id}.label`, { defaultValue: title })
    };

    const defaultStyle = {
        paddingTop: '12px',
        paddingBottom: '12px',
        marginLeft: '-1em',
        marginRight: '-1em'
    };
    let componentStyle = backgroundImage ? { backgroundImage: `url('${backgroundImage}')` } : backgroundColor ? { backgroundColor: transformBackgroundColor(backgroundColor) } : {};
    componentStyle = Object.assign(componentStyle, defaultStyle);
    switch (displayType) {
        case 'stores_carousel':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingStoreCarousel {...props} />
                </Grid.Column>
            );

        case 'items_carousel':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingItemCarousel {...props} />
                </Grid.Column>
            );

        case 'banners_carousel':
            return <LandingBannerCarousel {...props} />;

        case 'categories_carousel':
            // Filters on category (found on MyPasar)
            return (
                <Grid.Column style={componentStyle}>
                    <LandingCategoryCarousel {...props} />
                </Grid.Column>
            );

        case 'items_list':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingItemList {...props} />
                </Grid.Column>
            );

        case 'categories_list':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingCategoryList {...props} />
                </Grid.Column>
            );

        case 'tags':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingTags {...props} />
                </Grid.Column>
            );

        case 'child_components':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingChildComponents {...props} />
                </Grid.Column>
            );

        case 'latest_users_carousel':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingUserCarouselView />
                </Grid.Column>
            );
        case 'custom_landing_pages_carousel':
        case 'custom_bazar_carousel':
            return (
                <Grid.Column style={componentStyle}>
                    <LandingShoppingMallCarousel {...props} />
                </Grid.Column>
            );

        default:
            // TODO. Just hide other landing component types for now
            // return <pre key={key}>{ JSON.stringify(component.entity, null, 2) }</pre>;
            //console.warn('unimplemented landing component type', displayType);
            console.log('Warning(LandingComponentView): Unimplemented landing component type', displayType);
            return null;
    }
}

export default compose(withTranslation('translations'))(LandingComponentView);
