// @flow
import { mapValues, merge } from 'lodash';
import { RESET_ENTITY } from '../../actions/entity';

const initialState = {
    categories: {},
    items: {},
    users: {},
    stores: {}
};

// Merges new entities from any action with payload.entities set
export default function entities(state = initialState, action) {
    if (action.payload && action.payload.entities) {
        const metadata = {
            isFetching: action.status === 'request',
            lastUpdated: Date.now()
        };
        const wrapMetadata = (entity) => ({ ...metadata, entity });
        const update = mapValues(action.payload.entities, (coll) => mapValues(coll, wrapMetadata));
        return merge({}, state, update);
    }
    if (action.type === RESET_ENTITY) {
        const { entityType, entityId } = action.payload;
        const entities = state[entityType];
        entities[entityId].entity = null;
        return {
            ...state,
            [entityType]: entities
        };
    }
    return state;
}
