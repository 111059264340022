// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

type Props = {
    active: boolean,
    toggle: () => void
};

function SidebarOverlay({ active, toggle }: Props) {
    return <div className={`main-sidebar-overlay${active ? ' main-sidebar-overlay-active' : ''}`} role='presentation' onClick={toggle} />;
}

export default SidebarOverlay;
