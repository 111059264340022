// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getStore } from 'store/utilities';

import BulkEditFormView from './BulkEditFormView';

type Props = {
    loadBulkImportSettings: typeof actions.loadBulkImportSettings
};

class BulkEditFormContainer extends Component<Props> {
    constructor(props) {
        super(props);

        const API_SERVER = (process.env.REACT_APP_MYBAZAR_API_SERVER || '').replace(/\/$/, '');
        if (API_SERVER === '') {
            throw new Error('Must define REACT_APP_MYBAZAR_API_SERVER');
        }

        this.state = {
            categoryTemplates: [],
            uploadResults: [],
            isUploadingExcel: false,
            isPublishingItems: false,
            isPublished: false,
            isPublishedSuccessfully: false,
            API_SERVER
        };
    }

    componentDidMount() {
        const { loadMyStoreSettings, loadBulkImportSettings } = this.props;

        loadMyStoreSettings().catch((error) => console.error(error));

        loadBulkImportSettings()
            .then((result) => {
                this.setState({ categoryTemplates: result.result.categoryTemplates });
            })
            .catch((error) => console.error(error));
    }

    postFormData(endpoint, access_token, file, fileParamName, extraParams = []) {
        const { API_SERVER } = this.state;
        const url = `${API_SERVER}${endpoint}`;

        const data = new FormData();
        if (file) {
            data.append(fileParamName, file, file.name);
        }
        extraParams.forEach((param) => data.append(param.name, param.value));

        const headers = {};
        headers.access_token = access_token;

        return fetch(url, {
            method: 'POST',
            credentials: 'same-origin',
            body: data,
            headers
        }).then((res) => {
            if (res.ok) {
                return res.json();
            }
            throw new Error('Something went wrong ...');
        });
    }

    handleDownloadAllItems() {
        const endpoint = '/v3/bulk-edit/download-link';
        const access_token = this.props.merchantData.accessToken;

        this.postFormData(endpoint, access_token)
            .then((response) => {
                const url = response.results;
                window.location = url;
            })
            .catch(() => {
                console.log('ERROR CAUGHT DG');
            });
    }

    handleUploadExcelDocument(excelFile) {
        const endpoint = '/v3/bulk-edit/upload';
        const access_token = this.props.merchantData.accessToken;
        const extraParams = [
            { name: 'storeId', value: this.props.stores[0].entity.id },
            { name: 'validateOnly', value: true }
        ];

        this.setState({
            uploadResults: [],
            isUploadingExcel: true,
            isPublishingItems: false,
            isPublished: false,
            isPublishedSuccessfully: false
        });

        this.postFormData(endpoint, access_token, excelFile, 'excelFile', extraParams)
            .then((response) => {
                const { isPublishedSuccessfully } = response.results;
                const uploadResults = response.results.validatedItems || [];
                this.setState({ uploadResults, isPublished: true, isPublishedSuccessfully, isUploadingExcel: false });
            })
            .catch(() => {
                console.log('ERROR CAUGHT DG');
                this.setState({ uploadResults: [], isPublished: true, isPublishedSuccessfully: false, isUploadingExcel: false });
            });
    }

    handlePublishItems(excelFile) {
        const endpoint = '/v3/bulk-edit/upload';
        const access_token = this.props.merchantData.accessToken;
        const extraParams = [
            { name: 'storeId', value: this.props.stores[0].entity.id },
            { name: 'validateOnly', value: false }
        ];

        this.setState({
            uploadResults: [],
            isUploadingExcel: false,
            isPublishingItems: true,
            isPublished: false,
            isPublishedSuccessfully: false
        });

        this.postFormData(endpoint, access_token, excelFile, 'excelFile', extraParams)
            .then((response) => {
                console.log(response, 'API publish response');
                const { isPublishedSuccessfully } = response.results;
                this.setState({ isPublishingItems: false, isPublished: true, isPublishedSuccessfully });
            })
            .catch((error) => {
                console.log(error, 'ERROR CAUGHT DG');
                this.setState({ isPublishingItems: false, isPublished: true, isPublishedSuccessfully: false });
            });
    }

    render() {
        return (
            <BulkEditFormView
                isUploadingExcel={this.state.isUploadingExcel}
                isPublishingItems={this.state.isPublishingItems}
                isPublished={this.state.isPublished}
                isPublishedSuccessfully={this.state.isPublishedSuccessfully}
                handleUploadExcelDocument={this.handleUploadExcelDocument.bind(this)}
                handlePublishItems={this.handlePublishItems.bind(this)}
                handleDownloadAllItems={this.handleDownloadAllItems.bind(this)}
                API_SERVER={this.state.API_SERVER}
                uploadResults={this.state.uploadResults}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    merchantData: state.merchantData,
    stores: (state.merchantData.settings.stores || []).map((id) => getStore(state, id))
});

const mapDispatchToProps = (dispatch) => ({
    loadBulkImportSettings: wrapAPIDispatch(dispatch, actions.loadBulkImportSettings),
    loadMyStoreSettings: wrapAPIDispatch(dispatch, actions.loadMyStoreSettings)
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkEditFormContainer);
