import React from 'react';
import { Button, Icon, Image, Card } from 'semantic-ui-react';
import { ReactComponent as Customer } from '../../../styles/assets/icons/customer-icon.svg';
import { ReactComponent as Store } from '../../../styles/assets/icons/store-icon.svg';
import { ReactComponent as Agent } from '../../../styles/assets/icons/agent-icon.svg';

import { withTranslation, useTranslation } from 'react-i18next';

const ProfileCard = ({ onClick, user, activeProfile, profileName, profileDesc }) => {
    const { t } = useTranslation('translations');
    let profileIcon;
    let profileBtn;
    if (profileName === 'Customer') {
        profileBtn = (
            <>
                {activeProfile ? (
                    <Button color='green' fluid size='large' disabled>
                        {t('profile.active', { defaultValue: 'Active' })}
                    </Button>
                ) : (
                    <Button animated='vertical' fluid style={{ boxSizing: 'border-box' }} onClick={onClick}>
                        <Button.Content hidden>{t('profile.switchCustomer', { defaultValue: 'Switch to Customer' })}</Button.Content>
                        <Button.Content visible>
                            <Icon name='exchange' style={{ fontSize: '19px', paddingTop: '3px' }} />
                        </Button.Content>
                    </Button>
                )}
            </>
        );
        profileIcon = (
            <Customer onClick={onClick} className='select-profile-icon' style={activeProfile ? { opacity: 0.6, display: 'block', textAlign: 'center' } : { display: 'block' }} />
        );
    } else if (profileName === 'Merchant') {
        profileBtn = (
            <>
                {activeProfile && user.merchant ? (
                    <Button color='green' fluid size='large' disabled>
                        {t('profile.active', { defaultValue: 'Active' })}
                    </Button>
                ) : (
                    <Button animated='vertical' fluid style={{ boxSizing: 'border-box' }} onClick={onClick}>
                        <Button.Content hidden>
                            {user.merchant ? t('profile.switchMerchant', { defaultValue: 'Switch to Merchant' }) : t('profile.createMerchant', { defaultValue: 'Create Merchant' })}
                        </Button.Content>
                        <Button.Content visible>
                            <Icon name={user.merchant ? 'exchange' : 'add user'} style={{ fontSize: '19px', paddingTop: '3px' }} />
                        </Button.Content>
                    </Button>
                )}
            </>
        );
        profileIcon = <Store onClick={onClick} className='select-profile-icon' style={activeProfile ? { opacity: 0.6, display: 'block' } : { display: 'block' }} />;
    } else {
        profileBtn = (
            <>
                {activeProfile && user.merchant ? (
                    <Button color='green' fluid size='large' disabled>
                        {t('profile.active', { defaultValue: 'Active' })}
                    </Button>
                ) : (
                    <Button animated='vertical' fluid style={{ boxSizing: 'border-box' }} onClick={onClick}>
                        <Button.Content hidden>
                            {user.merchant ? t('profile.switchAgent', { defaultValue: 'Switch to Agent' }) : t('profile.createAgent', { defaultValue: 'Create Agent' })}
                        </Button.Content>
                        <Button.Content visible>
                            <Icon name={user.merchant ? 'exchange' : 'add user'} style={{ fontSize: '19px', paddingTop: '3px' }} />
                        </Button.Content>
                    </Button>
                )}
            </>
        );
        profileIcon = <Agent onClick={onClick} className='select-profile-icon' style={activeProfile ? { opacity: 0.6, display: 'block' } : { display: 'block' }} />;
    }

    return (
        <Card className='select-profile-card'>
            {user?.pictureThumb != null ? (
                <div onClick={onClick} className='select-profile-icon' style={activeProfile ? { opacity: 0.6, textAlign: 'center' } : {}}>
                    <Image src={user?.pictureThumb} circular style={{ width: '190px' }} />
                </div>
            ) : (
                profileIcon
            )}

            <Card.Content>
                <Card.Header>{t(`profile.${profileName.toLowerCase()}`, { defaultValue: profileName })}</Card.Header>
                <Card.Description>{t(`profile.desc.${profileName}`, { defaultValue: profileDesc })}</Card.Description>
            </Card.Content>
            <Card.Content extra>{profileBtn}</Card.Content>
        </Card>
    );
};

export default withTranslation('translations')(ProfileCard);
