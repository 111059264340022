// @flow
import React from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { UserTypes } from 'store/enums';
import { getUser } from 'store/utilities';
import UserTeaser from 'components/TeaserCarousel/components/UserTeaser';
import StoreTeaser from 'components/TeaserCarousel/components/StoreTeaser';

type Props = {
    users: [User],
    parentUser: User,
    currentUser: EntityData<User>
};

const FollowerList = ({ users, currentUser, parentUser, addedUsers }: Props) => {
    if (!(users && users.length) && !(addedUsers && addedUsers.length)) {
        return null;
    }

    users = users.concat(addedUsers);
    return (
        <Grid className='auto full-width-list'>
            {users.map((user) => (
                <Grid.Column key={user.id} className='full-width-list-item'>
                    {user.userType === UserTypes.MERCHANT && (
                        <StoreTeaser
                            merchant={{
                                entity: user
                            }}
                            items={user.items ? user.items.map((item) => ({ entity: item })) : []}
                            parentId={parentUser.id}
                        />
                    )}
                    {user.userType === UserTypes.SHOPPER && <UserTeaser user={{ entity: user }} parentUser={parentUser} />}
                </Grid.Column>
            ))}
        </Grid>
    );
};

const mapStateToProps = (state, { usersMissingUserType }) => {
    const addedUsers = usersMissingUserType.map((user) => getUser(state, user.id).entity);
    return {
        currentUser: getUser(state, state.userData.user),
        addedUsers
    };
};

export default connect(mapStateToProps)(FollowerList);
