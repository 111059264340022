// @flow
import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    list: [],
    hasMore: true
};

function conversations(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_CONVERSATIONS: {
            const { offset, limit } = action.reducerParams;

            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'success') {
                const { conversations: results, hasNewMessage, totalUnread, totalMessage, totalUserUnread, totalSystemUnread } = action.payload.result;
                const { list } = state;
                return {
                    ...state,
                    list: offset === list.length ? list.concat(results) : results,
                    isLoading: false,
                    hasMore: results.length === limit,
                    hasNewMessage,
                    totalUnread,
                    totalMessage,
                    totalUserUnread,
                    totalSystemUnread
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            break;
        }
        case ActionTypes.MARK_ALL_STORE_USER_MESSAGES_AS_READ:
            const { totalSystemUnread } = state;
            return {
                ...state,
                hasNewMessage: totalSystemUnread > 0,
                totalUnread: totalSystemUnread,
                totalUserUnread: 0
            };
        case ActionTypes.MARK_ALL_STORE_SYSTEM_MESSAGES_AS_READ:
            const { totalUserUnread } = state;
            return {
                ...state,
                hasNewMessage: totalUserUnread > 0,
                totalUnread: totalUserUnread,
                totalSystemUnread: 0
            };
        // Reset settings store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            break;
    }
    return state;
}

export default conversations;
