// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

// import { wrapAPIDispatch } from 'services/api';
// import * as actions from 'store/actions';
import { getUser, getOffer, getOrder } from 'store/utilities';

import MessageView from './MessageView';

type Props = {};

class MessageContainer extends Component<Props> {
    render() {
        const { message, me, otherUser, item, negotiationBox, offer, conversation, lastOfferId, order } = this.props;
        return (
            <MessageView
                message={message}
                negotiate={negotiationBox}
                me={me}
                otherUser={otherUser}
                item={item}
                offer={offer}
                conversation={conversation}
                lastOfferId={lastOfferId}
                order={order}
            />
        );
    }
}

const mapStateToProps = (state, { otherUser: otherUserId, message }) => ({
    offer: getOffer(state, message.entity.offer),
    order: getOrder(state, message.entity.order),
    otherUser: getUser(state, otherUserId)
});

export default compose(connect(mapStateToProps))(MessageContainer);
