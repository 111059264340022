// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import type { Node } from 'react';
import { Grid } from 'semantic-ui-react';

import PaginatedGrid from 'components/PaginatedGrid';
import SortDropdown from 'components/SortDropdown';

type Props = {
    title?: Node,
    componentType?: string
};

function SortablePaginatedGrid({ title, componentId, componentType, ...rest }: Props) {
    return (
        <Grid className='auto'>
            <Grid.Column className='col-row'>
                <Grid style={{ marginTop: -37 }} className='auto vertically-tensed grid-align-items-center'>
                    <Grid.Column className='col-grow'>{title}</Grid.Column>
                    <Grid.Column className='col-shrink'>
                        <SortDropdown componentType={componentType} componentId={componentId} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column className='col-row'>
                <PaginatedGrid componentType={componentType} {...rest} />
            </Grid.Column>
        </Grid>
    );
}

SortablePaginatedGrid.defaultProps = {
    title: null
};

export default SortablePaginatedGrid;
