// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch, Authentication } from 'services/api';
import * as actions from 'store/actions';
import { getUser, getConversation } from 'store/utilities';

import MyStoreMessagesView from './MyStoreMessagesView';

type Props = {
    loadMyStoreConversations: typeof actions.loadMyStoreConversations
};

type State = {
    isLoadingMoreMessages: boolean,
    hasLoadedConversations: boolean
};

class MyStoreMessagesContainer extends Component<Props, State> {
    state = {
        isLoadingMoreMessages: false,
        hasLoadedConversations: false
    };

    componentDidMount() {
        this.loadConversations().then(() => {
            this.props.markAllStoreUserMessagesAsRead();
        });
    }

    loadConversations = () => {
        const { loadMyStoreConversations } = this.props;
        const { isLoadingConversations } = this.state;
        if (!isLoadingConversations) {
            loadMyStoreConversations({ offset: 0 }).catch((error) => console.error(error));
        }
        return Promise.resolve(true);
    };

    loadMoreConversations = () => {
        const { loadMyStoreConversations, conversations, hasMore, isLoading } = this.props;
        if (!isLoading && hasMore) {
            loadMyStoreConversations({ offset: conversations.length }).catch((error) => console.error(error));
        }
    };

    loadMoreMessages = ({ conversation, offset }) => {
        this.setState({ isLoadingMoreMessages: true });
        const { loadMyStoreMessages } = this.props;
        if (conversation.entity) {
            const { conversationKey, typeId, item: itemId } = conversation.entity;
            loadMyStoreMessages({ conversationKey, typeId, itemId, offset })
                .then(() => this.setState({ isLoadingMoreMessages: false }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingMoreMessages: false });
                });
        }
    };

    render() {
        const { conversations, me } = this.props;
        return (
            <MyStoreMessagesView authentication={Authentication.Merchant} conversations={conversations} me={me} onLoadMoreMessages={this.loadMoreMessages} type='myStoreMessages' />
        );
    }
}

const mapStateToProps = (state) => ({
    conversations: state.merchantData.conversations.list.map((conversationKey) => getConversation(state, conversationKey)),
    hasMore: state.merchantData.conversations.hasMore,
    isLoading: state.merchantData.conversations.isLoading,
    me: getUser(state, state.merchantData.user)
});

const mapDispatchToProps = (dispatch) => ({
    loadMyStoreConversations: wrapAPIDispatch(dispatch, actions.loadMyStoreConversations),
    loadMyStoreMessages: wrapAPIDispatch(dispatch, actions.loadMyStoreMessages),
    markAllStoreUserMessagesAsRead: wrapAPIDispatch(dispatch, actions.markAllStoreUserMessagesAsRead)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyStoreMessagesContainer);
