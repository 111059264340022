import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Header, Button, Divider, Icon } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalNames, openModal } from 'store/actions';
import { getIsLoggedIn } from 'store/utilities';

function CheckoutCompleteScene() {
    const isLoggedIn = useSelector((state) => getIsLoggedIn(state));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoggedIn) {
            dispatch(openModal(ModalNames.REGISTER_ACCOUNT));
        }
    }, [isLoggedIn]);

    return (
        <Container text className='narrow'>
            <Header as='h1'>
                <Trans i18nKey='checkoutCompleteScene.header'>Thank you for your purchase!</Trans>
            </Header>
            <p>
                <Trans i18nKey='checkoutCompleteScene.info'>We are getting your order ready for delivery. View your order to see its delivery status.</Trans>
            </p>
            <Link to='/my-profile/purchases'>
                <Button basic fluid icon>
                    <Trans i18nKey='checkoutCompleteScene.button.orderHistory'>View Order History</Trans> <Icon name='history' />
                </Button>
            </Link>
            <Divider horizontal>
                <Trans i18nKey='checkoutCompleteScene.divider'>Or</Trans>
            </Divider>
            <Link to='/'>
                <Button primary fluid icon>
                    <Trans i18nKey='checkoutCompleteScene.button.continueShopping'>Continue Shopping</Trans> <Icon name='shopping cart' />
                </Button>
            </Link>
        </Container>
    );
}

export default withTranslation('translations')(CheckoutCompleteScene);
