// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type PostCommentParams = {
    itemId: number,
    text: string,
    authentication: Authentication.User | Authentication.Merchant
};

// POST_COMMENT ----------------------
export const POST_COMMENT = 'POST_COMMENT';
export const postComment = wrapAPIActionCreator(({ itemId, text, authentication = Authentication.User }: PostMessageParams) => ({
    [API_DATA]: {
        type: POST_COMMENT,
        endpoint: `/v3/items/${itemId}/comments`,
        authentication,
        method: 'POST',
        body: {
            itemId,
            text
        },
        reducerParams: { itemId }
    }
}));
