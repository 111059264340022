// @flow
import React from 'react';
import { Menu, Icon, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import * as actions from 'store/actions';

type Props = {
    quantity: number,
    openModal: typeof actions.openModal
};

function TopBarCart({ quantity, isLoggedIn, openModal }: Props) {
    const history = useHistory();

    function onCartClick() {
        if (isLoggedIn) {
            history.push('/cart');
        } else {
            openModal(actions.ModalNames.REGISTER_ALERT);
        }
    }

    return (
        <Menu.Item onClick={onCartClick} active={false} className={`page-header-cart${quantity < 1 ? ' page-header-cart-empty' : ''} item-icon`}>
            <Icon className='page-header-cart-icon' name='shopping cart' fitted />
            <Label className='page-header-cart-label' floating size='mini' circular>
                {quantity}
            </Label>
        </Menu.Item>
    );
}

export default TopBarCart;
