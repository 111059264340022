// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Grid, Icon, Input, Table, Message } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';
import CommonForm from 'components/CommonForm';

type Props = {
    t: (string) => string
};

type State = {
    excelFile: {
        value: string,
        error: boolean
    }
};

class BulkEditFormView extends CommonForm<Props, State> {
    state = {
        excelFile: {
            value: '',
            error: false
        }
    };

    handleChangeFile = (event, data) => {
        const file = event.target.files[0];
        this.setState({ excelFile: file }, () => this.props.handleUploadExcelDocument(this.state.excelFile));
        event.target.value = null;
    };

    handlePublishItems = () => {
        this.props.handlePublishItems(this.state.excelFile);
    };

    render() {
        const API_SERVER = (process.env.REACT_APP_MYBAZAR_API_SERVER || '').replace(/\/$/, '');
        if (API_SERVER === '') {
            throw new Error('Must define REACT_APP_MYBAZAR_API_SERVER');
        }
        const presentationUrl = `${API_SERVER}/static/bulkoperations/MB_BulkImport_UsersGuide.pdf`;
        const { isUploadingExcel, isPublishingItems, isPublished, isPublishedSuccessfully, uploadResults, handleDownloadAllItems } = this.props;
        const publishItems = uploadResults.length > 0 && uploadResults.filter((row) => !row.valid).length === 0;
        return (
            <div className='sell-item'>
                <Form>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Grid className='auto tensed'>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Button type='submit' fluid primary onClick={handleDownloadAllItems}>
                                                <Trans i18nKey='bulkEdit.downloadItems'>Download All Items</Trans>
                                            </Button>
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field>
                                            <Button
                                                as='label'
                                                icon
                                                labelPosition='left'
                                                htmlFor='my-profile-cover-photo-file'
                                                disabled={isUploadingExcel}
                                                loading={isUploadingExcel}
                                            >
                                                <Icon name='upload' />
                                                <Trans i18nKey='uploadChanges.button'>Upload Changes</Trans>
                                            </Button>
                                            <Input
                                                type='file'
                                                name='file'
                                                id='my-profile-cover-photo-file'
                                                className='store-logo-input'
                                                disabled={isUploadingExcel}
                                                onChange={this.handleChangeFile}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                </Grid>
                                {false && (
                                    <Grid className='auto tensed'>
                                        <Grid.Column width={8}>
                                            <Form.Field>
                                                <Button
                                                    type='submit'
                                                    fluid
                                                    primary
                                                    disabled={!publishItems || isPublishedSuccessfully}
                                                    loading={isPublishingItems}
                                                    onClick={this.handlePublishItems}
                                                >
                                                    <Trans i18nKey='bulkEdit.publishItems'>Publish Edited Items</Trans>
                                                </Button>
                                            </Form.Field>
                                        </Grid.Column>
                                    </Grid>
                                )}
                            </Grid.Column>
                            <Grid.Column width={8} verticalAlign='middle'>
                                <Grid centered columns={3}>
                                    <Grid.Row centered columns={3}>
                                        <Grid.Column>
                                            <a
                                                className='ui button fluid'
                                                style={{ minHeight: '5rem', display: 'flex', alignItems: 'center' }}
                                                href={presentationUrl}
                                                target='_blank'
                                            >
                                                <Trans i18nKey='bulkEdit.instructions'>How to use Bulk Edit</Trans>
                                            </a>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>

                {isPublished && isPublishedSuccessfully && (
                    <Message>
                        <Message.Header>
                            <Trans i18nKey='bulkEdit.published.success.header'>Update Successful</Trans>
                        </Message.Header>
                        <p>
                            <Trans i18nKey='bulkEdit.published.success.msg'>
                                Your data has been successfully updated. You can see your items <Link to='/my-store/items'>here</Link>.
                            </Trans>
                        </p>
                    </Message>
                )}

                {isPublished && !isPublishedSuccessfully && (
                    <Message>
                        <Message.Header>
                            <Trans i18nKey='bulkEdit.published.failed.header'>Update Error</Trans>
                        </Message.Header>
                        <p>
                            <Trans i18nKey='bulkEdit.published.failed.msg'>
                                Your data has not been successfully updated. Please review the instructions for bulk edit and try again.
                            </Trans>
                        </p>
                    </Message>
                )}

                {uploadResults && uploadResults.length > 0 && (
                    <Table celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Trans i18nKey='bulkEdit.table.row'>Row </Trans> #
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Trans i18nKey='bulkEdit.table.data'>Data</Trans>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Trans i18nKey='bulkEdit.table.val'>Valid / Error </Trans>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {uploadResults.map((row, idx) => (
                                <Table.Row key={idx}>
                                    <Table.Cell>{idx + 1}.</Table.Cell>
                                    <Table.Cell>{row.item.name}</Table.Cell>
                                    <Table.Cell>
                                        <div>{row.valid ? <Trans i18nKey='bulkEdit.table.valid'>Valid</Trans> : <Trans i18nKey='bulkEdit.table.invalid'>Invalid</Trans>}</div>
                                        {!row.valid && (
                                            <ul>
                                                {row.error.error_message.map((m, i) => (
                                                    <li key={i + 1}> {m} </li>
                                                ))}
                                            </ul>
                                        )}
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </div>
        );
    }
}

export default withTranslation('translations')(BulkEditFormView);
