// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateAddressParams = {
    addressId: number,
    fullName: string,
    emailAddress: string,
    contactNo: string,
    street: string,
    houseNo: string,
    zip: string,
    country: string,
    state: string,
    city: string,
    country_id: number,
    state_id: number,
    city_id: number
};

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const updateAddress = wrapAPIActionCreator(
    ({ addressId, fullName, emailAddress, contactNo, street, houseNo, zip, country, state, city, country_id, state_id, city_id }: UpdateAddressParams) => ({
        [API_DATA]: {
            type: UPDATE_ADDRESS,
            endpoint: `/v3/addresses/${addressId}`,
            method: 'PUT',
            authentication: Authentication.User,
            body: {
                fullName,
                emailAddress,
                contactNo,
                street,
                houseNo,
                zip,
                country,
                state,
                city,
                country_id,
                state_id,
                city_id
            },
            schema: [Schemas.userDeliveryAddress]
        }
    })
);

export default updateAddress;
