// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getCategory } from 'store/utilities';
import type { EntityData, Category, LandingComponent } from 'store/types';

import LandingCategoryListView from './LandingCategoryListView';

type Props = {
    component: EntityData<LandingComponent>,
    categories: EntityData<Category>[],
    title: string
};

function LandingCategoryListContainer({ component, categories, title }: Props) {
    return <LandingCategoryListView {...{ component, categories, title }} />;
}

const mapStateToProps = (state, { component }) => {
    const { entities = [] } = component.entity || {};
    const categories = entities.map(({ id }) => getCategory(state, id));
    return {
        categories
    };
};

export default compose(connect(mapStateToProps))(LandingCategoryListContainer);
