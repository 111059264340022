// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_FOLLOWERS ------------------
export type loadFollowersParams = {
    userId: number,
    offset: number,
    totalCount: number,
    limit?: number
};

export const LOAD_FOLLOWERS = 'LOAD_FOLLOWERS';
export const loadFollowers = wrapAPIActionCreator(({ userId, offset, limit, totalCount }: loadFollowersParams) => ({
    [API_DATA]: {
        type: LOAD_FOLLOWERS,
        endpoint: `/v3/users/${userId}/followers`,
        optionalAuthentication: Authentication.User,
        endpointParams: {
            offset,
            limit: limit || 16
        },
        reducerParams: {
            offset,
            limit: limit || 16,
            userId,
            totalCount
        },
        schema: Schemas.User
    }
}));

export default loadFollowers;
