// @flow
import React, { useState } from 'react';
import { Grid, Message } from 'semantic-ui-react';

import CartList from 'components/CartList';
import type { Cart } from 'store/types';
import CheckoutSteps from './components/CheckoutSteps';
import CheckoutDetails from './components/CheckoutDetails';
import GatewayError from './components/GatewayError';
import { CheckoutStep, PaymentMethod } from './index';
import { withTranslation } from 'react-i18next';

type Props = {
    initialStep?: $Values<typeof CheckoutStep>,
    error?: {
        message: string,
        gatewayParams: { [string]: mixed }
    },
    cart: Cart
};

function Checkout({ initialStep = CheckoutStep.DELIVERY, cart, error }: Props) {
    const [step, setStep] = useState(initialStep);
    const [stepCompleted, setStepCompleted] = useState(initialStep);
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.CreditCard);

    const getAvailableControls = () => {
        switch (step) {
            case CheckoutStep.DELIVERY:
                return ['remove', 'delivery-option'];
            case CheckoutStep.PAYMENT:
                return ['remove', 'payment-option'];
            default:
                return [];
        }
    };

    const nextStep = async () => {
        setStep((s) => s + 1);
        setStepCompleted((s) => s + 1);
    };

    const setPaymentMethodSafe = (paymentMethod) => {
        if (paymentMethod) {
            setPaymentMethod(paymentMethod);
        }
    };

    const { errorMessage } = cart;

    const { message, forStep } = cart.error || {};
    const isErrorRelevant = cart.error && forStep <= step + 1;

    const handleMessages = (message: string) => {
        switch (message) {
            case 'Please add delivery address':
                return 'Molimo dodajte adresu za dostavu.';
            default:
                return message;
        }
    }
    return (
        <div className='checkout'>
            <Grid>
                <Grid.Column>
                    <CheckoutSteps step={step} stepCompleted={stepCompleted} changeStep={setStep} />
                    {error && <GatewayError gatewayParams={error.gatewayParams} />}
                    <Grid stackable>
                        <Grid.Column width={10}>
                            {errorMessage && <Message error>{handleMessages(errorMessage)}</Message>}
                            {cart.error && isErrorRelevant && <Message error>{handleMessages(message)}</Message>}
                            <CartList list={cart.cartItems} showQuantity step={step} controls={getAvailableControls()} />
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <CheckoutDetails step={step} nextStep={nextStep} paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethodSafe} />
                        </Grid.Column>
                    </Grid>
                </Grid.Column>
            </Grid>
        </div>
    );
}

export default withTranslation('translations')(Checkout);
