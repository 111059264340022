// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon } from 'semantic-ui-react';

type Props = {
    item: Object,
    first: boolean,
    last: boolean,
    moveImage: () => void
};

function SellItemImageListItemMove({ item, first, last, moveImage }: Props) {
    if (first && last) {
        return null;
    }
    return (
        <Button.Group className='sell-item-image-list-item-move' icon fluid>
            {!first && (
                <Button circular onClick={() => moveImage(item.index, 'left')}>
                    <Icon name='chevron left' />
                </Button>
            )}
            {!last && (
                <Button circular onClick={() => moveImage(item.index, 'right')}>
                    <Icon name='chevron right' />
                </Button>
            )}
        </Button.Group>
    );
}

export default withTranslation('translations')(SellItemImageListItemMove);
