// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Comment } from 'semantic-ui-react';

import { Comment as CommentType } from 'store/types';

import { formatDatestamp } from 'utilities/format';
import missingUserImage from 'styles/assets/images/missing-user-image.svg';

type Props = {
    comment: CommentType,
    t: (string) => string
};

function CommentView({ comment, t }: Props) {
    return (
        <Comment>
            <Comment.Avatar className='ui image circular-image' src={(comment.commenter && comment.commenter.pictureThumb) ? comment.commenter.pictureThumb : missingUserImage} />
            <Comment.Content>
                <Comment.Author>{comment.commenter?.alias}</Comment.Author>
                <Comment.Metadata>{formatDatestamp(comment.createdAt, t)}</Comment.Metadata>
                <Comment.Text>{comment.text}</Comment.Text>
            </Comment.Content>
        </Comment>
    );
}

export default withTranslation('translations')(CommentView);
