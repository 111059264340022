// @flow
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, Header, Image } from 'semantic-ui-react';

import type { EntityData, Category } from 'store/types';

type Props = {
    onClick: (SyntheticEvent<>, { categoryId: number, categorySlug: string }) => void,
    isActive?: boolean,
    // from store
    category: EntityData<Category>
};

function CategoryTeaserView({ onClick, isActive, category }: Props) {
    const { t } = useTranslation('translations');
    if (category.isFetching || category.entity == null) {
        return null;
    }
    const { id, slug, name, imageFull } = category.entity;
    const handleClick = (event) => {
        onClick(event, { categoryId: id, categorySlug: slug });
    };

    return (
        <Button onClick={handleClick} className={isActive ? 'category-teaser-active' : 'category-teaser'}>
            <Image fluid src={imageFull} />
            <Header as='h3' className={isActive ? 'category-teaser-header-active' : 'category-teaser-header'}>
                {name}
            </Header>
        </Button>
    );
}

CategoryTeaserView.defaultProps = {
    isActive: false
};

export default CategoryTeaserView;
