// @flow
import * as ActionTypes from 'store/actions';

const initialState = {
    hasMoreStores: true
};

function storesByKeyword(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SEARCH_STORES_BY_KEYWORD:
            if (action.status === 'request') {
                return {
                    ...state,
                    storesByKeywordIsLoading: true
                };
            }
            if (action.status === 'success') {
                const { query, offset, result } = action.payload;
                const { stores } = result;
                const { sort, limit } = action.reducerParams;
                let existingItems = [];
                let hasMoreStores = true;

                if (state[query]) {
                    if (state[query].sort === sort) {
                        existingItems = [...state[query].existingItems];
                    }
                }

                if (offset === existingItems.length) {
                    existingItems = existingItems.concat(stores);
                }

                if (stores.length !== limit) {
                    hasMoreStores = false;
                }

                return {
                    ...state,
                    hasMoreStores,
                    [query]: { sort, existingItems },
                    storesByKeywordIsLoading: false
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    storesByKeywordIsLoading: false
                };
            }
            break;
        default:
            break;
    }
    return state;
}

export default storesByKeyword;
