// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import { Message } from 'semantic-ui-react';

import { PaymentErrors } from 'services/paymentGateway/ipay88';

const errorMessageKeys = {
    [PaymentErrors.USER_CANCEL]: 'userCancel',
    default: 'default'
};

type Props = {
    gatewayParams: {
        ErrDesc: string
    },
    t: (string | string[]) => string
};

function GatewayError({ gatewayParams, t }: Props) {
    const { ErrDesc } = gatewayParams;
    const key = errorMessageKeys[ErrDesc] || errorMessageKeys.default;
    const content = t([`checkout.paymentError.${key}`, 'checkout.paymentError.default']);
    return <Message error header='Payment problem' content={content} />;
}

export default withTranslation('translations')(GatewayError);
