// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getUserDeliveryAddress } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import DeliveryAddressView from './DeliveryAddressView';

type Props = {
    updateCartAddress?: boolean,
    // from store
    openModal: typeof actions.openModal
};

class DeliveryAddressContainer extends Component<Props> {
    static defaultProps;

    handleOpenAddAddress = () => {
        const { openModal, updateCartAddress } = this.props;
        openModal(actions.ModalNames.DELIVERY_ADDRESS_ADD, { updateCartAddress });
    };

    handleOpenEditAddress = (addressId) => {
        const { openModal } = this.props;
        openModal(actions.ModalNames.DELIVERY_ADDRESS_EDIT, { addressId });
    };

    render() {
        return <DeliveryAddressView onOpenAddAddress={this.handleOpenAddAddress} onOpenEditAddress={this.handleOpenEditAddress} {...this.props} />;
    }
}

DeliveryAddressContainer.defaultProps = {
    updateCartAddress: false
};

const mapStateToProps = (state) => ({
    addresses: state.userData.cart.addresses || [],
    getUserDeliveryAddress: (id) => getUserDeliveryAddress(state, id),
    selectedAddress: state.userData.cart.addressId
});

const mapDispatchToProps = (dispatch) => ({
    openModal: (...args) => dispatch(actions.openModal(...args)),
    setCartAddress: wrapAPIDispatch(dispatch, actions.setCartAddress)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeliveryAddressContainer);
