// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Form, Button, Icon, Modal, Header, Message, Grid } from 'semantic-ui-react';

import CommonForm, { createEmptyFormFieldData } from 'components/CommonForm';
import { getVariantHeading, getVariantOptionText } from 'components/Product/components/ProductActions/ProductActionsView';

import { formatPrice, formatOfferDuration } from 'utilities/format';
import MessageProductView from '../MessageProductView';

class OfferActionFormView extends CommonForm {
    state = {
        isPosting: false,
        errorMessage: null,
        formValues: {
            selectedVariant: createEmptyFormFieldData({ value: '', type: 'number', required: true })
        },
        showModal: false
    };

    handleDeclineOffer = () => {
        const { offer, conversation, declineOffer, loadMyMessages, onPostMessageSuccess = () => {} } = this.props;

        this.setState({ isPosting: true });
        declineOffer({ offer: offer.entity })
            .then(() => {
                const { conversationKey, typeId, item: itemId } = conversation.entity;
                loadMyMessages({ conversationKey, typeId, itemId, limit: 20 });
                this.setState({ isPosting: false });
                onPostMessageSuccess();
            })
            .catch((error) => {
                this.setState({ errorMessage: error.message, isPosting: false });
            });
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const { offer, product } = this.props;

        if (!this.validate()) {
            return;
        }
        const { formValues } = this.state;

        const {
            selectedVariant: { value: selectedVariant }
        } = formValues;

        this.setState({ isPosting: true });
        this.props
            .acceptOffer({
                offer: offer.entity,
                productId: product.entity.id,
                variantId: selectedVariant
            })
            .then(() => this.onSuccess())
            .catch((error) => {
                this.setState({ errorMessage: error.message, isPosting: false });
            });
    };

    onSuccess = () => {
        const { formValues } = this.state;
        this.setState({ formValues, showModal: false, isPosting: false });
    };

    onCancel = () => {
        this.setState({ showModal: false });
    };

    render() {
        const { isPosting, showModal, errorMessage } = this.state;

        const { item, variants, offer, otherUser, merchantSide, t } = this.props;

        const { offerPrice, quantity, offerTotalPrice, timeLeft } = offer.entity;
        const timeL = timeLeft && formatOfferDuration(timeLeft, t);

        const formattedOfferPrice = formatPrice(Number(offerPrice));
        const formattedOfferTotalPrice = formatPrice(Number(offerTotalPrice));

        const variantKeys =
            variants.length > 0
                ? Object.keys(variants[0].entity.variantBody || {})
                      // Strip keys where all values are empty
                      .filter((key) => variants.every(({ entity }) => entity.variantBody && entity.variantBody[key] !== ''))
                : [];

        const variantHeading = variants.length > 0 ? getVariantHeading(variants[0].entity, variantKeys) : '';

        return (
            <Grid className='auto very-tensed'>
                <Grid.Column className='col-row'>
                    <p>
                        {quantity === 1 &&
                            (merchantSide ? (
                                <Trans i18nKey='messages.conversation.offer.offerDescription'>
                                    You have offered this item for a price of <strong>{formattedOfferPrice}</strong>
                                </Trans>
                            ) : (
                                <Trans i18nKey='messages.conversation.offer.offerDescCustomer'>
                                    Merchant is offering this item for a price of <strong>{{ formattedOfferPrice }}</strong>.
                                </Trans>
                            ))}
                        {quantity > 1 &&
                            (merchantSide ? (
                                <Trans i18nKey='messages.conversation.offer.offerPluralityDescription'>
                                    You are offering <strong>{{ quantity }}</strong> of this item for a price of <strong>{{ formattedOfferTotalPrice }}</strong>, (
                                    {{ formattedOfferPrice }} per item).
                                </Trans>
                            ) : (
                                <Trans i18nKey='messages.conversation.offer.offerPluralityDescCustomer'>
                                    Merchant is offering <strong>{{ quantity }}</strong> of this item for a price of <strong>{{ formattedOfferTotalPrice }}</strong>, (
                                    {{ formattedOfferPrice }} per item).
                                </Trans>
                            ))}
                    </p>
                </Grid.Column>
                <Grid.Column className='col-shrink'>
                    <Button color='gray' icon labelPosition='left' style={{ display: merchantSide ? 'none' : 'block' }} loading={isPosting} onClick={this.handleDeclineOffer}>
                        <Icon name='close' />
                        <Trans i18nKey='messages.conversation.offer.actions.decline'>Decline</Trans>
                    </Button>
                </Grid.Column>
                <Grid.Column className='col-shrink'>
                    <Modal
                        open={showModal}
                        size='small'
                        onClose={() => this.setState({ showModal: false })}
                        trigger={
                            <Button
                                color='black'
                                icon
                                style={{ display: merchantSide ? 'none' : 'block' }}
                                labelPosition='left'
                                loading={isPosting}
                                onClick={() => this.setState({ showModal: true })}
                            >
                                <Icon name='check' />
                                <Trans i18nKey='messages.conversation.offer.actions.accept'>Accept</Trans>
                            </Button>
                        }
                    >
                        <Header>
                            <Header.Content>
                                <Trans i18nKey='messages.conversation.offer.header'>Select Variant</Trans>
                            </Header.Content>
                        </Header>
                        <Modal.Content>
                            <Grid.Row>
                                <Grid.Column>
                                    <MessageProductView item={item} offer={offer} user={otherUser} />
                                </Grid.Column>
                            </Grid.Row>
                            <Form error={errorMessage != null}>
                                <Message error>{errorMessage}</Message>

                                {variants.length > 0 && (
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Header as='h2' size='small'>
                                                {variantHeading}
                                            </Header>

                                            {this.renderDropdownField(
                                                {
                                                    name: 'selectedVariant',
                                                    options: variants.map(({ entity }) => ({
                                                        value: entity.id,
                                                        text: getVariantOptionText(entity, variantKeys)
                                                    }))
                                                },
                                                this.props
                                            )}
                                        </Grid.Column>
                                    </Grid.Row>
                                )}
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button basic type='button' onClick={this.onCancel}>
                                <Trans i18nKey='messages.conversation.offer.actions.cancel'>Cancel</Trans>
                            </Button>
                            <Button primary icon labelPosition='left' type='submit' onClick={this.handleSubmit} loading={isPosting}>
                                <Icon name='check' />
                                <Trans i18nKey='messages.conversation.offer.actions.addToCart'>Add To Cart</Trans>
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Column>
                {timeLeft && (
                    <Grid.Column className='col-row'>
                        <p style={{ color: 'gray', fontSize: '13px' }}>{timeL}</p>
                    </Grid.Column>
                )}
            </Grid>
        );
    }
}

export default withTranslation('translations')(OfferActionFormView);
