// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import MyAgentOfficeView from './MyAgentOfficeView';

type Props = {
    // from store
};

const MyAgentOfficeContainer = () => {
    // componentDidMount() {
    //   const { activity, loadActivity } = this.props;

    //   if (!activity.isLoadingActivity) {
    //     loadActivity();
    //   }
    // }

    return (
        // const { orders, items, activity, sales } = this.props;

        <MyAgentOfficeView />
    );
};

const mapStateToProps = (state) => ({
    // orders: state.merchantData.orders,
    // items: state.merchantData.items,
    // activity: state.merchantData.activity,
    // sales: state.merchantData.sales,
});

const mapDispatchToProps = (dispatch) => ({
    // loadActivity: wrapAPIDispatch(dispatch, actions.loadMyStoreActivity),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyAgentOfficeContainer);
