// @flow
import React, { Component } from 'react';
import { Button, Form, Label, Message, Input, Icon } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

class LogInForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasTriggered: false,
            errorMessage: null,
            username: {
                value: '',
                error: false
            },
            password: {
                value: '',
                error: false
            },
            passwordShown: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.isLoggedIn && props.isNonTempUser && !state.hasTriggered) {
            props.onSuccess();
            return {
                hasTriggered: true
            };
        }
        return null;
    }

    handleChange = (event) => {
        const target = this.state[event.target.name];
        target.value = event.target.value;
        this.setState({ [event.target.name]: target });
    };

    handleBlur = (event) => {
        this.validate(event.target.name).catch(() => {});
    };

    togglePassword = () => {
        this.setState({ passwordShown: !this.state.passwordShown });
    };

    handleSubmit = (event) => {
        const { loginUser, loadCartItems } = this.props;
        const {
            username: { value: username },
            password: { value: password }
        } = this.state;

        event.preventDefault();
        this.props.setIsLoggingIn(true);

        this.validate('username', 'password')
            .then(() => loginUser({ email: username, password }))
            .then(() => {
                this.props.setIsLoggingIn(false);
                return loadCartItems();
            })
            .catch((err) => {
                this.props.setIsLoggingIn(false);
                this.setState({ errorMessage: err.message });
            });
    };

    validate = (...inputs) => {
        let valid = true;
        inputs.forEach((input) => {
            const target = this.state[input];
            target.error = target.value.length === 0;
            if (target.error) {
                valid = false;
            }
            this.setState({ [input]: target });
        });
        return valid ? Promise.resolve() : Promise.reject(new Error('Some fields were invalid'));
    };

    render() {
        const { errorMessage } = this.state;

        return (
            <Form onSubmit={this.handleSubmit} error={errorMessage !== null} style={{ textAlign: 'left', margin: '20px 0 0 0' }}>
                <Message error>
                    <Trans i18nKey='logInForm.errorMessage'>Wrong user name or password!</Trans>
                </Message>
                <Form.Field error={this.state.username.error}>
                    <label htmlFor='log-in-username'>
                        <Trans i18nKey='logInForm.username.label'>Email address</Trans>
                    </label>
                    <input
                        type='text'
                        name='username'
                        id='log-in-username'
                        value={this.state.username.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('logInForm.username.placeholder', { defaultValue: '' })}
                        disabled={this.props.isLoggingIn}
                    />
                    {this.state.username.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='logInForm.username.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field error={this.state.password.error}>
                    <label htmlFor='log-in-password'>
                        <Trans i18nKey='logInForm.password.label'>Password</Trans>
                    </label>
                    <Input
                        type={this.state.passwordShown ? 'text' : 'password'}
                        name='password'
                        id='log-in-password'
                        value={this.state.password.value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        placeholder={this.props.t('logInForm.password.placeholder', { defaultValue: '' })}
                        disabled={this.props.isLoggingIn}
                        icon={() => <Icon onClick={() => this.togglePassword()} style={{ cursor: 'pointer', pointerEvents: 'unset' }} name='eye' />}
                    />
                    {this.state.password.error && (
                        <Label basic color='red' pointing>
                            <Trans i18nKey='logInForm.password.error.required'>This field is required.</Trans>
                        </Label>
                    )}
                </Form.Field>
                <Form.Field>
                    <Button className='standard-color-button' type='submit' fluid primary loading={this.props.isLoggingIn} disabled={this.props.isLoggingIn}>
                        <Trans i18nKey='logInForm.loginButton'>Continue with email</Trans>
                    </Button>
                </Form.Field>
            </Form>
        );
    }
}

export default withTranslation('translations')(LogInForm);
