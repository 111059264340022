// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Grid } from 'semantic-ui-react';

import SellItemImageListItem from './components/SellItemImageListItem';
import SellItemImageAdd from './components/SellItemImageAdd';

type Props = {
    list: Array,
    limit: number,
    addImage: () => void,
    deleteImage: () => void,
    moveImage: () => void
};

function SellItemImageList({ list, limit, addImage, deleteImage, moveImage }: Props) {
    return (
        <Form.Field>
            <Grid className='tensed'>
                {list.length < limit && (
                    <Grid.Column style={{ height: '135px' }} width={16}>
                        <SellItemImageAdd addImage={addImage} />
                    </Grid.Column>
                )}
                {list.map((item, index) => (
                    <Grid.Column key={item.index} width={4}>
                        <SellItemImageListItem item={item} first={index === 0} last={index === list.length - 1} deleteImage={deleteImage} moveImage={moveImage} />
                    </Grid.Column>
                ))}
            </Grid>
        </Form.Field>
    );
}

export default withTranslation('translations')(SellItemImageList);
