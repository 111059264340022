// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';

import MyStoreItemsTabView from './MyStoreItemsTabView';

function MyStoreItemsTabContainer(props) {
    return <MyStoreItemsTabView {...props} />;
}

export default MyStoreItemsTabContainer;
