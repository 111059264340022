// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment, Header, Button, Icon, List, Divider } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import { formatPrice } from 'utilities/format';

type Props = {
    total: number,
    shippingFee: number,
    vat: number,
    subtotal: number,
    itemCount: number
};

function CartCheckout({ total, shippingFee, vat, subtotal, itemCount }: Props) {
    return (
        <Segment className='cart-checkout semi-simple'>
            <Grid className='tensed'>
                <Grid.Row>
                    <Grid.Column>
                        <List>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(subtotal)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.subTotal' count={itemCount}>
                                        Subtotal ({{ count: itemCount }} items)
                                    </Trans>
                                    :
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(shippingFee)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.payment.shippingFee'>Shipping Fee</Trans>:
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content floated='right'>{formatPrice(vat)}</List.Content>
                                <List.Content>
                                    <Trans i18nKey='checkout.details.review.vat'>VAT</Trans>:
                                </List.Content>
                            </List.Item>
                        </List>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Divider fitted />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='auto tensed'>
                            <Grid.Column>
                                <Header as='h3'>
                                    <Trans i18nKey='cart.checkout.total'>Total</Trans>:
                                </Header>
                            </Grid.Column>
                            <Grid.Column className='col-text-align-right'>
                                <Header size='h3'>{formatPrice(total)}</Header>
                            </Grid.Column>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Link to='/checkout'>
                            <Button primary fluid icon>
                                <Trans i18nKey='cart.checkout.button'>Checkout</Trans> <Icon name='shopping cart' />
                            </Button>
                        </Link>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            {/*
                 <Grid className='auto vertically-tensed grid-align-items-center teaser-carousel-header'>
                     <Grid.Column className='col-grow'>
                         <p style={{ marginBottom: '0', color: '#000000' }}>
                             <Trans i18nKey='cart.checkout.subTotal'>Subtotal</Trans>: {formatPrice(subtotal)}
                         </p>
                         <p style={{ color: '#888888', fontSize: '13px' }}>
                             <Trans i18nKey='cart.checkout.checkoutText'>Shipping, taxes, and discount codes calculated at checkout.</Trans></p>
                     </Grid.Column>

                     <Grid.Column className='col-shrink'>
                         <Link to='/checkout'>
                             <Button style={{ color: '#fff', backgroundColor: '#000' }} key='remove'>
                                 <Trans i18nKey='cart.checkout.button'>Checkout</Trans>
                             </Button>
                         </Link>
                     </Grid.Column>
                 </Grid>
                  */}
        </Segment>
    );
}

export default withTranslation('translations')(CartCheckout);
