// @flow
import React from 'react';
import { Header, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withTranslation, Trans } from 'react-i18next';

import { formatItemDescription, formatItemTags } from 'utilities/format';
import Price from 'components/Price';
import RichTextView from 'components/RichTextView';
import TagButtons from 'components/TagButtons';

import ProductCarousel from './components/ProductCarousel';
import ProductStore from './components/ProductStore';
import ProductActions from './components/ProductActions';
import ProductShipping from './components/ProductShipping';

type Props = {
    product: {},
    variants: [],
    onChangeVariant: (?number) => void,
    onSendMessageAction: () => void,
    onDidFocusSendMessage: () => void,
    selectedVariant: ?number,
    user: number,
    merchant: {}
};

function Product({ product, merchant, onChangeVariant, selectedVariant, isLoggedIn, user, onSendMessageAction, onDidFocusSendMessage, focusSendMessage, variants }: Props) {
    const showNegotiate = (product.allowNegotiate === true || product.allowNegotiate === 1) && user;
    const showMessage = !showNegotiate && user;

    // changing the selectedVariant prop depending on dropdown(choose variant) click or click on the Product from 'More from this Store'
    const variantsArr = variants.filter((variant) => variant.entity.id === selectedVariant);
    const selVariant = variantsArr.length > 0 || selectedVariant === product.variants[0] || selectedVariant === null ? selectedVariant : variants[0].entity.id;

    const sku = variants.length > 0 ? variants.reduce((acc, cur) => (cur.entity.id === selVariant ? cur : acc), {}).entity.sku : product.sku;
    const productTags = (product.tags || '').split(/[\s,]+/g);
    const descriptionTags = (product.descriptions || '').match(/#[\w_.-]+/g);
    const categoriesTags = (product.categoriesTags || '').split(/[\s,]+/g);
    const allTags = productTags.concat(descriptionTags).concat(categoriesTags).filter(Boolean);
    const merchantEntity = merchant?.entity;
    const merchantName = merchantEntity?.alias;

    return (
        <Grid className='auto'>
            <Grid.Column className='col-sm-16 col-8'>
                <Grid className='auto'>
                    <Grid.Column className='col-row'>
                        <ProductCarousel product={product} user={user} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            <Grid.Column className='col-sm-16 col-8'>
                <Grid className='auto'>
                    <Grid.Column className='col-row'>
                        <Header className='margin-0'>
                            <Link to={`/${product.storeSlug}`} className='store-name-link'>
                                {merchantName}
                            </Link>
                        </Header>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <Header as='h1' size='medium'>
                            {product.name}
                            <Header.Subheader>
                                <Price item={product} />
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <ProductActions
                            itemId={product.id}
                            product={product}
                            onChangeVariant={onChangeVariant}
                            selectedVariant={selVariant}
                            showNegotiate={showNegotiate}
                            showMessage={showMessage}
                            onMessageAction={onSendMessageAction}
                            focusSendMessage={focusSendMessage}
                            onFocusSendMessage={onDidFocusSendMessage}
                            isLoggedIn={isLoggedIn}
                        />
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <Header as='h2' size='medium'>
                            <Trans i18nKey='product.description'>Description</Trans>
                        </Header>
                        <RichTextView className='product-description' sourceText={formatItemDescription(product.descriptions)} />
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <Header as='h2' size='medium'>
                            <Trans i18nKey='product.tags'>Tags</Trans>
                        </Header>
                        <TagButtons tags={allTags} />
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <ProductShipping product={product} />
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <Header as='h2' size='medium'>
                            <Trans i18nKey='product.sku'>SKU</Trans>
                        </Header>
                        <p>{sku}</p>
                    </Grid.Column>
                    <Grid.Column className='col-row'>
                        <ProductStore merchant={merchantEntity} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(Product);
