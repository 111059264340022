import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export type UpdateMyStoreProfilePictureParams = {
    profilePictureUrl?: string, // Base64 encoded image url
    coverPhotoUrls?: [string] // Base64 encoded image url
};

export const UPDATE_MY_STORE_PROFILE_PICTURE = 'UPDATE_MY_STORE_PROFILE_PICTURE';
export const updateMyStoreProfilePicture = wrapAPIActionCreator(({ profilePictureUrl, coverPhotoUrls }: UpdateMyStoreProfilePictureParams = {}) => {
    const body = {};

    if (profilePictureUrl) {
        body.picture = profilePictureUrl;
    }
    if (coverPhotoUrls) {
        body.coverPhotos = coverPhotoUrls.map((url) => ({ data: url }));
    }
    return {
        [API_DATA]: {
            type: UPDATE_MY_STORE_PROFILE_PICTURE,
            endpoint: '/v3/me/profile/picture/',
            method: 'PUT',
            authentication: Authentication.Merchant,
            body,
            schema: {}
        }
    };
});

export default updateMyStoreProfilePicture;
