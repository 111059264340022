// @flow
import store from '../store';

/**
 * Triggers a download for the specified URL, with given options.
 * opts.filename: filename to use for the download
 */
export function downloadFile(url, opts = {}) {
    const { accessToken } = store.getState().merchantData;
    const fetchOpts = {
        method: 'GET',
        headers: {
            access_token: accessToken
        }
    };
    fetch(url, fetchOpts)
        .then((res) => res.blob())
        .then((blob) => {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = opts.filename || '';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
}

/**
 * Triggers a download for the specified URL, with given options.
 * opts.filename: filename to use for the download
 * Works with POST endpoints, accepting body parameters
 */
 export function downloadFileByPosting(url, data, opts = {}) {
    const { accessToken } = store.getState().merchantData;
    const fetchOpts = {
        method: 'POST',
        headers: {
            access_token: accessToken,
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    };
    fetch(url, fetchOpts)
        .then((res) => res.blob())
        .then((blob) => {
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = blobURL;
            a.download = opts.filename || '';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        });
}


export default downloadFile;
