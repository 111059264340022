// @flow
import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { getLandingPage } from 'store/utilities';
import type { EntityData, LandingPage } from 'store/types';

import BazarTeaserView from './BazarTeaserView';

type Props = {
    // from store
    landingPage: EntityData<LandingPage>
};

function BazarTeaserContainer(props: Props) {
    return <BazarTeaserView {...props} />;
}

const mapStateToProps = (state, { landingPageId }) => {
    const landingPage = getLandingPage(state, landingPageId);
    return { landingPage };
};

export default compose(connect(mapStateToProps))(BazarTeaserContainer);
