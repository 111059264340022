// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';
import { getComment, getItem, getUser, getConversation } from 'store/utilities';

import type { EntityData, Item, User, Comment } from 'store/types';

import ProductMessagesView from './ProductMessagesView';

type Props = {
    loadProductConversations: typeof actions.loadProductConversations,
    loadProductComments: typeof actions.loadProductComments,
    product: EntityData<Item>,
    me: EntityData<User>,
    comments: [EntityData<Comment>]
};

type State = {
    isLoadingConversations: boolean,
    hasLoadedConversations: boolean,
    isLoadingComments: boolean,
    hasLoadedComments: boolean,
    isLoadingMoreMessages: boolean
};

class ProductMessagesContainer extends Component<Props, State> {
    state = {
        isLoadingConversations: false,
        hasLoadedConversations: false,
        isLoadingComments: false,
        hasLoadedComments: false,
        isLoadingMoreMessages: false
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        const { product, me } = nextProps;
        const { isLoadingConversations, hasLoadedConversations, isLoadingComments, hasLoadedComments } = this.state;
        if (product && me.entity && !isLoadingConversations && !hasLoadedConversations && !isLoadingComments && !hasLoadedComments) {
            this.loadConversations();
            this.loadComments();
        }
    }

    loadConversations = () => {
        const { loadProductConversations, product, me } = this.props;
        const { isLoadingConversations } = this.state;
        if (!isLoadingConversations && me.entity) {
            this.setState({ isLoadingConversations: true });
            loadProductConversations({ itemId: product.id, merchantId: product.userId, myId: me.entity.id, limit: 99, offset: 0 })
                .then(() => this.setState({ isLoadingConversations: false, hasLoadedConversations: true }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingConversations: false, hasLoadedConversations: true });
                });
        }
    };

    loadComments = () => {
        const { loadProductComments, product } = this.props;
        const { isLoadingComments } = this.state;
        if (!isLoadingComments) {
            this.setState({ isLoadingConversations: true });
            loadProductComments({ itemId: product.id, limit: 20, offset: 0 })
                .then(() => this.setState({ isLoadingConversations: false, hasLoadedConversations: true }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingConversations: false, hasLoadedConversations: true });
                });
        }
    };

    loadMoreMessages = ({ conversation, offset }) => {
        if (this.state.isLoadingMoreMessages) {
            return;
        }
        this.setState({ isLoadingMoreMessages: true });
        const { loadMyMessages } = this.props;
        if (conversation.entity) {
            const { conversationKey, typeId } = conversation.entity;
            loadMyMessages({ conversationKey, typeId, limit: 20, offset })
                .then(() => this.setState({ isLoadingMoreMessages: false }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingMoreMessages: false });
                });
        }
    };

    loadMoreComments = (offset) => {
        const { isLoadingMoreComments } = this.state;
        if (!isLoadingMoreComments) {
            this.setState({ isLoadingMoreComments: true });
            const { loadProductComments, product } = this.props;
            loadProductComments({ itemId: product.id, limit: 5, offset })
                .then(() => this.setState({ isLoadingMoreComments: false }))
                .catch((error) => {
                    console.error(error);
                    this.setState({ isLoadingMoreComments: false });
                });
        }
    };

    render() {
        const { conversation, comments, me, product, focusSendMessage, negotiationBox } = this.props;
        const { isLoadingMoreComments } = this.state;
        const conversations = conversation.entity ? [conversation] : [];
        return (
            <ProductMessagesView
                conversations={conversations}
                comments={comments}
                me={me}
                productId={product.id}
                onPostMessageSuccess={this.loadConversations}
                onLoadMoreMessages={this.loadMoreMessages}
                focusSendMessage={focusSendMessage}
                onPostCommentSuccess={this.loadComments}
                onLoadMoreComments={this.loadMoreComments}
                isLoadingMoreComments={isLoadingMoreComments}
                type='shopperProduct'
                negotiationBox={negotiationBox}
            />
        );
    }
}

const mapStateToProps = (state, { product }) => {
    const productConversation = state.userData.productConversations[product.id] || {};

    const item = getItem(state, product.id);
    const comments = item.entity && item.entity.comments ? item.entity.comments.map((commentId) => getComment(state, commentId)) : [];

    const { conversationId } = productConversation;
    const conversation = getConversation(state, conversationId);
    return {
        conversation,
        comments: comments.filter((comment) => comment.entity != null),
        me: getUser(state, state.userData.user)
    };
};

const mapDispatchToProps = (dispatch) => ({
    loadProductConversations: wrapAPIDispatch(dispatch, actions.loadProductConversations),
    loadProductComments: wrapAPIDispatch(dispatch, actions.loadProductComments),
    loadMyMessages: wrapAPIDispatch(dispatch, actions.loadMyMessages)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(ProductMessagesContainer);
