// @flow
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { getStore } from 'store/utilities';
import { wrapAPIDispatch } from 'services/api';

import { EntityData, Store } from 'store/types';
import StoreAddressesView from './StoreAddressesView';

type Props = {
    // from store
    openModal: typeof actions.openModal,
    stores: EntityData<Store>[],
    selectedStore: number,
    createMerchantStoreLocation: typeof actions.createMerchantStoreLocation,
    updateMerchantStoreLocation: typeof actions.updateMerchantStoreLocation
};

class StoreAddressContainer extends Component<Props> {
    handleSubmitNewAddress = (params) => {
        const { createMerchantStoreLocation, onStoreAddressAdded } = this.props;
        const { address: houseNo, phone: contactNo, name: contactPerson, email: contact_email, addressSecondary: street, postal: zip, ...rest } = params;
        return createMerchantStoreLocation({ mailingAddress: { houseNo, street, zip, contactNo, contactPerson, contact_email, ...rest } })
            .then((res) => {
                console.log('handleSubmitNewAddress finsih', res);
                onStoreAddressAdded(res);
            })
            .catch((err) => {
                console.log('ERROR:StoreAddressContainer:handleSubmitNewAddress()', err);
            });
    };

    handleSubmitNewAddressSyncOnly = (params) => {
        const { onStoreAddressAdded } = this.props;
        return onStoreAddressAdded(params);
    };

    handleSubmitEditAddress = (params) => {
        const { updateMerchantStoreLocation, onStoreAddressUpdated } = this.props;
        const { addressId: storeId, address: houseNo, addressSecondary: street, postal: zip, ...rest } = params;
        return updateMerchantStoreLocation({ storeId, mailingAddress: { houseNo, street, zip, ...rest } })
            .then((res) => {
                console.log('handleSubmitEditAddress finish', res);
                onStoreAddressUpdated(res);
            })
            .catch((err) => {
                console.log('ERROR:StoreAddressContainer:handleSubmitEditAddress()', err);
            });
    };

    handleSubmitEditAddressSyncOnly = (params) => {
        const { onStoreAddressUpdated } = this.props;
        return onStoreAddressUpdated(params);
    };

    handleDeleteEditAddress = ({ addressId: storeId }) => {
        const { deleteMerchantStoreLocation, onStoreAddressDeleted } = this.props;
        return deleteMerchantStoreLocation({ storeId })
            .then((res) => {
                console.log('handleDeleteEditAddress finish', res);
                onStoreAddressDeleted(res);
            })
            .catch(onStoreAddressDeleted);
    };

    handleDeleteEditAddressSyncOnly = (params) => {
        const { onStoreAddressDeleted } = this.props;
        return onStoreAddressDeleted(params);
    };

    handleOpenAddAddress = () => {
        const { openModal, syncOnly } = this.props;
        openModal(actions.ModalNames.ADDRESS_ADD, {
            fields: ['name', 'email', 'phone', 'address', 'addressSecondary', 'postal', 'country', 'state', 'city'],
            onSubmit: syncOnly ? this.handleSubmitNewAddressSyncOnly : this.handleSubmitNewAddress
        });
    };

    handleOpenEditAddress = (address) => {
        const { openModal, syncOnly } = this.props;
        openModal(actions.ModalNames.ADDRESS_EDIT, {
            address,
            fields: ['name', 'email', 'phone', 'address', 'addressSecondary', 'postal', 'country', 'state', 'city'],
            onDelete: syncOnly ? this.handleDeleteEditAddressSyncOnly : this.handleDeleteEditAddress,
            onSubmit: syncOnly ? this.handleSubmitEditAddressSyncOnly : this.handleSubmitEditAddress
        });
    };

    render() {
        return <StoreAddressesView onOpenAddAddress={this.handleOpenAddAddress} onOpenEditAddress={this.handleOpenEditAddress} {...this.props} />;
    }
}

const mapStateToProps = (state, { stores = [], selectedStore }) => {
    // console.log('mapStateToProps', stores, selectedStore);
    return {
        addresses: stores.map((store) => {
            return { ...store.mailingAddress, id: store.id };
        }),
        selectedAddress: selectedStore
    };
};

const mapDispatchToProps = (dispatch) => ({
    openModal: (...args) => dispatch(actions.openModal(...args)),
    createMerchantStoreLocation: wrapAPIDispatch(dispatch, actions.createMerchantStoreLocation),
    updateMerchantStoreLocation: wrapAPIDispatch(dispatch, actions.updateMerchantStoreLocation),
    deleteMerchantStoreLocation: wrapAPIDispatch(dispatch, actions.deleteMerchantStoreLocation)
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StoreAddressContainer);
