// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type FollowUserParams = {
    userId: number,
    currentUserId: number,
    parentUserId?: number,
    isFollowing: boolean,
    totalFollowers: number,
    totalFollowing: number
};

// FOLLOW_USER ----------------------
export const FOLLOW_USER = 'FOLLOW_USER';
export const followUser = wrapAPIActionCreator(({ userId, currentUserId, parentUserId, isFollowing, totalFollowers, totalFollowing }: FollowUserParams) => ({
    [API_DATA]: {
        type: FOLLOW_USER,
        endpoint: `/v3/users/${userId}/follow`,
        authentication: Authentication.User,
        reducerParams: {
            userId,
            currentUserId,
            parentUserId,
            isFollowing
        },
        method: 'POST',
        schema: {
            user: Schemas.user
        },
        preprocess: () => {
            if (parentUserId === currentUserId) {
                // toggled my own following
                return {
                    user: {
                        id: parentUserId,
                        totalFollowing: (totalFollowing += isFollowing ? 1 : -1)
                    }
                };
            }

            const props = {};
            if (parentUserId === userId) {
                // toggled followers for other profile
                props.totalFollowers = totalFollowers += isFollowing ? 1 : -1;
            }
            return {
                user: {
                    id: userId,
                    isFollowing,
                    ...props
                }
            };
        }
    }
}));
