// @flow
import React from 'react';
import { Grid, List } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

function CheckoutDetailsPCIDSS() {
    return (
        <Grid className='auto tensed'>
            <Grid.Column className='color-de-emphasized'>
                <p>
                    <Trans i18nKey='checkout.details.pcidss.description'>MyBazar complies with the highest level of Payment Card Industry (PCI) standards.</Trans>
                </p>
            </Grid.Column>
            <Grid.Column className='col-shrink' style={{ display: 'flex', alignItems: 'center' }}>
                <Grid className='auto very-tensed'>
                    <Grid.Column>
                        <List.Icon size='big' className='cc visa' style={{ margin: 0 }} />
                    </Grid.Column>
                    <Grid.Column>
                        <List.Icon size='big' className='cc mastercard' style={{ margin: 0 }} />
                    </Grid.Column>
                </Grid>
            </Grid.Column>
        </Grid>
    );
}

export default withTranslation('translations')(CheckoutDetailsPCIDSS);
