// @flow
import * as ActionTypes from '../../actions';

const initialState = {
    all: {
        isFetching: false,
        ids: []
    }
};

// Populates category ID mappings when categories are loaded, with collection
// filter applied (or 'all').  All IDs of categories in the collection are
// included--if only top-level categories are desired, this has to be filtered
// out manually.
export default function categoriesByCollection(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_CATEGORY_TREE: {
            const collectionKey = action.reducerParams.collection || 'all';
            if (action.status === 'request') {
                return { ...state, [collectionKey]: { isFetching: true, ids: [] } };
            }
            const { categories = {} } = action.payload.entities;
            const ids = action.status === 'success' ? Object.keys(categories).map(Number) : [];

            return {
                ...state,
                [collectionKey]: { isFetching: false, ids }
            };
        }
        default:
        // do nothing
    }
    return state;
}
