// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_RETURN_POLICIES ----------------------
export const LOAD_RETURN_POLICIES = 'LOAD_RETURN_POLICIES';
export const loadReturnPolicies = wrapAPIActionCreator(() => ({
    [API_DATA]: {
        type: LOAD_RETURN_POLICIES,
        endpoint: '/v3/return_policies',
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            returnPolicies: [Schemas.returnPolicy]
        }
    }
}));
