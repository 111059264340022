// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import { getReturnPolicy, shippingParamsToKey } from 'store/utilities';

import { ShipmentOptions } from 'store/enums';
import ShippingToDetailsView from './ShippingToDetailsView';

type Props = {
    loadReturnPolicies: typeof actions.loadReturnPolicies
};

class ShippingToDetailsContainer extends Component<Props> {
    state = {
        costs: undefined,
        costsError: null,
        isLoadingCosts: false
    };

    componentDidMount() {
        const { returnPolicies, isLoadingReturnPolicies } = this.props;
        if (returnPolicies.length === 0 && !isLoadingReturnPolicies) {
            this.props.loadReturnPolicies().catch((err) => console.error(err));
        }
        this.onReceivedProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.onReceivedProps(nextProps);
    }

    onReceivedProps = (props) => {
        const { shippingDefaults, shippingFrom, weight, weightType, lookupShippingCost, ownFormValues, shippingCostsState } = props;

        //console.log('onReceivedProps', props);
        if (shippingDefaults && shippingFrom && weight && weightType) {
            if (shippingDefaults.shipment === ShipmentOptions.POSLAJU ||
                shippingDefaults.shipment === ShipmentOptions.EUROEXPRESS ) {
                let merchantWillCoverCost = false;

                if (ownFormValues) {
                    merchantWillCoverCost = ownFormValues.merchantWillCoverCost.value;
                }

                const shippingParams = {
                    delivery: shippingDefaults.delivery,
                    isMerchantCoverCost: merchantWillCoverCost,
                    payment: 2,
                    shipFromId: shippingFrom,
                    shipToId: shippingDefaults.shipToId,
                    weight,
                    weightType
                };

                const costsKey = shippingParamsToKey(shippingParams);

                const costsState = shippingCostsState[costsKey] || {};

                const { isLoading = false, details = null, error } = costsState;

                if (details || error) {
                    this.setState({ costs: details, costsError: error, isLoadingCosts: isLoading });
                }

                if (!details && !isLoading && !error) {
                    lookupShippingCost(shippingParams).catch((err) => console.error(err));
                }
            }
        }
    };

    render() {
        const { translationId, ...rest } = this.props;
        return (
            <ShippingToDetailsView
                {...rest}
                costs={this.state.costs}
                costsError={this.state.costsError}
                isLoadingCosts={this.state.isLoadingCosts}
                translationId='sellItem.form.delivery.shippingTo.details'
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { formId } = ownProps;
    return {
        isLoadingReturnPolicies: state.merchantData.returnPolicies.isLoading,
        returnPolicies: state.merchantData.returnPolicies.list.map((id) => getReturnPolicy(state, id).entity),
        ownFormValues: (state.ui.forms[formId] || {}).formValues,
        shippingCostsState: state.merchantData.shippingCosts
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFormValues: (params) => dispatch(actions.updateFormValues(params)),
    loadReturnPolicies: wrapAPIDispatch(dispatch, actions.loadReturnPolicies),
    lookupShippingCost: wrapAPIDispatch(dispatch, actions.lookupShippingCost)
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingToDetailsContainer);
