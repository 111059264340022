// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { wrapAPIDispatch } from 'services/api';
import * as actions from 'store/actions';

import MyStoreCampaignsView from './MyStoreCampaignsView';

type Props = {
    loadCampaigns: typeof actions.loadCampaigns,
    loadMyConversations: typeof actions.loadMyConversations
};

class MyStoreCampaignsContainer extends Component<Props, {}> {
    componentDidMount() {
        this.loadCampaigns();
        this.loadConversations().then(() => {
            this.props.markAllStoreSystemMessagesAsRead();
        });
    }

    loadConversations = () => {
        const { loadMyConversations, isLoadingConversations } = this.props;
        if (!isLoadingConversations) {
            loadMyConversations({ offset: 0 }).catch((error) => console.error(error));
        }
        return Promise.resolve(true);
    };

    loadCampaigns = () => {
        const { loadCampaigns, isLoadingCampaigns } = this.props;
        if (!isLoadingCampaigns) {
            loadCampaigns({ offset: 0, limit: 10 }).catch((error) => console.error(error));
        }
    };

    render() {
        return <MyStoreCampaignsView />;
    }
}

const mapStateToProps = (state) => ({
    isLoadingConversations: state.merchantData.conversations.isLoading,
    isLoadingCampaigns: state.merchantData.campaigns.isLoading
});

const mapDispatchToProps = (dispatch) => ({
    loadCampaigns: wrapAPIDispatch(dispatch, actions.loadCampaigns),
    loadMyConversations: wrapAPIDispatch(dispatch, actions.loadMyConversations),
    markAllStoreSystemMessagesAsRead: wrapAPIDispatch(dispatch, actions.markAllStoreSystemMessagesAsRead)
});

export default connect(mapStateToProps, mapDispatchToProps)(MyStoreCampaignsContainer);
