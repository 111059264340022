import { useState, useMemo } from 'react';

const PARTIAL_CVV_PATTERN = /^\d{1,3}$/;

const PARTIAL_MONTH_PATTERN = /^(?:0$|1$|0[1-9]|1[0-2])\d{0,2}$/;

const PARTIAL_PAN_PATTERN = /^\d{1,16}$/;

const PAN_GROUP_PATTERN = /.{1,4}/g;

const MONTH_GROUP_PATTERN = /.{1,2}/g;

const defaultFormatter = (value) => value;

const panFormatter = (value) => (value.length ? value.match(PAN_GROUP_PATTERN).join(' ') : value);

const monthFormatter = (value) => (value.length ? value.match(MONTH_GROUP_PATTERN).join('/') : value);

const defaultSetter = (setValue, value) => setValue(value);

const cvvSetter = (setValue, value) => (!value || PARTIAL_CVV_PATTERN.test(value)) && setValue(value);

const monthSetter = (setValue, value) => {
    if (value) {
        const raw = value.replace(/\//g, '');
        if (PARTIAL_MONTH_PATTERN.test(raw)) {
            setValue(raw);
        }
    } else {
        setValue(value);
    }
};

const panSetter = (setValue, value) => {
    if (value) {
        const raw = value.replace(/ /g, '');
        if (PARTIAL_PAN_PATTERN.test(raw)) {
            setValue(raw);
        }
    } else {
        setValue(value);
    }
};

const defaultValidator = () => null;

const emptyFieldValidator = (t, value) => (!value && t('validation.requiredField.error.message', { defaultValue: 'This field is required' })) || null;

const luhnCheck = ((arr) => (input) => {
    let length = input.length;
    let bit = 1;
    let sum = 0;

    while (length) {
        const digit = parseInt(input.charAt(--length), 10);
        sum += (bit ^= 1) ? arr[digit] : digit;
    }

    return sum && sum % 10 === 0;
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]);

const panValidator = (t, value) => {
    const empty = emptyFieldValidator(t, value);
    if (empty) {
        return empty;
    }
    return value.length === 16 && luhnCheck(value) ? null : t('validation.cardNumber.error.message', { defaultValue: 'Invalid card number' });
};

const monthValidator = (t, value) => {
    const empty = emptyFieldValidator(t, value);
    if (empty) {
        return empty;
    }
    return value.length === 4 ? null : t('validation.monthYearFormat.error.message', { defaultValue: 'Invalid format, please use MM/YY' });
};

const cvvValidator = (t, value) => {
    const empty = emptyFieldValidator(t, value);
    if (empty) {
        return empty;
    }
    return value.length === 3 ? null : t('validation.cvvFormat.error.message', { defaultValue: 'Invalid format, please find the three digit code on the back of your card' });
};

const useInput = (t, initialValue, validate = defaultValidator, format = defaultFormatter, set = defaultSetter) => {
    const [value, setValue] = useState(initialValue);
    const validationResult = useMemo(() => validate(t, value), [t, validate, value]);
    const [showError, setShowError] = useState(false);
    const formatted = format(value);
    const error = (showError && validationResult) || null;
    return {
        value,
        setValue,
        valid: !validationResult,
        highlightErrors: () => {
            setShowError(true);
        },
        reset: () => {
            setValue('');
            setShowError(false);
        },
        fieldAttributes: {
            value: formatted,
            onChange: (e) => set(setValue, e.target.value),
            error
        }
    };
};

const useCreditCardFields = (t, userFullName) => {
    const nameInput = useInput(t, userFullName, emptyFieldValidator);
    const panInput = useInput(t, '', panValidator, panFormatter, panSetter);
    const expirationDateInput = useInput(t, '', monthValidator, monthFormatter, monthSetter);
    const cvvInput = useInput(t, '', cvvValidator, defaultFormatter, cvvSetter);

    const reversedDate = useMemo(() => {
        if (expirationDateInput.value && expirationDateInput.value.length === 4) {
            return expirationDateInput.value.substring(2) + expirationDateInput.value.substring(0, 2);
        }
        return expirationDateInput.value;
    }, [expirationDateInput.value]);

    const valid = nameInput.valid && panInput.valid && expirationDateInput.valid && cvvInput.valid;
    return {
        valid,
        values: {
            name: nameInput.value,
            pan: panInput.value,
            expirationDate: reversedDate,
            cvv: cvvInput.value
        },
        reset: () => {
            nameInput.reset();
            panInput.reset();
            expirationDateInput.reset();
            cvvInput.reset();
        },
        highlightErrors: () => {
            nameInput.highlightErrors();
            panInput.highlightErrors();
            expirationDateInput.highlightErrors();
            cvvInput.highlightErrors();
        },
        fieldAttributes: {
            name: nameInput.fieldAttributes,
            pan: panInput.fieldAttributes,
            expirationDate: expirationDateInput.fieldAttributes,
            cvv: cvvInput.fieldAttributes
        }
    };
};

export default useCreditCardFields;
