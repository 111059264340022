// @flow
import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Container, Grid, Header } from 'semantic-ui-react';

import MyAgentInboxTabView from './components/MyAgentInboxTabView';

function MyAgentInbox(props) {
    return (
        <Container>
            <Grid className='auto'>
                <Grid.Column className='col-row'>
                    <Header as='h1'>
                        <Trans i18nKey='myProfile.inbox.header'>My Agent Inbox</Trans>
                    </Header>
                </Grid.Column>
                <Grid.Column className='col-row'>
                    <MyAgentInboxTabView {...props} />
                </Grid.Column>
            </Grid>
        </Container>
    );
}

export default withTranslation('translations')(MyAgentInbox);
