import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type RejectOfferParams = {
    itemId: number,
    offerId: number
};

export const REJECT_OFFER = 'REJECT_OFFER';
export const rejectItemOffer = wrapAPIActionCreator(({ itemId, offerId }: RejectOfferParams) => ({
    [API_DATA]: {
        type: REJECT_OFFER,
        endpoint: '/v3/cart_items/reject_offer',
        method: 'POST',
        authentication: Authentication.User,
        body: {
            itemId,
            offerId
        }
    }
}));

export default rejectItemOffer;
