// Adapted from https://www.quaxio.com/html_white_listed_sanitizer/

const makeUrlSanitizer = (allowedUrls) => (str) => (str && Object.values(allowedUrls).some((prefix) => str.startsWith(prefix)) ? str : '');

export function makeDefaultAllowedTags(urls) {
    const unconstrainted = (x) => x;
    const globalAttributes = {
        dir: unconstrainted,
        lang: unconstrainted,
        title: unconstrainted
    };
    const urlSanitizer = makeUrlSanitizer(urls);
    const defaultAllowedTags = {
        a: { ...globalAttributes, href: urlSanitizer, hreflang: unconstrainted, rel: unconstrainted, type: unconstrainted, target: (x) => ['_blank', '_top'].includes(x) },
        img: { ...globalAttributes, alt: unconstrainted, height: unconstrainted, width: unconstrainted, src: urlSanitizer },
        p: globalAttributes,
        div: globalAttributes,
        span: globalAttributes,
        strong: globalAttributes,
        em: globalAttributes,
        del: globalAttributes,
        ins: globalAttributes,
        br: globalAttributes,
        ul: globalAttributes,
        ol: globalAttributes,
        li: globalAttributes
    };
    return defaultAllowedTags;
}

const hasOwnProperty = (...args) => Object.prototype.hasOwnProperty.call(...args);

/** Sanitizer which filters a set of whitelisted tags, attributes and css. */
class HtmlWhitelistedSanitizer {
    constructor({ escape, urlPrefixes = ['http://', 'https://', '/'], allowedCss = ['border', 'margin', 'padding'], allowedTags = makeDefaultAllowedTags(urlPrefixes) }) {
        // Use the browser to parse the input but create a new HTMLDocument.
        // This won't evaluate any potentially dangerous scripts since the element
        // isn't attached to the window's document. It also won't cause img.src to
        // preload images.
        //
        // To be extra cautious, you can dynamically create an iframe, pass the
        // input to the iframe and get back the sanitized string.
        this.doc = document.implementation.createHTMLDocument();

        this.escape = escape;
        this.allowedTags = allowedTags;
        this.allowedCss = allowedCss;
    }

    sanitizeString(input) {
        const div = this.doc.createElement('div');
        div.innerHTML = input;
        // Return the sanitized version of the node.
        return this.sanitizeNode(div).innerHTML;
    }

    sanitizeNode(node) {
        // Note: <form> can have it's nodeName overriden by a child node. It's
        // not a big deal here, so we can punt on this.
        const nodeName = node.nodeName.toLowerCase();
        // text nodes are always safe
        if (nodeName === '#text') {
            return node;
        }
        // always strip comments
        if (nodeName === '#comment') {
            return this.doc.createTextNode('');
        }
        // escape or strip disallowed node
        if (!hasOwnProperty(this.allowedTags, nodeName)) {
            if (this.escape) {
                return this.doc.createTextNode(node.outerHTML);
            }
            return this.doc.createTextNode('');
        }

        // create a new node
        const copy = this.doc.createElement(nodeName);

        if (nodeName === 'a') {
            // default to target=_top to avoid opening in iframe
            copy.setAttribute('target', '_top');
        }

        // copy the whitelist of attributes using the per-attribute sanitizer
        for (let i = 0; i < node.attributes.length; i += 1) {
            const attr = node.attributes.item(i).name;
            if (hasOwnProperty(this.allowedTags[nodeName], attr)) {
                const sanitizer = this.allowedTags[nodeName][attr];
                copy.setAttribute(attr, sanitizer(node.getAttribute(attr)));
            }
        }
        // copy the whitelist of css properties
        Object.keys(this.allowedCss).forEach((key) => {
            copy.style[this.allowedCss[key]] = node.style[this.allowedCss[key]];
        });

        // recursively sanitize child nodes
        while (node.childNodes.length > 0) {
            const child = node.removeChild(node.childNodes[0]);
            copy.appendChild(this.sanitizeNode(child));
        }
        return copy;
    }
}

export default HtmlWhitelistedSanitizer;
