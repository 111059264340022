// @flow
import React from 'react';
import { Segment, Grid, Header, Loader, Divider } from 'semantic-ui-react';
import { withTranslation, Trans } from 'react-i18next';

import CartListItem from './components/CartListItem';

type Props = {
    list: number[],
    isLoading: boolean,
    showQuantity?: boolean
};

function CartList({ list, isLoading, showQuantity, ...rest }: Props) {
    return (
        <Segment className='semi-simple'>
            {isLoading && <Loader active inline='centered' />}
            {!isLoading && list.length === 0 && (
                <Header as='h3' className='margin-0 text-align-center'>
                    <Trans i18nKey='cart.list.isEmpty'>Your Cart Is Empty</Trans>
                </Header>
            )}
            {!isLoading && list.length > 0 && (
                <Grid className='auto tensed'>
                    {list.map((id, index) => (
                        <Grid.Column className='col-row' key={id}>
                            <Grid className='auto tensed'>
                                <Grid.Column className='col-row'>
                                    <CartListItem {...{ id, showQuantity, ...rest }} />
                                </Grid.Column>
                                {index + 1 !== list.length && (
                                    <Grid.Column className='col-row'>
                                        <Divider fitted />
                                    </Grid.Column>
                                )}
                            </Grid>
                        </Grid.Column>
                    ))}
                </Grid>
            )}
        </Segment>
    );
}

CartList.defaultProps = {
    showQuantity: false
};

export default withTranslation('translations')(CartList);
