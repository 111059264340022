// @flow
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react';

import StoreTeaser from 'components/TeaserCarousel/components/StoreTeaser';

type Props = {
    items: {
        merchantId: number,
        itemIds: number[]
    }
};

function StoreList({ items }: Props) {
    if (!(items && items.length)) {
        return null;
    }
    return (
        <Grid className='auto full-width-list'>
            {items.map(({ merchantId, itemIds }) => (
                <Grid.Column key={merchantId} className='full-width-list-item'>
                    <StoreTeaser merchantId={merchantId} itemIds={itemIds} />
                </Grid.Column>
            ))}
        </Grid>
    );
}

export default StoreList;
