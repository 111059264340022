// @flow
import React from 'react';
import { Dropdown } from 'semantic-ui-react';

function range(from, to) {
    const res = [];
    for (let i = from; i <= to; i += 1) {
        res.push(i);
    }
    return res;
}

type Props = {
    min: number,
    max: number,
    value: number,
    onChange: (number) => void,
    loading: boolean,
    label: string
};

/** 'select quantity' dropdown of a CartList item */
function QuantityDropdown({ min, max, onChange, label, ...rest }: Props) {
    return (
        <Dropdown
            style={{ float: 'right' }}
            selection
            compact
            onChange={(ev, data) => onChange(data.value)}
            options={range(min, max).map((value) => ({
                value,
                text: `${label}: ${String(value)}`
            }))}
            {...rest}
        />
    );
}

export default QuantityDropdown;
