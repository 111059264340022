// @flow
import { uniq } from 'lodash';

import * as ActionTypes from '../../../actions';

const initialState = {
    isLoading: false,
    cancelled: [],
    cancelledCount: 0,
    delivered: [],
    deliveredCount: 0,
    open: [],
    openCount: 0
};

function updateFromLoadOrders(state, action) {
    const { result } = action.payload;

    const { offset } = action.reducerParams;

    const {
        cancalledOrderCount: cancelledCount,
        cancalledOrder: cancelled,
        deliveredOrderCount: deliveredCount,
        deliveredOrder: delivered,
        openOrderCount: openCount,
        openOrder: open
    } = result.items;

    if (offset > 0) {
        return {
            ...state,
            cancelledCount,
            cancelled: uniq(state.cancelled.concat(cancelled)),
            deliveredCount,
            delivered: uniq(state.delivered.concat(delivered)),
            openCount,
            open: uniq(state.open.concat(open)),
            isLoading: false
        };
    }
    return {
        ...state,
        cancelledCount,
        cancelled,
        deliveredCount,
        delivered,
        openCount,
        open,
        isLoading: false
    };
}

function updateFromLoadActivity(state, action) {
    const { result } = action.payload;

    const { cancalledOrderCount: cancelledCount, deliveredOrderCount: deliveredCount, openOrderCount: openCount } = result.order;

    return {
        ...state,
        cancelledCount,
        deliveredCount,
        openCount
    };
}

/** Update order status based on Order entity changes */
const statusToCollection = {
    ORDER_CREATED: 'open',
    SHIP_TO_DC: 'open',
    DELIVERED: 'delivered',
    CANCELLED: 'cancelled'
};

function updateFromEntities(state, action) {
    // Local copy of state to modify
    const open = {
        ids: state.open.slice(),
        count: state.openCount
    };
    const delivered = {
        ids: state.delivered.slice(),
        count: state.deliveredCount
    };
    const cancelled = {
        ids: state.cancelled.slice(),
        count: state.cancelledCount
    };
    const collections = { open, delivered, cancelled };

    const { entities } = action.payload;

    // Helpers
    const findPreviousCollection = (colls, id) => Object.keys(colls).filter((key) => colls[key].ids.includes(id))[0];
    const mapStatusToCollection = (status) => statusToCollection[status] || 'open';

    // Move order ID between collections
    const moveId = (id, prev, curr) => {
        collections[prev].ids.splice(collections[prev].ids.indexOf(id), 1);
        collections[prev].count -= 1;
        collections[curr].ids.push(id);
        collections[curr].count += 1;
    };

    Object.values(entities.orders).forEach(({ id, orderStatus }) => {
        const previousCollection = findPreviousCollection(collections, id);
        const currentCollection = mapStatusToCollection(orderStatus);

        if (previousCollection != null && previousCollection !== currentCollection) {
            moveId(id, previousCollection, currentCollection);
        }
    });

    return {
        ...state,
        open: uniq(collections.open.ids),
        openCount: collections.open.count,
        delivered: uniq(collections.delivered.ids),
        deliveredCount: collections.delivered.count,
        cancelled: uniq(collections.cancelled.ids),
        cancelledCount: collections.cancelled.count
    };
}

function orders(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOAD_MY_STORE_ACTIVITY: {
            if (action.status === 'success') {
                return updateFromLoadActivity(state, action);
            }
            break;
        }
        case ActionTypes.LOAD_ORDERS:
            if (action.status === 'request') {
                return {
                    ...state,
                    isLoading: true
                };
            }
            if (action.status === 'error') {
                return {
                    ...state,
                    isLoading: false
                };
            }
            if (action.status === 'success') {
                return updateFromLoadOrders(state, action);
            }
            break;
        // Reset orders store on logout
        case ActionTypes.LOGOUT_USER:
            return initialState;
        default:
            if (((action.payload || {}).entities || {}).orders) {
                return updateFromEntities(state, action);
            }
            break;
    }
    return state;
}

export default orders;
