// @flow
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Icon, Input } from 'semantic-ui-react';

import { serializeFile } from 'utilities';

type Props = {
    addImage: () => void
};

class ItemImageAdd extends Component<Props> {
    handleChangeFile = (event, data) => {
        const file = event.target.files[0];
        if (file) {
            serializeFile(file).then(
                (dataURI) => this.props.addImage({ fileDataURI: dataURI }),
                () => {}
            );
        }
    };

    render() {
        return (
            <div className='sell-item-image-add'>
                <Button type='button' as='label' className='sell-item-image-add-button' circular icon fluid size='massive' htmlFor='sell-item-picture-add-image'>
                    <Icon name='add' />
                </Button>
                <Input
                    error={null}
                    type='file'
                    name='file'
                    id='sell-item-picture-add-image'
                    className='sell-item-add-image-input'
                    value=''
                    style={{ display: 'none' }}
                    onChange={this.handleChangeFile}
                />
            </div>
        );
    }
}

export default withTranslation('translations')(ItemImageAdd);
