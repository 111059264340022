// @flow
import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';

import CheckoutDetailsDelivery from './components/CheckoutDetailsDelivery';
import CheckoutDetailsPayment from './components/CheckoutDetailsPayment';
import CheckoutDetailsReview from './components/CheckoutDetailsReview';

import useCreditCardFields from './cardDetailsLogic';

type Props = {
    subtotal: number,
    shippingFee: number,
    cart: object,
    step: number,
    nextStep: Function,
    openModal: Function
};

function CheckoutDetails(props: Props) {
    const { t } = useTranslation('translations');
    const creditCardFields = useCreditCardFields(t, '');

    let details = null;
    switch (props.step) {
        case 1:
            details = <CheckoutDetailsDelivery {...props} />;
            break;
        case 2:
            details = <CheckoutDetailsPayment {...props} creditCardFields={creditCardFields} />;
            break;
        case 3:
            details = <CheckoutDetailsReview {...props} creditCardValues={creditCardFields.values} />;
            break;
        default:
    }
    return <div className='checkout-details'>{details}</div>;
}

export default withTranslation('translations')(CheckoutDetails);
