// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

// LOAD_MY_STORE_SETTINGS ------------------
export type LoadMyStoreSettingsParams = {
    fields?: 'cover_photos'[]
};

export const LOAD_MY_STORE_SETTINGS = 'LOAD_MY_STORE_SETTINGS';
export const loadMyStoreSettings = wrapAPIActionCreator(({ fields = ['cover_photos'] }: LoadMyStoreSettingsParams = {}) => ({
    [API_DATA]: {
        type: LOAD_MY_STORE_SETTINGS,
        endpoint: '/v3/me/web/settings',
        endpointParams: { fields: fields.join(',') },
        authentication: Authentication.Merchant,
        method: 'GET',
        schema: {
            user: Schemas.user,
            settings: {
                stores: [Schemas.store]
            }
        }
    }
}));
