import { API_DATA, Schemas, Authentication, wrapAPIActionCreator } from 'services/api';

export const UNLOCK_CART = 'UNLOCK_CART';
export const unlockCart = wrapAPIActionCreator((clientSecret) => {
    return {
        [API_DATA]: {
            type: UNLOCK_CART,
            endpoint: '/v4/cart_items/unlock_cart',
            method: 'POST',
            authentication: Authentication.User,
            body: {
                secret: clientSecret
            },
            schema: Schemas.cart
        }
    };
});

export default unlockCart;
