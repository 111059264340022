// @flow
import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Tab, Menu, Label, Visibility, Loader } from 'semantic-ui-react';

import ProductDetailList from 'components/ProductDetailList';
import { Authentication } from '../../../../../../services/api';

type Props = {
    items: {
        active: array,
        activeCount: number,
        banned: array,
        bannedCount: number,
        inactive: array,
        inactiveCount: number,
        soldout: array,
        soldoutCount: number,
        isLoading: false
    },
    loadMoreItems: Function
};

class MyStoreItemsTab extends Component<Props> {
    state = {
        redirectTo: null
    };

    onTabChange = (event, { activeIndex, defaultActiveIndex }) => {
        if (activeIndex !== defaultActiveIndex) {
            let redirectTo = '/my-store/items';
            switch (activeIndex) {
                case 0:
                    redirectTo = '/my-store/items';
                    break;
                case 1:
                    redirectTo = '/my-store/items/banned';
                    break;
                case 2:
                    redirectTo = '/my-store/items/sold-out';
                    break;
                case 3:
                    redirectTo = '/my-store/items/inactive';
                    break;
                default:
                    redirectTo = '/my-store/items';
            }
            this.setState({ redirectTo });
        }
    };

    render() {
        const { items, loadMoreItems, location } = this.props;
        const { redirectTo } = this.state;

        const { active, activeCount, banned, bannedCount, inactive, inactiveCount, soldout, soldoutCount, isLoading } = items;

        const tabs = [
            {
                key: 'active',
                title: 'Active',
                count: activeCount,
                items: active
            },
            {
                key: 'banned',
                title: 'Banned',
                count: bannedCount,
                items: banned
            },
            {
                key: 'soldOut',
                title: 'Sold Out',
                count: soldoutCount,
                items: soldout
            },
            {
                key: 'inactive',
                title: 'Inactive',
                count: inactiveCount,
                items: inactive
            }
        ];

        let defaultActiveIndex = 0;
        switch (location.pathname) {
            case '/my-store/items/banned':
                defaultActiveIndex = 1;
                break;
            case '/my-store/items/sold-out':
                defaultActiveIndex = 2;
                break;
            case '/my-store/items/inactive':
                defaultActiveIndex = 3;
                break;
            default:
                break;
        }

        if (redirectTo && redirectTo !== location.pathname) {
            return <Redirect to={redirectTo} />;
        }

        return (
            <Tab
                defaultActiveIndex={defaultActiveIndex}
                menu={{ secondary: true, pointing: true }}
                onTabChange={this.onTabChange}
                ref={(c) => {
                    this.tab = c;
                }}
                panes={tabs.map((tab) => ({
                    menuItem: (
                        <Menu.Item key={tab.key}>
                            <Trans i18nKey={`myStore.dashboard.items.${tab.key}`}>{tab.title}</Trans>
                            <Label>{tab.count}</Label>
                        </Menu.Item>
                    ),
                    render: () => (
                        <Tab.Pane attached={false} className='simple'>
                            <Visibility continuous onBottomVisible={() => loadMoreItems()}>
                                <ProductDetailList type='item' tab={tab.key} idList={tab.items} authentication={Authentication.Merchant} />
                                <Loader active={isLoading} />
                            </Visibility>
                        </Tab.Pane>
                    )
                }))}
            />
        );
    }
}

export default withTranslation('translations')(MyStoreItemsTab);
