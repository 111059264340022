// @flow
import { API_DATA, wrapAPIActionCreator, Authentication } from 'services/api';

export const ItemWeightTypes = {
    Grams: 'GM',
    Kilograms: 'KG'
};

export type AddItemParams = {
    brand?: string,
    category: number,
    categoryChild: number,
    categoryParent: number,
    categoryTags: string,
    dealTypes: {
        buy_3_for_2: boolean,
        sale: number,
        saleGross: number,
        sample: boolean
    },
    description?: string,
    tags?: string,
    gst: boolean,
    isFixedPrice: boolean,
    isDeal: boolean,
    name: string,
    pictures: [
        {
            data: string, // Base64 encoded image data
            index: number
        }
    ],
    price: number,
    shippingDetails: [
        {
            delivery: number, // TODO set possible values here
            estimatedDeliveryCost: number,
            estimatedDeliveryTimeEnd: number, // Maximum Days
            estimatedDeliveryTimeStart: number, // Minimum Days
            isMerchantPay: 0 | 1,
            itemShippingDetailKey: number,
            payment: number,
            returnPolicyId: number,
            shipFromId: number, // Store Id
            shipToId: number,
            shipment: number, // TODO set possible values here
            title: string // eg. "West Malaysia (POSlaju)"
        }
    ],
    storeId: number,
    variants: [
        {
            sku: string,
            quantity: number
            // TODO: This will also have more options depending on category selected
        }
    ],
    weight: number, // Can be grams or kilograms,
    weightType: ItemWeightTypes.Grams | ItemWeightTypes.Kilograms
};

export const ADD_ITEM = 'ADD_ITEM';
export const addItem = wrapAPIActionCreator(
    ({
        brand,
        category,
        categoryChild,
        categoryParent,
        categoryTags,
        dealTypes,
        description,
        tags,
        gst,
        isFixedPrice,
        isDeal,
        name,
        pictures,
        price,
        shippingDetails,
        storeId,
        variants,
        weight, // Can be grams or kilograms,
        weightType
    }: AddItemParams) => ({
        [API_DATA]: {
            type: ADD_ITEM,
            endpoint: '/v3/items',
            endpointParams: { newRequestMode: true }, // "new_request_mode" is some flag on backend used instead of creating a v4 route
            method: 'POST',
            authentication: Authentication.Merchant,
            body: {
                brand,
                category,
                categoryChlid: categoryChild, // Yeah, it's a type on backend.
                categoryParent,
                categoryTags,
                dealTypes,
                descriptions: description,
                tags,
                gst,
                isDeal,
                isFixedPrice,
                isGstEnabled: gst,
                name,
                pictures,
                price,
                shippingDetails,
                storeId,
                variants,
                weight, // Can be grams or kilograms,
                weightType
            },
            decamelizeOptions: {
                process: (key, convert) => (/^[A-Z]/.test(key) ? key : convert(key))
            },
            schema: {}
        }
    })
);

export default addItem;
