// @flow
import { API_DATA, Schemas, wrapAPIActionCreator, Authentication } from 'services/api';

export type PostMessageParams = {
    recipientId: number,
    typeId: number,
    message: string,
    itemId: number,
    conversationKey?: string,
    pictures?: [string | ArrayBuffer],
    offer: {
        deliveryOptions: {},
        grossPrice: string,
        offerPrice: string,
        paymentOptions: {},
        quantity: number,
        duration: string
    },
    authentication: Authentication.User | Authentication.Merchant
};

// POST_MESSAGE ----------------------
export const POST_MESSAGE = 'POST_MESSAGE';
export const postMessage = wrapAPIActionCreator(
    ({ recipientId, typeId, message, itemId, conversationKey, offer, pictures, authentication = Authentication.User }: PostMessageParams) => ({
        [API_DATA]: {
            type: POST_MESSAGE,
            endpoint: '/v3/messages',
            authentication,
            method: 'POST',
            body: {
                userId: recipientId,
                typeId,
                message,
                itemId,
                offer,
                conversationKey,
                pictures
            },
            reducerParams: { itemId, recipientId },
            schema: Schemas.message
        }
    })
);
